import React, { useContext, useEffect } from "react";
import styled, { css } from "styled-components";

import { PricingContext } from "./PricingProvider";
import {
  ArrowDown1616,
  CheckIcon1616Blue,
  CheckIcon1616White,
  CrossedMark,
  Flash,
  InfoIcon1818Grey,
  InfoIcon1818White,
  PercentLimited,
} from "../../../components/Icons/Icons";
import { IExternalLinks } from "../../../lib/routeUtils";
import Tooltip from "./Tooltip";
import Button from "./Button";
import { useSelector } from "react-redux";
import { getChangeSubscriptions, getUserPlans } from "../../../redux/reducers/plansReducer";
import { PlanPeriod } from "../../PricingSubscriptions/oldComponents/PlanCard";
import { UserPlanPeriod } from "../../../types/plans";
import { useDispatch } from "react-redux";
import { updateSubscriptionServer } from "../../../redux/actions/plansActions";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import SelectPlanButton from "./SelectPlanButton";

type PlanPrices = {
  oldPrice: string;
  newPrice: string;
  trigger: string;
};

export type PlanTypes = "FREE" | "PERSONAL" | "CREATOR" | "BUSINESS UNLIMITED";

export interface PlanCardProps {
  plan: PlanTypes;
  greatFor: string;
  isLimited: boolean;
  yearly: PlanPrices;
  monthly: PlanPrices;
  yearlyBilled: string;
  list: {
    text: React.ReactNode;
    important?: boolean;
    tooltip: string;
  }[];
}

const PlanCard = ({ plan, greatFor, isLimited, yearly, monthly, yearlyBilled, list }: PlanCardProps) => {
  const { isYearly, toggleYearly } = useContext(PricingContext);
  const business = plan === "BUSINESS UNLIMITED";
  const initialHref = isYearly ? yearly.trigger : monthly.trigger;

  return (
    <Wrapper>
      {business && (
        <BusinessWrapper>
          <Prefer>
            <img src="/images/pricing/prefer.png" alt="people who prefer" width={78} height={24} />
            <span>72% of professionals prefer it</span>
          </Prefer>
        </BusinessWrapper>
      )}
      <CardWrapper isBusiness={business}>
        <TopInfo>
          <TopWrapper>
            <Heading>
              <Title isBusiness={business}>{plan}</Title>
              <SubTitle isBusiness={business}>{greatFor}</SubTitle>
            </Heading>
            <LimitedLable isLimited={isLimited}>
              <PercentLimited />
              <span>Limited Time 50% OFF </span>
            </LimitedLable>
            <PriceWrapper>
              <MonthlyPrice isBusiness={business}>
                {isYearly
                  ? yearly.oldPrice && (
                      <span className="oldPrice">
                        ${yearly.oldPrice}
                        <span className="crossedMark">
                          <CrossedMark />
                        </span>
                      </span>
                    )
                  : monthly.oldPrice && (
                      <span className="oldPrice">
                        ${monthly.oldPrice}
                        <span className="crossedMark">
                          <CrossedMark />
                        </span>
                      </span>
                    )}
                <span className="newPrice">
                  ${isYearly ? yearly.newPrice : monthly.newPrice}
                  {plan !== "FREE" && <span className="month">/m</span>}
                </span>
              </MonthlyPrice>
              {isYearly ? (
                <YearlyPrice yearlyBilled={yearlyBilled} isBusiness={business}>
                  ${yearlyBilled} Billed Yearly <span onClick={toggleYearly}>Switch to Monthly</span>
                </YearlyPrice>
              ) : (
                <YearlyPrice yearlyBilled={yearlyBilled} isBusiness={business}>
                  Save 30% with <span onClick={toggleYearly}>Yearly plan</span>
                </YearlyPrice>
              )}
            </PriceWrapper>
          </TopWrapper>
          {plan === "FREE" ? (
            <Button color="pricingOutlineWhite" linkPath={IExternalLinks.tryForFree} type="link">
              Start for free
            </Button>
          ) : (
            <SelectPlanButton
              planType={plan}
              popupHash={initialHref}
              buttonColor={business ? "pricingBlue" : "pricingOutlineWhite"}
            />
          )}
        </TopInfo>
        <Separator isBusiness={business} />
        <BottomInfo>
          <ProsWrapper>
            {list.map(({ text, tooltip, important }, idx) => (
              <Pros isBusiness={business} isImportant={important} key={idx}>
                <span className="checkWrapper">
                  <CheckIcon1616White />
                </span>
                <ProsText isBusiness={business}>{text}</ProsText>
                {tooltip && (
                  <span className="tooltipWrapper">
                    {business ? <InfoIcon1818Grey /> : <InfoIcon1818White />}
                    <Tooltip title={tooltip} />
                  </span>
                )}
              </Pros>
            ))}
          </ProsWrapper>
          <GoToFeatures isBusiness={business} href="#CompareAllPlans">
            <span>See all our standard features</span>
            <ArrowDown1616 />
          </GoToFeatures>
        </BottomInfo>
      </CardWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: calc((100% - 69px) / 4);
  min-width: 280px;
  position: relative;
  padding-top: 56px;

  @media screen and (max-width: 440px) {
    min-width: 330px;
  }
`;

const BusinessWrapper = styled.div`
  position: absolute;
  width: calc(100% + 16px);
  height: calc(100% + 8px);
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px;
  background: #2a74b1;
  padding: 16px 8px;
`;

const Prefer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  img {
    display: block;
    width: 60px;
    height: auto;
  }

  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
  }

  @media screen and (max-width: 440px) {
    span {
      font-size: 12px;
    }
  }
`;

const CardWrapper = styled.div<{ isBusiness: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: ${({ isBusiness }) => (isBusiness ? "#FFFFFF" : "#2a74b1")};
  position: relative;

  @media screen and (max-width: 440px) {
    padding: 16px;
  }
`;

const TopInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 1;

  .primaryButton {
    width: 100%;
    height: 48px;

    .primaryButtonText {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.41px;
    }
  }

  @media screen and (max-width: 440px) {
    gap: 20px;
  }
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 440px) {
    gap: 6px;
  }
`;

const Title = styled.p<{ isBusiness: boolean }>`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;

  ${({ isBusiness }) =>
    isBusiness &&
    css`
      background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    `}

  @media screen and (max-width: 440px) {
    font-weight: 700;
    letter-spacing: -0.96px;
  }
`;

const SubTitle = styled.p<{ isBusiness: boolean }>`
  color: ${({ isBusiness }) => (isBusiness ? "#6F7074" : "#ffffff")};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;

  @media screen and (max-width: 440px) {
    font-size: 12px;
  }
`;

const LimitedLable = styled.div<{ isLimited: boolean }>`
  visibility: ${({ isLimited }) => (isLimited ? "visible" : "hidden")};

  width: max-content;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 60px;
  background: #0aae7d;

  span {
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.17;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MonthlyPrice = styled.div<{ isBusiness: boolean }>`
  display: flex;
  gap: 16px;
  align-items: baseline;

  .oldPrice {
    color: ${({ isBusiness }) => (isBusiness ? "#6F7074" : "#f3f4f9")};
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    position: relative;

    .crossedMark {
      position: absolute;
      top: 0;
      left: 5%;
      opacity: ${({ isBusiness }) => (isBusiness ? "0.4" : "0.5")};

      svg {
        fill: ${({ isBusiness }) => (isBusiness ? "#3b3d47" : "#ffffff")};
        width: 55px;
        height: auto;
      }
    }
  }

  .newPrice {
    color: ${({ isBusiness }) => (isBusiness ? "#191B1F" : "#ffffff")};
    font-size: 40px;
    font-weight: 600;
    line-height: 1.35;

    .month {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
    }
  }
`;

const YearlyPrice = styled.p<{ yearlyBilled: string; isBusiness: boolean }>`
  visibility: ${({ yearlyBilled }) => (yearlyBilled !== "" ? "visible" : "hidden")};
  color: ${({ isBusiness }) => (isBusiness ? "#3B3D47" : "#ffffff")};

  font-size: 12px;
  font-weight: 500;
  line-height: 2;

  span {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const Separator = styled.div<{ isBusiness: boolean }>`
  background: ${({ isBusiness }) => (isBusiness ? "#D2D2D9" : "#458ac4")};

  width: 100%;
  height: 1px;
  z-index: 1;
`;

const BottomInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  z-index: 1;
`;

const ProsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media screen and (max-width: 440px) {
    gap: 12px;
  }
`;

const Pros = styled.li<{ isBusiness: boolean; isImportant?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  width: 100%;

  .checkWrapper {
    align-self: flex-start;
    display: flex;
    padding-top: 3px;

    svg > path {
      stroke: ${({ isBusiness }) => (isBusiness ? "#0274d1" : "#ffffff")};
    }
  }

  .tooltipWrapper {
    position: absolute;
    right: 0;
    top: 3px;
    display: flex;
    cursor: help;

    .tooltip {
      max-width: 190px;
    }

    :hover .tooltip {
      opacity: 1;
    }

    svg {
      width: 17px;
      height: auto;
    }
  }

  ${({ isImportant, isBusiness }) =>
    isImportant &&
    css`
      ::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 4px;
        background: ${isBusiness ? "#DDF0FF" : "rgba(255, 255, 255, 0.2)"};
      }
    `}

  @media screen and (max-width: 440px) {
    .checkWrapper > svg {
      width: 16px;
      height: auto;
    }

    .tooltipWrapper {
      top: 2px;

      svg {
        width: 15px;
        height: auto;
      }
    }
  }
`;

const ProsText = styled.p<{ isBusiness: boolean }>`
  max-width: 85%;
  color: ${({ isBusiness }) => (isBusiness ? "#3B3D47" : "#ffffff")};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: -0.38px;
  position: relative;

  @media screen and (max-width: 440px) {
    font-size: 12px;
  }
`;

const GoToFeatures = styled.a<{ isBusiness: boolean }>`
  margin-top: auto;
  align-self: center;
  display: flex;
  gap: 8px;
  align-items: center;

  color: ${({ isBusiness }) => (isBusiness ? "#191B1F" : "#ffffff")};
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  svg {
    stroke: ${({ isBusiness }) => (isBusiness ? "#191b1f" : "#ffffff")};
  }
`;

export default PlanCard;
