import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/themeReducer";

export const LikeIcon = () => {
  const theme = useSelector(getTheme);

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.81298 4.00006C4.23698 4.00006 3.69698 4.22273 3.29298 4.62739C2.45431 5.46739 2.45431 6.83473 3.29365 7.67606L7.99965 12.3901L12.7063 7.67606C13.5456 6.83473 13.5456 5.46739 12.7063 4.62739C11.8983 3.81739 10.4743 3.81873 9.66631 4.62739L8.47165 5.82406C8.22098 6.07539 7.77831 6.07539 7.52765 5.82406L6.33298 4.62673C5.92898 4.22273 5.38965 4.00006 4.81298 4.00006ZM8.00003 14.0001C7.82336 14.0001 7.65336 13.9301 7.5287 13.8041L2.35003 8.61741C0.992698 7.25741 0.992698 5.04475 2.35003 3.68475C3.00603 3.02875 3.8807 2.66675 4.81336 2.66675C5.74603 2.66675 6.62136 3.02875 7.2767 3.68475L8.00003 4.40941L8.72336 3.68541C9.37936 3.02875 10.254 2.66675 11.1874 2.66675C12.1194 2.66675 12.9947 3.02875 13.65 3.68475C15.008 5.04475 15.008 7.25741 13.6507 8.61741L8.47203 13.8047C8.3467 13.9301 8.17736 14.0001 8.00003 14.0001Z"
        fill={theme === "light" ? "#191B1F" : "#FFFFFF"}
      />
      <mask id="mask0_493_15784" maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.81298 4.00006C4.23698 4.00006 3.69698 4.22273 3.29298 4.62739C2.45431 5.46739 2.45431 6.83473 3.29365 7.67606L7.99965 12.3901L12.7063 7.67606C13.5456 6.83473 13.5456 5.46739 12.7063 4.62739C11.8983 3.81739 10.4743 3.81873 9.66631 4.62739L8.47165 5.82406C8.22098 6.07539 7.77831 6.07539 7.52765 5.82406L6.33298 4.62673C5.92898 4.22273 5.38965 4.00006 4.81298 4.00006ZM8.00003 14.0001C7.82336 14.0001 7.65336 13.9301 7.5287 13.8041L2.35003 8.61741C0.992698 7.25741 0.992698 5.04475 2.35003 3.68475C3.00603 3.02875 3.8807 2.66675 4.81336 2.66675C5.74603 2.66675 6.62136 3.02875 7.2767 3.68475L8.00003 4.40941L8.72336 3.68541C9.37936 3.02875 10.254 2.66675 11.1874 2.66675C12.1194 2.66675 12.9947 3.02875 13.65 3.68475C15.008 5.04475 15.008 7.25741 13.6507 8.61741L8.47203 13.8047C8.3467 13.9301 8.17736 14.0001 8.00003 14.0001Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_493_15784)"></g>
    </svg>
  );
};

export const LikeActiveIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00003 14.0001C7.8227 14.0001 7.65336 13.9301 7.52803 13.8041L2.35003 8.61741C0.992698 7.25741 0.992698 5.04475 2.35003 3.68475C3.00536 3.02875 3.8807 2.66675 4.81336 2.66675C5.74603 2.66675 6.62136 3.02875 7.2767 3.68475L8.00003 4.40941L8.7227 3.68541C9.3787 3.02875 10.254 2.66675 11.1867 2.66675C12.1194 2.66675 12.9947 3.02875 13.65 3.68475C15.0074 5.04475 15.0074 7.25741 13.6507 8.61741L8.47203 13.8047C8.3467 13.9301 8.17736 14.0001 8.00003 14.0001Z"
      fill="url(#paint0_linear_493_15858)"
    />
    <mask id="mask0_493_15858" maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00003 14.0001C7.8227 14.0001 7.65336 13.9301 7.52803 13.8041L2.35003 8.61741C0.992698 7.25741 0.992698 5.04475 2.35003 3.68475C3.00536 3.02875 3.8807 2.66675 4.81336 2.66675C5.74603 2.66675 6.62136 3.02875 7.2767 3.68475L8.00003 4.40941L8.7227 3.68541C9.3787 3.02875 10.254 2.66675 11.1867 2.66675C12.1194 2.66675 12.9947 3.02875 13.65 3.68475C15.0074 5.04475 15.0074 7.25741 13.6507 8.61741L8.47203 13.8047C8.3467 13.9301 8.17736 14.0001 8.00003 14.0001Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_15858)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_493_15858"
        x1="4.20442"
        y1="4.1488"
        x2="10.5763"
        y2="13.789"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
