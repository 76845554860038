import { formatDistance } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { sliceString } from "../../lib/formatUtils";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { renameProjectServer } from "../../redux/actions/projectAction";
import { Project } from "../../types/project";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import BinIcon from "../Icons/BinIcon";
import CircularProgress from "../Icons/CircularProgress";
import { ClockIcon, EditIcon, OptionsIcon, TrashIcon } from "../Icons/Icons";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import MenuItem from "../ActionMenu/MenuItem";
import ActionMenu from "../ActionMenu/ActionMenu";

interface ProjectCardProps {
  project: Project;
  title: string;
  description?: string;
  projectTime?: string;
  image?: string;
  fullWidth?: boolean;
  active?: boolean;
  setActiveProject: any;
  handleActiveProject?: (e: any) => void;
}

const ProjectCard = ({
  project,
  title,
  description,
  projectTime,
  image,
  fullWidth = false,
  active,
  setActiveProject,
  handleActiveProject,
}: ProjectCardProps) => {
  const dispatch = useDispatch();

  const [projectName, setProjectName] = useState<string>(title);
  const [isLoading, setIsLoading] = useState<string | number>("");
  const [menuOpen, setMenuOpen] = useState<number>();
  const handleChange = (e: any) => setProjectName(e.target.value);

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();

    setMenuOpen(-1);
  };

  const handleKeyPress = (e: any, type?: "blur") => {
    if (e.code === "Enter" || type) {
      if (!project) throw Error("Project entity is missing while renaming");
      if (projectName !== project?.title) {
        dispatch(
          renameProjectServer({
            projectId: project?.projectId,
            projectTypeId: project?.projectTypeId,
            newTitle: projectName,
            oldTitle: project?.title,
          }),
        );
      }
      setActiveProject(null);
    }
  };

  useEffect(() => {
    setProjectName(project.title);
  }, [project.title]);

  const handleDeleteClick = (e: any) => {
    if (!project.projectId) {
      throw Error("Project entity is missing while deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: project.projectId,
      title: "Are you sure you want to delete project " + project.title,
      description: "The project will be removed and the action cannot be undone",
      typeId: 1,
      type: DeleteType.Project,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  console.log(projectTime, "projectTime");

  return (
    <Wrapper className="project-card" fullWidth={fullWidth}>
      <Left>
        {/* <TitleWrapper onClick={handleActiveProject}> */}
        <TitleWrapper>
          {!active ? (
            <>
              <Avatar src={image ? image : "/images/projectMock.png"} />
              <Title>{title}</Title>
              <ActionWrapper>
                {menu.map((m, order) => (
                  <ActionMenu
                    key={order}
                    position={{
                      x: "left",
                      y: "bottom",
                    }}
                    open={menuOpen === order}
                    menuStyle={{ top: "-10px", right: "25px", padding: "8px 12px" }}
                    handleClose={handleCloseMenu}
                    trigger={
                      <IconButton
                        iconButtonTheme={IconButtonThemes.Primary}
                        icon={m.icon}
                        onClick={order === 0 ? (e: any) => handleOpenMenu(e, order) : () => null}
                      />
                    }
                  >
                    {m.options && (
                      <MenuWrapper>
                        {m.options.map((option, index) =>
                          option.hidden ? (
                            <></>
                          ) : (
                            <MenuItem
                              key={index}
                              icon={option.icon}
                              onClick={(e: any) => {
                                e.preventDefault();
                                option.name === "Rename" && handleActiveProject && handleActiveProject(e);
                                option.name === "Delete" && handleDeleteClick(e);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ),
                        )}
                      </MenuWrapper>
                    )}
                  </ActionMenu>
                ))}
              </ActionWrapper>
              {/* <IconButton iconButtonTheme={IconButtonThemes.Transparent} icon={<EditIcon />} /> */}
            </>
          ) : (
            <Textfield
              autoFocus
              variant={TextfieldVariant.project}
              value={projectName}
              placeholder="Enter project name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onBlur={(e) => handleKeyPress(e, "blur")}
            />
          )}
        </TitleWrapper>
        {description && <Description>{sliceString(description, 22)}</Description>}
        {projectTime && (
          <TimeWrapper>
            <ClockIcon />
            <ProjectTime>Edited {formatDistance(new Date(projectTime + "Z"), new Date())} ago</ProjectTime>
          </TimeWrapper>
        )}
      </Left>
      {/* <ImageWrapper>
        {isLoading == project?.projectId && <CircularProgress />}
        <div className="delete" onClick={handleDeleteClick}>
          <BinIcon />
        </div>
        <Image src={image ? image : "/images/projectMock.png"} alt={title} />
      </ImageWrapper> */}
    </Wrapper>
  );
};

const menu = [
  {
    icon: <OptionsIcon />,
    options: [
      {
        name: "Rename",
        icon: <EditIcon />,
        hidden: false,
      },
      {
        name: "Delete",
        icon: <TrashIcon />,
      },
    ],
  },
];

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  padding: 14px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.iconButtonShadow};
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  gap: 4px;
  width: 234px;
  height: 108px;
  position: relative;

  &:hover {
    .delete {
      opacity: 1;
    }
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
  `}
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 14px;

  span {
    button {
      width: 20px;
      height: 20px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg > path {
        fill: white;
      }
    }
  }
`;

const MenuWrapper = styled.div`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 4px;

  button {
    svg > path {
      fill: ${({ theme }) => theme.icon};
    }
  }
`;

const Title = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: 115px;
`;

const Description = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
  margin: 7px 0px 0px 0px;
`;

const ProjectTime = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.6;
`;

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: auto;

  & > svg > path {
    fill: ${({ theme }) => theme.icon};
    opacity: 0.6;
  }
`;

const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;

  .loader {
    position: absolute;
    z-index: 300;
    background-color: white;
    border-radius: 50%;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;

    path {
      fill: #009af7;
    }
  }

  .delete {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    z-index: 8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;

    svg {
      margin: auto;
      width: 20px;
      height: auto;

      path {
        fill: red;
      }
    }
  }
`;

const Image = styled.img`
  border-radius: 50%;
  object-fit: cover;
  width: 40px;
  height: 40px;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
`;

const EmptyImage = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.disableIcon}33;
  border-radius: 3px;
`;

export default ProjectCard;
