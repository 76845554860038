import React, { useContext } from "react";
import styled from "styled-components";

import Button, { ButtonThemes } from "../../../../components/Button/Button";
import AddTextPanel from "../../../ScenesPoc/components/AddTextPanel";
import ObjectChips from "../../../ScenesPoc/components/ObjectChips";
import PropertiesPanel from "../../../ScenesPoc/components/PropertiesPanel";
import TitleWithAction from "../TitleWithAction";
import { DropdownDelete } from "../../../../components/Icons/Icons";
import { VideoEditorContext } from "../../../../App";

const TextTab = () => {
  const { deleteAllText } = useContext(VideoEditorContext);

  return (
    <Wrapper>
      <TitleWithAction
        type="text"
        title="Text"
        action={
          <Button
            onClick={deleteAllText}
            buttonTheme={ButtonThemes.Secondary}
            icon={<DropdownDelete />}
            text="Delete all"
          />
        }
      >
        <Panel>
          <AddTextPanel />
          <ObjectChips />
          {/* <PropertiesPanel /> */}
        </Panel>
      </TitleWithAction>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 272px;
  max-width: 100%;
`;

export default TextTab;
