import styled from "styled-components";

import { TranslationTabProps } from "../../../../types/videoTranslation";

const SetPositionTab = ({ title, icon, position }: TranslationTabProps) => {
  return (
    <SetPositionTabWrapper>
      <span>{title}</span>
      <SetPositionIconWrapper position={position}>{icon}</SetPositionIconWrapper>
    </SetPositionTabWrapper>
  );
};

const SetPositionTabWrapper = styled("div")`
  background-color: ${({ theme }) => theme.videoTranslationBackground};
  max-width: 120px;
  min-width: 120px;
  width: 100%;
  height: 85px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
  }
`;

const SetPositionIconWrapper = styled.div<{
  position?: { left?: string; right?: string; top?: string; bottom?: string };
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.videoTranslationTabBackground};
  position: relative;

  svg {
    position: absolute;
    ${({ position }) =>
      position &&
      `
      ${position.bottom ? `bottom: ${position.bottom};` : ""}
      ${position.left ? `left: ${position.left};` : ""}
      ${position.right ? `right: ${position.right};` : ""}
      ${position.top ? `top: ${position.top};` : ""}
    `}

    path {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;

export default SetPositionTab;
