import React from "react";
import styled from "styled-components";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { DownloadIcon } from "../../../components/Icons/DownloadIcon";
import { downloadFile } from "../../../lib/downloadFile";

interface Props {
  src: string;
  date: string;
  // duration: number;
}

const Video = ({ date, src }: Props) => {
  return (
    <Wrapper>
      <VideoWrapper>
        <video src={src} autoPlay loop />
      </VideoWrapper>
      <Bottom>
        <DateWrapper>
          <Date>{date}</Date>
          {/* <DurationWrapper>{duration}s</DurationWrapper> */}
        </DateWrapper>
        <StyledButton
          text="Download Original Video"
          buttonTheme={ButtonThemes.Secondary}
          icon={<DownloadIcon />}
          onClick={(e) => downloadFile(e, src)}
        />
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
`;

const VideoWrapper = styled.div`
  border-radius: 20px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  overflow: hidden;

  & video {
    max-height: 530px;
    width: 100%;
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 631px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const Date = styled.div`
  color: ${({ theme }) => theme.primaryText};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const DurationWrapper = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 0 6px;
  border-radius: 2px;
  color: ${({ theme }) => theme.primaryText};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const StyledButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 14px 44px;
  height: 48px;
  border-radius: 12px;

  & path {
    fill: #0063b4;
  }
`;

export default Video;
