import { useEffect, useState } from "react";

interface UseResponsibleProps {
  width: number;
}

export const useMobile = ({ width }: UseResponsibleProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${width}px)`);
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(window.matchMedia(`(max-width: ${width}px)`).matches);
    }
  }, [width]);

  return isMobile;
};
