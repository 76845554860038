export const TextAlignRightIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1.75L15 1.75C15.4142 1.75 15.75 1.41421 15.75 1C15.75 0.585786 15.4142 0.25 15 0.25H1C0.585786 0.25 0.25 0.585788 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75Z"
      fill="#191B1F"
    />
    <path
      d="M9 5.75L15 5.75C15.4142 5.75 15.75 5.41421 15.75 5C15.75 4.58579 15.4142 4.25 15 4.25L9 4.25C8.58579 4.25 8.25 4.58579 8.25 5C8.25 5.41422 8.58579 5.75 9 5.75Z"
      fill="#191B1F"
    />
    <path
      d="M1 8.25C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75L15 9.75C15.4142 9.75 15.75 9.41421 15.75 9C15.75 8.58579 15.4142 8.25 15 8.25L1 8.25Z"
      fill="#191B1F"
    />
    <path
      d="M9 13.75L15 13.75C15.4142 13.75 15.75 13.4142 15.75 13C15.75 12.5858 15.4142 12.25 15 12.25L9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75Z"
      fill="#191B1F"
    />
  </svg>
);
