export enum SocialNetwork {
  Google = "google",
  G2 = "g2",
  Capterra = "capterra",
}

export enum BackgroundType {
  Default = "default",
  Color = "color",
}

export interface Review {
  id: number;
  background: BackgroundType;
  network: SocialNetwork;
  stars: number;
  avatar: string;
  name: string;
  date: string;
  text: string;
}
