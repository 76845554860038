export const AddIcon = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.12435 4.2915H6.70768V1.87484C6.70768 1.21025 6.16393 0.666504 5.49935 0.666504C4.83477 0.666504 4.29102 1.21025 4.29102 1.87484V4.2915H1.87435C1.20977 4.2915 0.666016 4.83525 0.666016 5.49984C0.666016 6.16442 1.20977 6.70817 1.87435 6.70817H4.29102V9.12484C4.29102 9.78942 4.83477 10.3332 5.49935 10.3332C6.16393 10.3332 6.70768 9.78942 6.70768 9.12484V6.70817H9.12435C9.78893 6.70817 10.3327 6.16442 10.3327 5.49984C10.3327 4.83525 9.78893 4.2915 9.12435 4.2915Z"
      fill="white"
    />
  </svg>
);
