import React, { useContext, useState } from "react";
import styled from "styled-components";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { CircleHumatarIcon, FullBodyHumatarIcon, VoiceOnluHumatarIcon } from "../../../../../components/Icons/Icons";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";
import { ActorPositionTypes, Avatar } from "../../../../../types/scene";
import { VideoEditorContext } from "../../../../../App";

const getListItem = {
  [ActorPositionTypes.Circle]: {
    text: "Circle",
    icon: <CircleHumatarIcon />,
    isAllow: true,
  },
  [ActorPositionTypes.FullBody]: {
    text: "Full body",
    icon: <FullBodyHumatarIcon />,
    isAllow: true,
  },
  [ActorPositionTypes.VoiceOnly]: {
    text: "Voice only",
    icon: <VoiceOnluHumatarIcon />,
    isAllow: true,
  },
};

interface Props {
  currentObject: Avatar;
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  handleDeleteCurrentObject: () => void;
}

const AvatarType = ({ currentObject, handleSceneObjectChange, handleDeleteCurrentObject }: Props) => {
  const modifyGetListItem = {
    ...getListItem,
    [ActorPositionTypes.Circle]: {
      ...getListItem[ActorPositionTypes.Circle],
      isAllow: currentObject.allowCircle,
    },
  };

  const theme = useSelector(getTheme);

  const { handleChangeActorPositionType } = useContext(VideoEditorContext);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const handleChangeCurrentType = (type: ActorPositionTypes) => {
    if (!currentObject) return;
    handleClose();
    if (type !== ActorPositionTypes.VoiceOnly) {
      handleChangeActorPositionType(type);
      handleSceneObjectChange("actorPositionType", type, currentObject.id);
    } else {
      handleDeleteCurrentObject();
    }
  };

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper>
            <IconButton
              iconButtonTheme={IconButtonThemes.Rounded}
              icon={getListItem[currentObject.actorPositionType].icon}
              className={isOpen ? "active" : "not-active"}
              onClick={handleOpen}
            />
            <Tooltip
              text={"View mode"}
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
              reverseColor
              arrow
            />
            {isOpen && <ActiveBackground />}
          </ButtonWrapper>
        }
      >
        {Object.keys(ActorPositionTypes).map((type) => (
          <MenuListItem
            key={type}
            isActive={type === currentObject.actorPositionType}
            isAllow={!modifyGetListItem[type as ActorPositionTypes]?.isAllow}
            onClick={() => handleChangeCurrentType(type as ActorPositionTypes)}
          >
            {modifyGetListItem[type as ActorPositionTypes].icon}
            {modifyGetListItem[type as ActorPositionTypes].text}
          </MenuListItem>
        ))}
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
    }
  }
`;

const ActiveBackground = styled.div`
  background: linear-gradient(to bottom right, #0063b4 0%, #009af7 100%);
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  border-radius: 12px;
`;

const MenuListItem = styled.div<{ isActive: boolean; isAllow: boolean }>`
  user-select: none;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};

  & svg {
    width: 16px;
    height: 16px;
  }

  ${({ isAllow }) =>
    isAllow &&
    `
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(25px)",
  left: "0",
  border: "none",
  padding: "12px 0",
  width: "140px",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default AvatarType;
