export const PauseIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 24.000000 24.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
      <path
        d="M36 194 c-13 -34 -6 -142 10 -158 19 -20 29 -20 48 0 11 11 16 35 16
79 0 81 -6 95 -40 95 -16 0 -30 -7 -34 -16z"
      />
      <path
        d="M136 194 c-13 -34 -6 -142 10 -158 19 -20 29 -20 48 0 11 11 16 35
16 79 0 81 -6 95 -40 95 -16 0 -30 -7 -34 -16z"
      />
    </g>
  </svg>
);
