import styled from "styled-components";
import { Link } from "react-router-dom";

import ManageChanges from "./components/ManageChanges";
import Tooltip from "../../components/Tooltip/Tooltip";
import TrimAction from "./components/navbarActions/video/TrimAction";
import Button, { ButtonThemes } from "../../components/Button/Button";
import TextActions from "./components/navbarActions/text/TextActions";
import CircularProgress from "../../components/Icons/CircularProgress";
import MediaActions from "./components/navbarActions/media/MediaActions";
import ShapesActions from "./components/navbarActions/shapes/ShapesActions";
import HumatarActions from "./components/navbarActions/humatar/HumatarActions";
import IconButton, { IconButtonThemes } from "../../components/Button/IconButton";
import { Project } from "../../types/humansProject";
import { ObjectTypes, Scene } from "../../types/scene";
import { ScreenProps, screens } from "../../mocks/humans";
import { SaveIcon } from "../../components/Icons/Icons";
import { Status } from "../../redux/actions/humansProjectActions";

export const startAdornment = (activeScreen: ScreenProps, handleActiveScreen: (id: ScreenProps) => void) => (
  <Heading onClick={(event) => event.stopPropagation()}>
    <Link to="/my-projects?filter=ai-humans">
      <Button
        className="btn-back"
        buttonTheme={ButtonThemes.Secondary}
        icon={<img src="/images/arrow-left.svg" />}
        text="Back"
      />
    </Link>
    <ScreenButton>
      {screens.map(({ id, icon, type }) => (
        <IconButton
          key={id}
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={icon}
          className={activeScreen === type ? "active" : "not-active"}
          onClick={() => handleActiveScreen(type)}
        />
      ))}
    </ScreenButton>
  </Heading>
);

export const navActions = (
  createVideo: () => void,
  updateVideo: () => void,
  humansProject: Project | null,
  humansProjectLoading: boolean,
  handleBackInHistory: () => void,
  handleForwardInHistory: () => void,
  currentHistoryIndex: number,
  historyLength: number,
  generativeVideoPopupOpen: () => void,
  disabled: boolean,
  isNotEnoughCredits: boolean,
  notEnoughCreditsPopupOpen: () => void,
  status?: Status,
  isGuest?: boolean,
  signUpPopupOpen?: () => void,
) => (
  <>
    <ManageChanges
      back={handleBackInHistory}
      undo={handleForwardInHistory}
      currentHistoryIndex={currentHistoryIndex}
      historyLength={historyLength}
    />
    <ButtonWrapper>
      <SaveButton>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={humansProjectLoading ? <CircularProgress color="#009af7" /> : <SaveIcon />}
          onClick={humansProject?.projectId ? updateVideo : createVideo}
        />
        <Tooltip
          text="Save"
          position="bottom"
          style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
          reverseColor
          arrow
        />
      </SaveButton>
      <GenerateButton disabled={!disabled}>
        <Button
          text={status === Status.isProgress ? "Generating Video..." : "Create Video"}
          disabled={!disabled}
          onClick={
            !isGuest ? (isNotEnoughCredits ? notEnoughCreditsPopupOpen : generativeVideoPopupOpen) : signUpPopupOpen
          }
        />
        <Tooltip
          text={
            status === Status.isProgress ? "The project is being generated" : "Save the project before creating a video"
          }
          position="bottom"
          style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
          reverseColor
          arrow
        />
      </GenerateButton>
    </ButtonWrapper>
  </>
);

export const tabActions = (currentScene: Scene, backgroundRef: boolean, handleTrimVideo: () => void) => {
  const getTabAction = {
    [ObjectTypes.avatars]: <HumatarActions />,
    [ObjectTypes.texts]: <TextActions />,
    [ObjectTypes.shapes]: <ShapesActions />,
    [ObjectTypes.media]: <MediaActions />,
  };

  const { slideBackgroundColor } = currentScene || {};

  if (!currentScene?.objects) {
    if (backgroundRef && currentScene?.backgroundAssetId === 2 && !slideBackgroundColor) {
      return <TrimAction onClick={handleTrimVideo} />;
    }

    return <div />;
  }

  const activeObjectType = currentScene.objects.find((item) => item.object.id === currentScene.activeObjectId)?.type;

  if (!activeObjectType) {
    if (backgroundRef && currentScene?.backgroundAssetId === 2 && !slideBackgroundColor) {
      return <TrimAction onClick={handleTrimVideo} />;
    }

    return <div />;
  }

  return (
    <TabActionsWrapper onClick={(event) => event.stopPropagation()}>{getTabAction[activeObjectType]}</TabActionsWrapper>
  );
};

const TabActionsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.secondaryBackground};
  width: 100%;
  height: 100vh;
  padding: 24px 24px 12px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  gap: 24px;
`;

export const PageWrapper = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  border-radius: 32px;
  flex: 1;
  position: relative;
  overflow: hidden;

  & > div:first-of-type {
    padding: 24px 0 0 0;
    row-gap: 0;

    & > div:last-of-type {
      padding-top: 24px;
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 223px;
  gap: 24px;
  padding-left: 32px;

  & > a {
    width: 223px;
    justify-content: flex-start;
  }

  & > div {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 100%;
  padding: 0 32px 15px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 272px;
  width: 100%;

  .btn-back {
    margin-right: auto;
    height: 48px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 12px;

  hr {
    border: none;
    width: 1px;
    background-color: #d1d1d5;
  }

  & > div:first-of-type {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;

export const ImageWrapper = styled.div<{ activeScreen: ScreenProps }>`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100% - 240px);

  & > div {
    aspect-ratio: 16 / 9;
    max-height: 100%;
  }

  @media (max-height: 799px) {
    & > div {
      min-width: 500px;
      max-height: 350px;
    }
  }

  @media (max-height: 735px) {
    & > div {
      max-height: 300px;
    }
  }

  @media (max-height: 685px), (max-width: 1366px) {
    & > div {
      min-width: auto;
      max-height: 245px;
    }
  }

  ${({ activeScreen }) =>
    activeScreen === ScreenProps.MOBILE &&
    `
        & > div {
          aspect-ratio: 9 / 16;
        }

        .bar-preview {
          width: 258px;
          left: calc((100% - 258px) / 2);
        
          & > div {
            & > svg {
              width: 12px;
              height: 12px;
            }

            & > span {
              font-size: 12px;
              letter-spacing: -0.7px;
            } 
          } 
        }

        @media (max-height: 800px) {
          & > div {
            min-width: 148px;
          }
        }
      `}
`;

export const BarPreviewOuterWrapper = styled.div<{ opacity?: boolean; canvasWidth?: number }>`
  position: absolute;
  top: -25px;
  left: ${({ canvasWidth }) => (canvasWidth ? `calc((100% - ${canvasWidth}px) / 2)` : 0)};
  width: ${({ canvasWidth }) => (canvasWidth ? `${canvasWidth}px` : "auto")};
  z-index: 5;
  pointer-events: none;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
  transition: opacity 1s;
`;

export const BarPreviewInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;

  svg {
    flex-shrink: 0;
    width: 14px;
    height: 14px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    opacity: 0.8;
    color: ${({ theme }) => theme.primaryText};
  }
`;

export const IconButtonWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  top: calc(50% - 18px);
  left: -2px;
  transform: rotate(-180deg);
  transition: 0.4s;
  width: 20px;

  & > button {
    width: 20px;
    height: 32px;
    border: 2px solid ${({ theme }) => theme.primaryBackground};
    border-radius: 12px 0 0 12px;

    &:focus {
      border: 2px solid ${({ theme }) => theme.primaryBackground};
    }

    svg {
      transform: translateX(1px);
    }
  }

  ${({ active }) =>
    active &&
    `
        & > button > svg {
          transform: rotate(180deg);
        }
    `}
`;

export const ScreenButton = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 50%;
  justify-content: end;

  button {
    box-shadow: ${({ theme }) => theme.buttonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const GenerateButton = styled.div<{ disabled: boolean }>`
  position: relative;

  button {
    max-width: 175px;
    min-width: 175px;
    cursor: not-allowed;
  }

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  ${({ disabled }) =>
    !disabled &&
    `
      & > div {
        display: none;
      }

      button {
        cursor: pointer;
      }
    `}
`;

const SaveButton = styled.div`
  position: relative;

  button {
    position: relative;
    z-index: 1;
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;
    box-shadow: ${({ theme }) => theme.buttonShadow};

    svg {
      width: 24px;
      height: 24px;
    }
  }

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: ${({ theme }) => theme.primaryBackground}4d;
  backdrop-filter: blur(6px);

  svg {
    height: 100px;
    width: 100px;
  }
`;
