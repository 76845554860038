import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/react";
import Router from "./routes";

import { useDarkMode } from "./hooks/useDarkMode";
import { ThemeTypes } from "./types/theme";
import { darkTheme, lightTheme } from "./themes/themes";
import { createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMyProfileServer } from "./redux/actions/profileActions";
import { useSelector } from "react-redux";
import { getIsAuthorized, getIsHydrated } from "./redux/reducers/authReducer";
import { getProfile } from "./redux/reducers/profileReducer";
import { useVideoEditor } from "./hooks/useVideoEditor";
import { VideoEditorContextType } from "./types/videoEditorContext";
import { AiImagesContextTypes } from "./types/aiImagesContext";
import { useAiImages } from "./hooks/useAiImages";
import { VideoTranslationContextTypes } from "./types/videoTranslationContext";
import { useVideoTranslation } from "./hooks/useVideoTranslation";

export const VideoEditorContext = createContext<VideoEditorContextType>({} as VideoEditorContextType);
export const AIImagesContext = createContext<AiImagesContextTypes>({} as AiImagesContextTypes);
export const VideoTranslationContext = createContext<VideoTranslationContextTypes>({} as VideoTranslationContextTypes);

const App = () => {
  const videoEditor = useVideoEditor();
  const aiImagesContext = useAiImages();
  const videoTranslation = useVideoTranslation();
  const { theme } = useDarkMode();
  const themeMode = theme === ThemeTypes.light ? lightTheme : darkTheme;
  const profile = useSelector(getProfile);

  const dispatch = useDispatch();

  const authorized = useSelector(getIsAuthorized);
  const hydrated = useSelector(getIsHydrated);

  useEffect(() => {
    // if (!hydrated) return;
    if (authorized) {
      dispatch(getMyProfileServer());
    } else {
      Sentry.setUser(null);
    }
  }, [authorized, hydrated]);

  useEffect(() => {
    if (authorized && hydrated && profile.email) {
      Sentry.setUser({ email: profile.email });
    }
  }, [authorized, hydrated, profile]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.noticeable.render("widget", "HhSbAWQvk90PptShlFTC");
  });

  return (
    <>
      <ThemeProvider theme={themeMode}>
        <VideoEditorContext.Provider value={videoEditor}>
          <AIImagesContext.Provider value={aiImagesContext}>
            <VideoTranslationContext.Provider value={videoTranslation}>
              <Router />
            </VideoTranslationContext.Provider>
          </AIImagesContext.Provider>
        </VideoEditorContext.Provider>
      </ThemeProvider>
      <OnboardingTour />
    </>
  );
};

const OnboardingTour = () => {
  useEffect(() => {
    // Create the User segmentation script element
    const userSegmentationScript = document.createElement("script");
    userSegmentationScript.innerHTML = `
      window.usetifulTags = {
        segment: "EXAMPLE_SEGMENT_NAME",
        role: "EXAMPLE_ROLE",
        firstName: "EXAMPLE_NAME"
      };
    `;

    // Create the Usetiful script element
    const usetifulScript: any = document.createElement("script");
    usetifulScript.async = 1;
    usetifulScript.src = "https://www.usetiful.com/dist/usetiful.js";
    usetifulScript.setAttribute("id", "usetifulScript");
    usetifulScript.dataset.token = "1c9e114e2651672e5578a7fb96cdd59c";

    // Append the script elements to the body
    document.body.appendChild(userSegmentationScript);
    document.body.appendChild(usetifulScript);

    // Cleanup: Remove the script elements when the component unmounts
    return () => {
      document.body.removeChild(userSegmentationScript);
      document.body.removeChild(usetifulScript);
    };
  }, []);

  return null;
};

export default App;
