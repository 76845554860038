import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { GET_ALL_SUPPORTED_LANGUAGES, updateLanguageLoading } from "../actions/languageAction";

const languageSaga = [
  takeEvery(`${GET_ALL_SUPPORTED_LANGUAGES}`, handleGetAllSupportedLanguagesServer),
  takeEvery(`${GET_ALL_SUPPORTED_LANGUAGES}_FAIL`, handleGetAllSupportedLanguagesServerFail),
  takeEvery(`${GET_ALL_SUPPORTED_LANGUAGES}_SUCCESS`, handleGetAllSupportedLanguagesServerSuccess),
];

function* handleGetAllSupportedLanguagesServer() {
  yield put(updateLanguageLoading({ isLoading: true }));
}

function* handleGetAllSupportedLanguagesServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_LANGUAGES.toast);
  yield put(updateLanguageLoading({ isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_LANGUAGES,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetAllSupportedLanguagesServerSuccess() {
  yield put(updateLanguageLoading({ isLoading: false }));
}

export default languageSaga;
