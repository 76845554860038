import React from "react";
import { Link } from "react-router-dom";

interface SmartLinkProps {
  url?: string;
  label?: string;
  children: React.ReactNode;
  blank?: boolean;
}

const SmartLink: React.FC<SmartLinkProps> = ({ children, url, label, blank }: SmartLinkProps) => {
  const externalLinkTest = "http";

  if (url && url.includes(externalLinkTest)) {
    return (
      <a href={url} rel="noreferrer" target="_blank" aria-label={label}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={url || "#"}>
        <a href="" aria-label={label} target={blank ? "_blank" : "_self"}>
          {children}
        </a>
      </Link>
    );
  }
};

export default SmartLink;
