export const ArrowRight = () => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.33333 5.99999C6.33333 6.18999 6.26917 6.37916 6.14 6.53333L1.97333 11.5333C1.67917 11.8867 1.15333 11.935 0.8 11.64C0.446667 11.3458 0.399167 10.8208 0.693333 10.4667L4.42333 5.99083L0.8275 1.52249C0.539167 1.16416 0.595833 0.639162 0.954167 0.350828C1.3125 0.062495 1.83667 0.119161 2.12583 0.477495L6.14917 5.47749C6.27167 5.62999 6.33333 5.81499 6.33333 5.99999"
      fill="white"
    />
  </svg>
);
