export const ApiIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 11.6667H3.33333C2.83333 11.6667 2.5 11.3333 2.5 10.8333C2.5 10.3333 2.83333 9.99998 3.33333 9.99998H7.5C8 9.99998 8.33333 10.3333 8.33333 10.8333C8.33333 11.3333 8 11.6667 7.5 11.6667Z"
      fill="black"
    />
    <path
      d="M10 14.1673C9.5 14.1673 9.16666 13.834 9.16666 13.334V6.6673C9.16666 6.1673 9.5 5.83397 10 5.83397H12.5C13.1667 5.83397 13.8333 6.08397 14.25 6.58397C14.75 7.00064 15 7.6673 15 8.33397V9.1673C15 9.83397 14.75 10.5006 14.25 10.9173C13.75 11.334 13.1667 11.6673 12.5 11.6673H10.8333V13.334C10.8333 13.834 10.5 14.1673 10 14.1673ZM10.8333 10.0006H12.5C12.75 10.0006 12.9167 9.9173 13.0833 9.75064C13.25 9.58397 13.3333 9.4173 13.3333 9.1673V8.33397C13.3333 8.08397 13.25 7.9173 13.0833 7.75064C12.9167 7.58397 12.75 7.50064 12.5 7.50064H10.8333V10.0006Z"
      fill="black"
    />
    <path
      d="M16.6667 14.1673C16.1667 14.1673 15.8333 13.834 15.8333 13.334V6.6673C15.8333 6.1673 16.1667 5.83397 16.6667 5.83397C17.1667 5.83397 17.5 6.1673 17.5 6.6673V13.334C17.5 13.834 17.1667 14.1673 16.6667 14.1673Z"
      fill="black"
    />
    <path
      d="M7.5 14.166C7 14.166 6.66667 13.8327 6.66667 13.3327V8.74933C6.66667 8.416 6.5 8.08267 6.33333 7.83267C5.83333 7.416 5 7.416 4.5 7.83267C4.33333 8.08267 4.16667 8.416 4.16667 8.74933V13.3327C4.16667 13.8327 3.83333 14.166 3.33333 14.166C2.83333 14.166 2.5 13.8327 2.5 13.3327V8.74933C2.5 7.99933 2.83333 7.24933 3.33333 6.666C4.41667 5.58267 6.41667 5.58267 7.5 6.666C8.08333 7.24933 8.33333 7.916 8.33333 8.74933V13.3327C8.33333 13.8327 8 14.166 7.5 14.166Z"
      fill="black"
    />
  </svg>
);
