import styled from "styled-components";
import { IVideoType, TrainingTab, VideoType, videos } from "../../../../../Training/TrainingPage";
import TrainingCard from "../../../../../Training/components/TrainingCard";
import { Popups, updatePopup } from "../../../../../../redux/actions/popupsActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
interface Props {
  tabValue: TrainingTab;
}
export const TrainingCards = ({ tabValue }: Props) => {
  const dispatch = useDispatch();

  const handlePopupOpen = (src: string) => {
    dispatch(
      updatePopup({
        popup: Popups.vimeoPopup,
        status: true,
        prefilled: {
          src,
        },
      }),
    );
  };
  return (
    <CardsWrapper>
      {videos[tabValue].slice(0, 4).map((video: IVideoType) => (
        <TrainingCard
          key={video.id}
          src={
            video.type === VideoType.vimeo ? `${video.src}&controls=0` : `https://img.youtube.com/vi/${video.src}/0.jpg`
          }
          title={video.title}
          subtitle={video?.subtitle}
          subtitleSecond={video?.subtitleSecond}
          handleClick={() =>
            handlePopupOpen(video.type === VideoType.youtube ? `https://www.youtube.com/embed/${video.src}` : video.src)
          }
          previewType={video.type === VideoType.vimeo ? "iframe" : "image"}
        />
      ))}
    </CardsWrapper>
  );
};

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 12px;
  max-height: 100%;
  & > div {
    width: calc(25% - 9px);
    min-height: 274px;
    box-shadow: ${({ theme }) => theme.homeSectionTabsShadow};
    background: ${({ theme }) => theme.voiceCardBackground};
    &:hover {
      background: ${({ theme }) => theme.hoverVoiceCardBackground};
      &>: nth-child(2) >div {
        color: white;
      }
    }
    & > :nth-child(1) {
      & > :nth-child(1) {
        height: 160px;
      }
    }
    & > :nth-child(2) {
      padding: 12px;
      row-gap: 10px;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 37px 0;
  }

  @media (max-width: 1201px) {
    max-width: 100%;
    & > div {
      width: calc(50% - 6px);
    }
    padding-right: 15px;
  }

  @media (max-width: 701px) {
    & > div {
      width: 100%;
    }
    padding-right: 10px;
  }
`;
