export const CircleHumatarIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5716 8.07065C13.5716 7.204 12.8668 6.49922 12.0002 6.49922C11.1335 6.49922 10.4287 7.204 10.4287 8.07065C10.4287 8.93729 11.1335 9.64208 12.0002 9.64208C12.8668 9.64208 13.5716 8.93729 13.5716 8.07065ZM15.143 8.07157C15.143 9.80486 13.7334 11.2144 12.0001 11.2144C10.2668 11.2144 8.85724 9.80486 8.85724 8.07157C8.85724 6.33828 10.2668 4.92871 12.0001 4.92871C13.7334 4.92871 15.143 6.33828 15.143 8.07157ZM6.5 18.2855C6.5 15.2527 8.96793 12.7855 12 12.7855C15.0321 12.7855 17.5 15.2527 17.5 18.2855C17.5 18.7193 17.1488 19.0713 16.7143 19.0713C16.2798 19.0713 15.9286 18.7193 15.9286 18.2855C15.9286 16.1193 14.1662 14.357 12 14.357C9.83379 14.357 8.07143 16.1193 8.07143 18.2855C8.07143 18.7193 7.72021 19.0713 7.28571 19.0713C6.85121 19.0713 6.5 18.7193 6.5 18.2855Z"
      fill="url(#paint0_linear_1_10322)"
    />
    <rect x="0.8" y="0.8" width="22.4" height="22.4" rx="11.2" stroke="url(#paint1_linear_1_10322)" strokeWidth="1.6" />
    <defs>
      <linearGradient
        id="paint0_linear_1_10322"
        x1="8.86923"
        y1="6.77812"
        x2="17.5119"
        y2="15.421"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_10322"
        x1="5.16923"
        y1="3.13846"
        x2="19.3846"
        y2="21.4154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
