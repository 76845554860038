import React, { useState } from "react";
import styled from "styled-components";
import { ProfileHumanSidebarType } from "../../../types/human";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import {
  CircleIcon,
  ImageIcon,
  LinkIcon,
  MusicIcon,
  ProfileIcon,
  TextIcon,
  Logo,
  PricingIcon,
  TemplateIcon,
} from "../../../components/Icons/Icons";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { Link } from "react-router-dom";
import { pages } from "../../../lib/routeUtils";
import { useSelector } from "react-redux";
import { getProfile } from "../../../redux/reducers/profileReducer";

interface Props {
  activeGlobalTab: ProfileHumanSidebarType;
  setActiveGlobalTab: (type: ProfileHumanSidebarType) => void;
}

const humansSidebar = [
  { id: 1, icon: <ProfileIcon />, type: ProfileHumanSidebarType.Humatar },
  { id: 2, icon: <ImageIcon />, type: ProfileHumanSidebarType.Background },
  { id: 3, icon: <TextIcon />, type: ProfileHumanSidebarType.Text },
  { id: 5, icon: <CircleIcon />, type: ProfileHumanSidebarType.Shapes },
  { id: 6, icon: <MusicIcon />, type: ProfileHumanSidebarType.Soundtrack },
  { id: 7, icon: <LinkIcon />, type: ProfileHumanSidebarType.Transitions },
  { id: 8, icon: <ImageIcon />, type: ProfileHumanSidebarType.Media },
  { id: 9, icon: <TemplateIcon />, type: ProfileHumanSidebarType.Templates },
];

const ProfileHumanSidebar = ({ activeGlobalTab, setActiveGlobalTab }: Props) => {
  const [selectedMenu, setSelectedMenu] = useState("");

  const handleActive = (type: ProfileHumanSidebarType) => {
    const scrollElement = document.getElementById("pagewrapperid");
    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: "smooth" });
    }

    setActiveGlobalTab(type);
  };

  const profile = useSelector(getProfile);

  return (
    <Wrapper onClick={(event) => event.stopPropagation()}>
      <Content>
        <ImageWrapper>
          <Logo />
        </ImageWrapper>
        <ButtonsWrapper>
          {humansSidebar.map(({ id, icon, type }) => (
            <div key={id} onMouseEnter={() => setSelectedMenu(type)} onMouseLeave={() => setSelectedMenu("")}>
              <IconButton
                key={id}
                iconButtonTheme={IconButtonThemes.Rounded}
                icon={icon}
                className={activeGlobalTab !== type ? "not-active" : "active"}
                onClick={() => handleActive(type)}
              />
              <Tooltip
                text={selectedMenu}
                position="bottom"
                style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
                arrow
              />
            </div>
          ))}
        </ButtonsWrapper>
        {!profile.hidePricing && (
          <Link to={`${pages.pricingSubscriptions()}?plan=humans`}>
            <PricingButton>
              <PricingIcon />
              <span>Pricing</span>
            </PricingButton>
          </Link>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 60px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  padding-bottom: 12px;
`;

const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  & > div {
    position: relative;

    & > div {
      width: max-content;

      span {
        text-transform: capitalize;
      }
    }

    &:hover > div {
      opacity: 1;
      visibility: visible;
    }

    button {
      box-shadow: ${({ theme }) => theme.secondaryCardShadow};
      border-radius: 16px;
      max-width: 60px;
      height: 60px;

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: ${({ theme }) => theme.white};
        }

        g > rect {
          fill: ${({ theme }) => theme.white};
        }
      }

      &.not-active {
        background: ${({ theme }) => theme.secondaryBackground};

        svg {
          path {
            fill: ${({ theme }) => theme.primaryBackground};
          }

          g > rect {
            fill: ${({ theme }) => theme.primaryBackground};
          }

          .stroke-color {
            stroke: ${({ theme, id }) => (id === "light" ? theme.primaryBackground : theme.primaryBackground)};
            fill: ${({ theme, id }) => (id === "light" ? "none" : "none")};
          }
        }
      }
    }

    .stroke-color {
      stroke: ${({ theme, id }) => (id === "light" ? theme.primaryBackground : theme.white)};
      fill: ${({ theme, id }) => (id === "light" ? "none" : "none")};
    }
  }

  & > div:last-of-type {
    button {
      svg > path {
        fill: none;
      }
    }
  }
`;

const PricingButton = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.secondaryBackground};
  padding: 11px 8px 12px 8px;
  box-shadow: ${({ theme }) => theme.secondaryCardShadow};
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: center;

  & span {
    font-size: 12px;
    font-weight: 500;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export default ProfileHumanSidebar;
