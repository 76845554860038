export const transparentFaq = {
  faqContent: [
    {
      id: 0,
      title: "Is Lip-sync easy for beginners?",
      details:
        "Absolutely! We have designed it for simplicity. Just follow the 3-step guide, and you can translate videos in no time.",
    },
    {
      id: 1,
      title: "Can I use Lip-sync for both personal and business content? ",
      details:
        "Yes, Lipsynch is versatile and suitable for a range of applications, from personal vlogs to corporate communications.",
    },
    {
      id: 2,
      title: "Are my video’s audio and visual qualities preserved during translation?",
      details:
        "Yes, Lip-sync retains the original voice and video qualities, giving your content a natural and authentic touch.",
    },
    {
      id: 3,
      title: "What if my language is not in the library?",
      details:
        "We regularly expand our language options. Feel free to reach out, and we will do our best to accomodate your needs.",
    },
    {
      id: 4,
      title: "Do you have a refund policy for the AI Dubbing with Lip-sync tool?",
      details:
        "Yes, we do provide a 30 days hassle free refund guarantee in case you are not 100% satisfied with your purchase. That is how confident we are about this app!",
    },
  ],
};
