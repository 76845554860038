export const ArrowLeft = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66709 9.99975C6.66709 10.1898 6.73125 10.3789 6.86042 10.5331L11.0271 15.5331C11.3213 15.8864 11.8471 15.9348 12.2004 15.6398C12.5538 15.3456 12.6013 14.8206 12.3071 14.4664L8.57709 9.99058L12.1729 5.52225C12.4613 5.16392 12.4046 4.63892 12.0463 4.35058C11.6879 4.06225 11.1638 4.11892 10.8746 4.47725L6.85125 9.47725C6.72875 9.62975 6.66709 9.81475 6.66709 9.99975Z"
      fill="white"
    />
    <mask
      id="mask0_3474_27013"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="4"
      width="7"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66709 9.99975C6.66709 10.1898 6.73125 10.3789 6.86042 10.5331L11.0271 15.5331C11.3213 15.8864 11.8471 15.9348 12.2004 15.6398C12.5538 15.3456 12.6013 14.8206 12.3071 14.4664L8.57709 9.99058L12.1729 5.52225C12.4613 5.16392 12.4046 4.63892 12.0463 4.35058C11.6879 4.06225 11.1638 4.11892 10.8746 4.47725L6.85125 9.47725C6.72875 9.62975 6.66709 9.81475 6.66709 9.99975Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3474_27013)"></g>
  </svg>
);
