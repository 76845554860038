import { StoreType } from "../../types/store";
import { ActorModules, IActor, VoiceoverHistoryItem } from "../../types/actor";
import {
  CLEAR_ACTORS,
  GET_ACTORS_SERVER,
  SET_PAGE_ACTORS,
  UPDATE_ACTOR_LOADING,
  UPDATE_HAS_MORE_ACTORS,
  DOWNLOAD_VOICE_SERVER,
  BOOKMARK_ACTOR_SERVER,
  SEARCH_RESULT_LOADING,
  CLEAR_SEARCH,
  FILTER_ACTOR_LOADING,
  RESET_DOWNLOAD_LINK,
  ZIP_VOICE_SERVER,
  GET_DOWNLOAD_HISTORY_SERVER,
  UPDATE_HAS_MORE_DOWNLOAD_HISTORY,
  SET_PAGE_DOWNLOAD_HISTORY,
  CLEAR_DOWNLOAD_HISTORY,
  FACESWAP_ACTOR_SERVER,
  DELETE_CLONE_VOICE_SERVER,
  GET_VOICEOVER_HISTORY_SERVER,
  VOICEOVER_HISTORY_PAGE_SIZE,
  AUDIO_TO_TRANSLATED_TEXT_SERVER,
  GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER,
  CLEAR_CURRENT_SPEECH_2_SPEECH_PROJECT,
  CLEAR_CURRENT_SPEECH_TRANSLATION_PROJECT,
} from "../actions/actorActions";
import { DownloadHistory } from "../../types/downloadHistory";
import { AudioTranslateProject } from "../../types/project";

export interface actorStateType {
  [ActorModules.actorList]: {
    data: IActor[];
    search: string;
    isLoading: boolean;
    hasMore: boolean;
    pageNumber: number;
    searchLoading: boolean;
    filterLoading: boolean;
    isDeleting: boolean;
  };
  [ActorModules.downloadAudio]: {
    data: string;
    extension?: "mp3" | "zip";
    isLoading: boolean;
    isError: boolean;
  };
  [ActorModules.downloadHistory]: {
    data: DownloadHistory[];
    isLoading: boolean;
    hasMore: boolean;
    pageNumber: number;
    totalPages: number;
    totalRecords: number;
  };
  [ActorModules.faceswapActor]: {
    data: string | null;
    isLoading: boolean;
  };
  [ActorModules.clonedVoices]: {
    data: IActor | null;
    isLoading: boolean;
  };
  [ActorModules.voiceTranslate]: {
    data: IActor[];
    isLoading: boolean;
  };
  [ActorModules.voiceoverHistory]: {
    data: VoiceoverHistoryItem[];
    isLoading: boolean;
    hasMore: boolean;
    pageNumber: number;
  };
  [ActorModules.audioToTranslatedText]: {
    data: string;
    isLoading: boolean;
  };
  [ActorModules.speechTranslation]: {
    data: AudioTranslateProject | null;
    isLoading: boolean;
  };
  [ActorModules.speech2Speech]: {
    data: AudioTranslateProject | null;
    isLoading: boolean;
  };
  isHydrated: boolean;
}

const actorInitialState: actorStateType = {
  [ActorModules.actorList]: {
    data: [],
    search: "",
    isLoading: false,
    hasMore: true,
    pageNumber: 0,
    searchLoading: false,
    filterLoading: false,
    isDeleting: false,
  },
  [ActorModules.downloadAudio]: {
    data: "",
    isLoading: false,
    isError: false,
  },
  [ActorModules.downloadHistory]: {
    data: [],
    isLoading: false,
    hasMore: true,
    pageNumber: 0,
    totalPages: 0,
    totalRecords: 0,
  },
  [ActorModules.faceswapActor]: {
    data: null,
    isLoading: false,
  },
  [ActorModules.clonedVoices]: {
    data: null,
    isLoading: false,
  },
  [ActorModules.voiceTranslate]: {
    data: [],
    isLoading: false,
  },
  [ActorModules.voiceoverHistory]: {
    data: [],
    isLoading: false,
    hasMore: true,
    pageNumber: 1,
  },
  [ActorModules.audioToTranslatedText]: {
    data: "",
    isLoading: false,
  },
  [ActorModules.speechTranslation]: {
    data: null,
    isLoading: false,
  },
  [ActorModules.speech2Speech]: {
    data: null,
    isLoading: false,
  },
  isHydrated: false,
};

const actorReducer = (state = actorInitialState, action: any) => {
  switch (action.type) {
    case GET_ACTORS_SERVER: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          hasMore: true,
        },
      };
    }
    case DOWNLOAD_VOICE_SERVER: {
      return {
        ...state,
        [ActorModules.downloadAudio]: {
          ...state[ActorModules.downloadAudio],
          data: "",
          extension: "mp3",
          isLoading: true,
          isError: false,
        },
      };
    }
    case `${DOWNLOAD_VOICE_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ActorModules.downloadAudio]: {
          ...state[ActorModules.downloadAudio],
          data: action.payload.data.data,
          isLoading: false,
        },
      };
    }
    case `${DOWNLOAD_VOICE_SERVER}_FAIL`:
    case `${ZIP_VOICE_SERVER}_FAIL`: {
      return {
        ...state,
        [ActorModules.downloadAudio]: {
          ...state[ActorModules.downloadAudio],
          isLoading: false,
          isError: true,
        },
      };
    }
    case ZIP_VOICE_SERVER: {
      return {
        ...state,
        [ActorModules.downloadAudio]: {
          ...state[ActorModules.downloadAudio],
          data: "",
          extension: "zip",
          isLoading: true,
          isError: false,
        },
      };
    }
    case `${ZIP_VOICE_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ActorModules.downloadAudio]: {
          ...state[ActorModules.downloadAudio],
          data: action.payload.data.data,
          isLoading: false,
        },
      };
    }
    case RESET_DOWNLOAD_LINK: {
      return {
        ...state,
        [ActorModules.downloadAudio]: {
          ...state[ActorModules.downloadAudio],
          data: "",
          extension: undefined,
          isLoading: false,
        },
      };
    }
    case CLEAR_ACTORS: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          data: [],
          pageNumber: 0,
        },
      };
    }
    case CLEAR_SEARCH: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          search: "",
        },
      };
    }

    case `${BOOKMARK_ACTOR_SERVER}_SUCCESS`: {
      const { id, action: isBookmarked } = action.meta.previousAction.payload;
      const actors = state[ActorModules.actorList].data;
      const newIsBoormarkedValue = actors.map((actor) => (actor.actorId === id ? { ...actor, isBookmarked } : actor));

      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          data: newIsBoormarkedValue,
        },
      };
    }

    case UPDATE_ACTOR_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as ActorModules.actorList], isLoading } };
    }
    case UPDATE_HAS_MORE_ACTORS: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          hasMore: action.payload.hasMore,
        },
      };
    }
    case `${GET_ACTORS_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;
      const { isVideoTranslationPage } = action.meta.previousAction.payload;
      const { VideoTranslate, categoryType } = action.meta.previousAction.payload.request.data;

      if (isVideoTranslationPage) {
        if (VideoTranslate) {
          return {
            ...state,
            [ActorModules.voiceTranslate]: {
              ...state[ActorModules.voiceTranslate],
              data,
            },
          };
        }

        if (categoryType.includes("Custom")) {
          return {
            ...state,
            [ActorModules.clonedVoices]: {
              ...state[ActorModules.clonedVoices],
              data: data[data.length - 1],
            },
          };
        }
      }

      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          data: pageNumber === 1 ? data : [...state[ActorModules.actorList].data, ...data],
          search: action.meta.previousAction.payload.request.data.keyword,
          pageNumber: state[ActorModules.actorList].pageNumber + 1,
        },
      };
    }
    case SET_PAGE_ACTORS: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          pageNumber: action.payload.pageNumber,
        },
      };
    }
    case SEARCH_RESULT_LOADING: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          searchLoading: action.payload,
        },
      };
    }
    case FILTER_ACTOR_LOADING: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          filterLoading: action.payload,
        },
      };
    }
    case `${GET_DOWNLOAD_HISTORY_SERVER}_SUCCESS`: {
      const { isHistoryPage } = action.meta.previousAction.payload;
      const { pageNumber, data, totalPages, totalRecords } = action.payload.data;

      return {
        ...state,
        [ActorModules.downloadHistory]: {
          ...state[ActorModules.downloadHistory],
          data: !isHistoryPage
            ? pageNumber === 1
              ? data
              : [...state[ActorModules.downloadHistory].data, ...data]
            : data,
          pageNumber: !isHistoryPage ? state[ActorModules.downloadHistory].pageNumber + 1 : pageNumber,
          totalPages,
          totalRecords,
        },
      };
    }
    case UPDATE_HAS_MORE_DOWNLOAD_HISTORY: {
      return {
        ...state,
        [ActorModules.downloadHistory]: {
          ...state[ActorModules.downloadHistory],
          hasMore: action.payload.hasMore,
        },
      };
    }
    case SET_PAGE_DOWNLOAD_HISTORY: {
      return {
        ...state,
        [ActorModules.downloadHistory]: {
          ...state[ActorModules.downloadHistory],
          pageNumber: action.payload.pageNumber,
        },
      };
    }
    case CLEAR_DOWNLOAD_HISTORY: {
      return {
        ...state,
        [ActorModules.downloadHistory]: {
          ...state[ActorModules.downloadHistory],
          data: [],
          pageNumber: 0,
        },
      };
    }
    case FACESWAP_ACTOR_SERVER: {
      return {
        ...state,
        [ActorModules.faceswapActor]: {
          ...state[ActorModules.faceswapActor],
          data: null,
        },
      };
    }
    case `${FACESWAP_ACTOR_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [ActorModules.faceswapActor]: {
          ...state[ActorModules.faceswapActor],
          data: data ? "https://synthesys.live" + data : null,
        },
      };
    }
    case DELETE_CLONE_VOICE_SERVER: {
      const { id } = action.payload;
      const data = state[ActorModules.actorList].data.filter((value) => value.actorId !== id);

      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          data,
          isDeleting: true,
        },
      };
    }
    case `${DELETE_CLONE_VOICE_SERVER}_FAIL`:
    case `${DELETE_CLONE_VOICE_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ActorModules.actorList]: {
          ...state[ActorModules.actorList],
          isDeleting: false,
        },
      };
    }
    case GET_VOICEOVER_HISTORY_SERVER: {
      return {
        ...state,
        [ActorModules.voiceoverHistory]: {
          ...state[ActorModules.voiceoverHistory],
          isLoading: true,
        },
      };
    }
    case `${GET_VOICEOVER_HISTORY_SERVER}_SUCCESS`: {
      const data = action.payload.data;
      const { isReload } = action.meta.previousAction.payload;
      const { pageNumber } = action.meta.previousAction.payload.request.data;
      const previousData = state[ActorModules.voiceoverHistory].data;
      return {
        ...state,
        [ActorModules.voiceoverHistory]: {
          ...state[ActorModules.voiceoverHistory],
          isLoading: false,
          data: isReload ? data : [...previousData, ...data],
          pageNumber: isReload ? pageNumber + 1 : state[ActorModules.voiceoverHistory].pageNumber + 1,
          hasMore: data?.length === VOICEOVER_HISTORY_PAGE_SIZE,
        },
      };
    }
    case `${GET_VOICEOVER_HISTORY_SERVER}_FAIL`: {
      return {
        ...state,
        [ActorModules.voiceoverHistory]: {
          ...state[ActorModules.voiceoverHistory],
          isLoading: false,
        },
      };
    }
    case AUDIO_TO_TRANSLATED_TEXT_SERVER: {
      return {
        ...state,
        [ActorModules.audioToTranslatedText]: {
          ...state[ActorModules.audioToTranslatedText],
          data: "",
        },
      };
    }
    case `${AUDIO_TO_TRANSLATED_TEXT_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [ActorModules.audioToTranslatedText]: {
          ...state[ActorModules.audioToTranslatedText],
          data,
        },
      };
    }
    case CLEAR_CURRENT_SPEECH_2_SPEECH_PROJECT: {
      return {
        ...state,
        [ActorModules.speech2Speech]: {
          ...state[ActorModules.speech2Speech],
          data: null,
          isLoading: false,
        },
      };
    }
    case CLEAR_CURRENT_SPEECH_TRANSLATION_PROJECT: {
      return {
        ...state,
        [ActorModules.speechTranslation]: {
          ...state[ActorModules.speechTranslation],
          data: null,
          isLoading: false,
        },
      };
    }
    case `${GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      if (data.conversionType === 1) {
        return {
          ...state,
          [ActorModules.speechTranslation]: {
            ...state[ActorModules.speechTranslation],
            data,
          },
        };
      }

      return {
        ...state,
        [ActorModules.speech2Speech]: {
          ...state[ActorModules.speech2Speech],
          data,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getActorsList = (state: StoreType) => state.actor[ActorModules.actorList].data;
export const getActorsListLoading = (state: StoreType) => state.actor[ActorModules.actorList].isLoading;

export const getClonedVoices = (state: StoreType) => state.actor[ActorModules.clonedVoices].data;
export const getClonedVoicesLoading = (state: StoreType) => state.actor[ActorModules.clonedVoices].isLoading;

export const getVoiceTranslate = (state: StoreType) => state.actor[ActorModules.voiceTranslate].data;
export const getVoiceTranslateLoading = (state: StoreType) => state.actor[ActorModules.voiceTranslate].isLoading;

export const getHasMoreActors = (state: StoreType) => state.actor[ActorModules.actorList].hasMore;
export const getCurrentPageActors = (state: StoreType) => state.actor[ActorModules.actorList].pageNumber;

export const searchResultLoading = (state: StoreType) => state.actor[ActorModules.actorList].searchLoading;
export const filterResultLoading = (state: StoreType) => state.actor[ActorModules.actorList].filterLoading;

export const getDownloadData = (state: StoreType) => state.actor[ActorModules.downloadAudio];

export const getDownloadHistory = (state: StoreType) => state.actor[ActorModules.downloadHistory].data;
export const getDownloadHistoryLoading = (state: StoreType) => state.actor[ActorModules.downloadHistory].isLoading;

export const getHasMoreDownloadHistory = (state: StoreType) => state.actor[ActorModules.downloadHistory].hasMore;
export const getCurrentPageDownloadHistory = (state: StoreType) => state.actor[ActorModules.downloadHistory].pageNumber;

export const getDownloadHistoryTotalPages = (state: StoreType) => state.actor[ActorModules.downloadHistory].totalPages;
export const getDownloadHistoryTotalRecords = (state: StoreType) =>
  state.actor[ActorModules.downloadHistory].totalRecords;
export const getFaceswapActor = (state: StoreType) => state.actor[ActorModules.faceswapActor].data;
export const getFaceswapActorLoading = (state: StoreType) => state.actor[ActorModules.faceswapActor].isLoading;

export const getCloneVoiceDeleting = (state: StoreType) => state.actor[ActorModules.actorList].isDeleting;

export const getVoiceoverHistory = (state: StoreType) => state.actor[ActorModules.voiceoverHistory].data;
export const getVoiceoverHistoryLoading = (state: StoreType) => state.actor[ActorModules.voiceoverHistory].isLoading;
export const getVoiceoverHistoryHasMore = (state: StoreType) => state.actor[ActorModules.voiceoverHistory].hasMore;
export const getVoiceoverHistoryPageNumber = (state: StoreType) =>
  state.actor[ActorModules.voiceoverHistory].pageNumber;

export const getTextFromAudio = (state: StoreType) => state.actor[ActorModules.audioToTranslatedText].data;
export const getTextFromAudioLoading = (state: StoreType) => state.actor[ActorModules.audioToTranslatedText].isLoading;

export const getSpeech2Speech = (state: StoreType) => state.actor[ActorModules.speech2Speech];
export const getSpeechTranslation = (state: StoreType) => state.actor[ActorModules.speechTranslation];

export default actorReducer;
