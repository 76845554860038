export const SearchbarFlash = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.91 10.72H14.82V3.52002C14.82 1.84002 13.91 1.50002 12.8 2.76002L12 3.67002L5.23001 11.37C4.30001 12.42 4.69001 13.28 6.09001 13.28H9.18001V20.48C9.18001 22.16 10.09 22.5 11.2 21.24L12 20.33L18.77 12.63C19.7 11.58 19.31 10.72 17.91 10.72Z"
      fill="url(#paint0_linear_217_2501)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_217_2501"
        x1="7.85835"
        y1="4.61476"
        x2="20.0544"
        y2="16.0228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
