export const PricingImagesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99805 9.99976C8.82605 9.99976 9.49805 9.32776 9.49805 8.49976C9.49805 7.67176 8.82605 6.99976 7.99805 6.99976C7.17005 6.99976 6.49805 7.67176 6.49805 8.49976C6.49805 9.32776 7.17005 9.99976 7.99805 9.99976ZM17.9986 18.9998H6.55957L13.5646 13.1548C13.8106 12.9458 14.2566 12.9468 14.4976 13.1538L18.9986 16.9938V17.9998C18.9986 18.5518 18.5506 18.9998 17.9986 18.9998ZM5.99805 5.00024H17.998C18.55 5.00024 18.998 5.44824 18.998 6.00024V14.3642L15.795 11.6322C14.805 10.7902 13.256 10.7902 12.275 11.6262L4.99805 17.6982V6.00024C4.99805 5.44824 5.44605 5.00024 5.99805 5.00024ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"
      fill="#6F7074"
    />
    <mask id="mask0_0_324" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99805 9.99976C8.82605 9.99976 9.49805 9.32776 9.49805 8.49976C9.49805 7.67176 8.82605 6.99976 7.99805 6.99976C7.17005 6.99976 6.49805 7.67176 6.49805 8.49976C6.49805 9.32776 7.17005 9.99976 7.99805 9.99976ZM17.9986 18.9998H6.55957L13.5646 13.1548C13.8106 12.9458 14.2566 12.9468 14.4976 13.1538L18.9986 16.9938V17.9998C18.9986 18.5518 18.5506 18.9998 17.9986 18.9998ZM5.99805 5.00024H17.998C18.55 5.00024 18.998 5.44824 18.998 6.00024V14.3642L15.795 11.6322C14.805 10.7902 13.256 10.7902 12.275 11.6262L4.99805 17.6982V6.00024C4.99805 5.44824 5.44605 5.00024 5.99805 5.00024ZM18 3H6C4.346 3 3 4.346 3 6V18C3 19.654 4.346 21 6 21H18C19.654 21 21 19.654 21 18V6C21 4.346 19.654 3 18 3Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_0_324)">
      <rect width="24" height="24" fill="#6F7074" />
    </g>
  </svg>
);
