import styled from "styled-components";
import HomeSectionTabs from "./HomeSectionTabs";
import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "../../../../components/Icons/ArrowRight";

export interface Props {
  title: string;
  tabs: { value: string; title: string }[];
  activeTab: string;
  content: React.ReactElement;
  link: {
    text: string;
    to: string;
  };
  handleActiveTab: (props: string) => void;
}

const HomeSection = ({ title, tabs, content, activeTab, link, handleActiveTab }: Props) => {
  return (
    <Wrapper>
      <TopWrapper>
        <Title>{title}</Title>
        <HomeSectionTabs active={activeTab} data={tabs} handleActive={handleActiveTab} />
        <LinkWrapper to={link.to}>
          <span>{link.text}</span>
          <ArrowRight />
        </LinkWrapper>
      </TopWrapper>
      <ContentWrapper>{content}</ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
`;
const Title = styled.div`
  font-family: Mulish;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1px;
  text-align: left;
  color: ${({ theme }) => theme.homeContentCardText};
`;

const ContentWrapper = styled.div``;

const LinkWrapper = styled(Link)`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.activeMenu};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  svg {
    path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

export default HomeSection;
