import styled from "styled-components";
import { Link } from "react-router-dom";
import { ChangeEvent, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "./Tabs";
import SideBarLayout from "./layout/SideBarLayout";
import Select from "../../../components/Select/Select";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import {
  artStyles,
  ratios,
  resolutions,
  artists,
  illustrations,
  stylesArr,
  motions,
  ratiosText2Video,
  resolutionsText2Video,
  imagesStoryRatios,
} from "../../../mocks/aiImages";
import {
  getDownloadImagesLoading,
  getGeneratedImages,
  getGeneratedImagesLoading,
  getGeneratedVideosLoading,
  getUploadFaceSwapImage,
  getUploadFaceSwapImageLoading,
  getUploadPersonalizeImage,
  getUploadPersonalizeImageLoading,
  getUploadPoseImage,
  getUploadPoseImageLoading,
  getUploadStoryReferenceImage,
  getUploadStoryReferenceImageLoading,
  getUpscaleImageLoading,
  getUpscaleVideoLoading,
} from "../../../redux/reducers/imagesReducer";
import { AIImagesContext } from "../../../App";
import { pages } from "../../../lib/routeUtils";
import { ImportIcon } from "../../../components/Icons/ImportIcon";
import { getProfile } from "../../../redux/reducers/profileReducer";
import {
  clearImageFromModule,
  downloadAllImagesServer,
  uploadImageToVisualGalleryServer,
} from "../../../redux/actions/imagesActions";
import {
  ArrowLeft,
  ArrowRight,
  CrossIcon,
  InfoGreyIcon,
  PopupGallery,
  PricingIcon,
} from "../../../components/Icons/Icons";
import { AIImagesSidebarTabs, AiImagesSidebarTabs } from "../../../hooks/useAiImages";
import Switch from "../../../components/Switch/Switch";
import Textfield from "../../../components/Textfield/Textfield";
import { AudioIcon } from "../../../components/Icons/AudioIcon";
import { toast } from "react-toastify";
import { ImagesModules } from "../../../types/image";
import Loader from "../../../components/Loader/Loader";
import Tooltip from "../../../components/Tooltip/Tooltip";

const possibleImagesOutputsValues = [1, 2, 3, 4];
const storyImagesScenes = ["Scene 1", "Scene 2", "Scene 3", "Scene 4", "Scene 5", "Scene 6"];

const storyVideosScenes = ["Scene 1", "Scene 2", "Scene 3", "Scene 4"];

const AIImagesRightSidebar = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const { isPhotorealisticEnabled, isUltraHDAIImageEnabled } = useSelector(getProfile);
  const {
    ratio,
    setRatio,
    resolution,
    setResolution,
    motion,
    setMotion,
    activeTab,
    setActiveTab,
    ratioText2Video,
    resolutionText2Video,
    setRatioText2Video,
    setResolutionText2Video,
    handleRatioChange,
    handleResolutionChange,
    changeIsCreateStoryMode,
    isCreateStoryMode,
    outputsAmount,
    changeOutputsAmount,
    imagesStoryTextValues,
    changeImagesStoryTextValues,
    isPoseControlTogle,
    handlePoseControlTogleClick,
    isPersonalizeTogle,
    handlePersonalizeTogleClick,
    videosStoryTextValues,
    changeVideosStoryTextValues,
    lipsyncFile,
    updateLipsyncFile,
    uploadedImage,
  } = useContext(AIImagesContext);

  const MOCK_TRUE = true;
  const MAX_SIZE = 100 * 1024 * 1024;
  const ACCEPTED_FORMATS_IMAGE = "image/png, image/jpg, image/jpeg, application/pdf";
  const ACCEPTED_FORMATS_TEXT_TO_VIDEO = "image/png, image/jpg, image/jpeg";
  const ACCEPTED_FORMATS_AUDIO = "audio/mp3, audio/wav, audio/mpeg";

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [referenceFile, setReferenceFile] = useState<File | null>(null);
  const [personalizeFile, setPersonalizeFile] = useState<File | null>(null);
  const [faceSwapFile, setFaceSwapFile] = useState<File | null>(null);
  const [poseFile, setPoseFile] = useState<File | null>(null);

  const [imagePersonalizeInputKey, setImagePersonalizeInputKey] = useState(new Date().toString());
  const [videoPersonalizeInputKey, setVideoPersonalizeInputKey] = useState(new Date().toString());

  const [imageReferenceInputKey, setImageReferenceInputKey] = useState(new Date().toString());
  const [videoReferenceInputKey, setVideoReferenceInputKey] = useState(new Date().toString());

  const [videoFaceSwapInputKey, setVideoFaceSwapInputKey] = useState(new Date().toString());

  const [imagePoseInputKey, setImagePoseInputKey] = useState(new Date().toString());
  const [videoPoseInputKey, setVideoPoseInputKey] = useState(new Date().toString());

  const [videoLipsyncInoutKey, setVideoLipsyncInoutKey] = useState(new Date().toString());

  const onPoseControlTogleClick = (e: any) => {
    handlePoseControlTogleClick(e.target.checked);
    changeIsCreateStoryMode(false);
  };

  const onPersonalizeTogleClick = (e: any) => {
    handlePersonalizeTogleClick(e.target.checked);
    changeIsCreateStoryMode(false);
  };

  const handleChangeIsCreateStoryMode = (e: any) => {
    changeIsCreateStoryMode(e.target.checked);
    handlePoseControlTogleClick(false);
    handlePersonalizeTogleClick(false);
  };

  const isFreeUser = profile.aiImageFreeUser;
  const isLoading = useSelector(
    activeTab === AIImagesSidebarTabs.Text2Image ? getGeneratedImagesLoading : getGeneratedVideosLoading,
  );
  const isDownloaImagesLoading = useSelector(getDownloadImagesLoading);
  const generetedImages = useSelector(getGeneratedImages);
  const upscaleLoading = useSelector(
    activeTab === AIImagesSidebarTabs.Text2Image ? getUpscaleImageLoading : getUpscaleVideoLoading,
  );
  const imagesDownloadAll: string[] = generetedImages.map((item) => item.path);
  const isImageToVideo = activeTab === AIImagesSidebarTabs.Text2Video;

  const isStoryReferenceLoading = useSelector(getUploadStoryReferenceImageLoading);
  const storyReferenceImage = useSelector(getUploadStoryReferenceImage);
  const isPersonalizeLoading = useSelector(getUploadPersonalizeImageLoading);
  const personalizeImage = useSelector(getUploadPersonalizeImage);
  const faceSwapImage = useSelector(getUploadFaceSwapImage);
  const isFaceSwapImageLoading = useSelector(getUploadFaceSwapImageLoading);
  const isPoseLoading = useSelector(getUploadPoseImageLoading);
  const poseImage = useSelector(getUploadPoseImage);

  const handleDownloadClick = () => {
    dispatch(
      downloadAllImagesServer({
        images: imagesDownloadAll,
      }),
    );
  };

  const handleActive = (tabId: AIImagesSidebarTabs | string) => {
    setActiveTab(tabId as AIImagesSidebarTabs);
  };

  const isImageStory = activeTab === AIImagesSidebarTabs.Text2Image && isCreateStoryMode;

  const modifyRatios = isImageStory
    ? {
        ...imagesStoryRatios,
        data: imagesStoryRatios.data.filter((ratio) => ratio.resolution === resolution.value),
      }
    : {
        ...ratios,
        data: ratios.data.filter((ratio) => ratio.resolution === resolution.value),
      };

  const clearImage = (
    module:
      | ImagesModules.storyReferenceImage
      | ImagesModules.personalizeImage
      | ImagesModules.faceSwapImage
      | ImagesModules.poseImage,
  ) => {
    switch (module) {
      case ImagesModules.personalizeImage: {
        setPersonalizeFile(null);
        setVideoPersonalizeInputKey(new Date().toString());
        setImagePersonalizeInputKey(new Date().toString());
        break;
      }
      case ImagesModules.storyReferenceImage: {
        setReferenceFile(null);
        setVideoReferenceInputKey(new Date().toString());
        setImageReferenceInputKey(new Date().toString());
        break;
      }
      case ImagesModules.faceSwapImage: {
        setFaceSwapFile(null);
        setVideoFaceSwapInputKey(new Date().toString());
        break;
      }
      case ImagesModules.poseImage: {
        setPoseFile(null);
        setVideoPoseInputKey(new Date().toString());
        setImagePoseInputKey(new Date().toString());
        break;
      }
      default:
        return;
    }
    dispatch(clearImageFromModule(module));
  };

  const clearLipsync = () => {
    setVideoLipsyncInoutKey(new Date().toString());
    updateLipsyncFile(null);
  };

  const handleUploadImageReference = (
    module:
      | ImagesModules.storyReferenceImage
      | ImagesModules.personalizeImage
      | ImagesModules.faceSwapImage
      | ImagesModules.poseImage,
    { target }: any,
  ) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const allowedTypes = MOCK_TRUE ? /image\/(png|jpeg|jpg)|application\/pdf/ : /image\/(png|jpeg|jpg)/;

    if (uploadedFile && uploadedFile.size > (MOCK_TRUE ? MAX_SIZE : MAX_SIZE / 10)) {
      toast.error(`Exceeds maximum image resolution of ${MOCK_TRUE ? "100" : "10"} MB`);
    } else if (!uploadedFile.type.match(allowedTypes)) {
      toast.error(`Invalid file type: ${uploadedFile.type}`);
    } else {
      const formData = new FormData();
      formData.append("Status", "1");
      formData.append("FileName", uploadedFile.name);
      formData.append("File", uploadedFile);

      dispatch(uploadImageToVisualGalleryServer({ data: formData, module }));

      switch (module) {
        case ImagesModules.storyReferenceImage: {
          return setReferenceFile(uploadedFile);
        }
        case ImagesModules.personalizeImage: {
          return setPersonalizeFile(uploadedFile);
        }
        case ImagesModules.faceSwapImage: {
          return setFaceSwapFile(uploadedFile);
        }
        case ImagesModules.poseImage: {
          return setPoseFile(uploadedFile);
        }
        default:
          return;
      }
    }
  };

  const handleLipsyncFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];

    if (!uploadedFile) return;

    updateLipsyncFile(uploadedFile);
  };

  return (
    <>
      <SideBarLayout width={345} active={sidebarVisible}>
        <Container>
          <SettingsTitle>Settings</SettingsTitle>
          <TabsRowWrapper>
            <p>Type</p>
            <TabsWrapper>
              <Tabs
                data={AiImagesSidebarTabs}
                active={activeTab}
                handleActive={handleActive}
                disabled={isLoading || upscaleLoading}
              />
            </TabsWrapper>
          </TabsRowWrapper>
          <SettingsWrapper>
            {isImageToVideo ? (
              <>
                <SelectWrapper>
                  <Select
                    value={ratioText2Video.value}
                    onChange={(value) =>
                      handleRatioChange({ newValue: value, ratios: ratiosText2Video, setRatio: setRatioText2Video })
                    }
                    optionsList={ratiosText2Video}
                    defaultValueText={ratioText2Video.value}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper>
                {/* <SelectWrapper>
                  <Select
                    value={resolutionText2Video.value}
                    onChange={(value) => {
                      handleResolutionChange({
                        newValue: value,
                        resolutions: resolutionsText2Video,
                        ratios: ratiosText2Video,
                        setRatio: setRatioText2Video,
                        setResolution: setResolutionText2Video,
                      });
                    }}
                    optionsList={resolutionsText2Video}
                    defaultValueText={resolutionText2Video.value}
                    secondaryLabel={ratioText2Video.resolutionLabel}
                    variant="popup"
                    // disabled={isLoading || !isUltraHDAIImageEnabled || isFreeUser}
                    disabled={true}
                  />
                </SelectWrapper> */}
                <SelectWrapper>
                  <Select
                    value={motion}
                    onChange={setMotion}
                    optionsList={motions}
                    defaultValueText={motion}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper>
                <>
                  <SwitchWrapper>
                    <Description>Create story</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Create a story by generating consistent images and videos." />
                    </TooltipWrapper>
                    <Switch
                      onChange={handleChangeIsCreateStoryMode}
                      checked={isCreateStoryMode}
                      disabled={!!uploadedImage || profile.aiImageFreeUser}
                    />
                  </SwitchWrapper>
                  {isCreateStoryMode && (
                    <>
                      <InputWrapper>
                        {storyVideosScenes.map((placeholder, index) => (
                          <Textfield
                            key={placeholder}
                            placeholder={placeholder}
                            value={videosStoryTextValues[index]}
                            onChange={(event) => {
                              const value = event.target.value as string;
                              const newStory = [...videosStoryTextValues];
                              newStory[index] = value;
                              changeVideosStoryTextValues(newStory);
                            }}
                          />
                        ))}
                      </InputWrapper>
                      <SwitchWrapper>
                        <Description>Reference image for the character’s story</Description>
                        <TooltipWrapper>
                          <InfoGreyIcon />
                          <Tooltip
                            text="Upload a source photo with a single person to generate specific characters for your stories with the same face."
                            position="left"
                          />
                        </TooltipWrapper>
                      </SwitchWrapper>
                      <UploadWrapper>
                        {isStoryReferenceLoading ? (
                          <Loader size={10} />
                        ) : storyReferenceImage ? (
                          <InputImage src={storyReferenceImage} alt="story image" />
                        ) : (
                          <PopupGallery />
                        )}
                        <UploadInfoWrapper>
                          <UploadText>{storyReferenceImage ? referenceFile?.name : "Upload an image"}</UploadText>
                          <SecondaryText>
                            {MOCK_TRUE
                              ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                              : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                          </SecondaryText>
                        </UploadInfoWrapper>
                        <input
                          id="imageUpload"
                          name="imageUpload"
                          type="file"
                          accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                          title=" "
                          key={videoReferenceInputKey}
                          onChange={handleUploadImageReference.bind(null, ImagesModules.storyReferenceImage)}
                        />
                        {storyReferenceImage && (
                          <CrossIconWrapper onClick={() => clearImage(ImagesModules.storyReferenceImage)}>
                            <CrossIcon />
                          </CrossIconWrapper>
                        )}
                      </UploadWrapper>
                    </>
                  )}
                </>
                <>
                  <SwitchWrapper>
                    <Description>Pose control</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Copy composition, outlines, body poses, and patterns from a source image for generations." />
                    </TooltipWrapper>
                    <Switch
                      onChange={onPoseControlTogleClick}
                      checked={isPoseControlTogle}
                      disabled={!!uploadedImage}
                    />
                  </SwitchWrapper>
                  {isPoseControlTogle && (
                    <UploadWrapper>
                      {isPoseLoading ? (
                        <Loader size={10} />
                      ) : poseImage ? (
                        <InputImage src={poseImage} alt="pose image" />
                      ) : (
                        <PopupGallery />
                      )}
                      <UploadInfoWrapper>
                        <UploadText>{poseImage ? poseFile?.name : "Upload an image"}</UploadText>
                        <SecondaryText>
                          {MOCK_TRUE
                            ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                            : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                        </SecondaryText>
                      </UploadInfoWrapper>
                      <input
                        id="imageUpload"
                        name="imageUpload"
                        type="file"
                        accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                        title=" "
                        key={videoPoseInputKey}
                        onChange={handleUploadImageReference.bind(null, ImagesModules.poseImage)}
                      />
                      {poseImage && (
                        <CrossIconWrapper onClick={() => clearImage(ImagesModules.poseImage)}>
                          <CrossIcon />
                        </CrossIconWrapper>
                      )}
                    </UploadWrapper>
                  )}
                </>
                <>
                  <SwitchWrapper>
                    <Description>Personalize</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Upload a source photo with a single person to generate characters with the same face." />
                    </TooltipWrapper>
                    <Switch
                      onChange={onPersonalizeTogleClick}
                      checked={isPersonalizeTogle}
                      disabled={!!uploadedImage}
                    />
                  </SwitchWrapper>
                  {isPersonalizeTogle && (
                    <UploadWrapper>
                      {isPersonalizeLoading ? (
                        <Loader size={10} />
                      ) : personalizeImage ? (
                        <InputImage src={personalizeImage} alt="personalize image" />
                      ) : (
                        <PopupGallery />
                      )}
                      <UploadInfoWrapper>
                        <UploadText>{personalizeImage ? personalizeFile?.name : "Upload an image"}</UploadText>
                        <SecondaryText>
                          {MOCK_TRUE
                            ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                            : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                        </SecondaryText>
                      </UploadInfoWrapper>
                      <input
                        id="imageUpload"
                        name="imageUpload"
                        type="file"
                        accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                        title=" "
                        key={videoPersonalizeInputKey}
                        onChange={handleUploadImageReference.bind(null, ImagesModules.personalizeImage)}
                      />
                      {personalizeImage && (
                        <CrossIconWrapper onClick={() => clearImage(ImagesModules.personalizeImage)}>
                          <CrossIcon />
                        </CrossIconWrapper>
                      )}
                    </UploadWrapper>
                  )}
                </>
                <>
                  <SwitchWrapper>
                    <Description>Face swap</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Upload a source photo with a single person and change face in already generated video." />
                    </TooltipWrapper>
                  </SwitchWrapper>
                  <UploadWrapper>
                    {isFaceSwapImageLoading ? (
                      <Loader size={10} />
                    ) : faceSwapImage ? (
                      <InputImage src={faceSwapImage} alt="face swap image" />
                    ) : (
                      <PopupGallery />
                    )}
                    <UploadInfoWrapper>
                      <UploadText>{faceSwapImage ? faceSwapFile?.name : "Upload an image"}</UploadText>
                      <SecondaryText>
                        {MOCK_TRUE
                          ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                          : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                      </SecondaryText>
                    </UploadInfoWrapper>
                    <input
                      id="imageUpload"
                      name="imageUpload"
                      type="file"
                      title=" "
                      key={videoFaceSwapInputKey}
                      accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                      onChange={handleUploadImageReference.bind(null, ImagesModules.faceSwapImage)}
                    />
                    {faceSwapImage && (
                      <CrossIconWrapper onClick={() => clearImage(ImagesModules.faceSwapImage)}>
                        <CrossIcon />
                      </CrossIconWrapper>
                    )}
                  </UploadWrapper>
                </>
                <>
                  <SwitchWrapper>
                    <Description>Lipsync</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Upload a source audio to integrate a face with your audio clips." />
                    </TooltipWrapper>
                  </SwitchWrapper>
                  <UploadWrapper>
                    <AudioIcon />
                    <UploadInfoWrapper>
                      <UploadText>{lipsyncFile?.name || "Upload an audio"}</UploadText>
                      <SecondaryText>File Supported: MP3. Maximum size: 10 MB</SecondaryText>
                    </UploadInfoWrapper>
                    <input
                      id="audioUpload"
                      name="audioUpload"
                      type="file"
                      title=" "
                      accept={ACCEPTED_FORMATS_AUDIO}
                      onChange={handleLipsyncFileUpload}
                    />
                    <CrossIconWrapper onClick={clearLipsync}>
                      <CrossIcon />
                    </CrossIconWrapper>
                  </UploadWrapper>
                </>
              </>
            ) : (
              <>
                <SelectWrapper>
                  <Select
                    value={ratio.value}
                    onChange={(value) =>
                      handleRatioChange({
                        newValue: value,
                        ratios: isImageStory ? imagesStoryRatios : ratios,
                        setRatio,
                      })
                    }
                    optionsList={modifyRatios}
                    defaultValueText={ratio.value}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper>
                {/* <SelectWrapper>
                  <Select
                    value={resolution.value}
                    onChange={(value) => {
                      handleResolutionChange({
                        newValue: value,
                        resolutions,
                        ratios,
                        setRatio,
                        setResolution,
                      });
                    }}
                    optionsList={resolutions}
                    defaultValueText={resolution.value}
                    secondaryLabel={ratio.resolutionLabel}
                    variant="popup"
                    // disabled={isLoading || !isUltraHDAIImageEnabled || isFreeUser}
                    disabled={true}
                  />
                </SelectWrapper> */}
                <OutputsWrapper>
                  <p>Outputs</p>
                  <Outputs>
                    {possibleImagesOutputsValues.map((value) => (
                      <OutputButton
                        key={value}
                        disabled={isCreateStoryMode || profile.aiImageFreeUser}
                        active={value === outputsAmount && !profile.aiImageFreeUser}
                        onClick={() => changeOutputsAmount(value)}
                      >
                        {value}
                      </OutputButton>
                    ))}
                  </Outputs>
                </OutputsWrapper>
                <>
                  <SwitchWrapper>
                    <Description>Create story</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Create a story by generating consistent images and videos." className="tooltip" />
                    </TooltipWrapper>
                    <Switch
                      checked={isCreateStoryMode}
                      onChange={handleChangeIsCreateStoryMode}
                      disabled={!!uploadedImage || profile.aiImageFreeUser}
                    />
                  </SwitchWrapper>
                  {isCreateStoryMode && (
                    <>
                      <InputWrapper>
                        {storyImagesScenes.map((placeholder, index) => (
                          <Textfield
                            key={placeholder}
                            placeholder={placeholder}
                            value={imagesStoryTextValues[index]}
                            onChange={(event) => {
                              const value = event.target.value as string;
                              const newStory = [...imagesStoryTextValues];
                              newStory[index] = value;
                              changeImagesStoryTextValues(newStory);
                            }}
                          />
                        ))}
                      </InputWrapper>
                      <SwitchWrapper>
                        <Description>Reference image for the character’s story</Description>
                        <TooltipWrapper>
                          <InfoGreyIcon />
                          <Tooltip
                            text="Upload a source photo with a single person to generate specific characters for your stories with the same face."
                            position="left"
                          />
                        </TooltipWrapper>
                      </SwitchWrapper>
                      <UploadWrapper>
                        {isStoryReferenceLoading ? (
                          <Loader size={10} />
                        ) : storyReferenceImage ? (
                          <InputImage src={storyReferenceImage} alt="story image" />
                        ) : (
                          <PopupGallery />
                        )}
                        <UploadInfoWrapper>
                          <UploadText>{storyReferenceImage ? referenceFile?.name : "Upload an image"}</UploadText>
                          <SecondaryText>
                            {MOCK_TRUE
                              ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                              : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                          </SecondaryText>
                        </UploadInfoWrapper>
                        <input
                          id="imageUpload"
                          name="imageUpload"
                          type="file"
                          accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                          title=" "
                          key={imageReferenceInputKey}
                          onChange={handleUploadImageReference.bind(null, ImagesModules.storyReferenceImage)}
                        />
                        {storyReferenceImage && (
                          <CrossIconWrapper onClick={() => clearImage(ImagesModules.storyReferenceImage)}>
                            <CrossIcon />
                          </CrossIconWrapper>
                        )}
                      </UploadWrapper>
                    </>
                  )}
                </>
                <>
                  <SwitchWrapper>
                    <Description>Personalize</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Upload a source photo with a single person to generate characters with the same face." />
                    </TooltipWrapper>
                    <Switch
                      onChange={onPersonalizeTogleClick}
                      checked={isPersonalizeTogle}
                      disabled={!!uploadedImage}
                    />
                  </SwitchWrapper>
                  {isPersonalizeTogle && (
                    <UploadWrapper>
                      {isPersonalizeLoading ? (
                        <Loader size={10} />
                      ) : personalizeImage ? (
                        <InputImage src={personalizeImage} alt="personalize image" />
                      ) : (
                        <PopupGallery />
                      )}
                      <UploadInfoWrapper>
                        <UploadText>{personalizeImage ? personalizeFile?.name : "Upload an image"}</UploadText>
                        <SecondaryText>
                          {MOCK_TRUE
                            ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                            : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                        </SecondaryText>
                      </UploadInfoWrapper>
                      <input
                        id="imageUpload"
                        name="imageUpload"
                        type="file"
                        accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                        title=" "
                        key={imagePersonalizeInputKey}
                        onChange={handleUploadImageReference.bind(null, ImagesModules.personalizeImage)}
                      />
                      {personalizeImage && (
                        <CrossIconWrapper onClick={() => clearImage(ImagesModules.personalizeImage)}>
                          <CrossIcon />
                        </CrossIconWrapper>
                      )}
                    </UploadWrapper>
                  )}
                </>
                <>
                  <SwitchWrapper>
                    <Description>Pose control</Description>
                    <TooltipWrapper>
                      <InfoGreyIcon />
                      <Tooltip text="Copy composition, outlines, body poses, and patterns from a source image for generations." />
                    </TooltipWrapper>
                    <Switch
                      onChange={onPoseControlTogleClick}
                      checked={isPoseControlTogle}
                      disabled={!!uploadedImage}
                    />
                  </SwitchWrapper>
                  {isPoseControlTogle && (
                    <UploadWrapper>
                      {isPoseLoading ? (
                        <Loader size={10} />
                      ) : poseImage ? (
                        <InputImage src={poseImage} alt="pose image" />
                      ) : (
                        <PopupGallery />
                      )}
                      <UploadInfoWrapper>
                        <UploadText>{poseImage ? poseFile?.name : "Upload an image"}</UploadText>
                        <SecondaryText>
                          {MOCK_TRUE
                            ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                            : "File Supported: PNG, JPG. Maximum size: 10 MB"}
                        </SecondaryText>
                      </UploadInfoWrapper>
                      <input
                        id="imageUpload"
                        name="imageUpload"
                        type="file"
                        accept={MOCK_TRUE ? ACCEPTED_FORMATS_IMAGE : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
                        title=" "
                        key={imagePoseInputKey}
                        onChange={handleUploadImageReference.bind(null, ImagesModules.poseImage)}
                      />
                      {poseImage && (
                        <CrossIconWrapper onClick={() => clearImage(ImagesModules.poseImage)}>
                          <CrossIcon />
                        </CrossIconWrapper>
                      )}
                    </UploadWrapper>
                  )}
                </>
                {/* <SelectWrapper>
                  <Select
                    value={artist}
                    onChange={setArtist}
                    optionsList={artists}
                    defaultValueText={artist}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper> */}
                {/* <SelectWrapper>
                  <Select
                    value={illustration}
                    onChange={setIllustration}
                    optionsList={illustrations}
                    defaultValueText={illustration}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper> */}
                {/* <SelectWrapper>
                  <Select
                    value={artStyle}
                    onChange={setArtStyle}
                    optionsList={artStyles}
                    defaultValueText={artStyle}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper> */}
                {/* <SelectWrapper>
                  <Select
                    value={style}
                    onChange={setStyle}
                    optionsList={stylesArr}
                    defaultValueText={style}
                    variant="popup"
                    disabled={isLoading || isFreeUser || upscaleLoading}
                  />
                </SelectWrapper> */}
                {/* <CheckboxWrapper>
                  <Checkbox
                    key="123"
                    label="Enable Photorealistic Model"
                    checked={photorealistic}
                    name="photorealisticModelCheckbox"
                    onChange={(e) => setPhotorealistic(e.target.checked)}
                    disabled={isLoading || !isPhotorealisticEnabled || isFreeUser || upscaleLoading}
                  />
                </CheckboxWrapper> */}
                <ButtonWrapper>
                  <Button
                    disabled={
                      isDownloaImagesLoading ||
                      isLoading ||
                      imagesDownloadAll.length == 0 ||
                      isFreeUser ||
                      upscaleLoading
                    }
                    icon={<ImportIcon />}
                    buttonTheme={ButtonThemes.Outline}
                    text={"Download all images"}
                    onClick={handleDownloadClick}
                  />
                </ButtonWrapper>
              </>
            )}
          </SettingsWrapper>

          {/* {!profile.hidePricing && (
            <Link to={`${pages.pricingSubscriptions()}?plan=image`} style={{ marginTop: "auto" }}>
              <PricingButton>
                <PricingIcon />
                <span>Pricing</span>
              </PricingButton>
            </Link>
          )} */}
        </Container>
        <SidebarVisibleButtonWrapper sidebarVisible={sidebarVisible}>
          <Button
            icon={sidebarVisible ? <ArrowRight /> : <ArrowLeft />}
            onClick={() => setSidebarVisible((prev) => !prev)}
          />
        </SidebarVisibleButtonWrapper>
      </SideBarLayout>
    </>
  );
};

const Container = styled.div`
  margin-left: 20px;
  width: 345px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabsWrapper = styled.div`
  & > div {
    background: #191b1f;
    border: 1px solid #0063b4;
    border-radius: 60px;
    box-shadow: none;
    width: 203px;
    align-items: center;
  }
  button {
    box-shadow: none;

    span {
      font-family: Mulish;
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
    }
    &.not-active {
      opacity: 1;
    }
    &.not-active span {
      font-family: Mulish;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      color: #6f7074;
      opacity: 1;
      margin: 0px 6px 0px 0px;
    }
  }
`;

const TabsRowWrapper = styled.div`
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-right: 11px;
  padding-bottom: 12px;

  & div {
    display: flex;
    align-items: center;
  }
  & div > button {
    height: 20px;
    background: #0063b4;
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  padding-right: 11px;
  gap: 0px;

  label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background: rgba(25, 27, 31, 0.06);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
    height: 10px;
  }
`;

const SettingsTitle = styled.div`
  margin: -15px 0px 21px 0px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2;
  color: #ffffff;
`;

const SelectWrapper = styled.div`
  margin-bottom: 7px;

  p {
    color: #ffffff;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    height: 100%;

    & > div:nth-child(2) {
      position: relative;
      width: 203px;
      height: 34px;
      box-shadow: none;
      background: #292937;
      border-radius: 60px;
    }

    & > div:nth-child(3) {
      width: 203px;
      height: auto;
      max-height: 150px;
      border-radius: 12px;
      position: absolute;
      right: 0px;
      top: 34px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      z-index: 25;
    }

    & svg > path {
      fill: #ffffff;
    }
  }
`;

const CheckboxWrapper = styled.div`
  margin: 0px 0px 20px 0px;
  span {
    background: none;
    box-shadow: none;
    border: 1px solid #a3a4a5;
  }

  p {
    color: #ffffff;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #a3a4a5;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0px 10px 0px;
  animation-fill-mode: none;
  margin-top: auto;

  button {
    width: 232px;
    height: 44px;
    display: flex;
    column-gap: 8px;
    box-shadow: none;
    background: #292937;
    border: 1px solid #0063b4;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    opacity: 1;
  }
  span {
    color: #ffffff;
  }
  & svg > path {
    fill: #ffffff;
  }
`;

const SidebarVisibleButtonWrapper = styled.div<{ sidebarVisible: boolean }>`
  position: absolute;
  top: 50%;
  box-shadow: none;
  visibility: visible;
  left: -16px;

  ${({ sidebarVisible }) =>
    !sidebarVisible &&
    `
    right: 0;
  `}

  &.open button {
    border-radius: 12px 0px 0px 12px;
    width: 20px;
  }
  button {
    opacity: 1;
    height: 32px;
    width: 32px;
    border-radius: 12px;
    border: 2px solid #f0f0f3;
    box-shadow: none;
    background: #0063b4;
  }
`;

const PricingButton = styled.div`
  border-radius: 16px;
  background: #191b1f;
  padding: 11px 8px 12px 8px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2);
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: center;

  & span {
    font-size: 12px;
    font-weight: 500;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  margin-bottom: 20px;

  & .tooltip {
    background-color: #f0f0f3;
    color: #191b1f !important;
  }

  label {
    width: 44px;
    min-width: 44px;
    height: 22px;

    span {
      box-shadow: none;
    }

    span:before {
      width: 18px;
      height: 18px;
    }

    input:checked + span {
      background: #0063b4;
    }

    input:checked + span:before {
      -webkit-transform: translateX(22px);
      -moz-transform: translateX(22px);
      transform: translateX(22px);
    }
  }
`;

const Description = styled.div`
  color: #ffffff;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
`;

const TooltipWrapper = styled.div`
  position: relative;
  margin-left: 8px;
  margin-right: auto;
  height: 16px;

  & > div {
    width: 150px;
    box-shadow: none;
    span {
      text-transform: none;
    }
  }

  :hover > div {
    opacity: 0.8;
    visibility: visible;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;

  & div {
    background-color: #292937;
    border-radius: 4px;
    border: 1px solid #3b3d47;

    & input {
      background-color: #292937;
      box-shadow: none;
      padding: 12px 16px;
      width: 100%;
      font-family: "Mulish", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2;
      color: #ffffff;

      &::placeholder {
        color: #ababb9;
      }
    }
  }
`;

const UploadWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 43px;
  padding: 10px;
  gap: 12px;
  border-radius: 20px;
  background: #292937;
  cursor: pointer;
  margin-bottom: 20px;

  input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 43px;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }

  & svg {
    width: 24px;
    height: 24px;
  }
`;

const CrossIconWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 11;

  & svg path {
    stroke: #fff;
  }
`;

const InputImage = styled.img`
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 4px;
`;

const UploadInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const UploadText = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  color: #ffffff;
`;

const SecondaryText = styled.div`
  font-family: Mulish;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3;
  color: #d2d2d9;
`;

const OutputsWrapper = styled.div`
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding-bottom: 30px;
  margin-top: 15px;

  & div {
    display: flex;
    align-items: center;
  }
  & div > button {
  }
`;

const Outputs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 203px;
  margin-top: 15px;
  gap: 13px;
`;

const OutputButton = styled.button<{ active: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 12px;
  background: ${({ active }) => (active ? "linear-gradient(142.13deg, #0063B4 16.78%, #009AF7 85.53%)" : "#292937")};
  color: #ffffff;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
`;

export default AIImagesRightSidebar;
