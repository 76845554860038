import React from "react";
import styled from "styled-components";
import CardLoader from "./CardLoader";

const CardsLoader = () => {
  return (
    <>
      <CardLoader />
      <CardLoader />
      <CardLoader />
    </>
  );
};

export default CardsLoader;
