import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getProjectAudio } from "../redux/reducers/projectReducer";
import { Paragraphs, Zone } from "../types/project";
import {
  checkIfZoneCached,
  checkIfZoneMatchNoAuthor,
  checkIfZonesMatch,
  getAllZones,
  getAudioList,
  isEmptyZone,
} from "../lib/editorUtils";
import { toast } from "react-toastify";
import useAudioPlayer from "./useAudioPlayer";
import { SentryErrors, sentryErrors } from "../lib/sentry";

const BULK_TO_GENERATE_SIZE = 3;

interface Props {
  paragraphs: Paragraphs[];
  selectedZone?: Zone;
  generateZoneByIndex: (zoneIndex: number) => void;
  setIsPlayScript?: (isVideoPreview: boolean) => void;
  onEndCallback?: () => void;
  onEveryAudioEnd?: (index: number) => void;
  disablePlayingOnceSelectedZoneChanges?: boolean;
}

const useVoiceGeneration = ({
  paragraphs,
  selectedZone,
  generateZoneByIndex,
  setIsPlayScript,
  onEveryAudioEnd,
  onEndCallback,
  disablePlayingOnceSelectedZoneChanges = true,
}: Props) => {
  const index = useRef(-1);
  const { cachedZonesAudio, loadingZonesAudio } = useSelector(getProjectAudio);
  const loadingZonesAudioRef = useRef<any>([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [memoizedAudioList, setMemoizedAudioList] = useState<(string | undefined)[]>([]);
  const [memoizedSelectedZone, setMemoizedSelectedZone] = useState<Zone | null>(null);

  const zones = getAllZones(paragraphs);
  const audioList = getAudioList(zones, cachedZonesAudio);

  const showVideoPreview = (isPlayScript: boolean) => {
    if (setIsPlayScript) {
      setIsPlayScript(isPlayScript);
    }
  };

  useEffect(() => {
    loadingZonesAudioRef.current = loadingZonesAudio;
  }, [loadingZonesAudio]);

  const allZones = getAllZones(paragraphs);

  const audioLoading = allZones[index.current] && checkIfZoneCached(allZones[index.current], loadingZonesAudio);

  useEffect(() => {
    if (!selectedZone) return;
    if (!memoizedSelectedZone) {
      setMemoizedSelectedZone(selectedZone);
      return;
    }
    if (!checkIfZonesMatch(memoizedSelectedZone, selectedZone)) {
      setMemoizedSelectedZone(selectedZone);
      return;
    }
  }, [selectedZone]);

  useEffect(() => {
    const amountOfUnmatches = audioList.filter((el, index) => {
      return el !== memoizedAudioList[index];
    });

    const isPerfetchMatch = audioList.length === memoizedAudioList.length && amountOfUnmatches.length === 0;
    if (isPerfetchMatch) return;
    setMemoizedAudioList(audioList);
  }, [audioList, cachedZonesAudio]);

  const {
    audioPlayer,
    currentTime,
    duration,
    seekValue,
    playing,
    onPlaying,
    isFetching,
    setPlaying,
    startFetchingLoader,
    handleProgressBarChange,
    onLoadStart,
    onLoadedMetadata,
  } = useAudioPlayer();

  useEffect(() => {
    if (disablePlayingOnceSelectedZoneChanges) {
      if (playing || isButtonClicked) {
        setIsButtonClicked(false);
        setPlaying(false);
        showVideoPreview(false);
      }
    }
  }, [memoizedSelectedZone]);

  const play = () => {
    if (!selectedZone) return;
    const allZones = getAllZones(paragraphs);
    const currentZoneIndex = allZones.findIndex((zone) => checkIfZonesMatch(zone, selectedZone));

    generateNextBunchOfZones(currentZoneIndex);
  };

  const pause = () => {
    setPlaying(false);
  };

  const handlePlayClick = () => {
    if (!playing) {
      let finalIndex = index.current;
      let allZones = getAllZones(paragraphs);
      if (selectedZone) {
        const currentZoneIndex = allZones.findIndex((zone) => checkIfZonesMatch(zone, selectedZone));
        finalIndex = currentZoneIndex;
      }
      index.current = finalIndex;
      play();
      setIsButtonClicked(true);
      showVideoPreview(true);
    } else {
      pause();
      setIsButtonClicked(false);
      showVideoPreview(false);
      audioPlayer?.current?.removeEventListener("ended", handleEnd);
    }
  };

  const isEmptyZone = (zone: Zone) => !zone?.text;

  function handleEnd() {
    const isLastAudio = index.current === memoizedAudioList.length - 1;
    let allZones = getAllZones(paragraphs);
    onEveryAudioEnd?.(index.current);
    if (isLastAudio) {
      onEndCallback?.();
      setIsButtonClicked(false);
      setPlaying(false);
      showVideoPreview(false);
    } else {
      let next = index.current + 1;
      while (isEmptyZone(allZones[next])) {
        if (next === memoizedAudioList.length - 1) {
          setIsButtonClicked(false);
          setPlaying(false);
          showVideoPreview(false);
          return;
        }
        next++;
      }
      const nextValidAudio = memoizedAudioList[next];
      if (nextValidAudio) {
        audioPlayer.current.src = nextValidAudio;
        index.current = next;
        setPlaying(true);
        audioPlayer.current.play();
        showVideoPreview(true);
        generateNextBunchOfZones(next);
      } else {
        setPlaying(false);
        showVideoPreview(false);
        generateNextBunchOfZones(next);

        // here we need to generate next 5 zones
        index.current = next;
      }
    }
  }

  const amount = 3;

  const generateNextBunchOfZones = (startIndex: number) => {
    let allZones = getAllZones(paragraphs);

    // 1. check if such zones exist next === memoizedAudioList.length - 1
    // 2. check if such zones are not loading (will be done inside of the generateZoneByIndex fn)
    // 3. check if such zones are not empty
    let current = startIndex;
    let endIndex = startIndex + amount;
    while (current !== endIndex) {
      const currentZone = allZones[current];
      const zoneExists = !!currentZone;
      const zoneIsEmpty = isEmptyZone(currentZone);
      const zoneIsLoading = !!checkIfZoneCached(currentZone, loadingZonesAudioRef.current);
      if (!zoneExists) {
        // quit
        current = endIndex;
      } else if (zoneIsEmpty) {
        // zone is empty -> move to next zone
        endIndex++;
        current++;
      } else if (zoneIsLoading) {
        // zone is already loading - let's just not touch it
        current++;
      } else {
        generateZoneByIndex(current);
        current++;
      }
    }
  };

  const handleResetClick = () => {
    setPlaying(false);
    audioPlayer?.current?.removeEventListener("ended", handleEnd);
  };

  const handleBackClick = () => {
    const finalIndex = index.current - 1;

    if (finalIndex < 0) {
      return;
    }

    setPlaying(false);

    const isAudioReady =
      memoizedAudioList.slice(finalIndex).filter((audio) => !audio).length === 0 && memoizedAudioList.length > 0;

    if (memoizedAudioList && isAudioReady) {
      setPlaying(true);
      index.current = finalIndex;

      if (!audioPlayer.current.src) {
        audioPlayer.current.src = memoizedAudioList[finalIndex];
      }
      if (audioPlayer.current.src !== memoizedAudioList[finalIndex]) {
        audioPlayer.current.src = memoizedAudioList[finalIndex];
        audioPlayer.current.play();
      }
    } else {
      toast.error("Please, generate zone before playing it");
    }
  };

  const handleForwardClick = () => {
    const finalIndex = index.current + 1;

    if (finalIndex >= memoizedAudioList.length) {
      return;
    }

    setPlaying(false);

    const isAudioReady =
      memoizedAudioList.slice(finalIndex).filter((audio) => !audio).length === 0 && memoizedAudioList.length > 0;

    if (memoizedAudioList && isAudioReady) {
      setPlaying(true);
      index.current = finalIndex;

      if (!audioPlayer.current.src) {
        audioPlayer.current.src = memoizedAudioList[finalIndex];
      }
      if (audioPlayer.current.src !== memoizedAudioList[finalIndex]) {
        audioPlayer.current.src = memoizedAudioList[finalIndex];
        audioPlayer.current.play();
      }
    } else {
      toast.error("Please, generate zone before playing it");
    }
  };

  // this use effect should be called only once -> once button is clicked
  useEffect(() => {
    if (!isButtonClicked) return;
    const finalIndex = index.current;

    const isAudioReady =
      memoizedAudioList.slice(finalIndex, finalIndex + 1).filter((audio) => !audio).length === 0 &&
      memoizedAudioList.length > 0;

    if (memoizedAudioList && isAudioReady) {
      setPlaying(true);
      showVideoPreview(true);

      if (!audioPlayer.current.src) audioPlayer.current.src = memoizedAudioList[finalIndex];
      if (audioPlayer.current.src !== memoizedAudioList[finalIndex])
        audioPlayer.current.src = memoizedAudioList[finalIndex];
      audioPlayer.current.play().catch(() => {
        sentryErrors({
          errorType: SentryErrors.AUDIO_PLAYBACK_ERROR,
          details: {
            // project,
            src: `${audioPlayer.current.src}`,
          },
        });
      });

      audioPlayer.current.addEventListener("ended", handleEnd);
    }

    return () => audioPlayer?.current?.removeEventListener("ended", handleEnd);
  }, [memoizedAudioList, isButtonClicked]);

  useEffect(() => {
    if (audioPlayer.current) {
      audioPlayer.current.addEventListener("error", handleError);
    }

    return () => audioPlayer?.current?.removeEventListener("error", handleError);
  }, [audioPlayer.current]);

  const handleError = () => {
    setPlaying(false);
    setIsButtonClicked(false);
    showVideoPreview(false);
    toast.error("Failed to play audio, please try to select the zone with your cursor and generate one more time:");
  };

  return {
    audioPlayer,
    playing,
    isFetching,
    audioLoading,
    currentTime,
    duration,
    seekValue,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    handleBackClick,
    handleForwardClick,
    handlePlayClick,
    handleProgressBarChange,
  };
};

export default useVoiceGeneration;
