import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import DownloadTime from "../DownloadTime/DownloadTime";
import { pages } from "../../lib/routeUtils";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { getProfile } from "../../redux/reducers/profileReducer";
import { getPrefilled } from "../../redux/reducers/popupsReducer";

interface Props {
  open: boolean;
  onClose: () => void;
}

const NotEnoughCreditsPopup = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const { hidePricing } = useSelector(getProfile);
  const { title, progressBar, description, link = "" }: NotEnoughCredits = useSelector(getPrefilled);

  const upgradePlan = () => {
    link.includes("//") ? window.open(link, "_blank") : navigate(!link ? pages.pricingSubscriptions() : link);
    onClose();
  };

  return (
    <Wrapper>
      <Modal
        maxWidth={532}
        maxHeight={320}
        className="not-enough-credits-popup"
        title={title}
        open={open}
        onClose={onClose}
      >
        <Content>
          {/* {subtitle && (
            <MinutesRequired>
              Minutes Required:<span>{subtitle}</span>
            </MinutesRequired>
          )} */}
          {progressBar && (
            <DownloadTime
              title={progressBar?.title}
              timeLeft={progressBar?.timeLeft}
              progress={progressBar?.progress}
            />
          )}
          <Description>{description}</Description>
          <ButtonWrapper>
            <Button text="Cancel" buttonTheme={ButtonThemes.Outline} onClick={onClose} />
            {!hidePricing && <Button text="Upgrade" onClick={upgradePlan} />}
          </ButtonWrapper>
        </Content>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-darkener {
    z-index: 102;
  }

  .modal-wrapper {
    width: 95%;
    z-index: 103;
  }

  .not-enough-credits-popup > span {
    padding-right: 40px;
  }
`;

const Content = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > div:nth-child(2) {
    max-width: 100%;

    @media (max-width: 900px) {
      display: flex;
    }

    @media (max-width: 500px) {
      min-width: 200px;
    }
  }
`;

const MinutesRequired = styled.div`
  color: #6f7074;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 15px;
  span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
    margin-left: 6px;
  }
`;

const Description = styled.span<{ isSubtitle?: boolean }>`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  ${({ isSubtitle }) =>
    isSubtitle &&
    `
      font-size: 16px;
      line-height: 28px;
      color: #6f7074;
    `}
`;

const ButtonWrapper = styled.div`
  margin-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  & > button,
  a {
    width: 100%;
  }

  & > button {
    height: 48px;
    border: none;

    &:first-of-type {
      box-shadow: ${({ theme }) => theme.homeCardContentShadow};

      span {
        color: ${({ theme }) => theme.activeMenu};
      }

      &:hover {
        border: none;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 12px;
    margin-top: 9px;
  }
`;

export default NotEnoughCreditsPopup;
