import styled from "styled-components";
import debounce from "lodash.debounce";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../Modal/Modal";
import Textfield from "../Textfield/Textfield";
import CircularProgress from "../Icons/CircularProgress";
import {
  Asset,
  clearAsset,
  createUserAssetServer,
  getAssetServer,
  searchAssetResultLoading,
} from "../../redux/actions/assetAction";
import { getAssets } from "../../redux/reducers/assetReducer";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { PopupGallery, SearchIcon, TrashIcon } from "../Icons/Icons";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { TrimImagePrefilled, UploadImagePrefilled, UploadImageTypes } from "../../types/image";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";

const MAX_SIZE = 100 * 1024 * 1024;
const ACCEPTED_FORMATS = "image/png, image/jpg, image/jpeg, application/pdf";
const ACCEPTED_FORMATS_TEXT_TO_VIDEO = "image/png, image/jpg, image/jpeg";
const searchTimeoutDelay = 500;

interface Props {
  open: boolean;
  onClose: () => void;
}

const AIImagesUploadImagePopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const {
    title,
    type,
    isTextToImage = true,
    handleSelectImage,
    previousFill,
  }: UploadImagePrefilled = useSelector(getPrefilled);

  const assets = useSelector(getAssets);
  const images =
    type === UploadImageTypes.CreateVariations
      ? assets[Asset.VisualAssets].data
      : assets[Asset.InPaintingVisualAssets].data;
  const imagesLoading =
    type === UploadImageTypes.CreateVariations
      ? assets[Asset.VisualAssets].isLoading
      : assets[Asset.InPaintingVisualAssets].isLoading;
  const uploadLoading =
    type === UploadImageTypes.CreateVariations
      ? assets[Asset.VisualAssets].uploadLoading
      : assets[Asset.InPaintingVisualAssets].uploadLoading;
  const uploadedImage =
    type === UploadImageTypes.CreateVariations
      ? assets[Asset.VisualAssets].latestUpload
      : assets[Asset.InPaintingVisualAssets].latestUpload;

  const [search, setSearch] = useState("");

  const debounceHandleGetGalleryImages = useRef(
    debounce(async (search?: string) => {
      dispatch(
        getAssetServer({
          keyword: search,
          pageNumber: 1,
          assetTypeId: type === UploadImageTypes.CreateVariations ? Asset.VisualAssets : Asset.InPaintingVisualAssets,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const handleClose = () => {
    if (uploadLoading) return;

    onClose();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleImageDelete = (e: any, id: number) => {
    if (!id) {
      throw Error("The ID image is missing when deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: "Are you sure you want to delete image?",
      description: "The image will be removed and the action cannot be undone",
      typeId: type === UploadImageTypes.CreateVariations ? Asset.VisualAssets : Asset.InPaintingVisualAssets,
      type: DeleteType.UploadImage,
      previousFill: { title, type, handleSelectImage },
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleFileSelect = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const allowedTypes = isTextToImage ? /image\/(png|jpeg|jpg)|application\/pdf/ : /image\/(png|jpeg|jpg)/;

    if (uploadedFile && uploadedFile.size > (isTextToImage ? MAX_SIZE : MAX_SIZE / 10)) {
      toast.error(`Exceeds maximum image resolution of ${isTextToImage ? "100" : "10"} MB`);
    } else if (!uploadedFile.type.match(allowedTypes)) {
      toast.error(`Invalid file type: ${uploadedFile.type}`);
    } else {
      const prefilled: TrimImagePrefilled = {
        file: uploadedFile,
        handleSelectImage,
      };

      const formData = new FormData();
      formData.append("AssetTypeId", "7");
      formData.append("Status", "1");
      formData.append("FileName", uploadedFile.name);
      formData.append("File", uploadedFile);

      if (type === UploadImageTypes.InPainting) {
        handleClose();

        dispatch(
          updatePopup({
            popup: Popups.trimImagePopup,
            status: true,
            prefilled,
          }),
        );
      } else {
        dispatch(
          createUserAssetServer({
            data: formData,
            assetTypeId: type === UploadImageTypes.CreateVariations ? Asset.VisualAssets : Asset.InPaintingVisualAssets,
          }),
        );
      }
    }
  };

  useEffect(() => {
    dispatch(
      searchAssetResultLoading(
        true,
        type === UploadImageTypes.CreateVariations ? Asset.VisualAssets : Asset.InPaintingVisualAssets,
      ),
    );
    dispatch(
      clearAsset({
        assetTypeId: type === UploadImageTypes.CreateVariations ? Asset.VisualAssets : Asset.InPaintingVisualAssets,
      }),
    );
    debounceHandleGetGalleryImages(search);
  }, [search]);

  useEffect(() => {
    if (!isFirstRender.current && !uploadLoading) {
      handleClose();

      if (uploadedImage) handleSelectImage(uploadedImage);
    }

    isFirstRender.current = false;
  }, [uploadLoading]);

  return (
    <Modal
      title={previousFill?.title || title}
      maxWidth={840}
      maxHeight={656}
      onClose={handleClose}
      open={open}
      className="ai-images-upload"
      childrenClassName="ai-images-children"
    >
      <>
        <UploadSection>
          <UploadWrapper>
            <PopupGallery />
            <UploadText>
              Drag and drop file (image), or <span>upload here</span>
            </UploadText>
            <SecondaryText>
              {isTextToImage
                ? "File Supported: PNG, JPG, PDF. Maximum size: 100 MB"
                : "File Supported: PNG, JPG. Maximum size: 10 MB"}
            </SecondaryText>
            <input
              id="imageUpload"
              name="imageUpload"
              type="file"
              accept={isTextToImage ? ACCEPTED_FORMATS : ACCEPTED_FORMATS_TEXT_TO_VIDEO}
              onChange={handleFileSelect}
            />
          </UploadWrapper>
        </UploadSection>
        <AssetsSection>
          <AssetsText>Browse your assets </AssetsText>
          <TextfieldWrapper>
            <Textfield
              value={search}
              startAdornment={<SearchIcon />}
              placeholder="Search"
              onChange={handleSearchChange}
            />
          </TextfieldWrapper>
          <ImagesContainer>
            {imagesLoading ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : (
              <ImagesWrapper>
                {images?.map(({ userAssetID, path }) => (
                  <ImgIconWrapper key={userAssetID}>
                    <StyledImg
                      src={path}
                      onClick={() => {
                        handleSelectImage(path);
                        handleClose();
                      }}
                    />
                    <TrashIconWrapper onClick={(e: any) => handleImageDelete(e, userAssetID)}>
                      <TrashIcon />
                    </TrashIconWrapper>
                  </ImgIconWrapper>
                ))}
              </ImagesWrapper>
            )}
          </ImagesContainer>
        </AssetsSection>
        {uploadLoading && (
          <Blur>
            <CircularProgress color="#009af7" />
          </Blur>
        )}
      </>
    </Modal>
  );
};

const UploadSection = styled.div<{ drag?: boolean }>`
  position: relative;
  max-height: 264px;
  height: 100%;
  padding: 40px 120px 40px 120px;
  margin: 24px 0px 12px 0px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='1' stroke-dasharray='8%2c 8' stroke-dashoffset='25' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.inputShadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const UploadText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0px 8px 0px;
  color: ${({ theme }) => theme.primaryText};

  span {
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SecondaryText = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #757679;
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const AssetsSection = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const AssetsText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};
`;

const TextfieldWrapper = styled.div`
  margin: 12px 0px;
`;

const ImagesContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-height: 148px;
  max-height: 148px;
`;

const ImagesWrapper = styled.div`
  gap: 8px;
  display: flex;
  height: 100%;
  flex-wrap: wrap;

  ::-webkit-scrollbar {
    width: 4px;
    background: rgba(25, 27, 31, 0.06);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
    height: 10px;
  }
`;

const ImgIconWrapper = styled.div`
  flex: 0 0 calc(16.666% - 7px);
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  filter: ${({ theme }) => theme.aiImagesUploadFilter};
  position: relative;

  &:hover {
    div {
      display: flex;
    }
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const TrashIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: white;
  }

  display: none;
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  z-index: 2;
`;

export default AIImagesUploadImagePopup;
