import styled from "styled-components";
import SectionHeading, { SectionHeadingProps } from "../TextComponents/SectionHeading";

export interface PageSectionProps extends SectionHeadingProps {
  children: React.ReactNode;
  maxWidth?: string;
  headingSectionWidth?: string;
  sectionId?: string;
}

const PageSection = ({ headingSectionWidth, maxWidth, sectionId, children, ...props }: PageSectionProps) => {
  return (
    <Wrapper maxWidth={maxWidth} headingSectionWidth={headingSectionWidth} id={sectionId}>
      <SectionHeading {...props} titleAlignMd="center" />
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ maxWidth?: string; headingSectionWidth?: string }>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .sectionHeading {
    ${({ headingSectionWidth }) => headingSectionWidth && `max-width: ${headingSectionWidth};`}
  }

  @media screen and (min-width: 1000px) {
    gap: 40px;
  }
`;

export default PageSection;
