import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "./components/Header";
import Pricing from "./components/Pricing";
import EnterprisePackage from "./components/EnterprisePackage";
import Plans from "./components/Plans";
import FAQ from "./components/FAQ";
import PricingProvider from "./components/PricingProvider";
import { PriceSection } from "../../types/pricing";
import { changeSubscriptionUpdated, getUserPlansServer } from "../../redux/actions/plansActions";
import { getIsSubscriptionUpdated, getUserPlans } from "../../redux/reducers/plansReducer";
import { UserPlan } from "../../types/plans";
import { pages } from "../../lib/routeUtils";
import { subscriptions } from "../../mocks/pricing";

export const getPlanGroup = (plan: UserPlan) => {
  const name = plan.productName.toLowerCase();
  if (name.includes("ai voices")) {
    return PriceSection.voices;
  }
  if (name.includes("ai humans")) {
    return PriceSection.humans;
  }
  if (name.includes("ai images")) {
    return PriceSection.image;
  }
  if (name.includes("ai bundle")) {
    return PriceSection.studio;
  }
};

export const getCheckoutLink = (planId: number) => {
  return subscriptions.find((plan) => plan.planID === planId)?.checkoutLink;
};

const DefaultUserPricing = () => {
  const [searchParams] = useSearchParams();
  const [activeSection, setActiveSection] = useState<PriceSection>(PriceSection.voices);
  const [isToggle, setIsToggle] = useState(false);
  const [route, setRoute] = useState<string>("");

  const isSubscriptionUpdated = useSelector(getIsSubscriptionUpdated);
  const plans = useSelector(getUserPlans);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(location.hash);

  const handleSetActiveSection = (section: PriceSection) => {
    setIsToggle(false);
    setActiveSection(section);
  };

  const handleToggleClick = () => {
    setIsToggle((prev) => !prev);
  };

  useEffect(() => {
    setRoute(searchParams.get("plan") || "");
  }, [searchParams]);

  useEffect(() => {
    dispatch(getUserPlansServer());
  }, []);

  useEffect(() => {
    if (!isSubscriptionUpdated) return;
    navigate(pages.main());
    dispatch(changeSubscriptionUpdated({ status: false }));
  }, [isSubscriptionUpdated]);

  useEffect(() => {
    if (!location.hash) return;
    if (location.hash.includes("bundle")) {
      setActiveSection(PriceSection.studio);
    }
  }, [location]);

  useEffect(() => {
    if (route && Object.values(PriceSection).includes(route as PriceSection)) {
      setActiveSection(route as PriceSection);
    }
  }, [route]);

  const activeGroups = plans
    .filter((plan) => !plan.isCancelled)
    .filter(getPlanGroup)
    .map(getPlanGroup) as PriceSection[];

  return (
    <PricingProvider>
      <Wrapper>
        <MobileOnly>
          <Sidebar mobile />
        </MobileOnly>
        <Header />
        <Pricing
          activeSection={activeSection}
          setActiveSection={handleSetActiveSection}
          handleToggleClick={handleToggleClick}
          isToggle={isToggle}
          activeGroups={activeGroups}
        />
        <EnterprisePackage />
        <Plans activeSection={activeSection} activeGroups={activeGroups} />
        {/* <FAQ /> */}
      </Wrapper>
    </PricingProvider>
  );
};

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  font-family: Mulish !important;

  @media (max-width: 1001px) {
    padding-top: 35px;
  }
`;

export default DefaultUserPricing;
