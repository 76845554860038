import styled from "styled-components";
import Button, { ButtonThemes } from "../Button/Button";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getPrefilled } from "../../redux/reducers/popupsReducer";

const EmbedOnWebsitePopup = ({ onClose }: { onClose: () => void }) => {
  const prefilled = useSelector(getPrefilled);
  const guid = prefilled.guid;

  const handleCopy = () => {
    const copyText: any = document.getElementById("embed-on-website");

    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      onClose();
      toast.success("Script copied to the clipboard!");
    } else {
      alert("Failed to copy, please copy manually");
    }
  };

  return (
    <Wrapper>
      <Modal
        maxWidth={532}
        maxHeight={320}
        open={true}
        onClose={onClose}
        title="Embed on website"
        className="not-enough-credits-popup"
      >
        <Content>
          <textarea readOnly id="embed-on-website">
            {`<script src="https://synthesys.live/synthesys.js"></script>
<script>
    synthesysinit('${guid}');
</script>`}
          </textarea>
          <ButtonWrapper>
            <Button text="Copy to clipboard" buttonTheme={ButtonThemes.Primary} onClick={handleCopy} />
            <Button text="Close" buttonTheme={ButtonThemes.Outline} onClick={onClose} />
          </ButtonWrapper>
        </Content>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-darkener {
    z-index: 102;
  }

  .modal-wrapper {
    width: 95%;
    z-index: 103;
  }

  .not-enough-credits-popup > span {
    padding-right: 40px;
  }
`;

const Content = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  textarea {
    padding: 10px;
    resize: none;
    min-height: 200px;
    border: none;
    outline: none;
  }

  & > div:nth-child(2) {
    max-width: 100%;

    @media (max-width: 900px) {
      display: flex;
    }

    @media (max-width: 500px) {
      min-width: 200px;
    }
  }
`;

const MinutesRequired = styled.div`
  color: #6f7074;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 15px;
  span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
    margin-left: 6px;
  }
`;

const Title = styled.span<{ isSubtitle?: boolean }>`
  color: ${({ theme }) => theme.primaryText};
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;

  ${({ isSubtitle }) =>
    isSubtitle &&
    `
      font-size: 16px;
      line-height: 28px;
      color: #6f7074;
    `}
`;

const ButtonWrapper = styled.div`
  margin-top: 17px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  & > button,
  a {
    width: 100%;
  }

  & > button {
    height: 48px;
    border: none;

    &:first-of-type {
      box-shadow: ${({ theme }) => theme.homeCardContentShadow};

      span {
        color: white;
      }

      &:hover {
        border: none;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    gap: 12px;
    margin-top: 9px;
  }
`;

export default EmbedOnWebsitePopup;
