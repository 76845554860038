export const IrisInIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="5" width="28" height="18" rx="4" fill="url(#paint0_linear_2977_21218)" />
    <circle cx="14.1156" cy="13.9707" r="7.59294" fill="white" />
    <circle cx="14.1156" cy="13.9707" r="7.59294" fill="url(#paint1_linear_2977_21218)" />
    <circle cx="14.1156" cy="13.9707" r="7.59294" stroke="white" strokeWidth="1.18587" />
    <path
      d="M14.9875 10.8949V13.1191L17.2118 13.1191"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2731 17.0332L13.2731 14.8089L11.0489 14.8089"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9868 13.1191L17.7672 10.3388"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4856 17.5801L13.2659 14.7997"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2977_21218"
        x1="1.91475e-07"
        y1="14"
        x2="23.2331"
        y2="24.5238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1680D3" />
        <stop offset="1" stopColor="#C0DFF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2977_21218"
        x1="10.131"
        y1="8.80147"
        x2="18.4233"
        y2="19.463"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
