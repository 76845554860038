import { ReactElement } from "react";

export interface IHuman {
  id: number;
  imageSrc: string;
  flagSrc: string;
  name: string;
}

export interface FeaturesSettings {
  id?: number;
  image: string;
  name: string;
  flag?: string;
  features: Features[];
}

export interface Features {
  id: number | string;
  title: string;
  defaultTitle?: string;
  icon: ReactElement;
  step?: number;
  defaultValue?: string;
  modifier?: string;
  values?: {
    id: number | string;
    text: string;
    label: string;
  }[];
}

export enum ProfileHumanSidebarType {
  Background = "Backgrounds",
  Text = "Text",
  Humatar = "Humatars",
  Soundtrack = "Soundtrack",
  Transitions = "Transitions",
  Shapes = "Shapes",
  Media = "Media",
  Templates = "Templates",
}
