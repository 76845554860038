import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../Modal/Modal";
import Tooltip from "../Tooltip/Tooltip";
import IconButton from "../Button/IconButton";
import RadioButton from "../RadioButton/RadioButton";
import Button, { ButtonThemes } from "../Button/Button";
import { pages } from "../../lib/routeUtils";
import { DiamondIcon } from "../Icons/DiamondIcon";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { getProfile } from "../../redux/reducers/profileReducer";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";

export enum CustomAvatar {
  InstantHumatar = "Instant Humatar",
  TalkingImage = "Talking Image",
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const SelectTypeCustomAvatarPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const { pathname } = useLocation();
  const { allowToCloneAIHuman = false } = useSelector(getProfile);
  const { noRefresh, prefilledType } = useSelector(getPrefilled);
  const [selectedType, setSelectedType] = useState<CustomAvatar | null>(prefilledType);

  const handleSelectedTypeChange = (type: CustomAvatar) => {
    setSelectedType((prevSelectedType) => {
      if (prevSelectedType === type) {
        return null;
      }
      return type;
    });
  };

  const handleSubmit = () => {
    if (!selectedType) return;

    onClose();
    dispatch(
      updatePopup({
        popup: Popups.addCustomAvatarPopup,
        status: true,
        prefilled: { noRefresh, type: selectedType },
      }),
    );
  };

  const upgradeYourPlan = () => {
    const prefilled: NotEnoughCredits = {
      title: "Upgrade the plan",
      description: "Get access to additional premium features",
      link: `${pages.pricingSubscriptions()}?plan=humans`,
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      onClose();
    }

    isFirstRender.current = false;
  }, [pathname]);

  return (
    <Modal className="addVoiceAudio" open={open} closeIcon={false} onClose={onClose} maxWidth={523} maxHeight={384}>
      <Title>Select type of custom avatar</Title>
      <TypesWrapper>
        {menu.map((item, index) => {
          const isDisabled = !allowToCloneAIHuman && item.title === CustomAvatar.InstantHumatar;

          return (
            <Type
              key={item.title}
              isActive={item.title === selectedType}
              isDisabled={isDisabled}
              onClick={() => !isDisabled && handleSelectedTypeChange(item.title)}
            >
              <TitleWrapper>
                <RadioButton
                  id={index}
                  value={(selectedType as CustomAvatar) || ""}
                  checked={item.title === selectedType}
                  onChange={() => handleSelectedTypeChange(item.title)}
                />
                <TypeTitle>{item.title}</TypeTitle>
              </TitleWrapper>
              <TypeDescription>{item.description}</TypeDescription>
              {isDisabled && (
                <>
                  <DiamondButton icon={<DiamondIcon />} onClick={upgradeYourPlan} />
                  <Tooltip
                    className="select-type-custom-avatar-tooltip"
                    text={
                      <span>
                        <b onClick={upgradeYourPlan}>Upgrade plan to access Instant Custom Avatars</b>
                      </span>
                    }
                    position="bottom"
                    reverseColor
                    arrow
                  />
                </>
              )}
            </Type>
          );
        })}
      </TypesWrapper>
      <ButtonWrapper>
        <Button buttonTheme={ButtonThemes.Outline} onClick={onClose} text="Cancel" />
        <Button disabled={!selectedType} onClick={handleSubmit} text="Submit" />
      </ButtonWrapper>
    </Modal>
  );
};

const menu = [
  {
    title: CustomAvatar.InstantHumatar,
    description: "Upload your video to create your custom humatar",
  },
  {
    title: CustomAvatar.TalkingImage,
    description: "Transform any image into a talking avatar",
  },
];

const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};
`;

const TypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0 32px;
`;

const Type = styled.div<{ isActive?: boolean; isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 14px;
  border-radius: 16px;
  border: 1px solid #d2d2d9;
  background: ${({ theme }) => theme.primaryBackground};
  cursor: pointer;

  .select-type-custom-avatar-tooltip {
    box-shadow: none;
    border-radius: 8px;
    top: 110%;
    width: 100%;
    max-width: 328px;

    span {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }

  &:hover > .select-type-custom-avatar-tooltip {
    opacity: 1;
    visibility: visible;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
        border: 1px solid ${theme.activeMenu};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
        & > div, & > span {
          opacity: 0.4;
        }
    `}
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > div {
    width: 16px;
    margin-bottom: 1px;
    pointer-events: none;
  }
`;

const TypeTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
`;

const TypeDescription = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.buttonGreyText};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  & > button:first-of-type {
    border: none;

    span {
      background: var(--Linear, linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const DiamondButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 16px;
  width: 48px;
  height: 48px;
  max-width: 48px;
  transform: translateY(-50%);
  pointer-events: auto;
`;

export default SelectTypeCustomAvatarPopup;
