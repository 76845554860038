import Select, { components } from "react-select";
import styled from "styled-components";
import { FC, ReactNode, useState } from "react";

interface OptionType {
  value: string;
  label: string;
}

interface Props {
  optionsList: OptionType[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  actionIcon?: ReactNode;
}

const EditorSelect: FC<Props> = ({ optionsList, onChange, value, disabled, placeholder, actionIcon }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const customStyles = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 6,
    }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: "5px",
      boxShadow: "none",
      border: "1px solid #0063B4",
      backgroundColor: "#0865A7",
      height: "34px",
      minWidth: "148px",
      maxWidth: "148px",
      cursor: "pointer",

      "&:hover": {
        border: "1px solid #0063B4",
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: "16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      paddingRight: "16px",

      div: {
        padding: 0,
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s",
        path: {
          stroke: "#ffffff",
          strokeWidth: "2px",
        },
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: "12px",
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: "5px",
      maxHeight: "125px",
      border: "1px solid #0063B4",
      backgroundColor: "#0865A7",
      boxShadow: "none",
      paddingBottom: "2px",
      paddingTop: "2px",

      "@media (max-width: 1001px)": {
        "::-webkit-scrollbar": {
          display: "none",
        },
      },

      "::-webkit-scrollbar": {
        width: "2px",
      },

      "::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#ffffff",
      },

      "::-webkit-scrollbar-track": {
        margin: "15px 0",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#E2E2E3",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#E2E2E3",
      opacity: 0.6,
    }),
    input: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: "#E2E2E3",
    }),
    option: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      backgroundColor: "transparent",
      padding: "8px 16px",

      "&:active": {
        backgroundColor: "transparent",
        color: "#ffffff",
      },
    }),
  };

  const formatOptionLabel = (option: OptionType, { context }: { context: string }) => (
    <SelectorOptionItem active={context === "menu" && option.value === value}>
      <Row>{option.label}</Row>
    </SelectorOptionItem>
  );

  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  const DropdownIndicator = (props: any) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>{actionIcon}</components.DropdownIndicator>
    );

  return (
    <Select
      value={optionsList.find((option) => option.value === value)}
      onChange={handleChange}
      options={optionsList}
      isDisabled={disabled}
      styles={customStyles}
      isSearchable={false}
      placeholder={placeholder}
      menuPlacement="auto"
      menuShouldBlockScroll={true}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      components={{ DropdownIndicator }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};

const SelectorOptionItem = styled.span<{ active?: boolean }>`
  position: relative;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #e2e2e3;
  cursor: pointer;
  display: block !important;
  border: none !important;
  margin: 0 !important;

  ${({ active, theme }) =>
    active &&
    `
       border-radius: 5px;
       color: #ffffff;
       font-weight: 600;
  `}
`;

const Row = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export default EditorSelect;
