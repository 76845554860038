import React from "react";

export const Email = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.834 14.9996H4.16732C3.70815 14.9996 3.33398 14.6263 3.33398 14.1663V6.04126L9.50065 10.6663C9.64898 10.7779 9.82482 10.8329 10.0007 10.8329C10.1765 10.8329 10.3523 10.7779 10.5007 10.6663L16.6673 6.04126V14.1663C16.6673 14.6263 16.2931 14.9996 15.834 14.9996V14.9996ZM15.2778 4.99967L10.0003 8.95801L4.72283 4.99967H15.2778ZM15.8337 3.33301H4.16699C2.78866 3.33301 1.66699 4.45467 1.66699 5.83301V14.1663C1.66699 15.5447 2.78866 16.6663 4.16699 16.6663H15.8337C17.212 16.6663 18.3337 15.5447 18.3337 14.1663V5.83301C18.3337 4.45467 17.212 3.33301 15.8337 3.33301V3.33301Z"
        fill="#231F20"
      />
      <mask id="mask0_655_8472" maskUnits="userSpaceOnUse" x="1" y="3" width="18" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.834 14.9996H4.16732C3.70815 14.9996 3.33398 14.6263 3.33398 14.1663V6.04126L9.50065 10.6663C9.64898 10.7779 9.82482 10.8329 10.0007 10.8329C10.1765 10.8329 10.3523 10.7779 10.5007 10.6663L16.6673 6.04126V14.1663C16.6673 14.6263 16.2931 14.9996 15.834 14.9996V14.9996ZM15.2778 4.99967L10.0003 8.95801L4.72283 4.99967H15.2778ZM15.8337 3.33301H4.16699C2.78866 3.33301 1.66699 4.45467 1.66699 5.83301V14.1663C1.66699 15.5447 2.78866 16.6663 4.16699 16.6663H15.8337C17.212 16.6663 18.3337 15.5447 18.3337 14.1663V5.83301C18.3337 4.45467 17.212 3.33301 15.8337 3.33301V3.33301Z"
          fill="#231F20"
        />
      </mask>
      <g mask="url(#mask0_655_8472)" />
    </g>
  </svg>
);

export const EmailBlue = () => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.834 11.9998H3.16732C2.70815 11.9998 2.33398 11.6265 2.33398 11.1665V3.0415L8.50065 7.6665C8.64898 7.77817 8.82482 7.83317 9.00065 7.83317C9.17648 7.83317 9.35232 7.77817 9.50065 7.6665L15.6673 3.0415V11.1665C15.6673 11.6265 15.2931 11.9998 14.834 11.9998ZM14.2778 1.99992L9.00033 5.95825L3.72283 1.99992H14.2778ZM14.8337 0.333252H3.16699C1.78866 0.333252 0.666992 1.45492 0.666992 2.83325V11.1666C0.666992 12.5449 1.78866 13.6666 3.16699 13.6666H14.8337C16.212 13.6666 17.3337 12.5449 17.3337 11.1666V2.83325C17.3337 1.45492 16.212 0.333252 14.8337 0.333252Z"
      fill="url(#paint0_linear_126_4977)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_126_4977"
        x1="4.25674"
        y1="2.07684"
        x2="11.5666"
        y2="13.8248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
