export const AtsLogoIcon = () => (
  <svg width="47" height="24" viewBox="0 0 47 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_19_549)">
      <path d="M1.99792 24H43.9237V0H1.99792V24Z" fill="white" />
      <path d="M1.99792 23.1999H43.5979V0.799904H1.99792V23.1999Z" fill="#6F7074" />
      <path
        d="M10.2815 13.0828L11.5444 9.73849L12.7845 13.0828H10.2815ZM12.4398 6.1062H10.6463L6.39832 17.2985H8.63861L9.53272 14.9997H13.5525L14.4475 17.2985H16.6878L12.4398 6.1062Z"
        fill="white"
      />
      <path d="M19.8893 17.3058H17.7214V7.93274H15.0701V6.08344H22.4045V7.93274H19.8893V17.3058Z" fill="white" />
      <path
        d="M32.4112 9.42503C32.3659 11.119 34.1659 11.9804 34.9587 12.4765C35.5391 12.84 36.4336 13.3484 36.6479 14.0562C36.7688 14.4546 36.6217 14.8931 36.4031 15.1418C36.1583 15.4202 35.7494 15.5756 35.3214 15.5638C34.4617 15.5411 33.987 14.8228 33.4376 13.9911L33.3503 13.8589L31.8654 15.3378L31.8911 15.3831C32.7032 16.8092 33.8722 17.5833 35.2699 17.6204C36.4777 17.6522 37.4735 17.1565 38.1232 16.4915C38.7429 15.8566 39.0418 14.952 38.9868 13.8751C38.91 12.343 37.3038 11.4162 36.1408 10.697C35.176 10.1005 34.525 9.9827 34.5464 9.1907C34.5647 8.48423 35.2611 8.06052 35.8528 8.07622C36.4616 8.09193 37.0978 8.42139 37.5979 8.97993L37.6533 9.0419L38.8424 7.46401L38.8014 7.42168C38.0447 6.63273 36.8761 6.10517 35.824 6.07681C33.8971 6.02662 32.4619 7.51332 32.4112 9.42503Z"
        fill="white"
      />
      <path
        d="M25.3792 15.6736C24.6426 15.6736 24.0535 14.9082 24.0535 14.2166C24.0535 13.4215 24.8621 12.8887 25.4512 12.455L27.1753 14.7219C26.7188 15.1543 26.0582 15.6736 25.3792 15.6736ZM26.0826 7.51144C26.6564 7.48002 26.9815 8.02635 27.0086 8.53166C27.0365 9.05093 26.8196 9.40918 26.4548 9.74693L26.1023 10.069L25.5332 9.36206C25.343 9.12642 25.2409 8.85806 25.2252 8.56962C25.1977 8.05122 25.494 7.54286 26.0826 7.51144ZM30.0439 14.7459L31.2116 13.5136L29.9789 12.054L28.7475 13.3164L27.1644 11.3174C28.0746 10.6615 29.0102 9.73035 28.9456 8.53253C28.8579 6.90402 27.5645 5.91871 25.9609 6.00555C24.3725 6.09108 23.1943 7.23871 23.2816 8.85326C23.3292 9.73253 23.7507 10.4325 24.3044 11.1114L23.6241 11.56C22.6223 12.2246 22.0175 13.1772 22.0175 14.3898C22.0175 16.2226 23.4033 17.5661 25.2745 17.5661C26.526 17.5661 27.5431 16.96 28.4573 16.1802L29.3562 17.2773H31.9936L30.0439 14.7459Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_19_549">
        <rect width="42" height="23.2" fill="white" transform="translate(2.00195 0.399902)" />
      </clipPath>
    </defs>
  </svg>
);
