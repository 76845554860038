import { toast } from "react-toastify";
import { put, select, takeEvery } from "redux-saga/effects";
import {
  CLEAR_SEARCH,
  GET_TEMPLATES_SERVER,
  IMPORT_TEMPLATE_SERVER,
  clearTemplates,
  getTemplatesServer,
  searchTemplatesResultLoading,
  updateHasMoreTemplates,
  updateIsEmptyTemplates,
  updateTemplateLoading,
} from "../actions/templateAction";
import { getTemplates } from "../reducers/templateReducer";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { Popups, updatePopup } from "../actions/popupsActions";
import { createProject } from "../actions/humansProjectActions";
import { ProjectType } from "../actions/projectAction";
import { VideoEditorContext } from "../../App";
import { useContext } from "react";

const templateSaga = [
  takeEvery(`${GET_TEMPLATES_SERVER}`, handleGetTemplatesServer),
  takeEvery(`${GET_TEMPLATES_SERVER}_FAIL`, handleGetTemplatesServerFail),
  takeEvery(`${GET_TEMPLATES_SERVER}_SUCCESS`, handleGetTemplatesServerSuccess),

  takeEvery(CLEAR_SEARCH, handleClearSearch),

  takeEvery(`${IMPORT_TEMPLATE_SERVER}_FAIL`, handleImportTemplateServerFail),
  takeEvery(`${IMPORT_TEMPLATE_SERVER}_SUCCESS`, handleImportTemplateServerSuccess),
];

function* handleGetTemplatesServer() {
  yield put(updateTemplateLoading({ isLoading: true }));
  yield put(updateIsEmptyTemplates({ isEmpty: false }));
}

function* handleGetTemplatesServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_TEMPLATES.toast);
  yield put(updateTemplateLoading({ isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_TEMPLATES,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetTemplatesServerSuccess(action: any) {
  const allTemplates = getTemplates(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allTemplates.length >= totalLength) yield put(updateHasMoreTemplates({ hasMore: false }));
  if (!allTemplates.length) yield put(updateIsEmptyTemplates({ isEmpty: true }));

  yield put(updateTemplateLoading({ isLoading: false }));
  yield put(searchTemplatesResultLoading(false));
}

function* handleClearSearch() {
  yield put(clearTemplates());
  yield put(
    getTemplatesServer({
      keyword: "",
      pageNumber: 1,
    }),
  );
}

function* handleImportTemplateServerFail() {
  yield toast.error("Failed to import the template");
}

function* handleImportTemplateServerSuccess() {
  yield toast.success("The template was imported successfully");
  yield put(
    updatePopup({
      popup: Popups.templatesPopup,
      status: false,
    }),
  );
  yield put(
    createProject({
      projectTypeId: ProjectType.HSS,
      title: `Project ${Math.random()}`,
      slides: [],
    }),
  );
}

export default templateSaga;
