import React, { useContext, useEffect, useState } from "react";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import styled from "styled-components";
import { DropdownIcon } from "../../../../../components/Icons/DropdownIcon";
import { Text } from "../../../../../types/scene";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { VideoEditorContext } from "../../../../../App";
import CircularProgress from "../../../../../components/Icons/CircularProgress";
import FontImage from "./FontImage";

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Text;
  fontList: { font: string; image: string }[];
  selectedFont: string;
  handleChangeSelected: (value: string) => void;
}

const TextFontFamily = ({
  handleSceneObjectChange,
  currentObject,
  fontList,
  selectedFont,
  handleChangeSelected,
}: Props) => {
  const theme = useSelector(getTheme);
  const [isOpen, setIsOpen] = useState(false);

  const { isFontLoading } = useContext(VideoEditorContext);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (!currentObject) return handleChangeSelected("none");
    handleChangeSelected((currentObject.style.fontFamily as string) || "Mulish");
  }, [currentObject]);

  const handleFontChange = (value: string) => {
    if (!currentObject) return;
    handleSceneObjectChange("style", { ...currentObject.style, fontFamily: value }, currentObject.id);
    handleClose();
    handleChangeSelected(value);
  };

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <DropdownWrapper onClick={handleOpen}>
            <ValueText>{isFontLoading ? <CircularProgress /> : selectedFont}</ValueText>
            <DropdownIcon />
            <Tooltip
              text="Font"
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
              reverseColor
              arrow
            />
          </DropdownWrapper>
        }
      >
        <MenuWrapper>
          {fontList.map(({ image, font }) => (
            <FontImage handleClick={() => handleFontChange(font)} key={font} src={image} />
          ))}
        </MenuWrapper>
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2px;
  cursor: pointer;
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
`;

const ValueText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};

  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & path {
    fill: #0063b4;
  }
`;

const MenuWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0;
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(10px)",
  left: "0",
  border: "none",
  padding: "10px 2px 10px 0",
  width: "max-content",

  background: "#FFFFFF",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default TextFontFamily;
