export const OptionsIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 6C1.5 5.448 1.948 5 2.5 5C3.052 5 3.5 5.448 3.5 6C3.5 6.552 3.052 7 2.5 7C1.948 7 1.5 6.552 1.5 6ZM6 5C5.448 5 5 5.448 5 6C5 6.552 5.448 7 6 7C6.552 7 7 6.552 7 6C7 5.448 6.552 5 6 5ZM9.5 5C8.948 5 8.5 5.448 8.5 6C8.5 6.552 8.948 7 9.5 7C10.052 7 10.5 6.552 10.5 6C10.5 5.448 10.052 5 9.5 5Z"
      fill="white"
    />
    <mask
      id="mask0_1094_14074"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="5"
      width="10"
      height="2"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6C1.5 5.448 1.948 5 2.5 5C3.052 5 3.5 5.448 3.5 6C3.5 6.552 3.052 7 2.5 7C1.948 7 1.5 6.552 1.5 6ZM6 5C5.448 5 5 5.448 5 6C5 6.552 5.448 7 6 7C6.552 7 7 6.552 7 6C7 5.448 6.552 5 6 5ZM9.5 5C8.948 5 8.5 5.448 8.5 6C8.5 6.552 8.948 7 9.5 7C10.052 7 10.5 6.552 10.5 6C10.5 5.448 10.052 5 9.5 5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1094_14074)"></g>
  </svg>
);
