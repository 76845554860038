import { HumansParagraph } from "../types/humansProject";
import { Scene } from "../types/scene";

export const reorderSlides = (
  list: Scene[] | HumansParagraph[],
  startIndex: number,
  endIndex: number,
  isZeroBased?: boolean,
) => {
  const newOrder =
    endIndex > startIndex
      ? list.map((scene, index) =>
          index > startIndex && index <= endIndex ? { ...scene, order: scene.order - 1 } : scene,
        )
      : list.map((scene, index) =>
          index >= endIndex && index < startIndex ? { ...scene, order: scene.order + 1 } : scene,
        );
  const result = newOrder.map((scene, index) =>
    index === startIndex ? { ...scene, order: endIndex + +!isZeroBased } : scene,
  );

  return result;
};
