import React from "react";
import { ObjectTypes, Scene } from "../../../types/scene";
import styled from "styled-components";
import SceneObjectWrapper from "./SceneObjectWrapper";
import SceneObject from "./SceneObject";

interface Props {
  scenes: Scene[];
}

const PreRender = ({ scenes }: Props) => {
  const sceneObjects = scenes
    .map(({ objects, id: sceneId }) =>
      objects
        .filter(({ type }) => type === ObjectTypes.texts || type === ObjectTypes.shapes)
        .map(({ object: obj, type }: { object: any; type: ObjectTypes }, index: any) => (
          <SceneObjectWrapper
            index={index}
            key={obj.id}
            obj={obj}
            objectType={type}
            nodeId={
              type === ObjectTypes.shapes
                ? `shape${obj.id}${sceneId}`
                : type === ObjectTypes.texts
                ? `text${obj.id}${sceneId}`
                : ""
            }
          >
            <ObjectWrapper>
              <SceneObject object={{ object: obj, type }} />
            </ObjectWrapper>
          </SceneObjectWrapper>
        )),
    )
    .flat();

  return <Wrapper>{sceneObjects}</Wrapper>;
};

const Wrapper = styled.div`
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  & div,
  & pre,
  & textarea {
    pointer-events: none !important;
  }

  & > div {
    transform: translate(0, 0) !important;
  }
`;

const ObjectWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export default PreRender;
