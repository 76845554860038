import { toast } from "react-toastify";
import { StoreType } from "../../types/store";
import { UploadVideoTabs, VideoTranslationModules, VideoTranslationStatus } from "../../types/videoTranslation";
import {
  ADD_VIDEO_TRANSLATE_PROJECT_SERVER,
  GET_VIDEO_TRANSLATE_PROJECTS_SERVER,
  GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER,
  UPDATE_VIDEO_TRANSLATE_IS_LOADING,
  UPDATE_SINGLE_VIDEO_DATA,
  FACE_DETECT_SERVER,
  GET_YOUTUBE_URL_SERVER,
  UPDATE_VIDEO_SERVICE_LOADING,
  CLEAR_VIDEO_SERVICE_URL,
  CLEAR_FACE_DETECT,
  GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER,
} from "../actions/videoTranslationActions";

export interface videoTranslationStateType {
  [VideoTranslationModules.allVideos]: {
    data: [];
    isLoading: boolean;
  };
  [VideoTranslationModules.singleVideo]: {
    data: Record<string, string | number | null> | null;
    isLoading: boolean;
  };
  [VideoTranslationModules.generateVideo]: {
    data: Record<string, string | number | null> | null;
    isLoading: boolean;
  };
  [VideoTranslationModules.faceDetect]: {
    data: Record<string, string | number | null> | null;
    isFaceDetectCompeted: boolean;
    isLoading: boolean;
  };
  [VideoTranslationModules.videoServices]: {
    [key: string]: {
      url: string;
      isLoading: boolean;
    };
  };
  [VideoTranslationModules.generateVideoToTranslateText]: {
    data: string;
    isLoading: boolean;
  };
}

const videoTranslationInitialState: videoTranslationStateType = {
  [VideoTranslationModules.allVideos]: {
    data: [],
    isLoading: false,
  },
  [VideoTranslationModules.singleVideo]: {
    data: null,
    isLoading: false,
  },
  [VideoTranslationModules.generateVideo]: {
    data: null,
    isLoading: false,
  },
  [VideoTranslationModules.faceDetect]: {
    data: null,
    isFaceDetectCompeted: false,
    isLoading: false,
  },
  [VideoTranslationModules.videoServices]: {
    [UploadVideoTabs.YouTube]: {
      url: "",
      isLoading: false,
    },
  },
  [VideoTranslationModules.generateVideoToTranslateText]: {
    data: "",
    isLoading: false,
  },
};

const videoTranslationReducer = (state = videoTranslationInitialState, action: any) => {
  switch (action.type) {
    case `${GET_VIDEO_TRANSLATE_PROJECTS_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [VideoTranslationModules.allVideos]: {
          ...state[VideoTranslationModules.allVideos],
          data,
        },
      };
    }
    case `${GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      if (data.status === VideoTranslationStatus.Failed) {
        toast.error("Failed to generate video. Contact support!");
      }

      return {
        ...state,
        [VideoTranslationModules.singleVideo]: {
          ...state[VideoTranslationModules.singleVideo],
          data,
        },
      };
    }
    case ADD_VIDEO_TRANSLATE_PROJECT_SERVER: {
      return {
        ...state,
        [VideoTranslationModules.generateVideo]: {
          ...state[VideoTranslationModules.generateVideo],
          data: null,
        },
      };
    }
    case `${ADD_VIDEO_TRANSLATE_PROJECT_SERVER}_SUCCESS`: {
      return {
        ...state,
        [VideoTranslationModules.generateVideo]: {
          ...state[VideoTranslationModules.generateVideo],
          data: action.payload.data.data,
        },
      };
    }
    case UPDATE_VIDEO_TRANSLATE_IS_LOADING: {
      const { module, isLoading } = action.payload;

      return { ...state, [module]: { ...state[module as VideoTranslationModules], isLoading } };
    }
    case UPDATE_SINGLE_VIDEO_DATA: {
      return {
        ...state,
        [VideoTranslationModules.singleVideo]: {
          ...state[VideoTranslationModules.singleVideo],
          data: action.payload,
        },
      };
    }
    case CLEAR_FACE_DETECT:
    case FACE_DETECT_SERVER: {
      return {
        ...state,
        [VideoTranslationModules.faceDetect]: {
          ...state[VideoTranslationModules.faceDetect],
          data: null,
          isFaceDetectCompeted: false,
        },
      };
    }
    case `${FACE_DETECT_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      if (data.status || data.isFaceDetected) {
        toast.info("We detected a face in your video");

        return {
          ...state,
          [VideoTranslationModules.faceDetect]: {
            ...state[VideoTranslationModules.faceDetect],
            data,
            isFaceDetectCompeted: true,
          },
        };
      }

      return {
        ...state,
        [VideoTranslationModules.faceDetect]: {
          ...state[VideoTranslationModules.faceDetect],
          isFaceDetectCompeted: true,
        },
      };
    }
    case UPDATE_VIDEO_SERVICE_LOADING: {
      const { videoServiceType, isLoading } = action.payload;

      if (videoServiceType) {
        return {
          ...state,
          [VideoTranslationModules.videoServices]: {
            ...state[VideoTranslationModules.videoServices],
            [videoServiceType]: { ...state[VideoTranslationModules.videoServices][videoServiceType], isLoading },
          },
        };
      } else {
        return { ...state };
      }
    }
    case CLEAR_VIDEO_SERVICE_URL: {
      const { videoServiceType } = action.payload;

      if (videoServiceType) {
        return {
          ...state,
          [VideoTranslationModules.videoServices]: {
            ...state[VideoTranslationModules.videoServices],
            [videoServiceType]: {
              ...state[VideoTranslationModules.videoServices][videoServiceType],
              url: "",
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case `${GET_YOUTUBE_URL_SERVER}_SUCCESS`: {
      const { file_url: url } = action.payload.data.data;

      return {
        ...state,
        [VideoTranslationModules.videoServices]: {
          ...state[VideoTranslationModules.videoServices],
          [UploadVideoTabs.YouTube]: {
            ...state[VideoTranslationModules.videoServices][UploadVideoTabs.YouTube],
            url: `https://synthesys.live${url}`,
          },
        },
      };
    }
    case GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER: {
      return {
        ...state,
        [VideoTranslationModules.generateVideoToTranslateText]: {
          ...state[VideoTranslationModules.generateVideoToTranslateText],
          data: "",
        },
      };
    }
    case `${GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        [VideoTranslationModules.generateVideoToTranslateText]: {
          ...state[VideoTranslationModules.generateVideoToTranslateText],
          data,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getVideoTranslateProjects = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.allVideos].data;
export const getVideoTranslateProjectsLoading = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.allVideos].isLoading;

export const getVideoTranslateProjectById = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.singleVideo].data;
export const getVideoTranslateProjectByIdLoading = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.singleVideo].isLoading;

export const getGenerateVideoProject = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.generateVideo].data;
export const getGenerateVideoLoading = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.generateVideo].isLoading;

export const faceDetectData = (state: StoreType) => state.videoTranslation[VideoTranslationModules.faceDetect].data;
export const faceDetectLoading = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.faceDetect].isLoading;
export const faceDetectIsCompleted = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.faceDetect].isFaceDetectCompeted;

export const getVideoService = (state: StoreType) => state.videoTranslation[VideoTranslationModules.videoServices];
export const getTranslatedText = (state: StoreType) =>
  state.videoTranslation[VideoTranslationModules.generateVideoToTranslateText];

export default videoTranslationReducer;
