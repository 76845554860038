export const FORM_TEXT: Record<string, any> = {
  login: {
    title: "Welcome to Synthesys ✌️",
    question: "Don't have an account yet?",
    anotherPage: "Sign up",
    submitButton: "Sign In",
  },
  register: {
    title: "Sign up for free minutes! 👏",
    question: "Already have an account?",
    anotherPage: "Sign in",
    submitButton: "Join",
  },
};
