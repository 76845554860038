import styled from "styled-components";

import { Fragment, useContext } from "react";
import SelectPlanButton from "./SelectPlanButton";
import { PricingContext } from "./PricingProvider";
import {
  defaultColumns,
  imageTable,
  renderSpeedColumns,
  videoComparisonTable,
  videoTable,
  voicesTable,
} from "../../../mocks/plansTableMocks";
import { IExternalLinks } from "../../../lib/routeUtils";
import { InfoIcon1818White } from "../../../components/Icons/Icons";
import Button, { Color } from "./Button";
import Tooltip from "./Tooltip";
import { UserPlanPeriod } from "../../../types/plans";
import { getChangeSubscriptions, getUserPlans } from "../../../redux/reducers/plansReducer";
import { useDispatch, useSelector } from "react-redux";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { PlanTypes } from "./PlanCard";

const PlansTableMobile = () => {
  const { isYearly } = useContext(PricingContext);

  const dispatch = useDispatch();

  const plans = useSelector(getUserPlans);
  const changeSubscriptions = useSelector(getChangeSubscriptions);

  const activePlans = plans.filter((item) => !item.isCancelled);

  const handleUpdatePlan = (title: string) => {
    if (!changeSubscriptions.length || !activePlans.length) return;
    const existingUserLicenseId = activePlans[0].userLicenseID;

    const newPLan = changeSubscriptions.find((item) => {
      const samePeriod =
        (item.duration === UserPlanPeriod.monthly && !isYearly) ||
        (item.duration === UserPlanPeriod.yearly && isYearly);

      const splittedProductName = item.title.toLocaleLowerCase().split(" ").includes(title.toLowerCase());

      return splittedProductName && samePeriod;
    });

    if (!newPLan) return;

    dispatch(
      updatePopup({
        popup: Popups.changeSubscriptionPopup,
        status: true,
        prefilled: {
          existingUserLicenseId: existingUserLicenseId,
          newPlanId: newPLan.planID,
        },
      }),
    );
  };

  return (
    <Table>
      <thead>
        <TableRow>
          {defaultColumns
            .slice(1)
            .map(({ header, yearlyPrice, monthlyPrice, yearlyTrigger, monthlyTrigger, planType, buttonColor }) => {
              const isCurrentPlan = !!plans.find((item) => {
                const active = !item.isCancelled;

                const samePeriod =
                  (item.period === UserPlanPeriod.monthly && !isYearly) ||
                  (item.period === UserPlanPeriod.yearly && isYearly);

                const splittedProductName = item.productName
                  .toLocaleLowerCase()
                  .split(" ")
                  .includes(header.toLowerCase());
                return samePeriod && splittedProductName && active;
              });

              return (
                <TableHeaderCell key={header}>
                  <p className="th-title">{header}</p>
                  <span className="th-price-button">
                    {isYearly ? (
                      <span className="th-price">${yearlyPrice}</span>
                    ) : (
                      <span className="th-price">${monthlyPrice}</span>
                    )}
                    {header !== "" ? (
                      header === "Free" ? (
                        <Button color={buttonColor as Color} linkPath={IExternalLinks.tryForFree} type="link">
                          {activePlans.length ? "Update subscription" : "Start for free"}
                        </Button>
                      ) : (
                        <SelectPlanButton
                          popupHash={isYearly ? yearlyTrigger! : monthlyTrigger!}
                          buttonColor={buttonColor}
                          selected={isCurrentPlan}
                          hasSubscription={!!activePlans.length}
                          updateSubscription={handleUpdatePlan.bind(null, header)}
                          planType={planType as PlanTypes}
                        />
                      )
                    ) : null}
                  </span>
                </TableHeaderCell>
              );
            })}
        </TableRow>
      </thead>
      <tbody>
        <RenderSpeed>
          {renderSpeedColumns.slice(0, 1).map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <p className="speedContentTitle">{header}</p>
            </td>
          ))}
        </RenderSpeed>
        <RenderSpeed>
          {renderSpeedColumns.slice(1).map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <span className="speedContent mode">
                {header}
                {tooltip && (
                  <span className="tooltipWrapper">
                    <InfoIcon1818White />
                    <Tooltip title={tooltip} />
                  </span>
                )}
              </span>
              {priority && <p className="speedContent">{priority}</p>}
            </td>
          ))}
        </RenderSpeed>
        <HeadingRow>
          <td>
            {voicesTable.heading.icon}
            {voicesTable.heading.title}
          </td>
        </HeadingRow>
        {voicesTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {voicesTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {voicesTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {videoTable.heading.icon}
            {videoTable.heading.title}
          </td>
        </HeadingRow>
        {videoTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {videoTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {videoTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {imageTable.heading.icon}
            {imageTable.heading.title}
          </td>
        </HeadingRow>
        {imageTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {imageTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {imageTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
        <HeadingRow>
          <td>
            {videoComparisonTable.heading.icon}
            {videoComparisonTable.heading.title}
          </td>
        </HeadingRow>
        {videoComparisonTable.table.map((item) => (
          <Fragment key={item.id}>
            <SubHeadingRow>
              {videoComparisonTable.columns.slice(0, 1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </SubHeadingRow>
            <TableRow>
              {videoComparisonTable.columns.slice(1).map(({ accessor, id }) => (
                <TableCell key={id}>{item[accessor]}</TableCell>
              ))}
            </TableRow>
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  display: none;

  @media (max-width: 767px) {
    border-spacing: 0;
    border: 1px solid #d2d2d9;

    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    background: #f0f0f3;
    overflow: hidden;

    tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`;

const TableRow = styled.tr`
  display: flex;
  width: 100%;

  th,
  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    width: 100%;

    :last-child {
      border-right: none;
    }
  }

  th {
    :last-child {
      background: #d8e9f6;
    }
  }
`;

const TableHeaderCell = styled.th`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .th-price-button {
    width: 100%;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }

  .th-title {
    color: #191b1f;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .th-price {
    color: #6f7074;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  a {
    width: 100%;
  }

  .primaryButton {
    width: 100%;
    padding: 8px 7px;

    .primaryButtonText {
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
    }
  }

  @media (max-width: 420px) {
    .th-price {
      font-size: 10px;
    }
  }
`;

const RenderSpeed = styled.tr`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  width: 100%;
  display: flex;

  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    width: 100%;
    background: linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    :first-child > .mode > .tooltipWrapper > .tooltip {
      right: unset;
      left: 0;
    }

    :last-child {
      border-right: none;
    }

    .speedContentTitle {
      align-self: center;
      flex-grow: 1;
    }

    .speedContent,
    .speedContentTitle {
      color: #ffffff;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      line-height: normal;
      position: relative;
    }

    .mode {
      max-width: calc(100% - 18px);
      display: flex;
      align-items: center;
      gap: 4px;

      .tooltipWrapper {
        display: flex;
        cursor: help;

        :hover .tooltip {
          opacity: 1;
        }

        .tooltip {
          max-width: 190px;
        }

        @media (max-width: 420px) {
          right: -20px;
        }
      }
    }

    @media (max-width: 420px) {
      .speedContent {
        font-size: 10px;
      }
    }
  }
`;

const TableCell = styled.td`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  justify-content: center;
  color: #191b1f;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;

  .checkedIcon {
    display: block;
    width: 15px;
    height: auto;
  }
`;

const HeadingRow = styled.tr`
  width: 100%;

  td {
    border-spacing: 0;
    border-top: 1px solid #d2d2d9;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #eaeaf1;
    padding: 8px;

    color: #191b1f;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;

    svg {
      width: 16px;
      height: auto;
    }
  }
`;

const SubHeadingRow = styled.tr`
  width: 100%;

  td {
    border-spacing: 0;
    border-top: 1px solid #d2d2d9;

    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f0f0f3;
    padding: 8px;

    color: #191b1f;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;

    svg {
      width: 18px;
      height: auto;
    }
  }
`;

export default PlansTableMobile;
