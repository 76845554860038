export const InfoIcon1818White = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.37805 15.75C13.106 15.75 16.1281 12.7279 16.1281 9C16.1281 5.27208 13.106 2.25 9.37805 2.25C5.65013 2.25 2.62805 5.27208 2.62805 9C2.62805 12.7279 5.65013 15.75 9.37805 15.75Z"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.81555 8.4375H9.37805V12.375H9.94055"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.37805 6.75C9.84404 6.75 10.2218 6.37224 10.2218 5.90625C10.2218 5.44026 9.84404 5.0625 9.37805 5.0625C8.91206 5.0625 8.5343 5.44026 8.5343 5.90625C8.5343 6.37224 8.91206 6.75 9.37805 6.75Z"
      fill="white"
    />
  </svg>
);
