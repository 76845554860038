import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/themeReducer";

export const SquaresFourIcon = () => {
  const theme = useSelector(getTheme);
  const stroke = theme === "light" ? "#231F20" : "#ffffff";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 3.75H3.75V8.75H8.75V3.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M16.25 3.75H11.25V8.75H16.25V3.75Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M8.75 11.25H3.75V16.25H8.75V11.25Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M16.25 11.25H11.25V16.25H16.25V11.25Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
    </svg>
  );
};
