import React, { useState } from "react";
import Modal from "../Modal/Modal";
import { useSelector } from "react-redux";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import styled from "styled-components";
import { CircleCheckReversedicon } from "../Icons/Icons";
import RadioButton from "../RadioButton/RadioButton";
import Button, { ButtonThemes } from "../Button/Button";
import { useDispatch } from "react-redux";
import { cancelSubscriptionServer } from "../../redux/actions/plansActions";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import Checkbox from "../Checkbox/Checkbox";

interface Props {
  open: boolean;
  onClose: () => void;
}

const checkOptions = [
  {
    id: 1,
    label: "Did the service meet your expectations?",
  },
  {
    id: 2,
    label: "Did you encounter any difficulties or challenges while using the service?",
  },
  {
    id: 3,
    label: "Did you find the service to be too expensive?",
  },
  {
    id: 4,
    label: "Did you discover an alternative service that better suited your needs?",
  },
  {
    id: 5,
    label: "Did you experience any technical issues or bugs with the service?",
  },
];

const SubscriptionCanceledPopup = ({ onClose, open }: Props) => {
  const [checkedReasons, setCheckedReasons] = useState<number[]>([]);

  const prefilled = useSelector(getPrefilled);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!prefilled?.invoiceID) return;
    dispatch(cancelSubscriptionServer({ invoiceID: prefilled.invoiceID }));
    dispatch(updatePopup({ popup: Popups.subscriptionCanceledPopup, status: false }));
  };

  const hadnleCheckboxChange = (event: any, id: number) => {
    if (event.target.checked) {
      if (checkedReasons.length === 3) {
        return;
      }
      setCheckedReasons((items) => [...items, id]);
    } else {
      setCheckedReasons((items) => items.filter((item) => item !== id));
    }
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth={630} maxHeight={260}>
      <Wrapper>
        <Top>
          <TitleWrapper>
            <CircleCheckReversedicon />
            <Title>Cancellation is one step away</Title>
          </TitleWrapper>
          <CancelationReasonSection>
            <Descriprion>
              Let us know why you're leaving. We're always striving to improve our product, and your feedback would be
              incredibly valuable to us.
            </Descriprion>
            <ReasonsWrapper>
              {checkOptions.map((item) => (
                <Checkbox
                  checked={checkedReasons.includes(item.id)}
                  label={item.label}
                  key={item.id}
                  onChange={(event) => hadnleCheckboxChange(event, item.id)}
                />
              ))}
              <Textfield rows={3} placeholder="Add any additional feedback (optional) " />
            </ReasonsWrapper>
          </CancelationReasonSection>
        </Top>
        <Actions>
          {/* <ContinueButton buttonTheme={ButtonThemes.Secondary} text="No thanks " onClick={onClose} /> */}
          <Button text="Submit" onClick={handleSubmit} />
        </Actions>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
`;

const CancelationReasonSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

const Descriprion = styled.div`
  color: #3b3d47;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
`;

const ReasonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  & label {
    margin: 0;
    padding-left: 30px;
  }

  & p {
    color: ${({ theme }) => theme.primaryText};
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
  }

  & p:before {
    margin-right: 8px;
  }
`;

const Textfield = styled.textarea`
  color: ${({ theme }) => theme.primaryText};
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 16px;
  border-radius: 16px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.mainCardShadowPricing};
  border: none;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;
`;

export default SubscriptionCanceledPopup;
