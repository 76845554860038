import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReactElement, useEffect } from "react";

import { getIsAuthorized } from "../redux/reducers/authReducer";

interface Props {
  children: ReactElement;
}

const BotsonicWidget = ({ children }: Props) => {
  const location = useLocation();
  const isAuthorized = useSelector(getIsAuthorized);

  useEffect(() => {
    if (!isAuthorized) return;

    const allowedPaths = ["/ai-images", "/actors", "/ai-humans", "/signin", "/signup", "/settings"];

    const isRestrictedPath = allowedPaths.some((path) => location.pathname.includes(path));

    if (isRestrictedPath) {
      return;
    }

    const initializeBotsonic = () => {
      (window as any)["botsonic_widget"] = "Botsonic";
      (window as any)["Botsonic"] =
        (window as any)["Botsonic"] ||
        function (...innerArgs: any[]) {
          ((window as any)["Botsonic"].q = (window as any)["Botsonic"].q || []).push(innerArgs);
        };
      const js = document.createElement("script");
      const fjs = document.getElementsByTagName("script")[0];

      js.id = "Botsonic";
      js.src = "https://widget.writesonic.com/CDN/botsonic.min.js";
      js.async = true;

      if (fjs) {
        fjs.parentNode?.insertBefore(js, fjs);
      } else {
        document.head.appendChild(js);
      }

      (window as any)["Botsonic"]("init", {
        serviceBaseUrl: "https://api.botsonic.ai",
        token: "663ba0e5-cec7-46d0-8c16-fe4123caa7e2",
      });
    };

    initializeBotsonic();

    return () => {
      const botsonicScript = document.getElementById("Botsonic-wrapper");
      if (botsonicScript && botsonicScript.parentNode) {
        botsonicScript.parentNode.removeChild(botsonicScript);
      }
    };
  }, [location]);

  return children;
};

export default BotsonicWidget;
