export const InfoIcon1818Grey = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.37811 15.75C13.106 15.75 16.1281 12.7279 16.1281 9C16.1281 5.27208 13.106 2.25 9.37811 2.25C5.65019 2.25 2.62811 5.27208 2.62811 9C2.62811 12.7279 5.65019 15.75 9.37811 15.75Z"
      stroke="#6F7074"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.81561 8.4375H9.37811V12.375H9.94061"
      stroke="#6F7074"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.37811 6.75C9.8441 6.75 10.2219 6.37224 10.2219 5.90625C10.2219 5.44026 9.8441 5.0625 9.37811 5.0625C8.91212 5.0625 8.53436 5.44026 8.53436 5.90625C8.53436 6.37224 8.91212 6.75 9.37811 6.75Z"
      fill="#6F7074"
    />
  </svg>
);
