import React, { useContext } from "react";
import styled from "styled-components";
import TextFontSize from "./TextFontSize";
import TextFontFamily from "./TextFontFamily";
import TextLineHeight from "./TextLineHeight";
import TextAlign from "./TextAlign";
import FontStyle from "./FontStyle";
import TextColor from "./TextColor";
import TextOpacity from "./TextOpacity";
import TextLayers from "./TextLayers";
import { VideoEditorContext } from "../../../../../App";
import { ObjectTypes, SceneObject, Text } from "../../../../../types/scene";

const TextActions = () => {
  const { currentScene, handleSceneObjectChange, fontList, selectedFont, handleChangeSelected, setScenes } =
    useContext(VideoEditorContext);

  const currentObject = currentScene?.objects.find(
    (obj: SceneObject) => obj.object.id === currentScene.activeObjectId && obj.type === ObjectTypes.texts,
  )?.object as Text | undefined;

  return currentObject ? (
    <Wrapper>
      <TextFontSize currentObject={currentObject} />
      <TextFontFamily
        handleSceneObjectChange={handleSceneObjectChange}
        currentObject={currentObject}
        fontList={fontList}
        selectedFont={selectedFont}
        handleChangeSelected={handleChangeSelected}
      />
      {/* <TextLineHeight handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} /> */}
      <VerticalDivider />
      <TextAlign handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} />
      <FontStyle handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} />
      <TextColor handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} />
      <TextOpacity handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} />
      <TextLayers />
    </Wrapper>
  ) : (
    <div />
  );
};

const Wrapper = styled.div`
  padding: 0px 24px;
  display: flex;
  height: 48px;
  align-items: center;
  column-gap: 14px;
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 12px;
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background: ${({ theme }) => theme.primaryText}19;
`;

export default TextActions;
