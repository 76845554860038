import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { Product } from "../../types/product";
import Modal from "../Modal/Modal";
import ProductCard from "../ProductCard/ProductCard";

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateNewProjectPopup = ({ open, onClose }: Props) => {
  const [products, setProducts] = useState<Product[]>();
  const prefilled = useSelector(getPrefilled);

  useEffect(() => {
    const newProducts = prefilled.map((prefill: Product) => ({
      title: prefill.title,
      description: prefill.description,
      image: prefill.image,
      link: prefill.link,
    }));

    setProducts(newProducts);
  }, [prefilled]);
  // modifiedProducts

  return (
    <Wrapper>
      <Modal className="create-new-project" title="Create new project" open={open} onClose={onClose} maxHeight={436}>
        <ProductWrapper>
          {products?.map((product) => (
            <ProductCard
              key={product.title}
              image={product.image}
              title={product.title}
              description={product.description}
              link={product.link}
              onClose={onClose}
            />
          ))}
        </ProductWrapper>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-wrapper {
    @media (max-width: 600px) {
      max-height: 100vh;
    }
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 16px;
  row-gap: 12px;
  column-gap: 12px;
  height: 100%;
  flex-wrap: nowrap;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 210px;
    min-width: 210px;
    width: 100%;
    max-height: 340px;
    height: 100%;
    min-height: 340px;
    background: ${({ theme }) => theme.createNewProjectPopupBackground};
    box-shadow: ${({ theme }) => theme.createNewProjectPopupShadow};

    & > img {
      max-width: inherit;
      min-height: 340px;
    }

    :after {
      background: ${({ theme }) => theme.mainCardPopupBackground};
      height: 100%;
      max-height: 340px;
      bottom: 0;
      top: auto;
      background: ${({ theme }) => theme.createNewProjectPopupBackground};
      box-shadow: ${({ theme }) => theme.createNewProjectPopupShadow};
    }

    & > .text-with-actions {
      top: auto;
      left: auto;
      bottom: 16px;
      right: 16px;
    }

    & > .button-wrapper {
      right: 16px;
    }

    & > div > div {
      span:first-child {
        font-size: 14px;
      }

      span:last-child {
        font-size: 12px;
      }
    }

    @media (max-width: 1001px) {
      max-height: 340px;
      max-width: 200px;

      & > img {
        min-height: 340px;
        max-height: 340px;
      }
      &:after {
        max-height: 340px;
        height: 100%;
      }
    }

    @media (max-width: 756px) {
      width: calc((100% - 12px) / 2);
      min-width: auto;

      & > img {
        max-width: inherit;
      }

      :nth-child(4),
      :nth-child(5) {
        & > img {
          max-width: inherit;
        }
      }
    }
    @media (max-width: 550px) {
      max-width: 100%;
      width: 100%;
      max-height: 80px;
      min-height: 80px;
      background-position: top;
    }
  }
  @media (max-width: 550px) {
    flex-direction: column;
    & > div {
      &:nth-child(1) {
        background: url(/images/home/ai-voices-popup2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      :nth-child(2) {
        background: url(/images/home/ai-humans-popup2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      :nth-child(3) {
        background: url(/images/home/ai-images-popup2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      & > img {
        display: none;
      }
      & > div {
        :after {
          min-height: 80px;
        }
      }
    }
  }
`;

export default CreateNewProjectPopup;
