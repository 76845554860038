import React, { RefObject } from "react";
import styled from "styled-components";
import { Slider as SliderFromLib, SliderThumb as SliderThumbFromLib } from "@mui/material";

import { VideoCursorIcon } from "../../../components/Icons/Icons";

interface Props {
  value: number[];
  min?: number;
  max: number;
  previewRefs: RefObject<HTMLCanvasElement>[];
  step?: number;
  currentTime: number;
  cursorPosition: number;
  onChange: (event: Event, newValue: number | number[]) => void;
  videoRef: RefObject<HTMLVideoElement>;
  setCurrentTime: (currentTime: number) => void;
}

const Slider = ({
  value,
  min = 0,
  max,
  step = 1,
  previewRefs,
  videoRef,
  currentTime,
  cursorPosition,
  onChange,
  setCurrentTime,
}: Props) => {
  const handleCurrentTime = (e: any) => {
    const newCurrentTime = Number(e.target.value);
    if (videoRef.current) {
      videoRef.current.currentTime = newCurrentTime;
      setCurrentTime(newCurrentTime);
    }
  };

  return (
    <Wrapper>
      <SliderFromLib slots={{ thumb: SliderThumb }} step={step} min={min} max={max} value={value} onChange={onChange} />
      <input
        type="range"
        id="video-range"
        name="video-range"
        min={min}
        max={max}
        value={currentTime}
        onChange={handleCurrentTime}
      />
      <ImagesWrapper>
        {previewRefs.map((ref, idx) => (
          <div key={idx} style={{ height: "66px", width: `${70 * (16 / 9)}px`, position: "relative" }}>
            <canvas ref={ref} style={{ flex: 1 }} />
          </div>
        ))}
        <CursorPosition style={{ left: `${cursorPosition}%` }}>
          <VideoCursorIcon />
        </CursorPosition>
      </ImagesWrapper>
    </Wrapper>
  );
};

type ThumbProps = React.HTMLAttributes<unknown>;

const SliderThumb = (props: ThumbProps) => {
  const { children, ...other } = props;

  return (
    <SliderThumbStyled {...other}>
      {children}
      <Line />
      <Thumb>
        <div />
      </Thumb>
    </SliderThumbStyled>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 68px;
  padding: 1px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }

  & .MuiSlider-root {
    padding: 0;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & .MuiSlider-thumb {
      z-index: 2;

      &.Mui-active {
        box-shadow: none;
      }

      &:last-of-type {
        transform: rotate(180deg) translate(100%, 50%);
      }
    }
  }
`;

const SliderThumbStyled = styled(SliderThumbFromLib)`
  height: 100%;
  width: 9px;
  color: transparent;
  border-radius: 0;
  transform: translate(0, -50%);
`;

const Thumb = styled.div`
  width: 8px;
  height: 52px;
  border-radius: 0 8px 8px 0;
  background: ${({ theme }) => theme.button};
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 2px;
    height: 32px;
    border-radius: 20px;
    background: ${({ theme }) => theme.white};
  }
`;

const Line = styled("div")`
  width: 1px;
  height: 100%;
  background: ${({ theme }) => theme.button};
`;

const ImagesWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  display: flex;
  overflow: hidden auto;
  pointer-events: none;
  background-color: #191b1f;
  overflow-y: hidden;

  & > div > canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CursorPosition = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
`;

export default Slider;
