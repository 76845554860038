export const SearchIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.30507 13.9436C5.99212 13.9436 4.70865 13.5542 3.61697 12.8248C2.52529 12.0954 1.67442 11.0586 1.17198 9.84558C0.669531 8.63257 0.538068 7.2978 0.794213 6.01008C1.05036 4.72235 1.68261 3.5395 2.611 2.6111C3.5394 1.6827 4.72226 1.05045 6.00998 0.794306C7.29771 0.538162 8.63247 0.669624 9.84549 1.17207C11.0585 1.67452 12.0953 2.52538 12.8247 3.61706C13.5542 4.70874 13.9435 5.99221 13.9435 7.30517C13.942 9.06533 13.2421 10.753 11.9975 11.9976C10.7529 13.2422 9.06523 13.9421 7.30507 13.9436ZM7.30507 2.07918C6.27147 2.07918 5.26108 2.38568 4.40167 2.95992C3.54226 3.53416 2.87243 4.35034 2.47689 5.30527C2.08135 6.26019 1.97786 7.31096 2.1795 8.32471C2.38115 9.33845 2.87888 10.2696 3.60974 11.0005C4.34061 11.7314 5.27179 12.2291 6.28553 12.4307C7.29928 12.6324 8.35005 12.5289 9.30497 12.1333C10.2599 11.7378 11.0761 11.068 11.6503 10.2086C12.2246 9.34916 12.5311 8.33877 12.5311 7.30517C12.5296 5.91961 11.9785 4.59123 10.9988 3.61149C10.019 2.63175 8.69063 2.08068 7.30507 2.07918Z"
      fill="#191B1F"
    />
    <path
      d="M16.6269 17.3332C16.5341 17.3335 16.4421 17.3154 16.3563 17.28C16.2705 17.2446 16.1925 17.1926 16.1269 17.1269L10.9998 11.9998C10.8672 11.8672 10.7927 11.6874 10.7927 11.4998C10.7927 11.3123 10.8672 11.1324 10.9998 10.9998C11.1324 10.8672 11.3123 10.7927 11.4998 10.7927C11.6873 10.7927 11.8672 10.8672 11.9998 10.9998L17.1269 16.1269C17.2584 16.2602 17.3321 16.4398 17.3321 16.6269C17.3321 16.8141 17.2584 16.9937 17.1269 17.1269C17.0613 17.1926 16.9833 17.2446 16.8975 17.28C16.8117 17.3154 16.7197 17.3335 16.6269 17.3332Z"
      fill="#191B1F"
    />
  </svg>
);
