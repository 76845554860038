export const DownloadIcon = () => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.16904 8.06325C6.16747 8.04237 6.16668 8.02128 6.16668 8V1.33333C6.16668 0.873333 6.53918 0.5 7.00001 0.5C7.46084 0.5 7.83334 0.873333 7.83334 1.33333V7.99985L9.83334 6.5C10.2017 6.2225 10.7242 6.29833 11 6.66667C11.2767 7.035 11.2017 7.5575 10.8333 7.83333L7.50001 10.3333C7.35251 10.4442 7.17584 10.5 7.00001 10.5C6.83251 10.5 6.66501 10.4492 6.52084 10.3483L3.18751 8.00333C2.81084 7.73833 2.72001 7.21833 2.98501 6.8425C3.25001 6.46583 3.76918 6.375 4.14584 6.64L6.16904 8.06325ZM2.00001 12.1667V13H12V12.1667C12 11.7083 12.375 11.3333 12.8333 11.3333C13.2917 11.3333 13.6667 11.7083 13.6667 12.1667V13.8333C13.6667 14.2917 13.2917 14.6667 12.8333 14.6667H1.16668C0.708344 14.6667 0.333344 14.2917 0.333344 13.8333V12.1667C0.333344 11.7083 0.708344 11.3333 1.16668 11.3333C1.62501 11.3333 2.00001 11.7083 2.00001 12.1667Z"
      fill="white"
    />
  </svg>
);
