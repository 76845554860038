export const NotificateIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F0F0F3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5143 24.0008L14.6944 22.8188C15.0724 22.4408 15.2804 21.9388 15.2804 21.4048V16.7278C15.2804 15.3708 15.8704 14.0738 16.9004 13.1718C17.9384 12.2618 19.2604 11.8618 20.6374 12.0428C22.9644 12.3518 24.7194 14.4558 24.7194 16.9378V21.4048C24.7194 21.9388 24.9274 22.4408 25.3044 22.8178L26.4854 24.0008H13.5143ZM21.9994 26.3418C21.9994 27.2408 21.0834 28.0008 19.9994 28.0008C18.9154 28.0008 17.9994 27.2408 17.9994 26.3418V26.0008H21.9994V26.3418ZM28.5204 23.2088L26.7194 21.4048V16.9378C26.7194 13.4568 24.2174 10.4998 20.8994 10.0608C18.9774 9.80481 17.0374 10.3918 15.5824 11.6678C14.1184 12.9498 13.2804 14.7938 13.2804 16.7278L13.2794 21.4048L11.4784 23.2088C11.0094 23.6788 10.8704 24.3778 11.1244 24.9908C11.3794 25.6048 11.9724 26.0008 12.6364 26.0008H15.9994V26.3418C15.9994 28.3598 17.7934 30.0008 19.9994 30.0008C22.2054 30.0008 23.9994 28.3598 23.9994 26.3418V26.0008H27.3624C28.0264 26.0008 28.6184 25.6048 28.8724 24.9918C29.1274 24.3778 28.9894 23.6778 28.5204 23.2088Z"
      fill="#191B1F"
    />
    <path
      d="M32 13C32 16.3137 29.3137 19 26 19C22.6863 19 20 16.3137 20 13C20 9.68629 22.6863 7 26 7C29.3137 7 32 9.68629 32 13Z"
      stroke="#F1F1F4"
      strokeWidth="2"
    />
    <circle cx="26" cy="13" r="5" fill="url(#paint0_linear_493_13317)" />
    <defs>
      <linearGradient
        id="paint0_linear_493_13317"
        x1="23.1538"
        y1="9.30769"
        x2="29.0769"
        y2="16.9231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
