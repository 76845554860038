import { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";

import AuthLayout from "../../layouts/AuthLayout";
import FormikField from "../../components/FormInput/FormikField";
import CircularProgress from "../../components/Icons/CircularProgress";
import { FORM_TEXT } from "../../constants/Form";
import { Google } from "../../components/Icons/Google";
import { SignInFormValues } from "../../forms/auth/signIn/types";
import { signInSchema } from "../../forms/auth/signIn/validation";
import { getSignInFields, initialValuesSignIn } from "../../forms/auth/signIn/form";
import { Oauth, logout, signInServer, signInWithGoogleServer } from "../../redux/actions/authActions";
import { getIsAuthorized, getIsSignInLoading, getIsSignInWithGoogleLoading } from "../../redux/reducers/authReducer";
import { LogoIcon } from "../../components/Icons/LogoIcon";
import { Twitter } from "../../components/Icons/Twitter";

const SignIn = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsSignInLoading);
  const isAuthorized = useSelector(getIsAuthorized);
  const signInWithGoogleLoading = useSelector(getIsSignInWithGoogleLoading);

  const [searchParams] = useSearchParams();
  const [state, setState] = useState("");
  const [redirect, setRedirect] = useState("");

  const [showPassword, setShowPassword] = useState<Record<string, any>>({
    password: false,
  });

  const isRedirectToChat = (redirectString: string) => redirectString.includes("chat.openai.com");

  const onSubmit = (values: SignInFormValues) => {
    const isChatGPT = isRedirectToChat(redirect);
    const newValues = { ...values, FromChatGPT: isChatGPT };

    dispatch(signInServer(newValues));
  };

  const handleShowPassword = (value: boolean, type: string) => {
    setShowPassword({ ...showPassword, [type]: value });
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      dispatch(
        signInWithGoogleServer({
          IdToken: tokenResponse.access_token,
          FromChatGPT: isRedirectToChat(redirect),
        }),
      ),
    onError: () => toast.error("Issue with Google provider. Try again later!"),
  });

  useEffect(() => {
    const redirectUri = searchParams.get("redirect_uri");
    const stateParam = searchParams.get("state");

    if (redirectUri) {
      const decodedRedirectUri = decodeURIComponent(redirectUri);
      setRedirect(decodedRedirectUri);
    }

    if (stateParam) {
      setState(stateParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (redirect && state) {
      // if (isAuthorized) dispatch(logout());

      dispatch(Oauth({ redirect_uri: redirect, state }));
    }
  }, [redirect, state]);

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit: handleFormikSubmit,
  } = useFormik({
    initialValues: initialValuesSignIn,
    validationSchema: signInSchema,
    onSubmit,
  });

  useGoogleOneTapLogin({
    onSuccess: (tokenResponse) =>
      dispatch(
        signInWithGoogleServer({
          IdToken: tokenResponse.credential || "",
          FromChatGPT: isRedirectToChat(redirect),
        }),
      ),
    onError: () => toast.error("Issue with Google provider. Try again later!"),
  });

  const signupUrl = isRedirectToChat(redirect) ? "/signup?redirect=true" : "/signup";

  return (
    <AuthLayout>
      <FormContent>
        <LogoWrapper>
          <LogoIcon />
        </LogoWrapper>
        <Wrapper>
          <FormTitle>{FORM_TEXT.login.title}</FormTitle>
          <FormMain onSubmit={handleFormikSubmit}>
            {getSignInFields.map((field) => (
              <FormikField
                handleBlur={handleBlur}
                key={field.id}
                errors={errors}
                touched={touched}
                field={field}
                handleChange={handleChange}
                values={values}
                isVisible={showPassword[field.name]}
                setIsVisible={handleShowPassword}
              />
            ))}
            <FormForgetPasswordWrapper>
              <a href="/recover-password">Forgot password?</a>
            </FormForgetPasswordWrapper>
            <FormSubmitButton type="submit">
              {isLoading ? <CircularProgress /> : FORM_TEXT.login.submitButton}
            </FormSubmitButton>
            <GoogleAuth onClick={() => login()}>
              <Google />
              <span>Google</span>
            </GoogleAuth>
            <FormLastSection>
              <FormLastQuestion>{FORM_TEXT.login.question}</FormLastQuestion>
              <Link to={signupUrl}>{FORM_TEXT.login.anotherPage}</Link>
            </FormLastSection>
          </FormMain>
          {signInWithGoogleLoading && (
            <Blur>
              <CircularProgress color="#009af7" />
            </Blur>
          )}
          <FormLastSection isInfo>
            <FormLastQuestion>Need help? Contact us at</FormLastQuestion>
            <a href="mailto:support@synthesys.io">support@synthesys.io</a>
          </FormLastSection>
        </Wrapper>
      </FormContent>
    </AuthLayout>
  );
};

const FormContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  justify-content: flex-end;

  @media (max-height: 750px) {
    justify-content: center;
  }
`;

const Wrapper = styled("div")`
  position: relative;
  padding: 56px 72px;
  background: ${({ theme }) => theme.white};
  border-radius: 24px;

  @media (max-width: 1150px) {
    padding: 56px 36px;
  }

  @media (max-height: 750px) {
    padding: 56px 36px 36px;
  }

  @media (max-width: 601px) {
    padding: 56px 24px 24px;
  }
`;

const LogoWrapper = styled("div")`
  position: absolute;
  z-index: 1;
  top: 28px;
  left: 36px;

  svg {
    width: 120px;
    height: 28px;
  }

  @media (max-width: 601px) {
    left: 24px;
  }
`;

const FormTitle = styled("h1")`
  margin-bottom: 12px;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 60px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.signInText};
  text-align: left;

  @media (min-width: 280px) and (max-width: 375px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

const FormMain = styled("form")`
  margin-top: 20px;
  width: 100%;
  min-width: 353px;
  max-width: 353px;

  & > div:first-of-type {
    margin-bottom: 14px;
  }

  & > div > input {
    box-shadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3);
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.reviewText};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset 0px 0px 0px 30px rgba(240, 240, 243, 1) !important;
      -webkit-text-fill-color: ${({ theme }) => theme.reviewText};
    }

    &:focus {
      background-color: ${({ theme }) => theme.inputBackground};
    }
    &::placeholder {
      color: ${({ theme }) => theme.reviewText};
    }
  }
  & > div > span > svg > g > path {
    fill: ${({ theme }) => theme.signInputIcon};
  }

  @media (min-width: 280px) and (max-width: 424px) {
    min-width: 300px;
    max-width: 300px;
    input {
      min-width: auto;
    }
  }

  @media (max-height: 750px) {
    margin-top: 20px;
  }
`;

const GoogleAuth = styled.div`
  display: flex;
  height: 60px;
  padding: 0 12px;
  margin: 24px 0;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.buttonGreyText}44;
  background-color: ${({ theme }) => theme.white};
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.inputBackground};
  }

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.signInText};
  }

  & > svg {
    width: 18px;
    height: 18px;
  }

  @media (min-width: 320px) and (max-width: 750px) {
    height: 45px;
    font-size: 14px;
    margin: 14px 0;

    span {
      font-size: 14px;
    }
  }

  @media (max-height: 750px) {
    height: 45px;
    margin: 14px 0;

    span {
      font-size: 14px;
    }
  }
`;

const FormSubmitButton = styled("button")`
  font-family: Mulish;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  height: 60px;
  margin-top: 32px;
  border: none;
  background: ${({ theme }) => theme.buttonSignIn};
  border-radius: 12px;

  @media (min-width: 320px) and (max-width: 750px) {
    height: 45px;
    font-size: 14px;
    margin-top: 25px;
  }

  @media (max-height: 750px) {
    height: 45px;
    font-size: 14px;
  }
`;

const FormForgetPasswordWrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: -27px;

  a {
    font-family: Mulish;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #c5c5d0;
    margin-top: 4px;
    transition: color 0.3s;
    :hover {
      color: ${({ theme }) => theme.buttonSignIn};
    }
  }

  @media (max-width: 1150px) {
    margin-top: -16px;
  }

  @media (max-height: 750px) {
    margin-top: -16px;
  }
`;

const FormLastQuestion = styled("p")`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.signInText};
  text-align: center;
  opacity: 0.6;
`;

const FormLastSection = styled("div")<{ isInfo?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: center;
  align-items: center;

  a {
    background: ${({ theme }) => theme.button};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: ${({ isInfo }) => (isInfo ? "4px" : "8px")};
    font-family: "Mulish", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.activeMenu};
    -webkit-text-decoration-color: ${({ theme }) => theme.activeMenu};
  }

  @media (min-width: 320px) and (max-width: 750px) {
    margin-top: 20px;
  }

  @media (min-width: 751px) and (max-width: 1150px) {
    margin-top: 32px;
  }

  @media (max-height: 750px) {
    margin-top: 20px;
  }
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  z-index: 2;
`;

export default SignIn;
