export const TemplateIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.3333 14.5333V5.46667C29.3333 3.46667 28.48 2.66667 26.36 2.66667H20.9733C18.8533 2.66667 18 3.46667 18 5.46667V14.5333C18 16.5333 18.8533 17.3333 20.9733 17.3333H26.36C28.48 17.3333 29.3333 16.5333 29.3333 14.5333Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-color"
    />
    <path
      d="M29.3333 26.5333V24.1333C29.3333 22.1333 28.48 21.3333 26.36 21.3333H20.9733C18.8533 21.3333 18 22.1333 18 24.1333V26.5333C18 28.5333 18.8533 29.3333 20.9733 29.3333H26.36C28.48 29.3333 29.3333 28.5333 29.3333 26.5333Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-color"
    />
    <path
      d="M14 17.4667V26.5333C14 28.5333 13.1467 29.3333 11.0267 29.3333H5.64C3.52 29.3333 2.66667 28.5333 2.66667 26.5333V17.4667C2.66667 15.4667 3.52 14.6667 5.64 14.6667H11.0267C13.1467 14.6667 14 15.4667 14 17.4667Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-color"
    />
    <path
      d="M14 5.46667V7.86667C14 9.86667 13.1467 10.6667 11.0267 10.6667H5.64C3.52 10.6667 2.66667 9.86667 2.66667 7.86667V5.46667C2.66667 3.46667 3.52 2.66667 5.64 2.66667H11.0267C13.1467 2.66667 14 3.46667 14 5.46667Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-color"
    />
  </svg>
);
