import { useSelector } from "react-redux";
import styled from "styled-components";
import { getTheme } from "../../../../redux/reducers/themeReducer";

export interface ContentCardData {
  icon: React.ReactElement;
  title: string;
  description: string;
  link?: { to?: string; target?: "_blank" };
  isDisabled?: boolean;
  isNew?: boolean;
}

export interface Props {
  onClick?: () => void;
}

const ContentCard = ({ icon, title, description, isNew, onClick }: ContentCardData & Props) => {
  const themePreset = useSelector(getTheme);

  return (
    <Wrapper onClick={onClick} id={themePreset} isNew={isNew}>
      <IconsWrapper>
        {icon}
        {isNew && <span>NEW</span>}
      </IconsWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ isNew?: boolean }>`
  cursor: pointer;
  min-width: 240px;
  width: 100%;
  max-height: 185px;
  min-height: 161px;
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 3px solid transparent;
  background-color: ${({ theme }) => theme.homeContentCardBg};
  color: ${({ theme }) => theme.homeContentCardText};
  box-shadow: 0px 4px 4px 0px #00000040;
  -webkit-transition: background-color 200ms linear, color 200ms linear;
  -ms-transition: background-color 200ms linear, color 200ms linear;
  transition: background-color 200ms linear, color 200ms linear;

  ${({ isNew, theme }) =>
    isNew &&
    `
    border: 3px solid ${theme.red};
  `}

  svg {
    width: 32px;
    height: 32px;
    path {
      fill: ${({ theme }) => theme.homeContentCardIcon};
    }
    .inner-color {
      fill: ${({ theme }) => theme.homeContentCardIconInner};
      stroke: none;
    }
  }
    .stroke-color {
      stroke: ${({ theme, id }) => (id === "light" ? theme.homeContentCardIcon : theme.homeContentCardIcon)};
      fill: ${({ theme, id }) => (id === "light" ? "none" : theme.homeContentCardIcon)};

    }
  }

  :hover {
    background-color: ${({ theme }) => theme.homeContentCardBgHover};

      ${({ isNew, theme }) =>
        isNew &&
        `
        border: 3px solid ${theme.red};
    `}

    div {
      color: ${({ theme }) => theme.homeContentCardTextHover};
    }

    svg {
      path {
        fill: ${({ theme }) => theme.homeContentCardIconHover};
      }
      .inner-color {
        fill: ${({ theme }) => theme.homeContentCardIconInnerHover};
        stroke: none;
      }
      .stroke-color {
        stroke: ${({ theme, id }) =>
          id === "light" ? theme.homeContentCardIconHover : theme.homeContentCardIconHover};
        fill: ${({ theme, id }) => (id === "light" ? "none" : theme.homeContentCardIconHover)};
      } 
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #e21818;
  }
`;

const Title = styled.div`
  font-family: Mulish;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -1px;
  text-align: left;
  color: ${({ theme }) => theme.homeContentCardText};
  margin: 12px 0px 4px;
`;

const Description = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.homeContentCardText};
`;

export default ContentCard;
