export const AudioIcon = () => (
  <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.5833 18.125V10.875C26.5833 4.83333 24.1666 2.41666 18.125 2.41666H10.875C4.83329 2.41666 2.41663 4.83333 2.41663 10.875V18.125C2.41663 24.1667 4.83329 26.5833 10.875 26.5833H18.125C24.1666 26.5833 26.5833 24.1667 26.5833 18.125Z"
      stroke="url(#paint0_linear_260_69)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6242 20.9042C13.0389 20.9042 14.1858 19.7573 14.1858 18.3425C14.1858 16.9277 13.0389 15.7808 11.6242 15.7808C10.2094 15.7808 9.0625 16.9277 9.0625 18.3425C9.0625 19.7573 10.2094 20.9042 11.6242 20.9042Z"
      stroke="url(#paint1_linear_260_69)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1858 18.3425V9.38877"
      stroke="url(#paint2_linear_260_69)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8654 8.18046L18.6929 9.12292C19.3816 9.3525 19.9375 10.1258 19.9375 10.8508V11.6C19.9375 12.5787 19.1762 13.1225 18.2579 12.8083L15.4304 11.8659C14.7417 11.6363 14.1858 10.863 14.1858 10.138V9.38879C14.1858 8.42212 14.935 7.86629 15.8654 8.18046Z"
      stroke="url(#paint3_linear_260_69)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_260_69"
        x1="7.62176"
        y1="5.57692"
        x2="21.9359"
        y2="23.9808"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_260_69"
        x1="10.166"
        y1="16.4508"
        x2="13.2006"
        y2="20.3524"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_260_69"
        x1="14.4012"
        y1="10.5596"
        x2="15.9409"
        y2="10.7807"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_260_69"
        x1="15.4246"
        y1="8.72079"
        x2="18.1077"
        y2="12.8502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
