import React, { createContext, FC, useState } from "react";

const defaultState = ["default"];

interface MyContextProps {
  linksArray: string[];
  updateObject: (updatedValue: string[]) => void;
}

export const PricingContext = createContext<MyContextProps | undefined>(undefined);

interface PricingProviderProps {
  children: React.ReactNode;
}

const PricingProvider: FC<PricingProviderProps> = ({ children }) => {
  const [linksArray, setLinksArray] = useState<string[]>(defaultState);

  const updateObject = (updatedValue: string[]) => {
    setLinksArray(updatedValue);
  };

  return <PricingContext.Provider value={{ linksArray, updateObject }}>{children}</PricingContext.Provider>;
};

export default PricingProvider;
