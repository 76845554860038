import { AuthModules } from "../../types/auth";

export const SET_IS_AUTHORIZED = "SET_IS_AUTHORIZED";
export const UPDATE_AUTH_LOADING = "UPDATE_AUTH_LOADING";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SIGN_IN_SERVER = "SIGN_IN_SERVER";
export const SIGN_UP_SERVER = "SIGN_UP_SERVER";
export const RECOVER_PASSWORD_SERVER = "RECOVER_PASSWORD_SERVER";
export const RESET_PASSWORD_SERVER = "RESET_PASSWORD_SERVER";
export const LOGOUT = "LOGOUT";
export const GET_TOKEN_USER = "GET_TOKEN_USER";
export const REGISTER_GUEST_USER_SERVER = "REGISTER_GUEST_USER_SERVER";
export const SIGN_IN_WITH_GOOGLE_SERVER = "SIGN_IN_WITH_GOOGLE_SERVER";
export const ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER = "ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER";
export const GET_USER_BY_APPSUMO_TOKEN_SERVER = "GET_USER_BY_APPSUMO_TOKEN_SERVER";
export const GET_OAUTH = "GET_OAUTH";
export const GET_CELLO_TOKEN_SERVER = "GET_CELLO_TOKEN_SERVER";
interface UpdateAuthLoadingProps {
  module: AuthModules;
  isLoading: boolean;
}

interface SignInProps {
  email: string;
  password: string;
  FromChatGPT?: boolean;
}

export interface SignUpProps {
  email: string;
  name: string;
  password: string;
  token: string;
  cello_code?: string;
  FromChatGPT?: boolean;
}

interface RecoverPasswordProps {
  email: string;
}

interface ResetPasswordProps {
  password: string;
  token: string;
}

export interface SignInWithGoogleProps {
  IdToken: string;
  cello_code?: string;
  isSignUp?: boolean;
  FromChatGPT?: boolean;
}

interface AppSumoSignupProps {
  id: string;
  firstName: string;
  lastName: string;
  password: string;
}

interface GetUserByAppSumoTokenProps {
  id: string;
}

export const setIsAuthorized = (isAuthorized: boolean) => ({
  type: SET_IS_AUTHORIZED,
  payload: isAuthorized,
});

export const setAuthToken = ({ isAuthorized, accessToken }: { isAuthorized: boolean; accessToken: string }) => ({
  type: SET_AUTH_TOKEN,
  payload: {
    isAuthorized,
    accessToken,
  },
});

export const updateAuthLoading = ({ module, isLoading }: UpdateAuthLoadingProps) => ({
  type: UPDATE_AUTH_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const signInServer = (data: SignInProps) => ({
  type: SIGN_IN_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/auth/login`,
      data,
    },
  },
});

export const signUpServer = (data: SignUpProps) => ({
  type: SIGN_UP_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/auth/register`,
      data,
    },
  },
});

export const getUserByAppSumoTokenServer = (data: GetUserByAppSumoTokenProps) => ({
  type: GET_USER_BY_APPSUMO_TOKEN_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/auth/getActivateEmailByAppSumoToken?id=${data.id}`,
    },
  },
});

export const activateUserByAppSumoTokenServer = (data: AppSumoSignupProps) => ({
  type: ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/auth/activateUserByAppSumoToken",
      data,
    },
  },
});

export const recoverPasswordServer = (data: RecoverPasswordProps) => ({
  type: RECOVER_PASSWORD_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/auth/forgotPassword`,
      data,
    },
  },
});

export const resetPasswordServer = (data: ResetPasswordProps) => ({
  type: RESET_PASSWORD_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/auth/updateUserPassword`,
      data,
    },
  },
});

export const getTokenUser = (token: string) => ({
  type: GET_TOKEN_USER,
  payload: {
    request: {
      method: "GET",
      url: `/auth/tokenUser?Token=${token}`,
    },
  },
});

export const logout = () => ({
  type: LOGOUT,
});

interface RegisterGuestUserServerProps {
  page?: string;
  otherParams?: Record<string, any>;
}

export const registerGuestUserServer = ({ page, otherParams }: RegisterGuestUserServerProps) => ({
  type: REGISTER_GUEST_USER_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/auth/registerGuestUser",
    },
    page,
    otherParams,
  },
});

export const signInWithGoogleServer = ({ isSignUp, cello_code, IdToken, FromChatGPT }: SignInWithGoogleProps) => ({
  type: SIGN_IN_WITH_GOOGLE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/auth/externalLogin",
      data: {
        IdToken,
        cello_code,
        FromChatGPT,
      },
    },
    isSignUp: isSignUp || false,
  },
});

interface OauthProps {
  redirect_uri: string;
  state: string;
}

export const Oauth = ({ redirect_uri, state }: OauthProps) => ({
  type: GET_OAUTH,
  payload: { redirect_uri, state },
});

export const getCelloTokenServer = () => ({
  type: GET_CELLO_TOKEN_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/user/getCelloToken",
    },
  },
});
