import styled from "styled-components";

import FooterLinks from "./components/FooterLinks";
import Container from "../Container/Container";
import FooterFirstColumn from "./components/FooterFirstColumn";
import { footerCompanyLinks, footerFeaturesLinks, footerProductsLinks, footerUseCases } from "../../mocks/footerMocks";
import FooterCopyright from "./components/FooterCopyright";

const Footer = () => {
  return (
    <OuterWrapper>
      <Container>
        <Wrapper>
          <FooterFirstColumn />
          <ContentWrapper>
            <FooterLinks title="Our products" links={footerProductsLinks} />
            <FooterLinks title="Use Cases" links={footerUseCases} newOrder={2} />
            <FooterLinks title="Features" links={footerFeaturesLinks} newOrder={3} />
            <FooterLinks title="Company" links={footerCompanyLinks} newOrder={1} />
          </ContentWrapper>
          <MobileContentWrapper>
            <MobileColumn>
              <FooterLinks title="Our products" links={footerProductsLinks} />
              <FooterLinks title="Use Cases" links={footerUseCases} newOrder={2} />
            </MobileColumn>
            <MobileColumn firContent>
              <FooterLinks title="Features" links={footerFeaturesLinks} newOrder={3} />
              <FooterLinks title="Company" links={footerCompanyLinks} newOrder={1} />
            </MobileColumn>
          </MobileContentWrapper>
          <CopyrightWrapper>
            <FooterCopyright />
          </CopyrightWrapper>
        </Wrapper>
      </Container>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.footer`
  width: 100%;
  background: #f0f0f3;
  border-top: 1px solid #dbdbde;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  gap: 75px;

  @media (max-width: 1199px) {
    gap: 48px;
  }

  @media (max-width: 999px) {
    gap: 24px;
  }

  @media (max-width: 899px) {
    gap: 32px;
    flex-direction: column;
    padding: 32px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 56px;

  @media (max-width: 1200px) {
    column-gap: 24px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

const MobileContentWrapper = styled.div`
  display: none;

  @media (max-width: 650px) {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  @media (max-width: 399px) {
    justify-content: space-between;
    gap: 24px;
  }
`;

const MobileColumn = styled.div<{ firContent?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: ${({ firContent }) => (firContent ? "fit-content" : "auto")};
`;

const CopyrightWrapper = styled.div`
  display: none;

  @media (max-width: 899px) {
    display: block;
    border-top: 1px solid #dbdbde;
    padding-top: 12px;
  }
`;

export default Footer;
