export const MusicIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9413 8.50654C18.6926 8.50534 17.4954 8.00877 16.6124 7.12581C15.7295 6.24284 15.2329 5.04563 15.2317 3.79694C15.2317 3.49726 15.3507 3.20985 15.5626 2.99794C15.7745 2.78604 16.062 2.66699 16.3616 2.66699C16.6613 2.66699 16.9487 2.78604 17.1606 2.99794C17.3725 3.20985 17.4916 3.49726 17.4916 3.79694C17.4916 4.44664 17.7497 5.06974 18.2091 5.52915C18.6685 5.98856 19.2916 6.24665 19.9413 6.24665C20.241 6.24665 20.5284 6.3657 20.7403 6.57761C20.9522 6.78951 21.0712 7.07692 21.0712 7.3766C21.0712 7.67628 20.9522 7.96368 20.7403 8.17559C20.5284 8.38749 20.241 8.50654 19.9413 8.50654Z"
      fill="white"
    />
    <path
      d="M23.5209 12.0866C23.3723 12.0872 23.2251 12.0584 23.0878 12.0018C22.9504 11.9452 22.8256 11.862 22.7206 11.757C22.6155 11.652 22.5323 11.5272 22.4757 11.3898C22.4192 11.2524 22.3903 11.1052 22.3909 10.9567C22.3909 10.307 22.1328 9.68387 21.6734 9.22446C21.214 8.76505 20.5909 8.50696 19.9412 8.50696C19.6415 8.50696 19.3541 8.38791 19.1422 8.176C18.9303 7.9641 18.8113 7.67669 18.8113 7.37701C18.8113 7.07733 18.9303 6.78993 19.1422 6.57802C19.3541 6.36612 19.6415 6.24707 19.9412 6.24707C21.1899 6.24827 22.3871 6.74484 23.2701 7.6278C24.1531 8.51077 24.6496 9.70798 24.6508 10.9567C24.6514 11.1052 24.6226 11.2524 24.566 11.3898C24.5095 11.5272 24.4263 11.652 24.3212 11.757C24.2162 11.862 24.0914 11.9452 23.954 12.0018C23.8166 12.0584 23.6694 12.0872 23.5209 12.0866Z"
      fill="white"
    />
    <path
      d="M12.7458 29.3333C11.8071 29.3333 10.8896 29.055 10.1092 28.5335C9.32872 28.012 8.72045 27.2709 8.36125 26.4037C8.00206 25.5365 7.90807 24.5823 8.09119 23.6617C8.27431 22.7411 8.7263 21.8955 9.39 21.2318C10.0537 20.5681 10.8993 20.1161 11.8199 19.933C12.7405 19.7499 13.6947 19.8439 14.5619 20.203C15.4291 20.5622 16.1702 21.1705 16.6917 21.951C17.2132 22.7314 17.4915 23.6489 17.4915 24.5876C17.4903 25.8458 16.9899 27.0523 16.1002 27.942C15.2105 28.8317 14.0041 29.3321 12.7458 29.3333ZM12.7458 22.1017C12.2541 22.1017 11.7735 22.2475 11.3647 22.5206C10.9559 22.7938 10.6373 23.182 10.4491 23.6363C10.261 24.0905 10.2117 24.5903 10.3077 25.0725C10.4036 25.5547 10.6403 25.9977 10.988 26.3453C11.3356 26.693 11.7786 26.9298 12.2608 27.0257C12.743 27.1216 13.2428 27.0724 13.6971 26.8842C14.1513 26.6961 14.5395 26.3774 14.8127 25.9686C15.0858 25.5598 15.2316 25.0792 15.2316 24.5876C15.2304 23.9286 14.9682 23.297 14.5022 22.8311C14.0363 22.3652 13.4047 22.1029 12.7458 22.1017Z"
      fill="white"
    />
    <path
      d="M16.3616 25.7178C16.062 25.7178 15.7745 25.5988 15.5626 25.3869C15.3507 25.175 15.2317 24.8876 15.2317 24.5879V3.79694C15.2317 3.49726 15.3507 3.20985 15.5626 2.99794C15.7745 2.78604 16.062 2.66699 16.3616 2.66699C16.6613 2.66699 16.9487 2.78604 17.1606 2.99794C17.3725 3.20985 17.4916 3.49726 17.4916 3.79694V24.5879C17.4916 24.8876 17.3725 25.175 17.1606 25.3869C16.9487 25.5988 16.6613 25.7178 16.3616 25.7178Z"
      fill="white"
    />
  </svg>
);
