import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import { GET_SHAPES_SERVER, updateShapeLoading } from "../actions/shapesAction";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { ShapesModules } from "../../types/shapes";

const shapesSaga = [
  takeEvery(`${GET_SHAPES_SERVER}`, handleGetShapesServer),
  takeEvery(`${GET_SHAPES_SERVER}_FAIL`, handleGetShapesServerFail),
  takeEvery(`${GET_SHAPES_SERVER}_SUCCESS`, handleGetShapesServerSuccess),
];

function* handleGetShapesServer() {
  yield put(updateShapeLoading({ module: ShapesModules.shapesList, isLoading: true }));
}

function* handleGetShapesServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_SHAPES.toast);
  yield put(updateShapeLoading({ module: ShapesModules.shapesList, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_SHAPES,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetShapesServerSuccess() {
  yield put(updateShapeLoading({ module: ShapesModules.shapesList, isLoading: false }));
}

export default shapesSaga;
