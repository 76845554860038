export const Flash = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.1875 12.875C31.3125 13.0625 31.25 13.3125 31.125 13.5L12.375 38.5C12.1875 38.75 11.875 38.8125 11.5625 38.6875C11.3125 38.5625 11.1875 38.25 11.25 37.9375L17.375 19.125H10C9.8125 19.125 9.5625 19 9.4375 18.8125C9.3125 18.625 9.3125 18.375 9.4375 18.1875L17.75 1.625C17.8125 1.375 18.0625 1.25 18.3125 1.25H29.6875C29.9375 1.25 30.125 1.375 30.25 1.5625C30.375 1.75 30.375 2 30.25 2.1875L24.1875 12.5H30.625C30.875 12.5 31.0625 12.625 31.1875 12.875ZM36.25 11.875H33.75C33.375 11.875 33.125 12.125 33.125 12.5C33.125 12.875 33.375 13.125 33.75 13.125H36.25C36.625 13.125 36.875 12.875 36.875 12.5C36.875 12.125 36.625 11.875 36.25 11.875ZM34.375 15C34.125 14.8125 33.6875 14.875 33.5 15.125C33.3125 15.375 33.375 15.8125 33.625 16L35.625 17.5C35.875 17.6875 36.3125 17.625 36.5 17.375C36.6875 17.125 36.625 16.6875 36.375 16.5L34.375 15ZM34.375 10L36.375 8.5C36.625 8.3125 36.6875 7.875 36.5 7.625C36.3125 7.375 35.875 7.3125 35.625 7.5L33.625 9C33.375 9.1875 33.3125 9.625 33.5 9.875C33.6875 10.125 34.0625 10.1875 34.375 10ZM6.875 18.25H4.375C4 18.25 3.75 18.5 3.75 18.875C3.75 19.25 4 19.5 4.375 19.5H6.875C7.25 19.5 7.5 19.25 7.5 18.875C7.5 18.5 7.25 18.25 6.875 18.25ZM6.25 16.3125C6.5 16.5 6.9375 16.4375 7.125 16.1875C7.3125 15.9375 7.25 15.5 7 15.3125L5 13.875C4.75 13.6875 4.3125 13.75 4.125 14C3.9375 14.25 4 14.6875 4.25 14.875L6.25 16.3125ZM6.25 21.375L4.25 22.875C4 23.0625 3.9375 23.5 4.125 23.75C4.3125 24 4.75 24.0625 5 23.875L7 22.375C7.25 22.1875 7.3125 21.75 7.125 21.5C6.9375 21.1875 6.5625 21.125 6.25 21.375Z"
      fill="url(#paint0_linear_1_698)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_698"
        x1="10.8846"
        y1="6.15385"
        x2="33.617"
        y2="31.9713"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
