export enum IPages {
  main = "main",
  voice = "voice",
  video = "video",
  image = "image",
  pricing = "pricing",
  pricing2 = "pricing2",
  api = "aiApi",
  features = "features",
  resources = "resources",

  useCases = "useCases",
  videoAds = "videoAds",
  presentationVideos = "presentationVideos",
  brandingVideos = "brandingVideos",
  explainerVideos = "explainerVideos",
  trainingVideos = "trainingVideos",
  educationalVideos = "educationalVideos",
  eLearning = "eLearning",
  voiceoverAds = "voiceoverAds",
  voiceoverYouTube = "voiceoverYouTube",
  voiceoverAnimation = "voiceoverAnimation",
  voiceoverPodcast = "voiceoverPodcast",

  about = "about",
  refundPolicy = "refundPolicy",
  privacyPolicy = "privacyPolicy",
  terms = "terms",
  customAvatar = "customAvatar",
  avatars = "avatars",
  voiceCloning = "voiceCloning",

  ethics = "ethics",
  avatarsListing = "avatarsListing",
  languageListing = "languageListing",

  australianLang = "australianLang",
  britishLang = "britishLang",
  frenchLang = "frenchLang",
  germanLang = "germanLang",
  italianLang = "italianLang",
  arabicLang = "arabicLang",
  chineseLang = "chineseLang",
  hebrewLang = "hebrewLang",
  hindiLang = "hindiLang",
  irishLang = "irishLang",
  japaneseLang = "japaneseLang",
  koreanLang = "koreanLang",
  russianLang = "russianLang",
  scottishLang = "scottishLang",
  dutchLang = "dutchLang",
}

export const pages = {
  [IPages.main]: () => "/",
  [IPages.voice]: () => "/ai-voice-generator/",
  [IPages.video]: () => "/ai-video-generator/",
  [IPages.image]: () => "/ai-image-generator/",
  [IPages.pricing]: () => "/pricing/",
  [IPages.pricing2]: () => "/pricing/",
  [IPages.api]: () => "/text-to-speech-api/",
  [IPages.features]: () => "/features/",
  [IPages.resources]: () => "/resources/",

  [IPages.useCases]: () => "/use-cases/",
  [IPages.videoAds]: () => "/ai-video-ads/",
  [IPages.presentationVideos]: () => "/ai-presentation-videos/",
  [IPages.brandingVideos]: () => "/ai-branding-videos/",
  [IPages.explainerVideos]: () => "/ai-explainer-videos/",
  [IPages.trainingVideos]: () => "/ai-training-videos/",
  [IPages.educationalVideos]: () => "/ai-educational-videos/",
  [IPages.eLearning]: () => "/elearning-voiceover/",
  [IPages.voiceoverAds]: () => "/advertising-voiceovers/",
  [IPages.voiceoverYouTube]: () => "/voiceover-for-YouTube-videos/",
  [IPages.voiceoverAnimation]: () => "/voiceover-animation/",
  [IPages.voiceoverPodcast]: () => "/voice-over-podcast/",

  [IPages.about]: () => "/about/",
  [IPages.refundPolicy]: () => "/ai-refund-policy/",
  [IPages.privacyPolicy]: () => "/ai-privacy-policy/",
  [IPages.terms]: () => "/ai-terms/",
  [IPages.customAvatar]: () => "/custom-avatar/",
  [IPages.avatars]: () => "/ai-avatars/",
  [IPages.voiceCloning]: () => "/voice-cloning/",

  [IPages.ethics]: () => "/ethics/",
  [IPages.avatarsListing]: () => "/ai-avatars-listing/",
  [IPages.languageListing]: () => "/languages/",

  [IPages.australianLang]: () => "/australian-text-to-speech/",
  [IPages.britishLang]: () => "/british-text-to-speech/",
  [IPages.frenchLang]: () => "/french-text-to-speech/",
  [IPages.germanLang]: () => "/german-text-to-speech/",
  [IPages.italianLang]: () => "/italian-text-to-speech/",

  [IPages.arabicLang]: () => "/arabic-text-to-speech/",
  [IPages.chineseLang]: () => "/chinese-text-to-speech/",
  [IPages.hebrewLang]: () => "/hebrew-text-to-speech/",
  [IPages.hindiLang]: () => "/hindi-text-to-speech/",
  [IPages.irishLang]: () => "/irish-text-to-speech/",
  [IPages.japaneseLang]: () => "/japanese-text-to-speech/",
  [IPages.koreanLang]: () => "/korean-text-to-speech/",
  [IPages.russianLang]: () => "/russian-text-to-speech/",
  [IPages.scottishLang]: () => "/scottish-text-to-speech/",
  [IPages.dutchLang]: () => "/dutch-text-to-speech/",
};
