import { useState } from "react";

export const useSubSidebar = () => {
  const [subSidebarOpen, setSubSidebarOpen] = useState(true);

  const handleSubSidebarOpen = () => setSubSidebarOpen(!subSidebarOpen);

  return {
    subSidebarOpen,
    handleSubSidebarOpen,
  };
};
