import styled from "styled-components";

import { useContext } from "react";
import SelectPlanButton from "./SelectPlanButton";
import { PricingContext } from "./PricingProvider";
import { IExternalLinks } from "../../../lib/routeUtils";
import {
  defaultColumns,
  imageTable,
  renderSpeedColumns,
  videoComparisonTable,
  videoTable,
  voicesTable,
} from "../../../mocks/plansTableMocks";
import { InfoIcon1818White } from "../../../components/Icons/Icons";
import Button, { Color } from "./Button";
import Tooltip from "./Tooltip";
import { useSelector } from "react-redux";
import { getChangeSubscriptions, getUserPlans } from "../../../redux/reducers/plansReducer";
import { UserPlanPeriod } from "../../../types/plans";
import { useDispatch } from "react-redux";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { PlanTypes } from "./PlanCard";

const PlansTableDesktop = () => {
  const { isYearly } = useContext(PricingContext);

  const dispatch = useDispatch();

  const plans = useSelector(getUserPlans);
  const changeSubscriptions = useSelector(getChangeSubscriptions);

  const activePlans = plans.filter((item) => !item.isCancelled);

  const handleUpdatePlan = (title: string) => {
    if (!changeSubscriptions.length || !activePlans.length) return;
    const existingUserLicenseId = activePlans[0].userLicenseID;

    const newPLan = changeSubscriptions.find((item) => {
      const samePeriod =
        (item.duration === UserPlanPeriod.monthly && !isYearly) ||
        (item.duration === UserPlanPeriod.yearly && isYearly);

      const splittedProductName = item.title.toLocaleLowerCase().split(" ").includes(title.toLowerCase());

      return splittedProductName && samePeriod;
    });

    if (!newPLan) return;

    dispatch(
      updatePopup({
        popup: Popups.changeSubscriptionPopup,
        status: true,
        prefilled: {
          existingUserLicenseId: existingUserLicenseId,
          newPlanId: newPLan.planID,
        },
      }),
    );
  };

  return (
    <Table>
      <thead>
        <TableRow>
          {defaultColumns.map(
            ({ header, yearlyPrice, monthlyPrice, yearlyTrigger, monthlyTrigger, planType, buttonColor }) => {
              const isCurrentPlan = !!plans.find((item) => {
                const active = !item.isCancelled;

                const samePeriod =
                  (item.period === UserPlanPeriod.monthly && !isYearly) ||
                  (item.period === UserPlanPeriod.yearly && isYearly);

                const splittedProductName = item.productName
                  .toLocaleLowerCase()
                  .split(" ")
                  .includes(header.toLowerCase());
                return samePeriod && splittedProductName && active;
              });

              return (
                <TableHeaderCell key={header}>
                  <p className="th-title">{header}</p>
                  {isYearly
                    ? yearlyPrice && <p className="th-price">${yearlyPrice}</p>
                    : monthlyPrice && <p className="th-price">${monthlyPrice}</p>}
                  {header !== "" ? (
                    header === "Free" ? (
                      <Button color={buttonColor as Color} linkPath={IExternalLinks.tryForFree} type="link">
                        {activePlans.length ? "Update subscription" : "Start for free"}
                      </Button>
                    ) : (
                      <SelectPlanButton
                        popupHash={isYearly ? yearlyTrigger! : monthlyTrigger!}
                        buttonColor={buttonColor}
                        selected={isCurrentPlan}
                        hasSubscription={!!activePlans.length}
                        updateSubscription={handleUpdatePlan.bind(null, header)}
                        planType={planType as PlanTypes}
                      />
                    )
                  ) : null}
                </TableHeaderCell>
              );
            },
          )}
        </TableRow>
      </thead>
      <tbody>
        <RenderSpeed>
          {renderSpeedColumns.map(({ header, tooltip, priority, id }) => (
            <td key={id}>
              <p className="speedContent">
                {header}
                {tooltip && (
                  <span className="tooltipWrapper">
                    <InfoIcon1818White />
                    <Tooltip title={tooltip} />
                  </span>
                )}
              </p>
              {priority && <p className="speedContent">{priority}</p>}
            </td>
          ))}
        </RenderSpeed>
        <HeadingRow>
          <td>
            {voicesTable.heading.icon}
            {voicesTable.heading.title}
          </td>
        </HeadingRow>
        {voicesTable.table.map((item) => (
          <TableRow key={item.id}>
            {voicesTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {videoTable.heading.icon}
            {videoTable.heading.title}
          </td>
        </HeadingRow>
        {videoTable.table.map((item) => (
          <TableRow key={item.id}>
            {videoTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {imageTable.heading.icon}
            {imageTable.heading.title}
          </td>
        </HeadingRow>
        {imageTable.table.map((item) => (
          <TableRow key={item.id}>
            {imageTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
        <HeadingRow>
          <td>
            {videoComparisonTable.heading.icon}
            {videoComparisonTable.heading.title}
          </td>
        </HeadingRow>
        {videoComparisonTable.table.map((item) => (
          <TableRow key={item.id}>
            {videoComparisonTable.columns.map(({ accessor, id }) => (
              <TableCell key={id}>{item[accessor]}</TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  border-radius: 12px 12px 0px 0px;
  background: #f0f0f3;
  overflow: hidden;
  box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
    inset 2px 2px 4px #ffffff;

  @media (max-width: 767px) {
    display: none;
  }
`;

const TableRow = styled.tr`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  th,
  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    :first-child {
      flex-grow: 1;
    }

    :not(:first-child) {
      flex-basis: 212px;
      flex-shrink: 0;
    }

    :last-child {
      border-right: none;

      background: #d8e9f6;
    }
  }

  td:first-child {
    justify-content: flex-start;
  }

  @media (max-width: 1099px) {
    th,
    td {
      :not(:first-child) {
        flex-basis: 180px;
      }
    }
  }

  @media (max-width: 930px) {
    th,
    td {
      :not(:first-child) {
        flex-basis: 145px;
      }
    }
  }
`;

const TableHeaderCell = styled.th`
  border-spacing: 0;
  padding: 18px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;

  .th-title {
    color: #191b1f;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    text-align: left;
    text-transform: uppercase;

    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .th-content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .th-price {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;

    color: #191b1f;
  }

  a {
    width: 100%;
  }

  .primaryButton {
    width: 100%;
    padding: 10px;

    .primaryButtonText {
      font-size: 16px;
    }
  }

  @media (max-width: 1099px) {
    gap: 6px;

    .th-title {
      font-size: 14px;
    }

    .th-price {
      font-size: 12px;
    }

    .primaryButton {
      padding: 8px 12px;

      .primaryButtonText {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 999px) {
    gap: 4px;

    .th-title {
      font-size: 12px;
    }

    .primaryButton {
      padding: 8px 10px;

      .primaryButtonText {
        font-size: 12px;
      }
    }
  }
`;

const RenderSpeed = styled.tr`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  display: flex;
  width: 100%;
  justify-content: flex-end;
  background: linear-gradient(338deg, #0063b4 4.07%, #009af7 133.37%);

  td {
    border-spacing: 0;
    border-right: 1px solid #d2d2d9;

    padding: 20px 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    :last-child {
      border-right: none;
    }

    .speedContent {
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.43;
      position: relative;
      max-width: max-content;

      .tooltipWrapper {
        position: absolute;
        right: -24px;
        top: 2px;
        display: flex;
        cursor: help;

        :hover .tooltip {
          opacity: 1;
        }

        .tooltip {
          max-width: 250px;
        }
      }
    }

    :first-child {
      flex-grow: 1;
      align-items: flex-start;
    }

    :not(:first-child) {
      flex-basis: 212px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 1099px) {
    td {
      .speedContent {
        font-size: 12px;

        .tooltipWrapper {
          top: 1px;
        }
      }

      :not(:first-child) {
        flex-basis: 180px;
      }
    }
  }

  @media (max-width: 930px) {
    td {
      .speedContent {
        font-size: 10px;

        .tooltipWrapper {
          top: 0;
        }
      }

      :not(:first-child) {
        flex-basis: 145px;
      }
    }
  }
`;

const TableCell = styled.td`
  border-spacing: 0;
  border-top: 1px solid #d2d2d9;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  justify-content: center;
  color: #191b1f;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;

  .checkedIcon {
    display: block;
    width: 24px;
    height: auto;
  }

  @media (max-width: 1099px) {
    font-size: 14px;
  }

  @media (max-width: 1099px) {
    font-size: 12px;
  }
`;

const HeadingRow = styled.tr`
  width: 100%;

  td {
    border-spacing: 0;
    border-top: 1px solid #d2d2d9;

    display: flex;
    align-items: center;
    gap: 10px;
    background: #eaeaf1;
    padding: 20px 16px;

    color: #191b1f;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: -0.96px;
  }

  @media (max-width: 1099px) {
    td {
      font-size: 20px;

      svg {
        width: 20px;
        height: auto;
      }
    }
  }

  @media (max-width: 1099px) {
    td {
      font-size: 16px;
    }
  }
`;

export default PlansTableDesktop;
