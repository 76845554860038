import { toast } from "react-toastify";
import { AIHumansStatus } from "../modules/MyStudio";
import { Status } from "../redux/actions/humansProjectActions";

export function downloadFile(e: any, filePath: string, status?: AIHumansStatus | Status) {
  e.preventDefault();
  e.stopPropagation();

  if (status === AIHumansStatus.Failure || status === Status.isError) {
    toast.error("This project can't be downloaded");
    return;
  }

  const link = document.createElement("a");
  link.href = filePath;
  link.download = "Untitled";
  link.click();
}
