import React from "react";
import styled from "styled-components";
import { ShapeBorder } from "../../types/scene";

interface Props {
  background: string;
  opacity: number;
  border: ShapeBorder;
}

export const Square = ({ background, opacity, border }: Props) => (
  <StyledDiv className={"shape0"} background={background} opacity={opacity} border={border} />
);

const StyledDiv = styled.div<{ background: string; opacity: number; border: ShapeBorder }>`
  width: 100%;
  height: 100%;
  background: ${({ background }) => background};
  opacity: ${({ opacity }) => opacity};
  border: ${({ border }) => `${border.borderWidth}px ${border.borderStyle} ${border.borderColor}`};
`;
