export const CopyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6668 8.60004V11.4C10.6668 13.7334 9.7335 14.6667 7.40016 14.6667H4.60016C2.26683 14.6667 1.3335 13.7334 1.3335 11.4V8.60004C1.3335 6.26671 2.26683 5.33337 4.60016 5.33337H7.40016C9.7335 5.33337 10.6668 6.26671 10.6668 8.60004Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6668 4.60004V7.40004C14.6668 9.73337 13.7335 10.6667 11.4002 10.6667H10.6668V8.60004C10.6668 6.26671 9.7335 5.33337 7.40016 5.33337H5.3335V4.60004C5.3335 2.26671 6.26683 1.33337 8.60016 1.33337H11.4002C13.7335 1.33337 14.6668 2.26671 14.6668 4.60004Z"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
