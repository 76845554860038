export enum AuthModules {
  "signIn" = "signIn",
  "signUp" = "signUp",
  "recoverPassword" = "recoverPassword",
  "resetPassword" = "resetPassword",
  "getTokenUser" = "getTokenUser",
  "checkMail" = "checkMail",
  "registerGuestUser" = "registerGuestUser",
  "signInWithGoogle" = "signInWithGoogle",
  "appsumoLogin" = "appsumoLogin",
  "oauth" = "oauth",
  "cello" = "cello",
}
