export const EmptyGalleryIcon = () => (
  <svg width="118" height="106" viewBox="0 0 118 106" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M88.6682 98.8678L56.4168 81.7055C54.5579 80.7098 53.8551 78.3963 54.8276 76.5406L71.2193 45.6685C72.2131 43.8082 74.5239 43.1057 76.3782 44.0802L108.63 61.2425C110.489 62.2382 111.191 64.5517 110.219 66.4075L93.8271 97.2795C92.8333 99.1398 90.5225 99.8423 88.6682 98.8678Z"
      stroke="#565A61"
      strokeWidth="1.4"
      strokeMiterlimit="10"
    />
    <path d="M81.4006 77.9073L71.0593 72.4012L79.3489 69.2878L81.4006 77.9073Z" fill="url(#paint0_linear_439_3546)" />
    <path d="M89.9818 82.4621L75.0731 74.5273L86.9984 70.0679L89.9818 82.4621Z" fill="url(#paint1_linear_439_3546)" />
    <path
      d="M80.7394 66.6951C81.9333 66.4355 82.6904 65.2562 82.4305 64.061C82.1707 62.8658 80.9922 62.1073 79.7983 62.3669C78.6045 62.6265 77.8474 63.8058 78.1073 65.001C78.3671 66.1962 79.5456 66.9547 80.7394 66.6951Z"
      fill="url(#paint2_linear_439_3546)"
    />
    <path
      d="M67.8034 73.8995L21.0425 82.9062C18.3421 83.4188 15.7348 81.6481 15.2014 78.9698L6.54273 34.2389C6.02705 31.5385 7.79525 28.9338 10.4736 28.4035L57.2345 19.3968C59.935 18.8843 62.5423 20.6549 63.0757 23.3333L71.7343 68.0641C72.25 70.7645 70.4818 73.3692 67.8034 73.8995Z"
      stroke="#565A61"
      strokeWidth="1.4"
      strokeMiterlimit="10"
    />
    <path d="M41.4496 57.4888L26.4535 60.3737L32.3104 50.4279L41.4496 57.4888Z" fill="url(#paint3_linear_439_3546)" />
    <path d="M53.8815 55.0807L32.2645 59.2429L40.7062 44.9548L53.8815 55.0807Z" fill="url(#paint4_linear_439_3546)" />
    <path
      d="M31.597 46.6637C32.5951 45.4228 32.3971 43.6068 31.1548 42.6076C29.9125 41.6084 28.0963 41.8043 27.0982 43.0452C26.1001 44.2861 26.2981 46.102 27.5404 47.1013C28.7827 48.1005 30.5989 47.9046 31.597 46.6637Z"
      fill="url(#paint5_linear_439_3546)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_439_3546"
        x1="72.9138"
        y1="71.8725"
        x2="79.4727"
        y2="77.3949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_439_3546"
        x1="77.751"
        y1="73.7842"
        x2="87.1867"
        y2="81.7453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_439_3546"
        x1="78.6909"
        y1="63.2004"
        x2="81.9713"
        y2="65.9361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_439_3546"
        x1="27.9012"
        y1="58.3188"
        x2="39.075"
        y2="58.5481"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_439_3546"
        x1="34.3717"
        y1="56.2963"
        x2="50.4599"
        y2="56.6427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_439_3546"
        x1="26.6578"
        y1="44.7973"
        x2="32.2248"
        y2="44.8852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
