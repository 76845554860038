import styled from "styled-components";

export const LeftArrowPlanIcon = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath opacity="0.3" d="M9.5 2L5 7L9.5 12" stroke="#191B1F" />
  </svg>
);

const StyledPath = styled.path`
  stroke: ${({ theme }) => theme.primaryText};
`;
