import React, { FC } from "react";
import styled from "styled-components";
import { Star } from "../Icons/Star";

type Props = {
  stars: number;
  variant: "default" | "color";
};

const getStars = (stars: number) => {
  let output = [];
  for (let index = 0; index < 5; index++) {
    if (index < stars) {
      output.push(
        <StyledStar>
          <Star />
        </StyledStar>,
      );
    }
  }
  return output;
};

const StarsRender: FC<Props> = ({ stars, variant = "default" }) => {
  return <Wrapper variant={variant}>{getStars(stars)}</Wrapper>;
};

const Wrapper = styled("div")<{ variant?: "default" | "color" }>`
  display: flex;
  gap: 2px;

  ${({ variant, theme }) =>
    variant === "default" &&
    `
    opacity: 1;
    & svg path {
        fill: ${theme.reviewBlue};
      }
      `}

  ${({ variant, theme }) =>
    variant === "color" &&
    `
    opacity: 0.6;
    & svg path {
        fill: ${theme.white};
      }`}
`;

const StyledStar = styled.div`
  width: 18px;
  height: 18px;
  & svg {
    width: 18px;
    height: 18px;
  }
`;

export default StarsRender;
