export interface IImage {
  visualHistoryID: number;
  userId: number;
  path: string;
  status: number;
  fileName: string;
  insertDateTime: string;
  expiryDateTime: string;
}

export interface GalleryImage {
  visualGalleryID: number;
  userId: number;
  path: string;
  status: number;
  fileName: string;
  insertDateTime: string;
  expiryDateTime: string;
}

export interface GalleryVideo {
  videoGalleryID: number;
  userId: number;
  path: string;
  status: number;
  fileName: string;
  insertDateTime: string;
  expiryDateTime: string;
  file: null;
}

export interface PromptToVideoList {
  prompt2VideoProjectId: number;
  userId: number;
  title: string;
  prompt: string;
  insertDateTime: string;
  updateDateTime: string;
  outputUrl: string;
  isDeleted: boolean;
  estimatedTime: number;
  status: number;
  generatedImage: string;
  totalSecondsTaken: number;
  completeDateTime: null;
  estimatedCompleteDateTime: string;
  progress: string;
  guid: string;
  size: string;
  faceswapProjectId: number;
  lipsyncProjectId: number;
}

export enum ImagesModules {
  "generatedImages" = "generatedImages",
  "historyImages" = "historyImages",
  "uploadImageToVisualGallery" = "uploadImageToVisualGallery",
  "galleryImages" = "galleryImages",
  "downloadAllImages" = "downloadAllImages",
  "imageGallery" = "imageGallery",
  "inPaint" = "inPaint",
  "promptToVideo" = "promptToVideo",
  "generatedVideo" = "generatedVideo",
  "galleryVideos" = "galleryVideos",
  "videoGallery" = "videoGallery",
  "upscaleImage" = "upscaleImage",
  "upscaleVideo" = "upscaleVideo",
  "storyReferenceImage" = "storyReferenceImage",
  "personalizeImage" = "personalizeImage",
  "faceSwapImage" = "faceSwapImage",
  "lipsync" = "lipsync",
  "poseImage" = "poseImage",
}

export type TrimImagePrefilled = {
  file: File;
  handleSelectImage: (image: string) => void;
};

export enum UploadImageTypes {
  CreateVariations,
  InPainting,
}

export type UploadImagePrefilled = {
  title: string;
  handleSelectImage: (image: string) => void;
  type: UploadImageTypes;
  isTextToImage?: boolean;
  previousFill?: UploadImagePrefilled;
};
