import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCurrentPageHistoryImages,
  getHasMoreHistoryImages,
  getHistoryImages,
  getHistoryImagesLoading,
} from "../redux/reducers/imagesReducer";
import { MediaProps } from "../mocks/humans";
import { getAssets } from "../redux/reducers/assetReducer";
import { AssetModules, AssetType, CropImage } from "../types/asset";
import { Popups, updatePopup } from "../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../types/confirmationDelete";
import { Asset, clearAsset, getAssetServer } from "../redux/actions/assetAction";
import { clearHistoryImages, getAllHistoryImagesServer } from "../redux/actions/imagesActions";

const MAX_SIZE = 52428800;
const searchTimeoutDelay = 500;

export interface MediaType extends AssetType {
  loaded: boolean;
}

interface Props {
  activeMedia: MediaProps;
}

export const useMedia = ({ activeMedia }: Props) => {
  const dispatch = useDispatch();
  const assets = useSelector(getAssets);
  const uploadMediaLoading = assets[Asset.Media].uploadLoading;
  const media = assets[Asset.Media].data;
  const mediaLoading = assets[Asset.Media].isLoading;
  const mediaHasMore = assets[Asset.Media].hasMore;
  const mediaCurrentPage = assets[Asset.Media].pageNumber;

  const images = useSelector(getHistoryImages);
  const imagesLoading = useSelector(getHistoryImagesLoading);
  const imagesCurrentPage = useSelector(getCurrentPageHistoryImages);
  const imagesHasMore = useSelector(getHasMoreHistoryImages);

  const [errorSize, setErrorSize] = useState<boolean>(false);

  const debounceHandleGetMedia = useRef(
    debounce(async () => {
      dispatch(
        getAssetServer({
          keyword: "",
          pageNumber: 1,
          assetTypeId: Asset.Media,
          pageSize: 60,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const debounceHandleGetHistoryImages = useRef(
    debounce(async () => {
      dispatch(
        getAllHistoryImagesServer({
          keyword: "",
          pageNumber: 1,
          pageSize: 60,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const fetchMore = () => {
    if (activeMedia === MediaProps.MEDIA) {
      dispatch(
        getAssetServer({
          keyword: "",
          pageNumber: mediaCurrentPage + 1,
          assetTypeId: Asset.Media,
          pageSize: 60,
        }),
      );
    } else {
      dispatch(
        getAllHistoryImagesServer({
          keyword: "",
          pageNumber: imagesCurrentPage + 1,
          pageSize: 60,
        }),
      );
    }
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    if (uploadedFile && uploadedFile.size > MAX_SIZE) {
      setErrorSize(true);
    } else {
      const formData = new FormData();
      formData.append("AssetTypeId", "5");
      formData.append("Status", "1");
      formData.append("FileName", uploadedFile.name);
      formData.append("File", uploadedFile);

      setErrorSize(false);

      const prefilled: CropImage = {
        path: URL.createObjectURL(uploadedFile),
        typeId: Asset.Media,
        formData,
        fileType: uploadedFile.type,
        pageSize: 60,
      };

      dispatch(
        updatePopup({
          popup: Popups.cropImagePopup,
          status: true,
          prefilled,
        }),
      );
    }
  };

  const handleUserAssetDelete = (e: any, id: number) => {
    if (!id) {
      throw Error(`The ID ${AssetModules[Asset.Media]} is missing when deleting`);
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: `Are you sure you want to delete ${AssetModules[Asset.Media]}?`,
      description: `The ${AssetModules[Asset.Media]} will be removed and the action cannot be undone`,
      typeId: Asset.Media,
      type: DeleteType.Asset,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    if (activeMedia === MediaProps.MEDIA) {
      dispatch(clearAsset({ assetTypeId: Asset.Media }));
      debounceHandleGetMedia();
    } else {
      dispatch(clearHistoryImages());
      debounceHandleGetHistoryImages();
    }
  }, [activeMedia]);

  return {
    errorSize,
    uploadMediaLoading,
    media,
    mediaLoading,
    mediaHasMore,
    fetchMore,
    images,
    imagesLoading,
    imagesHasMore,
    handleUpload,
    handleUserAssetDelete,
  };
};
