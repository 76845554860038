export const ArchiveTick = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9469 3H7.20113C5.71061 3 4.5 4.21761 4.5 5.70113V15.5609C4.5 16.8205 5.40271 17.3524 6.50835 16.7436L9.92325 14.8472C10.2871 14.6442 10.8749 14.6442 11.2318 14.8472L14.6467 16.7436C15.7524 17.3594 16.6551 16.8275 16.6551 15.5609V5.70113C16.6481 4.21761 15.4375 3 13.9469 3Z"
      stroke="url(#paint0_linear_3413_32960)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.8877 9.29802L9.93736 10.3477L12.7364 7.54858"
      stroke="url(#paint1_linear_3413_32960)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3413_32960"
        x1="7.11802"
        y1="4.83077"
        x2="15.6221"
        y2="14.3237"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3413_32960"
        x1="9.71666"
        y1="7.91462"
        x2="11.1827"
        y2="10.5064"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
