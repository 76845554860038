export const InfoIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke="url(#paint0_linear_2305_10030)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.5 7.5H8V11H8.5" fill="url(#paint1_linear_2305_10030)" />
    <path
      d="M7.5 7.5H8V11H8.5"
      stroke="url(#paint2_linear_2305_10030)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 6C8.41421 6 8.75 5.66421 8.75 5.25C8.75 4.83579 8.41421 4.5 8 4.5C7.58579 4.5 7.25 4.83579 7.25 5.25C7.25 5.66421 7.58579 6 8 6Z"
      fill="url(#paint3_linear_2305_10030)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2305_10030"
        x1="4.58462"
        y1="3.56923"
        x2="11.6923"
        y2="12.7077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2305_10030"
        x1="7.71538"
        y1="7.95769"
        x2="9.09997"
        y2="8.46632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2305_10030"
        x1="7.71538"
        y1="7.95769"
        x2="9.09997"
        y2="8.46632"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2305_10030"
        x1="7.57308"
        y1="4.69615"
        x2="8.46154"
        y2="5.83846"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
