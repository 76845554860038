export const PopupNextIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5459 11.5001C9.98288 11.5001 6.76888 13.1341 4.73588 15.7591C5.67088 11.8901 9.26588 9.00015 13.5459 9.00015C14.0989 9.00015 14.5459 8.55315 14.5459 8.00015V6.07715L19.3699 10.2511L14.5459 14.4631V12.5001C14.5459 11.9471 14.0989 11.5001 13.5459 11.5001ZM3.77888 21.0005C4.23988 21.0005 4.64188 20.6855 4.75088 20.2375C5.64088 16.5885 8.77188 13.9525 12.5459 13.5535V14.6745C12.5459 15.3805 12.9649 16.0095 13.6389 16.3145C14.3699 16.6475 15.2229 16.5275 15.8159 16.0105L20.8819 11.5865C21.2749 11.2425 21.4999 10.7555 21.4999 10.2505C21.4999 9.74547 21.2749 9.25847 20.8819 8.91447L15.8159 4.49047C15.2229 3.97347 14.3699 3.85347 13.6389 4.18647C12.9649 4.49147 12.5459 5.12047 12.5459 5.82647V7.04447C6.92188 7.53747 2.49988 12.1505 2.49988 17.7505C2.49988 18.5605 2.60388 19.3965 2.80788 20.2365C2.91588 20.6855 3.31688 21.0005 3.77888 21.0005Z"
        fill="url(#paint0_linear_2_8465)"
      />
      <mask
        id="mask0_2_8465"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="20"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5459 11.5001C9.98288 11.5001 6.76888 13.1341 4.73588 15.7591C5.67088 11.8901 9.26588 9.00015 13.5459 9.00015C14.0989 9.00015 14.5459 8.55315 14.5459 8.00015V6.07715L19.3699 10.2511L14.5459 14.4631V12.5001C14.5459 11.9471 14.0989 11.5001 13.5459 11.5001ZM3.77888 21.0005C4.23988 21.0005 4.64188 20.6855 4.75088 20.2375C5.64088 16.5885 8.77188 13.9525 12.5459 13.5535V14.6745C12.5459 15.3805 12.9649 16.0095 13.6389 16.3145C14.3699 16.6475 15.2229 16.5275 15.8159 16.0105L20.8819 11.5865C21.2749 11.2425 21.4999 10.7555 21.4999 10.2505C21.4999 9.74547 21.2749 9.25847 20.8819 8.91447L15.8159 4.49047C15.2229 3.97347 14.3699 3.85347 13.6389 4.18647C12.9649 4.49147 12.5459 5.12047 12.5459 5.82647V7.04447C6.92188 7.53747 2.49988 12.1505 2.49988 17.7505C2.49988 18.5605 2.60388 19.3965 2.80788 20.2365C2.91588 20.6855 3.31688 21.0005 3.77888 21.0005Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2_8465)"></g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2_8465"
        x1="17.4076"
        y1="6.22314"
        x2="7.66556"
        y2="20.2218"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
