export const LinkIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68465 2.59949C9.13932 1.14149 11.4867 0.87816 13.108 2.14616C15.05 3.66483 15.1773 6.49483 13.49 8.18616L12.5413 9.13616C12.2793 9.39883 11.8553 9.39883 11.5933 9.13616C11.3313 8.87416 11.3313 8.44816 11.5933 8.18616L12.5413 7.23549C13.6447 6.13083 13.5847 4.29416 12.36 3.26883C11.2753 2.36016 9.64732 2.53349 8.64799 3.53483L7.79932 4.38483C7.53799 4.64749 7.11265 4.64749 6.85132 4.38483C6.58932 4.12283 6.58665 3.69949 6.96932 3.31616L7.68465 2.59949ZM8.86192 6.19503L6.19525 8.86169C5.93459 9.12236 5.93459 9.54369 6.19525 9.80436C6.32525 9.93436 6.49592 9.99969 6.66659 9.99969C6.83725 9.99969 7.00792 9.93436 7.13792 9.80436L9.80459 7.13769C10.0653 6.87703 10.0653 6.45569 9.80459 6.19503C9.54392 5.93436 9.12259 5.93436 8.86192 6.19503ZM8.18679 11.5974L7.33745 12.4447C6.33679 13.4427 4.70545 13.6147 3.62012 12.71C2.39412 11.6887 2.33345 9.85803 3.43812 8.75603L4.38812 7.80936C4.65012 7.54803 4.65012 7.12403 4.38812 6.86203C4.12612 6.60136 3.70079 6.60136 3.43812 6.86203L2.58812 7.71069C1.14012 9.15469 0.88612 11.4774 2.13812 13.0934C3.65212 15.0487 6.49212 15.1807 8.18679 13.4914L9.13679 12.5447C9.39879 12.2827 9.39879 11.8594 9.13679 11.5974C8.87412 11.3354 8.44945 11.3354 8.18679 11.5974Z"
      fill="url(#paint0_linear_1096_14252)"
    />
    <mask
      id="mask0_1096_14252"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68465 2.59949C9.13932 1.14149 11.4867 0.87816 13.108 2.14616C15.05 3.66483 15.1773 6.49483 13.49 8.18616L12.5413 9.13616C12.2793 9.39883 11.8553 9.39883 11.5933 9.13616C11.3313 8.87416 11.3313 8.44816 11.5933 8.18616L12.5413 7.23549C13.6447 6.13083 13.5847 4.29416 12.36 3.26883C11.2753 2.36016 9.64732 2.53349 8.64799 3.53483L7.79932 4.38483C7.53799 4.64749 7.11265 4.64749 6.85132 4.38483C6.58932 4.12283 6.58665 3.69949 6.96932 3.31616L7.68465 2.59949ZM8.86192 6.19503L6.19525 8.86169C5.93459 9.12236 5.93459 9.54369 6.19525 9.80436C6.32525 9.93436 6.49592 9.99969 6.66659 9.99969C6.83725 9.99969 7.00792 9.93436 7.13792 9.80436L9.80459 7.13769C10.0653 6.87703 10.0653 6.45569 9.80459 6.19503C9.54392 5.93436 9.12259 5.93436 8.86192 6.19503ZM8.18679 11.5974L7.33745 12.4447C6.33679 13.4427 4.70545 13.6147 3.62012 12.71C2.39412 11.6887 2.33345 9.85803 3.43812 8.75603L4.38812 7.80936C4.65012 7.54803 4.65012 7.12403 4.38812 6.86203C4.12612 6.60136 3.70079 6.60136 3.43812 6.86203L2.58812 7.71069C1.14012 9.15469 0.88612 11.4774 2.13812 13.0934C3.65212 15.0487 6.49212 15.1807 8.18679 13.4914L9.13679 12.5447C9.39879 12.2827 9.39879 11.8594 9.13679 11.5974C8.87412 11.3354 8.44945 11.3354 8.18679 11.5974Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1096_14252)">
      <rect width="16" height="16" fill="url(#paint1_linear_1096_14252)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1096_14252"
        x1="4.20526"
        y1="3.0766"
        x2="12.1027"
        y2="13.2304"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1096_14252"
        x1="3.44615"
        y1="2.09231"
        x2="12.9231"
        y2="14.2769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
