import styled from "styled-components";
import { ImageIcon2424, MicIcon2626, VideoIcon2626 } from "../components/Icons/Icons";
import TooltipCell from "../modules/PricingPage2/components/TooltipCell";
// import { IExternalLinks } from "../lib/routeUtils";
// import SmartLink from "../components/SmartLink/SmartLink";

const CircleCheckedIcon = () => {
  return <img className="checkedIcon" alt="checked icon" src="/images/icons/circleCheckedIcon.svg" />;
};

const PrimarryCell = styled.span`
  font-weight: 600;
  background: var(--Linear, linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

// const SynthesysX = () => <SmartLink url={IExternalLinks.xPage}>Synthesys X</SmartLink>;

enum Columns {
  "feature" = "feature",
  "free" = "free",
  "personal" = "personal",
  "creator" = "creator",
  "business_unlimited" = "business unlimited",
}

export const defaultColumns = [
  {
    id: 1,
    header: "",
    accessor: Columns.feature,
    planType: "",
    buttonColor: "",
  },
  {
    id: 2,
    header: "Free",
    accessor: Columns.free,
    yearlyPrice: "0",
    monthlyPrice: "0",
    planType: "",
    buttonColor: "pricingOutlineBlack",
  },
  {
    id: 3,
    header: "Personal",
    accessor: Columns.personal,
    yearlyPrice: "20/m",
    monthlyPrice: "29/m",
    yearlyTrigger: "#pkmodale895f1f871ddfd22553c80726188c551df2b4294",
    monthlyTrigger: "#pkmodal029b7f78b3903a8fa53c0f93a4412dcf5844896c",
    buttonColor: "pricingBlue",
    planType: "PERSONAL",
  },
  {
    id: 3,
    header: "Creator",
    accessor: Columns.creator,
    yearlyPrice: "41/m",
    monthlyPrice: "59/m",
    yearlyTrigger: "#pkmodal61112bdff3e11665c3400c2207075076526de8ba",
    monthlyTrigger: "#pkmodal7ff056c2adcd0049d1257aedd48d92b37950df38",
    buttonColor: "pricingBlue",
    planType: "CREATOR",
  },
  {
    id: 4,
    header: "Business Unlimited",
    accessor: Columns.business_unlimited,
    yearlyPrice: "69/m",
    monthlyPrice: "99/m",
    yearlyTrigger: "#pkmodal3052358a82dfc5607a2a167def3a5a20607d78bd",
    monthlyTrigger: "#pkmodal29ace0084cb963d1f6cacfa5ede1b98e806091cc",
    buttonColor: "pricingBlue",
    planType: "BUSINESS UNLIMITED",
  },
];

export const renderSpeedColumns = [
  {
    id: 1,
    header: "Render Speed for all services",
  },
  {
    id: 2,
    header: "Relaxed Mode",
    tooltip:
      "Unwind and let your ideas flourish with our Relax Generation Speed. This leisurely pace ensures a stress-free environment for your creative expressions to come to life.",
    priority: "Low Priority Rendering",
  },
  {
    id: 3,
    header: "Relaxed Mode",
    tooltip: "Enjoy a seamless creative process with standard speed generation—effortless and perfectly paced.",
    priority: "Low Priority Rendering",
  },
  {
    id: 4,
    header: "Relaxed Mode",
    tooltip:
      "Accelerate your workflow with industry-leading processing speeds. Scale your vision without compromise with our business-grade fast speed., Elevated rendering priority throughout the suite tools",
    priority: "Low Priority Rendering",
  },
  {
    id: 5,
    header: "Fast Turbo Mode ",
    tooltip: "",
    priority: "High Priority Rendering",
  },
];

export const voicesTable = {
  heading: {
    icon: <MicIcon2626 />,
    title: "AI Voices",
  },
  columns: defaultColumns,
  table: [
    {
      id: 1,
      [Columns.feature]: "No. of minutes of audio renders",
      [Columns.free]: "2 Mins",
      [Columns.personal]: "15 Mins",
      [Columns.creator]: "40 Mins",
      [Columns.business_unlimited]: <PrimarryCell>Unlimited*</PrimarryCell>,
    },
    {
      id: 2,
      [Columns.feature]: "Ultra Life-like Voices",
      [Columns.free]: "50",
      [Columns.personal]: "50",
      [Columns.creator]: "50",
      [Columns.business_unlimited]: "50",
    },
    {
      id: 3,
      [Columns.feature]: "Premium Voices",
      [Columns.free]: "370",
      [Columns.personal]: "370",
      [Columns.creator]: "370",
      [Columns.business_unlimited]: "370",
    },
    {
      id: 4,
      [Columns.feature]: "Languages",
      [Columns.free]: "140",
      [Columns.personal]: "140",
      [Columns.creator]: "140",
      [Columns.business_unlimited]: "140",
    },
    {
      id: 5,
      [Columns.feature]: (
        <TooltipCell
          text="Voice settings"
          tooltip="Adjust speed, pitch and pauses to achieve a more suitable tone of voice"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 6,
      [Columns.feature]: (
        <TooltipCell text="Voice settings for emotive texts" tooltip="Control voice styles to create richer content" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 7,
      [Columns.feature]: (
        <TooltipCell
          text="Multi-Voice functionality"
          tooltip="Use different voices for different sentences in a single audio file to create conversational voiceovers"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: (
        <TooltipCell
          text="Speech to Speech"
          tooltip="Convert spoken words from one person directly into another person’s voice"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 9,
      [Columns.feature]: <TooltipCell text="Speech Translation" tooltip="Translate any Audio into 29 languages" />,
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 10,
      [Columns.feature]: "Organize work with projects and make changes on the fly",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const videoTable = {
  heading: {
    icon: <VideoIcon2626 />,
    title: "AI Humans",
  },
  columns: defaultColumns,
  table: [
    {
      id: 1,
      [Columns.feature]: "No. of minutes of video renders",
      [Columns.free]: "2 Mins",
      [Columns.personal]: "15 Mins",
      [Columns.creator]: "40 Mins",
      [Columns.business_unlimited]: <PrimarryCell>Unlimited*</PrimarryCell>,
    },
    {
      id: 2,
      [Columns.feature]: "Premium Actors",
      [Columns.free]: "70+",
      [Columns.personal]: "70+",
      [Columns.creator]: "70+",
      [Columns.business_unlimited]: "70+",
    },
    {
      id: 3,
      [Columns.feature]: "Ultra Life-like Voices",
      [Columns.free]: "100+",
      [Columns.personal]: "100+",
      [Columns.creator]: "100+",
      [Columns.business_unlimited]: "100+",
    },
    {
      id: 4,
      [Columns.feature]: "Premium Voices",
      [Columns.free]: "370",
      [Columns.personal]: "370",
      [Columns.creator]: "370",
      [Columns.business_unlimited]: "370",
    },
    {
      id: 5,
      [Columns.feature]: "Languages",
      [Columns.free]: "140",
      [Columns.personal]: "140",
      [Columns.creator]: "140",
      [Columns.business_unlimited]: "140",
    },
    {
      id: 6,
      [Columns.feature]: "Slides per video",
      [Columns.free]: "3 Slides",
      [Columns.personal]: "6 Slides",
      [Columns.creator]: "6 Slides",
      [Columns.business_unlimited]: <PrimarryCell>12 Slides</PrimarryCell>,
    },
    {
      id: 7,
      [Columns.feature]: (
        <TooltipCell
          text="Voice settings"
          tooltip="Adjust speed, pitch and pauses to achieve a more suitable tone of voice"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: "Aspect ratio (Landscape & Portrait)",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 9,
      [Columns.feature]: "Upload your own images and videos",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 10,
      [Columns.feature]: "Upload music",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 11,
      [Columns.feature]: "Stock images and videos",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 12,
      [Columns.feature]: "Video quality",
      [Columns.free]: "Full HD (1080p)",
      [Columns.personal]: "Full HD (1080p)",
      [Columns.creator]: "Full HD (1080p)",
      [Columns.business_unlimited]: "Full HD (1080p)",
    },
    {
      id: 13,
      [Columns.feature]: "Voice Upload",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 14,
      [Columns.feature]: "Transitions",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 15,
      [Columns.feature]: "Import PDF",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 16,
      [Columns.feature]: "Video templates",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 17,
      [Columns.feature]: "Instant Face Change",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 18,
      [Columns.feature]: "Talking Image",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
  ],
};

export const imageTable = {
  heading: {
    icon: <ImageIcon2424 />,
    title: "AI Images",
  },
  columns: defaultColumns,
  table: [
    {
      id: 1,
      [Columns.feature]: "Image generations",
      [Columns.free]: "50 total",
      [Columns.personal]: "100/month",
      [Columns.creator]: "250/month",
      [Columns.business_unlimited]: <PrimarryCell>1000/month</PrimarryCell>,
    },
    {
      id: 2,
      [Columns.feature]: "Inpainting",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: "Variations",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: "No. of Image generations per request",
      [Columns.free]: "2",
      [Columns.personal]: "4",
      [Columns.creator]: "4",
      [Columns.business_unlimited]: "4",
    },
    {
      id: 4,
      [Columns.feature]: "Render Speed",
      [Columns.free]: (
        <TooltipCell
          toLeft
          text="Relaxed Mode"
          tooltip="Unwind and let your ideas flourish with our Relax Generation Speed. This leisurely pace ensures a stress-free environment for your creative expressions to come to life."
        />
      ),
      [Columns.personal]: (
        <TooltipCell
          toLeft
          text="Relaxed Mode"
          tooltip="Enjoy a seamless creative process with standard speed generation—effortless and perfectly paced."
        />
      ),
      [Columns.creator]: (
        <TooltipCell
          toLeft
          text="Relaxed Mode"
          tooltip="Accelerate your workflow with industry-leading processing speeds. Scale your vision without compromise with our business-grade fast speed., Elevated rendering priority throughout the suite tools"
        />
      ),
      [Columns.business_unlimited]: <PrimarryCell>Fast Turbo Mode</PrimarryCell>,
    },
  ],
};

export const videoComparisonTable = {
  heading: {
    icon: <VideoIcon2626 />,
    title: "AI Video",
  },
  columns: defaultColumns,
  table: [
    {
      id: 1,
      [Columns.feature]: "Text Prompt to Video Generations",
      [Columns.free]: "2 mins",
      [Columns.personal]: "15 mins",
      [Columns.creator]: "40 mins",
      [Columns.business_unlimited]: <PrimarryCell>Unlimited</PrimarryCell>,
    },
    {
      id: 2,
      [Columns.feature]: (
        <TooltipCell text="Image to Video" tooltip="Upload an image to create it into a short animated video" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: (
        <TooltipCell text="Create Video Stories" tooltip="Enter up to 6 text prompts to create a video story" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: (
        <TooltipCell
          text="Pose Control"
          tooltip="Copy composition, outlines, body poses and patterns from a source image for generations"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 5,
      [Columns.feature]: (
        <TooltipCell
          text="Personalize"
          tooltip="Upload source photo with a single person to generate characters with the same face"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 6,
      [Columns.feature]: (
        <TooltipCell
          text="Faceswap"
          tooltip="Upload a source photo with a single person and change the face of an already generated video"
        />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 7,
      [Columns.feature]: (
        <TooltipCell text="Lip-Sync" tooltip="Upload a source audio to integrate a face with your audio clip" />
      ),
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.personal]: <CircleCheckedIcon />,
      [Columns.creator]: <CircleCheckedIcon />,
      [Columns.business_unlimited]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: "Render Speed",
      [Columns.free]: (
        <TooltipCell
          toLeft
          text="Relaxed Mode"
          tooltip="Unwind and let your ideas flourish with our Relax Generation Speed. This leisurely pace ensures a stress-free environment for your creative expressions to come to life."
        />
      ),
      [Columns.personal]: (
        <TooltipCell
          toLeft
          text="Relaxed Mode"
          tooltip="Unwind and let your ideas flourish with our Relax Generation Speed. This leisurely pace ensures a stress-free environment for your creative expressions to come to life."
        />
      ),
      [Columns.creator]: (
        <TooltipCell
          toLeft
          text="Relaxed Mode"
          tooltip="Unwind and let your ideas flourish with our Relax Generation Speed. This leisurely pace ensures a stress-free environment for your creative expressions to come to life."
        />
      ),
      [Columns.business_unlimited]: <PrimarryCell>Fast Turbo Mode</PrimarryCell>,
    },
  ],
};
