import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Sidebar from "../../components/Sidebar/Sidebar";
import CircularProgress from "../../components/Icons/CircularProgress";
import { getActiveSumoPlanServer } from "../../redux/actions/sumoActions";
import { getActivePlan, getActivePlanLoading } from "../../redux/reducers/sumoReducer";
import Tabs from "../PricingSubscriptions/components/Tabs";
import SumoLicenseCard from "../PricingSubscriptions/components/SumoLicenseCard";

enum SuboTabs {
  "Current" = "Current plan",
  "All" = "All plans",
}

const tabsOption = [
  {
    value: SuboTabs.Current,
    label: "Current plan",
  },
  {
    value: SuboTabs.All,
    label: "All plans",
  },
];

const sumoLicenseCardMock = [
  {
    id: "synthesys_tier1",
    title: "License Tier 1",
    pros: ["AI Voice <b>20 minutes</b> per month", "AI Video <b>20 minutes</b> per month", "Free AI Writer"],
    buttonText: "Change to Tier 1",
  },
  {
    id: "synthesys_tier2",
    title: "License Tier 2",
    pros: [
      "AI Voice <b>120 minutes</b>/month",
      "AI Video <b>60 minutes</b>/month",
      "AI Image <b>100 generations</b>/month",
      "API Access (AI Voice)",
    ],
    buttonText: "Change to Tier 2",
  },
  {
    id: "synthesys_tier3",
    title: "License Tier 3",
    pros: [
      "AI Voice <b>300 minutes</b>/month",
      "AI Video <b>150 minutes</b>/month",
      "AI Image (250 generations per month / plus Synthesys X)",
      "API Access (AI Voice)",
    ],
    buttonText: "Change to Tier 3",
  },
  {
    id: "synthesys_tier4",
    title: "License Tier 4",
    pros: [
      "AI Voice <b>600 minutes</b>/month",
      "AI Video <b>300 minutes</b>/month",
      "AI Image (500 generations per month / plus Synthesys X)",
      "API Access (AI Voice)",
    ],
    buttonText: "Change to Tier 4",
  },
];

const SumoUserPlans = () => {
  const dispatch = useDispatch();
  const activePlan = useSelector(getActivePlan);
  const isLoading = useSelector(getActivePlanLoading);

  const [activeTab, setActiveTab] = useState(tabsOption[0].value);
  const handleActiveTab = (tab: string) => setActiveTab(tab as SuboTabs);

  const getActiveCard = () => sumoLicenseCardMock.filter((card) => card.id === activePlan)[0];

  useEffect(() => {
    dispatch(getActiveSumoPlanServer());
  }, []);

  return (
    <Wrapper>
      <MobileOnly>
        <Sidebar mobile />
      </MobileOnly>
      <Title>My plan</Title>
      <TabsWrapper>
        <Tabs tabs={tabsOption} activeTab={activeTab} onClickTab={handleActiveTab} />
      </TabsWrapper>

      {isLoading ? (
        <LoaderWrapper>
          <CircularProgress color="#009af7" />
        </LoaderWrapper>
      ) : activeTab === "Current plan" ? (
        <CurrentCardWrapper>
          {getActiveCard() && <SumoLicenseCard isCurrent={true} {...getActiveCard()} />}
        </CurrentCardWrapper>
      ) : (
        <PlansWrapper>
          {sumoLicenseCardMock.map((elem) => (
            <SumoLicenseCard key={elem.title} isCurrent={activePlan === elem.id} {...elem} />
          ))}
        </PlansWrapper>
      )}
    </Wrapper>
  );
};

const LoaderWrapper = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CurrentCardWrapper = styled("div")`
  display: flex;

  & > div {
    padding: 24px;
    max-width: 480px;
    width: 100%;
    min-height: 288px;
  }
`;

const PlansWrapper = styled("div")`
  display: flex;
  gap: 13px;

  & > div {
    padding: 24px 16px;
    width: calc(25% - 10px);
    min-height: 330px;
  }
`;

const TabsWrapper = styled("div")`
  margin-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #d1d1d2;
`;

const Title = styled("h1")`
  color: ${({ theme }) => theme.primaryText};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
`;

const MobileOnly = styled("div")`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  overflow: hidden;

  font-family: Mulish !important;
`;

export default SumoUserPlans;
