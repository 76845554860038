import styled from "styled-components";
import { FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ReactElement, useContext, useEffect, useRef } from "react";

import ProgressBar from "../ProgressBar";
import ChooseActorTab from "../tabs/ChooseActorTab";
import SetPositionTab from "../tabs/SetPositionTab";
import Button from "../../../../components/Button/Button";
import TranslationTypeTab from "../tabs/TranslationTypeTab";
import TranslationOptionTab from "../tabs/TranslationOptionTab";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import VideoTranslationTabsWrapper from "../tabs/VideoTranslationTabsWrapper";
import SelectVideoTranslation from "../../../../components/Select/SelectVideoTranslaction";
import {
  languageOptions,
  setPositionTabsOption,
  translationOptionTabsOption,
  translationTypeTabsOption,
} from "../../../../mocks/videoTranslation";
import { VideoTranslationContext } from "../../../../App";
import { parsePercent } from "../../../../lib/parsePercent";
import { useUploadForm } from "../../../../hooks/useUploadForm";
import { extractThumbnail } from "../../../../lib/extractThumbnail";
import { DropdownIconThin } from "../../../../components/Icons/Icons";
import { getHumatarsServer } from "../../../../redux/actions/humatarActions";
import {
  faceDetectServer,
  generateVideoToTransatedTextServer,
} from "../../../../redux/actions/videoTranslationActions";
import { selectVideoOptionsActiveTabsInitial } from "../../../../forms/video-translation/tabs";
import { getHumatarList, getHumatarListLoading } from "../../../../redux/reducers/humatarReducer";
import { selectVideoOptionsActiveTabsSchema } from "../../../../forms/video-translation/validation";
import {
  faceDetectData,
  faceDetectIsCompleted,
  faceDetectLoading,
  getTranslatedText,
} from "../../../../redux/reducers/videoTranslationReducer";
import {
  SetPositionTabsType,
  TabsType,
  TranslationOptionTabsType,
  TranslationTypeTabsType,
} from "../../../../types/videoTranslationContext";
import Switch from "../../../../components/Switch/Switch";
import SelectVoice from "../SelectVoice";
import TranslatedText from "../TranslatedText";
import { getActorsServer } from "../../../../redux/actions/actorActions";
import {
  getClonedVoices,
  getClonedVoicesLoading,
  getVoiceTranslate,
  getVoiceTranslateLoading,
} from "../../../../redux/reducers/actorReducer";

interface VoiceOption {
  actorid: number;
  Name: string;
  src?: string;
}

const SelectVideoOptionsStep = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const actors = useSelector(getHumatarList);
  const actorsIsLoading = useSelector(getHumatarListLoading);
  const faceDetect = useSelector(faceDetectData);
  const isFaceDetect = useSelector(faceDetectLoading);
  const isFaceDetectCompleted = useSelector(faceDetectIsCompleted);
  const clonedVoicesServer = useSelector(getClonedVoices);
  const voiceTranslateServer = useSelector(getVoiceTranslate);
  const clonedVoicesLoading = useSelector(getClonedVoicesLoading);
  const voiceTranslateLoading = useSelector(getVoiceTranslateLoading);
  const { isLoading: isTranslatedTextLoading } = useSelector(getTranslatedText);

  const { isLoading, uploadForm, progress } = useUploadForm("VideoTranslateProject/add");

  const originalVoice: VoiceOption[] = [{ actorid: 0, Name: "CLONED", src: "" }];

  const clonedVoices: VoiceOption[] = clonedVoicesServer
    ? [
        ...originalVoice,
        {
          actorid: clonedVoicesServer.actorId,
          Name: clonedVoicesServer.name,
          src: clonedVoicesServer.audioSampleLink || clonedVoicesServer.inputAudio,
        },
      ]
    : originalVoice;

  const voiceTranslate: VoiceOption[] = voiceTranslateServer.length
    ? voiceTranslateServer.map((voice) => ({
        actorid: voice.actorId,
        Name: voice.name,
        src: voice.audioSampleLink,
      }))
    : [];

  const voicesOptions = [...clonedVoices, ...voiceTranslate];

  const {
    setSelectVideoOptionsActiveTabs,
    selectVideoOptionsActiveTabs,
    selectLanguage,
    selectVideoVoice,
    onChangeLanguageSelect,
    onChangeVideoVoice,
    handlePrevStep,
    videoPreviewActor,
    setVideoPreviewActor,
    setUploadedVideo,
    uploadedVideo,
    uploadedVideoDuration,
    isEnableCaption,
    setIsEnableCaption,
    textTranslated,
  } = useContext(VideoTranslationContext);

  const handleChangeVideo = () => {
    setUploadedVideo(null);
    setIsEnableCaption(false);
    handlePrevStep();
  };

  const handleFaceDetect = async () => {
    try {
      const video = document.createElement("video");
      video.muted = true;
      video.src = URL.createObjectURL(uploadedVideo as File);

      await video.load();
      const thumbnailBlob = await extractThumbnail(video);

      if (thumbnailBlob) {
        const base64String = await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result as string);
          reader.readAsDataURL(thumbnailBlob);
        });

        const formData = new FormData();
        formData.append("file", base64String);

        dispatch(faceDetectServer(formData));
      }
    } catch (error) {
      console.error("Error handling face detection and upload:", error);
    }
  };

  const onSubmit = async (values: Record<string, TabsType | null>) => {
    const { chooseActor, setPositionTab, translationOptionTab, translationTypeTab } = values;

    const formData = new FormData();

    if (typeof uploadedVideo === "string") {
      formData.append("videoSrc", uploadedVideo as string);
      formData.append("Title", "Untitled");
    } else {
      formData.append("file", uploadedVideo as File);
      formData.append("Title", uploadedVideo?.name as string);
    }

    formData.append("TargetLanguage", selectLanguage.toLowerCase());
    formData.append("EnableCaption", isEnableCaption.toString());
    formData.append("ActorID", selectVideoVoice.toString());

    formData.append("translatedText", textTranslated);

    if (translationTypeTab === TranslationTypeTabsType.TranslateVideo) {
      formData.append("TranslateOnlyAudio", (!!translationOptionTab)?.toString());
    } else {
      const actorPath = actors.find((actor) => actor.aiHumanActorId === chooseActor)?.photo;

      formData.append("AIHumanActor", chooseActor as string);
      formData.append("Face", actorPath as string);
      formData.append("Position", setPositionTab as string);
    }

    setSelectVideoOptionsActiveTabs(values);

    return await uploadForm(formData);
  };

  const formik = useFormik({
    initialValues: selectVideoOptionsActiveTabsInitial,
    validationSchema: selectVideoOptionsActiveTabsSchema,
    onSubmit,
  });

  const selectVideoOptionsHandleActiveTabFormik = (tab: TabsType, tabType: string) => {
    formik.setValues((prevState) => ({
      ...prevState,
      [tabType]: tab,
    }));
  };

  const modifyChooseActorData = actors.map((actor) => ({
    value: actor.aiHumanActorId,
    content: { avatar: actor.photo },
    actorImg: actor.photo,
  }));

  const modifyTranslationOptionTabsOption = translationOptionTabsOption.map((item) => {
    if (item.value === TranslationOptionTabsType.TranslateWithLipsyncing && isFaceDetect) {
      return {
        ...item,
        disabledValue: true,
        tooltip: "Face detection in progress...",
      };
    } else if (item.value === TranslationOptionTabsType.TranslateWithLipsyncing && !faceDetect && !isFaceDetect) {
      return {
        ...item,
        disabledValue: true,
        tooltip: "This type cannot be selected because there is no face in the video",
      };
    } else if (
      item.value === TranslationOptionTabsType.TranslateWithLipsyncing &&
      uploadedVideoDuration &&
      uploadedVideoDuration > 60 * 5
    ) {
      console.log("uploadedFileDuration", uploadedVideoDuration);
      return {
        ...item,
        disabledValue: true,
        tooltip: "Lipsyncing is disabled for videos longer than 5 mins",
      };
    } else return item;
  });

  const translationSteps: Record<TranslationTypeTabsType, ReactElement> = {
    [TranslationTypeTabsType.TranslateVideo]: (
      <>
        <TabTitle>Select translation type</TabTitle>
        <TranslationOptionTabWrapper>
          <VideoTranslationTabsWrapper
            data={modifyTranslationOptionTabsOption}
            active={formik.values.translationOptionTab}
            handleActive={(tab) => selectVideoOptionsHandleActiveTabFormik(tab, "translationOptionTab")}
            error={
              formik.errors.translationOptionTab && formik.touched.translationOptionTab
                ? `Select translation option is required`
                : false
            }
            disabled={isLoading}
          >
            {<TranslationOptionTab />}
          </VideoTranslationTabsWrapper>
        </TranslationOptionTabWrapper>
      </>
    ),
    [TranslationTypeTabsType.AddActor]: (
      <>
        <ChooseActorTabWrapper error={!!formik.errors.chooseActor && !!formik.touched.chooseActor}>
          <span>Choose your actor</span>
          {!actorsIsLoading ? (
            <VideoTranslationTabsWrapper
              data={modifyChooseActorData}
              active={formik.values.chooseActor}
              withChecked={false}
              handleActive={(tab) => selectVideoOptionsHandleActiveTabFormik(tab, "chooseActor")}
              error={formik.errors.chooseActor && formik.touched.chooseActor ? ` Choose actor is required` : false}
              disabled={isLoading}
            >
              <ChooseActorTab />
            </VideoTranslationTabsWrapper>
          ) : (
            <CircularProgress color="#009af7" />
          )}
        </ChooseActorTabWrapper>
        <TabTitle>Set position</TabTitle>
        <SetPositionTabWrapper>
          <VideoTranslationTabsWrapper
            data={setPositionTabsOption}
            active={formik.values.setPositionTab}
            handleActive={(tab) => selectVideoOptionsHandleActiveTabFormik(tab, "setPositionTab")}
            error={
              formik.errors.setPositionTab && formik.touched.setPositionTab ? `Select positionTab is required` : false
            }
            disabled={!formik.values.chooseActor || isLoading}
          >
            <SetPositionTab />
          </VideoTranslationTabsWrapper>
        </SetPositionTabWrapper>
      </>
    ),
  };

  const getActorImage = () => {
    if (formik.values.translationTypeTab !== TranslationTypeTabsType.AddActor) return;
    if (!formik.values.chooseActor) return;
    if (formik.values.setPositionTab === SetPositionTabsType.LeftBottom)
      return <ActorImg src={videoPreviewActor ?? "/"} position={{ left: "10px", bottom: "0px" }} />;
    if (formik.values.setPositionTab === SetPositionTabsType.RigtBottom)
      return <ActorImg src={videoPreviewActor ?? "/"} position={{ right: "10px", bottom: "0px" }} />;
  };

  const rightPreview = !isLoading ? (
    <>
      <ImgWrapper>
        <PreviewVideo>
          {uploadedVideo ? (
            <source src={typeof uploadedVideo === "string" ? uploadedVideo : URL.createObjectURL(uploadedVideo)} />
          ) : (
            <p>no uploaded video</p>
          )}
        </PreviewVideo>
        {getActorImage()}
      </ImgWrapper>
      <ActionWrapper>
        <SwitchWrapper>
          <span>Add captions/subtitles</span>
          <Switch checked={isEnableCaption} onChange={() => setIsEnableCaption(!isEnableCaption)} />
        </SwitchWrapper>
        <ChangeVideoButton onClick={handleChangeVideo}>Change video</ChangeVideoButton>
      </ActionWrapper>
      <TranslatedText translationTypeTab={formik.values.translationTypeTab as TranslationTypeTabsType} />
    </>
  ) : (
    <ProgressBarWrapper>
      <ProgressBar value={progress} title={`Processing ${parsePercent(progress)}%`} maxWidth={450} />
    </ProgressBarWrapper>
  );

  useEffect(() => {
    modifyChooseActorData.map(
      (item) => item.value === formik.values.chooseActor && setVideoPreviewActor(item.actorImg),
    );
  }, [formik.values.chooseActor]);

  useEffect(() => {
    dispatch(getHumatarsServer({ pageNumber: 1, VideoTranslate: true, pageSize: 5 }));
  }, []);

  useEffect(() => {
    dispatch(getActorsServer({ pageNumber: 1, categoryType: ["Custom"], pageSize: 20, isVideoTranslationPage: true }));
  }, []);

  useEffect(() => {
    dispatch(getActorsServer({ pageNumber: 1, VideoTranslate: true, isVideoTranslationPage: true }));
  }, []);

  useEffect(() => {
    if (uploadedVideo && !isFaceDetectCompleted) {
      handleFaceDetect();
    }
  }, [uploadedVideo]);

  useEffect(() => {
    if (!isFirstRender.current && !!faceDetect && !isFaceDetect) {
      if (formik.values.translationTypeTab === TranslationTypeTabsType.AddActor) {
        setSelectVideoOptionsActiveTabs({
          ...selectVideoOptionsActiveTabs,
          translationTypeTab: null,
        });
        selectVideoOptionsHandleActiveTabFormik(null, "translationTypeTab");
      }
    }

    isFirstRender.current = false;
  }, [faceDetect, isFaceDetect]);

  useEffect(() => {
    if (selectLanguage && uploadedVideo) {
      const formData = new FormData();
      formData.append("file", uploadedVideo);

      dispatch(generateVideoToTransatedTextServer({ language: selectLanguage, formData }));
    }
  }, [selectLanguage]);

  useEffect(() => {
    if (clonedVoicesServer) {
      onChangeVideoVoice(0);
    }
  }, [clonedVoicesServer]);

  useEffect(() => {
    if (voiceTranslateServer.length && !clonedVoicesServer) {
      onChangeVideoVoice(voiceTranslateServer[0].actorId);
    }
  }, [voiceTranslateServer]);

  const isFaceDetecting = isFaceDetect && formik.values.translationTypeTab === TranslationTypeTabsType.AddActor;

  const modifyTranslationTypeTabsOption = translationTypeTabsOption.map((item) =>
    item.value === TranslationTypeTabsType.AddActor && !!faceDetect && !isFaceDetect
      ? {
          ...item,
          disabledValue: true,
          tooltip: "This type cannot be selected because there is already a face in the video",
        }
      : item,
  );

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Left onSubmit={formik.handleSubmit}>
          <Title>Select video options</Title>
          <ScrollWrapper>
            <TabTitle>Select translation type</TabTitle>
            <TranslationTypeTabWrapper>
              <VideoTranslationTabsWrapper
                data={modifyTranslationTypeTabsOption}
                active={formik.values.translationTypeTab}
                handleActive={(tab) => selectVideoOptionsHandleActiveTabFormik(tab, "translationTypeTab")}
                error={
                  formik.errors.translationTypeTab && formik.touched.translationTypeTab
                    ? `Select type is required`
                    : false
                }
              >
                <TranslationTypeTab />
              </VideoTranslationTabsWrapper>
            </TranslationTypeTabWrapper>
            {translationSteps[formik.values.translationTypeTab as TranslationTypeTabsType]}
            {formik.values.translationTypeTab && (
              <>
                <TabTitle>Select Language</TabTitle>
                <SelectWrapper>
                  <SelectVideoTranslation
                    value={selectLanguage}
                    onChange={onChangeLanguageSelect}
                    optionsList={languageOptions.data}
                    actionIcon={<DropdownIconThin />}
                    placeholder="Select Language"
                  />
                </SelectWrapper>
                <TabTitle>Select Voice</TabTitle>
                <SelectVoiceWrapper>
                  {!clonedVoicesLoading && !voiceTranslateLoading ? (
                    <SelectVoice
                      value={selectVideoVoice}
                      onChange={onChangeVideoVoice}
                      optionsList={voicesOptions}
                      actionIcon={<DropdownIconThin />}
                    />
                  ) : (
                    <LoadingWrapper>
                      <CircularProgress color="#009af7" />
                    </LoadingWrapper>
                  )}
                </SelectVoiceWrapper>
              </>
            )}
            <Right progressBar={isLoading} isMobile={true}>
              {rightPreview}
            </Right>
            {formik.values.translationTypeTab && (
              <GenerateButton>
                <Button
                  text={isFaceDetecting ? "Face Detecting..." : "Generate"}
                  type="submit"
                  disabled={isLoading || isFaceDetecting || isTranslatedTextLoading}
                />
              </GenerateButton>
            )}
          </ScrollWrapper>
        </Left>
      </FormikProvider>
      <Right progressBar={isLoading}>{rightPreview}</Right>
    </Wrapper>
  );
};

const SetPositionTabWrapper = styled("div")`
  max-width: 250px;
`;

const GenerateButton = styled("div")`
  button {
    min-height: 48px;
  }
`;

const ProgressBarWrapper = styled("div")`
  width: 90%;
  height: 100%;
  max-height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  margin: auto;

  div {
    justify-content: center;
    align-items: center;
  }
`;

const Wrapper = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  height: 100%;
  gap: 10px;

  form {
    position: relative;

    #portal-root > div {
      display: block;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 500px) {
    max-width: 90%;
    margin: 0 auto;
  }
`;

const Left = styled("form")`
  display: flex;
  flex-direction: column;
  max-width: 495px;
  width: 100%;

  @media (max-width: 900px) {
    padding: 0 8px;
  }
`;

const ScrollWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`;

const Right = styled("div")<{
  progressBar?: boolean;
  isMobile?: true;
}>`
  width: 100%;
  max-width: 600px;
  min-height: 342px;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: none;
  padding-left: 8px;

  ${({ progressBar, theme }) =>
    progressBar &&
    `
      border-radius: 8px;
      background: ${theme.videoTranslationGenerete};
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${theme.videoTranslationGray.replace(
        "#",
        "",
      )}' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
 
 `}

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 20px;
    display: none;
    min-height: auto;
    padding-left: 0;
    overflow: visible;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
      display: none;

      @media (max-width: 900px) {
        display: flex;
        margin: 0px 0px 20px;
        min-height:300px;
        padding-left: 0;
      }
    `}
`;

const ChangeVideoButton = styled("span")`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #6f7074;
  cursor: pointer;
  max-width: max-content;
`;

const ImgWrapper = styled("div")`
  display: flex;
  max-width: 600px;
  max-height: 460px;
  height: 100%;
  position: relative;
`;

const PreviewVideo = styled("video")`
  width: 100%;
  height: 100%;
  max-height: 460px;
`;

const ActorImg = styled("img")<{
  position?: { left?: string; right?: string; top?: string; bottom?: string };
}>`
  position: absolute;
  width: 212px;
  height: 212px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.white};
  ${({ position }) =>
    position &&
    `
      ${position.bottom ? `bottom: ${position.bottom};` : ""}
      ${position.left ? `left: ${position.left};` : ""}
      ${position.right ? `right: ${position.right};` : ""}
      ${position.top ? `top: ${position.top};` : ""}
    `}
`;

const ChooseActorTabWrapper = styled("div")<{ error?: boolean }>`
  position: relative;
  width: 100%;
  max-width: 495px;
  padding: 13px 12px 13px 15px;
  border: ${({ theme }) => theme.videoTranslationBorder};
  background-color: ${({ theme }) => theme.videoTranslationBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: ${({ error }) => (error ? "48px" : "20px")};

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};
    width: 100%;
  }

  & > div {
    margin: 0;
  }

  div > div {
    div {
      width: 44px;
      height: 44px;
      border-radius: 40px;
      border: ${({ theme }) => theme.videoTranslationBorder};
      border: 2px;
    }
  }

  div > .active {
    border: 2px solid #3091d7;
  }

  div > .not-active {
    border: 2px solid transparent;
  }

  .tabs-error-text {
    position: absolute;
    bottom: -26px;
    left: 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const Title = styled("span")`
  color: ${({ theme }) => theme.primaryText};
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px 0px 16px 0px;
`;

const TabTitle = styled("span")`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0px 0px 12px 0px;
`;

const SelectWrapper = styled("div")`
  margin-bottom: 20px;
`;

const TranslationTypeTabWrapper = styled("div")`
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    .video-translation-tabs-wrapper {
      flex-direction: column;
      max-width: 100%;
    }
  }
`;

const TranslationOptionTabWrapper = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  @media (max-width: 500px) {
    div {
      width: 100%;
    }
    .video-translation-tabs-wrapper {
      flex-direction: column;
      max-width: 100%;
      width: 100%;
    }
  }
`;

const ActionWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
`;

const SwitchWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 12px;

  & > span {
    color: ${({ theme }) => theme.primaryText};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  & > label {
    width: 34px;
    height: 20px;
    min-width: 34px;

    .slider-range:before {
      width: 16px;
      height: 16px;
    }
  }
`;

const SelectVoiceWrapper = styled("div")`
  margin-bottom: 32px;
`;

const LoadingWrapper = styled("div")`
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border: ${({ theme }) => theme.videoTranslationBorder};
  background-color: ${({ theme }) => theme.videoTranslationBackground};
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
`;

export default SelectVideoOptionsStep;
