import React from "react";
import styled from "styled-components";

import Tooltip from "../Tooltip/Tooltip";

interface Props {
  title: string;
  onChange: (e: any) => void;
  value: number;
  step?: number;
  modifier?: string;
  activeBarLabel?: string;
}

const ProgressBar = ({ title, onChange, step, modifier, activeBarLabel, value }: Props) => (
  <Wrapper>
    <span className="progress-bar-title">{title}</span>
    <Row>
      <ProgressBarLineWrapper>
        <ProgressBarLine value={value} type="range" min="0" max="100" step={step} width={value} onChange={onChange} />
        <Tooltip
          text={`${activeBarLabel}${modifier ? modifier : ""}`}
          position="top"
          style={{
            boxShadow: "none",
            borderRadius: "4px",
            padding: "4px 8px 6px",
            left: `calc(${value}% - 2px)`,
            bottom: "15px",
          }}
          reverseColor
          arrow
        />
      </ProgressBarLineWrapper>
      <span>
        {activeBarLabel}
        {modifier}
      </span>
    </Row>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;

  & > span:first-of-type {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryText};
    width: 39px;
    flex-shrink: 0;
  }

  & > div {
    position: relative;
    width: 100%;
    display: flex;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #6f7074;
    white-space: nowrap;
  }
`;

const ProgressBarLineWrapper = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 60px;
  display: flex;
  align-items: center;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const ProgressBarLine = styled.input<{ width: number }>`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 80px;
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  ::-webkit-slider-runnable-track {
    background: ${({ theme, width }) => `linear-gradient(to right, ${theme.activeMenu} ${width}%, transparent 0)`};
    height: 4px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: ${({ theme }) => theme.white};
    width: 4px;
    height: 4px;
    padding: 2px;
    margin-top: -2px;
    border: 2px solid ${({ theme }) => theme.activeMenu};
    border-radius: 52px;
    cursor: pointer;
  }
`;

const RangeWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.4;
  }
`;

export default ProgressBar;
