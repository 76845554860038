export const CreateVariationsIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 19V19.88C12 21.75 11.25 22.5 9.37 22.5H4.62C3.17 22.5 2 21.33 2 19.88V15.13C2 13.25 2.75 12.5 4.62 12.5H5.5V16C5.5 17.66 6.84 19 8.5 19H12Z"
      fill="white"
    />
    <path
      d="M17 14V14.87C17 16.32 15.82 17.5 14.37 17.5H9.62C7.75 17.5 7 16.75 7 14.87V10.12C7 8.67 8.17 7.5 9.62 7.5H10.5V11C10.5 12.66 11.84 14 13.5 14H17Z"
      fill="white"
    />
    <path
      d="M22 5.12V9.87C22 11.75 21.25 12.5 19.37 12.5H14.62C12.75 12.5 12 11.75 12 9.87V5.12C12 3.25 12.75 2.5 14.62 2.5H19.37C21.25 2.5 22 3.25 22 5.12Z"
      fill="white"
    />
  </svg>
);
