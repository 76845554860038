import styled from "styled-components";
import Button from "../../../components/Button/Button";

interface Props {
  data: {
    value: any;
    title: string;
  }[];
  active: any;
  handleActive: (status: any) => void;
}

const Tabs = ({ data, active, handleActive }: Props) => {
  console.log(data, "data");
  return (
    <Wrapper>
      {data.map(({ value, title }) => (
        <Button
          key={title}
          text={title}
          className={value === active ? "active" : "not-active"}
          onClick={() => handleActive(value)}
        />
      ))}
    </Wrapper> //
  );
};

const Wrapper = styled.div`
  padding: 4px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.themeSwitcherShadow};
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 420px;

  button {
    border-radius: 52px;
    height: 40px;

    span {
      font-size: 14px;
      line-height: 24px;
    }

    &.not-active {
      background: transparent;
      box-shadow: none;
      opacity: 0.4;

      span {
        font-weight: 500;
        color: ${({ theme }) => theme.primaryText};
      }
    }
  }
`;

export default Tabs;
