import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import IconButton, { IconButtonThemes } from "../../components/Button/IconButton";
import { Status } from "../../redux/actions/humansProjectActions";
import { CloseIconV2, FailureIcon } from "../../components/Icons/Icons";

interface Props {
  status?: Status;
  text?: ReactNode;
  closeIcon?: boolean;
  output?: string;
}

const FailUploadAlert = ({ status, text, closeIcon = true, output }: Props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  if (
    status === Status.isDraft ||
    (status as Status) > Status.isError ||
    (status as Status) < Status.isDraft ||
    !status
  )
    return <></>;

  return (
    <Wrapper open={open} variant={status} className="alert-wrapper">
      {content({ status, text, output })}
      {closeIcon && (
        <IconButton
          icon={<CloseIconV2 />}
          iconButtonTheme={IconButtonThemes.Transparent}
          onClick={handleClose}
          className="close-icon"
        />
      )}
    </Wrapper>
  );
};

const content = ({ status, text, output }: Props) => {
  switch (status) {
    case Status.isError:
      return (
        <>
          <Heading>
            <FailureIcon />
            <Title>Your files failed to upload.</Title>
          </Heading>
          <Description>{text}</Description>
        </>
      );
    default:
      return <div></div>;
  }
};

const Heading = styled.div`
  display: flex;
  width: 100%;
  column-gap: 10px;
`;

const Title = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const Description = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
`;

const Wrapper = styled.div<{ open?: boolean; variant?: Status }>`
  padding: 15px;
  border-radius: 12px;
  width: 100%;
  gap: 12px;
  z-index: 70;
  width: 660px;
  max-width: 100%;
  visibility: visible;
  background-color: #db3a2f;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fff;

    span {
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    opacity: 0.7;
    right: 10px;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }

  .failure-icon {
    path {
      stroke: white;
    }
  }

  svg {
    width: 22px;
    height: 22px;
  }

  button {
    margin-left: auto;

    svg > path {
      fill: initial;
    }
  }

  ${({ open }) =>
    !open &&
    `
    display: none;
    `}
`;

export default FailUploadAlert;
