export const ImportIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16907 10.0632C9.1675 10.0424 9.16671 10.0213 9.16671 10V3.33333C9.16671 2.87333 9.53921 2.5 10 2.5C10.4609 2.5 10.8334 2.87333 10.8334 3.33333V9.99985L12.8334 8.5C13.2017 8.2225 13.7242 8.29833 14 8.66667C14.2767 9.035 14.2017 9.5575 13.8334 9.83333L10.5 12.3333C10.3525 12.4442 10.1759 12.5 10 12.5C9.83254 12.5 9.66504 12.4492 9.52087 12.3483L6.18754 10.0033C5.81087 9.73833 5.72004 9.21833 5.98504 8.8425C6.25004 8.46583 6.76921 8.375 7.14587 8.64L9.16907 10.0632ZM5.00004 14.1667V15H15V14.1667C15 13.7083 15.375 13.3333 15.8334 13.3333C16.2917 13.3333 16.6667 13.7083 16.6667 14.1667V15.8333C16.6667 16.2917 16.2917 16.6667 15.8334 16.6667H4.16671C3.70837 16.6667 3.33337 16.2917 3.33337 15.8333V14.1667C3.33337 13.7083 3.70837 13.3333 4.16671 13.3333C4.62504 13.3333 5.00004 13.7083 5.00004 14.1667Z"
      fill="url(#paint0_linear_1010_9076)"
    />
    <mask id="mask0_1010_9076" maskUnits="userSpaceOnUse" x="3" y="2" width="14" height="15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16907 10.0632C9.1675 10.0424 9.16671 10.0213 9.16671 10V3.33333C9.16671 2.87333 9.53921 2.5 10 2.5C10.4609 2.5 10.8334 2.87333 10.8334 3.33333V9.99985L12.8334 8.5C13.2017 8.2225 13.7242 8.29833 14 8.66667C14.2767 9.035 14.2017 9.5575 13.8334 9.83333L10.5 12.3333C10.3525 12.4442 10.1759 12.5 10 12.5C9.83254 12.5 9.66504 12.4492 9.52087 12.3483L6.18754 10.0033C5.81087 9.73833 5.72004 9.21833 5.98504 8.8425C6.25004 8.46583 6.76921 8.375 7.14587 8.64L9.16907 10.0632ZM5.00004 14.1667V15H15V14.1667C15 13.7083 15.375 13.3333 15.8334 13.3333C16.2917 13.3333 16.6667 13.7083 16.6667 14.1667V15.8333C16.6667 16.2917 16.2917 16.6667 15.8334 16.6667H4.16671C3.70837 16.6667 3.33337 16.2917 3.33337 15.8333V14.1667C3.33337 13.7083 3.70837 13.3333 4.16671 13.3333C4.62504 13.3333 5.00004 13.7083 5.00004 14.1667Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1010_9076)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_1010_9076"
        x1="6.20517"
        y1="4.35256"
        x2="14.7075"
        y2="14.6411"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
