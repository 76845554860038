import * as Yup from "yup";
import { TranslationTypeTabsType } from "../../types/videoTranslationContext";
import { UploadUrlInputFields } from "./types";

export const selectVideoOptionsActiveTabsSchema = Yup.object().shape({
  translationTypeTab: Yup.string().required("Translation type is required"),
  translationOptionTab: Yup.string().when("translationTypeTab", {
    is: TranslationTypeTabsType.TranslateVideo,
    then: Yup.string().required("Translation option is required"),
    otherwise: Yup.string().nullable(),
  }),
  chooseActor: Yup.string().when("translationTypeTab", {
    is: TranslationTypeTabsType.AddActor,
    then: Yup.string().required("Selecting actor is required"),
    otherwise: Yup.string().nullable(),
  }),
  setPositionTab: Yup.string().when("translationTypeTab", {
    is: TranslationTypeTabsType.AddActor,
    then: Yup.string().required("Setting position is required"),
    otherwise: Yup.string().nullable(),
  }),
});

export const uploadUrlSchema = Yup.object().shape({
  [UploadUrlInputFields.url]: Yup.string().required("Please, enter a URL").url("Please, enter a valid URL"),
});
