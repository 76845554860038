import domtoimage from "dom-to-image";
import { addPx, removePx } from "./stylesHelper";
import { ResolutionSize } from "../components/Popups/GenerativeVideoPopup";

export const handleDOMtoBase64 = async function (
  elementSelector: string,
  canvasWidth: number,
  isHorizontal: boolean,
  resolutionSize: ResolutionSize,
) {
  const element = document.querySelector(elementSelector);
  if (!element) return "";

  const clone = element.cloneNode(true) as HTMLElement;
  document.body.appendChild(clone);

  const coefficient =
    (isHorizontal ? parseFloat(resolutionSize.width) : parseFloat(resolutionSize.height)) / canvasWidth;

  const width = clone.offsetWidth;
  const height = clone.offsetHeight;

  clone.style.width = `${width * coefficient}px`;
  clone.style.height = `${height * coefficient}px`;
  clone.style.transform = `translate(0px, 0px)`;
  clone.style.zIndex = "-1";

  const text = clone.querySelector(".draggable-text") as HTMLElement | null;
  if (text) {
    const fontSize = +removePx(text.style.fontSize);
    const lineHeight = +removePx(text.style.lineHeight);

    text.style.fontSize = `${fontSize * coefficient}px`;
    text.style.lineHeight = `${lineHeight * coefficient}px`;
  }

  const borderElements = clone.getElementsByClassName("scene-object-wrapper");
  for (let i = 0; i < borderElements.length; i++) {
    (borderElements[i] as HTMLElement).style.border = "none";
  }

  const children = clone.getElementsByClassName("shape0");
  for (let i = 0; i < children.length; i++) {
    const computedStyles = window.getComputedStyle(children[i]);
    const borderWidth = +removePx(computedStyles.borderWidth);
    (children[i] as HTMLElement).style.borderWidth = `${coefficient * borderWidth}px`;
  }

  const result = await domtoimage
    .toPng(clone, {
      filter: (node) =>
        (node as HTMLElement).classList && (node as HTMLElement).classList.contains("scene-frame") ? false : true,
    })
    .then((result) => {
      document.body.removeChild(clone);
      return result;
    });

  return result;
};
