import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";

import CustomDot from "./CustomDot";
import MobilePlan from "./MobilePlan";
import Table from "../../../components/Table/Table";
import { mobileHumans, mobileImages, mobileStudio, mobileVoice, pricingSectionMock } from "../../../mocks/pricing";
import { PriceSection } from "../../../types/pricing";

interface PlansProps {
  activeSection: PriceSection;
  activeGroups: PriceSection[];
}

const getMobilePlan = (activeSection: PriceSection) => {
  if (activeSection === PriceSection.voices) return mobileVoice;
  if (activeSection === PriceSection.humans) return mobileHumans;
  if (activeSection === PriceSection.image) return mobileImages;
  if (activeSection === PriceSection.studio) return mobileStudio;
  return mobileVoice;
};

const Plans: FC<PlansProps> = ({ activeSection, activeGroups }) => {
  const [isMobile, setIsMobile] = useState<boolean>(true);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i: number) => <CustomDot key={i} />,
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);
    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const mediaQuery = window.matchMedia("(min-width: 768px)");
      setIsMobile(mediaQuery.matches);
    }
  }, []);

  return (
    <Wrapper>
      <Title>Compare all plans, features by features</Title>

      {isMobile ? (
        <TablesWrapper>
          <Table
            {...pricingSectionMock[activeSection].table}
            isActiveGroup={activeGroups.includes(activeSection)}
            activeSection={activeSection}
          />
        </TablesWrapper>
      ) : (
        <Slider {...settings}>
          {getMobilePlan(activeSection).map((table, index) => (
            <MobilePlan key={index} {...table} activeGroups={activeGroups} activeSection={activeSection} />
          ))}
        </Slider>
      )}
    </Wrapper>
  );
};

const TablesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 38px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.primaryText};

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin: 0 auto 140px;

  .slick-list {
    padding-bottom: 25px;
  }

  li {
    cursor: default;
    margin: 0 1px;
  }

  & li.slick-active {
    .dot {
      background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%), #d9d9d9;
      border: none;
      transform: scaleX(2);
      //width: 20px;
      border-radius: 35%;
    }
  }

  @media (max-width: 768px) {
    row-gap: 24px;
    //margin: 0 auto 100px;
    //padding-bottom: 100px;
    margin: 0 0 100px;
  }
`;

export default Plans;
