export const TataLogoWhite = () => (
  <svg width="138" height="46" viewBox="0 0 138 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.2729 17.2899H73.1053V11.9563H75.1146V9.80054H68.2637V11.9563H70.2729V17.2899Z" fill="white" />
    <path
      d="M77.2021 17.2899L78.6458 12.8111L80.0873 17.2899H82.858L80.1423 9.80054H77.1471L74.4336 17.2899H77.2021Z"
      fill="white"
    />
    <path d="M84.189 17.2899H87.0213V11.9563H89.0306V9.80054H82.1797V11.9563H84.189V17.2899Z" fill="white" />
    <path
      d="M91.1181 17.2899L92.5618 12.8111L94.0033 17.2899H96.774L94.0605 9.80054H91.0631L88.3496 17.2899H91.1181Z"
      fill="white"
    />
    <path
      d="M118.301 22.6444C118.651 23.4923 119.113 24.4099 119.573 25.2276L121.426 28.5216H122.768V21.0322H121.569V23.6456C121.569 24.7908 121.593 25.7549 121.685 26.7491L121.661 26.7561C121.333 25.9431 120.922 25.0719 120.453 24.2473L118.62 21.0322H117.139V28.5216H118.336V25.8432C118.336 24.6143 118.318 23.6247 118.268 22.6514L118.301 22.6444Z"
      fill="white"
    />
    <path
      d="M111.869 26.4053H114.123L114.765 28.5216H116.156L113.852 21.0322H112.204L109.926 28.5216H111.266L111.869 26.4053ZM112.628 23.5225C112.754 23.0765 112.862 22.5584 112.965 22.1194H112.987C113.095 22.5538 113.211 23.0649 113.346 23.5271L113.905 25.3739H112.076L112.628 23.5225Z"
      fill="white"
    />
    <path
      d="M90.7449 27.5067C90.0846 27.5067 89.4486 27.2837 89.0305 27.0282L88.7422 28.1618C89.1295 28.4127 89.9086 28.6404 90.659 28.6404C92.49 28.6404 93.3505 27.595 93.3505 26.3894C93.3505 25.3138 92.7585 24.6587 91.5261 24.1639C90.5798 23.783 90.1683 23.5112 90.1683 22.9374C90.1683 22.496 90.5182 22.0361 91.3413 22.0361C92.0081 22.0361 92.5033 22.2498 92.7585 22.3915L93.0688 21.2881C92.6991 21.0906 92.1357 20.9187 91.3677 20.9187C89.8294 20.9187 88.8566 21.8433 88.8566 23.0698C88.8566 24.143 89.6049 24.8074 90.7779 25.2465C91.6736 25.5903 92.0345 25.9248 92.0345 26.4893C92.0345 27.0979 91.5701 27.5067 90.7449 27.5067Z"
      fill="white"
    />
    <path
      d="M72.1172 22.045C72.696 22.045 73.1691 22.1797 73.4992 22.3377L73.7809 21.2482C73.5168 21.0972 72.916 20.916 72.0621 20.916C69.8944 20.916 68.2637 22.4027 68.2637 24.8651C68.2637 27.144 69.6347 28.64 71.8861 28.64C72.7444 28.64 73.42 28.4611 73.7259 28.2985L73.5124 27.2206C73.1713 27.3833 72.6233 27.4924 72.0974 27.4924C70.537 27.4924 69.6281 26.4471 69.6281 24.7954C69.6281 22.9974 70.6691 22.045 72.1172 22.045Z"
      fill="white"
    />
    <path d="M108.343 28.5216V22.1821H110.387V21.0322H105.02V22.1821H107.046V28.5216H108.343Z" fill="white" />
    <path
      d="M134.247 21.0322L133.393 22.951C133.151 23.4946 132.955 23.9592 132.779 24.4377H132.757C132.564 23.9313 132.379 23.5039 132.139 22.9534L131.294 21.0322H129.811L132.055 25.4041V28.5216H133.352V25.3669L135.709 21.0346H134.247V21.0322Z"
      fill="white"
    />
    <path
      d="M77.5363 20.9114C75.5337 20.9114 74.1582 22.5352 74.1582 24.8349C74.1582 27.0278 75.4258 28.647 77.4329 28.647H77.4439C79.3872 28.647 80.8286 27.2114 80.8286 24.7025C80.8242 22.5607 79.605 20.9114 77.5363 20.9114ZM77.4857 27.5505H77.4747C76.2357 27.5505 75.5117 26.2914 75.5161 24.8024C75.5139 23.3087 76.1961 22.0055 77.4901 22.0055C78.7842 22.0055 79.4488 23.3412 79.4488 24.7536C79.4466 26.3193 78.7379 27.5505 77.4857 27.5505Z"
      fill="white"
    />
    <path
      d="M127.738 22.045C128.317 22.045 128.79 22.1797 129.12 22.3377L129.402 21.2482C129.138 21.0972 128.537 20.916 127.683 20.916C125.516 20.916 123.885 22.4027 123.885 24.8651C123.885 27.144 125.256 28.64 127.507 28.64C128.365 28.64 129.041 28.4634 129.347 28.2985L129.134 27.2206C128.792 27.3833 128.244 27.4924 127.718 27.4924C126.158 27.4924 125.249 26.4471 125.249 24.7954C125.249 22.9974 126.29 22.045 127.738 22.045Z"
      fill="white"
    />
    <path d="M102.822 21.0322H101.525V28.5216H105.74V27.374H102.822V21.0322Z" fill="white" />
    <path
      d="M100.053 25.3113V21.0347H98.7569V25.4019C98.7569 26.87 98.1958 27.546 97.2626 27.546C96.3845 27.546 95.808 26.8654 95.808 25.4019V21.0347H94.5117V25.3206C94.5117 27.6761 95.5791 28.6495 97.2142 28.6495C98.9286 28.6471 100.053 27.6181 100.053 25.3113Z"
      fill="white"
    />
    <path
      d="M87.567 21.0322H86.3676V23.6456C86.3676 24.7908 86.3918 25.7549 86.4842 26.7491L86.46 26.7561C86.1321 25.9431 85.7206 25.0719 85.2518 24.2473L83.4186 21.0322H81.9375V28.5216H83.1369V25.8432C83.1369 24.6143 83.1193 23.6247 83.0687 22.6537L83.1017 22.6467C83.4516 23.4946 83.9138 24.4122 84.3737 25.2299L86.2267 28.5239H87.5692V21.0322H87.567Z"
      fill="white"
    />
    <path d="M93.1126 32.2664H91.8164V39.7557H93.1126V32.2664Z" fill="white" />
    <path
      d="M82.9036 36.2433V36.2131C83.5661 35.9646 84.1823 35.3141 84.1823 34.3571C84.1823 33.7206 83.9622 33.2049 83.5771 32.8657C83.1017 32.4476 82.283 32.2664 81.4049 32.2664C80.5268 32.2664 79.9458 32.3314 79.4551 32.4266V39.7557H80.7315V36.701H81.3785C82.1092 36.7196 82.4437 37.0332 82.6461 38.0019C82.842 38.9264 83.0115 39.5397 83.1193 39.7557H84.4398C84.2989 39.4653 84.1074 38.6128 83.8852 37.6836C83.7091 36.9611 83.4142 36.4478 82.9036 36.2433ZM81.493 35.7044H80.7315V33.3558C80.8613 33.321 81.1232 33.2885 81.5304 33.2885C82.3623 33.2908 82.9014 33.6695 82.9014 34.4802C82.9014 35.2189 82.3645 35.7044 81.493 35.7044Z"
      fill="white"
    />
    <path
      d="M88.6388 35.6903C88.3638 36.5986 88.1063 37.5139 87.917 38.3966H87.895C87.7101 37.4907 87.4681 36.6149 87.1996 35.6694L86.2312 32.2639H84.8184L87.1137 39.7533H88.5992L91.0509 32.2639H89.671L88.6388 35.6903Z"
      fill="white"
    />
    <path
      d="M102.079 36.4315H104.753V35.3165H102.079V33.3907H104.912V32.2664H100.783V39.7534H105.068V38.6291H102.079V36.4315Z"
      fill="white"
    />
    <path
      d="M98.0801 33.2767C98.6589 33.2767 99.132 33.4114 99.4621 33.5694L99.7438 32.4799C99.4797 32.3289 98.8789 32.1477 98.025 32.1477C95.8573 32.1477 94.2266 33.6344 94.2266 36.0968C94.2266 38.3757 95.5976 39.8717 97.849 39.8717C98.7073 39.8717 99.3829 39.6951 99.6888 39.5302L99.4753 38.4523C99.132 38.6149 98.5862 38.7241 98.0603 38.7241C96.4999 38.7241 95.591 37.6788 95.591 36.0271C95.591 34.2291 96.632 33.2767 98.0801 33.2767Z"
      fill="white"
    />
    <path
      d="M71.0479 35.3956C70.0993 35.0147 69.69 34.7429 69.69 34.1691C69.69 33.7277 70.0399 33.2678 70.8608 33.2678C71.5276 33.2678 72.0228 33.4815 72.2781 33.6232L72.5884 32.5198C72.2186 32.3223 71.6553 32.1504 70.8872 32.1504C69.3489 32.1504 68.3762 33.0749 68.3762 34.3015C68.3762 35.3747 69.1244 36.0391 70.2974 36.4781C71.1931 36.8219 71.554 37.1565 71.554 37.721C71.554 38.3342 71.0919 38.7431 70.2644 38.7431C69.6042 38.7431 68.9682 38.5201 68.55 38.2645L68.2617 39.3982C68.649 39.649 69.4281 39.8767 70.1786 39.8767C72.0096 39.8767 72.8701 38.8313 72.8701 37.6257C72.8723 36.5455 72.2803 35.8904 71.0479 35.3956Z"
      fill="white"
    />
    <path
      d="M75.3333 36.4315H78.0072V35.3165H75.3333V33.3907H78.1679V32.2664H74.0371V39.7534H78.3242V38.6291H75.3333V36.4315Z"
      fill="white"
    />
    <path
      d="M108.681 35.3956C107.734 35.0147 107.323 34.7429 107.323 34.1691C107.323 33.7277 107.673 33.2678 108.494 33.2678C109.16 33.2678 109.656 33.4815 109.911 33.6232L110.221 32.5198C109.851 32.3223 109.288 32.1504 108.52 32.1504C106.982 32.1504 106.009 33.0749 106.009 34.3015C106.009 35.3747 106.757 36.0391 107.93 36.4781C108.826 36.8219 109.187 37.1565 109.187 37.721C109.187 38.3342 108.725 38.7431 107.897 38.7431C107.237 38.7431 106.601 38.5201 106.183 38.2645L105.895 39.3982C106.282 39.649 107.061 39.8767 107.811 39.8767C109.642 39.8767 110.503 38.8313 110.503 37.6257C110.505 36.5455 109.913 35.8904 108.681 35.3956Z"
      fill="white"
    />
    <path
      d="M1.44531 14.9229C1.44531 11.5313 3.53381 9.49635 6.58624 9.49635V3.73066C6.58624 2.03487 5.51448 0.903564 4.01578 0.903564C2.51707 0.903564 1.44531 2.03487 1.44531 3.73066V14.9229Z"
      fill="white"
    />
    <path
      d="M10.5498 39.9625C11.835 39.9625 13.0124 39.7372 14.0842 39.34C8.67474 40.3017 6.58624 36.4014 6.58624 31.9923V13.9054H12.8517C14.2976 13.9054 15.2616 13.0017 15.2616 11.7009C15.2616 10.4 14.2976 9.49634 12.8517 9.49634H6.58624C3.53381 9.49634 1.44531 11.5313 1.44531 14.9229V30.4661C1.44531 36.6848 4.81905 39.9625 10.5498 39.9625Z"
      fill="white"
    />
    <path
      d="M14.0839 39.3397C16.2252 38.4918 17.8867 36.9098 18.9563 34.5915L16.5465 30.0128C15.4747 33.4044 13.6019 35.214 11.0314 35.214C8.35313 35.214 6.58594 33.574 6.58594 29.8433V31.992C6.58594 36.4011 8.67444 40.3014 14.0839 39.3397Z"
      fill="white"
    />
    <path
      d="M36.0913 38.4362C23.8816 40.3573 20.0259 33.4627 20.0259 24.5888C20.0259 18.1448 23.7738 13.7357 29.1832 13.7357C31.1639 13.7357 32.8254 14.1887 34.3241 14.9228C35.0196 15.262 35.6094 15.3758 36.1442 15.3758C37.4294 15.3758 38.2855 14.4721 38.2855 13.1155C38.2855 12.2676 37.8035 11.4197 36.6789 10.8552C34.6432 9.78199 32.02 9.15942 29.181 9.15942C20.7192 9.15942 14.8828 15.4896 14.8828 24.7026C14.8828 33.7461 20.5057 40.0205 29.5551 40.0205C32.0222 40.0182 34.1635 39.5094 36.0913 38.4362Z"
      fill="white"
    />
    <path
      d="M14.8867 24.7004C14.8867 15.4874 20.7231 9.15723 29.1849 9.15723C32.0239 9.15723 34.6472 9.77979 36.6828 10.853C37.8074 11.4175 38.2894 12.2654 38.2894 13.1133C38.2894 14.4699 37.4333 15.3736 36.1481 15.3736C35.6133 15.3736 35.0235 15.2598 34.328 14.9206C32.508 14.0169 30.7408 13.7335 29.1871 13.7335C21.7398 13.7359 14.8867 18.7094 14.8867 24.7004Z"
      fill="white"
    />
    <path
      d="M46.8558 10.9668C46.3738 11.4755 46.1053 12.0981 46.1053 12.8322C46.1053 14.6975 47.7647 17.4666 51.6204 20.2937L56.0109 23.5157C59.2239 25.8898 60.5092 28.15 60.5092 31.5416C60.5092 33.9715 59.5981 36.0645 58.0994 37.5327C58.6341 37.0239 58.9554 36.3456 58.9554 35.4419C58.9554 33.4628 57.0804 30.3546 53.0641 27.416L49.0477 24.4774C45.674 21.9894 44.3359 19.6734 44.3359 16.5072C44.3359 14.6418 44.9786 12.946 46.0503 11.7032C46.321 11.4175 46.5873 11.1921 46.8558 10.9668Z"
      fill="white"
    />
    <path
      d="M43.377 36.3456C43.377 37.2493 43.8061 37.9857 44.9307 38.5502C46.4844 39.3423 48.9998 40.0206 51.249 40.0206C54.0879 40.0206 56.4427 39.117 58.1043 37.5327C58.6391 37.0239 58.9604 36.3456 58.9604 35.442C58.9604 33.4628 57.0854 30.3546 53.069 27.416C54.889 28.7726 55.6395 29.9039 55.6395 31.5417C55.6395 33.9715 53.8195 35.6116 51.194 35.6116C48.6763 35.6116 46.535 34.2549 45.3576 34.2549C44.1802 34.2549 43.377 35.1028 43.377 36.3456Z"
      fill="white"
    />
    <path
      d="M51.6208 20.2936C49.9064 18.9927 49.1581 17.8056 49.1581 16.3933C49.1581 14.5836 50.5512 13.4547 52.5319 13.4547C55.0495 13.4547 56.2269 14.6975 57.7256 14.6975C58.903 14.6975 59.7063 13.8496 59.7063 12.6068C59.7063 11.7031 59.2771 10.9667 58.1526 10.4022C56.3854 9.49858 54.4575 9.15942 52.369 9.15942C50.1727 9.15942 48.2448 9.83774 46.854 10.969C46.372 11.4778 46.1035 12.1003 46.1035 12.8344C46.1035 14.6975 47.7651 17.4665 51.6208 20.2936Z"
      fill="white"
    />
    <path
      d="M20.0293 24.5889C20.0293 33.4628 23.885 40.3598 36.0947 38.4364C40.0032 36.2876 42.7893 31.9366 44.715 25.2673L45.8396 21.3669C44.7678 19.8965 44.3409 18.2588 44.3409 16.5049C44.3409 14.6395 44.9835 12.9437 46.0552 11.7009C44.4487 13.5105 43.1106 16.1657 42.0389 19.8965L40.8065 24.1917C38.6101 31.767 35.1308 35.3839 29.7213 35.3839C23.7772 35.3839 20.0293 31.033 20.0293 24.5889Z"
      fill="white"
    />
  </svg>
);
