export const TextAlignCenterActiveIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 0.25C0.585786 0.25 0.25 0.585786 0.25 1C0.25 1.41421 0.585786 1.75 1 1.75H15C15.4142 1.75 15.75 1.41421 15.75 1C15.75 0.585786 15.4142 0.25 15 0.25H1Z"
      fill="url(#paint0_linear_58_1723)"
    />
    <path
      d="M5 4.25C4.58579 4.25 4.25 4.58579 4.25 5C4.25 5.41421 4.58579 5.75 5 5.75H11C11.4142 5.75 11.75 5.41421 11.75 5C11.75 4.58579 11.4142 4.25 11 4.25H5Z"
      fill="url(#paint1_linear_58_1723)"
    />
    <path
      d="M1 8.25C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75H15C15.4142 9.75 15.75 9.41421 15.75 9C15.75 8.58579 15.4142 8.25 15 8.25H1Z"
      fill="url(#paint2_linear_58_1723)"
    />
    <path
      d="M5 12.25C4.58579 12.25 4.25 12.5858 4.25 13C4.25 13.4142 4.58579 13.75 5 13.75H11C11.4142 13.75 11.75 13.4142 11.75 13C11.75 12.5858 11.4142 12.25 11 12.25H5Z"
      fill="url(#paint3_linear_58_1723)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_58_1723"
        x1="3.58846"
        y1="2.01538"
        x2="11.25"
        y2="13.3253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_58_1723"
        x1="3.58846"
        y1="2.01538"
        x2="11.25"
        y2="13.3253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_58_1723"
        x1="3.58846"
        y1="2.01538"
        x2="11.25"
        y2="13.3253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_58_1723"
        x1="3.58846"
        y1="2.01538"
        x2="11.25"
        y2="13.3253"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
