import styled from "styled-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";

import SceneActionMenu from "./SceneActionMenu";
import AIHumansPreviewPlayer from "./AIHumansPreviewPlayer";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { Zone } from "../../../types/project";
import { VideoEditorContext } from "../../../App";
import { ScreenProps } from "../../../mocks/humans";
import { reorderSlides } from "../../../lib/reorderSlides";
import { useSlideActions } from "../../../hooks/useAIHumans";
import { ProfileHumanSidebarType } from "../../../types/human";
import { useVideoPlayer } from "../../../hooks/useVideoPlayer";
import { HumansParagraph } from "../../../types/humansProject";
import { SentryErrors, sentryErrors } from "../../../lib/sentry";
import { NotEnoughCredits } from "../../../types/notEnoughCredits";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { AnimatedTransitionIcon, PlusIcon } from "../../../components/Icons/Icons";
import { Avatar, BackgroundColor, ObjectTypes, Scene } from "../../../types/scene";
import { getHumansProjectAudio } from "../../../redux/reducers/humansProjectReducer";

interface Props {
  selectedZone?: Zone;
  paragraphs: HumansParagraph[];
  activeScreen: ScreenProps;
  setActiveGlobalTab: (type: ProfileHumanSidebarType) => void;
  leftSidebarOpen?: boolean;
  editorContent: EditorState[];
  isButtonClicked: boolean;
  setIsButtonClicked: (b: boolean) => void;
  setParagraphs: (nextParagraphs: HumansParagraph[]) => void;
  setEditorContent: React.Dispatch<React.SetStateAction<EditorState[]>>;
  duplicateParagraph: (index: number) => void;
}

const Timeline = ({
  selectedZone,
  paragraphs,
  activeScreen,
  leftSidebarOpen,
  editorContent,
  isButtonClicked,
  setActiveGlobalTab,
  setIsButtonClicked,
  setParagraphs,
  setEditorContent,
  duplicateParagraph,
}: Props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { scenes, addScene, handleChangeActiveScene, setScenes, activeSceneId } = useContext(VideoEditorContext);

  const { menuOpen, handleOpenMenu, handleCloseMenu, handleOpenTransitionTab } = useSlideActions({
    setActiveGlobalTab,
    handleChangeActiveScene,
    setNoScroll: setIsMenuOpened,
  });

  const scenesEndRef: any = useRef(null);

  const handleAddScene = (event: React.MouseEvent<HTMLElement>, scene?: Scene) => {
    event.stopPropagation();
    if (!scene?.background) {
      sentryErrors({
        errorType: SentryErrors.BACKGROUND_OF_THE_SCENE_WAS_NOT_FOUND,
        details: {
          scene: JSON.stringify(scene || {}),
        },
      });
    }

    const backgroundImage = scene?.background;
    const backgroundId = scene?.backgroundId || 1;
    const backgroundAssetId = scene?.backgroundAssetId || 1;

    addScene(backgroundImage, backgroundId, backgroundAssetId);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const nextScenes = reorderSlides(scenes, result.source.index, result.destination.index) as Scene[];
    const nextParagraphs = reorderSlides(
      paragraphs,
      result.source.index,
      result.destination.index,
      true,
    ) as HumansParagraph[];

    const nextEditorContent = [...editorContent];
    const [removed] = nextEditorContent.splice(result.source.index, 1);
    nextEditorContent.splice(result.destination.index, 0, removed);

    setScenes(nextScenes);
    setEditorContent(nextEditorContent);
    setParagraphs(nextParagraphs);
  };

  useEffect(() => {
    if (scenes.length) {
      // handleChangeActiveScene(scenes[scenes.length - 1].id);
      scenesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scenes.length]);

  useEffect(() => {
    if (scenes.length && menuOpen === scenes[scenes.length - 1].id) {
      // handleChangeActiveScene(scenes[scenes.length - 1].id);
      scenesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuOpen]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Wrapper leftSidebarOpen={leftSidebarOpen} menuOpen={isMenuOpened}>
        <Droppable droppableId="scenes-container">
          {(provided) => (
            <>
              <ImagesWrapper
                {...provided.droppableProps}
                ref={provided.innerRef}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCloseMenu();
                }}
                noScroll={isMenuOpened}
              >
                {scenes.map((scene: any, index: number) => (
                  <Draggable key={`scene-${scene.id}`} draggableId={`${scene.id}`} index={index}>
                    {(provided) => (
                      <>
                        <ScenesWrapper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                        >
                          <ScenePreview
                            scene={scene}
                            index={index}
                            handleAddScene={(e: React.MouseEvent<HTMLElement>) => handleAddScene(e, scene)}
                            isButtonClicked={isButtonClicked}
                            setIsButtonClicked={setIsButtonClicked}
                            leftSidebarOpen={leftSidebarOpen}
                            activeScreen={activeScreen}
                            duplicateParagraph={duplicateParagraph}
                            menuOpen={menuOpen}
                            handleOpenMenu={handleOpenMenu}
                            handleCloseMenu={handleCloseMenu}
                            handleOpenTransitionTab={handleOpenTransitionTab}
                            {...scene}
                          />
                        </ScenesWrapper>
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <div ref={scenesEndRef} />
                {!scenes.length && <IconButton onClick={handleAddScene} icon={<PlusIcon />} />}
              </ImagesWrapper>
            </>
          )}
        </Droppable>
        <AIHumansPreviewPlayer
          scenes={scenes}
          selectedZone={selectedZone}
          paragraphs={paragraphs}
          setIsButtonClicked={setIsButtonClicked}
          handleChangeActiveScene={handleChangeActiveScene}
        />
      </Wrapper>
    </DragDropContext>
  );
};

interface ScenePreviewProps {
  activeScreen: ScreenProps;
  scene: Scene;
  index: number;
  handleAddScene: (e: any) => void;
  leftSidebarOpen?: boolean;
  isButtonClicked: boolean;
  setIsButtonClicked: (b: boolean) => void;
  duplicateParagraph: (index: number) => void;
  menuOpen?: number;
  handleOpenMenu: (id: number) => void;
  handleCloseMenu: () => void;
  handleOpenTransitionTab: (event: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => void;
}

const ScenePreview = ({
  activeScreen,
  scene,
  index,
  handleAddScene,
  isButtonClicked,
  setIsButtonClicked,
  leftSidebarOpen,
  duplicateParagraph,
  menuOpen,
  handleOpenMenu,
  handleCloseMenu,
  handleOpenTransitionTab,
}: ScenePreviewProps) => {
  const dispatch = useDispatch();
  const { aiHumanMaxSlidesAllowed } = useSelector(getProfile);

  const audioPaths = useSelector(getHumansProjectAudio);

  const { scenes, currentScene, activeSceneId, addScene, dublicateScene, handleDeleteScene, handleChangeActiveScene } =
    useContext(VideoEditorContext);

  const { audioRef, nextScene } = useVideoPlayer({
    audioPaths,
    activeSceneId,
    isButtonClicked,
    setIsButtonClicked,
    handleChangeActiveScene,
  });

  const handleDeleteSceneChange = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const newScenes = scenes.filter((s: any) => s.id !== scene.id);

    if (newScenes.length && newScenes.length > 0) {
      const newActiveSceneIndex = newScenes[newScenes.length - 1].id;
      handleDeleteScene(scene.id);
      handleChangeActiveScene(newActiveSceneIndex);
    } else toast.error("The single scene can't be deleted.");
  };

  const slidesLimitPopupOpen = () => {
    const slidesAllowed = aiHumanMaxSlidesAllowed || 0;
    const slidesLeft = scenes.length || 0;
    const slidesUsed = slidesAllowed - slidesLeft;
    const progress = (100 * (slidesAllowed - slidesUsed)) / slidesAllowed;

    const prefilled: NotEnoughCredits = {
      title: `Your Limit Slides per project is ${aiHumanMaxSlidesAllowed}`,
      progressBar: { title: "Slides per project used", timeLeft: `${scenes.length}/${slidesAllowed}`, progress },
      description: "Don't worry, you can easily upgrade your plan to get more max slides.",
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const avatar = (scene.objects.find((object) => object.type === ObjectTypes.avatars)?.object as Avatar)?.src;

  return (
    <ScenesItem
      activeScreen={activeScreen}
      isActive={activeSceneId === scene.id}
      src={scene.slideBackgroundColor ? scene.slideBackgroundColor : scene.background}
      onClick={() => handleChangeActiveScene(scene.id)}
      leftSidebarOpen={leftSidebarOpen}
    >
      {scene.audioPath && <audio ref={audioRef} onEnded={nextScene} preload="auto" />}
      {scene.backgroundAssetId === 2 && !scene.slideBackgroundColor && (
        <VideoBackgroundWrapper>
          <VideoBackground src={scene.background} />
        </VideoBackgroundWrapper>
      )}
      {avatar && (
        <AvatarImageWrapper>
          <AvatarImage src={avatar} alt="Avatar" />
        </AvatarImageWrapper>
      )}
      <SceneActionMenu
        sceneId={scene.id}
        menuOpen={menuOpen}
        activeScreen={activeScreen}
        handleCloseMenu={handleCloseMenu}
        handleOpenMenu={handleOpenMenu}
        handleAddScene={scenes.length >= aiHumanMaxSlidesAllowed ? slidesLimitPopupOpen : handleAddScene}
        handleDuplicateScene={scenes.length >= aiHumanMaxSlidesAllowed ? slidesLimitPopupOpen : dublicateScene}
        handleDeleteScene={handleDeleteSceneChange}
        duplicateParagraph={duplicateParagraph}
        scenes={scenes}
      />
      <SceneButtonWrapper>
        {index === scenes.length - 1 ? (
          <IconButton
            onClick={index + 1 >= aiHumanMaxSlidesAllowed ? slidesLimitPopupOpen : handleAddScene}
            icon={<PlusIcon />}
          />
        ) : (
          <IconButtonWrapper hasTransition={!!scene.transitionEffectId}>
            <IconButton
              onClick={(e: any) => handleOpenTransitionTab(e, scene.id)}
              iconButtonTheme={IconButtonThemes.Secondary}
              icon={<AnimatedTransitionIcon />}
            />
          </IconButtonWrapper>
        )}
      </SceneButtonWrapper>
    </ScenesItem>
  );
};

const Wrapper = styled.div<{ leftSidebarOpen?: boolean; menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 50px;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  min-width: 152px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: ${({ theme }) => theme.cardShadow};
    pointer-events: none;
  }

  & > div:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${({ menuOpen }) =>
      menuOpen &&
      `
      z-index: 25;
      cursor: pointer;
  `}
  }

  ${({ leftSidebarOpen }) =>
    !leftSidebarOpen &&
    `
      @media (min-height: 800px) {
        min-width: 252px;
      }
  `}
`;

const ScenesWrapper = styled.div``;

const ImagesWrapper = styled.div<{ noScroll?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow: hidden auto;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0;
  }
`;

const ScenesItem = styled.div<{
  src: string | BackgroundColor;
  isActive: boolean;
  leftSidebarOpen?: boolean;
  activeScreen: ScreenProps;
}>`
  width: 120px;
  height: 70px;
  border-radius: 16px;
  flex-shrink: 0;
  background-size: cover;
  position: relative;
  cursor: pointer;
  border: 2px solid ${({ isActive }) => (isActive ? "rgb(0, 154, 247)" : "transparent")};

  ${({ src }) =>
    /^https?:\/\/\S+$/.test(src)
      ? ` 
      background-image: url(${src});
      background-color: none;
      background-position: center;
    `
      : `
      background-image: url(none);
      background-color: ${src};
    `}

  ${({ leftSidebarOpen, activeScreen }) =>
    !leftSidebarOpen &&
    activeScreen === ScreenProps.DESKTOP &&
    `
      @media (min-height: 800px) {
        width: 220px;
        height: 110px;
      }
    `}

    ${({ activeScreen }) =>
    activeScreen === ScreenProps.MOBILE &&
    `
        width: 66px;
        height: 110px;
        border-radius: 8px;
      `}
`;

const AvatarImageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 16px;
`;

const AvatarImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 60px;
  height: 60px;
  object-fit: cover;
`;

const VideoBackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  /* position: absolute;
  left: 0;
  top: 0; */
  width: 100%;
  height: 100%;
`;

const SceneButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 10;

  & > button {
    width: 24px;
    height: 24px;
  }
`;

const IconButtonWrapper = styled.div<{ hasTransition?: boolean }>`
  & > button {
    width: 24px;
    height: 24px;
  }

  ${({ hasTransition, theme }) =>
    hasTransition &&
    `
      & > button {
        background: ${theme.button};
        border: 2px solid ${theme.white};

        svg > path {
          fill: ${theme.white};
        }
      }
  `}
`;

export default Timeline;
