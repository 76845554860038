import styled from "styled-components";

const ChooseActorTab = ({ avatar }: any) => {
  return <ChooseActorImg src={avatar} />;
};

const ChooseActorImg = styled("img")`
  opacity: 0.4;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  object-fit: cover;
  border: ${({ theme }) => theme.videoTranslationBorder};
`;

export default ChooseActorTab;
