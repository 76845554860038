import styled from "styled-components";
import IconComponent from "./IconComponent";

export enum HomeNavbarTabs {
  FeaturedTools = "FeaturedTools",
  AIVoices = "AIVoices",
  AIHumans = "AIHumans",
  SynthesysLab = "SynthesysLab",
  AIWorkflows = "AIWorkflows",
}

export interface HomeNavbarTabData {
  value: HomeNavbarTabs;
  title: string;
  icon: React.ReactElement;
}

interface Props {
  data: HomeNavbarTabData[];
  active: HomeNavbarTabs;
  handleActive: (status: HomeNavbarTabs) => void;
}

const Tabs = ({ data, active, handleActive }: Props) => {
  return (
    <Wrapper>
      {data.map(({ value, title, icon }) => (
        <Content key={title} className={value === active ? "active" : "not-active"} onClick={() => handleActive(value)}>
          <IconComponent icon={icon} title={title} />
          <Border className="border" />
        </Content>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0;
  max-width: 100%;
  justify-content: center;
  border-bottom: 1px solid #ffffff4d;
  gap: 16px;

  .active {
    div {
      font-weight: 600;
    }
    .border {
      background: #ffffff;
    }
  }

  @media (max-width: 700px) {
    overflow-x: scroll;
    justify-content: start;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 167px;
`;

const Border = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 8px 8px 0px 0px;
  margin-top: auto;
`;

export default Tabs;
