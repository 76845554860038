import { put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import { GET_ACTIVE_PLAN_SERVER, SET_NEW_PLAN_SERVER, setActivePlan, updateSumoLoading } from "../actions/sumoActions";
import { SumoModules } from "../../types/sumo";

const sumoSagas = [
  takeEvery(`${SET_NEW_PLAN_SERVER}`, handleSetNewPlan),
  takeEvery(`${SET_NEW_PLAN_SERVER}_FAIL`, handleSetNewPlanFail),
  takeEvery(`${SET_NEW_PLAN_SERVER}_SUCCESS`, handleSetNewPlanSuccess),

  takeEvery(`${GET_ACTIVE_PLAN_SERVER}`, handleGetActivePlan),
  takeEvery(`${GET_ACTIVE_PLAN_SERVER}_FAIL`, handleGetActivePlanFail),
  takeEvery(`${GET_ACTIVE_PLAN_SERVER}_SUCCESS`, handleGetActivePlanSuccess),
];

function* handleSetNewPlan() {
  yield put(updateSumoLoading({ module: SumoModules.planLink, isLoading: true }));
}

function* handleSetNewPlanFail() {
  yield toast.error("Error while getting link");
  yield put(updateSumoLoading({ module: SumoModules.planLink, isLoading: false }));
}

function* handleSetNewPlanSuccess(action: any) {
  console.log("action", action);
  const link = action.payload.data.data;
  yield put(updateSumoLoading({ module: SumoModules.planLink, isLoading: false }));

  window.open(link, "_blank");
}

function* handleGetActivePlan() {
  yield put(updateSumoLoading({ module: SumoModules.activePlan, isLoading: true }));
}

function* handleGetActivePlanFail() {
  yield toast.error("Error while getting active plan");
  yield put(updateSumoLoading({ module: SumoModules.activePlan, isLoading: false }));
}

function* handleGetActivePlanSuccess(action: any) {
  console.log("action", action);
  yield put(updateSumoLoading({ module: SumoModules.activePlan, isLoading: false }));
  yield put(setActivePlan(action.payload.data.data));
}

export default sumoSagas;
