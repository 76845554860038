import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import {
  ObjectTypes,
  SceneObject as SceneObjectType,
  Text,
  TextAlign,
  Avatar as AvatarType,
  ShapeTypes,
  Shape,
  Media,
} from "../../../types/scene";

import { Arrow, Circle, Heart, Polygon, Rectangle, Square, Star, Triangle } from "../../../components/Shapes";
import { VideoEditorContext } from "../../../App";
import { Line } from "../../../components/Shapes/Line";
import CustomSvgShape from "../../../components/Shapes/CustomSvgShape";
import { removePx } from "../../../lib/stylesHelper";

interface Props {
  object: SceneObjectType;
}

const SceneObject = ({ object: { object: obj, type } }: Props) => {
  const { currentScene, handleChangeActiveObject, handleInputChange, setEditableTextId, activeSceneId } =
    useContext(VideoEditorContext);
  const { activeObjectId, editableTextId } = currentScene;

  const [fontSize, setFontSize] = useState(
    type === ObjectTypes.texts ? +removePx(String((obj as Text).displayedFontSize)) : 0,
  );
  const [lineHeight, setLineHeight] = useState(
    type === ObjectTypes.texts ? +removePx(String((obj as Text).displayedFontSize)) * 1.37 : 0,
  );

  useEffect(() => {
    const displayedFontSize = +removePx(String((obj as Text).displayedFontSize));
    setFontSize(displayedFontSize);
    setLineHeight(displayedFontSize * 1.37);
  }, [(obj as Text).displayedFontSize, activeSceneId]);

  const handlChangeActive = (event: React.MouseEvent<HTMLElement>, id: number) => {
    event.stopPropagation();
    handleChangeActiveObject(id);
  };

  const shouldBlur = (e: KeyboardEvent) => {
    if (e.code !== "Escape") return;
    (e.target as HTMLElement).blur();
  };

  const getObject = (type: ObjectTypes) => {
    switch (type) {
      case ObjectTypes.texts: {
        const currentObject = obj as Text;
        return activeObjectId !== obj.id ? (
          <TextObject
            onClick={(event) => handlChangeActive(event, obj.id)}
            className="draggable-text text-object"
            align={currentObject.style.textAlign as TextAlign}
            style={{ ...currentObject.style, fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` }}
            // id={"text" + obj.id}
          >
            {currentObject.text}
          </TextObject>
        ) : (
          <TextObjectArea
            onMouseUp={() => setEditableTextId(obj.id)}
            onKeyDown={shouldBlur as any}
            disabled={activeObjectId !== obj.id}
            isEditable={editableTextId === obj.id}
            value={currentObject.text}
            style={{ ...currentObject.style, fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` }}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleInputChange(event.target.value, obj.id)}
            // id={"text" + obj.id}
            className="draggable-text"
          />
        );
      }
      case ObjectTypes.avatars: {
        const currentObject = obj as AvatarType;
        return (
          <Avatar
            src={currentObject.src}
            type={currentObject.actorPositionType}
            background={currentObject.background}
          />
        );
      }
      case ObjectTypes.shapes: {
        const currentObject = obj as Shape;
        return shapesComponents({ ...currentObject })[currentObject.shape];
      }
      case ObjectTypes.media: {
        const currentObject = obj as Media;
        return <MediaObject src={currentObject.src} id={`media${obj.id}`} alt="Media object" draggable="false" />;
      }
    }
  };

  return getObject(type);
};

export const shapesComponents = ({ background, opacity, border, shapeImageUrl, svgData }: Shape) => {
  const defaultShapesProps = {
    background,
    opacity,
    border,
  };

  return {
    [ShapeTypes.circle]: <Circle {...defaultShapesProps} />,
    [ShapeTypes.rectangle]: <Rectangle {...defaultShapesProps} />,
    [ShapeTypes.triangle]: <Triangle {...defaultShapesProps} />,
    [ShapeTypes.polygon]: <Polygon {...defaultShapesProps} />,
    [ShapeTypes.star]: <Star {...defaultShapesProps} />,
    [ShapeTypes.square]: <Square {...defaultShapesProps} />,
    [ShapeTypes.heart]: <Heart {...defaultShapesProps} />,
    [ShapeTypes.arrow]: <Arrow {...defaultShapesProps} />,
    [ShapeTypes.line]: <Line {...defaultShapesProps} />,
    [ShapeTypes.custom]: /\.(mp4|mov|avi|mkv)$/i.test(shapeImageUrl) ? (
      <CustomShapeVideoObject
        className={"shape0"}
        src={shapeImageUrl}
        draggable="false"
        opacity={opacity}
        autoPlay
        loop
      />
    ) : (
      <CustomShapeImageObject className={"shape0"} src={shapeImageUrl} draggable="false" opacity={opacity} />
    ),
    [ShapeTypes.customSvg]: <CustomSvgShape svgData={svgData || ""} background={background} opacity={opacity} />,
  };
};

const TextObjectArea = styled.textarea<{ disabled?: boolean; isEditable: boolean }>`
  max-height: 100%;
  max-width: 100%;
  background: transparent;
  border: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: ${({ isEditable }) => (isEditable ? "text" : "move")};
  user-select: none;
`;

const TextObject = styled.pre<{ align?: TextAlign }>`
  display: inline-block;
  font-family: monospace;
  position: absolute;
  ${({ align }) => getTextAlign[align || "left"]}
  min-width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  pointer-events: all;
  user-select: none;
`;

const getTextAlign = {
  left: `left: 0;`,
  right: `right: 0;`,
  center: `left: 50%; transform: translateX(-50%);`,
};

const Avatar = styled.div<{ src: string; type: string; background: string }>`
  display: flex;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 50%;

  ${({ type, background }) =>
    type === "Circle" &&
    `
      background-color: ${background};
    `}

  ${({ type }) =>
    type === "FullBody" &&
    `
      border-radius: 0;
    `}
`;

const MediaObject = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CustomShapeImageObject = styled.img<{ opacity: number }>`
  width: 100%;
  height: 100%;
  opacity: ${({ opacity }) => opacity};
`;

const CustomShapeVideoObject = styled.video<{ opacity: number }>`
  width: 100%;
  height: 100%;
  opacity: ${({ opacity }) => opacity};
`;

export default SceneObject;
