import React from "react";
import styled from "styled-components";
import SmartLink from "../../SmartLink/SmartLink";

interface IFooterLinksProps {
  title: string;
  links: {
    id: number;
    label: string;
    href: string;
  }[];
  newOrder?: number;
}

const FooterLinks = ({ title, links, newOrder }: IFooterLinksProps) => {
  return (
    <Wrapper newOrder={newOrder} className="footerLinks">
      <Title>{title}</Title>
      <Links>
        {links.map(({ id, label, href }) => (
          <SmartLink key={id} url={href}>
            <>
              <span className="greyText">{label}</span>
              <span className="hoverText">{label}</span>
            </>
          </SmartLink>
        ))}
      </Links>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ minWidth?: string; newOrder?: number }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: max-content;

  @media (max-width: 650px) {
    gap: 8px;
    min-width: auto;
    order: ${({ newOrder }) => (newOrder ? newOrder : "unset")};
  }
`;

const Title = styled.h3`
  color: #191b1f;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  a {
    position: relative;
    width: 100%;
    display: block;

    span {
      display: block;
      font-size: 12px;
      line-height: 16px;
    }

    .greyText {
      position: absolute;
      top: 0;
      left: 0;

      color: #191b1f;
      opacity: 0.6;
      transition: font-weight 0.1s, color 0.2s 0.1s;
      font-weight: 400;
    }

    .hoverText {
      background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      opacity: 0;

      transition: opacity 0.2s 0.1s;
      font-weight: 500;
    }

    :hover {
      .greyText {
        font-weight: 500;
        color: transparent;
      }

      .hoverText {
        opacity: 1;
      }
    }
  }

  @media (max-width: 599px) {
    gap: 14px;
  }
`;

export default FooterLinks;
