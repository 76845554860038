import React from "react";
import styled from "styled-components";
import useAudioPlayer from "../../hooks/useAudioPlayer";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { PauseIcon, PlayIcon, TrashIcon } from "../Icons/Icons";
import { formatNumberToDuration } from "../../lib/formatDuration";
import Button, { ButtonThemes } from "../Button/Button";
import BinIcon from "../Icons/BinIcon";

interface Props {
  title: string;
  active?: boolean;
  deleteActive?: boolean;
  selectedSoundtrack?: boolean;
  soundtrack: string;
  onClick: () => void;
  handleSelect?: () => void;
  variant?: "aiHumans" | "addVoiceAudioPopup" | "editor";
  handleDelete?: (e: any) => void;
}

const Soundtrack = ({
  title,
  active,
  deleteActive,
  selectedSoundtrack,
  soundtrack,
  variant = "aiHumans",
  onClick,
  handleSelect,
  handleDelete,
}: Props) => {
  const { audioPlayer, currentTime, duration, playing, onPlaying, setPlaying, onLoadedMetadata } = useAudioPlayer();

  const handleClick = () => {
    onClick();
    if (playing) {
      if (!active) setPlaying(false);
      else setPlaying(false);
    } else {
      if (!active) setPlaying(true);
      else setPlaying(true);
    }
  };

  return (
    <Wrapper>
      {active && (
        <audio src={soundtrack} ref={audioPlayer} onTimeUpdate={onPlaying} onLoadedMetadata={onLoadedMetadata}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )}
      <IconButtonWrapper>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={!playing || currentTime === duration || !active ? <PlayIcon /> : <PauseIcon />}
          onClick={handleClick}
        />
      </IconButtonWrapper>
      <TitleWrapper>
        <span>{title}</span>
        <span>{formatNumberToDuration(duration)}</span>
      </TitleWrapper>
      <ButtonWrapper variant={variant}>
        {variant === "aiHumans" ? (
          <Row selectedSoundtrack={selectedSoundtrack} variant="aiHumans">
            <Button
              buttonTheme={ButtonThemes.Outline}
              onClick={handleSelect}
              text={selectedSoundtrack ? "Selected" : "Select"}
            />
            <IconButton
              className={`aiHumans-soundtrack-delete ${deleteActive ? "" : "hide"}`}
              icon={<BinIcon />}
              iconButtonTheme={IconButtonThemes.Secondary}
              onClick={handleDelete}
            />
          </Row>
        ) : variant === "editor" ? (
          <Row selectedSoundtrack={selectedSoundtrack} variant="editor">
            <Button
              buttonTheme={ButtonThemes.Outline}
              onClick={handleSelect}
              text={selectedSoundtrack ? "Selected" : "Select"}
            />
            {deleteActive ? (
              <IconButton
                className="soundtrack-delete"
                icon={<TrashIcon />}
                iconButtonTheme={IconButtonThemes.Transparent}
                onClick={handleDelete}
              />
            ) : (
              <div />
            )}
          </Row>
        ) : (
          <Button text="Delete" icon={<TrashIcon />} buttonTheme={ButtonThemes.Transparent} onClick={handleDelete} />
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  &:hover .aiHumans-soundtrack-delete {
    opacity: 1;
    transition: 0.3s;
    right: 90px;
  }

  .hide {
    display: none;
  }
`;

const IconButtonWrapper = styled.div`
  button {
    width: 32px;
    height: 32px;
    svg {
      transform: translateX(1px);
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 9px;
  span:first-of-type {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};
  }
  span:last-of-type {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.4;
  }
`;

const ButtonWrapper = styled.div<{ variant?: string }>`
  margin-left: auto;
  position: relative;

  button {
    width: 88px;
    height: 36px;
    box-shadow: none;
    span {
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.41px;
      background: ${({ theme }) => theme.button};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  ${({ variant }) =>
    variant === "addVoiceAudioPopup" &&
    `
    button {
        gap: 8px;

        span {
          font-weight: 600;
          background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }

        svg {
          display: flex;

          & > path {
            fill: url("#paint0_linear_2305_10104");
          }
        }
      }
  `}
`;

const Row = styled.div<{ selectedSoundtrack?: boolean; variant?: "aiHumans" | "editor" }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;

  ${({ variant }) =>
    variant === "aiHumans" &&
    `
      button {
        z-index: 2;
      }

      button:last-of-type {
        position: absolute;
        top: 6px;
        right: 6px;
        width: 24px !important;
        height: 24px !important;
        box-shadow: none;
        opacity: 0;
        z-index: 1;

        svg {
          width: 12px;
          height: 12px;

          path {
            fill: red;
          }
        }
      }
    `}

  ${({ variant, theme }) =>
    variant === "editor" &&
    `
      button:first-of-type {
        background: ${theme.editor};
      }

      div {
        width: 20px;
        height: 20px;
      }
    `}

  ${({ selectedSoundtrack, theme }) =>
    selectedSoundtrack &&
    `
      button:first-of-type {
        border: none !important;
        background: ${theme.button};
        span {
          color: ${theme.white};
          background: none;
          -webkit-text-fill-color: ${theme.white};
        }
      }
  `}
`;

export default Soundtrack;
