import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import * as Sentry from "@sentry/react";
import {
  CREATE_HUMANS_PROJECT_SERVER,
  GET_HUMANS_PROJECT_SERVER,
  Status,
  UPDATE_HUMANS_PROJECT_SERVER,
  updateHumansProjectLoading,
} from "../actions/humansProjectActions";
import { AiHumansProjectModules, Slide } from "../../types/humansProject";
import { Zone } from "../../types/project";
import { getAllZones } from "../../lib/editorUtils";
import { setCachedZones } from "../actions/projectAction";
import Navigate from "../../lib/Navigate";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { Popups, updatePopup } from "../actions/popupsActions";
import { NotEnoughCredits } from "../../types/notEnoughCredits";

const humansProjectSaga = [
  // takeEvery(CREATE_HUMANS_PROJECT_SERVER, handleCreateHumansProjectServer),
  takeEvery(`${CREATE_HUMANS_PROJECT_SERVER}_FAIL`, handleCreateHumansProjectServerFail),
  takeEvery(`${CREATE_HUMANS_PROJECT_SERVER}_SUCCESS`, handleCreateHumansProjectServerSuccess),

  takeEvery(`${GET_HUMANS_PROJECT_SERVER}`, handleGetProject),
  takeEvery(`${GET_HUMANS_PROJECT_SERVER}_FAIL`, handleGetProjectFail),
  takeEvery(`${GET_HUMANS_PROJECT_SERVER}_SUCCESS`, handleGetProjectSuccess),

  // takeEvery(UPDATE_HUMANS_PROJECT_SERVER, handleUpdateHumansProject),
  takeEvery(`${UPDATE_HUMANS_PROJECT_SERVER}_FAIL`, handleUpdateHumansProjectFail),
  takeEvery(`${UPDATE_HUMANS_PROJECT_SERVER}_SUCCESS`, handleUpdateHumansProjectSuccess),
];

// function* handleCreateHumansProjectServer() {
//   yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: true }));
// }

function* handleCreateHumansProjectServerFail(action: any) {
  const project = action?.meta?.previousAction?.payload?.request?.data;

  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_CREATE_PROJECT.toast);
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_CREATE_PROJECT,
    details: {
      responseFromApi: action?.error?.response?.data || null,
      project: JSON.stringify(project || []),
    },
  });
}

function* handleCreateHumansProjectServerSuccess() {
  yield toast.success("Project created");

  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));
}

function* handleGetProject() {
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.loadingPage, isLoading: true }));
}

function* handleGetProjectFail() {
  yield toast.error("Error while getting human project");
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.loadingPage, isLoading: false }));
}

const getAllZonesFromSlides = (slides: Slide[]) => {
  return slides.reduce((prev: Zone[], current: Slide) => {
    return [...prev, ...getAllZones(current.projectParagraphs)];
  }, []);
};

function* handleGetProjectSuccess(action: any) {
  // const zones = getAllZonesFromSlides(action?.payload?.data?.data?.slides || []).filter((zone: Zone) => zone.audioPath);
  // we should add manual cache
  // yield put(setCachedZones({ zones }));
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.loadingPage, isLoading: false }));
}

// function* handleUpdateHumansProject(action: any) {
//   const { status } = action.payload.request.data;

//   if (status === Status.isDraft) {
//     yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: true }));
//   }

//   if (status === Status.isProgress) {
//     yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.generateVideo, isLoading: true }));
//   }
// }

function* handleUpdateHumansProjectFail(action: any) {
  if (action?.error?.response?.data?.message === "There are not enough credits available.") {
    const prefilled: NotEnoughCredits = {
      title: "There are not enough credits available.",
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
    };

    yield put(updatePopup({ popup: Popups.generativeVideo, status: false, prefilled: {} }));

    yield put(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  } else {
    yield toast.error("Error while update human project");
    yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));
    yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.generateVideo, isLoading: false }));
  }
}

function* handleUpdateHumansProjectSuccess(action: any) {
  const { status } = action.payload.data.data;

  if (status === Status.isDraft) yield toast.success("Project updated");
  if (status === Status.isProgress) {
    yield toast.success("The generation has begun");
    yield Navigate.push("/my-projects?filter=ai-humans");
    yield put(updatePopup({ popup: Popups.generativeVideo, status: false, prefilled: {} }));
  }
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));
  yield put(updateHumansProjectLoading({ module: AiHumansProjectModules.generateVideo, isLoading: false }));
}

export default humansProjectSaga;
