import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { PricingContext } from "./PricingProvider";
import { IPriceItemProps, subscriptions } from "../../../mocks/pricing";
import ButtonTabs from "./ButtonTabs";
import Slider from "../../../components/Slider/Slider";
import Toggle from "./Toggle";
import Button from "../../../components/Button/Button";
import { CheckIcon } from "../../../components/Icons/CheckIcon";
import Tooltip from "./Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingPlans, getUserPlans } from "../../../redux/reducers/plansReducer";
import { getPlanGroup } from "../DefaultUserPricing";
import { PriceSection } from "../../../types/pricing";
import { updateSubscriptionServer } from "../../../redux/actions/plansActions";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { numberDecorator } from "../../../lib/numberDecorator";
import { InfoGreyIcon } from "../../../components/Icons/Icons";

const PriceHumans: FC<IPriceItemProps> = ({
  tabs,
  maxSliderValue,
  prices,
  handleToggleClick,
  isToggle,
  activeSection,
}) => {
  const [sliderValue, setSliderValue] = useState(1);
  const [activeTab, setActiveTab] = useState(2);
  const { updateObject } = useContext(PricingContext)!;

  const dispatch = useDispatch();

  const currentSliderValue = prices.find((mock) => mock.id === sliderValue);

  const handleSliderChange = (value: number) => {
    setSliderValue(value);
  };

  const handleChangeTab = (value: number) => {
    setActiveTab(value);
  };

  const getCurrentPrice = () => {
    const pricingType = activeTab === 1 ? "monthlyPrice" : "yearlyPrice";
    const userCount = isToggle ? "fiveUsers" : "oneUser";
    return currentSliderValue?.[pricingType]?.[userCount];
  };

  const currentValue = getCurrentPrice();

  useEffect(() => {
    const pricingType = activeTab === 1 ? "monthlyPrice" : "yearlyPrice";
    const userCount = isToggle ? "fiveUsers" : "oneUser";

    const value = prices
      .map((elem) => elem?.[pricingType]?.[userCount]?.url)
      .filter((elem): elem is string => typeof elem === "string");

    updateObject(value);
  }, [isToggle, activeTab]);

  const currentPlanLink = subscriptions.find((plan) => plan.planID === currentValue?.id)?.checkoutLink;

  const userPlans = useSelector(getUserPlans);
  const loadingPlans = useSelector(getLoadingPlans);

  const activeUserPlans = userPlans.filter((plan) => !plan.isCancelled);

  const activeGroups = userPlans
    .filter((plan) => !plan.isCancelled)
    .filter(getPlanGroup)
    .map(getPlanGroup) as PriceSection[];

  const handleChangeSubscription = () => {
    if (!activeUserPlans.length || !currentValue) return;
    const existingUserLicenseId = activeUserPlans.find((plan) => getPlanGroup(plan) === activeSection)?.userLicenseID;
    if (!existingUserLicenseId) return;
    // dispatch(updateSubscriptionServer({ existingUserLicenseId, newPlanId: currentValue?.id }));
    dispatch(
      updatePopup({
        popup: Popups.changeSubscriptionPopup,
        status: true,
        prefilled: { existingUserLicenseId, newPlanId: currentValue?.id },
      }),
    );
  };

  useEffect(() => {
    if (!currentValue?.href) return;

    const link = document.getElementById(currentValue.href);

    if (!link) return;

    const hrefArray = link.getAttribute("href")?.split("#pkmodal");

    const hash = hrefArray?.length && hrefArray.length > 1 ? hrefArray[1].replace("#pkmodal", "") : undefined;

    link.setAttribute("data-pk-is-inited", "1");
    link.setAttribute("href", "javascript:void(0)");
    if (hash) {
      link.setAttribute(
        "onclick",
        `
        const func = async () => {
          return await new Promise((res, rej) => {
            let counter = 0;
            const interval = setInterval(() => {
              if (counter > 100) {
                clearInterval(interval);
                rej(new Error("Error!!! Restart the page!"));
              }
              const widget = document.getElementById("pk-widget-${hash}");
              if (!widget) {
                counter++;
                return;
              }
              clearInterval(interval);
              res(window.PKWIDGET.openModal(this));
            }, 100);
          });
        };
        func();
        try {
          window.PKWIDGET.openModal(this);
        } catch(error) {
          console.log(error);
        }
`,
      );
    } else {
      link.setAttribute("onclick", "return window.PKWIDGET.openModal(this);");
    }
  }, [currentValue]);

  return (
    <Wrapper>
      <ButtonTabs tabs={tabs} activeTab={activeTab} onChange={handleChangeTab} />
      {activeTab === 1 ? (
        <SliderWrapper>
          <Price>
            ${numberDecorator(currentValue?.price || 0)}
            <PriceDuration>/mo</PriceDuration>
          </Price>
          <Slider
            labels={prices.map((mock) => mock.label)}
            value={sliderValue}
            min={1}
            max={maxSliderValue}
            onChange={handleSliderChange}
          />
        </SliderWrapper>
      ) : (
        currentValue?.price && (
          <SliderWrapper>
            <Price>
              ${numberDecorator(currentValue?.price / 12)}
              <PriceDuration>/mo (${numberDecorator(currentValue?.price)} billed yearly)</PriceDuration>
            </Price>
            <Slider
              labels={prices.map((mock) => mock.label)}
              value={sliderValue}
              min={1}
              max={maxSliderValue}
              onChange={handleSliderChange}
            />
          </SliderWrapper>
        )
      )}
      <ToggleWrapper>
        <Toggle isOn={isToggle} onToggle={handleToggleClick} />
        <ToggleText>UP to 5 users</ToggleText>
        <TooltipWrapper>
          <InfoGreyIcon />
          <Tooltip title="5 users will be allowed to access the same user account" />
        </TooltipWrapper>
      </ToggleWrapper>

      {userPlans.some((plan) => plan.productName === currentValue?.title && !plan.isCancelled) ? (
        <ActivePlan className="startFree">Active plan</ActivePlan>
      ) : activeGroups.includes(PriceSection.humans) ? (
        <Button
          text="Select plan"
          className="startFree"
          onClick={handleChangeSubscription}
          icon={currentValue && loadingPlans.includes(currentValue.id) && <CircularProgress />}
        />
      ) : (
        currentValue?.href && (
          <a
            id={currentValue?.href}
            href={currentValue?.href}
            data-pk-hash={currentValue?.href.slice(8, currentValue?.href.length + 1)}
          >
            <Button className="startFree">Get Started</Button>
          </a>
        )
      )}

      <ProsWrapper>
        {currentSliderValue?.pros.map(({ text, tooltip }) => (
          <Pros key={text}>
            <CheckIcon />
            <ProsText>{text}</ProsText>
            {tooltip && <InfoGreyIcon />}
            {tooltip && <Tooltip title={tooltip} />}
          </Pros>
        ))}
      </ProsWrapper>
    </Wrapper>
  );
};

const ActivePlan = styled.div`
  padding: 14px 32px;
  border-radius: 12px;
  background: ${({ theme }) => theme.primaryBackground};
  border: 1px solid #0063b4 !important;
  text-align: center;

  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    font-family: Mulish;
    line-height: 20px; /* 125% */
    letter-spacing: -0.41px;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const ProsText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6f7074;
`;

const Pros = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;

  &:hover .tooltip {
    opacity: 1;
  }
`;

const ProsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ToggleText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #6f7074;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  height: 16px;

  & .tooltip {
    max-width: 200px;
  }

  &:hover .tooltip {
    opacity: 1;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const PriceDuration = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #6f7074;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const Price = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: end;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  color: ${({ theme }) => theme.primaryText};

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.mainCardShadowPricing};
  border-radius: 16px;
  padding: 40px;

  width: 100%;

  .startFree {
    height: 48px;
    margin: 24px 0;
    width: 100%;
  }

  button.startFree {
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
    box-shadow: ${({ theme }) => theme.buttonShadow};
    border-radius: 12px;
    border: none;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.secondaryText};
    cursor: pointer;
  }

  @media (max-width: 768px) {
    padding: 24px 16px;
  }
`;

export default PriceHumans;
