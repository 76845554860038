export interface UserPlan {
  expiryDateTime: string;
  isActive: boolean;
  isCancelled: boolean;
  period: UserPlanPeriod;
  price: number;
  productName: string;
  remarks: string;
  userID: number;
  voiceCredits: number;
  invoiceID: string;
  userLicenseID: number;
}

export enum UserPlanPeriod {
  yearly = "Yearly",
  monthly = "Monthly",
}

export interface ChangeSubscription {
  aiHumanAllowed: number;
  aiImagesAllowed: number;
  aiVoicesAllowed: number;
  aiVoicesApiAllowed: number;
  checkoutLink: string;
  description: string;
  duration: UserPlanPeriod;
  groupTitle: string;
  id: number;
  licenseCode: string;
  planDetails: string[];
  planID: number;
  price: number;
  title: string;
  voiceCloneCredits: number;
}
