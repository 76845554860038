import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";

import { getAssets } from "../redux/reducers/assetReducer";
import { Avatar } from "../types/scene";
import { AssetModules } from "../types/asset";
import { Popups, updatePopup } from "../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../types/confirmationDelete";
import { Asset, clearAsset, createUserAssetServer, getAssetServer } from "../redux/actions/assetAction";

const MAX_SIZE = 52428800;
const searchTimeoutDelay = 500;

interface Props {
  isOpen: boolean;
  currentObject: Avatar;
  setActiveFaceswap: (src: string) => void;
}

export const useFaceswap = ({ isOpen, currentObject, setActiveFaceswap }: Props) => {
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const assets = useSelector(getAssets);
  const faceswap = assets[Asset.Faceswap].data;
  const faceswapLoading = assets[Asset.Faceswap].isLoading;
  const faceswapHasMore = assets[Asset.Faceswap].hasMore;
  const faceswapCurrentPage = assets[Asset.Faceswap].pageNumber;
  const uploadFaceswapLoading = assets[Asset.Faceswap].uploadLoading;

  const [errorSize, setErrorSize] = useState<boolean>(false);

  const debounceHandleGetBackground = useRef(
    debounce(async () => {
      dispatch(
        getAssetServer({
          keyword: "",
          pageNumber: 1,
          assetTypeId: Asset.Faceswap,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const fetchMore = () => {
    dispatch(
      getAssetServer({
        keyword: "",
        pageNumber: faceswapCurrentPage + 1,
        assetTypeId: Asset.Faceswap,
      }),
    );
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) {
      // TODO: add sentry error handling
      return;
    }

    if (uploadedFile && uploadedFile.size > MAX_SIZE) {
      setErrorSize(true);
    } else {
      const formData = new FormData();
      formData.append("AssetTypeId", "6");
      formData.append("Status", "1");
      formData.append("FileName", uploadedFile.name);
      formData.append("File", uploadedFile);

      setErrorSize(false);
      dispatch(
        createUserAssetServer({
          data: formData,
          assetTypeId: Asset.Faceswap,
        }),
      );
    }
  };

  const handleUserAssetDelete = (e: any, id: number) => {
    if (!id) {
      throw Error(`The ID ${AssetModules[Asset.Faceswap]} is missing when deleting`);
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: `Are you sure you want to delete ${AssetModules[Asset.Faceswap]}?`,
      description: `The ${AssetModules[Asset.Faceswap]} will be removed and the action cannot be undone`,
      typeId: Asset.Faceswap,
      type: DeleteType.Asset,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    if (isFirstRender.current && !faceswap.length && isOpen) {
      dispatch(clearAsset({ assetTypeId: Asset.Faceswap }));
      debounceHandleGetBackground();
      isFirstRender.current = false;
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (!faceswap.length) setActiveFaceswap(currentObject.src);
      else setActiveFaceswap(currentObject.faceImage || currentObject.src);
    }
  }, [isOpen, faceswap]);

  return {
    errorSize,
    uploadFaceswapLoading,
    faceswap,
    faceswapLoading,
    faceswapHasMore,
    fetchMore,
    handleUpload,
    handleUserAssetDelete,
  };
};
