import React from "react";
import styled from "styled-components";

const EmptyIcon = () => <Wrapper />;

const Wrapper = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  background: transparent !important;
`;

export default EmptyIcon;
