export const TextIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6667 5.33301H5.33333C4.596 5.33301 4 5.92901 4 6.66634V10.6663C4 11.4037 4.596 11.9997 5.33333 11.9997C6.07067 11.9997 6.66667 11.4037 6.66667 10.6663V7.99967H14.6667V25.333H12C11.2627 25.333 10.6667 25.929 10.6667 26.6663C10.6667 27.4037 11.2627 27.9997 12 27.9997H20C20.7373 27.9997 21.3333 27.4037 21.3333 26.6663C21.3333 25.929 20.7373 25.333 20 25.333H17.3333V7.99967H25.3333V10.6663C25.3333 11.4037 25.9293 11.9997 26.6667 11.9997C27.404 11.9997 28 11.4037 28 10.6663V6.66634C28 5.92901 27.404 5.33301 26.6667 5.33301Z"
      fill="white"
    />
    <mask id="mask0_1154_10356" maskUnits="userSpaceOnUse" x="4" y="5" width="24" height="23">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6667 5.33301H5.33333C4.596 5.33301 4 5.92901 4 6.66634V10.6663C4 11.4037 4.596 11.9997 5.33333 11.9997C6.07067 11.9997 6.66667 11.4037 6.66667 10.6663V7.99967H14.6667V25.333H12C11.2627 25.333 10.6667 25.929 10.6667 26.6663C10.6667 27.4037 11.2627 27.9997 12 27.9997H20C20.7373 27.9997 21.3333 27.4037 21.3333 26.6663C21.3333 25.929 20.7373 25.333 20 25.333H17.3333V7.99967H25.3333V10.6663C25.3333 11.4037 25.9293 11.9997 26.6667 11.9997C27.404 11.9997 28 11.4037 28 10.6663V6.66634C28 5.92901 27.404 5.33301 26.6667 5.33301Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1154_10356)"></g>
  </svg>
);
