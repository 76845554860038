export const removePx = (str: string) => str.replace("px", "");

export const addPx = (str: string) => str + "px";

export const getTranslateValues = (translateString: string) => {
  const regex = /translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/;
  const res = translateString.match(regex);
  if (!res) return [0, 0];
  const [_, x, y] = res;
  return [parseFloat(x), parseFloat(y)];
};
