import styled from "styled-components";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../Modal/Modal";
import Switch from "../Switch/Switch";
import Button, { ButtonThemes } from "../Button/Button";
import DownloadTime from "../DownloadTime/DownloadTime";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import { formatMinutes } from "../Navigation/Navigation";
import { getProfile } from "../../redux/reducers/profileReducer";
import { useAIHumansRenderVideo } from "../../hooks/useAIHumans";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { HumansParagraph, Project } from "../../types/humansProject";
import { getGenerateVideoLoading } from "../../redux/reducers/humansProjectReducer";

interface Props {
  open: boolean;
  humansProject: Project | null;
  paragraphs: HumansParagraph[];
  onClose: () => void;
}

export interface ResolutionSize {
  width: string;
  height: string;
}

interface Resolution {
  id: string;
  label: string;
  value: string;
  size: ResolutionSize;
  button?: ReactNode;
}

const resolutionsData: Resolution[] = [
  {
    id: "1",
    label: "1280x720 (HD)",
    value: "1280x720",
    size: {
      width: "1280",
      height: "720",
    },
  },
  {
    id: "2",
    label: "1920x1080 (Super HD)",
    value: "1920x1080",
    size: {
      width: "1920",
      height: "1080",
    },
  },
];

export const resolutions = {
  label: "Resolution",
  id: "resolution",
  data: resolutionsData,
};

const GenerativeVideoPopup = ({ humansProject, paragraphs, open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const prefilled = useSelector(getPrefilled);
  const profile = useSelector(getProfile);
  const generateVideoLoading = useSelector(getGenerateVideoLoading);
  const [title, setTitle] = useState<string>("");
  const [isEnableCaption, setIsEnableCaption] = useState<boolean>(humansProject?.enableCaption || false);

  const apiAllowed = profile.aiHumanAllowed || 0;
  const apiLeft = profile.aiHumanAllowed - (profile.aiHumanUsed || 0);
  const apiUsed = apiAllowed - apiLeft;
  const progress = (100 * (apiAllowed - apiUsed)) / apiAllowed;
  const hasUnlimitedAIHuman = profile.hasUnlimitedAIHuman;

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);

  const { createVideo } = useAIHumansRenderVideo({
    getFullProject: prefilled.getFullProject,
    title,
    paragraphs,
    enableCaption: isEnableCaption,
    activeScreen: prefilled.activeScreen,
    backgroundSoundTrackId: prefilled.backgroundSoundTrackId,
    canvasWidth: prefilled.canvasWidth,
  });

  const onSubmit = (e: any) => {
    e.preventDefault();

    createVideo();
  };

  useEffect(() => {
    if (!isFirstRender.current && !generateVideoLoading) {
      onClose();
    }

    isFirstRender.current = false;
  }, [generateVideoLoading]);

  return (
    <Modal open={open} onClose={onClose} closeIcon={false}>
      <Wrapper onSubmit={onSubmit}>
        <Title>Generate video</Title>
        <CreditsRequired>
          Credits Required: <b>{(humansProject?.totalDuration || 0) / 10} credits</b>
        </CreditsRequired>
        <DownloadTimeWrapper>
          <DownloadTime
            title="Credits left"
            timeLeft={`${apiLeft} out of ${!hasUnlimitedAIHuman ? apiAllowed : "Unlimited"}`}
            progress={progress}
          />
        </DownloadTimeWrapper>
        <InputWrapper>
          <Textfield
            placeholder="Untitled"
            label="Title"
            value={title}
            onChange={handleTitleChange}
            required
            variant={TextfieldVariant.noneAdornment}
          />
        </InputWrapper>
        <SwitchWrapper>
          <span>Add captions/subtitles</span>
          <Switch checked={isEnableCaption} onChange={() => setIsEnableCaption(!isEnableCaption)} />
        </SwitchWrapper>
        <Actions>
          <Button text="Cancel" onClick={onClose} buttonTheme={ButtonThemes.Outline} />
          <Button text={generateVideoLoading ? "Loading..." : "Create"} type="submit" />
        </Actions>
      </Wrapper>
    </Modal>
  );
};

const CreditsRequired = styled.div`
  color: #6f7074;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;

  b {
    color: #191b1f;
  }
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 375px;

  @media (max-width: 430px) {
    min-width: 0;
    width: auto;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.primaryText};
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Actions = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const DownloadTimeWrapper = styled.div`
  & > div {
    min-width: 150px;
    max-width: 100%;

    @media (max-width: 900px) {
      display: flex;
    }
  }
`;

const SwitchWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 12px;

  & > span {
    color: ${({ theme }) => theme.primaryText};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  & > label {
    width: 34px;
    height: 20px;
    min-width: 34px;

    .slider-range:before {
      width: 16px;
      height: 16px;
    }
  }
`;

export default GenerativeVideoPopup;
