import React from "react";
import styled from "styled-components";

interface Props {
  size?: number;
  width?: number;
}

const Loader = ({ size = 50, width = 10 }: Props) => (
  <div>
    <StyledLoader size={size} width={width} />
  </div>
);

const StyledLoader = styled.div<{ size: number; width: number }>`
  border: ${({ width }) => width}px solid #f3f3f3;
  border-top: ${({ width }) => width}px solid #009af7;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: spin 1.2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
