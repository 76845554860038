export const HomeIcon = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8252 15.6667H11.3335V9.83334C11.3335 9.37251 10.9602 9.00001 10.5002 9.00001H5.50018C5.03935 9.00001 4.66685 9.37251 4.66685 9.83334V15.6667H2.16685L2.17185 8.65251L7.99852 2.69334L13.8335 8.68667L13.8252 15.6667ZM6.33352 15.6667H9.66685V10.6667H6.33352V15.6667ZM15.0202 7.48751L8.59602 0.917505C8.28185 0.596672 7.71852 0.596672 7.40435 0.917505L0.97935 7.48834C0.675183 7.80084 0.500183 8.2375 0.500183 8.68667V15.6667C0.500183 16.5858 1.20602 17.3333 2.07352 17.3333H5.50018H10.5002H13.926C14.7935 17.3333 15.5002 16.5858 15.5002 15.6667V8.68667C15.5002 8.2375 15.3252 7.80084 15.0202 7.48751Z"
      fill="#191B1F"
    />
    <defs>
      <linearGradient
        id="paint0_linear_493_11335"
        x1="3.73095"
        y1="2.85503"
        x2="13.8015"
        y2="14.5153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#0063B4" />
      </linearGradient>
    </defs>
  </svg>
);
