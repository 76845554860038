import { FC } from "react";
import styled from "styled-components";

interface TooltipProps {
  title: string;
  arrow?: boolean;
  toRight?: boolean;
}

const Tooltip: FC<TooltipProps> = ({ title, arrow, toRight }) => (
  <StyledTooltip className="tooltip" toRight={toRight}>
    {title} {arrow && <Arrow />}
  </StyledTooltip>
);

const Arrow = styled.span`
  position: absolute;
  bottom: -14px;
  left: 50%;
  width: 30px;
  height: 15px;
  transform: translateX(-50%);
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    margin-top: -1px;
    width: 14px;
    height: 14px;
    transform: rotate(45deg) translateX(-50%);
    background: #012b5a;
  }
`;

const StyledTooltip = styled.span<{ toRight?: boolean }>`
  position: absolute;
  bottom: 20px;
  width: max-content;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(97, 97, 97, 0.92);
  border-radius: 4px;
  color: #fff;
  padding: 4px 8px;
  font-size: 0.6875rem;
  max-width: 300px;
  word-wrap: break-word;
  font-weight: 500;
  z-index: 1;

  right: ${({ toRight }) => (toRight ? "none" : "0")};
  left: ${({ toRight }) => (toRight ? "0" : "none")};
`;

export default Tooltip;
