export const pages = {
  main: (route?: "google" | "mail") => {
    console.log("newURL", "/" + (route ? "?signup=" + route : ""));
    return "/" + (route ? "?signup=" + route : "");
  },
  // signUp: () => "/signup",
  signIn: () => "/signin",
  myPlan: () => "/my-plan",
  sharedWithMe: () => "/shared-with-me",
  updates: () => "/updates",
  support: () => "/support",
  settings: () => "/settings",
  myProjects: () => "/my-projects",
  actors: () => "/actors",
  recoverPassword: () => "/recover-password",
  resetPassword: () => "/reset-password",
  aiHumans: () => "/ai-humans",
  pocDragResize: () => "/poc-drag-n-resize",
  scenesPoc: () => "/poc-scenes",
  apiAccess: () => "/api-access",
  pricing: () => "/pricing",
  pricingSubscriptions: () => "/pricing/subscriptions",
  aiImages: () => "/ai-images",
  video: () => "/video",
  training: () => "/training",
  assets: () => "/assets",
  videoTranslation: () => "/video-translation",
  transparentPricing: () => "/transparent-pricing",
  history: () => "/history",
};

const PATH_TO_LIVE = "https://app.synthesys.live/";
const DOMAIN = "https://synthesys.io/";

export const IExternalLinks = {
  live: PATH_TO_LIVE,
  login: PATH_TO_LIVE + "signin",
  tryForFree: PATH_TO_LIVE + "free-trial",
  aiHumans: PATH_TO_LIVE + "free-trial/ai-humans",
  aiVoices: PATH_TO_LIVE + "free-trial/ai-voices",
  aiImages: PATH_TO_LIVE + "free-trial/ai-images",
  translator: PATH_TO_LIVE + "free-trial/video-translation",

  blog: DOMAIN + "blog/",
  blogHowToChooseAiVoices: DOMAIN + "blog/how-to-choose-ai-voices/",
  blogCreateTextToSpeechWithEmotion: DOMAIN + "blog/create-text-to-speech-with-emotion/",
  blogHowToMakeYourVideoContentCome: DOMAIN + "blog/how-to-make-your-video-content-come-alive-with-text-to-speech/",
  xPage: DOMAIN + "x/",

  videoPreview: "https://i.ytimg.com/vi/",
  videoLink: "https://www.youtube.com/embed/",

  privacyPolicyGenerator: "https://www.privacypolicygenerator.info/",
  termsConditionsTemplate: "https://www.privacypolicies.com/blog/sample-terms-conditions-template/",
  cookiesArticle: "https://www.generateprivacypolicy.com/#cookies",
  googleCookies: "https://policies.google.com/technologies/ads",
  googleAdsPartner: "https://policies.google.com/technologies/ads",

  tcs: "https://www.tcs.com/",
  yahooInc: "https://www.yahooinc.com/",
  nVidia: "https://www.nvidia.com/en-us/",
  cocaCola: "https://www.coca-colacompany.com/",
  ats: "https://ats.net/en/",

  facebook: "https://www.facebook.com/groups/synthesysofficial",
  linkedin: "https://www.linkedin.com/company/synthesys-studio",
  eMail: "support@synthesys.io",

  affiliatesReferrals:
    "https://docs.google.com/forms/d/e/1FAIpQLSeX6VV_3JqMF5VJ58uFvu1lhJz-SEe7iG7DTpIYQ5tApOixyw/viewform",
  supportedLanguages: "https://help.synthesys.io/article/59-list-of-supported-languages",
  createAvatar: "https://app.paykickstart.com/checkout/45nv3ME1weJgxz3JxpEjdL07plRBGqDo",
};
