import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material";
import * as Sentry from "@sentry/react";

const DEFAULT_SENTRY_DSN =
  "https://9db76e35afb545e78608235dcdccebb2@o4504986857832448.ingest.sentry.io/4504986863271936";

const DEFAULT_SYNTHESYS_GOOGLE_TOKEN = "989339005912-3n92pol51lut7vsljavd4fj94nvldud1.apps.googleusercontent.com";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || DEFAULT_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.REACT_APP_ENV === "production",
});

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <GoogleOAuthProvider clientId={process.env.SYNTHESYS_GOOGLE_TOKEN || DEFAULT_SYNTHESYS_GOOGLE_TOKEN}>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </StyledEngineProvider>
  </GoogleOAuthProvider>,
);
