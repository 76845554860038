import { combineReducers } from "redux";

import authReducer from "./authReducer";
import tokensReducer from "./tokensReducer";
import profileReducer from "./profileReducer";
import actorReducer from "./actorReducer";
import popupsReducer from "./popupsReducer";
import projectReducer from "./projectReducer";
import themeReducer from "./themeReducer";
import chatReducer from "./chatReducer";
import humansProjectReducer from "./humansProjectReducer";
import shapesReducer from "./shapesReducer";
import transitionsReducer from "./transitionReducer";
import templateReducer from "./templateReducer";
import assetReducer from "./assetReducer";
import humatarReducer from "./humatarReducer";
import languageReducer from "./languageReducer";
import imagesReducer from "./imagesReducer";
import sumoReducer from "./sumoReducer";
import plansReducer from "./plansReducer";
import videoTranslationReducer from "./videoTranslationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  asset: assetReducer,
  tokens: tokensReducer,
  transition: transitionsReducer,
  profile: profileReducer,
  actor: actorReducer,
  humatar: humatarReducer,
  popups: popupsReducer,
  project: projectReducer,
  theme: themeReducer,
  template: templateReducer,
  chat: chatReducer,
  humansProject: humansProjectReducer,
  shapes: shapesReducer,
  language: languageReducer,
  images: imagesReducer,
  sumo: sumoReducer,
  plans: plansReducer,
  videoTranslation: videoTranslationReducer,
});

export default rootReducer;
