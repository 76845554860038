import React from "react";
import styled from "styled-components";

type IContainerVariant = "normal";

const getWidth = (variant: IContainerVariant) => {
  if (variant === "normal") return "1222px";
};

interface Props {
  children: React.ReactNode;
  variant?: IContainerVariant;
  style?: Record<string, string | number>;
}

const Container = ({ children, variant = "normal", style = {} }: Props) => (
  <Wrapper variant={variant} style={style} className="customContainer">
    {children}
  </Wrapper>
);

const Wrapper = styled.div<{ variant: IContainerVariant }>`
  width: ${({ variant }) => getWidth(variant)};
  max-width: 90%;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 93%;
  }
`;
export default Container;
