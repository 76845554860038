/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, FC, useState } from "react";

const defaultState = ["default"];

interface MyContextProps {
  linksArray: string[];
  updateObject: (updatedValue: string[]) => void;
  toggleYearly: () => void;
  isYearly: boolean;
}

const defaultValues = {
  linksArray: defaultState,
  updateObject: () => {},
  toggleYearly: () => {},
  isYearly: true,
};

export const PricingContext = createContext<MyContextProps>(defaultValues);

interface PricingProviderProps {
  children: React.ReactNode;
}

const PricingProvider: FC<PricingProviderProps> = ({ children }) => {
  const [linksArray, setLinksArray] = useState<string[]>(defaultState);
  const [isYearly, setIsYearly] = useState(true);

  const toggleYearly = () => {
    setIsYearly(!isYearly);
  };

  const updateObject = (updatedValue: string[]) => {
    setLinksArray(updatedValue);
  };

  return (
    <PricingContext.Provider value={{ linksArray, updateObject, toggleYearly, isYearly }}>
      {children}
    </PricingContext.Provider>
  );
};

export default PricingProvider;
