import React, { useContext } from "react";
import styled from "styled-components";

import MediaLayers from "./MediaLayers";
import DeleteMedia from "./DeleteMedia";
import DuplicateMedia from "./DuplicateMedia";
import { VideoEditorContext } from "../../../../../App";
import { Media, ObjectTypes, SceneObject } from "../../../../../types/scene";

const MediaActions = () => {
  const { currentScene } = useContext(VideoEditorContext);

  const currentObject = currentScene?.objects.find(
    (obj: SceneObject) => obj.object.id === currentScene.activeObjectId && obj.type === ObjectTypes.media,
  )?.object as Media | undefined;

  return currentObject ? (
    <Wrapper>
      <DuplicateMedia currentObject={currentObject} />
      <MediaLayers />
      <DeleteMedia />
    </Wrapper>
  ) : (
    <div />
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export default MediaActions;
