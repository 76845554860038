import styled from "styled-components";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import Button from "../../../components/Button/Button";
import Tooltip from "../../../components/Tooltip/Tooltip";
import useAudioPlayer from "../../../hooks/useAudioPlayer";
import CircularProgress from "../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { IAudio } from "../../../hooks/useAIVoices";
import { downloadFile } from "../../../lib/downloadFile";
import { AudioTranslateProject } from "../../../types/project";
import { ImportLightIcon } from "../../../components/Icons/Icons";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { formatNumberToDuration } from "../../../lib/formatDuration";
import { getSpeech2Speech, getSpeechTranslation, getTextFromAudioLoading } from "../../../redux/reducers/actorReducer";

interface Props {
  project: AudioTranslateProject | null;
  tabName: string;
  buttonText?: string;
  actorId: number;
  targetLanguage?: string;
  translatedText?: string;
  projectAudio?: IAudio | null;
  createProject: () => void;
  signUpPopupOpen: () => void;
  handleProjectAudioChange: (audio: IAudio | null) => void;
}

const PlayerForProjectAudio = ({
  project,
  tabName,
  buttonText = "Generate",
  actorId,
  targetLanguage,
  translatedText,
  projectAudio,
  createProject,
  signUpPopupOpen,
  handleProjectAudioChange,
}: Props) => {
  const isFirstRender = useRef(true);
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const speech2SpeechProject = useSelector(getSpeech2Speech);
  const speechTranslationProject = useSelector(getSpeechTranslation);
  const isProjectLoading = speech2SpeechProject.isLoading || speechTranslationProject.isLoading;
  const isTextFromAudioLoading = useSelector(getTextFromAudioLoading);

  const [isAudioFailed, setIsAudioFailed] = useState(false);

  const {
    audioPlayer,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    playing,
    isFetching,
    currentTime,
    duration,
    seekValue,
    setPlaying,
    setDuration,
    setCurrentTime,
    toggleAudioPlay,
    handleProgressBarChange,
  } = useAudioPlayer();

  const isAudioValidAndExist = async (callback: () => void, type: "play" | "save") => {
    if (
      !project?.audioTranslateProjectId ||
      project.actorID !== actorId ||
      project.targetLanguage !== (targetLanguage || "en") ||
      project.translatedText !== translatedText ||
      projectAudio?.src !== projectAudio?.oldSrc
    ) {
      if (type === "play") {
        setPlaying(false);
        if (seekValue) {
          setCurrentTime(0);
          handleProgressBarChange({ target: { value: 0 } });
        }
        setDuration(0);
        handleProjectAudioChange({ ...(projectAudio as IAudio), oldSrc: projectAudio?.src as string });
        createProject();
      } else {
        toast.error(`${tabName === "Speech2Speech" ? "Generate" : "Play"} audio first to download`);
      }
      return;
    }

    if (!project?.outputUrl) {
      toast.error("An error occurred when running the generated audio.");
      return;
    }

    callback();
  };

  const handlePlay = async () => {
    await isAudioValidAndExist(toggleAudioPlay, "play");
  };

  const saveAudio = async (e: any) => {
    await isAudioValidAndExist(() => downloadFile(e, project?.outputUrl as string), "save");
  };

  useEffect(() => {
    if (audioPlayer.current) {
      const handleAudioError = () => {
        setIsAudioFailed(true);
        toast.error("Failed to load the audio. The source might be invalid.");
      };

      audioPlayer.current.addEventListener("error", handleAudioError);

      return () => {
        if (audioPlayer.current) {
          audioPlayer.current.removeEventListener("error", handleAudioError);
        }
      };
    }
  }, [audioPlayer]);

  useEffect(() => {
    if (!isFirstRender.current && !projectAudio?.src) {
      setPlaying(false);

      if (seekValue) {
        setCurrentTime(0);
        handleProgressBarChange({ target: { value: 0 } });
      }
      setDuration(0);
    }
  }, [projectAudio]);

  useEffect(() => {
    if (!isFirstRender.current && project && project?.audioTranslateProjectId && project?.outputUrl) {
      isAudioValidAndExist(toggleAudioPlay, "play");
    }
    isFirstRender.current = false;
  }, [project?.audioTranslateProjectId, project?.outputUrl]);

  return (
    <Wrapper>
      {project?.outputUrl && (
        <audio
          src={project.outputUrl}
          ref={audioPlayer}
          onTimeUpdate={onPlaying}
          onLoadedMetadata={onLoadedMetadata}
          onLoadStart={onLoadStart}
        />
      )}
      <ManageButton>
        <PlayButton>
          <Button
            text={
              isFetching ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress color="#F0F0F3" />
                </div>
              ) : !playing || currentTime === duration ? (
                buttonText
              ) : (
                "Pause"
              )
            }
            disabled={isProjectLoading || isAudioFailed || isTextFromAudioLoading}
            onClick={handlePlay}
          />
        </PlayButton>
      </ManageButton>
      <ProgressWrapper>
        <ProgressBar>
          <ProgressBarLine
            type="range"
            min="0"
            max="100"
            step="1"
            value={seekValue || 0}
            width={seekValue || 0}
            onChange={handleProgressBarChange}
          />
        </ProgressBar>
        <ProgressCount>
          {formatNumberToDuration(currentTime)} <span> / {formatNumberToDuration(duration)}</span>
        </ProgressCount>
      </ProgressWrapper>
      <WatermarkTrack>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          disabled={isProjectLoading || isAudioFailed || isTextFromAudioLoading}
          icon={<ImportLightIcon />}
          onClick={!project?.audioTranslateProjectId ? saveAudio : isGuest ? signUpPopupOpen : saveAudio}
        />
        <Tooltip text="Download" />
      </WatermarkTrack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 16px;
  padding: 13px;
  align-items: center;

  @media (max-width: 1000px) {
    margin: 24px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ManageButton = styled.div`
  display: flex !important;
  align-items: center;
  gap: 4px;
  width: 100px;
  order: 1;
  margin-right: 7px;

  @media (max-width: 1000px) {
    order: 2;
    width: auto;
  }
`;

const PlayButton = styled.div`
  position: relative;
  width: 100px;

  & > button {
    height: 36px;
    font-size: 14px;
    background: #0063b4;
    box-shadow: none;

    .loader {
      display: flex;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const ProgressWrapper = styled.div`
  width: calc(100% - 160px);
  display: flex;
  align-items: center;
  order: 2;

  @media (max-width: 1000px) {
    order: 1;
    width: 100%;
    margin-bottom: 12px;
  }
`;

const ProgressBar = styled.div`
  height: 12px;
  width: calc(100% - 100px);
  position: relative;
  background: #f0f0f3;
  box-shadow: -5px -5px 4px 0px #ffffff4d inset, 5px 5px 4px 0px #aeaec033 inset;
  border-radius: 60px;
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-left: 2px;
  padding-right: 2px;
`;

const ProgressBarLine = styled.input<{ width: number }>`
  background: ${({ theme, width }) => `linear-gradient(to right, #0063b4 ${width}%, transparent 0)`};
  border-radius: 52px;
  width: 100%;
  height: 8px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0px;
    height: 0px;
    cursor: pointer;
  }
`;

const ProgressCount = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #191b1f;
  display: flex;
  margin-left: 19px;

  span {
    color: #191b1f66;
    display: flex;
    margin-left: 5px;
  }
`;

const WatermarkTrack = styled.div`
  display: flex;
  position: relative;
  order: 3;
  margin-left: 13px;
  max-width: 36px;
  width: 100%;

  :hover > div {
    top: -50px;
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  button {
    background: #0063b4;
    box-shadow: none;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 1000px) {
    order: 3;
    margin: 0;
    max-width: 36px;
    width: 100%;
  }
`;

export default PlayerForProjectAudio;
