import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { Shape, ShapeTypes } from "../../../../../types/scene";
import { ChromePicker } from "react-color";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Shape;
}

const ShapeColor = ({ handleSceneObjectChange, currentObject }: Props) => {
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleColorChange = (event: any) => {
    if (!currentObject) return;
    const {
      rgb: { r, g, b, a },
    } = event;
    const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
    handleSceneObjectChange("background", rgba, currentObject.id);
    setValue(rgba);
  };

  const handleClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (!currentObject) return setValue("");
    setValue(currentObject.background as string);
  }, [currentObject]);

  const getIsAlphaDisabled = (currentObject: Shape) => {
    if (
      currentObject.shape === ShapeTypes.circle ||
      currentObject.shape === ShapeTypes.square ||
      currentObject.shape === ShapeTypes.rectangle
    )
      return false;
    else return true;
  };

  return (
    <div>
      <ButtonWrapper>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={<ColorPickerIcon color={value} />}
          onClick={handleClick}
        />
        {isOpen && (
          <PickerWrapper>
            <ChromePicker onChange={handleColorChange} color={value} disableAlpha={getIsAlphaDisabled(currentObject)} />
          </PickerWrapper>
        )}
        <Tooltip
          text={"Background color"}
          position="bottom"
          style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
          reverseColor
          arrow
        />
      </ButtonWrapper>
      {isOpen && <Cover onClick={handleClick} />}
    </div>
  );
};

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
    }
  }
`;

const ColorPickerIcon = styled.div<{ color: string }>`
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background: ${({ color }) => color};
  border: 1px solid ${({ theme }) => theme.primaryText};
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 100;
`;

const PickerWrapper = styled.div`
  position: absolute;
  z-index: 101;
`;

export default ShapeColor;
