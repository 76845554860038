export const TrimIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3.33366C4.07953 3.33366 3.33333 4.07985 3.33333 5.00033C3.33333 5.9208 4.07953 6.66699 5 6.66699C5.92048 6.66699 6.66667 5.9208 6.66667 5.00033C6.66667 4.07985 5.92048 3.33366 5 3.33366ZM1.66667 5.00033C1.66667 3.15938 3.15905 1.66699 5 1.66699C6.84095 1.66699 8.33333 3.15938 8.33333 5.00033C8.33333 6.84127 6.84095 8.33366 5 8.33366C3.15905 8.33366 1.66667 6.84127 1.66667 5.00033Z"
      fill="url(#paint0_linear_1010_10801)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 13.3337C4.07953 13.3337 3.33333 14.0799 3.33333 15.0003C3.33333 15.9208 4.07953 16.667 5 16.667C5.92048 16.667 6.66667 15.9208 6.66667 15.0003C6.66667 14.0799 5.92048 13.3337 5 13.3337ZM1.66667 15.0003C1.66667 13.1594 3.15905 11.667 5 11.667C6.84095 11.667 8.33333 13.1594 8.33333 15.0003C8.33333 16.8413 6.84095 18.3337 5 18.3337C3.15905 18.3337 1.66667 16.8413 1.66667 15.0003Z"
      fill="url(#paint1_linear_1010_10801)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2559 2.74408C17.5814 3.06951 17.5814 3.59715 17.2559 3.92259L7.35592 13.8226C7.03049 14.148 6.50285 14.148 6.17741 13.8226C5.85198 13.4972 5.85198 12.9695 6.17741 12.6441L16.0774 2.74408C16.4028 2.41864 16.9305 2.41864 17.2559 2.74408Z"
      fill="url(#paint2_linear_1010_10801)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4685 11.478C11.7937 11.1523 12.3213 11.1518 12.6471 11.4769L17.2554 16.0769C17.5811 16.4021 17.5816 16.9297 17.2565 17.2555C16.9313 17.5812 16.4037 17.5817 16.0779 17.2565L11.4696 12.6565C11.1439 12.3314 11.1434 11.8037 11.4685 11.478Z"
      fill="url(#paint3_linear_1010_10801)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.17741 6.17767C6.50285 5.85223 7.03049 5.85223 7.35592 6.17767L10.5893 9.411C10.9147 9.73644 10.9147 10.2641 10.5893 10.5895C10.2638 10.915 9.73618 10.915 9.41075 10.5895L6.17741 7.35618C5.85198 7.03075 5.85198 6.50311 6.17741 6.17767Z"
      fill="url(#paint4_linear_1010_10801)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1010_10801"
        x1="3.10257"
        y1="2.53879"
        x2="7.05128"
        y2="7.61571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1010_10801"
        x1="3.10257"
        y1="12.5388"
        x2="7.05128"
        y2="17.6157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1010_10801"
        x1="8.42462"
        y1="4.01256"
        x2="15.2756"
        y2="12.821"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1010_10801"
        x1="12.5765"
        y1="12.0529"
        x2="16.2871"
        y2="16.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1010_10801"
        x1="6.98872"
        y1="6.57436"
        x2="9.89103"
        y2="10.3059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
