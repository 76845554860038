import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { CropImage } from "../../types/asset";
import { ImportIcon } from "../Icons/ImportIcon";
import { canvasPreview } from "../../lib/canvasPreview";
import { getAssets } from "../../redux/reducers/assetReducer";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { createUserAssetServer } from "../../redux/actions/assetAction";

interface Props {
  open: boolean;
  onClose: () => void;
}

const CropImagePopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const imgRef = useRef<HTMLImageElement>(null);

  const { path, typeId, formData, fileType, pageSize = 24 }: CropImage = useSelector(getPrefilled);
  const assets = useSelector(getAssets);
  const uploadLoading = assets[typeId].uploadLoading;

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [base64Image, setBase64Image] = useState("");

  const handleClose = () => {
    if (uploadLoading) return;

    onClose();
  };

  const handleUpload = () => {
    if (base64Image) {
      formData.append("Base64Image", base64Image);
    }

    dispatch(
      createUserAssetServer({
        data: formData,
        assetTypeId: typeId,
        pageSize,
      }),
    );
  };

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current && fileType) {
      canvasPreview(imgRef.current, setBase64Image, fileType, completedCrop);
    }
  }, [completedCrop]);

  useEffect(() => {
    if (!isFirstRender.current && !uploadLoading) {
      handleClose();
    }

    isFirstRender.current = false;
  }, [uploadLoading]);

  return (
    <Modal className="crop-image" title="Crop Image" open={open} onClose={handleClose} maxWidth={480} maxHeight={592}>
      <Content>
        <ReactCropWrapper>
          {!!path && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
            >
              <Image ref={imgRef} src={path} />
            </ReactCrop>
          )}
        </ReactCropWrapper>
        <UploadButton>
          <Button
            disabled={uploadLoading}
            icon={<ImportIcon />}
            text={uploadLoading ? "Loading..." : "Upload"}
            onClick={uploadLoading ? () => null : handleUpload}
          />
        </UploadButton>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ReactCropWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 392px !important;
`;

const Image = styled.img`
  width: auto;
  height: 100%;
  max-height: 392px !important;
  display: block;
`;

const UploadButton = styled.div`
  button {
    gap: 8px;

    svg > path {
      fill: #fff;
    }
  }
`;

export default CropImagePopup;
