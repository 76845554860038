import parse from "html-react-parser";
import styled from "styled-components";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "../Tabs";
import TitleWithAction from "../TitleWithAction";
import Textfield from "../../../../components/Textfield/Textfield";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import { Shape as ShapeType, ShapeBorderStyle, ShapeTypes } from "../../../../types/scene";
import { VideoEditorContext } from "../../../../App";
import { SearchIcon } from "../../../../components/Icons/Icons";
import { ShapesProps, shapeTabs } from "../../../../mocks/humans";
import { shapesComponents } from "../../../ScenesPoc/components/SceneObject";
import { getAllShapesByCategoryServer } from "../../../../redux/actions/shapesAction";
import { ShapesCategory, getAllShapesByCategory } from "../../../../redux/reducers/shapesReducer";

export const getShape = (shapeId: number, svgData: string | null) => {
  if (svgData) return ShapeTypes.customSvg;

  switch (shapeId) {
    case 652:
      return ShapeTypes.polygon;
    case 649:
      return ShapeTypes.rectangle;
    case 102:
      return ShapeTypes.star;
    case 651:
      return ShapeTypes.triangle;
    case 650:
      return ShapeTypes.circle;
    case 118:
      return ShapeTypes.heart;
    case 116:
      return ShapeTypes.arrow;
    case 146:
      return ShapeTypes.line;
    default: {
      return ShapeTypes.custom;
    }
  }
};

interface Props {
  canvasSize: { width: number; height: number };
}

const ShapesTab = ({ canvasSize }: Props) => {
  const dispatch = useDispatch();
  const { handleAddShape } = useContext(VideoEditorContext);

  const [search, setSearch] = useState("");
  const [activeShape, setActiveShape] = useState(shapeTabs[0]);
  const [data, setData] = useState<ShapesCategory[]>();

  const shapes = useSelector(getAllShapesByCategory);
  const shapesList = shapes[activeShape].data;
  const shapesLoading = shapes[activeShape].isLoading;

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleActiveShapes = (shape: string) => {
    setActiveShape(shape as ShapesProps);
  };

  const filterData = () => {
    const newData = shapesList.filter((shape) => {
      if (search && shape.keyword && !shape.keyword.toLowerCase().includes(search.toLowerCase())) {
        return false;
      }

      return true;
    });

    setData(newData);
  };

  useEffect(() => {
    if (shapesList) {
      filterData();
    }
  }, [search, activeShape, shapesList]);

  useEffect(() => {
    if (!shapesList.length) {
      dispatch(getAllShapesByCategoryServer(activeShape));
    }
  }, [activeShape]);

  return (
    <Wrapper>
      <TitleWithAction title={activeShape}>
        <Tabs data={shapeTabs} active={activeShape} handleActive={handleActiveShapes} />
        <Textfield
          value={search}
          placeholder={`Search by ${activeShape.toLowerCase()}`}
          startAdornment={<SearchIcon />}
          onKeyDown={(event) => {
            if (event.key === "Backspace" || event.key === "Delete") {
              event.stopPropagation();
            }
          }}
          onChange={handleSearchChange}
        />
        <Content isLoading={shapesLoading}>
          {shapesLoading ? (
            <CircularProgress color="#009af7" />
          ) : (
            <ItemWrapper>
              {data &&
                data?.map(({ shapeId, shapeName, shapeImageUrl, categoryName, svgData }) => (
                  <Item
                    key={shapeId}
                    onClick={() =>
                      handleAddShape(getShape(shapeId, svgData), svgData, shapeId, shapeImageUrl, canvasSize)
                    }
                  >
                    <Shape
                      categoryName={categoryName}
                      shapeName={shapeName}
                      shapeImageUrl={shapeImageUrl}
                      svgData={svgData}
                      shapeId={shapeId}
                    />
                  </Item>
                ))}
            </ItemWrapper>
          )}
        </Content>
      </TitleWithAction>
    </Wrapper>
  );
};

interface ShapeProps {
  categoryName: string;
  shapeName: string;
  shapeImageUrl: string;
  svgData: string | null;
  shapeId: number;
}

const Shape = ({ categoryName, shapeImageUrl, shapeName, svgData, shapeId }: ShapeProps): any => {
  const shapeType = getShape(shapeId, svgData);

  return shapeType !== ShapeTypes.custom && shapeType !== ShapeTypes.customSvg ? (
    shapesComponents({
      background: "#000000",
      border: { borderColor: "#000000", borderStyle: ShapeBorderStyle.solid, borderWidth: 0 },
      opacity: 1,
      shapeImageUrl: "",
      svgData,
    } as ShapeType)[shapeType]
  ) : svgData ? (
    parse(svgData)
  ) : categoryName === "Videos" ? (
    <video src={shapeImageUrl} />
  ) : (
    <img src={shapeImageUrl} alt={shapeName} />
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div<{ isLoading?: boolean }>`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 9px;
  border-radius: 20px;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  ${({ isLoading }) =>
    isLoading &&
    `
      align-items: center;
      justify-content: center;
    `}
`;

const ItemWrapper = styled.div`
  display: flex;
  align-content: start;
  flex-flow: row wrap;
  gap: 8px;
  margin-bottom: 24px;
  height: 100%;
  max-height: 100%;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const Item = styled.div`
  cursor: pointer;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 78px;
  height: 78px;

  img,
  svg,
  video,
  div {
    width: 24px !important;
    height: 24px;
    max-width: 100%;
  }
  svg {
    width: 24x;
    height: 24px;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.primaryText};
  }
`;

export default ShapesTab;
