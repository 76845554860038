import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import CloseIcon from "../../../components/Icons/CloseIcon";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { Status } from "../../../redux/actions/humansProjectActions";
import { InfoIcon, FailureIcon, SuccessIcon } from "../../../components/Icons/Icons";
import { downloadFile } from "../../../lib/downloadFile";

interface Props {
  status?: Status;
  text?: ReactNode;
  closeIcon?: boolean;
  output?: string;
}

const Alert = ({ status, text, closeIcon = true, output }: Props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  if (
    status === Status.isDraft ||
    (status as Status) > Status.isError ||
    (status as Status) < Status.isDraft ||
    !status
  )
    return <></>;

  return (
    <Wrapper open={open} variant={status} className="alert-wrapper">
      {content({ status, text, output })}
      {closeIcon && (
        <IconButton icon={<CloseIcon />} iconButtonTheme={IconButtonThemes.Transparent} onClick={handleClose} />
      )}
    </Wrapper>
  );
};

const content = ({ status, text, output }: Props) => {
  switch (status) {
    case Status.isProgress:
      return (
        <>
          <InfoIcon />
          <span>{text || "Generating of video is in progress."}</span>
        </>
      );
    case Status.isCompleted:
      return (
        <>
          <SuccessIcon />
          <span>
            {text || "Generated successfuly."}
            {!text && <span onClick={(e: any) => downloadFile(e, output || "", status)}>Download</span>}
          </span>
        </>
      );
    case Status.isError:
      return (
        <>
          <FailureIcon />
          <span>{text || "Generation failed, contact support."}</span>
        </>
      );
    default:
      return <div></div>;
  }
};

const Wrapper = styled.div<{ open?: boolean; variant?: Status }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 32px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  visibility: visible;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    span {
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  svg {
    width: 22px;
    height: 22px;
  }

  button {
    margin-left: auto;

    svg > path {
      fill: initial;
    }
  }

  ${({ variant }) =>
    variant === Status.isProgress &&
    `
      background-color: rgb(229, 246, 253);

      span {
        color: #0288d1;
      }
    `}

  ${({ variant }) =>
    variant === Status.isCompleted &&
    `
      background-color: rgb(237, 247, 237);

      span {
        color: #2e7d32;
      }

      svg > path {
        fill: #2e7d32;
      }
    `}

  ${({ variant }) =>
    variant === Status.isError &&
    `
      background-color: rgb(253, 237, 237);

      span {
        color: #d32f2f;
      }
    `}

  ${({ open }) =>
    !open &&
    `
    visibility: hidden;
    `}
`;

export default Alert;
