import styled from "styled-components";
import { useContext } from "react";
import { Slide } from "@mui/material";

import ArrowDown from "../../../components/Icons/ArrowDown";
import RecentlyGeneratedVideo from "./RecentlyGeneratedVideo";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { VideoTranslationContext } from "../../../App";

interface Props {
  data: any;
  isLoading: boolean;
}

const RecentlyGeneratedVideosDrawer = ({ data, isLoading }: Props) => {
  const { recentlyGeneratedVisible, toggleRecentlyGeneratedVisible } = useContext(VideoTranslationContext);

  return (
    <BottomContent active={recentlyGeneratedVisible}>
      <BottomContentFormControl active={recentlyGeneratedVisible} onClick={toggleRecentlyGeneratedVisible}>
        <div>
          <span>Recently Generated Videos</span>
          <IconButton>
            <ArrowDown />
          </IconButton>
        </div>
      </BottomContentFormControl>
      <BottomContentSlide direction="up" in={recentlyGeneratedVisible} mountOnEnter unmountOnExit className="acvsss">
        <VideoItemsWrapper>
          {isLoading ? (
            <CircularProgressWrapper>
              <CircularProgress color="#009af7" />
            </CircularProgressWrapper>
          ) : (
            data.map((item: any) => <RecentlyGeneratedVideo key={item.videoTranslateProjectId} {...item} />)
          )}
        </VideoItemsWrapper>
      </BottomContentSlide>
    </BottomContent>
  );
};

const BottomContent = styled.div<{ active: boolean }>`
  margin-top: auto;
  height: ${({ active }) => (active ? "180px" : "46px")};
  transition: height 0.3s ease;
  border-top: 1px solid ${({ theme }) => theme.videoTranslationGray};
  background-color: ${({ theme }) => theme.primaryBackground};

  @media (max-width: 400px) {
    height: ${({ active }) => (active ? "100%" : "46px")};
  }
`;

const BottomContentSlide = styled(Slide)`
  margin-top: auto;
`;

const VideoItemsWrapper = styled.div`
  display: flex;
  gap: 12px;
  max-height: 123px;
  height: 100%;
  @media (max-width: 400px) {
    flex-wrap: wrap;
    max-height: 100%;
  }
  overflow-x: auto;
  overflow-y: hidden;
`;

const CircularProgressWrapper = styled.div`
  margin: auto;
`;

const BottomContentFormControl = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 12px 0;
  div {
    display: flex;
    justify-content: space-between;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
  }
  svg {
    ${({ active }) =>
      !active &&
      `
        transform: rotate(180deg);
  `}

    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;

const IconButton = styled.div`
  cursor: pointer;
`;

export default RecentlyGeneratedVideosDrawer;
