import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import Tooltip from "../Tooltip/Tooltip";
import {
  SpeechSynthesysIcon,
  SpeechToSpeechIcon,
  SpeechMultilingualIcon,
  SpeechStylesSupportIcon,
} from "../Icons/AIVoicesSpeechIcons";
import { IActor } from "../../types/actor";
import { getFullImageUrl } from "../../lib/getFullImageUrl";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { getProfile } from "../../redux/reducers/profileReducer";
import { getMyProfileServer } from "../../redux/actions/profileActions";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";

interface Props {
  actor: IActor;
  active?: boolean;
  onClick?: () => void;
}

const ActorCard = ({ actor, active, onClick }: Props) => {
  const hasBadge = (badge: string) => actor?.badges?.includes(badge);

  return (
    <Wrapper active={active} onClick={onClick}>
      <Avatar>
        <img src={actor?.photo} alt="" />
      </Avatar>
      <Content>
        <InfoCol>
          <Name>{actor?.name}</Name>
          <Flag>
            {hasBadge("Multilingual") ? (
              <IconWrapper>
                <SpeechMultilingualIcon />
                <Tooltip
                  text="Multilingual"
                  style={{ width: "24px", height: "24px", right: "28px", top: "0", transform: "none" }}
                  position="right"
                />
              </IconWrapper>
            ) : (
              <>
                <img src={actor?.flagPath ? getFullImageUrl(actor.flagPath) : "/images/flag.png"} alt="" />
                {/* {actor?.languageName && <Tooltip text={actor.languageName.split(" ")[0]} />} */}
              </>
            )}
          </Flag>
        </InfoCol>
        <IconsRow active={!!active}>
          {iconsData.map(
            ({ badge, IconComponent, tooltipText }) =>
              hasBadge(badge) && (
                <IconWrapper key={badge}>
                  <IconComponent />
                  <Tooltip text={tooltipText} />
                </IconWrapper>
              ),
          )}
        </IconsRow>
      </Content>
    </Wrapper>
  );
};

export const YourVoiceCard = () => {
  const dispatch = useDispatch();
  const { voiceCloneAllowed = 0, voiceCloneUsed = 0 } = useSelector(getProfile);

  const handleVoiceCloning = () => {
    voiceCloneAllowed === 0 || voiceCloneAllowed <= voiceCloneUsed
      ? upgradeYourPlan()
      : dispatch(getMyProfileServer({ checkCloneVoice: true }));
  };

  const upgradeYourPlan = (link?: string) => {
    const prefilled: NotEnoughCredits = {
      title: "Upgrade your plan to access this feature",
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
      link,
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <Wrapper onClick={handleVoiceCloning} isBordered>
      <Avatar>
        <img style={{ objectFit: "cover", backgroundColor: "#f0f0f3" }} src="/images/jeffrey-keenan.jpg" alt="" />
      </Avatar>
      <Content>
        <InfoCol>
          <Name>Your voice here</Name>
        </InfoCol>
      </Content>
    </Wrapper>
  );
};

const iconsData = [
  { badge: "Styles", IconComponent: SpeechStylesSupportIcon, tooltipText: "Speaking styles" },
  { badge: "Synthesys Voice", IconComponent: SpeechSynthesysIcon, tooltipText: "Synthesys Voice" },
  { badge: "Speech to Speech", IconComponent: SpeechToSpeechIcon, tooltipText: "Speech to speech" },
];

const IconWrapper = styled.div`
  position: relative;

  :hover > div {
    opacity: 1;
    visibility: visible;
    right: 28px;
    top: 0px;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    border-radius: 7px;
    min-width: max-content;
    box-shadow: none;

    span {
      font-size: 10px !important;
      line-height: 12px !important;
    }
  }
`;

const IconsRow = styled.div<{ active?: boolean }>`
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;

  ${({ active, theme }) =>
    active &&
    `
    .active-fill {
      fill: ${theme.white};
    }
    .active-stroke {
      stroke: ${theme.white};
    }
  `}

  div {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
  }
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div<{ active?: boolean; isBordered?: boolean }>`
  width: 248px;
  max-height: 46px;
  padding: 3px 7px;
  background: ${({ theme }) => theme.sidebarMenuBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 4;

  ${({ isBordered, theme }) =>
    isBordered &&
    `
    border: 1px solid ${theme.activeMenu};
  `}

  ${({ active, theme }) =>
    active &&
    `
    background: ${theme.homeContentCardBgHover};

    span {
      color: ${theme.white};
    }
  `}

  @media (max-width: 1000px) {
    width: auto;
    height: 48px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    border-radius: 52px;
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 4px;

  @media (max-width: 1000px) {
    flex-direction: row;
    padding: 4px;
  }
`;

const Name = styled.span`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;

  @media (max-width: 1000px) {
    width: auto;
  }
`;

const Flag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  border-radius: 2px;
  width: 24px;
  height: 24px;

  img {
    width: 16px;
    height: 12px;
  }

  @media (max-width: 1000px) {
    position: initial;
    padding-right: 18px;
  }

  /* :hover > div {
    opacity: 1;
    visibility: visible;
    right: 20px;
    top: -8px;
    min-width: 75px;
    max-width: 120px;
    width: auto;
    padding: 6px 12px;

    span {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  } */
`;

export default ActorCard;
