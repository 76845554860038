import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import TitleWithAction from "../TitleWithAction";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import {
  CrossDissolveIcon,
  CutawayIcon,
  IrisInIcon,
  IrisOutIcon,
  JumbCutIcon,
  RippleDissolveIcon,
} from "../../../../components/Icons/Icons";
import { getTransitionsServer } from "../../../../redux/actions/transitionAction";
import { getTransitions, getTransitionsLoading } from "../../../../redux/reducers/transitionReducer";
import { VideoEditorContext } from "../../../../App";

const TransitionTab = () => {
  const { currentScene, handleTransitionChange } = useContext(VideoEditorContext);

  const dispatch = useDispatch();
  const transitions = useSelector(getTransitions);
  const transitionsLoading = useSelector(getTransitionsLoading);

  const handleSelectTransition = (selected: boolean, id: number) => {
    if (selected) handleTransitionChange(0);
    else handleTransitionChange(id);
  };

  useEffect(() => {
    if (!transitions.length) dispatch(getTransitionsServer());
  }, [transitions]);

  return (
    <Wrapper>
      <TitleWithAction title="Transitions">
        <Content isLoading={transitionsLoading}>
          {transitionsLoading ? (
            <CircularProgress color="#009af7" />
          ) : (
            <ItemWrapper>
              {transitions.map(({ transitionEffectId, transitionEffectName }) => {
                const transitionSelected = transitionEffectId === currentScene.transitionEffectId;
                return (
                  <Item
                    key={transitionEffectId}
                    active={transitionSelected}
                    onClick={() => handleSelectTransition(transitionSelected, transitionEffectId)}
                  >
                    <Transition transitionEffectName={transitionEffectName} />
                    <span>{transitionEffectName}</span>
                  </Item>
                );
              })}
            </ItemWrapper>
          )}
        </Content>
      </TitleWithAction>
    </Wrapper>
  );
};

interface TransitionProps {
  transitionEffectName: string;
}

const Transition = ({ transitionEffectName }: TransitionProps) => {
  switch (transitionEffectName) {
    case "Cross Dissolve":
      return <CrossDissolveIcon />;
    case "Ripple Dissolve":
      return <RippleDissolveIcon />;
    case "Jump Cut":
      return <JumbCutIcon />;
    case "Cutaway":
      return <CutawayIcon />;
    case "Iris Out":
      return <IrisOutIcon />;
    case "Iris In":
      return <IrisInIcon />;
    default:
      return null;
  }
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div<{ isLoading?: boolean }>`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 16px;
  border-radius: 20px;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  /* flex: 1; */
  height: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  ${({ isLoading }) =>
    isLoading &&
    `
      align-items: center;
      justify-content: center;
    `}

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.secondaryBackground}f;
  }

  ::-webkit-scrollbar-track {
    margin: 15px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

const Item = styled.div<{ active?: boolean }>`
  padding: 12px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  width: calc(50% - 4px);
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    `
    border: solid 2px ${theme.activeMenu};
    padding: 10px;
    `}

  svg {
    width: 28x;
    height: 28px;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryText};
    text-align: center;
  }
`;

export default TransitionTab;
