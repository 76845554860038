import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { PlayIcon } from "../../../components/Icons/PlayIcon";
import { Scene } from "../../../types/scene";
import useVoiceGeneration from "../../../hooks/useVoiceGeneration";
import { checkIfZoneCached, checkIfZoneMatchNoAuthor, getAllZones } from "../../../lib/editorUtils";
import { generateVoiceServer } from "../../../redux/actions/actorActions";
import { getProjectAudio } from "../../../redux/reducers/projectReducer";
import { Zone } from "../../../types/project";
import { HumansParagraph } from "../../../types/humansProject";
import { PauseIcon } from "../../../components/Icons/PauseIcon";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { toast } from "react-toastify";
import { GenerateAudioParams } from "../../../types/generateAudio";

interface Props {
  scenes: Scene[];
  selectedZone?: Zone;
  paragraphs: HumansParagraph[];
  setIsButtonClicked: (b: boolean) => void;
  handleChangeActiveScene: (id: number) => void;
}

const AIHumansPreviewPlayer = ({
  scenes,
  setIsButtonClicked,
  handleChangeActiveScene,
  selectedZone,
  paragraphs,
}: Props) => {
  const { cachedZonesAudio } = useSelector(getProjectAudio);

  const generateZoneByIndex = (startZone: number) => {
    generateAudio({
      startZone: startZone,
    });
  };
  const dispatch = useDispatch();

  const onEveryAudioEnd = (index: number) => {
    const lenghts = paragraphs.reduce(
      (
        prev: {
          start: number;
          end: number;
        }[],
        current: HumansParagraph,
        currentIndex: number,
      ) => {
        const last = prev?.[currentIndex - 1] || {
          end: 0,
        };
        const newLength = {
          start: last.end + 1,
          end: last.end + current.data.length,
        };
        return [...prev, newLength];
      },
      [],
    );
    const finalIndex = lenghts.findIndex((item) => item.end === index + 1);
    if (finalIndex === -1) {
      // silent
    } else {
      // check if next scene exists
      if (paragraphs[finalIndex + 1]) {
        handleChangeActiveScene(scenes[finalIndex + 1].id);
      }
    }
  };

  const {
    audioPlayer,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    handleBackClick,
    playing,
    isFetching,
    currentTime,
    duration,
    audioLoading,
    handlePlayClick,
  } = useVoiceGeneration({
    onEveryAudioEnd,
    selectedZone,
    paragraphs: paragraphs,
    generateZoneByIndex,
    disablePlayingOnceSelectedZoneChanges: false,
  });

  const generateAudio = ({ startZone }: GenerateAudioParams) => {
    // const paragraphsModified = removeEmptyParagrahs(removeEmptyZones(JSON.parse(JSON.stringify(paragraphs))));
    const allZones = getAllZones(paragraphs);
    if (!startZone && startZone !== 0) {
      return;
    }
    const currentZone = allZones[startZone];

    if (currentZone) {
      const currentZoneIndex = allZones.findIndex((zone) => checkIfZoneMatchNoAuthor(zone, currentZone));
      const finalCurrentZone = allZones[currentZoneIndex];
      const isZoneCached = checkIfZoneCached(finalCurrentZone, cachedZonesAudio);
      if (!isZoneCached) {
        dispatch(generateVoiceServer({ data: [finalCurrentZone], isAIHuman: true }));
      } else {
        // TODO: sentry error - this exxception shouldn't be called
      }
    } else {
      // TODO: sentry error - this exxception shouldn't be called
    }
  };

  const handlePlaySceneScript = () => {
    handlePlayClick();
  };

  const handlePreviewClick = (event: any) => {
    // dispatch(generateHumansVoicesServer({ data: audioPaths }));
    // setIsButtonClicked(true);
    if (!selectedZone || selectedZone?.text === "") {
      toast.error("Please, make sure to select zone with your cursor before previewing the video");
      return;
    }
    event.stopPropagation();
    setTimeout(() => {
      handlePlaySceneScript();
    }, 200);
  };

  return (
    <Bottom onClick={handlePreviewClick}>
      <TimeLineWrapper>
        <audio
          ref={audioPlayer}
          onTimeUpdate={onPlaying}
          onLoadedMetadata={onLoadedMetadata}
          onLoadStart={onLoadStart}
        />
        <IconWrapper>
          {isFetching || audioLoading ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress color="#F0F0F3" />
            </div>
          ) : !playing || currentTime === duration ? (
            <PlayIcon />
          ) : (
            <PauseIcon />
          )}
        </IconWrapper>
        <EstimatedTime>Preview</EstimatedTime>
      </TimeLineWrapper>
    </Bottom>
  );
};

const IconWrapper = styled.div`
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    display: block;
    width: 10px;
    height: auto;
    transform: translateX(1px);
  }
`;

const Bottom = styled.div`
  position: sticky;
  bottom: 0;
  cursor: pointer;
  left: 0;
  border-radius: 10px 10px 0 0;
  background: ${({ theme }) => theme.primaryBackground};
  z-index: 10;
  /* padding: 16px 0 98px; */
  width: 100%;
`;

const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  gap: 4px;

  button {
    width: 24px;
    height: 24px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const EstimatedTime = styled.span`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.4;
`;

export default AIHumansPreviewPlayer;
