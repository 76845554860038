import PageSection from "../../../components/PageSection/PageSection";
import PlansTableDesktop from "./PlansTableDesktop";
import PlansTableMobile from "./PlansTableMobile";

const PlansSection = () => {
  return (
    <PageSection title="Compare all plans, features by features" sectionId="CompareAllPlans">
      <PlansTableDesktop />
      <PlansTableMobile />
    </PageSection>
  );
};

export default PlansSection;
