export function downloadImage(e: any, filePath: string) {
  e.preventDefault();
  e.stopPropagation();

  const link = document.createElement("a");
  link.href = filePath;
  link.target = "_blank";
  link.download = "Untitled";
  link.click();
}
