import styled from "styled-components";
import { useContext } from "react";

import UploadVideoCard from "../UploadVideoCard";

import { VideoTranslationContext } from "../../../../App";
import { CircleCheckedIconV2 } from "../../../../components/Icons/Icons";
import { advantagesData } from "../../../../mocks/videoTranslation";

const UploadVideoStep = () => {
  const { isModalOpened, setIsModalOpened } = useContext(VideoTranslationContext);

  const handleCloseModal = () => {
    setIsModalOpened(false);
  };

  return (
    <Wrapper>
      <LeftContentWrapper>
        <LeftContent>
          <Title>AI Video Translate</Title>
          <Description>
            Effortlessly translate your videos across 29 languages in seconds using a natural voice clone, authentic
            speaking style, speaker detection, and audio dubbing!
          </Description>
          <AdvantagesWrapper>
            {advantagesData.map((item) => (
              <AdvantageItem key={item.id}>
                <CircleCheckedIconV2 />
                <span>{item.text}</span>
              </AdvantageItem>
            ))}
          </AdvantagesWrapper>
        </LeftContent>
      </LeftContentWrapper>
      <RightContent>
        <UploadVideoCard />
      </RightContent>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  gap: 32px;
  margin-top: 84px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`;

const LeftContentWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 900px) {
    align-items: center;
  }
`;

const LeftContent = styled("div")`
  max-width: 500px;
`;

const RightContent = styled("div")`
  width: 100%;
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
`;

const Title = styled("div")`
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: -0.04em;
  text-align: left;
  color: ${({ theme }) => theme.primaryText};

  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

const Description = styled("div")`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.greyText};
  margin: 12px 0px 24px 0px;

  @media (max-width: 600px) {
    font-size: 14px;
    margin: 8px 0px 10px 0px;
  }
`;

const AdvantagesWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AdvantageItem = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
  }

  @media (max-width: 600px) {
    gap: 5px;
    span {
      font-size: 14px;
    }
  }
`;

export default UploadVideoStep;
