import { ReactNode } from "react";
import styled from "styled-components";

import PageSection from "../PageSection/PageSection";
import SectionSubTitle from "../TextComponents/SectionSubTitle";
import Accordion from "../Accordion/Accordion";
import AccordionSummary from "../Accordion/AccordionSummary";
import AccordionDetails from "../Accordion/AccordionDetails";

export type IFaqContent = {
  id: number;
  title: string;
  details: ReactNode;
  isList?: boolean;
};

export interface IFaqProps {
  faqContent: IFaqContent[];
  faqTitle?: ReactNode;
  faqSubtitle?: ReactNode;
  twoColumns?: boolean;
}

const FaqSingle = ({ faqContent, faqTitle, faqSubtitle, twoColumns }: IFaqProps) => {
  const middle = Math.ceil(faqContent.length / 2);
  const leftColumn = faqContent.slice(0, middle);
  const rightColumn = faqContent.slice(middle);

  return (
    <PageSection
      titleAlignMd="center"
      title={faqTitle || "FAQ"}
      maxWidth={twoColumns ? "100%" : "806px"}
      subtitle={faqSubtitle}
      subtitleAlignMd="center"
    >
      <Wrapper twoColumns={twoColumns}>
        <ColumnWrapper>
          {leftColumn.map(({ id, title, details, isList }) => (
            <Accordion key={id}>
              <AccordionSummary>
                <SummaryText>{title}</SummaryText>
              </AccordionSummary>
              <AccordionDetails>
                <SectionSubTitle subtitle={details} />
              </AccordionDetails>
            </Accordion>
          ))}
        </ColumnWrapper>
        <ColumnWrapper>
          {rightColumn.map(({ id, title, details, isList }) => (
            <Accordion key={id}>
              <AccordionSummary>
                <SummaryText>{title}</SummaryText>
              </AccordionSummary>
              <AccordionDetails>
                <SectionSubTitle subtitle={details} subtitleItemsList={isList} />
              </AccordionDetails>
            </Accordion>
          ))}
        </ColumnWrapper>
      </Wrapper>
    </PageSection>
  );
};

const Wrapper = styled.div<{ twoColumns?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  @media (min-width: 768px) {
    flex-direction: ${({ twoColumns }) => (twoColumns ? "row" : "column")};
    column-gap: 24px;
    row-gap: 12px;
  }
`;

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  @media (min-width: 768px) {
    row-gap: 12px;
  }
`;

const SummaryText = styled.h3`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #191b1f;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export default FaqSingle;
