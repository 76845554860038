export const EllipsisIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10.0016C2.5 9.08163 3.24667 8.33496 4.16667 8.33496C5.08667 8.33496 5.83333 9.08163 5.83333 10.0016C5.83333 10.9216 5.08667 11.6683 4.16667 11.6683C3.24667 11.6683 2.5 10.9216 2.5 10.0016ZM10 8.33497C9.08 8.33497 8.33333 9.08164 8.33333 10.0016C8.33333 10.9216 9.08 11.6683 10 11.6683C10.92 11.6683 11.6667 10.9216 11.6667 10.0016C11.6667 9.08164 10.92 8.33497 10 8.33497ZM15.8337 8.33497C14.9137 8.33497 14.1671 9.08164 14.1671 10.0016C14.1671 10.9216 14.9137 11.6683 15.8337 11.6683C16.7537 11.6683 17.5004 10.9216 17.5004 10.0016C17.5004 9.08164 16.7537 8.33497 15.8337 8.33497Z"
      fill="white"
    />
    <mask
      id="mask0_3413_32966"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="8"
      width="16"
      height="4"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10.0016C2.5 9.08163 3.24667 8.33496 4.16667 8.33496C5.08667 8.33496 5.83333 9.08163 5.83333 10.0016C5.83333 10.9216 5.08667 11.6683 4.16667 11.6683C3.24667 11.6683 2.5 10.9216 2.5 10.0016ZM10 8.33497C9.08 8.33497 8.33333 9.08164 8.33333 10.0016C8.33333 10.9216 9.08 11.6683 10 11.6683C10.92 11.6683 11.6667 10.9216 11.6667 10.0016C11.6667 9.08164 10.92 8.33497 10 8.33497ZM15.8337 8.33497C14.9137 8.33497 14.1671 9.08164 14.1671 10.0016C14.1671 10.9216 14.9137 11.6683 15.8337 11.6683C16.7537 11.6683 17.5004 10.9216 17.5004 10.0016C17.5004 9.08164 16.7537 8.33497 15.8337 8.33497Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3413_32966)"></g>
  </svg>
);
