export const CircleIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0001 5.33398C10.1188 5.33398 5.33344 10.1193 5.33344 16.0007C5.33344 21.882 10.1188 26.6673 16.0001 26.6673C21.8814 26.6673 26.6668 21.882 26.6668 16.0007C26.6668 10.1193 21.8814 5.33398 16.0001 5.33398ZM16.0001 29.3337C8.64808 29.3337 2.66675 23.3523 2.66675 16.0003C2.66675 8.64833 8.64808 2.66699 16.0001 2.66699C23.3521 2.66699 29.3334 8.64833 29.3334 16.0003C29.3334 23.3523 23.3521 29.3337 16.0001 29.3337Z"
      fill="white"
    />
    <mask id="mask0_1106_10815" maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0001 5.33398C10.1188 5.33398 5.33344 10.1193 5.33344 16.0007C5.33344 21.882 10.1188 26.6673 16.0001 26.6673C21.8814 26.6673 26.6668 21.882 26.6668 16.0007C26.6668 10.1193 21.8814 5.33398 16.0001 5.33398ZM16.0001 29.3337C8.64808 29.3337 2.66675 23.3523 2.66675 16.0003C2.66675 8.64833 8.64808 2.66699 16.0001 2.66699C23.3521 2.66699 29.3334 8.64833 29.3334 16.0003C29.3334 23.3523 23.3521 29.3337 16.0001 29.3337Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1106_10815)"></g>
  </svg>
);
