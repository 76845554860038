import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { getCelloToken } from "../redux/reducers/authReducer";
import { getProfile } from "../redux/reducers/profileReducer";

declare global {
  interface Window {
    cello: any;
  }
}

const PRODUCT_ID = "synthesys.io";
const SCRIPT_SRC = "https://assets.cello.so/app/latest/cello.js";

const CelloProvider = () => {
  const location = useLocation();
  const { setIsScriptAdded } = useToggleCelloScript();

  useEffect(() => {
    const allowedPaths = ["/signin", "/signup"];
    const isRestrictedPath = allowedPaths.some((path) => location.pathname.includes(path));

    setIsScriptAdded(!isRestrictedPath);
  }, [location]);

  return null;
};

const useToggleCelloScript = () => {
  const { name, email } = useSelector(getProfile);
  const nameParts = name ? name.split(" ") : [];
  const firstName = nameParts[0] || "";
  const lastName = nameParts.slice(1).join(" ") || "";

  const token = useSelector(getCelloToken);

  const [isScriptAdded, setIsScriptAdded] = useState(false);

  useEffect(() => {
    const scriptId = "cello-script";
    const celloWidgetAppId = "cello-widget-app";

    if (isScriptAdded) {
      const script = document.createElement("script");
      script.type = "module";
      script.id = scriptId;
      script.src = SCRIPT_SRC;
      script.async = true;
      script.onload = () => {
        window.cello = window.cello || { cmd: [] };
        window.cello.cmd.push((cello: Record<string, any>) =>
          cello.boot({
            productId: PRODUCT_ID,
            token,
            hideDefaultLauncher: true,
            productUserDetails: {
              firstName,
              lastName,
              fullName: name,
              email,
            },
          }),
        );
      };
      document.head.appendChild(script);

      return () => {
        if (document.getElementById(scriptId)) {
          document.getElementById(scriptId)?.remove();
        }
      };
    } else {
      if (document.getElementById(scriptId)) {
        document.getElementById(scriptId)?.remove();
      }
    }
  }, [isScriptAdded]);

  return {
    setIsScriptAdded,
  };
};

export default CelloProvider;
