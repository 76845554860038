import React from "react";
import styled from "styled-components";
import SidebarLayout from "../../layouts/SidebarLayout";
import AssetsTable, { AssetType } from "./components/AssetsTable";
import Sidebar from "../../components/Sidebar/Sidebar";

const assets = [
  {
    id: "1",
    name: "Identity",
    type: AssetType.voiceOver,
    duration: "6h 2m",
  },
  {
    id: "2",
    name: "Banking Details",
    type: AssetType.image,
  },
  {
    id: "3",
    name: "Asset Details",
    type: AssetType.video,
    duration: "1h 30m",
  },
  {
    id: "4",
    name: "Personal Details",
    type: AssetType.video,
    duration: "2h 33m",
  },
];

const AssetsPage = () => {
  return (
    <Wrapper>
      <SidebarLayout>
        <MobileOnly>
          <Sidebar mobile />
        </MobileOnly>
        <Title>Assets</Title>
        <AssetsTable assets={assets} />
      </SidebarLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-family: Mulish;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
  margin-bottom: 16px;
`;

export default AssetsPage;
