import React from "react";
import Modal from "../Modal/Modal";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button from "../Button/Button";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ChangedSubscriptionPopup = ({ onClose, open }: Props) => {
  return (
    <Modal open={open} onClose={onClose} maxWidth={630} maxHeight={260}>
      <Wrapper>
        <TopWrapper>
          <Title>Your subscription has been changed successfully</Title>
          <Subtitle>The plan change will take effect from the next billing cycle</Subtitle>
        </TopWrapper>
        <Actions>
          <Button text="I understand" onClick={onClose} />
        </Actions>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 542px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;

  & button {
    width: 100%;
  }
`;

export default ChangedSubscriptionPopup;
