import React, { useContext } from "react";
import styled from "styled-components";

import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import MenuItem from "../../../components/ActionMenu/MenuItem";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { OptionsIcon } from "../../../components/Icons/Icons";
import { VideoEditorContext } from "../../../App";
import { ScreenProps } from "../../../mocks/humans";
import { Scene } from "../../../types/scene";

interface Props {
  menuOpen?: number;
  sceneId: number;
  activeScreen: ScreenProps;
  handleCloseMenu: () => void;
  handleOpenMenu: (sceneId: number) => void;
  handleAddScene: (event: React.MouseEvent<HTMLElement>) => void;
  handleDuplicateScene: (sceneId: number) => void;
  handleDeleteScene: (event: React.MouseEvent<HTMLElement>) => void;
  duplicateParagraph: (index: number) => void;
  scenes: Scene[];
}

const SceneActionMenu = ({
  menuOpen,
  sceneId,
  activeScreen,
  handleCloseMenu,
  handleOpenMenu,
  handleAddScene,
  handleDuplicateScene,
  handleDeleteScene,
  duplicateParagraph,
  scenes,
}: Props) => {
  const handleAddSceneChange = (event: React.MouseEvent<HTMLElement>) => {
    handleAddScene(event);
    handleCloseMenu();
  };

  const handleDublicateSceneChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    const duplicatedIndex = scenes.findIndex((scene) => scene.id === sceneId);

    handleDuplicateScene(sceneId);
    duplicateParagraph(duplicatedIndex);
    handleCloseMenu();
  };

  const handleDeleteSceneChange = (event: React.MouseEvent<HTMLElement>) => {
    handleDeleteScene(event);
    handleCloseMenu();
  };

  return (
    <ActionMenu
      position={{
        x: "left",
        y: "bottom",
      }}
      activeScreen={activeScreen}
      open={menuOpen === sceneId}
      handleClose={handleCloseMenu}
      trigger={
        <MenuButtonWrapper>
          <IconButton
            iconButtonTheme={IconButtonThemes.Primary}
            icon={<OptionsIcon />}
            onClick={(event) => {
              event.stopPropagation();
              handleOpenMenu(sceneId);
            }}
          />
        </MenuButtonWrapper>
      }
    >
      <MenuWrapper>
        <MenuItem onClick={handleAddSceneChange}>Add Scene</MenuItem>
        <MenuItem onClick={handleDublicateSceneChange}>Duplicate</MenuItem>
        <MenuItem onClick={handleDeleteSceneChange}>Delete</MenuItem>
      </MenuWrapper>
    </ActionMenu>
  );
};

const MenuButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  & > button {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: none;
  }
`;

const MenuWrapper = styled.div`
  /* width: 115px; */
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export default SceneActionMenu;
