import React, { useState } from "react";
import styled, { css } from "styled-components";

interface AccordionProps {
  children: React.ReactNode;
  active?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({ children, ...props }) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <AccordionWrapper active={active} onClick={handleClick} {...props}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, {
          ...props,
          active: active,
        }),
      )}
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  background: #f0f0f3;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 5px 5px 10px 0px rgba(174, 174, 192, 0.3), -5px -5px 10px 0px #fff, 2px 2px 4px 0px #fff inset,
    -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(354deg, rgba(0, 99, 180, 1) 16%, rgba(0, 154, 247, 0.3) 83%);
    border-radius: 16px;
    opacity: 0;
    transition: opacity 0.4s;
  }

  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background: #f0f0f3;
    box-shadow: 2px 2px 4px 0px #fff inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset;
    border-radius: 14px;
    transition: box-shadow 0.4s;
  }

  ${({ active }) =>
    active &&
    css`
      &:before {
        opacity: 1;
      }

      &:after {
        box-shadow: 2px 2px 4px 0px transparent inset, -2px -2px 4px 0px transparent inset;
      }
    `}

  & * {
    z-index: 100;
  }

  @media (max-width: 768px) {
    padding: 18px 16px;
  }
`;

export default Accordion;
