import React, { useContext, useState } from "react";
import styled from "styled-components";
import AvatarLayers from "./AvatarLayers";
import AvatarType from "./AvatarType";
import DelerteAvatar from "./DelerteAvatar";
import { ActorPositionTypes, Avatar, ObjectTypes, SceneObject } from "../../../../../types/scene";
import { VideoEditorContext } from "../../../../../App";
import AvatarBackground from "./AvatarBackground";
import AvatarFaceswap from "./AvatarFaceswap";

const HumatarActions = () => {
  const { currentScene, handleSceneObjectChange, handleDeleteCurrentObject } = useContext(VideoEditorContext);

  const currentObject = currentScene?.objects.find(
    (obj: SceneObject) => obj.object.id === currentScene.activeObjectId && obj.type === ObjectTypes.avatars,
  )?.object as Avatar | undefined;

  return currentObject ? (
    <Wrapper>
      <AvatarType
        currentObject={currentObject}
        handleSceneObjectChange={handleSceneObjectChange}
        handleDeleteCurrentObject={handleDeleteCurrentObject}
      />
      <AvatarFaceswap currentObject={currentObject} />
      {currentObject.actorPositionType === ActorPositionTypes.Circle && currentObject && (
        <AvatarBackground currentObject={currentObject} handleSceneObjectChange={handleSceneObjectChange} />
      )}
      <AvatarLayers />
      <DelerteAvatar />
    </Wrapper>
  ) : (
    <div />
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export default HumatarActions;
