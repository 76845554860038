import React, { useContext, useState } from "react";
import styled from "styled-components";
import ShapeColor from "./ShapeColor";
import ShapeBorder from "./ShapeBorder";
import ShapeOpacity from "./ShapeOpacity";
import BorderColor from "./BorderColor";
import ShapeLayers from "./ShapeLayers";
import CopyShape from "./CopyShape";
import DeleteShape from "./DeleteShape";
import { VideoEditorContext } from "../../../../../App";
import { ObjectTypes, SceneObject, Shape, ShapeTypes } from "../../../../../types/scene";

const ShapesActions = () => {
  const { currentScene, handleSceneObjectChange } = useContext(VideoEditorContext);

  const [borderWidth, setBorderWidth] = useState(0);

  const currentObject = currentScene?.objects.find(
    (obj: SceneObject) => obj.object.id === currentScene.activeObjectId && obj.type === ObjectTypes.shapes,
  )?.object as Shape | undefined;

  const isWithBorder =
    currentObject?.shape !== ShapeTypes.arrow &&
    currentObject?.shape !== ShapeTypes.triangle &&
    currentObject?.shape !== ShapeTypes.custom &&
    currentObject?.shape !== ShapeTypes.customSvg;

  return currentObject ? (
    <Wrapper>
      {currentObject?.shape !== ShapeTypes.custom && (
        <ShapeColor handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} />
      )}
      {isWithBorder && (
        <ShapeBorder
          handleSceneObjectChange={handleSceneObjectChange}
          currentObject={currentObject}
          borderWidth={borderWidth}
          setBorderWidth={setBorderWidth}
        />
      )}
      <ShapeOpacity handleSceneObjectChange={handleSceneObjectChange} currentObject={currentObject} />
      {isWithBorder && (
        <BorderColor
          currentObject={currentObject}
          handleSceneObjectChange={handleSceneObjectChange}
          setBorderWidth={setBorderWidth}
        />
      )}
      <ShapeLayers />
      <CopyShape currentObject={currentObject} />
      <DeleteShape />
    </Wrapper>
  ) : (
    <div />
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export default ShapesActions;
