import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import Textfield from "../Textfield/Textfield";
import { ImportIcon, SearchIcon } from "../Icons/Icons";
import {
  getCurrentPageTemplates,
  getHasMoreTemplates,
  getTemplates,
  getTemplatesIsEmpty,
  getTemplatesLoading,
  importTemplateLoading,
  searchResultLoading,
} from "../../redux/reducers/templateReducer";
import {
  clearTemplates,
  getTemplatesServer,
  importTemplateServer,
  searchTemplatesResultLoading,
} from "../../redux/actions/templateAction";
import { getProjectServer } from "../../redux/actions/humansProjectActions";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import CircularProgress from "../Icons/CircularProgress";

const ACCEPTED_FORMATS = "application/pdf";

interface Props {
  open: boolean;
  onClose: () => void;
}

const searchTimeoutDelay = 500;

const TemplatesPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const templates = useSelector(getTemplates);
  const templatesLoading = useSelector(getTemplatesLoading);
  const templatesIsEmpty = useSelector(getTemplatesIsEmpty);
  const isImportTemplate = useSelector(importTemplateLoading);
  const hasMore = useSelector(getHasMoreTemplates);
  const currentPage = useSelector(getCurrentPageTemplates);
  const searchLoading = useSelector(searchResultLoading);
  const [search, setSearch] = useState("");
  const [activeTemplate, setActiveTemplate] = useState<number>();

  const handleActiveTemplate = (id: number) => setActiveTemplate(id);
  const template: any = templates.find(({ projectId }) => projectId === activeTemplate);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const debounceHandleGetTemplates = useRef(
    debounce(async (search?: string) => {
      dispatch(
        getTemplatesServer({
          keyword: search,
          pageNumber: 1,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const addTemplate = () => {
    if (!activeTemplate) {
      toast.error("Choose a template");
      return;
    }

    dispatch(getProjectServer(parseInt(template.projectId), true));
    onClose();
  };

  const handleClose = () => {
    navigate("/my-projects?filter=ai-humans");
    onClose();
  };

  const fetchMore = (search: string) => {
    dispatch(
      getTemplatesServer({
        keyword: search,
        pageNumber: currentPage + 1,
      }),
    );
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("File", uploadedFile);

    dispatch(importTemplateServer({ formData }));
  };

  useEffect(() => {
    dispatch(searchTemplatesResultLoading(true));
    dispatch(clearTemplates());
    debounceHandleGetTemplates(search);
  }, [search]);

  return (
    <>
      <Modal className="templates" open={open} onClose={handleClose} closeIcon={false} maxWidth={1276} maxHeight={704}>
        <Wrapper>
          <Heading>
            <Title>Templates</Title>
            <Actions>
              <div>
                <Button buttonTheme={ButtonThemes.Secondary} icon={<ImportIcon />} text="Import PDF" />
                <input type="file" id="template" name="template" accept={ACCEPTED_FORMATS} onChange={handleUpload} />
              </div>
              <Textfield
                value={search}
                placeholder="Search by template title..."
                startAdornment={<SearchIcon />}
                onChange={handleSearchChange}
              />
            </Actions>
          </Heading>
          <TemplatesWrapper id="scrollableDiv">
            <InfiniteScroll
              next={() => !searchLoading && fetchMore(search)}
              hasMore={hasMore}
              loader={templatesLoading ? <CardsLoader /> : null}
              dataLength={templates?.length}
              endMessage={templatesIsEmpty && <div>Templates not found</div>}
              style={{ display: "contents" }}
              scrollableTarget="scrollableDiv"
            >
              {templates &&
                templates.map(({ coverImage, projectId, title }) => (
                  <TemplateCard key={projectId} onClick={() => handleActiveTemplate(projectId)}>
                    <TemplateImage src={coverImage} active={projectId === activeTemplate} alt={title} />
                    <TemplateTitle>{title}</TemplateTitle>
                  </TemplateCard>
                ))}
            </InfiniteScroll>
          </TemplatesWrapper>
          <ActionsWrapper>
            <Button text="Cancel" buttonTheme={ButtonThemes.Outline} onClick={handleClose} />
            <Button text="Add" onClick={addTemplate} />
          </ActionsWrapper>
        </Wrapper>
      </Modal>
      {isImportTemplate && (
        <Overlay>
          <CircularProgressWrapper>
            <CircularProgress color="#009af7" />
            <span>Please wait, the template is being imported</span>
          </CircularProgressWrapper>
        </Overlay>
      )}
    </>
  );
};

const CardsLoader = () => {
  return (
    <>
      <CardLoader />
      <CardLoader />
      <CardLoader />
    </>
  );
};

const CardLoader = () => (
  <TemplateCardLoader>
    <TemplateImageLoader />
    <TemplateTitleLoader />
  </TemplateCardLoader>
);

const TemplateCardLoader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 16px;
  margin-right: 4px;
  margin-bottom: 8px;
`;

const TemplateImageLoader = styled.div`
  width: 100%;
  height: 123px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.characterCardShadow};
  border-radius: 16px;
`;

const TemplateTitleLoader = styled.div`
  width: 100%;
  height: 14px;
  border-radius: 16px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.characterCardShadow};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  overflow: hidden auto;
  position: relative;
`;

const Heading = styled.div`
  display: flex;

  @media (max-width: 1100px) {
    column-gap: 20px;
    align-items: center;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  color: ${({ theme }) => theme.primaryText};
  flex: 1;

  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
  }
`;

const Actions = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;

  & > div:first-of-type {
    position: relative;

    button {
      width: auto;
      gap: 8px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type="file"],
    input[type="file"]::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    flex-direction: row-reverse;
    column-gap: 16px;
    width: 100%;
    justify-content: start;

    & > div:last-of-type {
      width: 100%;
    }
  }
`;

const TemplatesWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  overflow: hidden auto;
  height: 100%;
  min-height: 252px;
  max-height: 500px;
  margin-top: 18px;
  padding-right: 6px;
  gap: 10px;
  display: grid;
  align-content: start;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
    display: flex;
    padding-top: 50px;
    align-items: start;
    justify-content: center;
  `}

  @media (min-width: 1270px) {
    grid-template-columns: repeat(5, 215px);
  }

  @media (min-width: 990px) and (max-width: 1269px) {
    grid-template-columns: repeat(4, 216px);
    justify-content: space-between;
  }
  @media (min-width: 950px) and (max-width: 1309px) and (max-height: 860px), (max-height: 800px) {
    max-height: 58vh;
  }

  @media (max-height: 800px) {
    max-height: 58vh;
  }

  @media (max-height: 660px) {
    grid-template-columns: repeat(3, 216px);
  }

  @media (max-width: 989px) {
    grid-template-columns: repeat(2, 49%);
  }

  @media (max-width: 949px) and (max-height: 860px) {
    max-height: 58vh;
  }
  @media (max-width: 735px) {
    max-height: 65vh;
    margin-top: 0;
  }

  @media (max-width: 735px) and (max-height: 660px) {
    max-height: 58vh;
    margin-top: 0;
  }

  @media (max-width: 515px) {
    position: inherit;
    max-height: 71vh;
    margin-top: 0;
  }
  @media (max-width: 515px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 515px) and (max-height: 760px) {
    max-height: 62vh;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const TemplateCard = styled.div`
  display: flex !important;
  flex-direction: column;
  height: 160px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  &:last-child {
    margin-bottom: 110px;
  }
`;

const TemplateImage = styled.img<{ active: boolean }>`
  min-height: 123px;
  max-height: 123px;
  width: 100%;
  object-fit: contain;
  border-radius: 16px;
  border: 2px solid transparent;

  ${({ active, theme }) =>
    active &&
    `
      border: 2px solid ${theme.activeMenu};
    `}
`;

const TemplateTitle = styled.span`
  margin-top: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.primaryText};
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.characterActionsBackground};
  height: 100px;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 10;

  button {
    width: 161.5px;
  }

  @media (max-width: 515px) and (max-height: 760px) {
    height: 80px;
    width: 99%;
  }

  @media (min-width: 950px) and (max-height: 720px) {
    height: auto;

    button {
      width: 100px;
      max-height: 48px;

      & > span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const Error = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  flex: 1;

  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};

    @media (max-width: 600px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default TemplatesPopup;
