import React, { useContext, useEffect, useState } from "react";
import { Rnd } from "react-rnd";

import { getTranslateValues, removePx } from "../../../lib/stylesHelper";

import { ObjectTypes, ResizeDragObj } from "../../../types/scene";
import { VideoEditorContext } from "../../../App";
import { ScreenProps } from "../../../mocks/humans";

interface Props {
  index: number;
  children: React.ReactNode;
  objType: ObjectTypes;
  lockRatio?: boolean;
  dragHandleClassName?: string;
  nodeId?: string;
}

const DragResizeWrapper = ({
  index,
  id,
  position,
  size,
  children,
  objType,
  lockRatio,
  dragHandleClassName,
  nodeId,
}: ResizeDragObj & Props) => {
  const { currentScene, updatePosition, updateSize, handleChangeActiveObject } = useContext(VideoEditorContext);
  const { activeObjectId, editableTextId } = currentScene;

  return (
    <Rnd
      onClick={(event: any) => event.stopPropagation()}
      dragHandleClassName={dragHandleClassName}
      default={{
        x: position.x / size.width,
        y: position.y / size.height,
        ...size,
      }}
      onDragStop={(e, d) => {
        updatePosition({ id, objType, position: { x: d.x, y: d.y } });
      }}
      onDrag={() => {
        handleChangeActiveObject(NaN);
      }}
      onResizeStop={(e, direction, ref) => {
        const [x, y] = getTranslateValues(ref.style.transform);
        updateSize({
          size: { width: +removePx(ref.style.width), height: +removePx(ref.style.height) },
          position: { x, y },
          id,
          objType,
        });
      }}
      size={size}
      position={position}
      bounds={
        objType === ObjectTypes.avatars || objType === ObjectTypes.shapes ? `#avatar-scene-bounds${id}` : "parent"
      }
      enableResizing={activeObjectId === id && editableTextId !== id}
      disableDragging={editableTextId === id}
      style={{
        zIndex: index + 10,
        pointerEvents: objType === ObjectTypes.texts && activeObjectId !== id ? "none" : "auto",
      }}
      lockAspectRatio={lockRatio}
      id={nodeId}
    >
      {children}
    </Rnd>
  );
};

export default DragResizeWrapper;
