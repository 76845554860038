export const NvidiaLogoIcon = () => (
  <svg width="72" height="24" viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M71.0717 15.7147V15.5162H71.1992C71.271 15.5162 71.3666 15.5218 71.3666 15.6063C71.3666 15.6979 71.3108 15.7147 71.2311 15.7147H71.0717ZM71.0717 15.8541H71.1594L71.3506 16.2008H71.5658L71.3506 15.8398C71.4622 15.8318 71.5578 15.7776 71.5578 15.6254C71.5578 15.4365 71.4303 15.376 71.2072 15.376H70.8885V16.2008H71.0717V15.8541ZM71.9961 15.7896C71.9961 15.3051 71.6216 15.0238 71.1992 15.0238C70.7769 15.0238 70.4023 15.3051 70.4023 15.7896C70.4023 16.2741 70.7769 16.5562 71.1992 16.5562C71.6216 16.5562 71.9961 16.2741 71.9961 15.7896ZM71.773 15.7896C71.773 16.1426 71.51 16.3793 71.1992 16.3793V16.3769C70.8885 16.3793 70.6334 16.1426 70.6334 15.7896C70.6334 15.4365 70.8885 15.2007 71.1992 15.2007C71.51 15.2007 71.773 15.4365 71.773 15.7896Z"
      fill="#6F7074"
    />
    <path
      d="M42.7877 7.58967L42.7885 16.2853H45.2446V7.58967H42.7877ZM23.4688 7.57772V16.2853H25.9463V9.67354L27.866 9.67434C28.5019 9.67434 28.9545 9.83291 29.2613 10.1596C29.6494 10.5732 29.808 11.241 29.808 12.4619V16.2853H32.2082V11.4745C32.2082 8.0407 30.02 7.57772 27.8787 7.57772H23.4688ZM46.7419 7.59046V16.2853H50.7248C52.8469 16.2853 53.5394 15.9323 54.2893 15.141C54.8184 14.5856 55.1603 13.3663 55.1603 12.0331C55.1603 10.8115 54.871 9.72055 54.3658 9.0416C53.4557 7.82714 52.1448 7.59046 50.1885 7.59046H46.7419ZM49.178 9.48308H50.2339C51.7655 9.48308 52.756 10.1716 52.756 11.9558C52.756 13.7417 51.7655 14.4294 50.2339 14.4294H49.178V9.48308ZM39.2472 7.59046L37.1976 14.4812L35.2332 7.59046H32.5828L35.387 16.2853H38.9268L41.7534 7.59046H39.2472ZM56.303 16.2853H58.759V7.59046H56.303V16.2853ZM63.1873 7.59286L59.7583 16.2821H62.1801L62.7227 14.7465H66.7773L67.2953 16.2821H69.925L66.4665 7.59286H63.1873ZM64.7819 9.17867L66.2673 13.2492H63.2471L64.7819 9.17867Z"
      fill="#6F7074"
    />
    <path
      d="M7.74389 9.29843V8.05762C7.86409 8.0491 7.98571 8.04248 8.1097 8.03869C11.5027 7.93222 13.7283 10.9542 13.7283 10.9542C13.7283 10.9542 11.3243 14.2933 8.74666 14.2933C8.37565 14.2933 8.04297 14.2332 7.74389 14.1329V10.3712C9.06467 10.5307 9.33015 11.1142 10.1242 12.4378L11.8903 10.9486C11.8903 10.9486 10.6012 9.25773 8.42818 9.25773C8.19156 9.25773 7.96536 9.27429 7.74389 9.29843ZM7.74389 5.20028V7.05344C7.86551 7.0435 7.9876 7.03593 8.1097 7.03167C12.8282 6.87266 15.9019 10.9008 15.9019 10.9008C15.9019 10.9008 12.3711 15.1944 8.69271 15.1944C8.35577 15.1944 8.04013 15.1631 7.74389 15.1106V16.2563C7.99707 16.2885 8.26018 16.3074 8.53418 16.3074C11.957 16.3074 14.4325 14.5593 16.8299 12.4903C17.2269 12.8088 18.8538 13.5826 19.1889 13.9219C16.9094 15.8299 11.5979 17.3679 8.58671 17.3679C8.29662 17.3679 8.01742 17.3504 7.74389 17.3239V18.9338H20.7538V5.20028H7.74389ZM7.74389 14.1329V15.1106C4.57753 14.5465 3.69875 11.2552 3.69875 11.2552C3.69875 11.2552 5.21922 9.57101 7.74389 9.29843V10.3712C7.74153 10.3712 7.74058 10.3708 7.73869 10.3708C6.41412 10.2118 5.3787 11.4492 5.3787 11.4492C5.3787 11.4492 5.95888 13.5333 7.74389 14.1329ZM2.12053 11.1128C2.12053 11.1128 3.99688 8.34392 7.74389 8.05762V7.05344C3.59369 7.38612 0 10.9008 0 10.9008C0 10.9008 2.03535 16.7853 7.74389 17.3239V16.2563C3.55488 15.7291 2.12053 11.1128 2.12053 11.1128Z"
      fill="#6F7074"
    />
  </svg>
);
