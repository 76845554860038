import { FacebookIcon, LinkedInIcon, MailIcon } from "../components/Icons/Icons";
import { IPages, pages } from "../lib/pricingRoutes";
import { IExternalLinks } from "../lib/routeUtils";

export const footerSocialMocks = [
  {
    id: 1,
    icon: <FacebookIcon />,
    href: IExternalLinks.facebook,
    title: "Facebook",
  },
  {
    id: 2,
    icon: <LinkedInIcon />,
    href: IExternalLinks.linkedin,
    title: "LinkedIn",
  },
  {
    id: 3,
    icon: <MailIcon />,
    href: IExternalLinks.eMail,
    title: "Mail",
    isMail: true,
  },
];

export const footerProductsLinks = [
  {
    id: 1,
    label: "AI Voices",
    href: pages[IPages.voice](),
  },
  {
    id: 2,
    label: "AI Humans",
    href: pages[IPages.video](),
  },
  {
    id: 3,
    label: "AI Image",
    href: pages[IPages.image](),
  },
  {
    id: 5,
    label: "Сustom AI avatar",
    href: pages[IPages.customAvatar](),
  },
  {
    id: 6,
    label: "AI Voice Cloning",
    href: pages[IPages.voiceCloning](),
  },
  {
    id: 7,
    label: "Synthesys X",
    href: IExternalLinks.xPage,
  },
];

export const footerUseCases = [
  {
    id: 1,
    label: "Create AI Video Ads",
    href: pages[IPages.videoAds](),
  },
  {
    id: 2,
    label: "Create AI Branding Videos",
    href: pages[IPages.brandingVideos](),
  },
  {
    id: 3,
    label: "Create AI Explainer Videos",
    href: pages[IPages.explainerVideos](),
  },
  {
    id: 4,
    label: "Create AI Training Videos",
    href: pages[IPages.trainingVideos](),
  },
  {
    id: 5,
    label: "Create AI Education Videos",
    href: pages[IPages.educationalVideos](),
  },
  {
    id: 6,
    label: "Create AI Presentation Videos",
    href: pages[IPages.presentationVideos](),
  },
  {
    id: 7,
    label: "Create AI E-learning Voiceover",
    href: pages[IPages.eLearning](),
  },
  {
    id: 8,
    label: "Create AI Advertising Voiceovers",
    href: pages[IPages.voiceoverAds](),
  },
  {
    id: 9,
    label: "Create AI Voiceover for YouTube Videos",
    href: pages[IPages.voiceoverYouTube](),
  },
  {
    id: 10,
    label: "Create AI Voiceover Animation",
    href: pages[IPages.voiceoverAnimation](),
  },
  {
    id: 11,
    label: "Create AI Voiceover Podcast",
    href: pages[IPages.voiceoverPodcast](),
  },
];

export const footerCompanyLinks = [
  {
    id: 1,
    label: "About Us",
    href: pages[IPages.about](),
  },
  {
    id: 2,
    label: "Pricing",
    href: pages[IPages.pricing](),
  },
  {
    id: 3,
    label: "API",
    href: pages[IPages.api](),
  },
  {
    id: 4,
    label: "Contact us",
    href: "mailto:support@synthesys.io",
  },
  {
    id: 5,
    label: "Blog",
    href: IExternalLinks.blog,
  },
  {
    id: 6,
    label: "Affiliates / Referrals",
    href: IExternalLinks.affiliatesReferrals,
  },
  {
    id: 7,
    label: "Privacy Policy",
    href: pages[IPages.privacyPolicy](),
  },
  {
    id: 8,
    label: "Terms of Use",
    href: pages[IPages.terms](),
  },
  {
    id: 9,
    label: "Refund Policy",
    href: pages[IPages.refundPolicy](),
  },
  {
    id: 10,
    label: "Ethics",
    href: pages[IPages.ethics](),
  },
];

export const footerFeaturesLinks = [
  {
    id: 1,
    label: "Use Cases",
    href: pages[IPages.useCases](),
  },
  {
    id: 2,
    label: "AI Avatars",
    href: pages[IPages.avatars](),
  },
  {
    id: 3,
    label: "Languages",
    href: pages[IPages.languageListing](),
  },
];
