import styled, { css } from "styled-components";

interface ILineBreakProps {
  firstBreak: string;
  removeBreak?: string;
  secondBreak?: string;
}

const LineBreak = ({ firstBreak, removeBreak, secondBreak }: ILineBreakProps) => (
  <Break firstBreak={firstBreak} removeBreak={removeBreak} secondBreak={secondBreak} />
);

const Break = styled.span<{ firstBreak: string; removeBreak?: string; secondBreak?: string }>`
  ${({ firstBreak }) => `@media screen and (min-width: ${firstBreak}px)`} {
    content: " ";
    display: block;
  }

  ${({ removeBreak }) => removeBreak && `@media screen and (min-width: ${removeBreak}px)`} {
    ${({ removeBreak }) =>
      removeBreak &&
      css`
        content: " ";
        display: inline;
      `}
  }

  ${({ secondBreak }) => secondBreak && `@media screen and (min-width: ${secondBreak}px)`} {
    ${({ secondBreak }) =>
      secondBreak &&
      css`
        content: " ";
        display: block;
      `}
  }
`;

export default LineBreak;
