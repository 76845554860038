export const VideoCursorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="71" viewBox="0 0 12 71" fill="none">
    <line x1="6" y1="3" x2="6" y2="71" stroke="url(#paint0_linear_1_7418)" strokeWidth="2" />
    <path
      d="M4.26795 9C5.03775 10.3333 6.96225 10.3333 7.73205 9L9.4641 6C10.2339 4.66667 9.27165 3 7.73205 3H4.26795C2.72835 3 1.7661 4.66667 2.5359 6L4.26795 9Z"
      fill="url(#paint1_linear_1_7418)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_7418"
        x1="4.86923"
        y1="17.6462"
        x2="3.64717"
        y2="17.6601"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_7418"
        x1="2.58462"
        y1="10.4308"
        x2="9.69231"
        y2="1.29231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
