export const PopupPrevIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4541 11.5001C14.0171 11.5001 17.2311 13.1341 19.2641 15.7591C18.3291 11.8901 14.7341 9.00015 10.4541 9.00015C9.90113 9.00015 9.45413 8.55315 9.45413 8.00015V6.07715L4.63013 10.2511L9.45413 14.4631V12.5001C9.45413 11.9471 9.90113 11.5001 10.4541 11.5001ZM20.2211 21.0005C19.7601 21.0005 19.3581 20.6855 19.2491 20.2375C18.3591 16.5885 15.2281 13.9525 11.4541 13.5535V14.6745C11.4541 15.3805 11.0351 16.0095 10.3611 16.3145C9.63012 16.6475 8.77712 16.5275 8.18412 16.0105L3.11812 11.5865C2.72512 11.2425 2.50012 10.7555 2.50012 10.2505C2.50012 9.74547 2.72512 9.25847 3.11812 8.91447L8.18412 4.49047C8.77712 3.97347 9.63012 3.85347 10.3611 4.18647C11.0351 4.49147 11.4541 5.12047 11.4541 5.82647V7.04447C17.0781 7.53747 21.5001 12.1505 21.5001 17.7505C21.5001 18.5605 21.3961 19.3965 21.1921 20.2365C21.0841 20.6855 20.6831 21.0005 20.2211 21.0005Z"
      fill="url(#paint0_linear_2_8768)"
    />
    <mask id="mask0_2_8768" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="4" width="20" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4541 11.5001C14.0171 11.5001 17.2311 13.1341 19.2641 15.7591C18.3291 11.8901 14.7341 9.00015 10.4541 9.00015C9.90113 9.00015 9.45413 8.55315 9.45413 8.00015V6.07715L4.63013 10.2511L9.45413 14.4631V12.5001C9.45413 11.9471 9.90113 11.5001 10.4541 11.5001ZM20.2211 21.0005C19.7601 21.0005 19.3581 20.6855 19.2491 20.2375C18.3591 16.5885 15.2281 13.9525 11.4541 13.5535V14.6745C11.4541 15.3805 11.0351 16.0095 10.3611 16.3145C9.63012 16.6475 8.77712 16.5275 8.18412 16.0105L3.11812 11.5865C2.72512 11.2425 2.50012 10.7555 2.50012 10.2505C2.50012 9.74547 2.72512 9.25847 3.11812 8.91447L8.18412 4.49047C8.77712 3.97347 9.63012 3.85347 10.3611 4.18647C11.0351 4.49147 11.4541 5.12047 11.4541 5.82647V7.04447C17.0781 7.53747 21.5001 12.1505 21.5001 17.7505C21.5001 18.5605 21.3961 19.3965 21.1921 20.2365C21.0841 20.6855 20.6831 21.0005 20.2211 21.0005Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2_8768)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_2_8768"
        x1="6.59243"
        y1="6.22314"
        x2="16.3344"
        y2="20.2218"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
