// import styled from "styled-components";
import { PlanCardProps } from "../modules/PricingPage2/components/PlanCard";

// const StrongText = styled.span`
//   font-weight: 700;
// `;

// const Wrapper = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 6px;
//   font-weight: 700;
// `;

// const NewWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: 600;
//   font-size: 10px;
//   line-height: 12px;
//   color: #ffffff;
//   background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
//   border: 1px solid #f0f0f3;
//   border-radius: 5px;
//   padding: 2px 6px;
// `;

// const NewLabel = styled.span`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   left: calc(100% + 10px);
//   display: flex;
//   padding: 0px 10px;
//   justify-content: center;
//   align-items: center;
//   border-radius: 10px;
//   background: linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%);
//   color: #ffffff;

//   font-size: 12px;
//   font-weight: 500;
//   line-height: 1.66;
// `;

export const planCardsMocks: PlanCardProps[] = [
  {
    plan: "FREE",
    greatFor: "Great For Personal Use",
    isLimited: false,
    yearly: {
      oldPrice: "",
      newPrice: "0",
      trigger: "",
    },
    monthly: {
      oldPrice: "",
      newPrice: "0",
      trigger: "",
    },
    yearlyBilled: "",
    list: [
      {
        text: "Access to 4 tools (AI Voices, AI Humans, AI Image and AI Video)",
        tooltip: "",
      },
      {
        text: "2 minutes of free audio/video generation",
        tooltip: "12 credits where 1 credit = 10 seconds of audio/video generation",
      },
      {
        text: "50 Synthesys AI Image generations in total",
        tooltip: "",
      },
      {
        text: "3 Slides in each video of AI humans",
        tooltip: "",
      },
      {
        text: "2 image generation per request",
        tooltip: "",
      },
      {
        text: "Voice cloning 1 voice",
        tooltip: "",
      },
      {
        text: "Synthesys X",
        tooltip: "",
      },
      {
        text: "Non Commercial",
        tooltip: "",
      },
    ],
  },
  {
    plan: "PERSONAL",
    greatFor: "Great For Personal Use ",
    isLimited: false,
    yearly: {
      oldPrice: "",
      newPrice: "20",
      trigger: "#pkmodale895f1f871ddfd22553c80726188c551df2b4294",
    },
    monthly: {
      oldPrice: "",
      newPrice: "29",
      trigger: "#pkmodal029b7f78b3903a8fa53c0f93a4412dcf5844896c",
    },
    yearlyBilled: "243",
    list: [
      {
        text: "Access to 4 tools (AI Voices, AI Humans, AI Image and AI Video) ",
        tooltip: "",
      },
      {
        text: "15 Mins of Audio/Video Generation per month",
        tooltip: "90 credits monthly where 1 credit = 10 seconds of audio/video generation with daily fair use",
      },
      {
        text: "100 Image Generations per month",
        tooltip: "",
      },
      {
        text: "6 slides in each video",
        tooltip: "1 slide equals to 1 minute of content. Maximum length of a video is 6 minutes",
      },
      {
        text: "Relax mode - Low priority rendering",
        tooltip: "Relax mode - Low priority rendering",
      },
      {
        text: "Voice cloning 2 Voices Pro",
        tooltip: "",
      },
      {
        text: "Personal License",
        tooltip: "",
      },
    ],
  },
  {
    plan: "CREATOR",
    greatFor: "Great For Small Teams",
    isLimited: true,
    yearly: {
      oldPrice: "99",
      newPrice: "41",
      trigger: "#pkmodal61112bdff3e11665c3400c2207075076526de8ba",
    },
    monthly: {
      oldPrice: "99",
      newPrice: "59",
      trigger: "#pkmodal7ff056c2adcd0049d1257aedd48d92b37950df38",
    },
    yearlyBilled: "495",
    list: [
      {
        text: "Access to 4 tools (AI Voices, AI Humans, AI Image and AI Video)",
        tooltip: "",
      },
      {
        text: "40 Mins of Audio/Video Generation per month",
        tooltip: "240 credits monthly where 1 credit = 10 seconds of audio/video generation with daily fair use",
      },
      {
        text: "250 image generations per month",
        tooltip: "",
      },
      {
        text: "6 slides in each video",
        tooltip: "1 slide equals to 1 minute of content. Maximum length of a video is 6 minutes",
      },
      {
        text: "Relax mode - Low priority rendering",
        tooltip: "Relax mode - Low priority rendering",
      },
      {
        text: "Voice cloning 5 Voices Pro",
        tooltip: "",
      },
      {
        text: "Commercial License",
        tooltip: "",
      },
    ],
  },
  {
    plan: "BUSINESS UNLIMITED",
    greatFor: "Great For Big teams and Business ",
    isLimited: true,
    yearly: {
      oldPrice: "198",
      newPrice: "69",
      trigger: "#pkmodal3052358a82dfc5607a2a167def3a5a20607d78bd",
    },
    monthly: {
      oldPrice: "130",
      newPrice: "99",
      trigger: "#pkmodal29ace0084cb963d1f6cacfa5ede1b98e806091cc",
    },
    yearlyBilled: "831",
    list: [
      {
        text: "Access to 4 tools (AI Voices, AI Humans, AI Image and AI Video)",
        tooltip: "",
      },
      {
        text: "Unlimited video and audio generations*",
        tooltip: "Unlimited credits subject to fair use where 1 credit = 10 seconds with daily fair use.",
      },
      {
        text: "1000 image generations",
        tooltip: "",
      },
      {
        text: "12 slides in each video",
        tooltip: "1 Slide equals 1 minute of content. Maximum length of a video is 12 minutes",
      },
      {
        text: "Fast Turbo mode - High priority rendering",
        tooltip: "",
      },
      {
        text: "Voice cloning 10 voices Pro",
        tooltip: "",
      },
      {
        text: "Transfered voice cloning to other languages.**",
        tooltip: "Use your voice under multiple languages",
      },
      {
        text: "Priority support",
        tooltip: "",
      },
      {
        text: "Commercial License",
        tooltip: "",
      },
    ],
  },
];
