import { CharacterPrefilled } from "./actor";
import { HumatarPrefilled } from "./humatar";
import { UploadImagePrefilled } from "./image";

export enum DeleteType {
  Project,
  Asset,
  GalleryImage,
  UploadImage,
  CloneVoice,
  CustomHumatar,
  GalleryVideo,
}

export type ConfirmationDelete = {
  id: number;
  title: string;
  description: string;
  typeId: number;
  type: DeleteType;
  status?: number;
  redirectTo?: boolean;
  previousFill?: UploadImagePrefilled | CharacterPrefilled | HumatarPrefilled;
  pageSize?: number;
};
