import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import Alert from "./components/Alert";
import * as Styles from "./index.styles";
import Sidebar from "./components/Sidebar";
import Timeline from "./components/Timeline";
import Scene from "../ScenesPoc/components/Scene";
import ChatPopup from "../Chat/components/ChatPopup";
import ChatTrigger from "../Chat/components/ChatTrigger";
import AIHumansEditor from "./components/AIHumansEditor";
import PreRender from "../ScenesPoc/components/PreRender";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import IconButton from "../../components/Button/IconButton";
import DashboardLayout from "../../layouts/DashboardLayout";
import AIHumansPopup from "../../components/Popups/AIHumansPopup";
import ProfileHumanSidebar from "./components/ProfileHumanSidebar";
import GenerativeVideoPopup from "../../components/Popups/GenerativeVideoPopup";
import {
  getEmptyParagraphs,
  getNotGeneratedParagraphs,
  useAIHumansAssets,
  useAIHumansProject,
} from "../../hooks/useAIHumans";
import { IActor } from "../../types/actor";
import { VideoEditorContext } from "../../App";
import {
  Status,
  clearCurrentHumansProject,
  getProjectServer,
  updateHumansProjectLoading,
} from "../../redux/actions/humansProjectActions";
import { ProjectModules } from "../../types/project";
import { dataToState } from "../../lib/projectUtils";
import { useSubSidebar } from "../../hooks/useSubSidebar";
import { ScreenProps, sidebar } from "../../mocks/humans";
import { ProfileHumanSidebarType } from "../../types/human";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { useGlobalSidebar } from "../../hooks/useGlobalSidebar";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { SignUpPopupTypes } from "../../types/signUpPopupTypes";
import { getProfile } from "../../redux/reducers/profileReducer";
import { getIsHydrated } from "../../redux/reducers/authReducer";
import { useHumanParagraph } from "../../hooks/useHumanParagraph";
import { getActorsList } from "../../redux/reducers/actorReducer";
import { getProjectAudio } from "../../redux/reducers/projectReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { navActions, startAdornment, tabActions } from "./index.styles";
import { getTemplatesServer } from "../../redux/actions/templateAction";
import { updateProjectLoading } from "../../redux/actions/projectAction";
import { ActorPositionTypes, Scene as SceneType } from "../../types/scene";
import { AiHumansProjectModules, HumansParagraph } from "../../types/humansProject";
import { getImportedPictures, getTemplates } from "../../redux/reducers/templateReducer";
import { ArrowRight, BasePageLoader, InfoGreyIcon } from "../../components/Icons/Icons";
import { getAIHumansPopupIsOpen, getGenerativeVideoPopupIsOpen } from "../../redux/reducers/popupsReducer";
import { getHumansProject, getHumansProjectLoading, getLoadingPage } from "../../redux/reducers/humansProjectReducer";

const AIHumansPage = () => {
  const navigate = useNavigate();
  const actors = useSelector(getActorsList);
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const isNotEnoughCredits = profile.aiHumanUsed >= profile.aiHumanAllowed;
  const hydrated = useSelector(getIsHydrated);
  const loadingPage = useSelector(getLoadingPage);
  const importedPictures = useSelector(getImportedPictures);
  const { cachedZonesAudio } = useSelector(getProjectAudio);
  const templates = useSelector(getTemplates);

  const {
    getFullProject,
    currentScene,
    handleBackInHistory,
    handleForwardInHistory,
    currentHistoryIndex,
    historyLength,
    handleChangeActiveScene,
    scenes,
    activeSceneId,
    setScenes,
    setInitialSlides,
    handleUpdateSoundtrack,
    handleAddAvatar,
  } = useContext(VideoEditorContext);

  const {
    paragraphs,
    setParagraphs,
    paragraphActive,
    selectedZone, // can be undefined when current scene are changing
    paragraphActor,
    paragraphActorsList,
    handleTextParagraph,
    handleEditorContent,
    featureActive,
    setFeatureActive,
    lastSel,
    setLastSel,
    editorContent,
    styleMap,
    calcParagraphsLength,
    setEditorContent,
    duplicateParagraph,
  } = useHumanParagraph(actors, scenes, activeSceneId || 0);

  const isProjectRestored = useRef(false);
  const [searchParams] = useSearchParams();
  const [route, setRoute] = useState<string>("");
  const [actorRoute, setActorRoute] = useState<string>("");
  const [templateRoute, setTemplateRoute] = useState<string>("");
  const [isRouteHydrated, setIsRouteHydrated] = useState(false);
  const [activeScreen, setActiveScreen] = useState<ScreenProps>(ScreenProps.DESKTOP);
  const [backgroundSoundTrackId, setBackgroundSoundTrackId] = useState<number | null>(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [isTrimVideo, setIsTrimVideo] = useState(false);
  const [isPlayScript, setIsPlayScript] = useState(false);

  const handleBackgroundSoundTrackId = (id: number) => {
    if (backgroundSoundTrackId === id) {
      setBackgroundSoundTrackId(null);
    } else {
      setBackgroundSoundTrackId(id);
    }
  };

  const handleTrimVideo = () => setIsTrimVideo(true);
  const handleTrimVideoClose = () => setIsTrimVideo(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [backgroundRef, setBackgroundRef] = useState(false);

  const dispatch = useDispatch();
  const { shapes, humatarsList } = useAIHumansAssets();
  const humatar =
    humatarsList.length && humatarsList.find((humatar) => !humatar.isLocked && humatar.name === actorRoute);

  const humansProject = useSelector(getHumansProject);
  const status = humansProject?.status;
  const disabled = (!!humansProject?.projectId && status === Status.isDraft) || status === Status.isCompleted;
  const humansProjectLoading = useSelector(getHumansProjectLoading);
  const { createProject, updateVideo } = useAIHumansProject({
    getFullProject,
    activeScreen,
    paragraphs,
    canvasWidth: canvasSize.width,
    backgroundSoundTrackId,
    handleChangeActiveScene,
  });

  const [isButtonClicked, setIsButtonClicked] = useState<boolean>(false);

  const { activeGlobalTab, setActiveGlobalTab } = useGlobalSidebar();
  const { subSidebarOpen, handleSubSidebarOpen } = useSubSidebar();

  const activeSubSidebarData = sidebar.find(({ type }) => type === activeGlobalTab);
  const handleActiveScreen = (id: ScreenProps) => setActiveScreen(id);

  const setActorActive = (actors: IActor[]) => {
    const selectedActor = actors?.[0];
    if (!selectedActor) {
      sentryErrors({
        errorType: SentryErrors.NO_SELECTED_ACTOR,
        details: {
          humansProjectId: `${humansProject?.projectId}`,
          paragraphs: JSON.stringify(paragraphs || []),
        },
      });
    }
    setParagraphs(
      paragraphs?.map((paragraph) => {
        if (paragraph.order === paragraphActive) {
          return {
            ...paragraph,
            actorId: actors?.[0].actorId,
            actor: selectedActor,
          };
        } else {
          return paragraph;
        }
      }),
    );
  };

  useEffect(() => {
    setRoute(searchParams.get("projectId") || "");
    setActorRoute(searchParams.get("actor")?.replace("№", "#") || "");
    setTemplateRoute(searchParams.get("template") || "");
    setIsRouteHydrated(true);
  }, [searchParams]);

  useEffect(() => {
    if (
      isRouteHydrated &&
      !isProjectRestored.current &&
      !route &&
      humansProject === null &&
      !templateRoute &&
      templates.length
    ) {
      setScenes([]);
      dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.loadingPage, isLoading: false }));

      const template: any = templates?.[0];
      dispatch(getProjectServer(parseInt(template.projectId), true));

      setIsRouteHydrated(false);
      isProjectRestored.current = true;
    }
  }, [route, humansProject, isRouteHydrated, templates.length]);

  useEffect(() => {
    if (!route && humansProject && !humansProject.slides.length && importedPictures) {
      const newScenes = importedPictures.map(({ path, userAssetID, assetTypeId }, index: number) => ({
        id: index,
        sceneId: 0,
        order: index + 1,
        background: path,
        audioPath: "",
        activeObjectId: 0,
        editableTextId: 0,
        objects: [],
        transitionEffectId: 0,
        backgroundId: userAssetID,
        backgroundAssetId: assetTypeId,
        slideBackgroundColor: "",
        startBackgroundVideoTime: null,
        endBackgroundVideoTime: null,
        initiallyUpdatedScale: false,
      }));
      setScenes(newScenes);
      addParagraphForEachScene(newScenes);
    }
  }, [route, humansProject]);

  const addParagraphForEachScene = (scenes: SceneType[], initialParagraphs?: HumansParagraph[]) => {
    if (!actors.length) {
      // TODO: sentry
    }

    let paragraphs: HumansParagraph[] = initialParagraphs || [];

    const newParagraphs = scenes.map((_, index) => ({
      actorId: 1,
      actor: actors?.[0],
      order: index,
      data: [
        {
          text: "",
          features: [
            {
              key: "",
              value: "",
            },
          ],
        },
      ],
      sceneId: index,
    }));

    paragraphs = [...paragraphs, ...newParagraphs];

    if (paragraphs?.length) {
      setEditorContent(paragraphs.map((paragraph) => dataToState(paragraph.data, 0)));
      setParagraphs(paragraphs);
    }
  };

  useEffect(() => {
    dispatch(clearCurrentHumansProject());
    setScenes([]);
    dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.loadingPage, isLoading: true }));

    if (route) {
      dispatch(updateProjectLoading({ module: ProjectModules.projectList, isLoading: true }));
      dispatch(getProjectServer(parseInt(route)));
    }
  }, [route]);

  const updateInitialScene = () => {
    if (humansProject) {
      setInitialSlides(humansProject, setEditorContent, setParagraphs);
      setActiveScreen(humansProject.isHorizontal ? ScreenProps.DESKTOP : ScreenProps.MOBILE);
      setBackgroundSoundTrackId(humansProject.backgroundSoundTrackId);
    }
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    updateInitialScene();
    if (humansProject?.slides.length && isFirstRender) {
      handleChangeActiveScene(humansProject.slides[0].slideId);
      setIsFirstRender(false);
    }
  }, [humansProject]);

  const [isHumatarAdded, setIsHumatarAdded] = useState(false);

  useEffect(() => {
    if (!isHumatarAdded && humatarsList.length && humatar && actorRoute && scenes.length) {
      setIsHumatarAdded(true);
      handleAddAvatar(
        humatar.photo,
        humatar.aiHumanActorId,
        humatar.allowCircle,
        canvasSize,
        humatar.category === "Custom" ? true : false,
        ActorPositionTypes.FullBody,
      );
    }
  }, [humatarsList, actorRoute, scenes, isHumatarAdded, humatar]);

  useEffect(() => {
    return () => {
      dispatch(clearCurrentHumansProject());
    };
  }, []);

  useEffect(() => {
    if (status === Status.isProgress && route === humansProject?.projectId.toString()) {
      navigate("/my-projects?filter=ai-humans");
      toast.warning("There is no way to see the project because it is at the generation stage");
    }
  }, [status]);

  useEffect(() => {
    if (isRouteHydrated) {
      dispatch(
        getTemplatesServer({
          keyword: "",
          pageNumber: 1,
        }),
      );
    }
  }, [isRouteHydrated]);

  useEffect(() => {
    if (isRouteHydrated && templateRoute) dispatch(getProjectServer(parseInt(templateRoute), true));
  }, [isRouteHydrated]);

  const generativeVideoPopupOpen = () => {
    const indexes = getNotGeneratedParagraphs(paragraphs, cachedZonesAudio)
      .filter((element) => element !== undefined)
      .map((value) => (value !== undefined ? value + 1 : value));

    const emptyIndexes = getEmptyParagraphs(paragraphs)
      .filter((element) => element !== undefined)
      .filter((element) => {
        if (element !== undefined) {
          if (scenes[element]?.audioPath) return false;
          else return true;
        } else {
          return false;
        }
      })
      .map((value) => (value !== undefined ? value + 1 : value));

    if (emptyIndexes.length === 0) {
      if (indexes.length === 0) {
        dispatch(
          updatePopup({
            popup: Popups.generativeVideo,
            status: true,
            prefilled: {
              canvasWidth: canvasSize.width,
              activeScreen,
              getFullProject,
              backgroundSoundTrackId,
            },
          }),
        );
      } else {
        toast.error(
          `Please, make sure to play all scripts (on slides ${indexes.join(", ")}) before generating the video`,
        );
      }
    } else {
      toast.error(`Please, add scripts (on slides ${emptyIndexes.join(", ")}) before generating the video`);
    }
  };

  const notEnoughCreditsPopupOpen = () => {
    const apiAllowed = profile.aiHumanAllowed || 0;
    const apiLeft = profile.aiHumanAllowed - (profile.aiHumanUsed || 0);
    const apiUsed = apiAllowed - apiLeft;
    const progress = (100 * (apiAllowed - apiUsed)) / apiAllowed;

    const prefilled: NotEnoughCredits = {
      title: "You Don’t Have Enough Credits",
      progressBar: { title: "Credits left", timeLeft: `${apiLeft} out of ${apiAllowed}`, progress },
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const signUpPopupOpen = () => {
    const prefilled: SignUpPopupTypes = {
      type: "AIHumans",
      link: { projectName: "ai-humans", projectId: humansProject?.projectId },
      projectSave: updateVideo,
    };

    dispatch(
      updatePopup({
        popup: Popups.signUpPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const generativeVideoPopupIsOpen = useSelector(getGenerativeVideoPopupIsOpen);
  const aIHumansPopupIsOpen = useSelector(getAIHumansPopupIsOpen);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === containerRef.current) {
          const containerWidth = containerRef.current?.offsetWidth;
          const containerHeight = containerRef.current?.offsetHeight;
          setCanvasSize({ width: containerWidth, height: containerHeight });
        }
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
      const containerWidth = containerRef.current?.offsetWidth;
      const containerHeight = containerRef.current?.offsetHeight;
      setCanvasSize({ width: containerWidth, height: containerHeight });
    }

    return () => {
      observer.disconnect();
    };
  }, [containerRef.current]);

  useEffect(() => {
    if (hydrated && profile.email) {
      if (!profile.allowToAIHuman) {
        navigate("/");
      }
    }
  }, [hydrated, profile]);

  useEffect(() => {
    if (activeGlobalTab !== ProfileHumanSidebarType.Background) {
      setBackgroundRef(false);
    }
  }, [activeGlobalTab]);

  const handleCloseGenerativeVideoPopup = () => {
    dispatch(updatePopup({ popup: Popups.generativeVideo, status: false, prefilled: {} }));
  };

  const handleCloseAIHumansPopup = () => {
    dispatch(updatePopup({ popup: Popups.aIHumansPopup, status: false, prefilled: {} }));
  };

  if (status === Status.isProgress) {
    return null;
  }

  if (!profile.allowToAIHuman) {
    return null;
  }

  return (
    <>
      <Alert status={status} output={humansProject?.output} />
      <Styles.Wrapper>
        <ProfileHumanSidebar activeGlobalTab={activeGlobalTab} setActiveGlobalTab={setActiveGlobalTab} />
        <Styles.PageWrapper id="pagewrapperid">
          <DashboardLayout
            startAdornment={startAdornment(activeScreen, handleActiveScreen)}
            tabActions={tabActions(currentScene, backgroundRef, handleTrimVideo)}
            navActions={navActions(
              createProject,
              updateVideo,
              humansProject,
              humansProjectLoading,
              handleBackInHistory,
              handleForwardInHistory,
              currentHistoryIndex,
              historyLength,
              generativeVideoPopupOpen,
              disabled,
              isNotEnoughCredits,
              notEnoughCreditsPopupOpen,
              status,
              isGuest,
              signUpPopupOpen,
            )}
          >
            <Styles.Content>
              {subSidebarOpen && (
                <Styles.Left onClick={(event) => event.stopPropagation()}>
                  <Sidebar
                    canvasSize={canvasSize}
                    paragraphs={paragraphs}
                    setParagraphs={setParagraphs}
                    setBackgroundRef={setBackgroundRef}
                    setEditorContent={setEditorContent}
                    activeSubSidebarData={activeSubSidebarData}
                    backgroundSoundTrackId={backgroundSoundTrackId}
                    handleBackgroundSoundTrackId={handleBackgroundSoundTrackId}
                  />
                </Styles.Left>
              )}
              <Styles.Right>
                <div>
                  <Styles.ImageWrapper activeScreen={activeScreen}>
                    {currentScene && (
                      <Scene
                        canvasSize={canvasSize}
                        isTrimVideo={isTrimVideo}
                        handleTrimVideoClose={handleTrimVideoClose}
                        containerRef={containerRef}
                      />
                    )}

                    <Styles.BarPreviewOuterWrapper
                      className="bar-preview"
                      opacity={isPlayScript}
                      canvasWidth={canvasSize.width}
                    >
                      <Styles.BarPreviewInnerWrapper>
                        <InfoGreyIcon />
                        <span>Lip movement is not visible in video preview</span>
                      </Styles.BarPreviewInnerWrapper>
                    </Styles.BarPreviewOuterWrapper>
                  </Styles.ImageWrapper>
                  <AIHumansEditor
                    paragraphs={paragraphs}
                    selectedZone={selectedZone}
                    paragraphActive={paragraphActive}
                    handleEditorContent={handleEditorContent}
                    editorContent={editorContent}
                    handleTextParagraph={handleTextParagraph}
                    featureActive={featureActive}
                    setFeatureActive={setFeatureActive}
                    lastSel={lastSel}
                    setLastSel={setLastSel}
                    paragraphActor={paragraphActor}
                    paragraphActorsList={paragraphActorsList}
                    calcParagraphsLength={calcParagraphsLength(paragraphs)}
                    styleMap={styleMap}
                    currentScene={currentScene}
                    scenes={scenes}
                    activeSceneId={activeSceneId || 0}
                    setEditorContent={setEditorContent}
                    handleUpdateSoundtrack={handleUpdateSoundtrack}
                    setParagraphs={setParagraphs}
                    setActorActive={setActorActive}
                    setIsPlayScript={setIsPlayScript}
                  />
                </div>
                <Timeline
                  selectedZone={selectedZone}
                  paragraphs={paragraphs}
                  activeScreen={activeScreen}
                  isButtonClicked={isButtonClicked}
                  setActiveGlobalTab={setActiveGlobalTab}
                  leftSidebarOpen={subSidebarOpen}
                  editorContent={editorContent}
                  setIsButtonClicked={setIsButtonClicked}
                  setParagraphs={setParagraphs}
                  setEditorContent={setEditorContent}
                  duplicateParagraph={duplicateParagraph}
                />
              </Styles.Right>
            </Styles.Content>
          </DashboardLayout>
          <Styles.IconButtonWrapper active={subSidebarOpen} onClick={(event) => event.stopPropagation()}>
            <IconButton icon={<ArrowRight />} onClick={handleSubSidebarOpen} />
          </Styles.IconButtonWrapper>
        </Styles.PageWrapper>
      </Styles.Wrapper>
      {generativeVideoPopupIsOpen && (
        <GenerativeVideoPopup
          humansProject={humansProject}
          paragraphs={paragraphs}
          open={generativeVideoPopupIsOpen}
          onClose={handleCloseGenerativeVideoPopup}
        />
      )}
      {aIHumansPopupIsOpen && (
        <AIHumansPopup open={aIHumansPopupIsOpen} onClose={handleCloseAIHumansPopup} canvasSize={canvasSize} />
      )}
      <ChatTrigger />
      <ChatPopup />
      <PreRender scenes={scenes} />
      {loadingPage && (
        <Styles.CircularProgressWrapper>
          <BasePageLoader />
        </Styles.CircularProgressWrapper>
      )}
    </>
  );
};

export default withPrivateRoute(AIHumansPage);
