export const TranslateVideoIcon = () => (
  <svg width="97" height="74" viewBox="0 0 97 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M79.7467 73H8.30864C4.44372 73 1.29785 69.8835 1.29785 66.0547V7.94534C1.29785 4.1165 4.44372 1 8.30864 1H79.7467C83.6117 1 86.7575 4.1165 86.7575 7.94534V66.0547C86.7575 69.8835 83.6117 73 79.7467 73Z"
      fill="white"
    />
    <path
      d="M79.7467 1H8.30864C4.44372 1 1.29785 4.1165 1.29785 7.94534V11.0618H86.7575V7.94534C86.7575 4.09869 83.6117 1 79.7467 1Z"
      fill="#147FD6"
    />
    <path
      d="M79.7467 73H8.30864C4.44372 73 1.29785 69.8835 1.29785 66.0547V7.94534C1.29785 4.1165 4.44372 1 8.30864 1H79.7467C83.6117 1 86.7575 4.1165 86.7575 7.94534V66.0547C86.7575 69.8835 83.6117 73 79.7467 73Z"
      stroke="#111C3A"
      strokeWidth="0.989364"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.66211 10.9619L86.1414 10.9619"
      stroke="#111C3A"
      strokeWidth="0.989364"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.92819 7.64166C10.7919 7.64166 11.4921 6.94799 11.4921 6.09231C11.4921 5.23663 10.7919 4.54297 9.92819 4.54297C9.06445 4.54297 8.36426 5.23663 8.36426 6.09231C8.36426 6.94799 9.06445 7.64166 9.92819 7.64166Z"
      fill="white"
    />
    <path
      d="M16.3276 7.64166C17.1914 7.64166 17.8916 6.94799 17.8916 6.09231C17.8916 5.23663 17.1914 4.54297 16.3276 4.54297C15.4639 4.54297 14.7637 5.23663 14.7637 6.09231C14.7637 6.94799 15.4639 7.64166 16.3276 7.64166Z"
      fill="white"
    />
    <path
      d="M22.728 7.64166C23.5918 7.64166 24.292 6.94799 24.292 6.09231C24.292 5.23663 23.5918 4.54297 22.728 4.54297C21.8643 4.54297 21.1641 5.23663 21.1641 6.09231C21.1641 6.94799 21.8643 7.64166 22.728 7.64166Z"
      fill="white"
    />
    <path
      d="M11.8108 43.2423L11.8108 43.2419C11.8102 42.1769 11.8102 42.1687 11.8102 40.5792L11.8105 34.2557V34.2557V27.77C11.8105 24.0595 14.8685 21.0211 18.6305 21.0211H70.1515C73.9471 21.0512 76.9741 24.0909 76.9741 27.8015V54.0275C76.9741 57.7381 73.916 60.7764 70.1541 60.7764H18.6305C14.8685 60.7764 11.8105 57.7381 11.8105 54.0275V47.5104V47.5104L11.8108 43.2423Z"
      stroke="#10002B"
      strokeWidth="1.36258"
    />
    <path
      d="M39.3265 49.8926L39.3265 49.8925L39.3206 49.896C38.6541 50.2921 37.7787 49.806 37.7787 49.0071V32.604C37.7787 31.8478 38.6184 31.3377 39.3311 31.7213L53.6616 39.9359L53.6615 39.936L53.6702 39.9407C54.3466 40.3082 54.3666 41.2695 53.6613 41.6754C53.6612 41.6755 53.6611 41.6755 53.661 41.6756L39.3265 49.8926Z"
      fill="#10002B"
      stroke="#10002B"
      strokeWidth="0.86201"
    />
    <path
      d="M96.6666 7.28833V15.0516C96.6666 15.8344 96.3415 16.5351 95.8209 17.0421C95.3108 17.5389 94.6086 17.8404 93.8373 17.8404H84.3067C82.7433 17.8404 81.5068 16.6001 81.5068 15.0516V7.28833C81.5068 6.35304 81.9547 5.5171 82.6673 5.00663C83.1325 4.6726 83.6893 4.46875 84.3084 4.46875H93.8391C95.399 4.47046 96.6666 5.7415 96.6666 7.28833Z"
      fill="black"
    />
    <path
      d="M90.1021 16.1304L85.3013 20.8908C85.1958 20.9953 85.0298 21.0261 84.8914 20.9696C84.7548 20.9131 84.6562 20.7812 84.6562 20.6339V15.6953L90.1021 16.1304Z"
      fill="black"
    />
    <path
      d="M91.809 11.1788L90.1644 6.5332H87.9698L86.8111 9.82728L84.9814 15.0125H86.9149L87.5288 12.8918H90.6158L91.2297 15.0125H93.1632L91.809 11.1788ZM87.9819 11.4786C88.1479 10.9493 88.2949 10.4662 88.4264 10.0431C88.7671 8.93139 88.985 8.19652 89.0766 7.84193C89.1078 7.97897 89.1596 8.16568 89.2305 8.40208C89.2859 8.58365 89.4865 9.23116 89.8324 10.3395C89.9379 10.6752 90.0555 11.0658 90.1886 11.4769H87.9819V11.4786Z"
      fill="white"
    />
    <path
      d="M83.8834 18.2001V25.9634C83.8834 26.6845 83.6153 27.3594 83.1622 27.8562C82.6451 28.4249 81.9015 28.7966 81.0679 28.7966H71.5373C69.974 28.7966 68.6631 27.5119 68.6631 25.9634V18.2001C68.6631 17.2134 69.2009 16.3603 69.9791 15.8601C70.4219 15.5758 70.9718 15.4268 71.5373 15.4268H81.0679C82.6296 15.4268 83.8834 16.6515 83.8834 18.2001Z"
      fill="#147FD6"
    />
    <path
      d="M80.6748 26.5801V31.5186C80.6748 31.6659 80.5936 31.7978 80.4569 31.8544C80.3203 31.9109 80.1664 31.8801 80.0609 31.7756L75.248 27.0152L80.6748 26.5801Z"
      fill="#147FD6"
    />
    <path d="M80.4356 18.959H72.1709V20.254H80.4356V18.959Z" fill="white" />
    <path
      d="M79.919 26.4969C76.9963 25.8271 75.4174 24.0027 74.6063 22.5912C73.7347 21.0735 73.5497 19.7134 73.541 19.6552L74.8346 19.489L74.1878 19.5712L74.8346 19.4873C74.8363 19.4993 75.0058 20.6915 75.7633 21.9917C76.7559 23.6944 78.2536 24.7873 80.213 25.2378L79.919 26.4969Z"
      fill="white"
    />
    <path
      d="M72.7061 26.4969L72.4121 25.2378C74.3715 24.7873 75.8691 23.6961 76.8618 21.9917C77.621 20.6898 77.7888 19.4993 77.7905 19.4873L79.0841 19.6552C79.0771 19.7117 78.8904 21.0735 78.0188 22.5912C77.2077 24.0027 75.627 25.8271 72.7061 26.4969Z"
      fill="white"
    />
    <path d="M76.9263 17.6631H75.6777V19.5474H76.9263V17.6631Z" fill="white" />
  </svg>
);
