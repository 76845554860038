import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { Position, ResizableDelta } from "react-rnd";
import { useSelector, useDispatch } from "react-redux";

import useAIHumansFonts from "./useAIHumansFonts";
import {
  Scene as SceneType,
  TextTypes,
  TextAlign,
  ShapeTypes,
  ObjectTypes,
  Text,
  SceneObject,
  Avatar,
  Shape,
  ShapeBorderStyle,
  ActorPositionTypes,
  Media,
} from "../types/scene";
import {
  AiHumansProjectModules,
  BackgroundAsset,
  CustomImageDetails,
  CustomText,
  HumansParagraph,
  Project,
  ShapeDetail,
} from "../types/humansProject";
import { Zone } from "../types/project";
import { getMathObjectArray } from "../lib/math";
import { dataToState } from "../lib/projectUtils";
import { checkIfZoneCached } from "../lib/editorUtils";
import { SentryErrors, sentryErrors } from "../lib/sentry";
import { handleDOMtoBase64 } from "../lib/handleDOMtoBase64";
import { useVideoSceneHistory } from "./useVideoSceneHistory";
import { getProjectAudio } from "../redux/reducers/projectReducer";
import { VideoEditorContextType } from "../types/videoEditorContext";
import { getShape } from "../modules/AIHumans/components/tabs/ShapesTab";
import { ResolutionSize, resolutions } from "../components/Popups/GenerativeVideoPopup";
import { Status, updateHumansProjectLoading } from "../redux/actions/humansProjectActions";

const DEFAULT_OBJECT_POSITION = { x: 10, y: 10 };

const DEFAULT_TEXT_SIZE = { width: 220, height: 60 };
// const DEFAULT_AVATAR_SIZE = { width: 200, height: 200 };
const DEFAULT_SHAPE_SIZE = { width: 100, height: 100 };
const DEFAULT_MEDIA_SIZE = { width: 100, height: 100 };
const DEFAULT_BACKGROUND_ASSET: BackgroundAsset = {
  path: "",
  assetTypeId: 0,
};

export enum ChangeLayer {
  raise = "raise",
  lower = "lower",
}

const initialStyles = {
  [TextTypes.title]: {
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "26px",
    textAlign: "left" as TextAlign,
    opacity: 1,
    color: "#000000",
    fontFamily: "Mulish",
    fontStyle: "inherit",
    textDecoration: "none",
  },
  [TextTypes.subtitle]: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "20px",
    textAlign: "left" as TextAlign,
    opacity: 1,
    color: "#000000",
    fontFamily: "Mulish",
    fontStyle: "inherit",
    textDecoration: "none",
  },
  [TextTypes.bodyText]: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "left" as TextAlign,
    opacity: 1,
    color: "#000000",
    fontFamily: "Mulish",
    fontStyle: "inherit",
    textDecoration: "none",
  },
};

export interface GetFullProjectProps {
  title: string;
  isDraft: boolean;
  resolution: string;
  isTemplate: boolean;
  projectTypeId: number;
  projectCategoryId: number;
  status: Status;
  projectId?: number;
  isHorizontal: boolean;
  resolutionSize: string;
  backgroundSoundTrackId: number;
  paragraphs: HumansParagraph[];
  canvasWidth: number;
  enableCaption: boolean;
}

export const useVideoEditor = (): VideoEditorContextType => {
  const [scenes, setScenesState] = useState<SceneType[]>([]);
  const [activeSceneId, setActiveSceneId] = useState<number>();
  const { cachedZonesAudio } = useSelector(getProjectAudio);
  const [actorPositionType, setActorPositionType] = useState(ActorPositionTypes.Circle);

  const dispatch = useDispatch();

  const { addFontToDocument, fontList, handleChangeSelected, selectedFont, isFontLoading } = useAIHumansFonts();

  const handleChangeActorPositionType = (type: ActorPositionTypes) => setActorPositionType(type);

  const setScenes = (nextScenes: SceneType[]) => {
    const orderedScenes = nextScenes.sort((a, b) => a.order - b.order);
    setScenesState(orderedScenes);
  };

  const [canvasInitialWidth, setCanvasInitialWidth] = useState<{ value: number }>({ value: 0 });

  const { currentScenes, handleBackInHistory, handleForwardInHistory, currentHistoryIndex, historyLength } =
    useVideoSceneHistory({
      scenes,
      activeSceneId,
      setActiveSceneId,
      setScenes,
    });

  const updateCanvasInitialWidth = (value: number) => {
    setCanvasInitialWidth({ value });
  };

  const currentScene: SceneType = (currentScenes || scenes).find(({ id }) => id === activeSceneId) || ({} as SceneType);

  const updateScene = (scene: SceneType) => {
    setScenes(scenes.map((sceneItem) => (sceneItem.id === currentScene?.id ? scene : sceneItem)));
  };

  const dublicateScene = (id: number) => {
    const current = scenes.find(({ id: sceneId }) => sceneId === id);
    if (!current) return;

    const newId = getMathObjectArray(scenes, "id") + 1;

    const prevScene = { ...current };
    const newScenes = scenes
      .map((scene) => {
        if (scene.order > current.order) {
          // scene.id++;
          scene.order++;
        }
        return scene;
      })
      .concat({ ...prevScene, id: newId, order: current.order + 1, sceneId: 0 });
    setScenes([...newScenes]);
    handleChangeActiveScene(newId);
  };

  const addScene = (background?: string, backgroundId?: number, backgroundAssetId?: number) => {
    const id = scenes.length ? getMathObjectArray(scenes, "id") + 1 : 0;
    const newScene = {
      id,
      sceneId: 0,
      order: scenes.length + 1,
      background: background || "",
      audioPath: "",
      activeObjectId: 0,
      editableTextId: 0,
      objects: [],
      transitionEffectId: 0,
      backgroundId: backgroundId || 1,
      backgroundAssetId: backgroundAssetId || 1,
      slideBackgroundColor: "",
      startBackgroundVideoTime: null,
      endBackgroundVideoTime: null,
      initiallyUpdatedScale: false,
    };
    setScenes([...scenes, newScene]);
    handleChangeActiveScene(id);
  };

  const handleDeleteScene = (id: number) => {
    const updatedScenes = scenes.filter(({ id: sceneId }) => sceneId !== id);

    const reorderedScenes = updatedScenes.map((scene, index) => ({
      ...scene,
      order: index + 1,
    }));

    setScenes(reorderedScenes);
  };

  const handleSceneObjectChange = (key: string, value: any, id: number) => {
    if (!currentScene) return;
    const newObjects = [...currentScene.objects];

    newObjects.forEach(({ object: obj }: SceneObject) => {
      if (obj.id === id) obj[key as keyof typeof obj] = value;
    });

    updateScene({ ...currentScene, objects: newObjects });
  };

  const handleAddText = (text: string, type: TextTypes) => {
    if (!currentScene) return;
    const id = currentScene.objects.length
      ? getMathObjectArray(
          currentScene.objects.map((item) => item.object),
          "id",
        ) + 1
      : 1;

    const newText = {
      type: ObjectTypes.texts,
      object: {
        id,
        text,
        style: initialStyles[type],
        position: DEFAULT_OBJECT_POSITION,
        size: DEFAULT_TEXT_SIZE,
        textType: type,
        customTextId: 0,
        layerOrder: 0,
        displayedFontSize: initialStyles[type].fontSize,
      },
    };
    const newObjects = [...currentScene.objects, newText];
    updateScene({ ...currentScene, objects: newObjects, activeObjectId: id });
  };

  const handleAddMedia = (src: string) => {
    if (!currentScene) return;
    const id = currentScene.objects.length
      ? getMathObjectArray(
          currentScene.objects.map((item) => item.object),
          "id",
        ) + 1
      : 1;

    const newMedia = {
      type: ObjectTypes.media,
      object: {
        id,
        position: DEFAULT_OBJECT_POSITION,
        size: DEFAULT_MEDIA_SIZE,
        layerOrder: 0,
        src,
        customImageDetailId: 0,
      },
    };
    const newObjects = [...currentScene.objects, newMedia];
    updateScene({ ...currentScene, objects: newObjects, activeObjectId: id });
  };

  const handleAddShape = (
    type: ShapeTypes,
    svgData: string | null,
    shapeId: number,
    shapeImageUrl: string,
    canvasSize: { width: number; height: number },
  ) => {
    if (!currentScene) return;
    const id = currentScene.objects.length
      ? getMathObjectArray(
          currentScene.objects.map((item) => item.object),
          "id",
        ) + 1
      : 1;

    const size = (DEFAULT_SHAPE_SIZE.height * canvasSize.height) / 500;

    const newShape = {
      type: ObjectTypes.shapes,
      object: {
        id,
        position: DEFAULT_OBJECT_POSITION,
        size: { height: size, width: size },
        shape: type,
        background: "#000000",
        shapeId,
        opacity: 1,
        border: {
          borderStyle: ShapeBorderStyle.solid,
          borderWidth: 0,
          borderColor: "#000000",
        },
        shapeDetailId: 0,
        layerOrder: 0,
        shapeImageUrl,
        svgData,
      },
    };
    const newObjects = [...currentScene.objects, newShape];
    updateScene({ ...currentScene, objects: newObjects, activeObjectId: id });
  };

  const handleDuplicateObject = (id: number, newValues?: Record<string, any>) => {
    if (!currentScene) return;
    const currentObject = currentScene.objects.find((object) => object.object.id === id);
    if (!currentObject) return;
    const newId =
      getMathObjectArray(
        currentScene.objects.map((object) => object.object),
        "id",
      ) + 1;
    const newObject = { ...currentObject, object: { ...currentObject.object, id: newId, ...newValues } };
    const newObjects = [...currentScene.objects, newObject];
    updateScene({ ...currentScene, objects: newObjects, activeObjectId: newId });
  };

  const handleAddAvatar = (
    src: string,
    aiHumanActorId: number,
    allowCircle: boolean,
    canvasSize: { width: number; height: number },
    isSadtalker?: boolean,
    actorPositionType?: ActorPositionTypes,
  ) => {
    if (!currentScene) return;
    const id = currentScene.objects.length
      ? getMathObjectArray(
          currentScene.objects.map((item) => item.object),
          "id",
        ) + 1
      : 1;

    const width = allowCircle ? canvasSize.width / 2.5 : canvasSize.width / 1.5;
    const height = canvasSize.width / 2.5;

    const size = { width, height };

    const newAvatar = {
      type: ObjectTypes.avatars,
      object: {
        id: id,
        position: { x: canvasSize.width / 2 - size.width / 2, y: canvasSize.height - size.height },
        size,
        src,
        aiHumanActorId,
        allowCircle,
        actorPositionType: actorPositionType || ActorPositionTypes.FullBody,
        isSadtalker: isSadtalker || null,
        background: "#ffffff",
        layerOrder: 0,
        faceImage: null,
      },
    };
    const prevObjects = currentScene.objects.filter((sceneObject) => sceneObject.type !== ObjectTypes.avatars);
    const newObjects = [...prevObjects, newAvatar];
    updateScene({ ...currentScene, objects: newObjects, activeObjectId: id });
  };

  const updateFaceImage = (faceImage: string | null) => {
    if (!currentScene) return;
    const newObjects = currentScene.objects.map((item) =>
      item.type === ObjectTypes.avatars
        ? { ...item, object: { ...item.object, faceImage, src: faceImage as string } }
        : item,
    );
    updateScene({ ...currentScene, objects: newObjects });
  };

  const handleChangeActiveObject = (id: number) => {
    if (!currentScene) return;
    if (currentScene.editableTextId) {
      return updateScene({ ...currentScene, editableTextId: NaN });
    }
    updateScene({ ...currentScene, activeObjectId: id });
  };

  const handleChangeActiveScene = (id: number) => {
    setActiveSceneId(id);
    // handleChangeActiveObject(0);
  };

  const handleRemoveTextChip = (id: number) => {
    if (!currentScene) return;
    const current = currentScene.objects.find((obj) => obj.object.id === id);
    const newObjects = currentScene.objects.filter((obj) => obj.object.id !== id);
    if (current?.object.id === currentScene.activeObjectId && newObjects.length) {
      handleChangeActiveObject(newObjects[0].object.id);
    }
    updateScene({ ...currentScene, objects: newObjects });
  };

  const handleInputChange = (value: string, id: number) => {
    handleSceneObjectChange("text", value, id);
  };

  const updatePosition = (...args: { position: Position; id: number; objType: ObjectTypes }[]) => {
    if (!currentScene || !args.length) return;

    updateScene({
      ...currentScene,
      objects: currentScene.objects.map((obj) => {
        const newPositionArgs = args.find(({ id, objType }) => obj.object.id === id && obj.type === objType);
        return newPositionArgs ? { ...obj, object: { ...obj.object, position: newPositionArgs.position } } : obj;
      }),
      activeObjectId: args.length === 1 ? args[0].id : currentScene.activeObjectId,
    });
  };

  const updateSize = (...args: { size: ResizableDelta; position: Position; id: number; objType: ObjectTypes }[]) => {
    if (!currentScene) return;
    updateScene({
      ...currentScene,
      objects: currentScene.objects.map((obj) => {
        const newSizeArgs = args.find(({ id, objType }) => obj.object.id === id && obj.type === objType);
        return newSizeArgs
          ? { ...obj, object: { ...obj.object, size: newSizeArgs.size, position: newSizeArgs.position } }
          : obj;
      }),
    });
  };

  const handleBackgroundChange = (src: string, backgroundId: number, backgroundAssetId: number) => {
    if (!currentScene) return;
    updateScene({
      ...currentScene,
      background: src,
      backgroundId,
      backgroundAssetId,
      slideBackgroundColor: "",
      startBackgroundVideoTime: null,
      endBackgroundVideoTime: null,
    });
  };

  const handleBackgroundColorChange = (color: string) => {
    if (!currentScene) return;
    updateScene({
      ...currentScene,
      slideBackgroundColor: color,
      background: "",
      backgroundId: 0,
      startBackgroundVideoTime: null,
      endBackgroundVideoTime: null,
    });
  };

  const handleTransitionChange = (transitionEffectId: number) => {
    if (!currentScene) return;
    updateScene({ ...currentScene, transitionEffectId });
  };

  const handleUpdateSoundtrack = (audioPath: string) => {
    if (!currentScene) return;
    updateScene({ ...currentScene, audioPath });
  };

  const deleteAllText = () => {
    if (!currentScene) return;
    updateScene({ ...currentScene, objects: currentScene.objects.filter((obj) => obj.type !== ObjectTypes.texts) });
  };

  const handleDisactivateObjects = () => {
    if (!currentScene) return;
    if (currentScene.editableTextId) {
      return updateScene({ ...currentScene, editableTextId: NaN });
    }
    updateScene({ ...currentScene, activeObjectId: NaN, editableTextId: NaN });
  };

  const handleDeleteCurrentObject = () => {
    if (!currentScene) return;
    const id = currentScene.activeObjectId;
    const activeObject = currentScene.objects?.find((obj) => obj.object.id === id);
    if (!activeObject || (activeObject.type === ObjectTypes.texts && currentScene.editableTextId === id)) return;

    const newObjects = currentScene.objects.filter((obj) => obj.object.id !== id);

    updateScene({ ...currentScene, objects: newObjects });
  };

  const setEditableTextId = (id: number) => {
    if (!currentScene) return;
    updateScene({ ...currentScene, editableTextId: id });
  };

  const handleBackgroundVideoTime = (newValue: number[]) => {
    const [start, end] = newValue;

    if (!currentScene) return;
    updateScene({ ...currentScene, startBackgroundVideoTime: start, endBackgroundVideoTime: end });
  };

  const changeObjectLayer = (direction: ChangeLayer) => {
    if (!currentScene) return;
    const objects = [...currentScene.objects];
    const index = objects.findIndex((item) => item.object.id === currentScene.activeObjectId);
    if (index === -1) return;
    const removed = objects.splice(index, 1)[0];
    if (direction === ChangeLayer.lower) {
      objects.unshift(removed);
    } else {
      objects.push(removed);
    }
    updateScene({ ...currentScene, objects });
  };

  const handleKeyboardPress = (event: KeyboardEvent) => {
    switch (event.code) {
      case "Escape": {
        return handleDisactivateObjects();
      }
      case "Delete": {
        return handleDeleteCurrentObject();
      }
      case "Backspace": {
        return handleDeleteCurrentObject();
      }
      case "BracketLeft": {
        return changeObjectLayer(ChangeLayer.lower);
      }
      case "BracketRight": {
        return changeObjectLayer(ChangeLayer.raise);
      }
      default: {
        return;
      }
    }
  };

  useEffect(() => {
    addEventListener("keydown", handleKeyboardPress);

    return () => removeEventListener("keydown", handleKeyboardPress);
  }, [scenes, activeSceneId, currentScenes]);

  useEffect(() => {
    const handleDisactivateObjects = () => {
      if (currentScene?.activeObjectId) {
        handleChangeActiveObject(NaN);
      }
    };

    document.addEventListener("click", handleDisactivateObjects);

    return () => document.removeEventListener("click", handleDisactivateObjects);
  }, [scenes, activeSceneId, currentScenes]);

  const parseShapes = async (
    shapes: Shape[],
    objectsLayers: { id: number; layer: number }[],
    canvasWidth: number,
    isHorizontal: boolean,
    resolutionSize: ResolutionSize,
    sceneId: number,
  ) => {
    return await Promise.all(
      shapes.map(async (shape) => ({
        shapeDetailId: shape.shapeDetailId,
        shapeId: shape.shapeId,
        shapePositionX: shape.position.x,
        shapePositionY: shape.position.y,
        shapeHeight: shape.size.height,
        shapeWidth: shape.size.width,
        opacity: shape.opacity * 100,
        borderStyle: "Dashed",
        position: "Center",
        image:
          shape.svgData || (shape.shape !== ShapeTypes.custom && shape.shape !== ShapeTypes.customSvg)
            ? await handleDOMtoBase64(`#shape${shape.id}${sceneId}`, canvasWidth, isHorizontal, resolutionSize)
            : "",
        LayerOrder: objectsLayers.find((item) => item.id === shape.id)?.layer || 0,
        extraStyleData: [
          {
            key: "borderWidth",
            value: String(shape.border.borderWidth),
          },
          {
            key: "borderColor",
            value: shape.border.borderColor,
          },
          {
            key: "background",
            value: shape.background,
          },
          {
            key: "shapeImageUrl",
            value: shape.shapeImageUrl,
          },
          {
            key: "svgData",
            value: shape.svgData,
          },
        ],
      })),
    );
  };

  const parseText = async (
    texts: Text[],
    objectsLayers: { id: number; layer: number }[],
    canvasWidth: number,
    isHorizontal: boolean,
    resolutionSize: ResolutionSize,
    sceneId: number,
  ) => {
    return await Promise.all(
      texts.map(async (text) => {
        return {
          customTextId: text.customTextId,
          text: text.text,
          customTextType: text.textType,
          fontFamily: text.style.fontFamily as string,
          fontSize: text.style.fontSize as string,
          lineSpacing: text.style.lineHeight as string,
          fontStyle: text.style.fontStyle as string,
          textAlign: text.style.textAlign as string,
          fontColor: text.style.color as string,
          opacity: text.style.opacity as number,
          textPositionX: text.position.x,
          textPositionY: text.position.y,
          image: await handleDOMtoBase64(`#text${text.id}${sceneId}`, canvasWidth, isHorizontal, resolutionSize),
          LayerOrder: objectsLayers.find((item) => item.id === text.id)?.layer || 0,
          extraStyleData: [
            {
              key: "textDecoration",
              value: text.style.textDecoration,
            },
            {
              key: "fontWeight",
              value: text.style.fontWeight,
            },
            {
              key: "size",
              value: JSON.stringify(text.size),
            },
          ],
        };
      }),
    );
  };

  const parseMedia = async (media: Media[], objectsLayers: { id: number; layer: number }[], canvasWidth: number) => {
    return await Promise.all(
      media.map(async (mediaItem) => {
        return {
          customImageDetailId: mediaItem.customImageDetailId,
          // projectId: 22672,
          // slideId: 332,
          customImageId: 1,
          layerOrder: objectsLayers.find((item) => item.id === mediaItem.id)?.layer || 0,
          customImagePositionX: mediaItem.position.x,
          customImagePositionY: mediaItem.position.y,
          customImageHeight: mediaItem.size.height,
          customImageWidth: mediaItem.size.width,
          // opacity: 1,
          // borderStyle: "Dashed",
          // position: "center",
          extraStyleJsonText: null,
          // image: await handleDOMtoBase64(`#media${mediaItem.id}`, canvasWidth),
          // insertDateTime: "2023-05-25T06:17:45.8096499Z",
          updateDateTime: null,
          extraStyleData: [
            {
              key: "src",
              value: mediaItem.src,
            },
          ],
        };
      }),
    );
  };

  const parseParagraphs = (paragraphs: HumansParagraph[]) => {
    const newParagraphs: HumansParagraph[] = paragraphs.map((paragraph) => {
      return {
        ...paragraph,
        data: paragraph.data.map((zone: Zone) => {
          const matchedZone = checkIfZoneCached({ ...zone, actorId: paragraph.actorId }, cachedZonesAudio);
          const audioPath = matchedZone?.outputUrl || matchedZone?.audioPath;
          return {
            ...zone,
            audioPath,
          };
        }),
      };
    });

    return newParagraphs.map((item) => ({
      projectParagraphId: 0,
      order: item.order,
      actorId: item.actorId,
      data: item.data,

      // outputAudio: item.outputAudio,
    }));
  };

  const parseScenes = async (
    paragraphs: HumansParagraph[],
    canvasWidth: number,
    isHorizontal: boolean,
    resolutionSize: ResolutionSize,
  ) => {
    const allScenes = currentScenes || scenes;
    return await Promise.all(
      allScenes.map(async (item) => {
        const extraStyleJsonText = JSON.stringify({ isActive: activeSceneId === item.id, id: item.id });
        const avatar = item.objects.find((sceneObject) => sceneObject.type === ObjectTypes.avatars)?.object as
          | Avatar
          | undefined;
        const objectsLayers = item.objects.map((sceneObject, index) => ({ id: sceneObject.object.id, layer: index }));
        return {
          extraStyleJsonText,
          slideId: item.sceneId,
          order: item.order,
          slideBackgroundColor: item.slideBackgroundColor,
          audioPath: item.audioPath || "",
          backGroundAssetId: item.backgroundId,
          backGroundColor: avatar?.background,
          aiHumanActorId: avatar?.aiHumanActorId || 0,
          actorPositionType: avatar ? avatar.actorPositionType : "",
          actorPositionX: avatar ? avatar.position.x : null,
          actorPositionY: avatar ? avatar.position.y : null,
          actorSizeHeight: avatar ? avatar.size.height : null,
          actorSizeWidth: avatar ? avatar.size.width : null,
          isSadtalker: avatar ? avatar.isSadtalker : null,
          faceImage: avatar?.faceImage || null,
          slideTransitionEffectId: item.transitionEffectId,
          humatarLayerOrder: objectsLayers.find((item) => item.id === avatar?.id)?.layer || 0,
          shapeDetails: await parseShapes(
            item.objects.filter((obj) => obj.type === ObjectTypes.shapes).map((item) => item.object as Shape),
            objectsLayers,
            canvasWidth,
            isHorizontal,
            resolutionSize,
            item.id,
          ),
          customTexts: await parseText(
            item.objects.filter((obj) => obj.type === ObjectTypes.texts).map((item) => item.object as Text),
            objectsLayers,
            canvasWidth,
            isHorizontal,
            resolutionSize,
            item.id,
          ),
          customImageDetails: await parseMedia(
            item.objects.filter((obj) => obj.type === ObjectTypes.media).map((item) => item.object as Media),
            objectsLayers,
            canvasWidth,
          ),
          projectParagraphs: parseParagraphs(paragraphs.filter((paragraph) => paragraph.sceneId === item.id)),
          startBackgroundVideoTime: item.startBackgroundVideoTime || null,
          endBackgroundVideoTime: item.endBackgroundVideoTime || null,
        };
      }),
    );
  };

  const getFullProject = async ({
    title,
    isDraft,
    resolution,
    isTemplate,
    projectTypeId,
    projectCategoryId,
    status,
    projectId,
    isHorizontal,
    backgroundSoundTrackId,
    paragraphs = [],
    canvasWidth,
    enableCaption,
  }: GetFullProjectProps) => {
    const resolutionSize = resolutions.data.find((item) => item.value === resolution)?.size;

    if (!resolutionSize) {
      toast.error("Issue with resolution size");
      return;
    }

    const allScenes = currentScenes || scenes;

    if (allScenes.length === 0) {
      toast.error(SentryErrors.CREATE_PROJECT_WITHOUT_SLIDES.toast);

      sentryErrors({
        errorType: SentryErrors.CREATE_PROJECT_WITHOUT_SLIDES,
        details: {
          allScenes: JSON.stringify(allScenes || []),
        },
      });
      return;
    }

    try {
      const parsedScenes = await parseScenes(paragraphs, canvasWidth, isHorizontal, resolutionSize);
      return {
        projectTypeId,
        title,
        projectCategoryId,
        subTitleLanguageId: 1,
        resolution,
        isDraft,
        isTemplate,
        slides: parsedScenes,
        status,
        projectId,
        isHorizontal,
        resolutionSize,
        canvasWidth,
        enableCaption,
        backgroundSoundTrackId: backgroundSoundTrackId || 0,
      };
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while saving this project!");
      dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: false }));
    }
  };

  const getInitialTexsts = (customTexts: CustomText[]) => {
    let id = 1;
    const objects = customTexts.map((customText): SceneObject => {
      addFontToDocument(customText.fontFamily);
      return {
        type: ObjectTypes.texts,
        object: {
          text: customText.text,
          style: {
            fontFamily: customText.fontFamily,
            fontSize: customText.fontSize,
            lineHeight: customText.lineSpacing,
            fontStyle: customText.fontStyle,
            fontWeight: customText.extraStyleData.find(({ key }) => key === "fontWeight")?.value || "400",
            textAlign: customText.textAlign as TextAlign,
            color: customText.fontColor,
            opacity: customText.opacity,
            textDecoration: customText.extraStyleData.find(({ key }) => key === "textDecoration")?.value || "none",
          },
          textType: customText.customTextType as TextTypes,
          id: id++,
          position: {
            x: customText.textPositionX,
            y: customText.textPositionY,
          },
          size:
            JSON.parse(customText.extraStyleData.find(({ key }) => key === "size")?.value || "null") ||
            DEFAULT_TEXT_SIZE,
          customTextId: customText.customTextId,
          layerOrder: customText.layerOrder,
          displayedFontSize: customText.fontSize,
        },
      };
    });

    return {
      id,
      objects,
    };
  };

  const getInitialShapes = (shapes: ShapeDetail[], id: number) => {
    const objects = shapes.map((shape): SceneObject => {
      const svgData = shape.extraStyleData.find(({ key }) => key === "svgData")?.value || null;

      const shapeName = getShape(shape.shapeId, svgData);

      return {
        type: ObjectTypes.shapes,
        object: {
          shapeDetailId: shape.shapeDetailId,
          shape: shapeName,
          background: shape.extraStyleData.find(({ key }) => key === "background")?.value || "#000000",
          opacity: (shape as any).opacity / 100,
          border: {
            borderStyle: ShapeBorderStyle.solid,
            borderWidth: Number(shape.extraStyleData.find(({ key }) => key === "borderWidth")?.value) || 0,
            borderColor: shape.extraStyleData.find(({ key }) => key === "borderColor")?.value || "#000000",
          },
          shapeId: shape.shapeId,
          id: id++,
          position: {
            x: shape.shapePositionX,
            y: shape.shapePositionY,
          },
          size: {
            width: shape.shapeWidth,
            height: shape.shapeHeight,
          },
          layerOrder: shape.layerOrder,
          shapeImageUrl: shape.extraStyleData.find(({ key }) => key === "shapeImageUrl")?.value || "",
          svgData,
        },
      };
    });
    return { objects, id };
  };

  const getInitialMedia = (customImages: CustomImageDetails[], id: number) => {
    return customImages.map((image): SceneObject => {
      return {
        type: ObjectTypes.media,
        object: {
          id: id++,
          position: {
            x: image.customImagePositionX,
            y: image.customImagePositionY,
          },
          size: {
            width: image.customImageWidth,
            height: image.customImageHeight,
          },
          layerOrder: image.layerOrder,
          src: image.extraStyleData.find(({ key }) => key === "src")?.value || "",
          customImageDetailId: image.customImageDetailId,
        },
      };
    });
  };

  const getInitialParagraphs = (paragraphs: any[], sceneId: number) => {
    return paragraphs.map(
      (item): HumansParagraph => ({
        projectParagraphId: item.projectParagraphId,
        order: item.order,
        actorId: item.actorId,
        data: item.data,
        actor: item.actor,
        // outputAudio: item.outputAudio,
        sceneId,
      }),
    );
  };

  const setInitialSlides = (
    project: Project,
    setEditorContent: React.Dispatch<React.SetStateAction<EditorState[]>>,
    setParagraphs: (nextParagraphs: HumansParagraph[]) => void,
  ) => {
    const slides = project.slides;
    if (!slides || !slides?.length) return;

    let paragraphs: HumansParagraph[] = [];

    const scenes = slides.map((slide, index): SceneType => {
      const backgroundId = slide.backGroundAssetId;
      const { objects: newTexts, id: newId } = getInitialTexsts(slide.customTexts);
      const { objects: newShapes, id } = getInitialShapes(slide.shapeDetails, newId);
      const newMedia = getInitialMedia(slide.customImageDetails, id);
      const objects = [...newTexts, ...newShapes, ...newMedia];

      const extraProps = JSON.parse(slide.extraStyleJsonText || "{}");

      if (extraProps?.isActive) {
        handleChangeActiveScene(slide.slideId);
      }

      const avatarSrc = slide.faceImage || slide.aiHumanActor?.photo;

      handleChangeActorPositionType(slide.actorPositionType);

      const avatar: SceneObject = {
        type: ObjectTypes.avatars,
        object: {
          src: avatarSrc || "",
          allowCircle: slide.aiHumanActor?.allowCircle || null,
          aiHumanActorId: slide.aiHumanActorId,
          actorPositionType: slide.actorPositionType,
          isSadtalker: slide.aiHumanActor?.category === "Custom" ? true : false || null,
          id: objects.length ? objects[objects.length - 1].object.id + 1 : 1,
          position: {
            x: slide.actorPositionX,
            y: slide.actorPositionY,
          },
          size: {
            width: slide.actorSizeWidth,
            height: slide.actorSizeHeight,
          },
          background: slide.backGroundColor || "#ffffff",
          layerOrder: slide.humatarLayerOrder,
          faceImage: slide.faceImage,
        },
      };

      if (slide.projectParagraphs.length)
        paragraphs = [...paragraphs, ...getInitialParagraphs(slide.projectParagraphs, slide.slideId)];

      return {
        id: slide.slideId,
        sceneId: slide.slideId,
        audioPath: slide.audioPath,
        order: typeof slide.order === "number" ? slide.order : index + 1,
        activeObjectId: 0,
        editableTextId: 0,
        background: (slide.backgroundAsset || DEFAULT_BACKGROUND_ASSET)?.path,
        objects: (avatarSrc ? [...objects, avatar] : [...objects]).sort(
          (prev, next) => prev.object.layerOrder - next.object.layerOrder,
        ),
        transitionEffectId: slide.slideTransitionEffectId,
        backgroundId,
        backgroundAssetId: (slide.backgroundAsset || DEFAULT_BACKGROUND_ASSET)?.assetTypeId,
        slideBackgroundColor: slide.slideBackgroundColor || "",
        startBackgroundVideoTime: slide.startBackgroundVideoTime || null,
        endBackgroundVideoTime: slide.endBackgroundVideoTime || null,
        initiallyUpdatedScale: false,
      };
    });
    setScenes(scenes);

    if (paragraphs?.length) {
      setEditorContent(paragraphs.map((paragraph) => dataToState(paragraph.data)));
      setParagraphs(paragraphs);
    }

    setCanvasInitialWidth({ value: project.canvasWidth });
  };

  const addSlides = (
    project: Project,
    setEditorContent: React.Dispatch<React.SetStateAction<EditorState[]>>,
    setParagraphs: (nextParagraphs: HumansParagraph[]) => void,
    initialParagraphs?: HumansParagraph[],
  ) => {
    const slides = project.slides;
    if (!slides || !slides?.length) return;

    let paragraphs: HumansParagraph[] = initialParagraphs || [];

    const slideId = scenes.length ? getMathObjectArray(scenes, "id") + 1 : 0;

    const newScenes = slides.map((slide, index): SceneType => {
      const backgroundId = slide.backGroundAssetId;
      const { objects: newTexts, id: newId } = getInitialTexsts(slide.customTexts);
      const { objects: newShapes, id } = getInitialShapes(slide.shapeDetails, newId);
      const newMedia = getInitialMedia(slide.customImageDetails, id);
      const objects = [...newTexts, ...newShapes, ...newMedia];

      const extraProps = JSON.parse(slide.extraStyleJsonText || "{}");

      if (extraProps?.isActive) {
        handleChangeActiveScene(slideId + index);
      }

      const avatarSrc = slide.aiHumanActor?.photo;

      handleChangeActorPositionType(slide.actorPositionType);

      const avatar: SceneObject = {
        type: ObjectTypes.avatars,
        object: {
          src: avatarSrc || "",
          allowCircle: slide.aiHumanActor?.allowCircle || null,
          aiHumanActorId: slide.aiHumanActorId,
          actorPositionType: slide.actorPositionType,
          isSadtalker: slide.aiHumanActor?.category === "Custom" ? true : false || null,
          id: objects.length ? objects[objects.length - 1].object.id + 1 : 1,
          position: {
            x: slide.actorPositionX,
            y: slide.actorPositionY,
          },
          size: {
            width: slide.actorSizeWidth,
            height: slide.actorSizeHeight,
          },
          background: slide.backGroundColor || "#ffffff",
          layerOrder: slide.humatarLayerOrder,
          faceImage: slide.faceImage,
        },
      };

      if (slide.projectParagraphs.length)
        paragraphs = [...paragraphs, ...getInitialParagraphs(slide.projectParagraphs, slideId + index)];

      return {
        id: slideId + index,
        sceneId: 0,
        audioPath: slide.audioPath,
        order: scenes.length + index + 1,
        activeObjectId: 0,
        editableTextId: 0,
        background: (slide.backgroundAsset || DEFAULT_BACKGROUND_ASSET)?.path,
        objects: (avatarSrc ? [...objects, avatar] : [...objects]).sort(
          (prev, next) => prev.object.layerOrder - next.object.layerOrder,
        ),
        transitionEffectId: slide.slideTransitionEffectId,
        backgroundId,
        backgroundAssetId: (slide.backgroundAsset || DEFAULT_BACKGROUND_ASSET)?.assetTypeId,
        slideBackgroundColor: slide.slideBackgroundColor || "",
        startBackgroundVideoTime: slide.startBackgroundVideoTime || null,
        endBackgroundVideoTime: slide.endBackgroundVideoTime || null,
        initiallyUpdatedScale: false,
      };
    });

    setScenes([...scenes, ...newScenes]);

    if (paragraphs?.length) {
      setEditorContent(paragraphs.map((paragraph) => dataToState(paragraph.data)));
      setParagraphs(paragraphs);
    }

    setCanvasInitialWidth({ value: project.canvasWidth });
  };

  return {
    dublicateScene,
    addScene,
    handleDeleteScene,
    handleAddText,
    handleAddShape,
    handleAddAvatar,
    handleRemoveTextChip,
    handleSceneObjectChange,
    handleInputChange,
    updatePosition,
    updateSize,
    handleChangeActiveObject,
    handleChangeActiveScene,
    handleBackgroundChange,
    deleteAllText,
    setEditableTextId,
    handleTransitionChange,
    handleUpdateSoundtrack,
    getFullProject,
    addSlides,
    setInitialSlides,
    changeObjectLayer,
    scenes: currentScenes || scenes,
    currentScene,
    activeSceneId,
    handleBackInHistory,
    handleForwardInHistory,
    currentHistoryIndex,
    historyLength,
    handleDeleteCurrentObject,
    handleDuplicateObject,
    setScenes,
    canvasInitialWidth,
    actorPositionType,
    handleChangeActorPositionType,
    addFontToDocument,
    fontList,
    handleChangeSelected,
    selectedFont,
    updateFaceImage,
    updateCanvasInitialWidth,
    handleAddMedia,
    isFontLoading,
    handleBackgroundColorChange,
    handleBackgroundVideoTime,
  };
};
