const TEMPLATES = "TEMPLATES_";

export const GET_TEMPLATES_SERVER = "GET_TEMPLATES_SERVER";
export const UPDATE_TEMPLATE_LOADING = "UPDATE_TEMPLATE_LOADING";
export const UPDATE_HAS_MORE_TEMPLATES = "UPDATE_HAS_MORE_TEMPLATES";
export const SET_PAGE_TEMPLATES = "SET_PAGE_TEMPLATES";
export const CLEAR_TEMPLATES = "CLEAR_TEMPLATES";
export const CLEAR_SEARCH = TEMPLATES + "CLEAR_SEARCH";
export const SEARCH_RESULT_LOADING = "SEARCH_RESULT_LOADING";

export const UPDATE_IS_EMPTY_TEMPLATES = "UPDATE_IS_EMPTY_TEMPLATES";
export const IMPORT_TEMPLATE_SERVER = "IMPORT_TEMPLATE_SERVER";

interface UpdateTemplateLoadingProps {
  isLoading: boolean;
}

interface GetTemplatesProps {
  keyword?: string;
  pageNumber?: number;
  pageSize?: number;
}

interface UpdateHasMoreTemplatesProps {
  hasMore: boolean;
}

interface UpdateIsEmptyTemplatesProps {
  isEmpty: boolean;
}

interface SetPageTemplatesProps {
  pageNumber: number;
}

export const updateHasMoreTemplates = ({ hasMore }: UpdateHasMoreTemplatesProps) => ({
  type: UPDATE_HAS_MORE_TEMPLATES,
  payload: {
    hasMore,
  },
});

export const updateIsEmptyTemplates = ({ isEmpty }: UpdateIsEmptyTemplatesProps) => ({
  type: UPDATE_IS_EMPTY_TEMPLATES,
  payload: {
    isEmpty,
  },
});

export const setPageTemplates = ({ pageNumber }: SetPageTemplatesProps) => ({
  type: SET_PAGE_TEMPLATES,
  payload: { pageNumber },
});

export const updateTemplateLoading = ({ isLoading }: UpdateTemplateLoadingProps) => ({
  type: UPDATE_TEMPLATE_LOADING,
  payload: {
    isLoading,
  },
});

export const getTemplatesServer = ({ keyword, pageNumber, pageSize }: GetTemplatesProps) => ({
  type: GET_TEMPLATES_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/getAllTemplates",
      data: {
        pageNumber,
        keyword,
        pageSize: pageSize || 16,
      },
    },
  },
});

export const clearTemplates = () => ({
  type: CLEAR_TEMPLATES,
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

export const searchTemplatesResultLoading = (loading: boolean) => ({
  type: SEARCH_RESULT_LOADING,
  payload: loading,
});

interface ImportTemplateServerProps {
  formData: FormData;
}

export const importTemplateServer = ({ formData: data }: ImportTemplateServerProps) => ({
  type: IMPORT_TEMPLATE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/User/convertPdf2Images",
      data,
    },
  },
});
