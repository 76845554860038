import React from "react";
import styled from "styled-components";

interface Props {
  icon: React.ReactElement;
  title: string;
}
const IconComponent = ({ icon, title }: Props) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 12px 30px;
  gap: 12px;
`;

const IconWrapper = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #ffffff;
  border-radius: 40px;
`;

const Title = styled.div`
  font-family: Mulish;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #ffffff;
`;

export default IconComponent;
