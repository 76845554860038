import { useState } from "react";
import { ProfileHumanSidebarType } from "../types/human";

export const useGlobalSidebar = () => {
  const [activeGlobalTab, setActiveGlobalTab] = useState<ProfileHumanSidebarType>(ProfileHumanSidebarType.Humatar);

  return {
    activeGlobalTab,
    setActiveGlobalTab,
  };
};
