import { UploadVideoTabs, VideoTranslationModules } from "../../types/videoTranslation";

export const GET_VIDEO_TRANSLATE_PROJECTS_SERVER = "GET_VIDEO_TRANSLATE_PROJECTS_SERVER";
export const GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER = "GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER";
export const ADD_VIDEO_TRANSLATE_PROJECT_SERVER = "ADD_VIDEO_TRANSLATE_PROJECT_SERVER";
export const FACE_DETECT_SERVER = "FACE_DETECT_SERVER";
export const GET_YOUTUBE_URL_SERVER = "GET_YOUTUBE_URL_SERVER";

export const UPDATE_VIDEO_TRANSLATE_IS_LOADING = "UPDATE_VIDEO_TRANSLATE_IS_LOADING";
export const UPDATE_SINGLE_VIDEO_DATA = "UPDATE_SINGLE_VIDEO_DATA";
export const UPDATE_VIDEO_SERVICE_LOADING = "UPDATE_VIDEO_SERVICE_LOADING";
export const CLEAR_VIDEO_SERVICE_URL = "CLEAR_VIDEO_SERVICE_URL";
export const CLEAR_FACE_DETECT = "CLEAR_FACE_DETECT";

export const GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER = "GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER";

interface GetVideoTranslateProjectsServerProps {
  isLoading?: boolean;
}

interface GetVideoTranslateProjectByIdServerProps {
  id: number;
}

interface UpdateVideoTranslateIsLoading {
  module: VideoTranslationModules;
  isLoading: boolean;
}

interface UpdateSingleVideoDataProps {
  data: Record<string, string | number | null> | null;
}

interface UpdateVideoServiceLoading {
  videoServiceType: UploadVideoTabs;
  isLoading: boolean;
}

interface GenerateVideoToTransatedTextServer {
  language: string;
  formData: FormData;
}

export const getVideoTranslateProjectsServer = ({ isLoading }: GetVideoTranslateProjectsServerProps) => ({
  type: GET_VIDEO_TRANSLATE_PROJECTS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/VideoTranslateProject/list",
    },
    isLoading,
  },
});

export const getVideoTranslateProjectByIdServer = ({ id }: GetVideoTranslateProjectByIdServerProps) => ({
  type: GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/VideoTranslateProject/get?Id=${id}`,
    },
  },
});

export const addVideoTranslateProjectServer = (formData: FormData) => ({
  type: ADD_VIDEO_TRANSLATE_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "VideoTranslateProject/add",
      data: formData,
    },
  },
});

export const updateVideoTranslateIsLoading = ({ module, isLoading }: UpdateVideoTranslateIsLoading) => ({
  type: UPDATE_VIDEO_TRANSLATE_IS_LOADING,
  payload: { module, isLoading },
});

export const updateSingleVideoData = ({ data }: UpdateSingleVideoDataProps) => ({
  type: UPDATE_SINGLE_VIDEO_DATA,
  payload: data,
});

export const faceDetectServer = (formData: FormData) => ({
  type: FACE_DETECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/VideoTranslateProject/faceDetectImage",
      data: formData,
    },
  },
});

export const getYoutubeUrlServer = (url: string) => ({
  type: GET_YOUTUBE_URL_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/VideoTranslateProject/GetYoutubeUrl?url=${url}`,
    },
  },
});

export const updateVideoServiceLoading = ({ videoServiceType, isLoading }: UpdateVideoServiceLoading) => ({
  type: UPDATE_VIDEO_SERVICE_LOADING,
  payload: { videoServiceType, isLoading },
});

export const clearVideoServiceUrl = (videoServiceType: UploadVideoTabs) => ({
  type: CLEAR_VIDEO_SERVICE_URL,
  payload: { videoServiceType },
});

export const clearFaceDetect = () => ({
  type: CLEAR_FACE_DETECT,
});

export const generateVideoToTransatedTextServer = ({ language, formData }: GenerateVideoToTransatedTextServer) => ({
  type: GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `VideoTranslateProject/videoToTranslatedText?lang=${language.toLowerCase()}`,
      data: formData,
    },
  },
});
