import React from "react";
import styled from "styled-components";
import { ShapeBorder } from "../../types/scene";

interface Props {
  background: string;
  opacity: number;
  border: ShapeBorder;
}

export const Triangle = ({ background, opacity, border }: Props) => (
  <StyledDiv background={background} opacity={opacity} border={border} className={"shape0"} />
);

const StyledDiv = styled.div<{ background: string; opacity: number; border: ShapeBorder }>`
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  opacity: ${({ opacity }) => opacity};
  background: ${({ background }) => background};
`;
