import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  Avatar,
  BackgroundColor,
  Media,
  ObjectTypes,
  Scene as SceneType,
  SceneObject as SceneObjectType,
  Shape,
  Text,
} from "../../../types/scene";

import SceneObject from "./SceneObject";
import SceneObjectWrapper from "./SceneObjectWrapper";
import { VideoEditorContext } from "../../../App";
import TrimVideo from "../../AIHumans/components/TrimVideo";
import { addPx, removePx } from "../../../lib/stylesHelper";

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
  canvasSize: { width: number; height: number };
  isTrimVideo: boolean;
  handleTrimVideoClose: () => void;
}

const getObjectKey = ({ object, type }: SceneObjectType) => {
  switch (type) {
    case ObjectTypes.texts: {
      return (object as Text).customTextId;
    }
    case ObjectTypes.shapes: {
      return (object as Shape).shapeDetailId;
    }
    default: {
      return object.id;
    }
  }
};

const Scene = ({ containerRef, canvasSize, isTrimVideo, handleTrimVideoClose }: Props) => {
  const {
    handleChangeActiveObject,
    setEditableTextId,
    currentScene,
    setScenes,
    canvasInitialWidth,
    updateCanvasInitialWidth,
    scenes,
  } = useContext(VideoEditorContext);
  const { background, slideBackgroundColor, activeObjectId, objects, editableTextId } = currentScene || {};

  const [prevCanvasSize, setPrevCanvasSize] = useState<typeof canvasSize | null>(null);

  const handleDisactivateObjects = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (activeObjectId === editableTextId) {
      setEditableTextId(NaN);
      return;
    }
    handleChangeActiveObject(NaN);
  };

  const updatePosSize = ({
    currentWidth,
    initialWidth,
    isInitialUpdate,
  }: {
    currentWidth: number;
    initialWidth: number;
    isInitialUpdate: boolean;
  }) => {
    const coefficient = currentWidth / initialWidth;
    setScenes(
      scenes.map((scene) => {
        const isInitiallyUpdated = scene.initiallyUpdatedScale;

        if (isInitialUpdate && isInitiallyUpdated) {
          return scene;
        }

        return {
          ...scene,
          initiallyUpdatedScale: true,
          objects: scene.objects.map((object: SceneObjectType) => {
            const newPosition = { ...object.object.position };
            const newSize = { ...object.object.size };

            newSize.width *= coefficient;
            newSize.height *= coefficient;

            newPosition.x *= coefficient;
            newPosition.y *= coefficient;

            const newDisplayedFontSizeStr: string | undefined = (object.object as Text)?.displayedFontSize;

            if (newDisplayedFontSizeStr) {
              const displayedFontSizeWithoutPx = +removePx(newDisplayedFontSizeStr);
              const newDisplayedFontSizeNum = displayedFontSizeWithoutPx * coefficient;

              return {
                ...object,
                object: {
                  ...object.object,
                  position: newPosition,
                  size: newSize,
                  displayedFontSize: addPx(String(newDisplayedFontSizeNum)),
                },
              };
            }

            return { ...object, object: { ...object.object, position: newPosition, size: newSize } };
          }),
        };
      }),
    );
  };

  useEffect(() => {
    if (canvasSize.width === 0) return;
    if (!prevCanvasSize) {
      return setPrevCanvasSize(canvasSize);
    }
    updatePosSize({ currentWidth: canvasSize.width, initialWidth: prevCanvasSize.width, isInitialUpdate: false });
    setPrevCanvasSize(canvasSize);
  }, [canvasSize]);

  useEffect(() => {
    if (canvasInitialWidth.value === 0 || canvasSize.width === 0) return;
    updatePosSize({ currentWidth: canvasSize.width, initialWidth: canvasInitialWidth.value, isInitialUpdate: true });
  }, [canvasInitialWidth]);

  useEffect(() => {
    return () => updateCanvasInitialWidth(0);
  }, []);

  const sceneObjects = objects?.map(({ object: obj, type }, index) => (
    <SceneObjectWrapper
      index={index}
      key={getObjectKey({ object: obj, type })}
      obj={obj}
      objectType={type}
      dragHandleClassName={type === ObjectTypes.texts && activeObjectId !== obj.id ? "text-object" : undefined}
    >
      <ObjectWrapper>
        <SceneObject object={{ object: obj, type }} />
      </ObjectWrapper>
    </SceneObjectWrapper>
  ));

  return currentScene?.backgroundAssetId === 2 && !slideBackgroundColor ? (
    <>
      <VideoBackgroundWrapper ref={containerRef} onClick={handleDisactivateObjects}>
        {!isTrimVideo ? (
          <>
            {sceneObjects}
            <VideoBackground src={background} autoPlay loop muted />
            {objects &&
              objects
                .filter((item) => item.type === ObjectTypes.avatars || item.type === ObjectTypes.shapes)
                .map((item) => (
                  <Bounds
                    key={item.object.id}
                    id={`avatar-scene-bounds${item.object.id}`}
                    width={item.object.size.width * 0.9}
                    height={item.object.size.height * 0.9}
                  />
                ))}
          </>
        ) : (
          <TrimVideo videoSrc={background} onClick={handleTrimVideoClose} />
        )}
      </VideoBackgroundWrapper>
      {isTrimVideo && <Darkener onClick={handleTrimVideoClose} />}
    </>
  ) : (
    <ImageBackground
      ref={containerRef}
      onClick={handleDisactivateObjects}
      src={slideBackgroundColor ? slideBackgroundColor : background || ""}
    >
      {sceneObjects}
      {objects &&
        objects
          .filter((item) => item.type === ObjectTypes.avatars || item.type === ObjectTypes.shapes)
          .map((item) => (
            <Bounds
              key={item.object.id}
              id={`avatar-scene-bounds${item.object.id}`}
              width={item.object.size.width * 0.9}
              height={item.object.size.height * 0.9}
            />
          ))}
    </ImageBackground>
  );
};

const ImageBackground = styled.div<{ src: string | BackgroundColor }>`
  position: relative;
  user-select: none;
  border-radius: 20px;
  background-size: cover;
  padding: 10px;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.cardShadow};

  ${({ src }) =>
    /^https?:\/\/\S+$/.test(src)
      ? ` 
      background-image: url(${src});
      background-color: none;
      background-position: center;
    `
      : `
      background-image: url(none);
      background-color: ${src};
    `}

  & .react-resizable-handle {
    bottom: -10px;
    right: -10px;
  }
`;

const VideoBackgroundWrapper = styled.div`
  position: relative;
  overflow: hidden;
  user-select: none;
  border-radius: 20px;
  padding: 10px;
  margin: 0 auto;
  z-index: 21;
  box-shadow: ${({ theme }) => theme.cardShadow};

  & .react-resizable-handle {
    bottom: -10px;
    right: -10px;
  }
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const ObjectWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  // z-index: 10;
`;

const Bounds = styled.div<{ width: number; height: number }>`
  position: absolute;
  pointer-events: none;
  visible: none;
  top: -${({ height }) => height}px;
  left: -${({ width }) => width}px;
  width: calc(100% + 2 * ${({ width }) => width}px);
  height: calc(100% + 2 * ${({ height }) => height}px);
`;

const Darkener = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background: rgba(25, 27, 31, 0.76);
  backdrop-filter: blur(6px);
`;

export default Scene;
