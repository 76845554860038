import { StoreType } from "../../types/store";
import { Template } from "../../types/template";
import {
  CLEAR_SEARCH,
  CLEAR_TEMPLATES,
  GET_TEMPLATES_SERVER,
  IMPORT_TEMPLATE_SERVER,
  SEARCH_RESULT_LOADING,
  SET_PAGE_TEMPLATES,
  UPDATE_HAS_MORE_TEMPLATES,
  UPDATE_IS_EMPTY_TEMPLATES,
  UPDATE_TEMPLATE_LOADING,
} from "../actions/templateAction";

export interface templateStateType {
  data: Template[];
  search: string;
  pageNumber: number;
  hasMore: boolean;
  isLoading: boolean;
  searchLoading: boolean;
  isEmpty: boolean;
  importedPictures: [];
  isImportTemplate: boolean;
}

const templateInitialState: templateStateType = {
  data: [],
  search: "",
  pageNumber: 0,
  isLoading: false,
  hasMore: true,
  searchLoading: false,
  isEmpty: false,
  importedPictures: [],
  isImportTemplate: false,
};

const templateReducer = (state = templateInitialState, action: any) => {
  switch (action.type) {
    case GET_TEMPLATES_SERVER: {
      return {
        ...state,
        hasMore: true,
      };
    }
    case CLEAR_TEMPLATES: {
      return {
        ...state,
        data: [],
        pageNumber: 0,
      };
    }
    case CLEAR_SEARCH: {
      return {
        ...state,
        search: "",
      };
    }
    case UPDATE_TEMPLATE_LOADING: {
      const { isLoading } = action.payload;
      return { ...state, isLoading };
    }
    case UPDATE_HAS_MORE_TEMPLATES: {
      return {
        ...state,
        hasMore: action.payload.hasMore,
      };
    }
    case UPDATE_IS_EMPTY_TEMPLATES: {
      return {
        ...state,
        isEmpty: action.payload.isEmpty,
      };
    }
    case `${GET_TEMPLATES_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;

      return {
        ...state,
        data: pageNumber === 1 ? data : [...state.data, ...data],
        search: action.meta.previousAction.payload.request.data.keyword,
        pageNumber: state.pageNumber + 1,
      };
    }
    case SET_PAGE_TEMPLATES: {
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    }
    case SEARCH_RESULT_LOADING: {
      return {
        ...state,
        searchLoading: action.payload,
      };
    }
    case IMPORT_TEMPLATE_SERVER: {
      return {
        ...state,
        isImportTemplate: true,
      };
    }
    case `${IMPORT_TEMPLATE_SERVER}_FAIL`:
    case `${IMPORT_TEMPLATE_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      return {
        ...state,
        isImportTemplate: false,
        importedPictures: data ? data : [],
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getTemplates = (state: StoreType) => state.template.data;
export const getTemplatesLoading = (state: StoreType) => state.template.isLoading;
export const getTemplatesIsEmpty = (state: StoreType) => state.template.isEmpty;

export const getHasMoreTemplates = (state: StoreType) => state.template.hasMore;
export const getCurrentPageTemplates = (state: StoreType) => state.template.pageNumber;

export const searchResultLoading = (state: StoreType) => state.template.searchLoading;
export const importTemplateLoading = (state: StoreType) => state.template.isImportTemplate;
export const getImportedPictures = (state: StoreType) => state.template.importedPictures;

export default templateReducer;
