import { DesktopIcon, MobileIcon, AIHumansPause, PitchIcon, WorldIcon } from "../components/Icons/Icons";
import { FeaturesSettings, IHuman, ProfileHumanSidebarType } from "../types/human";

export const humans: IHuman[] = [
  {
    id: 0,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 1,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 2,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 3,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 4,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 5,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 6,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 7,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
  {
    id: 8,
    imageSrc: "/images/human.png",
    flagSrc: "/images/flag.png",
    name: "Steave M.",
  },
];

export enum HumatarProps {
  ALL_ACTORS = "All Actors",
  SEATED = "Seated",
  CUSTOM = "Custom",
}

export const humatarTabs = [HumatarProps.ALL_ACTORS, HumatarProps.SEATED, HumatarProps.CUSTOM];

export enum BackgroundProps {
  IMAGE = "Image",
  VIDEO = "Video",
  COLOR = "Color",
}

export const backgroundTabs = [BackgroundProps.IMAGE, BackgroundProps.VIDEO, BackgroundProps.COLOR];

export enum ShapesProps {
  SHAPES = "Shapes",
  FRAMES = "Frames",
  ICONS = "Icons",
  IMAGES = "Images",
  STICKERS = "Stickers",
  VIDEOS = "Videos",
}

export const shapeTabs = [
  ShapesProps.SHAPES,
  ShapesProps.FRAMES,
  ShapesProps.ICONS,
  ShapesProps.IMAGES,
  ShapesProps.STICKERS,
  ShapesProps.VIDEOS,
];

export enum ScreenProps {
  DESKTOP = "Desktop",
  MOBILE = "Mobile",
}

export const colors = [
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#DCE775",
  "#FF8A65",
  "#BA68C8",
  "#FF6900",
  "#FCB900",
  "#7BDCB5",
  "#8ED1FC",
  "#ABB8C3",
  "#EB144C",
  "#795548",
  "#607D8B",
  "#3F51B5",
  "#FFEB3B",
];

export const screens = [
  { id: 1, icon: <DesktopIcon />, type: ScreenProps.DESKTOP },
  { id: 2, icon: <MobileIcon />, type: ScreenProps.MOBILE },
];

export enum MediaProps {
  MEDIA = "Media",
  AIIMAGES = "AI Images",
}

export const mediaTabs = [MediaProps.MEDIA, MediaProps.AIIMAGES];

export const initialParagraphsData = [
  {
    actorId: 1,
    actor: {
      actorId: 1,
      actorTypeId: 1,
      flagPath: "",
      isBookmarked: false,
      language: "en-US",
      name: "Sonia",
      photo: "",
      audioSampleLink: "",
      brief: "",
      categoryType: "",
      content: "",
      mood: "",
      region: "",
      voiceAge: "",
      voiceName: "",
    },
    order: 1,
    data: [
      {
        text: "",
        features: [
          {
            key: "",
            value: "",
          },
        ],
      },
    ],
  },
];

export const sidebar = [
  {
    type: ProfileHumanSidebarType.Background,
    data: [
      {
        type: BackgroundProps.IMAGE,
        data: [
          { id: 1, image: "/images/mock.png" },
          { id: 2, image: "/images/mock.png" },
          { id: 3, image: "/images/mock.png" },
          { id: 4, image: "/images/mock.png" },
          { id: 5, image: "/images/mock.png" },
          { id: 6, image: "/images/mock.png" },
          { id: 7, image: "/images/mock.png" },
          { id: 8, image: "/images/mock.png" },
          { id: 9, image: "/images/mock.png" },
          { id: 10, image: "/images/mock.png" },
          { id: 11, image: "/images/mock.png" },
          { id: 12, image: "/images/mock.png" },
        ],
      },
      {
        type: BackgroundProps.VIDEO,
        data: [
          { id: 1, video: "/images/mock.png" },
          { id: 2, video: "/images/mock.png" },
          { id: 3, video: "/images/mock.png" },
          { id: 4, video: "/images/mock.png" },
          { id: 5, video: "/images/mock.png" },
          { id: 6, video: "/images/mock.png" },
          { id: 7, video: "/images/mock.png" },
          { id: 8, video: "/images/mock.png" },
          { id: 9, video: "/images/mock.png" },
          { id: 10, video: "/images/mock.png" },
          { id: 11, video: "/images/mock.png" },
          { id: 12, video: "/images/mock.png" },
        ],
      },
    ],
  },
  {
    type: ProfileHumanSidebarType.Text,
  },
  {
    type: ProfileHumanSidebarType.Humatar,
    data: [
      {
        id: 1,
        image: "/images/humatar-1.png",
      },
      {
        id: 2,
        image: "/images/ai-human-pic.png",
      },
      {
        id: 3,
        image: "/images/humatar-2.png",
      },
      {
        id: 4,
        image: "/images/humatar-3.png",
      },
      {
        id: 5,
        image: "/images/humatar-4.png",
      },
      {
        id: 6,
        image: "/images/humatar-5.png",
      },
      {
        id: 7,
        image: "/images/humatar-1.png",
      },
      {
        id: 8,
        image: "/images/ai-human-pic.png",
      },
      {
        id: 9,
        image: "/images/humatar-2.png",
      },
      {
        id: 10,
        image: "/images/humatar-3.png",
      },
      {
        id: 11,
        image: "/images/humatar-4.png",
      },
      {
        id: 12,
        image: "/images/humatar-5.png",
      },
      {
        id: 13,
        image: "/images/humatar-1.png",
      },
      {
        id: 14,
        image: "/images/ai-human-pic.png",
      },
      {
        id: 15,
        image: "/images/humatar-2.png",
      },
      {
        id: 16,
        image: "/images/humatar-3.png",
      },
      {
        id: 17,
        image: "/images/humatar-4.png",
      },
    ],
  },
  {
    type: ProfileHumanSidebarType.Soundtrack,
    data: [
      {
        id: 1,
        title: "Corporate",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 2,
        title: "Inspiring",
        soundtrack: "/audio/audio-2.mp3",
      },
      {
        id: 3,
        title: "Relaxing",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 4,
        title: "Inspiring",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 5,
        title: "Motivation",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 6,
        title: "Modern",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 7,
        title: "Happy",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 8,
        title: "Melanholic",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 9,
        title: "Relaxing",
        soundtrack: "/audio/audio-1.mp3",
      },
      {
        id: 10,
        title: "Inspiring",
        soundtrack: "/audio/audio-1.mp3",
      },
    ],
  },
  {
    type: ProfileHumanSidebarType.Transitions,
    data: [
      {
        id: 1,
        transitionName: "Fade",
      },
      {
        id: 2,
        transitionName: "Close",
      },
      {
        id: 3,
        transitionName: "Crop",
      },
      {
        id: 4,
        transitionName: "Blur",
      },
      {
        id: 5,
        transitionName: "Open",
      },
      {
        id: 6,
        transitionName: "Slide",
      },
      {
        id: 7,
        transitionName: "Wipe",
      },
      {
        id: 8,
        transitionName: "Smooth",
      },
    ],
  },
  {
    type: ProfileHumanSidebarType.Shapes,
    data: [
      {
        id: 1,
        shapeName: "Circle",
      },
      {
        id: 2,
        shapeName: "Rectangle",
      },
      {
        id: 3,
        shapeName: "Triangle",
      },
      {
        id: 4,
        shapeName: "Polygon",
      },
      {
        id: 5,
        shapeName: "Star",
      },
      {
        id: 6,
        shapeName: "Square",
      },
      {
        id: 7,
        shapeName: "Heart",
      },
      {
        id: 8,
        shapeName: "Arrow",
      },
    ],
  },
  {
    type: ProfileHumanSidebarType.Media,
  },
  {
    type: ProfileHumanSidebarType.Templates,
  },
];

export const featuresSettings: FeaturesSettings = {
  id: 1,
  image: "/images/ai-human-pic.png",
  name: "Steave B.",
  flag: "/images/flag.png",
  features: [
    {
      id: 1,
      title: "translate",
      icon: <WorldIcon />,
    },
    {
      id: 2,
      title: "speed",
      defaultTitle: "speed",
      icon: <img src="/images/editor/speed-icon.svg" />,
      step: 6.66,
      defaultValue: "0.5",
      modifier: "x",
      values: [
        {
          id: 1,
          text: "0.5",
          label: "0.5",
        },
        {
          id: 2,
          text: "0.6",
          label: "0.6",
        },
        {
          id: 3,
          text: "0.7",
          label: "0.7",
        },
        {
          id: 4,
          text: "0.8",
          label: "0.8",
        },
        {
          id: 5,
          text: "0.9",
          label: "0.9",
        },
        {
          id: 6,
          text: "1",
          label: "1",
        },
        {
          id: 7,
          text: "1.1",
          label: "1.1",
        },
        {
          id: 8,
          text: "1.2",
          label: "1.2",
        },
        {
          id: 9,
          text: "1.3",
          label: "1.3",
        },
        {
          id: 10,
          text: "1.4",
          label: "1.4",
        },
        {
          id: 11,
          text: "1.5",
          label: "1.5",
        },
        {
          id: 12,
          text: "1.6",
          label: "1.6",
        },
        {
          id: 13,
          text: "1.7",
          label: "1.7",
        },
        {
          id: 14,
          text: "1.8",
          label: "1.8",
        },
        {
          id: 15,
          text: "1.9",
          label: "1.9",
        },
        {
          id: 16,
          text: "2",
          label: "2",
        },
      ],
    },
    {
      id: 3,
      title: "pitch",
      defaultTitle: "pitch",
      icon: <PitchIcon />,
      step: 25,
      defaultValue: "1",
      modifier: "x",
      values: [
        {
          id: 1,
          text: "0.5",
          label: "0.5",
        },
        {
          id: 2,
          text: "1",
          label: "1",
        },
        {
          id: 3,
          text: "1.5",
          label: "1.5",
        },
        {
          id: 4,
          text: "2",
          label: "2",
        },
        {
          id: 5,
          text: "2.5",
          label: "2.5",
        },
      ],
    },
    {
      id: 4,
      title: "pause",
      defaultTitle: "pause",
      icon: <AIHumansPause />,
      step: 2,
      defaultValue: "0",
      modifier: "s",
      values: [
        {
          id: 1,
          text: "0",
          label: "0",
        },
        {
          id: 2,
          text: "0.1",
          label: "0.1",
        },
        {
          id: 3,
          text: "0.2",
          label: "0.2",
        },
        {
          id: 4,
          text: "0.3",
          label: "0.3",
        },
        {
          id: 5,
          text: "0.4",
          label: "0.4",
        },
        {
          id: 6,
          text: "0.5",
          label: "0.5",
        },
        {
          id: 7,
          text: "0.6",
          label: "0.6",
        },
        {
          id: 8,
          text: "0.7",
          label: "0.7",
        },
        {
          id: 9,
          text: "0.8",
          label: "0.8",
        },
        {
          id: 10,
          text: "0.9",
          label: "0.9",
        },
        {
          id: 11,
          text: "1",
          label: "1",
        },
        {
          id: 12,
          text: "1.1",
          label: "1.1",
        },
        {
          id: 13,
          text: "1.2",
          label: "1.2",
        },
        {
          id: 14,
          text: "1.3",
          label: "1.3",
        },
        {
          id: 15,
          text: "1.4",
          label: "1.4",
        },
        {
          id: 16,
          text: "1.5",
          label: "1.5",
        },
        {
          id: 17,
          text: "1.6",
          label: "1.6",
        },
        {
          id: 18,
          text: "1.7",
          label: "1.7",
        },
        {
          id: 19,
          text: "1.8",
          label: "1.8",
        },
        {
          id: 20,
          text: "1.9",
          label: "1.9",
        },
        {
          id: 21,
          text: "2",
          label: "2",
        },
        {
          id: 22,
          text: "2.1",
          label: "2.1",
        },
        {
          id: 23,
          text: "2.2",
          label: "2.2",
        },
        {
          id: 24,
          text: "2.3",
          label: "2.3",
        },
        {
          id: 25,
          text: "2.4",
          label: "2.4",
        },
        {
          id: 26,
          text: "2.5",
          label: "2.5",
        },
        {
          id: 27,
          text: "2.6",
          label: "2.6",
        },
        {
          id: 28,
          text: "2.7",
          label: "2.7",
        },
        {
          id: 29,
          text: "2.8",
          label: "2.8",
        },
        {
          id: 30,
          text: "2.9",
          label: "2.9",
        },
        {
          id: 31,
          text: "3",
          label: "3",
        },
        {
          id: 32,
          text: "3.1",
          label: "3.1",
        },
        {
          id: 33,
          text: "3.2",
          label: "3.2",
        },
        {
          id: 34,
          text: "3.3",
          label: "3.3",
        },
        {
          id: 35,
          text: "3.4",
          label: "3.4",
        },
        {
          id: 36,
          text: "3.5",
          label: "3.5",
        },
        {
          id: 37,
          text: "3.6",
          label: "3.6",
        },
        {
          id: 38,
          text: "3.7",
          label: "3.7",
        },
        {
          id: 39,
          text: "3.8",
          label: "3.8",
        },
        {
          id: 40,
          text: "3.9",
          label: "3.9",
        },
        {
          id: 41,
          text: "4",
          label: "4",
        },
        {
          id: 42,
          text: "4.1",
          label: "4.1",
        },
        {
          id: 43,
          text: "4.2",
          label: "4.2",
        },
        {
          id: 44,
          text: "4.3",
          label: "4.3",
        },
        {
          id: 45,
          text: "4.4",
          label: "4.4",
        },
        {
          id: 46,
          text: "4.5",
          label: "4.5",
        },
        {
          id: 47,
          text: "4.6",
          label: "4.6",
        },
        {
          id: 48,
          text: "4.7",
          label: "4.7",
        },
        {
          id: 49,
          text: "4.8",
          label: "4.8",
        },
        {
          id: 50,
          text: "4.9",
          label: "4.9",
        },
        {
          id: 51,
          text: "5",
          label: "5",
        },
      ],
    },
  ],
};

export const talkingImageTooltip = [
  "Photo Type: Use a medium shot with the subject facing the camera.",
  "Expression: The subject should have a neutral expression and closed mouth.",
  "Head Size: Ensure the head is at least 200x200 pixels in the image.",
  "Lighting: Use well-balanced, clear lighting.",
  "File Size: Photo should be no larger than 5MB.",
  "The image should not have a transparent background.",
];
