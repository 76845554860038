export const PopupGallery = () => (
  <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99902 14.5002C11.655 14.5002 12.999 13.1562 12.999 11.5002C12.999 9.84424 11.655 8.50024 9.99902 8.50024C8.34302 8.50024 6.99902 9.84424 6.99902 11.5002C6.99902 13.1562 8.34302 14.5002 9.99902 14.5002ZM30 32.5H7.122L21.132 20.81C21.624 20.392 22.516 20.394 22.998 20.808L32 28.488V30.5C32 31.604 31.104 32.5 30 32.5ZM5.99902 4.49976H29.999C31.103 4.49976 31.999 5.39576 31.999 6.49976V23.2278L25.593 17.7638C23.613 16.0798 20.515 16.0798 18.553 17.7518L3.99902 29.8958V6.49976C3.99902 5.39576 4.89502 4.49976 5.99902 4.49976ZM30 0.5H6C2.692 0.5 0 3.192 0 6.5V30.5C0 33.808 2.692 36.5 6 36.5H30C33.308 36.5 36 33.808 36 30.5V6.5C36 3.192 33.308 0.5 30 0.5Z"
      fill="url(#paint0_linear_2_6722)"
    />
    <mask id="mask0_2_6722" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="37">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99902 14.5002C11.655 14.5002 12.999 13.1562 12.999 11.5002C12.999 9.84424 11.655 8.50024 9.99902 8.50024C8.34302 8.50024 6.99902 9.84424 6.99902 11.5002C6.99902 13.1562 8.34302 14.5002 9.99902 14.5002ZM30 32.5H7.122L21.132 20.81C21.624 20.392 22.516 20.394 22.998 20.808L32 28.488V30.5C32 31.604 31.104 32.5 30 32.5ZM5.99902 4.49976H29.999C31.103 4.49976 31.999 5.39576 31.999 6.49976V23.2278L25.593 17.7638C23.613 16.0798 20.515 16.0798 18.553 17.7518L3.99902 29.8958V6.49976C3.99902 5.39576 4.89502 4.49976 5.99902 4.49976ZM30 0.5H6C2.692 0.5 0 3.192 0 6.5V30.5C0 33.808 2.692 36.5 6 36.5H30C33.308 36.5 36 33.808 36 30.5V6.5C36 3.192 33.308 0.5 30 0.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2_6722)">
      <rect x="-6" y="-5.5" width="48" height="48" fill="url(#paint1_linear_2_6722)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2_6722"
        x1="7.75385"
        y1="5.20769"
        x2="29.0769"
        y2="32.6231"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_6722"
        x1="4.33846"
        y1="0.776923"
        x2="32.7692"
        y2="37.3308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
