import React from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import TitleWithAction from "../TitleWithAction";
import Textfield from "../../../../components/Textfield/Textfield";
import Soundtrack from "../../../../components/Sountrack/Soundtrack";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import { cutText } from "../../../../lib/textUtils";
import { useSoundtrack } from "../../../../hooks/useSoundtrack";
import { ImportIcon, SearchIcon } from "../../../../components/Icons/Icons";
import { useSelector } from "react-redux";
import { getProfile } from "../../../../redux/reducers/profileReducer";

const ACCEPTED_FORMATS = "audio/mp3, audio/wav, audio/mpeg";
interface Props {
  backgroundSoundTrackId: number | null;
  handleBackgroundSoundTrackId: (id: number) => void;
}

const SoundtrackTab = ({ backgroundSoundTrackId, handleBackgroundSoundTrackId }: Props) => {
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const {
    search,
    activeSoundtrack,
    uploadSoundtrackLoading,
    soundtracks,
    soundtracksLoading,
    soundtracksHasMore,
    fileInputRef,
    handleSearchChange,
    handlePlayingSoundtrack,
    fetchMore,
    handleUpload,
    handleUserAssetDelete,
  } = useSoundtrack();

  return (
    <Wrapper>
      <TitleWithAction
        title="Soundtrack"
        action={
          !isGuest && (
            <ButtonWrapper>
              <Button
                buttonTheme={ButtonThemes.Secondary}
                icon={!uploadSoundtrackLoading && <ImportIcon />}
                text={uploadSoundtrackLoading ? "Loading..." : "Upload"}
              />
              <input
                ref={fileInputRef}
                type="file"
                id="soundtrack"
                name="soundtrack"
                accept={ACCEPTED_FORMATS}
                onChange={handleUpload}
              />
            </ButtonWrapper>
          )
        }
      >
        <Textfield
          value={search}
          placeholder="Search by soundtrack"
          startAdornment={<SearchIcon />}
          onKeyDown={(event) => {
            if (event.key === "Backspace" || event.key === "Delete") {
              event.stopPropagation();
            }
          }}
          onChange={handleSearchChange}
        />
        <Content>
          <SoundtrackWrapper id="scrollableDiv">
            {soundtracksLoading && !soundtracks?.length ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : (
              <InfiniteScroll
                next={fetchMore}
                hasMore={soundtracksHasMore}
                loader={
                  soundtracksLoading ? (
                    <CircularProgressWrapper>
                      <CircularProgress color="#009af7" />
                    </CircularProgressWrapper>
                  ) : null
                }
                dataLength={soundtracks?.length}
                style={{ display: "contents" }}
                scrollableTarget="scrollableDiv"
              >
                {soundtracks?.map((data, index: number) => {
                  const selectedSoundtrack = data.userAssetID === backgroundSoundTrackId;

                  return (
                    <Soundtrack
                      key={index}
                      active={index === activeSoundtrack + 1}
                      title={cutText(data.fileName, 10)}
                      deleteActive={!!data.userId}
                      soundtrack={data.path}
                      selectedSoundtrack={selectedSoundtrack}
                      variant="aiHumans"
                      handleSelect={() => handleBackgroundSoundTrackId(data.userAssetID)}
                      onClick={() => handlePlayingSoundtrack(index)}
                      handleDelete={(e: any) => handleUserAssetDelete(e, data.userAssetID)}
                    />
                  );
                })}
              </InfiniteScroll>
            )}
          </SoundtrackWrapper>
        </Content>
      </TitleWithAction>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 16px;
  border-radius: 20px;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const SoundtrackWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  overflow: hidden auto;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-content: start;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
    align-items: center;
    justify-content: center;
  `}

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default SoundtrackTab;
