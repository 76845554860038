import styled from "styled-components";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";

import { AIImagesContext } from "../../../App";
import { getUploadImageToVisualGalleryLoading } from "../../../redux/reducers/imagesReducer";
import { DropdownDelete, DropdownGalleryEdit, DropdownRepeat, EllipsisIcon } from "../../../components/Icons/Icons";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import MenuItem from "../../../components/ActionMenu/MenuItem";
import { getAssets } from "../../../redux/reducers/assetReducer";
import { Asset } from "../../../redux/actions/assetAction";

const Uploaded = () => {
  // const isLoading = useSelector(getUploadImageToVisualGalleryLoading);
  const assets = useSelector(getAssets);
  const isLoading = assets[Asset.VisualAssets].uploadLoading || assets[Asset.InPaintingVisualAssets].uploadLoading;
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpen(true);
  };

  const handleCloseMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setMenuOpen(false);
  };

  const { text, uploadedImage, handleOpenUpload, handleInPaintEditImage, handleDeleteUploadedImage } =
    useContext(AIImagesContext);

  const modifySelectedItems = selectItems.filter((item) => (!text ? item.text !== "Edit image" : item));

  return (
    <>
      {(uploadedImage || isLoading) && (
        <Wrapper>
          <TitleWrapper>
            <Title>Uploaded</Title>
          </TitleWrapper>
          {isLoading ? (
            <UploadedEmpty />
          ) : (
            <ImageWrapper>
              <StyledImg src={uploadedImage} />
              <ActionWrapper>
                <ActionMenu
                  position={{
                    x: "left",
                    y: "bottom",
                  }}
                  open={menuOpen}
                  handleClose={handleCloseMenu}
                  trigger={
                    <IconButton
                      iconButtonTheme={IconButtonThemes.Primary}
                      icon={<EllipsisIcon />}
                      onClick={handleOpenMenu}
                    />
                  }
                >
                  <MenuWrapper>
                    {modifySelectedItems.map((item) => (
                      <MenuItem
                        key={item.id}
                        icon={item.icon}
                        onClick={(e) => {
                          e.preventDefault();

                          item.text === "Edit image" && handleInPaintEditImage(uploadedImage);
                          item.text === "Change image" && handleOpenUpload();
                          item.text === "Delete image" && handleDeleteUploadedImage();
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </MenuWrapper>
                </ActionMenu>
              </ActionWrapper>
            </ImageWrapper>
          )}
        </Wrapper>
      )}
    </>
  );
};

const selectItems = [
  { id: 1, icon: <DropdownGalleryEdit />, text: "Edit image" },
  { id: 2, icon: <DropdownRepeat />, text: "Change image" },
  { id: 3, icon: <DropdownDelete />, text: "Delete image" },
];

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 12px 0px;
`;

const Wrapper = styled.div`
  margin: 0px 0px 16px 0px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const StyledImg = styled.img`
  height: 180px;
  width: 240px;
  border-radius: 16px;
`;

const Title = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};
`;

const UploadedEmpty = styled.div`
  height: 180px;
  width: 240px;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.characterCardShadow};
  background: linear-gradient(-90deg, #e9e9e9, #d6d6d6);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: none;
    border-radius: 6px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .menu-wrapper {
    padding: 8px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: none;
    background: ${({ theme }) => theme.primaryBackground};
  }
`;

const MenuWrapper = styled.div`
  height: auto;
  max-height: 92px;
  width: 146px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & > div {
    background: none;
    -webkit-text-fill-color: ${({ theme }) => theme.primaryText};
  }

  svg path {
    fill: transparent;
    stroke: ${({ theme }) => theme.primaryText};
  }
`;

export default Uploaded;
