import React, { useContext } from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { CopyIconV2 } from "../../../../../components/Icons/Icons";
import { VideoEditorContext } from "../../../../../App";
import { Shape } from "../../../../../types/scene";

interface Props {
  currentObject: Shape;
}

const CopyShape = ({ currentObject }: Props) => {
  const { handleDuplicateObject } = useContext(VideoEditorContext);

  const handleDublicate = () => {
    if (!currentObject) return;
    handleDuplicateObject(currentObject.id, { shapeDetailId: 0 });
  };

  return (
    <Wrapper>
      <IconButton iconButtonTheme={IconButtonThemes.Rounded} icon={<CopyIconV2 />} onClick={handleDublicate} />
      <Tooltip
        text={"Duplicate"}
        position="bottom"
        style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
        reverseColor
        arrow
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export default CopyShape;
