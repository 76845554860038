export const ChatGPT = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 25.3333V20H25.3333L25.3347 25.3333H20ZM25.3359 17.3359H20.0026C18.5319 17.3359 17.3359 18.5319 17.3359 20.0026V25.3359C17.3359 26.8066 18.5319 28.0026 20.0026 28.0026H25.3359C26.8066 28.0026 28.0026 26.8066 28.0026 25.3359V20.0026C28.0026 18.5319 26.8066 17.3359 25.3359 17.3359ZM6.66406 25.3333V20H11.9974L11.9987 25.3333H6.66406ZM12 17.3359H6.66667C5.196 17.3359 4 18.5319 4 20.0026V25.3359C4 26.8066 5.196 28.0026 6.66667 28.0026H12C13.4707 28.0026 14.6667 26.8066 14.6667 25.3359V20.0026C14.6667 18.5319 13.4707 17.3359 12 17.3359ZM20 11.9974V6.66406H25.3333L25.3347 11.9974H20ZM25.3359 4H20.0026C18.5319 4 17.3359 5.196 17.3359 6.66667V12C17.3359 13.4707 18.5319 14.6667 20.0026 14.6667H25.3359C26.8066 14.6667 28.0026 13.4707 28.0026 12V6.66667C28.0026 5.196 26.8066 4 25.3359 4ZM6.66406 11.9974V6.66406H11.9974L11.9987 11.9974H6.66406ZM12 4H6.66667C5.196 4 4 5.196 4 6.66667V12C4 13.4707 5.196 14.6667 6.66667 14.6667H12C13.4707 14.6667 14.6667 13.4707 14.6667 12V6.66667C14.6667 5.196 13.4707 4 12 4Z"
      fill="#0063B4"
    />
    <mask
      id="mask0_12_7267"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="25"
      height="25"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 25.3333V20H25.3333L25.3347 25.3333H20ZM25.3359 17.3359H20.0026C18.5319 17.3359 17.3359 18.5319 17.3359 20.0026V25.3359C17.3359 26.8066 18.5319 28.0026 20.0026 28.0026H25.3359C26.8066 28.0026 28.0026 26.8066 28.0026 25.3359V20.0026C28.0026 18.5319 26.8066 17.3359 25.3359 17.3359ZM6.66406 25.3333V20H11.9974L11.9987 25.3333H6.66406ZM12 17.3359H6.66667C5.196 17.3359 4 18.5319 4 20.0026V25.3359C4 26.8066 5.196 28.0026 6.66667 28.0026H12C13.4707 28.0026 14.6667 26.8066 14.6667 25.3359V20.0026C14.6667 18.5319 13.4707 17.3359 12 17.3359ZM20 11.9974V6.66406H25.3333L25.3347 11.9974H20ZM25.3359 4H20.0026C18.5319 4 17.3359 5.196 17.3359 6.66667V12C17.3359 13.4707 18.5319 14.6667 20.0026 14.6667H25.3359C26.8066 14.6667 28.0026 13.4707 28.0026 12V6.66667C28.0026 5.196 26.8066 4 25.3359 4ZM6.66406 11.9974V6.66406H11.9974L11.9987 11.9974H6.66406ZM12 4H6.66667C5.196 4 4 5.196 4 6.66667V12C4 13.4707 5.196 14.6667 6.66667 14.6667H12C13.4707 14.6667 14.6667 13.4707 14.6667 12V6.66667C14.6667 5.196 13.4707 4 12 4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_12_7267)"></g>
  </svg>
);
