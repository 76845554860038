import React from "react";
import styled from "styled-components";
import { ScreenProps } from "../../mocks/humans";

interface MenuPosition {
  y: "top" | "bottom";
  x: "left" | "right";
}

interface Props {
  open: boolean;
  activeScreen?: ScreenProps;
  position?: MenuPosition;
  children?: React.ReactNode;
  trigger: React.ReactNode;
  handleClose: (e?: any) => void;
  menuStyle?: Record<string, string>;
}

const ActionMenu = ({
  children,
  trigger,
  open,
  position = { x: "left", y: "bottom" },
  menuStyle,
  activeScreen,
  handleClose,
}: Props) => {
  return (
    <Wrapper>
      {trigger}
      {open && (
        <MenuWrapper activeScreen={activeScreen} position={position} className="menu-wrapper" style={menuStyle}>
          {children}
        </MenuWrapper>
      )}
      {open && <ClosePanel onClick={handleClose} />}
    </Wrapper>
  );
};

const Wrapper = styled.span``;

interface WrapperProps {
  position: MenuPosition;
  activeScreen?: ScreenProps;
}

const MenuWrapper = styled.div`
  padding: 12px;
  background: #f0f0f3;
  border-radius: 12px;
  border: 1px solid #0063b4;
  position: absolute;
  ${(props: WrapperProps) => (props.position.y === "top" ? "bottom: 50px" : "top: 30px")};
  ${(props: WrapperProps) => (props.position.x === "left" ? "right: 8px" : "left: 140px")};
  z-index: 25;

  @media (max-width: 516px) {
    ${(props: WrapperProps) => (props.position.y === "top" ? "bottom: 50px" : "top: 30px")};
    ${(props: WrapperProps) => (props.position.x === "left" ? "right: 8px" : "left: 100px")};
  }

  ${(props: WrapperProps) => props.activeScreen === ScreenProps.MOBILE && "right: -21px; min-width: 104px; "};
`;

const ClosePanel = styled.div`
  position: fixed;
  z-index: 24;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default ActionMenu;
