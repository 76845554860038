export const FolderIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F0F0F3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.7555V26.0685C12 26.3065 12.224 26.5005 12.5 26.5005H27.5C27.776 26.5005 28 26.3065 28 26.0685V17.4785C28 17.2395 27.776 17.0465 27.5 17.0465H20C19.7 17.0465 19.416 16.9115 19.226 16.6785L16.626 13.5005H12.5C12.224 13.5005 12 13.6935 12 13.9315V21.7555ZM27.5 28.5005H12.5C11.122 28.5005 10 27.4095 10 26.0685V13.9315C10 12.5905 11.122 11.5005 12.5 11.5005H17.101C17.4 11.5005 17.685 11.6345 17.875 11.8675L20.474 15.0465H27.5C28.878 15.0465 30 16.1365 30 17.4785V26.0685C30 27.4095 28.878 28.5005 27.5 28.5005Z"
      fill="#191B1F"
    />
    <mask id="mask0_493_13309" maskUnits="userSpaceOnUse" x="10" y="11" width="20" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.7555V26.0685C12 26.3065 12.224 26.5005 12.5 26.5005H27.5C27.776 26.5005 28 26.3065 28 26.0685V17.4785C28 17.2395 27.776 17.0465 27.5 17.0465H20C19.7 17.0465 19.416 16.9115 19.226 16.6785L16.626 13.5005H12.5C12.224 13.5005 12 13.6935 12 13.9315V21.7555ZM27.5 28.5005H12.5C11.122 28.5005 10 27.4095 10 26.0685V13.9315C10 12.5905 11.122 11.5005 12.5 11.5005H17.101C17.4 11.5005 17.685 11.6345 17.875 11.8675L20.474 15.0465H27.5C28.878 15.0465 30 16.1365 30 17.4785V26.0685C30 27.4095 28.878 28.5005 27.5 28.5005Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_13309)"></g>
  </svg>
);
