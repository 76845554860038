import styled from "styled-components";
import { ReactNode } from "react";

import Button from "../../../components/Button/Button";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { Wrapper } from "../../AIHumans/components/Tabs";
import { AIImagesSidebarTabs } from "../../../hooks/useAiImages";

interface Tab {
  label: AIImagesSidebarTabs | string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

interface Props {
  disabled?: boolean;
  data: Tab[];
  active: string;
  handleActive: (tabId: AIImagesSidebarTabs | string) => void;
}

const Tabs = ({ data, active, handleActive, disabled }: Props) => {
  return (
    <StyledWrapper>
      {data.map((tab: Tab, order: number) => (
        <ButtonWrapper key={order}>
          <Button
            key={order}
            text={tab.label}
            className={tab.label === active ? "active" : "not-active"}
            disabled={tab.disabled || disabled}
            onClick={() => handleActive(tab.label)}
            endAdornment={tab.endAdornment}
            icon={tab.startAdornment}
          />
          {tab.endAdornment && (
            <Tooltip
              text={"Coming Soon"}
              position="left"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", right: "30px", top: "20px" }}
              arrow
            />
          )}
        </ButtonWrapper>
      ))}
    </StyledWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: relative;
  border: none !important;
  width: 100%;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const StyledWrapper = styled(Wrapper)`
  margin-bottom: 0px;
`;

export default Tabs;
