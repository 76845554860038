import React, { FC } from "react";
import { Review, SocialNetwork } from "../../types/review";
import styled from "styled-components";
import StarsRender from "./StarsRender";
import { CapterraLogo } from "../Icons/CapterraLogo";
import { G2logo } from "../Icons/G2logo";
import { Google } from "../Icons/Google";

const getSocialIcon = (network: SocialNetwork) => {
  switch (network) {
    case SocialNetwork.Capterra:
      return <CapterraLogo />;
    case SocialNetwork.G2:
      return <G2logo />;
    case SocialNetwork.Google:
      return <Google />;
    default:
      return <div />;
  }
};

const ReviewCard: FC<Review> = ({ id, background, network, stars, avatar, name, date, text }) => {
  return (
    <Wrapper variant={background}>
      <StarsAndSocialWrapper>
        <StarsRender stars={stars} variant={background} />
        <SocialNetworkIcon>{getSocialIcon(network)}</SocialNetworkIcon>
      </StarsAndSocialWrapper>
      <ReviewText>{text}</ReviewText>
      <UserInfo>
        <PhotoWrapper>
          <Photo src={avatar} />
        </PhotoWrapper>
        <div>
          <StyledName>{name}</StyledName>
          <StyledDate variant={background}>{date}</StyledDate>
        </div>
      </UserInfo>
    </Wrapper>
  );
};

const Wrapper = styled("div")<{ variant?: "default" | "color" }>`
  padding: 24px;
  gap: 24px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  ${({ variant, theme }) =>
    variant === "default" &&
    `
    background: ${theme.white};
    color: ${theme.reviewText};
    `}

  ${({ variant, theme }) =>
    variant === "color" &&
    `
    background: ${theme.reviewBlue};
    color: ${theme.white};
    `}
`;

const StarsAndSocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SocialNetworkIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;

  & svg {
    width: 12px;
    height: 12px;
  }
`;

const ReviewText = styled.p`
  padding-top: 12px;
`;

const UserInfo = styled.div`
  display: flex;
  padding-top: 24px;
  gap: 12px;
`;

const PhotoWrapper = styled.div`
  border-radius: 50%;
  width: 44px;
  height: 44px;
  overflow: hidden;
`;

const Photo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const StyledName = styled.p`
  font-weight: 600;
`;
const StyledDate = styled.p<{ variant?: "default" | "color" }>`
  font-size: 12px;

  ${({ variant, theme }) =>
    variant === "default" &&
    `
    color: ${theme.buttonGreyText};
    `}

  ${({ variant, theme }) =>
    variant === "color" &&
    `
    color: ${theme.white};
    opacity: 0.7;
    `}
`;

export default ReviewCard;
