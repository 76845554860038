import React from "react";
import styled from "styled-components";

import Tooltip from "../../modules/PricingSubscriptions/components/Tooltip";
import { ILabel } from "../../mocks/pricing";
import { InfoGreyIcon } from "../Icons/Icons";

interface StyledSliderProps {
  value: number;
  min: number;
  max: number;
}

interface SliderProps {
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
  labels?: ILabel[];
}

const CustomSlider: React.FC<SliderProps> = ({ value, min, max, onChange, labels }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    onChange(newValue);
  };

  return (
    <SliderWrapper>
      <StyledSlider aria-label="Slider" type="range" value={value} min={min} max={max} onChange={handleChange} />
      {labels?.length && (
        <Labels>
          {labels.map(({ text, tooltip }, index) => {
            return (
              <Label
                key={text}
                isActive={index === value - 1}
                left={(index / (labels.length - 1)) * 100 + "%"}
                isLeft={index === 0}
                isRight={index === labels.length - 1}
              >
                {text}
                {tooltip && (
                  <div>
                    <InfoGreyIcon />
                  </div>
                )}
                {tooltip && <Tooltip title={tooltip} />}
              </Label>
            );
          })}
        </Labels>
      )}
    </SliderWrapper>
  );
};

const Label = styled.div<{ isActive: boolean; left: string; isLeft: boolean; isRight: boolean }>`
  position: absolute;
  top: 12px;
  width: max-content;
  left: ${({ left }) => left};
  transform: ${({ isRight }) => (isRight ? "translateX(-100%)" : "translateX(-50%)")};
  transform: ${({ isLeft }) => isLeft && "translateX(0)"};
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-weight: ${({ isActive }) => (isActive ? "600" : "500")};
  font-size: 16px;
  line-height: 28px;
  color: ${({ isActive }) => (isActive ? "#191B1F" : "#6F7074")};

  &:hover .tooltip {
    opacity: 1;
  }

  .tooltip {
    right: 0;
    bottom: 40px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
  }
`;

const Labels = styled.div`
  position: relative;
  width: 100%;
  height: 28px;
`;

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledSlider = styled.input<StyledSliderProps>`
  flex: 1;
  width: 100%;
  height: 16px;
  border-radius: 52px;
  background: transparent;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      #0063b4 0%,
      #009af7 ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%,
      #f0f0f3 ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%,
      #f0f0f3 100%
    );
    border-radius: 52px;
    height: 16px;
    box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
      inset 2px 2px 4px #ffffff;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 5px solid #0063b4;
    cursor: pointer;
    margin-top: -2px;
  }
`;

const SliderLabel = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: #333;
`;

export default CustomSlider;
