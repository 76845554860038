import React from "react";
import styled from "styled-components";
import { FailureIcon } from "../../../components/Icons/Icons";

const Tooltip = () => {
  return (
    <Wrapper>
      <FailureIcon />
      <Text>Fail to render</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;

  &:hover {
    span {
      visibility: visible;
    }
  }
`;

const Text = styled.span`
  width: 97px;
  visibility: hidden;
  background-color: #191b1f;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px 6px;
  position: absolute;
  z-index: 1;
  top: -30px;
  left: -5px;

  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 8px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export default Tooltip;
