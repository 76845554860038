import styled from "styled-components";

import useAudioPlayer from "../../../hooks/useAudioPlayer";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { PauseIcon, PlayIcon } from "../../../components/Icons/Icons";

interface Props {
  src: string;
  title: string;
  active: boolean;
  onClick: () => void;
}

const Voice = ({ title, active, src, onClick }: Props) => {
  const { audioPlayer, currentTime, duration, playing, onPlaying, setPlaying, onLoadedMetadata } = useAudioPlayer();

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    onClick();

    if (playing) {
      if (!active) setPlaying(false);
      else setPlaying(false);
    } else {
      if (!active) setPlaying(true);
      else setPlaying(true);
    }
  };

  return (
    <Wrapper>
      {active && (
        <audio src={src} ref={audioPlayer} onTimeUpdate={onPlaying} onLoadedMetadata={onLoadedMetadata}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )}
      <IconButton
        iconButtonTheme={IconButtonThemes.Rounded}
        icon={!playing || currentTime === duration || !active ? <PlayIcon /> : <PauseIcon />}
        onClick={handleClick}
      />
      <Text>{title}</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  button {
    width: 20px;
    height: 20px;

    svg {
      width: 11px;
      height: 11px;
    }
  }
`;

const Text = styled.p<{ isValueExist?: boolean }>`
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};
`;

export default Voice;
