import { useDispatch, useSelector } from "react-redux";
import {
  getLanguageDetectLoading,
  getLanguages,
  getLanguagesLoading,
  getSourceLanguage,
  getTargetLanguage,
} from "../redux/reducers/languageReducer";
import { useEffect, useState } from "react";
import { Language } from "../types/language";
import {
  detectLanguageServer,
  getAllSupportedLanguagesServer,
  translateTextServer,
} from "../redux/actions/languageAction";

interface Props {
  textInput: string[];
  setMenuOpen: (id: number) => void;
}

const removeEmptyStrings = (array: string[]) => {
  const filteredArray = array.filter((str) => str !== "");
  return filteredArray;
};

export const useAiHumansTextTranslate = ({ textInput, setMenuOpen }: Props) => {
  const dispatch = useDispatch();
  const languages = useSelector(getLanguages);
  const languagesLoading = useSelector(getLanguagesLoading);
  const detectLoading = useSelector(getLanguageDetectLoading);
  const sourceLanguage = useSelector(getSourceLanguage);
  const targetLanguage = useSelector(getTargetLanguage);

  const [search, setSearch] = useState<string>("");
  const [searchResults, setSearchResults] = useState<Language[]>([]);
  const [selectedLang, setSelectedLang] = useState<string>("");

  const handleSelectLanguage = (language: string) => {
    setSelectedLang(language);
    dispatch(
      translateTextServer({ textData: removeEmptyStrings(textInput), targetLanguage: language, sourceLanguage }),
    );
    setMenuOpen(-1);
  };

  const searchByName = (search: string) => {
    const searchQuery = search.toLowerCase().trim();
    const words = searchQuery.split(/\s+/);

    const results = languages.filter((language) => {
      return words.every((word) => language.name.toLowerCase().includes(word));
    });

    setSearchResults(results);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (e.target.value.trim() !== "") {
      searchByName(e.target.value);
    } else {
      setSearchResults(languages);
    }
  };

  useEffect(() => {
    if (!languages.length) {
      dispatch(getAllSupportedLanguagesServer());
    }

    dispatch(detectLanguageServer({ textInput: textInput.join(" ") }));
    setSelectedLang(targetLanguage);
  }, []);

  useEffect(() => {
    setSearchResults(languages);
  }, [languages]);

  return {
    search,
    selectedLang,
    searchResults,
    languagesLoading,
    detectLoading,
    sourceLanguage,
    handleSearchChange,
    handleSelectLanguage,
  };
};
