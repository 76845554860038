import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { BorderStyleIcon } from "../../../../../components/Icons/BorderStyleIcon";
import Textfield from "../../../../../components/Textfield/Textfield";
import { SolidBorderIcon } from "../../../../../components/Icons/SolidBorderIcon";
import { SolidBorderActiveIcon } from "../../../../../components/Icons/SolidBorderActiveIcon";
import { DashedBorderIcon } from "../../../../../components/Icons/DashedBorderIcon";
import { DashedBorderActiveIcon } from "../../../../../components/Icons/DashedBorderActiveIcon";
import { Shape, ShapeBorderStyle } from "../../../../../types/scene";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";

interface Props {
  currentObject: Shape;
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  borderWidth: number;
  setBorderWidth: React.Dispatch<React.SetStateAction<number>>;
}

const ShapeBorder = ({ currentObject, handleSceneObjectChange, borderWidth, setBorderWidth }: Props) => {
  const theme = useSelector(getTheme);
  const [isOpen, setIsOpen] = useState(false);
  const [borderStyle, setBorderStyle] = useState(ShapeBorderStyle.solid);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleTextfieldChange = (event: any) => {
    if (!currentObject) return;
    const newValue = Math.min(Math.max(+event.target.value, 0), 100);
    handleSceneObjectChange("border", { ...currentObject.border, borderWidth: newValue }, currentObject.id);
    setBorderWidth(newValue);
  };

  const handleChangeBorderStyle = (borderStyle: ShapeBorderStyle) => {
    if (!currentObject) return;
    handleSceneObjectChange("border", { ...currentObject.border, borderStyle }, currentObject.id);
    setBorderStyle(borderStyle);
  };

  useEffect(() => {
    if (!currentObject) {
      setBorderWidth(0);
      setBorderStyle(ShapeBorderStyle.solid);
      return;
    }
    setBorderWidth(currentObject.border.borderWidth);
    setBorderStyle(currentObject.border.borderStyle);
  }, [currentObject]);

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper>
            <IconButton
              iconButtonTheme={IconButtonThemes.Rounded}
              icon={<BorderStyleIcon />}
              className={isOpen ? "active" : "not-active"}
              onClick={handleOpen}
            />
            <Tooltip
              text={"Border style"}
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
              reverseColor
              arrow
            />
          </ButtonWrapper>
        }
      >
        <MenuWrapper>
          <MenuTop>
            <OpacityText>Border style</OpacityText>
            <TextfieldWrapper>
              <Textfield
                onKeyDown={(event) => {
                  if (event.key === "Backspace" || event.key === "Delete") {
                    event.stopPropagation();
                  }
                }}
                placeholder="0"
                type="number"
                value={String(borderWidth)}
                onChange={handleTextfieldChange}
              />
            </TextfieldWrapper>
          </MenuTop>
          <MenuBottom>
            <MenuListItem
              isActive={borderStyle === ShapeBorderStyle.solid}
              onClick={() => handleChangeBorderStyle(ShapeBorderStyle.solid)}
            >
              {borderStyle === ShapeBorderStyle.solid ? <SolidBorderActiveIcon /> : <SolidBorderIcon />}
            </MenuListItem>
            {/* <MenuListItem
              isActive={borderStyle === ShapeBorderStyle.dashed}
              onClick={() => handleChangeBorderStyle(ShapeBorderStyle.dashed)}
            >
              {borderStyle === ShapeBorderStyle.dashed ? <DashedBorderActiveIcon /> : <DashedBorderIcon />}
            </MenuListItem> */}
          </MenuBottom>
        </MenuWrapper>
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
    }
  }
`;

const MenuListItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  border-radius: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  ${({ isActive, theme }) =>
    !isActive
      ? `
      svg > path {
        stroke: ${theme.icon};
      }
  `
      : `
    svg > rect {
        fill: ${theme.menuListItemActive};
      }
  `}
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const MenuTop = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const OpacityText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};
`;

const TextfieldWrapper = styled("div")`
  & input {
    padding: 0;
    width: 46px;
    height: 30px;
    text-align: center;
  }
`;

const MenuBottom = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(25px)",
  left: "0",
  border: "none",
  padding: "12px 16px",
  width: "max-content",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default ShapeBorder;
