import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import {
  FontStyleActiveIcon,
  FontStyleIcon,
  OpacityActiveIcon,
  OpacityIcon,
} from "../../../../../components/Icons/Icons";
import Textfield from "../../../../../components/Textfield/Textfield";
import { Text } from "../../../../../types/scene";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

const step = 10;

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Text;
}

const TextOpacity = ({ handleSceneObjectChange, currentObject }: Props) => {
  const theme = useSelector(getTheme);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleSliderChange = (event: any) => {
    if ((!value && value !== 0) || !currentObject) return;
    const newValue = Math.round(event.target.value / step) * step;
    handleSceneObjectChange("style", { ...currentObject.style, opacity: newValue / 100 }, currentObject.id);
    setValue(newValue);
  };

  const handleTextfieldChange = (event: any) => {
    if ((!value && value !== 0) || !currentObject) return;
    const newValue = Math.min(Math.max(+event.target.value, 0), 100);
    handleSceneObjectChange("style", { ...currentObject.style, opacity: newValue / 100 }, currentObject.id);
    setValue(newValue);
  };

  useEffect(() => {
    if (!currentObject) return setValue(0);
    setValue((currentObject.style.opacity as number) * 100);
  }, [currentObject]);

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper isActive={isOpen}>
            <IconButton onClick={handleOpen}>
              <OpacityActiveIcon />
            </IconButton>
            <Tooltip
              text="Opacity"
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
              reverseColor
              arrow
            />
          </ButtonWrapper>
        }
      >
        <MenuWrapper>
          <MenuTop>
            <OpacityText>Opacity</OpacityText>
            <TextfieldWrapper>
              <Textfield
                onKeyDown={(event) => {
                  if (event.key === "Backspace" || event.key === "Delete") {
                    event.stopPropagation();
                  }
                }}
                placeholder="0"
                type="number"
                value={String(value)}
                onChange={handleTextfieldChange}
              />
            </TextfieldWrapper>
          </MenuTop>
          <MenuBottom>
            <ProgressBar>
              <ProgressBarLine
                value={value}
                type="range"
                min="0"
                max="100"
                step={10}
                width={160}
                onChange={handleSliderChange}
              />
            </ProgressBar>
            <ProgressBarLabels>
              {[0, 20, 40, 60, 100].map((label) => (
                <Label key={label}>{label}</Label>
              ))}
            </ProgressBarLabels>
          </MenuBottom>
        </MenuWrapper>
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ProgressBar = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  border-radius: 60px;
  display: flex;
  align-items: center;
`;

const ProgressBarLine = styled.input<{ width: number }>`
  background: ${({ theme }) => theme.button};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 80px;
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  ::-webkit-slider-runnable-track {
    background: ${({ theme, width }) => `linear-gradient(to right, ${theme.activeMenu} ${width}%, transparent 0)`};
    height: 4px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: ${({ theme }) => theme.white};
    width: 4px;
    height: 4px;
    padding: 2px;
    margin-top: -2px;
    border: 2px solid ${({ theme }) => theme.activeMenu};
    border-radius: 52px;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div<{ isActive: boolean }>`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const IconButton = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  border-radius: 4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

const MenuTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OpacityText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};
`;

const TextfieldWrapper = styled("div")`
  & input {
    padding: 0;
    width: 46px;
    height: 30px;
    text-align: center;
  }
`;

const MenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const ProgressBarLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.div`
  user-select: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.4;
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(10px)",
  left: "0",
  border: "none",
  padding: "12px 16px",
  width: "max-content",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default TextOpacity;
