export enum VideoTranslationModules {
  "allVideos" = "allVideos",
  "singleVideo" = "singleVideo",
  "generateVideo" = "generateVideo",
  "faceDetect" = "faceDetect",
  "videoServices" = "videoServices",
  "generateVideoToTranslateText" = "generateVideoToTranslateText",
}

export enum UploadVideoTabs {
  Upload = "Upload",
  TikTok = "TikTok",
  YouTube = "YouTube",
  XTwitter = "XTwitter",
  Vimeo = "Vimeo",
  OtherURL = "OtherURL",
}

export enum VideoTranslationStatus {
  Pending = 1,
  InProgress,
  Completed,
  Failed,
}

export interface TranslationTabProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  position?: { left?: string; right?: string; top?: string; bottom?: string };
  avatar?: string;
}
