import React, { FC } from "react";
import styled from "styled-components";

import { MinusCircleIcon, PlusIconGrey } from "../Icons/Icons";

interface AccordionSummaryProps {
  active?: boolean;
  children: React.ReactNode;
}

const AccordionSummary: FC<AccordionSummaryProps> = ({ children, active }) => {
  return (
    <StyledAccordionSummary active={active}>
      {children}
      <div>
        <MinusCircleIcon />
        <PlusIconGrey />
      </div>
    </StyledAccordionSummary>
  );
};

const StyledAccordionSummary = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  div {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
  svg {
    border-radius: ;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
  }

  .minusCircleIcon {
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity 0.4s;
  }

  .plusIcon {
    opacity: ${({ active }) => (active ? 0 : 1)};
    transition: opacity 0.4s;
  }
`;

export default AccordionSummary;
