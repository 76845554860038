export const IrisOutIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="5" width="28" height="18" rx="4" fill="url(#paint0_linear_2969_22671)" />
    <circle cx="13.968" cy="13.9688" r="7.60564" fill="white" />
    <circle cx="13.968" cy="13.9688" r="7.60564" fill="url(#paint1_linear_2969_22671)" />
    <circle cx="13.968" cy="13.9688" r="7.60564" stroke="white" strokeWidth="1.21127" />
    <path
      d="M17.4818 12.6676V10.4434H15.2576"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2512 15.3496V17.5739H12.4755"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4819 10.4434L14.7015 13.2237"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0316 14.7949L10.2512 17.5753"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2969_22671"
        x1="1.91475e-07"
        y1="14"
        x2="23.2331"
        y2="24.5238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1680D3" />
        <stop offset="1" stopColor="#C0DFF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2969_22671"
        x1="9.9834"
        y1="8.79952"
        x2="18.2757"
        y2="19.4611"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
