import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Zone } from "../types/project";
import { ScreenProps } from "../mocks/humans";
import {
  Status,
  createProjectServer,
  updateHumansProjectLoading,
  updateProjectServer,
} from "../redux/actions/humansProjectActions";
import { getAllZones } from "../lib/editorUtils";
import { Asset } from "../redux/actions/assetAction";
import { checkIfZoneCached } from "../lib/editorUtils";
import { ProfileHumanSidebarType } from "../types/human";
import { SentryErrors, sentryErrors } from "../lib/sentry";
import { getAssets } from "../redux/reducers/assetReducer";
import { getShapes } from "../redux/reducers/shapesReducer";
import { ProjectType } from "../redux/actions/projectAction";
import { clearHumatars } from "../redux/actions/humatarActions";
import { getShapesServer } from "../redux/actions/shapesAction";
import { getActorsServer } from "../redux/actions/actorActions";
import { getHumatarList } from "../redux/reducers/humatarReducer";
import { getProjectAudio } from "../redux/reducers/projectReducer";
import { getHumansProject } from "../redux/reducers/humansProjectReducer";
import { AiHumansProjectModules, HumansParagraph } from "../types/humansProject";

interface AIHumansProjectProps {
  getFullProject: any;
  activeScreen: ScreenProps;
  paragraphs: HumansParagraph[];
  canvasWidth?: number;
  backgroundSoundTrackId: number | null;
  handleChangeActiveScene: (id: number) => void;
}

export const useAIHumansProject = ({
  getFullProject,
  activeScreen,
  paragraphs,
  canvasWidth,
  backgroundSoundTrackId,
  handleChangeActiveScene,
}: AIHumansProjectProps) => {
  const dispatch = useDispatch();
  const humansProject = useSelector(getHumansProject);

  const createProject = async () => {
    dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: true }));
    const project = await getFullProject({
      projectTypeId: ProjectType.HSS,
      title: `Project ${Math.random()}`,
      enableCaption: humansProject?.enableCaption || false,
      resolution: humansProject?.resolution || "1920x1080",
      projectCategoryId: 1,
      status: Status.isDraft,
      isHorizontal: activeScreen === ScreenProps.DESKTOP ? true : false,
      backgroundSoundTrackId,
      paragraphs,
      canvasWidth,
    });
    if (!project) {
      sentryErrors({
        errorType: SentryErrors.EMPTRY_PROJECT_WHEN_CREATING_A_PROJECT,
        details: {
          humansProjectId: `${humansProject?.projectId}`,
          projectTypeId: `${ProjectType.HSS}`,
          title: `Project ${Math.random()}`,
          enableCaption: `${humansProject?.enableCaption}` || "false",
          resolution: `${humansProject?.resolution}` || "1920x1080",
          projectCategoryId: "1",
          status: `${Status.isDraft}`,
          isHorizontal: activeScreen === ScreenProps.DESKTOP ? "true" : "false",
          backgroundSoundTrackId: `${backgroundSoundTrackId}`,
          paragraphs: `${paragraphs}`,
          canvasWidth: `${canvasWidth}`,
        },
      });
      return;
    }
    // handleChangeActiveScene(project.slides[0]?.slideId);
    dispatch(createProjectServer({ ...project }));
  };

  const updateVideo = async () => {
    console.log("enableCaption: humansProject?.enableCaption,", humansProject?.enableCaption);
    dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.project, isLoading: true }));
    const project = await getFullProject({
      projectTypeId: ProjectType.HSS,
      title: humansProject?.title,
      enableCaption: humansProject?.enableCaption,
      resolution: humansProject?.resolution,
      projectCategoryId: 1,
      status: humansProject?.status,
      projectId: humansProject?.projectId,
      isHorizontal: activeScreen === ScreenProps.DESKTOP ? true : false,
      backgroundSoundTrackId,
      paragraphs,
      canvasWidth,
    });
    if (!project) {
      sentryErrors({
        errorType: SentryErrors.EMPTRY_PROJECT_WHEN_UPDATING_A_PROJECT,
        details: {
          projectTypeId: `${ProjectType.HSS}`,
          title: `${humansProject?.title}`,
          enableCaption: `${humansProject?.enableCaption}`,
          resolution: `${humansProject?.resolution}`,
          projectCategoryId: "1",
          status: `${humansProject?.status}`,
          projectId: `${humansProject?.projectId}`,
          isHorizontal: activeScreen === ScreenProps.DESKTOP ? "true" : "false",
          backgroundSoundTrackId: `${backgroundSoundTrackId}`,
          paragraphs: `${paragraphs}`,
          canvasWidth: `${canvasWidth}`,
        },
      });
      return;
    }
    // handleChangeActiveScene(project.slides[0]?.slideId);
    dispatch(updateProjectServer({ ...project }));
  };

  return {
    createProject,
    updateVideo,
  };
};

interface AIHumansCreateVideoProps {
  paragraphs: HumansParagraph[];
  getFullProject: any;
  title: string;
  enableCaption: boolean;
  activeScreen: ScreenProps;
  backgroundSoundTrackId: number | null;
  canvasWidth: any;
}

export const getNotGeneratedParagraphs = (paragraphs: HumansParagraph[], cachedZonesAudio: Zone[]) => {
  const allZones = getAllZones(paragraphs);
  const notGeneratedZones = allZones
    .filter((zone) => !checkIfZoneCached(zone, cachedZonesAudio))
    .filter((zone) => zone.text !== "");

  const parsedZones: (number | undefined)[] = notGeneratedZones.map((zone) => zone.paragraphIndex);

  const paragraphsIndexes = parsedZones.reduce((prev: (number | undefined)[], current: number | undefined) => {
    if (prev.includes(current)) return prev;
    else return [...prev, current];
  }, []);

  return paragraphsIndexes;
};

export const getEmptyParagraphs = (paragraphs: HumansParagraph[]) => {
  return paragraphs.map((paragraph) => {
    if (!paragraph.data[0].text.length) return paragraph.order;
  });
};

export const useAIHumansRenderVideo = ({
  paragraphs,
  getFullProject,
  title,
  enableCaption,
  activeScreen,
  backgroundSoundTrackId,
  canvasWidth,
}: AIHumansCreateVideoProps) => {
  const dispatch = useDispatch();
  const humansProject = useSelector(getHumansProject);
  const { cachedZonesAudio } = useSelector(getProjectAudio);

  const createVideo = async () => {
    dispatch(updateHumansProjectLoading({ module: AiHumansProjectModules.generateVideo, isLoading: true }));
    const project = await getFullProject({
      projectTypeId: ProjectType.HSS,
      title: title,
      enableCaption,
      resolution: humansProject?.resolution,
      projectCategoryId: 1,
      status: Status.isProgress,
      projectId: humansProject?.projectId,
      paragraphs: paragraphs,
      isHorizontal: activeScreen === ScreenProps.DESKTOP ? true : false,
      backgroundSoundTrackId,
      canvasWidth,
    });
    if (!project) {
      sentryErrors({
        errorType: SentryErrors.EMPTRY_PROJECT_WHEN_GENERATING_A_PROJECT,
        details: {
          projectTypeId: `${ProjectType.HSS}`,
          title: `${title}`,
          enableCaption: `${enableCaption}`,
          resolution: `${humansProject?.resolution}`,
          projectCategoryId: "1",
          status: `${Status.isProgress}`,
          projectId: `${humansProject?.projectId}`,
          isHorizontal: activeScreen === ScreenProps.DESKTOP ? "true" : "false",
          backgroundSoundTrackId: `${backgroundSoundTrackId}`,
          paragraphs: `${paragraphs}`,
          canvasWidth: `${canvasWidth}`,
        },
      });
      return;
    }
    dispatch(updateProjectServer({ ...project }));
  };

  return {
    createVideo,
  };
};

export const useAIHumansAssets = () => {
  const assets = useSelector(getAssets);
  const images = assets[Asset.Images].data;
  const videos = assets[Asset.Videos].data;
  const shapes = useSelector(getShapes);
  const humatarsList = useSelector(getHumatarList);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearHumatars());
    // dispatch(
    //   getHumatarsServer({
    //     keyword: "",
    //     pageNumber: 1,
    //     category: ["Custom", "Seated", "Humatars"],
    //   }),
    // );
  }, []);

  useEffect(() => {
    dispatch(
      getActorsServer({
        keyword: "",
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        mood: [],
        content: [],
        region: [],
        language: [],
        country: [],
        isAIHuman: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (!shapes.length) dispatch(getShapesServer());
  }, [shapes]);

  return {
    shapes,
    images,
    videos,
    humatarsList,
  };
};

interface SlideActionsProps {
  setActiveGlobalTab: (type: ProfileHumanSidebarType) => void;
  handleChangeActiveScene: (id: number) => void;
  setNoScroll: (b: boolean) => void;
}

export const useSlideActions = ({ setActiveGlobalTab, handleChangeActiveScene, setNoScroll }: SlideActionsProps) => {
  const [menuOpen, setMenuOpen] = useState<number>();
  const handleOpenMenu = (id: number) => {
    setMenuOpen(id);
    setNoScroll(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(-1);
    setNoScroll(false);
  };

  const handleOpenTransitionTab = (event: React.MouseEvent<HTMLElement>, id: number) => {
    event.stopPropagation();

    setActiveGlobalTab(ProfileHumanSidebarType.Transitions);
    handleChangeActiveScene(id);
  };

  return {
    menuOpen,
    handleOpenMenu,
    handleCloseMenu,
    handleOpenTransitionTab,
  };
};
