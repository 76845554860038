export const PopupBrush = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.155 1.96994C10.385 3.88994 8.45501 6.49994 6.84001 7.79494L5.85501 8.58494C5.73001 8.67494 5.60501 8.75494 5.46501 8.80994C5.46501 8.71994 5.46001 8.61994 5.44501 8.52494C5.39001 8.10494 5.20001 7.71494 4.86501 7.37994C4.52501 7.03994 4.11001 6.83994 3.68501 6.78494C3.58501 6.77994 3.48501 6.76994 3.38501 6.77994C3.44001 6.62494 3.52501 6.47994 3.63001 6.35994L4.41001 5.37494C5.70001 3.75994 8.32001 1.81994 10.235 1.05494C10.53 0.944939 10.815 1.02494 10.995 1.20994C11.185 1.39494 11.275 1.67994 11.155 1.96994Z"
      stroke="#6F7074"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.46501 8.8099C5.46501 9.3599 5.25501 9.8849 4.86001 10.2849C4.55501 10.5899 4.14001 10.7999 3.64501 10.8649L2.41501 10.9999C1.74501 11.0749 1.17001 10.5049 1.25001 9.8249L1.38501 8.5949C1.50501 7.4999 2.42001 6.7999 3.39001 6.7799C3.49001 6.7749 3.59501 6.7799 3.69001 6.7849C4.11501 6.8399 4.53001 7.0349 4.87001 7.3799C5.20501 7.7149 5.39501 8.1049 5.45001 8.5249C5.45501 8.6199 5.46501 8.7149 5.46501 8.8099Z"
      stroke="#6F7074"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.37 7.23512C7.37 5.93012 6.31 4.87012 5.005 4.87012"
      stroke="#6F7074"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
