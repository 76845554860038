import { BackgroundType, Review, SocialNetwork } from "../types/review";

export const leftSignInReviews: Review[] = [
  {
    id: 3,
    background: BackgroundType.Default,
    network: SocialNetwork.Google,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.3.jpg",
    name: "James Milo",
    date: "Mar 7, 2024",
    text: "Useful, easy-to-learn extension for generating variations of a seed image with option of upscaling and/or creating a super-short video of the variant.",
  },
  {
    id: 2,
    background: BackgroundType.Color,
    network: SocialNetwork.Google,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.2.jpg",
    name: "Tonya White",
    date: "Mar 9, 2024",
    text: "This is really fun!  Using it with photos either I create or stock photos, it generates something new & different, but similar.",
  },
  {
    id: 4,
    background: BackgroundType.Default,
    network: SocialNetwork.Google,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.4.jpg",
    name: "Reed Scott",
    date: "Jan 12, 2024",
    text: "Easy to use, with incredible royalty-free images. Essential tool for any content creator.",
  },
  {
    id: 32,
    background: BackgroundType.Default,
    network: SocialNetwork.G2,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.32.jpg",
    name: "Alex R",
    date: "Dec 12, 2023",
    text: "It helped me to create great videos with humal like voiceovers. There are many humatars you can use for different niches.",
  },
  {
    id: 22,
    background: BackgroundType.Default,
    network: SocialNetwork.G2,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.22.jpg",
    name: "Davey B",
    date: "Feb 7, 2024",
    text: "Get it because it works. The face swap action is like nothing you've seen before.",
  },
];

export const rightSignInReviews = [
  {
    id: 6,
    background: BackgroundType.Default,
    network: SocialNetwork.G2,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.6.jpg",
    name: "Jesus F",
    date: "Dec 19, 2023",
    text: "A comprehensive solution for multiple content formats.",
  },
  {
    id: 7,
    background: BackgroundType.Default,
    network: SocialNetwork.G2,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.7.jpg",
    name: "Dave K",
    date: "Dec 13, 2023",
    text: "The quality of the product is outstanding. Well designed. Easy to implement and fast.",
  },
  {
    id: 8,
    background: BackgroundType.Default,
    network: SocialNetwork.G2,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.8.jpg",
    name: "Mark van Geyzel",
    date: "Mar 1,2024",
    text: "I love this. I got Synthesis for one simple task (voiceovers), but the platform just gets better and better.",
  },
  {
    id: 913,
    background: BackgroundType.Color,
    network: SocialNetwork.Capterra,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.913.jpg",
    name: "Susan J",
    date: "Oct 17, 2022",
    text: "Synthesys has helped me improve the marketing of my products.",
  },
  {
    id: 213,
    background: BackgroundType.Default,
    network: SocialNetwork.G2,
    stars: 5,
    avatar: "/images/signin/review.avatar.id.213.jpg",
    name: "Paul B",
    date: "Dec 13, 2023",
    text: "Investing in this application will undoubtedly bring numerous benefits to various facets of your business. ",
  },
];
