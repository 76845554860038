import styled from "styled-components";

import { EditorTabs } from "./AIHumansEditor";
import { Zone } from "../../../types/project";
import AIHumansScriptPlayer from "./AIHumansScriptPlayer";
import { HumansParagraph } from "../../../types/humansProject";
import { Dispatch, RefObject, SetStateAction } from "react";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { ImportIcon } from "../../../components/Icons/Icons";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { useSelector } from "react-redux";

const ACCEPTED_FORMATS = "audio/mp3, audio/wav, audio/mpeg";
interface Props {
  paragraphs: HumansParagraph[];
  selectedZone: Zone | undefined;
  paragraphActive: number;
  data: {
    value: EditorTabs;
    label: string;
  }[];
  setParagraphs: (nextParagraphs: HumansParagraph[]) => void;
  active: EditorTabs;
  handleTabsChange: (value: EditorTabs) => void;
  uploadSampleLoading: boolean;
  fileInputRef: RefObject<HTMLInputElement>;
  handleUpload: ({ target }: any) => void;
  setIsPlayScript: (isPlayScript: boolean) => void;
}

const AIHumansEditorTabs = ({
  selectedZone,
  setParagraphs,
  paragraphs,
  paragraphActive,
  data,
  active,
  handleTabsChange,
  uploadSampleLoading,
  fileInputRef,
  handleUpload,
  setIsPlayScript,
}: Props) => {
  const { isUploadVoiceEnabled } = useSelector(getProfile);
  const currentParagraph = paragraphs?.[paragraphActive];

  return (
    <Row>
      <Tabs>
        {data.map(({ value, label }) => (
          <Tab key={value} active={value === active} onClick={() => handleTabsChange(value)}>
            {label}
          </Tab>
        ))}
      </Tabs>
      {active === EditorTabs.uploadVoice && (
        <ButtonWrapper>
          <Button
            buttonTheme={ButtonThemes.Secondary}
            icon={<ImportIcon />}
            text={uploadSampleLoading ? "Loading..." : "Upload"}
            disabled={!isUploadVoiceEnabled}
          />
          {isUploadVoiceEnabled && (
            <input
              ref={fileInputRef}
              type="file"
              id="samples"
              name="samples"
              accept={ACCEPTED_FORMATS}
              onChange={handleUpload}
            />
          )}
        </ButtonWrapper>
      )}
      {active === EditorTabs.textEditor && currentParagraph && (
        <AIHumansScriptPlayer
          selectedZone={selectedZone}
          currentParagraph={currentParagraph}
          paragraphs={paragraphs}
          setParagraphs={setParagraphs}
          setIsPlayScript={setIsPlayScript}
        />
      )}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 25px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 16px 0 16px;
`;

const Tab = styled.div<{ active: boolean }>`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  letter-spacing: -0.404119px;
  opacity: 0.6;
  color: ${({ theme }) => theme.primaryText};
  padding: 10px;
  cursor: pointer;

  ${({ active, theme }) =>
    active &&
    `
        background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        border-bottom: 2px solid ${theme.activeMenu};
        opacity: 1;
    `}
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin-left: auto;

  button {
    gap: 4px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

export default AIHumansEditorTabs;
