export const G2logo = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_588)">
      <path
        d="M11.2746 11.3995C11.8797 12.4209 12.4781 13.4306 13.076 14.4393C10.4284 16.4109 6.30925 16.6492 3.25991 14.3787C-0.249203 11.7638 -0.825958 7.30204 1.05277 4.09027C3.21358 0.396045 7.25716 -0.420956 9.86065 0.17812C9.79025 0.326904 8.23094 3.47347 8.23094 3.47347C8.23094 3.47347 8.10767 3.48135 8.03794 3.48266C7.2684 3.51439 6.69525 3.68855 6.08093 3.9975C5.40693 4.33959 4.82934 4.83705 4.39863 5.4464C3.96792 6.05575 3.69721 6.75844 3.61015 7.49305C3.51932 8.23806 3.62523 8.99328 3.91787 9.6874C4.16531 10.2742 4.51532 10.7954 4.98455 11.2354C5.70437 11.9111 6.56096 12.3294 7.55386 12.4679C8.49412 12.5992 9.39839 12.4692 10.2458 12.055C10.5636 11.8999 10.834 11.7286 11.15 11.4936C11.1903 11.4682 11.2261 11.4361 11.2746 11.3995Z"
        fill="#FF492C"
      />
      <path
        d="M11.2805 2.44213C11.1269 2.29509 10.9845 2.15944 10.8428 2.02291C10.7582 1.94151 10.6768 1.85684 10.5902 1.77741C10.5591 1.74875 10.5227 1.70958 10.5227 1.70958C10.5227 1.70958 10.5522 1.64876 10.5648 1.62381C10.7306 1.30021 10.9904 1.06369 11.2985 0.875517C11.6393 0.665861 12.0363 0.559186 12.4394 0.568978C12.9552 0.578824 13.4348 0.703759 13.8395 1.04027C14.1382 1.28861 14.2914 1.60369 14.3184 1.9798C14.3634 2.61432 14.0935 3.10028 13.5574 3.43942C13.2425 3.63897 12.9028 3.79322 12.5623 3.97592C12.3744 4.07679 12.2138 4.1654 12.0303 4.34788C11.8688 4.53102 11.8609 4.70365 11.8609 4.70365L14.3009 4.70059V5.75761H10.5346C10.5346 5.75761 10.5346 5.68541 10.5346 5.65543C10.5202 5.136 10.5825 4.6472 10.8271 4.17547C11.052 3.74268 11.4016 3.42585 11.8215 3.18189C12.145 2.99394 12.4856 2.834 12.8097 2.64693C13.0097 2.53162 13.1509 2.36248 13.1498 2.11721C13.1498 1.90672 12.9924 1.71965 12.7674 1.66123C12.237 1.52207 11.6971 1.74415 11.4164 2.21633C11.3755 2.28525 11.3336 2.35373 11.2805 2.44213Z"
        fill="#FF492C"
      />
      <path
        d="M16.0001 10.276L13.9439 6.82202H9.8749L7.80542 10.3117H11.9043L13.927 13.7492L16.0001 10.276Z"
        fill="#FF492C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_588">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
