export const CloseIconV2 = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2559 7.74416C11.9301 7.41832 11.4034 7.41832 11.0776 7.74416L10.0001 8.82166L8.92258 7.74416C8.59675 7.41832 8.07008 7.41832 7.74425 7.74416C7.41841 8.06999 7.41841 8.59666 7.74425 8.92249L8.82175 9.99999L7.74425 11.0775C7.41841 11.4033 7.41841 11.93 7.74425 12.2558C7.90675 12.4183 8.12008 12.5 8.33342 12.5C8.54675 12.5 8.76008 12.4183 8.92258 12.2558L10.0001 11.1783L11.0776 12.2558C11.2401 12.4183 11.4534 12.5 11.6667 12.5C11.8801 12.5 12.0934 12.4183 12.2559 12.2558C12.5817 11.93 12.5817 11.4033 12.2559 11.0775L11.1784 9.99999L12.2559 8.92249C12.5817 8.59666 12.5817 8.06999 12.2559 7.74416ZM10.0001 16.6667C6.32425 16.6667 3.33341 13.6758 3.33341 9.99999C3.33341 6.32416 6.32425 3.33332 10.0001 3.33332C13.6759 3.33332 16.6667 6.32416 16.6667 9.99999C16.6667 13.6758 13.6759 16.6667 10.0001 16.6667ZM10.0001 1.66666C5.40508 1.66666 1.66675 5.40499 1.66675 9.99999C1.66675 14.595 5.40508 18.3333 10.0001 18.3333C14.5951 18.3333 18.3334 14.595 18.3334 9.99999C18.3334 5.40499 14.5951 1.66666 10.0001 1.66666Z"
      fill="url(#paint0_linear_573_6943)"
    />
    <mask id="mask0_573_6943" maskUnits="userSpaceOnUse" x="1" y="1" width="18" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2559 7.74416C11.9301 7.41832 11.4034 7.41832 11.0776 7.74416L10.0001 8.82166L8.92258 7.74416C8.59675 7.41832 8.07008 7.41832 7.74425 7.74416C7.41841 8.06999 7.41841 8.59666 7.74425 8.92249L8.82175 9.99999L7.74425 11.0775C7.41841 11.4033 7.41841 11.93 7.74425 12.2558C7.90675 12.4183 8.12008 12.5 8.33342 12.5C8.54675 12.5 8.76008 12.4183 8.92258 12.2558L10.0001 11.1783L11.0776 12.2558C11.2401 12.4183 11.4534 12.5 11.6667 12.5C11.8801 12.5 12.0934 12.4183 12.2559 12.2558C12.5817 11.93 12.5817 11.4033 12.2559 11.0775L11.1784 9.99999L12.2559 8.92249C12.5817 8.59666 12.5817 8.06999 12.2559 7.74416ZM10.0001 16.6667C6.32425 16.6667 3.33341 13.6758 3.33341 9.99999C3.33341 6.32416 6.32425 3.33332 10.0001 3.33332C13.6759 3.33332 16.6667 6.32416 16.6667 9.99999C16.6667 13.6758 13.6759 16.6667 10.0001 16.6667ZM10.0001 1.66666C5.40508 1.66666 1.66675 5.40499 1.66675 9.99999C1.66675 14.595 5.40508 18.3333 10.0001 18.3333C14.5951 18.3333 18.3334 14.595 18.3334 9.99999C18.3334 5.40499 14.5951 1.66666 10.0001 1.66666Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_573_6943)" />
    <defs>
      <linearGradient
        id="paint0_linear_573_6943"
        x1="5.25649"
        y1="3.84614"
        x2="15.1283"
        y2="16.5385"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
