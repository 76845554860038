export const CutawayIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <rect y="4" width="24" height="17" rx="4" fill="url(#paint0_linear_2977_21168)" />
    </g>
    <rect x="4" y="7" width="24" height="19" rx="4" fill="url(#paint1_linear_2977_21168)" />
    <path d="M5.21667 15.3633H7.50834" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.36249 16.5085V14.2168" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6.27079 19.6263C8.67553 19.6263 10.625 17.6769 10.625 15.2721C10.625 12.8674 8.67553 10.918 6.27079 10.918C3.86605 10.918 1.91663 12.8674 1.91663 15.2721C1.91663 17.6769 3.86605 19.6263 6.27079 19.6263Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0833 20.0846L10.1666 19.168"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2977_21168"
        x1="27.9089"
        y1="15.1242"
        x2="4.74021"
        y2="1.42973"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.022604" stopColor="#C0DFF4" />
        <stop offset="1" stopColor="#1680D3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2977_21168"
        x1="9.16923"
        y1="9.48462"
        x2="19.5372"
        y2="26.3228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
