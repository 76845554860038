import React from "react";
import styled from "styled-components";

import Slider from "./Slider";
import Switch from "../../../components/Switch/Switch";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { useVideoTrim } from "../../../hooks/useVideoTrim";
import { PauseIcon, PlayIcon } from "../../../components/Icons/Icons";
import Button, { ButtonThemes } from "../../../components/Button/Button";

interface Props {
  videoSrc: string;
  onClick: () => void;
}

function TrimVideo({ videoSrc, onClick }: Props) {
  const {
    videoRef,
    previewRefs,
    playing,
    value,
    right,
    left,
    currentTime,
    videoDuration,
    cursorPosition,
    intervals,
    handleChange,
    handlePlay,
    handlePause,
    handleTimeUpdate,
    setCurrentTime,
  } = useVideoTrim({ videoSrc });

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes.toFixed(0).toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toFixed(0).toString().padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <>
      <VideoBackground src={videoSrc} ref={videoRef} onTimeUpdate={handleTimeUpdate}>
        <source src={videoSrc} type="video/mp4" />
      </VideoBackground>
      <TimelineWrapper>
        <Row playing={playing}>
          <IconButton
            iconButtonTheme={IconButtonThemes.Rounded}
            onClick={!playing ? handlePlay : handlePause}
            icon={!playing ? <PlayIcon /> : <PauseIcon />}
          />
          <SliderWrapper right={right} left={left}>
            <Slider
              value={value}
              previewRefs={previewRefs}
              max={videoDuration}
              onChange={handleChange}
              videoRef={videoRef}
              currentTime={currentTime}
              setCurrentTime={setCurrentTime}
              cursorPosition={cursorPosition}
            />
            <Bottom>
              <LabelsWrapper>
                {intervals.map((interval, index) => (
                  <span key={index}>{formatTime(interval)}</span>
                ))}
              </LabelsWrapper>
              <ButtonWrapper>
                <Button text="Apply" buttonTheme={ButtonThemes.Primary} onClick={onClick} />
              </ButtonWrapper>
              {/* <SwitchWrapper>
                <span>Auto trim</span>
                <Switch />
              </SwitchWrapper> */}
            </Bottom>
          </SliderWrapper>
        </Row>
      </TimelineWrapper>
    </>
  );
}

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
`;

const TimelineWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  width: auto;
  height: 118px;
  padding: 8px 12px;
  border-radius: 20px;
  background: #191b1f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Row = styled.div<{ playing: boolean }>`
  display: flex;
  gap: 8px;

  & > button {
    margin-top: 18px;
    width: 32px;
    height: 32px;
    box-shadow: none;

    ${({ playing }) =>
      !playing &&
      `
        svg {
          transform: translateX(2px);
        }
      `}
  }
`;

const SliderWrapper = styled.div<{ left: number; right: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(25, 27, 31, 0.8);
    width: ${({ right }) => right}%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(25, 27, 31, 0.8);
    width: ${({ left }) => left}%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 27px;
`;

const LabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.white};
  }
`;

const SwitchWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;

  & > span:first-of-type {
    color: ${({ theme }) => theme.activeMenu};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    width: max-content;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
  min-width: 110px;

  button {
    height: 28px;
    box-shadow: none;
  }
`;

export default TrimVideo;
