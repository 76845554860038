import styled from "styled-components";

import { UploadVideoTabs } from "../../../../types/videoTranslation";

interface Props {
  data: { value: UploadVideoTabs; title: string; disabled?: boolean; isDisplay?: boolean }[];
  active: UploadVideoTabs;
  handleActive: (status: UploadVideoTabs) => void;
}

const Tabs = ({ data, active, handleActive }: Props) => {
  return (
    <Wrapper>
      {data.map(({ value, title, disabled, isDisplay = true }) => (
        <Content
          key={title}
          className={value === active ? "active" : "not-active"}
          onClick={() => {
            if (disabled || !isDisplay) return;
            handleActive(value);
          }}
          disabled={disabled}
          isDisplay={isDisplay}
        >
          <Title>{title}</Title>
        </Content>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  max-height: 40px;

  position: relative;

  .active {
    border-bottom: 1px solid ${({ theme }) => theme.activeMenu};

    & > div {
      color: ${({ theme }) => theme.activeMenu};
    }
  }

  .not-active {
    border-bottom: ${({ theme }) => theme.videoTranslationBorder};
  }

  @media (max-width: 600px) {
    overflow: scroll;
  }
`;

const Content = styled.div<{ disabled?: boolean; isDisplay?: boolean }>`
  width: 100%;
  cursor: pointer;
  min-width: 100px;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
 `}

  ${({ isDisplay }) =>
    !isDisplay &&
    `
    cursor: default;

    & > div {
      display: none;
    }
 `}
`;

const Title = styled.div`
  color: #6f7074;
  padding: 12px 0px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  flex: 1;
`;

export default Tabs;
