export const Monitor = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6615 18.6641C26.6615 19.3987 26.0641 19.9974 25.3281 19.9974H16.1108H15.8788H6.66146C5.92546 19.9974 5.32812 19.3987 5.32812 18.6641V7.9974C5.32812 7.26273 5.92546 6.66406 6.66146 6.66406H25.3281C26.0641 6.66406 26.6615 7.26273 26.6615 7.9974V18.6641ZM25.3307 4H6.66406C4.45873 4 2.66406 5.79467 2.66406 8V18.6667C2.66406 20.872 4.45873 22.6667 6.66406 22.6667H14.6641V25.3333H9.33073C8.5974 25.3333 7.9974 25.9333 7.9974 26.6667C7.9974 27.4 8.5974 28 9.33073 28H22.6641C23.3974 28 23.9974 27.4 23.9974 26.6667C23.9974 25.9333 23.3974 25.3333 22.6641 25.3333H17.3307V22.6667H25.3307C27.5361 22.6667 29.3307 20.872 29.3307 18.6667V8C29.3307 5.79467 27.5361 4 25.3307 4Z"
      fill="#0063B4"
    />
    <mask
      id="mask0_12_7251"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="4"
      width="28"
      height="24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6615 18.6641C26.6615 19.3987 26.0641 19.9974 25.3281 19.9974H16.1108H15.8788H6.66146C5.92546 19.9974 5.32812 19.3987 5.32812 18.6641V7.9974C5.32812 7.26273 5.92546 6.66406 6.66146 6.66406H25.3281C26.0641 6.66406 26.6615 7.26273 26.6615 7.9974V18.6641ZM25.3307 4H6.66406C4.45873 4 2.66406 5.79467 2.66406 8V18.6667C2.66406 20.872 4.45873 22.6667 6.66406 22.6667H14.6641V25.3333H9.33073C8.5974 25.3333 7.9974 25.9333 7.9974 26.6667C7.9974 27.4 8.5974 28 9.33073 28H22.6641C23.3974 28 23.9974 27.4 23.9974 26.6667C23.9974 25.9333 23.3974 25.3333 22.6641 25.3333H17.3307V22.6667H25.3307C27.5361 22.6667 29.3307 20.872 29.3307 18.6667V8C29.3307 5.79467 27.5361 4 25.3307 4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_12_7251)"></g>
  </svg>
);
