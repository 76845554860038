export const ClockIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66666 6.33334H7.66666V4.33334C7.66666 3.96468 7.36799 3.66668 6.99999 3.66668C6.63199 3.66668 6.33333 3.96468 6.33333 4.33334V7.00001C6.33333 7.36868 6.63199 7.66668 6.99999 7.66668H9.66666C10.0353 7.66668 10.3333 7.36868 10.3333 7.00001C10.3333 6.63134 10.0353 6.33334 9.66666 6.33334ZM6.99999 12.3333C4.05933 12.3333 1.66666 9.94068 1.66666 7.00001C1.66666 4.05934 4.05933 1.66668 6.99999 1.66668C9.94066 1.66668 12.3333 4.05934 12.3333 7.00001C12.3333 9.94068 9.94066 12.3333 6.99999 12.3333ZM7 0.333344C3.324 0.333344 0.333328 3.32401 0.333328 7.00001C0.333328 10.676 3.324 13.6667 7 13.6667C10.676 13.6667 13.6667 10.676 13.6667 7.00001C13.6667 3.32401 10.676 0.333344 7 0.333344Z"
      fill="#191B1F"
    />
  </svg>
);
