export const PricingVoicesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9961 4.00049C10.8931 4.00049 9.99609 4.89749 9.99609 6.00049V11.0005C9.99609 12.1035 10.8931 13.0005 11.9961 13.0005C13.0991 13.0005 13.9961 12.1035 13.9961 11.0005V6.00049C13.9961 4.89749 13.0991 4.00049 11.9961 4.00049ZM15.998 11.0002C15.998 13.2062 14.204 15.0002 11.998 15.0002C9.79205 15.0002 7.99805 13.2062 7.99805 11.0002V6.00024C7.99805 3.79424 9.79205 2.00024 11.998 2.00024C14.204 2.00024 15.998 3.79424 15.998 6.00024V11.0002ZM17.998 10.0002C18.551 10.0002 18.998 10.4482 18.998 11.0002C18.998 14.5202 16.385 17.4322 12.998 17.9202V20.0002H15.103C15.596 20.0002 15.998 20.4022 15.998 20.8952V21.1052C15.998 21.5982 15.596 22.0002 15.103 22.0002H8.89305C8.40005 22.0002 7.99805 21.5982 7.99805 21.1052V20.8952C7.99805 20.4022 8.40005 20.0002 8.89305 20.0002H10.998V17.9202C7.61105 17.4322 4.99805 14.5202 4.99805 11.0002C4.99805 10.4482 5.44505 10.0002 5.99805 10.0002C6.55105 10.0002 6.99805 10.4482 6.99805 11.0002C6.99805 13.7572 9.24105 16.0002 11.998 16.0002C14.755 16.0002 16.998 13.7572 16.998 11.0002C16.998 10.4482 17.445 10.0002 17.998 10.0002Z"
      fill="url(#paint0_linear_0_310)"
    />
    <mask id="mask0_0_310" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="4" y="2" width="15" height="21">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9961 4.00049C10.8931 4.00049 9.99609 4.89749 9.99609 6.00049V11.0005C9.99609 12.1035 10.8931 13.0005 11.9961 13.0005C13.0991 13.0005 13.9961 12.1035 13.9961 11.0005V6.00049C13.9961 4.89749 13.0991 4.00049 11.9961 4.00049ZM15.998 11.0002C15.998 13.2062 14.204 15.0002 11.998 15.0002C9.79205 15.0002 7.99805 13.2062 7.99805 11.0002V6.00024C7.99805 3.79424 9.79205 2.00024 11.998 2.00024C14.204 2.00024 15.998 3.79424 15.998 6.00024V11.0002ZM17.998 10.0002C18.551 10.0002 18.998 10.4482 18.998 11.0002C18.998 14.5202 16.385 17.4322 12.998 17.9202V20.0002H15.103C15.596 20.0002 15.998 20.4022 15.998 20.8952V21.1052C15.998 21.5982 15.596 22.0002 15.103 22.0002H8.89305C8.40005 22.0002 7.99805 21.5982 7.99805 21.1052V20.8952C7.99805 20.4022 8.40005 20.0002 8.89305 20.0002H10.998V17.9202C7.61105 17.4322 4.99805 14.5202 4.99805 11.0002C4.99805 10.4482 5.44505 10.0002 5.99805 10.0002C6.55105 10.0002 6.99805 10.4482 6.99805 11.0002C6.99805 13.7572 9.24105 16.0002 11.998 16.0002C14.755 16.0002 16.998 13.7572 16.998 11.0002C16.998 10.4482 17.445 10.0002 17.998 10.0002Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_0_310)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_0_310"
        x1="8.01343"
        y1="4.61563"
        x2="20.1681"
        y2="15.5549"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
