export const FontStyleActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.98999 5.92982V4.41982C1.98999 3.39982 2.81999 2.56982 3.83999 2.56982H16.76C17.78 2.56982 18.61 3.39982 18.61 4.41982V5.92982"
      stroke="url(#paint0_linear_1_16778)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3 18.0998V3.31982"
      stroke="url(#paint1_linear_1_16778)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.8999 18.1001H12.4799"
      stroke="url(#paint2_linear_1_16778)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6799 10.3398H20.6899C21.4199 10.3398 22.0099 10.9298 22.0099 11.6598V12.4598"
      stroke="url(#paint3_linear_1_16778)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0801 21.4301V10.8701"
      stroke="url(#paint4_linear_1_16778)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9399 21.4302H18.2199"
      stroke="url(#paint5_linear_1_16778)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_16778"
        x1="5.56968"
        y1="3.00921"
        x2="6.19984"
        y2="7.01679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_16778"
        x1="10.5154"
        y1="5.25259"
        x2="12.0751"
        y2="5.38827"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_16778"
        x1="8.10175"
        y1="18.2309"
        x2="8.26886"
        y2="19.4298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_16778"
        x1="15.4741"
        y1="10.6171"
        x2="15.9676"
        y2="13.1105"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_16778"
        x1="16.2955"
        y1="12.251"
        x2="17.8439"
        y2="12.4396"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_16778"
        x1="14.8618"
        y1="21.5609"
        x2="15.0768"
        y2="22.7441"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
