export const StarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00035 10.6999C8.10635 10.6999 8.21235 10.7253 8.30902 10.7753L10.8217 12.0886L10.3437 9.31725C10.3057 9.09992 10.3783 8.87859 10.537 8.72459L12.561 6.76725L9.75902 6.35925C9.54235 6.32792 9.35502 6.19258 9.25768 5.99659L8.00035 3.46725L6.74302 5.99659C6.64568 6.19258 6.45835 6.32792 6.24168 6.35925L3.43968 6.76725L5.46368 8.72459C5.62235 8.87859 5.69502 9.09992 5.65702 9.31725L5.17902 12.0886L7.69168 10.7753C7.78835 10.7253 7.89435 10.6999 8.00035 10.6999ZM11.7083 13.9699C11.6023 13.9699 11.4963 13.9453 11.3997 13.8946L8.00035 12.1186L4.60102 13.8946C4.37568 14.0119 4.10368 13.9913 3.90035 13.8419C3.69502 13.6926 3.59302 13.4399 3.63568 13.1899L4.28368 9.43792L1.53702 6.78125C1.35368 6.60459 1.28835 6.33925 1.36635 6.09725C1.44368 5.85525 1.65302 5.67925 1.90435 5.64259L5.70502 5.09059L7.40302 1.67325C7.62835 1.21992 8.37235 1.21992 8.59768 1.67325L10.2957 5.09059L14.0963 5.64259C14.3477 5.67925 14.557 5.85525 14.6343 6.09725C14.7123 6.33925 14.647 6.60459 14.4637 6.78125L11.717 9.43792L12.365 13.1899C12.4077 13.4399 12.3057 13.6926 12.1003 13.8419C11.985 13.9273 11.847 13.9699 11.7083 13.9699Z"
      fill="#191B1F"
    />
    <mask id="mask0_493_16250" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="13">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00035 10.6999C8.10635 10.6999 8.21235 10.7253 8.30902 10.7753L10.8217 12.0886L10.3437 9.31725C10.3057 9.09992 10.3783 8.87859 10.537 8.72459L12.561 6.76725L9.75902 6.35925C9.54235 6.32792 9.35502 6.19258 9.25768 5.99659L8.00035 3.46725L6.74302 5.99659C6.64568 6.19258 6.45835 6.32792 6.24168 6.35925L3.43968 6.76725L5.46368 8.72459C5.62235 8.87859 5.69502 9.09992 5.65702 9.31725L5.17902 12.0886L7.69168 10.7753C7.78835 10.7253 7.89435 10.6999 8.00035 10.6999ZM11.7083 13.9699C11.6023 13.9699 11.4963 13.9453 11.3997 13.8946L8.00035 12.1186L4.60102 13.8946C4.37568 14.0119 4.10368 13.9913 3.90035 13.8419C3.69502 13.6926 3.59302 13.4399 3.63568 13.1899L4.28368 9.43792L1.53702 6.78125C1.35368 6.60459 1.28835 6.33925 1.36635 6.09725C1.44368 5.85525 1.65302 5.67925 1.90435 5.64259L5.70502 5.09059L7.40302 1.67325C7.62835 1.21992 8.37235 1.21992 8.59768 1.67325L10.2957 5.09059L14.0963 5.64259C14.3477 5.67925 14.557 5.85525 14.6343 6.09725C14.7123 6.33925 14.647 6.60459 14.4637 6.78125L11.717 9.43792L12.365 13.1899C12.4077 13.4399 12.3057 13.6926 12.1003 13.8419C11.985 13.9273 11.847 13.9699 11.7083 13.9699Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_16250)"></g>
  </svg>
);
