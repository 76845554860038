export const DropdownIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99998 10.6666C7.84798 10.6666 7.69665 10.6152 7.57331 10.5119L3.57331 7.17857C3.29065 6.94324 3.25198 6.52257 3.48798 6.23991C3.72331 5.95724 4.14331 5.91924 4.42665 6.15457L8.00731 9.13857L11.582 6.26191C11.8686 6.03124 12.2886 6.07657 12.5193 6.36324C12.75 6.64991 12.7046 7.06924 12.418 7.30057L8.41798 10.5192C8.29598 10.6172 8.14798 10.6666 7.99998 10.6666"
      fill="url(#paint0_linear_493_12294)"
    />
    <mask id="mask0_493_12294" maskUnits="userSpaceOnUse" x="3" y="6" width="10" height="5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 10.6666C7.84798 10.6666 7.69665 10.6152 7.57331 10.5119L3.57331 7.17857C3.29065 6.94324 3.25198 6.52257 3.48798 6.23991C3.72331 5.95724 4.14331 5.91924 4.42665 6.15457L8.00731 9.13857L11.582 6.26191C11.8686 6.03124 12.2886 6.07657 12.5193 6.36324C12.75 6.64991 12.7046 7.06924 12.418 7.30057L8.41798 10.5192C8.29598 10.6172 8.14798 10.6666 7.99998 10.6666"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_12294)">
      <rect width="16" height="16" fill="url(#paint1_linear_493_12294)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_493_12294"
        x1="5.34359"
        y1="6.61046"
        x2="7.2701"
        y2="11.5646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_493_12294"
        x1="3.44615"
        y1="2.09231"
        x2="12.9231"
        y2="14.2769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
