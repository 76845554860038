export const MagnifyingGlassMinus = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.875 10.875H13.875"
      stroke="url(#paint0_linear_2_10899)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.875 18.7495C15.2242 18.7495 18.75 15.2238 18.75 10.8745C18.75 6.52527 15.2242 2.99951 10.875 2.99951C6.52576 2.99951 3 6.52527 3 10.8745C3 15.2238 6.52576 18.7495 10.875 18.7495Z"
      stroke="url(#paint1_linear_2_10899)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4443 16.4434L21.0006 20.9997"
      stroke="url(#paint2_linear_2_10899)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2_10899"
        x1="9.16731"
        y1="11.0058"
        x2="9.32313"
        y2="12.2078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2_10899"
        x1="6.39231"
        y1="5.05913"
        x2="15.7212"
        y2="17.0534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2_10899"
        x1="17.4257"
        y1="17.0392"
        x2="20.1244"
        y2="20.509"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
