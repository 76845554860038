import { useEffect, useRef, useState } from "react";

interface Props {
  audioPaths: string[];
  isButtonClicked: boolean;
  activeSceneId: number | undefined;
  setIsButtonClicked: (b: boolean) => void;
  handleChangeActiveScene: (activeScene: number) => void;
}

export const useVideoPlayer = ({
  audioPaths,
  isButtonClicked,
  activeSceneId,
  setIsButtonClicked,
  handleChangeActiveScene,
}: Props) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(() => {
    if (audioPaths && audioPaths.length && isButtonClicked) {
      const audio = audioRef.current;
      const currentAudio = audioPaths[activeSceneId as number];

      if (audio && currentAudio) {
        if (isPlaying) {
          audio.pause();
          audio.currentTime = 0;
        }

        audio.src = currentAudio;
        audio.play();
        setIsPlaying(true);
      }

      return () => {
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          setIsPlaying(false);
        }
      };
    }
  }, [activeSceneId, isButtonClicked]);

  const nextScene = () => {
    if (activeSceneId === audioPaths.length - 1) {
      handleChangeActiveScene(0);
      setIsButtonClicked(false);
    } else {
      handleChangeActiveScene((activeSceneId as number) + 1);
    }
  };

  return {
    audioRef,
    nextScene,
  };
};
