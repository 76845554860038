export const LinkedInIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8125 1H3.1875C1.97938 1 1 1.97938 1 3.1875V12.8125C1 14.0206 1.97938 15 3.1875 15H12.8125C14.0206 15 15 14.0206 15 12.8125V3.1875C15 1.97938 14.0206 1 12.8125 1Z"
      fill="url(#paint0_linear_4577_31259)"
    />
    <path
      d="M5.61794 11.9998H3.7763V6.20403H5.61794V11.9998ZM4.69712 5.39155C4.10129 5.39155 3.66797 4.95822 3.66797 4.3624C3.66797 3.76658 4.15546 3.33325 4.69712 3.33325C5.29294 3.33325 5.72626 3.76658 5.72626 4.3624C5.72626 4.95822 5.29294 5.39155 4.69712 5.39155ZM12.3345 11.9998H10.4928V8.85815C10.4928 7.93733 10.1137 7.6665 9.57202 7.6665C9.03036 7.6665 8.48871 8.09983 8.48871 8.91231V11.9998H6.64708V6.20403H8.38038V7.01652C8.54287 6.63736 9.19286 6.04153 10.1137 6.04153C11.1428 6.04153 12.2261 6.63736 12.2261 8.42482V11.9998H12.3345Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4577_31259"
        x1="4.01539"
        y1="2.83077"
        x2="12.3077"
        y2="13.4923"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
