import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssets } from "../redux/reducers/assetReducer";
import { getProfile } from "../redux/reducers/profileReducer";
import debounce from "lodash.debounce";
import {
  Asset,
  clearAsset,
  createUserAssetServer,
  deleteUserAssetServer,
  getAssetServer,
  searchAssetResultLoading,
} from "../redux/actions/assetAction";
import { ConfirmationDelete, DeleteType } from "../types/confirmationDelete";
import { AssetModules } from "../types/asset";
import { Popups, updatePopup } from "../redux/actions/popupsActions";
import { toast } from "react-toastify";

const MAX_SOUNDTRACK_SIZE = 52428800;
const searchTimeoutDelay = 500;

export const useSoundtrack = () => {
  const dispatch = useDispatch();
  const assets = useSelector(getAssets);
  const uploadSoundtrackLoading = assets[Asset.Soundtracks].uploadLoading;
  const soundtracks = assets[Asset.Soundtracks].data;
  const soundtracksLoading = assets[Asset.Soundtracks].isLoading;
  const soundtracksHasMore = assets[Asset.Soundtracks].hasMore;
  const soundtracksCurrentPage = assets[Asset.Soundtracks].pageNumber;

  const [activeSoundtrack, setActiveSoundtrack] = useState<number>(0);
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handlePlayingSoundtrack = (id: number) => {
    setActiveSoundtrack(id - 1);
  };

  const debounceHandleGetBackground = useRef(
    debounce(async (search?: string) => {
      dispatch(
        getAssetServer({
          keyword: search,
          pageNumber: 1,
          assetTypeId: Asset.Soundtracks,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const fetchMore = () => {
    dispatch(
      getAssetServer({
        keyword: "",
        pageNumber: soundtracksCurrentPage + 1,
        assetTypeId: Asset.Soundtracks,
      }),
    );
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    if (uploadedFile && uploadedFile.size > MAX_SOUNDTRACK_SIZE) {
      setErrorSize(true);
    } else {
      const audio = new Audio();
      audio.addEventListener("loadedmetadata", () => {
        const durationInSeconds = audio.duration;
        const durationInMinutes = Math.floor(durationInSeconds / 60);

        if (durationInMinutes > 10) {
          toast.error("Please make sure your audio duration is less than 10 minutes");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          return;
        } else {
          const formData = new FormData();
          formData.append("AssetTypeId", "3");
          formData.append("Status", "1");
          formData.append("FileName", uploadedFile.name);
          formData.append("File", uploadedFile);

          setErrorSize(false);
          dispatch(
            createUserAssetServer({
              data: formData,
              assetTypeId: Asset.Soundtracks,
            }),
          );
        }
      });

      audio.src = URL.createObjectURL(uploadedFile);
    }
  };

  const handleUserAssetDelete = (e: any, id: number) => {
    if (!id) {
      throw Error(`The ID ${AssetModules[Asset.Soundtracks]} is missing when deleting`);
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: `Are you sure you want to delete ${AssetModules[Asset.Soundtracks]}?`,
      description: `The ${AssetModules[Asset.Soundtracks]} will be removed and the action cannot be undone`,
      typeId: Asset.Soundtracks,
      type: DeleteType.Asset,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    dispatch(searchAssetResultLoading(true, Asset.Soundtracks));
    dispatch(clearAsset({ assetTypeId: Asset.Soundtracks }));
    debounceHandleGetBackground(search);
  }, [search]);

  return {
    search,
    activeSoundtrack,
    errorSize,
    uploadSoundtrackLoading,
    soundtracks,
    soundtracksLoading,
    soundtracksHasMore,
    fileInputRef,
    handleSearchChange,
    handlePlayingSoundtrack,
    fetchMore,
    handleUpload,
    handleUserAssetDelete,
  };
};
