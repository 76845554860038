export const CloudArrowUp = () => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5003 32.498H11.7503C10.5094 32.4968 9.28297 32.2317 8.15239 31.7203C7.02181 31.2088 6.01294 30.4628 5.19275 29.5316C4.37255 28.6004 3.75979 27.5055 3.39514 26.3194C3.03048 25.1333 2.92227 23.8832 3.07769 22.6521C3.23311 21.421 3.64861 20.237 4.2966 19.1788C4.94459 18.1205 5.81025 17.2122 6.83613 16.5141C7.86201 15.816 9.02465 15.3441 10.2469 15.1297C11.4691 14.9152 12.7229 14.9632 13.9252 15.2705"
      stroke="url(#paint0_linear_29_4528)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 19.9978C13.0002 18.0172 13.471 16.0651 14.3737 14.3021C15.2763 12.5392 16.5849 11.016 18.1917 9.85805C19.7985 8.70009 21.6575 7.94051 23.6154 7.64191C25.5733 7.3433 27.5742 7.51422 29.4531 8.14058C31.332 8.76694 33.0352 9.8308 34.4224 11.2445C35.8095 12.6582 36.8408 14.3813 37.4314 16.2718C38.0219 18.1622 38.1548 20.166 37.8191 22.1179C37.4834 24.0698 36.6886 25.914 35.5004 27.4985"
      stroke="url(#paint1_linear_29_4528)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9463 25.3005L24.2496 19.9971L29.5529 25.3005"
      stroke="url(#paint2_linear_29_4528)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.25 32.4971V19.9971"
      stroke="url(#paint3_linear_29_4528)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_29_4528"
        x1="5.69927"
        y1="17.2865"
        x2="16.3544"
        y2="27.0652"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_29_4528"
        x1="18.3846"
        y1="10.1143"
        x2="29.349"
        y2="27.7361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_29_4528"
        x1="21.2308"
        y1="20.6906"
        x2="23.4204"
        y2="26.321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_29_4528"
        x1="24.4654"
        y1="21.6317"
        x2="26.0204"
        y2="21.7916"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
