import React, { useContext } from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { TrashIcon } from "../../../../../components/Icons/TrashIcon";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { VideoEditorContext } from "../../../../../App";

const DeleteShape = () => {
  const { handleDeleteCurrentObject } = useContext(VideoEditorContext);

  return (
    <Wrapper>
      <IconButton iconButtonTheme={IconButtonThemes.Rounded} icon={<TrashIcon />} onClick={handleDeleteCurrentObject} />
      <Tooltip
        text={"Delete"}
        position="bottom"
        style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
        reverseColor
        arrow
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export default DeleteShape;
