import styled from "styled-components";

import Container from "../../../components/Container/Container";
import HeroRaiting from "./HeroRaiting";
import Toggle from "./Toggle";
import PlanCard from "./PlanCard";
import PricingTrustedBrands from "./PricingTrustedBrands";
import LineBreak from "../../../components/LineBreak/LineBreak";
import { planCardsMocks } from "../../../mocks/planCardsMocks";
import { useMobile } from "../../../hooks/useResponsible";
import { Capterra } from "../../../components/Icons/Icons";
import RatingCard from "./RatingCard";

const Hero = () => {
  const mobilePlanCards = [...planCardsMocks].reverse();

  return (
    <Wrapper>
      <Container>
        <HeadingWrapper>
          <RatingCard icon={<Capterra />} rating="4.6" />
          <Title>
            Join the Synthesys Movement:
            <LineBreak firstBreak="768" /> 65,000+ Creators/Businesses and Counting
          </Title>
          <Toggle />
        </HeadingWrapper>
      </Container>
      <DesktopCardsWrapper>
        {planCardsMocks.map((card, idx) => (
          <PlanCard key={idx} {...card} />
        ))}
      </DesktopCardsWrapper>
      <MobileCardsWrapper>
        {mobilePlanCards.map((card, idx) => (
          <PlanCard key={idx} {...card} />
        ))}
      </MobileCardsWrapper>
      <Container>
        <PricingTrustedBrands />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: -7px;
  width: 100vw;
  padding: 54px 0 56px;
  background: linear-gradient(180deg, #0f5e9f 0%, #084f8a 97.46%);
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding: 32px 0;
  }
`;

const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 48px;

  @media (max-width: 767px) {
    gap: 14px;
  }
`;

const Title = styled.h1`
  color: #ffffff;

  font-size: 48px;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -1.92px;
  text-align: center;

  @media (max-width: 999px) {
    font-size: 42px;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    margin-bottom: 10px;
  }

  @media (max-width: 501px) {
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: 0;
  }

  @media (max-width: 420px) {
    font-size: 20px;
    text-align: left;
  }
`;

const DesktopCardsWrapper = styled.div`
  max-width: 1290px;
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 23px;
  padding: 0px 30px 15px;
  margin: 0px -30px -15px;
  overflow-x: auto;

  @media screen and (max-width: 999px) {
    display: none;
  }
`;

const MobileCardsWrapper = styled.div`
  display: none;

  @media screen and (max-width: 999px) {
    display: flex;
    align-items: stretch;
    gap: 24px;
    width: 100%;
    max-width: 1250px;
    padding: 0px 30px 15px;
    margin: 0px -30px -15px;
    overflow-x: auto;
  }

  @media screen and (max-width: 440px) {
    padding: 0px 16px 15px;
    margin: 0px -16px -15px;
  }
`;

export default Hero;
