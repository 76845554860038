import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { differenceInSeconds } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";

import ProgressBar from "../ProgressBar";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import { VideoTranslationContext } from "../../../../App";
import {
  getVideoTranslateProjectByIdServer,
  updateSingleVideoData,
} from "../../../../redux/actions/videoTranslationActions";
import { downloadFile } from "../../../../lib/downloadFile";
import { parsePercent } from "../../../../lib/parsePercent";
import { VideoTranslationStatus } from "../../../../types/videoTranslation";
import { getVideoTranslateProjectById } from "../../../../redux/reducers/videoTranslationReducer";

export const MIN_DURATION = 0;

const DownloadVideoStep = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const video = useSelector(getVideoTranslateProjectById);

  const { setUploadedVideo, setCurrentStep, setIsEnableCaption } = useContext(VideoTranslationContext);

  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [isVideoGeneratedLoading, setIsVideoGeneratedLoading] = useState(true);

  const lastUpdateTime = new Date((video?.updateDateTime as string) + "Z");
  const timeElapsed = (Number(currentTime) - Number(lastUpdateTime)) / 1000;
  const percentComplete = (timeElapsed / Number(video?.estimatedTime)) * 100;

  const targetDate = new Date((video?.estimatedCompleteDateTime as string) + "Z");
  const diffInSeconds = differenceInSeconds(targetDate, currentTime);
  const minutes = Math.floor(diffInSeconds / 60);
  const seconds = diffInSeconds % 60;
  const formattedDuration = `${minutes} minutes ${seconds} seconds remaining`;

  const handleGenerateNewVideo = () => {
    dispatch(updateSingleVideoData({ data: null }));
    navigate("/video-translation/");
    setUploadedVideo(null);
    setIsEnableCaption(false);
    setCurrentStep(0);
  };

  useEffect(() => {
    setIsVideoGeneratedLoading(true);
    setUploadedVideo(null);
  }, [id]);

  useEffect(() => {
    if (video) {
      if (video.status === VideoTranslationStatus.Completed) {
        setIsVideoGeneratedLoading(false);
        return;
      }

      if (video.status === VideoTranslationStatus.Failed) {
        setCurrentStep(0);
        navigate("/video-translation/");
        dispatch(updateSingleVideoData({ data: null }));
        setIsVideoGeneratedLoading(false);
        return;
      }
    }
  }, [isVideoGeneratedLoading, video?.status]);

  useEffect(() => {
    let intervalId: any;

    if (video && isVideoGeneratedLoading) {
      intervalId = setInterval(() => {
        setCurrentTime(new Date());
        dispatch(getVideoTranslateProjectByIdServer({ id: video.videoTranslateProjectId as number }));
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isVideoGeneratedLoading, video?.status]);

  return (
    <Wrapper>
      <>
        {!video ? (
          <ProgressBarWrapper>
            <CircularProgress color="#009af7" />
          </ProgressBarWrapper>
        ) : isVideoGeneratedLoading ? (
          <>
            <ProgressBarWrapper>
              <ProgressBar
                value={percentComplete}
                title={`${statusText[video?.status as VideoTranslationStatus]} ${parsePercent(percentComplete)}%`}
                bottomText={diffInSeconds > MIN_DURATION ? formattedDuration : ""}
                maxWidth={450}
              />
              <TextHelper>You can leave this page, and the video will continue rendering in the background.</TextHelper>
            </ProgressBarWrapper>
          </>
        ) : (
          <ImgWrapper>
            {video?.outputUrl && (
              <PreviewVideo controls>
                {video?.outputUrl ? <source src={video.outputUrl as string} /> : <p>no uploaded video</p>}
              </PreviewVideo>
            )}
          </ImgWrapper>
        )}
      </>
      <DownloadVideoButton>
        <Button
          text="Download video"
          disabled={isVideoGeneratedLoading}
          onClick={(e: any) => downloadFile(e, video?.outputUrl as string)}
        />
      </DownloadVideoButton>
      <NewVideoButton>
        <Button text="Generate new video" buttonTheme={ButtonThemes.Transparent} onClick={handleGenerateNewVideo} />
      </NewVideoButton>
    </Wrapper>
  );
};

const statusText = {
  [VideoTranslationStatus.Pending]: "Pending",
  [VideoTranslationStatus.InProgress]: "Generating",
  [VideoTranslationStatus.Completed]: "Completed",
  [VideoTranslationStatus.Failed]: "Failed",
};

const Wrapper = styled("div")`
  width: 100%;
  height: 100%;
  margin: 20px auto auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: none;
  gap: 24px;

  @media (max-width: 400px) {
    height: auto;
    padding-bottom: 10px;
  }
`;

const TextHelper = styled("div")`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #6f7074;
`;

const ProgressBarWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-height: 306px;
  max-width: 600px;
  min-height: 306px;
  overflow: hidden;
  background: white;
  padding: 32px 12px 12px;

  ${({ theme }) =>
    `
      border-radius: 8px;
      background: ${theme.videoTranslationGenerete};
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${theme.videoTranslationGray.replace(
        "#",
        "",
      )}' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
 
 `}

  div {
    max-width: 97%;
    justify-content: center;
    align-items: center;
  }
`;

const DownloadVideoButton = styled("div")`
  width: 100%;
  button {
    box-shadow: none;
  }
`;

const NewVideoButton = styled("div")`
  width: 100%;
  button {
    display: flex;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.4px;
      text-align: center;
      color: #0063b4;
    }
  }
`;

const ImgWrapper = styled("div")`
  display: flex;
  justify-content: center;
  max-width: 600px;
  height: auto;
  position: relative;
  video {
    max-height: 306px;
  }
`;

const PreviewVideo = styled("video")`
  width: 100%;
  height: 100%;
`;

export default DownloadVideoStep;
