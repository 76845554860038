import { Humatar, HumatarModules, HumatarPreview } from "../../types/humatar";
import { StoreType } from "../../types/store";
import {
  BOOKMARK_HUMATAR_SERVER,
  CLEAR_HUMATARS,
  CLEAR_SEARCH,
  DELETE_CUSTOM_HUMATAR_SERVER,
  FILTER_HUMATAR_LOADING,
  GET_HUMATARS_SERVER,
  GET_HUMATAR_PREVIEW_SERVER,
  SEARCH_HUMATARS_RESULT_LOADING,
  SET_PAGE_HUMATARS,
  UPDATE_HAS_MORE_HUMATARS,
  UPDATE_HUMATAR_LOADING,
  UPLOAD_HUMATAR_SERVER,
} from "../actions/humatarActions";

export interface humatarStateType {
  [HumatarModules.humatarList]: {
    data: Humatar[];
    search: string;
    isLoading: boolean;
    hasMore: boolean;
    pageNumber: number;
    searchLoading: boolean;
    filterLoading: boolean;
    isDeleting: boolean;
  };
  [HumatarModules.humatarPreview]: {
    data: HumatarPreview[];
    isLoading: boolean;
  };
  [HumatarModules.uploadHumatar]: {
    isLoading: boolean;
    isError: boolean;
    filename: string;
    isSuccess: boolean;
    errorMessage?: string;
  };
  isHydrated: boolean;
}

const humatarInitialState: humatarStateType = {
  [HumatarModules.humatarList]: {
    data: [],
    search: "",
    isLoading: false,
    hasMore: true,
    pageNumber: 0,
    searchLoading: false,
    filterLoading: false,
    isDeleting: false,
  },
  [HumatarModules.humatarPreview]: {
    data: [],
    isLoading: false,
  },
  [HumatarModules.uploadHumatar]: {
    isLoading: false,
    isError: false,
    filename: "",
    isSuccess: false,
  },
  isHydrated: false,
};

const humatarReducer = (state = humatarInitialState, action: any) => {
  switch (action.type) {
    case `${UPLOAD_HUMATAR_SERVER}_FAIL`: {
      return {
        ...state,
        [HumatarModules.uploadHumatar]: {
          ...state[HumatarModules.uploadHumatar],
          errorMessage:
            action.error?.response?.data?.message || "Please try again following the instructions or contact support",
          isError: true,
          isSuccess: false,
        },
      };
    }
    case `${UPLOAD_HUMATAR_SERVER}_SUCCESS`: {
      return {
        ...state,
        [HumatarModules.uploadHumatar]: {
          ...state[HumatarModules.uploadHumatar],
          errorMessage: "",
          isSuccess: true,
          isError: false,
        },
      };
    }

    case UPLOAD_HUMATAR_SERVER: {
      return {
        ...state,
        [HumatarModules.uploadHumatar]: {
          ...state[HumatarModules.uploadHumatar],
          errorMessage: "",
          isError: false,
          filename: action.payload.filename,
        },
      };
    }
    case GET_HUMATARS_SERVER: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          hasMore: true,
        },
      };
    }
    case CLEAR_HUMATARS: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          data: [],
          pageNumber: 0,
        },
      };
    }
    case CLEAR_SEARCH: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          search: "",
        },
      };
    }
    case `${BOOKMARK_HUMATAR_SERVER}_SUCCESS`: {
      const { id, action: isBookmarked } = action.meta.previousAction.payload;
      const humatars = state[HumatarModules.humatarList].data;
      const newIsBoormarkedValue = humatars.map((humatar) =>
        humatar.aiHumanActorId === id ? { ...humatar, isBookmarked } : humatar,
      );

      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          data: newIsBoormarkedValue,
        },
      };
    }
    case UPDATE_HUMATAR_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as HumatarModules.humatarList], isLoading } };
    }
    case UPDATE_HAS_MORE_HUMATARS: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          hasMore: action.payload.hasMore,
        },
      };
    }
    case `${GET_HUMATARS_SERVER}_SUCCESS`: {
      const { pageNumber, data } = action.payload.data;

      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          data: pageNumber === 1 ? data : [...state[HumatarModules.humatarList].data, ...data],
          search: action.meta.previousAction.payload.request.data.keyword,
          pageNumber: state[HumatarModules.humatarList].pageNumber + 1,
        },
      };
    }
    case SET_PAGE_HUMATARS: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          pageNumber: action.payload.pageNumber,
        },
      };
    }
    case SEARCH_HUMATARS_RESULT_LOADING: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          searchLoading: action.payload,
        },
      };
    }
    case FILTER_HUMATAR_LOADING: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          filterLoading: action.payload,
        },
      };
    }
    case `${GET_HUMATAR_PREVIEW_SERVER}_SUCCESS`: {
      return {
        ...state,
        [HumatarModules.humatarPreview]: {
          ...state[HumatarModules.humatarPreview],
          data: [...state[HumatarModules.humatarPreview].data, ...action.payload.data.data],
        },
      };
    }
    case DELETE_CUSTOM_HUMATAR_SERVER: {
      const { id } = action.payload;
      const data = state[HumatarModules.humatarList].data.filter((value) => value.aiHumanActorId !== id);

      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          data,
          isDeleting: true,
        },
      };
    }
    case `${DELETE_CUSTOM_HUMATAR_SERVER}_FAIL`:
    case `${DELETE_CUSTOM_HUMATAR_SERVER}_SUCCESS`: {
      return {
        ...state,
        [HumatarModules.humatarList]: {
          ...state[HumatarModules.humatarList],
          isDeleting: false,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getHumatarList = (state: StoreType) => state.humatar[HumatarModules.humatarList].data;
export const getHumatarListLoading = (state: StoreType) => state.humatar[HumatarModules.humatarList].isLoading;

export const getHasMoreHumatars = (state: StoreType) => state.humatar[HumatarModules.humatarList].hasMore;
export const getCurrentPageHumatars = (state: StoreType) => state.humatar[HumatarModules.humatarList].pageNumber;

export const searchResultLoading = (state: StoreType) => state.humatar[HumatarModules.humatarList].searchLoading;
export const filterResultLoading = (state: StoreType) => state.humatar[HumatarModules.humatarList].filterLoading;

export const getHumatarPreview = (state: StoreType) => state.humatar[HumatarModules.humatarPreview].data;
export const getHumatarPreviewLoading = (state: StoreType) => state.humatar[HumatarModules.humatarPreview].isLoading;

export const uploadingHumatarLoading = (state: StoreType) => state.humatar[HumatarModules.uploadHumatar].isLoading;
export const getCustomHumatar = (state: StoreType) => state.humatar[HumatarModules.uploadHumatar];
export const getCustomHumatarDeleting = (state: StoreType) => state.humatar[HumatarModules.humatarList].isDeleting;

export default humatarReducer;
