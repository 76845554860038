import React from "react";

const CloseIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09977 8.00014L12.4387 4.66121C12.7428 4.3571 12.7428 3.86556 12.4387 3.56145C12.1346 3.25735 11.6431 3.25735 11.3389 3.56145L8.00002 6.90038L4.66109 3.56145C4.35698 3.25735 3.86544 3.25735 3.56133 3.56145C3.25723 3.86556 3.25723 4.3571 3.56133 4.66121L6.90026 8.00014L3.56133 11.3391C3.25723 11.6432 3.25723 12.1347 3.56133 12.4388C3.71299 12.5905 3.9121 12.6667 4.11121 12.6667C4.31031 12.6667 4.50942 12.5905 4.66109 12.4388L8.00002 9.09989L11.3389 12.4388C11.4906 12.5905 11.6897 12.6667 11.8888 12.6667C12.0879 12.6667 12.287 12.5905 12.4387 12.4388C12.7428 12.1347 12.7428 11.6432 12.4387 11.3391L9.09977 8.00014Z"
      fill="#191B1F"
    />
  </svg>
);

export default CloseIcon;
