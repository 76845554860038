export interface Filter {
  categoryType: string[];
  voiceAge: string[];
  isFeMale: string[];
  mood: string[];
  content: string[];
  region: string[];
  country: string[];
  language: string[];
  category: string[];
}

export enum CategoryProps {
  CATEGORY_TYPE = "categoryType",
  VOICE_AGE = "voiceAge",
  IS_FEMALE = "isFeMale",
  MOOD = "mood",
  CONTENT = "content",
  REGION = "region",
  LANGUAGE = "language",
  COUNTRY = "country",
  CATEGORY = "category",
}

export interface Category {
  id: string;
  type: CategoryProps;
  title: string;
  data: {
    id: string;
    value?: string;
    label: string;
  }[];
}

export interface IChip {
  key: CategoryProps;
  item: string;
}
