export const BasePageLoader = () => (
  <svg id="7517:6467" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g transform="matrix(1,0,0,1,0,0)">
      <g id="7517:6467" opacity="1" style={{ mixBlendMode: "normal" }}>
        <g>
          <g>
            <g transform="matrix(1,0,0,1,2,6)">
              <g id="7517:6469" opacity="0" style={{ mixBlendMode: "normal" }}>
                <g>
                  <g>
                    <g style={{ mixBlendMode: "normal" }}>
                      <defs>
                        <linearGradient
                          id="lsd7vrie2998207502601755"
                          x1="37.99999776462095"
                          y1="30.000027033465123"
                          x2="1.9999990463222095"
                          y2="2.000002600637003"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop offset="0%" stopOpacity="1" stopColor="rgb(1, 154, 247)"></stop>
                          <stop offset="100%" stopOpacity="1" stopColor="rgb(1, 99, 180)"></stop>
                        </linearGradient>
                      </defs>
                      <g>
                        <path
                          id="7517:6469_stroke_path"
                          d="M40,13.99998h-3c-0.55228,0 -1,0.44772 -1,1v7.23999c0,1.10457 -0.89543,2 -2,2c-1.10457,0 -2,-0.89543 -2,-2v-20c0,-1.10457 -0.89543,-2 -2,-2c-1.10457,0 -2,0.89543 -2,2v28c0,1.10457 -0.89543,2 -2,2c-1.10457,0 -2,-0.89543 -2,-2v-25.00002c0,-1.10457 -0.89543,-2 -2,-2c-1.10457,0 -2,0.89543 -2,2v13.99996c0,1.10457 -0.89543,2 -2,2c-1.10457,0 -2,-0.89543 -2,-2v-17.22949c0,-1.11032 -0.90009,-2.01042 -2.01041,-2.01043c-1.11096,-0.00001 -2.01133,0.90107 -2.01045,2.01203l0.01927,24.23252c0.00088,1.10177 -0.89204,1.9954 -1.99381,1.9954c-1.10536,0 -1.99975,-0.89923 -1.99378,-2.00457l0.08618,-15.97004c0.00613,-1.13567 -0.9128,-2.05957 -2.04848,-2.05957c-1.13136,0 -2.04851,0.91715 -2.04851,2.04851v2.72883c0,0.55229 -0.44772,1 -1,1h-3"
                          fill="none"
                          stroke="url(#lsd7vrie2998207502601755)"
                          strokeWidth="1.465"
                          strokeLinecap="round"
                          strokeLinejoin="miter"
                          strokeDasharray="0 0"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <animate
      href="#7517:6469_stroke_path"
      attributeName="stroke-dasharray"
      values="0 216.1464984841456;25.87337788785466 190.27312059629097;74.36723586459466 141.77926261955096;150.38232127208823 65.76417721205738;187.53383823926416 28.612660244881464;203.45591693948242 12.690581544663207;184.96576102955154 31.180737454594087;139.33958927032398 76.80690921382164;63.62411287063019 152.52238561351544;26.47259590345427 189.67390258069133;10.550517203236017 205.5959812809096;3.167295225312233 212.97920325883337;0.2996090077998058 215.8468894763458;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456;0 216.1464984841456"
      dur="5s"
      repeatCount="indefinite"
      keyTimes="0;0.04;0.08;0.12;0.16;0.2;0.24;0.28;0.32;0.36;0.4;0.44;0.48;0.52;0.56;0.6;0.64;0.68;0.72;0.76;0.8;0.84;0.88;0.92;0.96;1"
      fill="freeze"
    ></animate>
    <animate
      href="#7517:6469_stroke_path"
      attributeName="stroke-dashoffset"
      values="-214.00643414271843;-188.13305625486376;-139.63919827812376;-63.62411287063019;-26.47259590345427;-10.550517203236017;-3.167295225312233;-0.2996090077998058;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0"
      dur="5s"
      repeatCount="indefinite"
      keyTimes="0;0.04;0.08;0.12;0.16;0.2;0.24;0.28;0.32;0.36;0.4;0.44;0.48;0.52;0.56;0.6;0.64;0.68;0.72;0.76;0.8;0.84;0.88;0.92;0.96;1"
      fill="freeze"
    ></animate>
    <animate
      href="#7517:6469"
      attributeName="opacity"
      values="0;1;0;0"
      dur="5s"
      repeatCount="indefinite"
      calcMode="spline"
      keyTimes="0;0.25;0.5;1"
      keySplines="0.5 0.35 0.15 1;0.5 0.35 0.15 1;0.5 0.35 0.15 1"
      additive="replace"
      fill="freeze"
    ></animate>
  </svg>
);
