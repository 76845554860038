import styled from "styled-components";
import { useDispatch } from "react-redux";

import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { GalleryVideo } from "../../types/image";
import { downloadImage } from "../../lib/downloadImage";
import { PopupUploadIcon, TrashIcon } from "../Icons/Icons";
import { EnlargedPicture } from "../../types/enlargedPicture";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import { Text2Video } from "../../types/text2Video";

const menu = [
  { name: "Download", icon: <PopupUploadIcon /> },
  { name: "Delete", icon: <TrashIcon /> },
];

interface Props {
  video: GalleryVideo;
}

const GalleryVideoCard = ({ video }: Props) => {
  const dispatch = useDispatch();

  const handleVideoClick = (video: GalleryVideo) => {
    const prefilled: Text2Video = {
      outputUrl: video.path,
      fileName: video.fileName,
      isView: true,
    };
    dispatch(updatePopup({ popup: Popups.text2VideoPopup, status: true, prefilled }));
  };

  const handleImageDelete = (e: any) => {
    if (!video.videoGalleryID) {
      throw Error("The ID video is missing when deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: video.videoGalleryID,
      title: "Are you sure you want to delete video?",
      description: "The video will be removed and the action cannot be undone",
      typeId: 0,
      type: DeleteType.GalleryVideo,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <ImgWrapper onClick={() => handleVideoClick(video)}>
      <StyledVideo src={video.path} />
      <ActionWrapper>
        {menu.map((m, order) => (
          <IconButton
            key={order}
            iconButtonTheme={IconButtonThemes.Primary}
            icon={m.icon}
            onClick={(e: any) => {
              e.preventDefault();
              m.name === "Download" && downloadImage(e, video.path);
              m.name === "Delete" && handleImageDelete(e);
            }}
          />
        ))}
      </ActionWrapper>
    </ImgWrapper>
  );
};

const ImgWrapper = styled.div`
  cursor: pointer;
  width: 228px;
  height: 160px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    box-shadow: ${({ theme }) => theme.cardShadow};
    pointer-events: none;
  }
`;

const StyledVideo = styled.video`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 16px;

  button {
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: none;
    border-radius: 6px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  svg path {
    fill: none;
    stroke: ${({ theme }) => theme.white};
  }

  button:first-of-type svg path,
  button:last-of-type svg path {
    fill: ${({ theme }) => theme.white};
    stroke: none;
  }

  .menu-wrapper {
    background: ${({ theme }) => theme.primaryBackground};
  }
`;

export default GalleryVideoCard;
