import styled from "styled-components";
import { ReactNode } from "react";

import Button from "../../../components/Button/Button";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { AIVoicesMainTabs, AIVoicesMainTabsLabels } from "../../../hooks/useAIVoices";

interface Tab {
  value: AIVoicesMainTabs;
  label: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
}

interface Props {
  disabled?: boolean;
  data: Tab[];
  active: string;
  handleActive: (tabId: AIVoicesMainTabs | string) => void;
}

const Tabs = ({ data, active, handleActive, disabled }: Props) => {
  return (
    <Wrapper>
      {data.map((tab: Tab, order: number) => (
        <ButtonWrapper key={order}>
          <Button
            key={order}
            text={tab.label}
            className={tab.value === active ? "active" : "not-active"}
            disabled={tab.disabled || disabled}
            onClick={() => handleActive(tab.value)}
            endAdornment={tab.endAdornment}
            icon={tab.startAdornment}
          />
          {tab.endAdornment && (
            <Tooltip
              text={"Coming Soon"}
              position="left"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", right: "30px", top: "20px" }}
              arrow
            />
          )}
        </ButtonWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ disabled?: boolean }>`
  padding: 4px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.themeSwitcherShadow};
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 30px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}

  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 0 15px;
  }

  button {
    border-radius: 52px;
    height: 30px;
    min-width: 88px;

    span {
      font-size: 14px;
      line-height: 24px;
    }

    &.not-active {
      background: transparent;
      box-shadow: none;
      opacity: 0.4;

      span {
        font-weight: 500;
        color: ${({ theme }) => theme.primaryText};
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  border: none !important;
  width: 100%;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

export default Tabs;
