export const FailureIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="failure-icon"
  >
    <path d="M7 5.25V8.16667" stroke="#E33A15" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7 12.4896H3.465C1.44083 12.4896 0.595001 11.0429 1.575 9.2754L3.395 5.99707L5.11 2.91707C6.14834 1.04457 7.85167 1.04457 8.89 2.91707L10.605 6.0029L12.425 9.28124C13.405 11.0487 12.5533 12.4954 10.535 12.4954H7V12.4896Z"
      stroke="#E33A15"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99658 9.91699H7.00182"
      stroke="#E33A15"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
