import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Video from "./components/Video";
import Settings from "./components/Settings";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import Navigation from "../../components/Navigation/Navigation";
import Button, { ButtonThemes } from "../../components/Button/Button";
import CircularProgress from "../../components/Icons/CircularProgress";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { getProjectServer } from "../../redux/actions/humansProjectActions";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import { getHumansProject, getLoadingPage } from "../../redux/reducers/humansProjectReducer";

const VideoPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const humansProject = useSelector(getHumansProject);
  const loadingPage = useSelector(getLoadingPage);

  const handleDeleteClick = (e: any) => {
    if (!humansProject?.projectId) {
      throw Error("Project entity is missing while deleting");
    }

    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: humansProject.projectId,
      title: "Are you sure you want to delete project " + humansProject.title,
      description: "The project will be removed and the action cannot be undone",
      typeId: 2,
      type: DeleteType.Project,
      redirectTo: true,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getProjectServer(parseInt(id)));
    } else {
      navigate("/my-projects?filter=ai-humans");
    }
  }, [id]);

  useEffect(() => {
    if (!isFirstRender.current && id && !humansProject) {
      navigate("/my-projects?filter=ai-humans");
    }

    isFirstRender.current = false;
  }, [humansProject, id]);

  if (!humansProject) return null;

  return loadingPage ? (
    <CircularProgressWrapper>
      <CircularProgress color="#009af7" />
    </CircularProgressWrapper>
  ) : (
    <Wrapper>
      <Navigation
        startAdornment={
          <Link to="/my-projects?filter=ai-humans">
            <Button text="Back" buttonTheme={ButtonThemes.Secondary} icon={<img src="/images/arrow-left.svg" />} />
          </Link>
        }
      />
      <PageTitle>{humansProject?.title}</PageTitle>
      <Content>
        <Video
          src={humansProject?.output}
          date={format(parseISO(humansProject?.completeDateTime), "dd/MM/yyyy hh:mm:ss")}
        />
        <Settings handleDeleteClick={handleDeleteClick} />
      </Content>
    </Wrapper>
  );
};

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  min-height: 100vh;
  padding: 24px 40px 30px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  font-family: Mulish;
`;

const PageTitle = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.96px;
`;

const Content = styled.div`
  display: flex;
  column-gap: 24px;

  @media (max-width: 1001px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

export default withPrivateRoute(VideoPage);
