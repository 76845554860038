import "draft-js/dist/Draft.css";
import "./Editor.css";

import React, { useCallback, useEffect, useRef } from "react";
import { convertFromRaw, convertToRaw, DraftInlineStyleType, Editor, EditorState, SelectionState } from "draft-js";
import { setInlineStyle } from "../../lib/setInlineStyle";
import { toast } from "react-toastify";
import { throttle } from "lodash";

import styled from "styled-components";

export class Block {
  data: any;
  depth: number;
  entityRanges: any;
  inlineStyleRanges: any;
  key: any;
  text: any;
  type: any;

  constructor({ key, text, inlineStyles }: any) {
    this.data = {};
    this.depth = 0;
    this.entityRanges = [];
    this.inlineStyleRanges = inlineStyles;
    this.key = key;
    this.text = text;
    this.type = "unstyled";
  }
}

export const styleGenerator: any = {};

function RichEditorExample({
  index,
  setFeatureActive,
  editorContent,
  setEditorContent,
  handleTextParagraph,
  lastSel,
  setLastSel,
  addParagraph,
  paragraphs,
  styleMap,
  textLength,
  limitation,
  defaultPause,
  placeholder,
}: any) {
  const editorRef = useRef<Editor | null>(null);
  const wrapperRef = useRef(null);

  // useEffect(() => {
  //   if (editorContent.getSelection().getHasFocus()) {
  //     setLastSel(editorContent.getSelection());
  //   }
  // }, [editorContent.getSelection().getHasFocus()]);

  // useEffect(() => {
  //   let content = convertToRaw(editorContent.getCurrentContent());
  //   const key = content.blocks[content.blocks.length - 1].key;
  //   content = setInlineStyle(content, key, { isActive: "active" });
  //   setEditorContent(EditorState.createWithContent(convertFromRaw(content)));
  // }, []);

  const debouncedToast = useCallback(
    throttle(() => {
      toast.error("For optimal results sentence can’t be more than 200 characters.");
    }, 1000),
    [],
  );

  const splitByTimer = (blocks: any[], key: any): any[] => {
    const index = blocks.findIndex((block) => block.key === key);
    const joinedBlock = {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [] as any[],
      key: "",
      text: "",
      type: "unstyled",
    };

    joinedBlock.text = blocks[index - 1].text + blocks[index + 1].text;
    joinedBlock.key = blocks[index + 1].key;

    const finalStyles: Record<string, string> = {};
    blocks[index + 1].inlineStyleRanges.forEach((inline: any) => {
      const [key, value]: [string, string] = inline.style.split("_");

      finalStyles[key] = value;
    });

    blocks[index - 1].inlineStyleRanges.forEach((inline: any) => {
      const [key, value]: [string, string] = inline.style.split("_");

      finalStyles[key] = value;
    });

    joinedBlock.inlineStyleRanges = Object.entries(finalStyles).map((style) => ({
      offset: 0,
      length: joinedBlock.text.length,
      style: `${style[0]}_${style[1]}`,
    }));

    const result = [...blocks.slice(0, index - 1), joinedBlock, ...blocks.slice(index + 2, blocks.length)];
    const emptySel = SelectionState.createEmpty(joinedBlock.key);
    const generatedSel = emptySel.merge({
      focusOffset: blocks[index - 1].text.length,
      anchorOffset: blocks[index - 1].text.length,
    });

    return [result, generatedSel];
  };

  const handleEditorChange = (state: any) => {
    let content: any = convertToRaw(state.getCurrentContent());

    let blocks = content.blocks;
    let selection: any = state.getSelection();
    const selectionKey = state.getSelection().getAnchorKey();
    const element = content.blocks.find((element: { key: any }) => element.key === selectionKey);

    const activeFeatures = blocks.findIndex((block: any) => block.key === selectionKey);
    setFeatureActive(activeFeatures);

    content.blocks = blocks.map((block: { inlineStyleRanges: any[] }) => ({
      ...block,
      inlineStyleRanges: block.inlineStyleRanges.filter((inline) => inline.style !== "isActive_active"),
    }));

    const prev = editorContent.getCurrentContent();
    const next = state.getCurrentContent();
    let notActive = false;
    const prevHigher = JSON.stringify(prev).length > JSON.stringify(next).length;
    const nextHigher = JSON.stringify(prev).length < JSON.stringify(next).length;

    if (textLength >= limitation && nextHigher) {
      toast.error(
        `Please make sure your project doesn’t exceed the maximum amount of ${limitation} characters, this is done to achieve high quality and rendering speed of the output`,
      );
      const preventedState = EditorState.forceSelection(
        EditorState.createWithContent(editorContent.getCurrentContent()),
        editorContent.getSelection(),
      );
      setEditorContent(preventedState);
      return;
    }

    if (nextHigher && element.text.length > 200) {
      setEditorContent(EditorState.forceSelection(editorContent, editorContent.getSelection()));
      setLastSel(editorContent.getSelection());
      debouncedToast();
      return;
    }

    if (nextHigher && !blocks[blocks.length - 1].text && !blocks[blocks.length - 2].text) {
      setEditorContent(editorContent);
      if (index === paragraphs?.length - 1) {
        addParagraph?.(paragraphs.length + 1);
      }
      return;
    }

    if (
      (prevHigher && element.inlineStyleRanges.length && element.inlineStyleRanges[0].style === "pause") ||
      (JSON.stringify(prev) === JSON.stringify(next) &&
        element.inlineStyleRanges.length &&
        element.inlineStyleRanges[0].style === "pause") ||
      (!editorContent.getSelection()?.getHasFocus() && state.getSelection()?.getHasFocus())
    ) {
      notActive = true;
    }
    let content2 = convertToRaw(editorContent.getCurrentContent());

    if (prevHigher && element.inlineStyleRanges.length && element.inlineStyleRanges[0].style === "pause") {
      const [newBlocks, newSel] = splitByTimer(content2.blocks, selectionKey);
      content.blocks = newBlocks;
      selection = newSel;
    }

    if (!notActive) {
      content = setInlineStyle(content, selectionKey, { isActive: "active" });
      content.blocks = content.blocks.reduce((acc: any, item: any, index: any, inital: any) => {
        acc.push(item);
        const next = inital[index + 1];
        if (
          !next ||
          (next && next.inlineStyleRanges.find((inline: { style: string }) => inline.style === "pause")) ||
          item.inlineStyleRanges.find((inline: { style: string }) => inline.style === "pause")
        ) {
          return acc;
        }

        acc.push({
          key: Math.random().toFixed(3),
          text: `${defaultPause}s`,
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [{ offset: 0, length: 4, style: "pause" }],
          data: {},
          entityRanges: [],
        });
        return acc;
      }, []);
    }

    let newEditorState;

    if (state.getSelection().getHasFocus()) {
      setLastSel(selection);
      newEditorState = EditorState.forceSelection(EditorState.createWithContent(convertFromRaw(content)), selection);
    } else {
      content.blocks = blocks.map((block: { inlineStyleRanges: any[] }) => ({
        ...block,
        inlineStyleRanges: block.inlineStyleRanges.filter((inline) => inline.style !== "isActive_active"),
      }));

      newEditorState = EditorState.createWithContent(convertFromRaw(content));
    }
    setEditorContent(newEditorState);
  };

  const stateToData = (state: any) => {
    let content = convertToRaw(state.getCurrentContent());
    let blocks = content.blocks;
    const resultData = [];

    for (let i = 0; i < blocks.length; i += 2) {
      blocks[i].inlineStyleRanges = blocks[i].inlineStyleRanges.filter(
        (inline: { style: string | string[] }) => !inline.style.includes("ACTIVE"),
      );

      resultData.push({
        text: blocks[i].text,
        features: [
          {
            key: "pause",
            value: i >= blocks.length - 1 ? "" : blocks[i + 1].text.replace("s", ""),
          },
          ...blocks[i].inlineStyleRanges
            .filter(
              (inline) =>
                inline.style !== ("isLoading_loading" as DraftInlineStyleType) &&
                inline.style !== ("isGenerated_generated" as DraftInlineStyleType),
            )
            .map((inline: any) => {
              let [key, value] = inline.style.split("_");
              if (key === "speed") value = value.replace("X", "");
              return { key, value };
            }),
        ],
      });
    }
    const filteredData = resultData.map((result) => ({
      ...result,
      features: result.features.filter(({ key, value }) => value !== ""),
    }));
    return filteredData;
  };

  useEffect(() => {
    handleTextParagraph(stateToData(editorContent));
  }, [editorContent]);

  return (
    <>
      <RichEditor className={"RichEditorContainer"}>
        <div ref={wrapperRef} className={"RichEditor-editor"} style={{ forcedColorAdjust: "none" }}>
          <Editor
            ref={editorRef}
            customStyleMap={styleMap}
            editorState={editorContent}
            placeholder={placeholder}
            onChange={handleEditorChange}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            formatPastedText={(text: string) => {
              const signs = text
                .split("")
                .filter((letter, index, textInital) => letter.match(/[.!?:;]/) && textInital[index - 1] !== letter);
              const createdBlocks = text
                .split(/[.!?:;]/)
                .filter((word) => !!word && word !== "\n")
                .reduce((acc: any, text, index, inital) => {
                  acc.push(
                    new Block({
                      key: Math.random().toString(16).slice(2).slice(0, 5),
                      text: text.trim() + (signs[index] ? signs[index] : ""),
                      inlineStyles: [],
                    }),
                  );

                  if (inital[index + 1]) {
                    const timerText = `${defaultPause}s`;
                    acc.push(
                      new Block({
                        key: Math.random().toString(16).slice(2).slice(0, 5),
                        text: timerText,
                        inlineStyles: [{ offset: 0, length: timerText.length, style: "pause" }],
                      }),
                    );
                  }

                  return acc;
                }, []);

              let { blocks } = convertToRaw(editorContent.getCurrentContent());
              const selectedKey = editorContent.getSelection().getAnchorKey();
              const index = blocks.findIndex((element) => element.key === selectedKey);
              const timerText = `${defaultPause}s`;
              let prevBlocks = blocks.slice(0, index + 1);

              if (prevBlocks.length > 1 || (prevBlocks[0] && prevBlocks[0].text.trim())) {
                prevBlocks.push(
                  new Block({
                    key: Math.random().toString(16).slice(2).slice(0, 5),
                    text: timerText,
                    inlineStyles: [{ offset: 0, length: timerText.length, style: "pause" }],
                  }),
                );
              } else {
                prevBlocks = [];
              }
              const nextBlocks = blocks.slice(index + 1, blocks.length);

              const finalBlocks = [...prevBlocks, ...createdBlocks, ...nextBlocks];
              const finalSel = lastSel
                .set("anchorKey", createdBlocks[createdBlocks.length - 1].key)
                .set("anchorOffset", createdBlocks[createdBlocks.length - 1].text.length);

              if (textLength + text.length >= limitation) {
                toast.error(
                  `Please make sure your project doesn’t exceed the maximum amount of ${limitation} characters, this is done to achieve high quality and rendering speed of the output`,
                );
                const preventedState = EditorState.forceSelection(
                  EditorState.createWithContent(editorContent.getCurrentContent()),
                  editorContent.getSelection(),
                );
                setEditorContent(preventedState);
                return;
              }

              setEditorContent(
                EditorState.forceSelection(
                  EditorState.createWithContent(convertFromRaw({ blocks: finalBlocks, entityMap: {} })),
                  finalSel,
                ),
              );

              return undefined;
            }}
            onCut={(_, e) => {
              e.preventDefault();
              return false;
            }}
          />
        </div>
      </RichEditor>
    </>
  );
}

const RichEditor = styled.div`
  .RichEditor-editor
    .DraftEditor-root
    .DraftEditor-editorContainer
    .public-DraftEditor-content
    div
    div:nth-child(odd)
    div
    > span {
    color: ${({ theme }) => theme.primaryText};

    @media (forced-colors: active) and (prefers-color-scheme: dark) {
      color: ${({ theme }) => theme.secondaryText};
    }
  }
`;

export default RichEditorExample;
