import { SumoModules } from "../../types/sumo";

export const SET_NEW_PLAN_SERVER = "SET_NEW_PLAN_SERVER";
export const UPDATE_SUMO_LOADING = "UPDATE_SUMO_LOADING";
export const GET_ACTIVE_PLAN_SERVER = "GET_ACTIVE_PLAN_SERVER";
export const SET_ACTIVE_PLAN = "SET_ACTIVE_PLAN";

interface UpdateSumoLoadingProps {
  module: SumoModules;
  isLoading: boolean;
}

export const updateSumoLoading = ({ module, isLoading }: UpdateSumoLoadingProps) => ({
  type: UPDATE_SUMO_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const setNewPlanServer = () => ({
  type: SET_NEW_PLAN_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/appsumo/getAppSumoLink`,
    },
  },
});

export const getActiveSumoPlanServer = () => ({
  type: GET_ACTIVE_PLAN_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/appsumo/getActivePlan`,
    },
  },
});

export const setActivePlan = (plan: string) => ({
  type: SET_ACTIVE_PLAN,
  payload: plan,
});
