import styled from "styled-components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CircularProgress from "../../../../../../components/Icons/CircularProgress";
import CharacterCard from "../../../../../../components/CharacterCard/CharacterCard";
import { IActor } from "../../../../../../types/actor";
import { getFullImageUrl } from "../../../../../../lib/getFullImageUrl";
import { bookmarkActorServer } from "../../../../../../redux/actions/actorActions";

const MAX_LENGTH = 5;

interface Props {
  data: IActor[];
  loading: boolean;
}
const AIVoicesCard = ({ data, loading }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePlaying, setActivePlaying] = useState<number>();

  const handleActivePlaying = (id: number) => {
    setActivePlaying(id);
  };

  const desiredVoiceNames = ["norman", "ian_synthesys", "vanessa", "en-US-GuyNeural", "fiona_synthesys"];
  const filteredData: IActor[] = [];
  const seenVoiceNames = new Set<string>();

  data.forEach((actor) => {
    if (desiredVoiceNames.includes(actor.voiceName ?? "") && !seenVoiceNames.has(actor.voiceName ?? "")) {
      filteredData.push(actor);
      seenVoiceNames.add(actor.voiceName ?? "");
    }
  });
  const handleBookmarked = (e: any, id: number) => {
    e.stopPropagation();

    const isBookmarked = data?.find((actor) => actor.actorId === id)?.isBookmarked;

    if (isBookmarked) dispatch(bookmarkActorServer({ id, action: false }));
    else dispatch(bookmarkActorServer({ id, action: true }));
  };

  return (
    <OuterWrapper isLoading={loading}>
      {loading ? (
        <CircularProgress color="#009af7" />
      ) : (
        filteredData
          .slice(0, MAX_LENGTH)
          .map((actor) => (
            <CharacterCard
              actor={actor}
              key={actor.actorId}
              flag={actor.flagPath ? getFullImageUrl(actor.flagPath) : "/images/flag.png"}
              languageName={actor.languageName?.split(" ")[0]}
              photo={actor.photo}
              name={actor.name}
              userId={0}
              isBookmarked={actor.isBookmarked}
              audioSampleLink={actor.audioSampleLink}
              activePlaying={actor.actorId === activePlaying}
              handleActive={() => navigate(`/actors?actor=${actor.name}`)}
              handleActivePlaying={() => handleActivePlaying(actor.actorId)}
              handleBookmarked={(e: any) => handleBookmarked(e, actor.actorId)}
              handleCloneVoiceDelete={() => null}
            />
          ))
      )}
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-height: auto;

  & > div {
    max-height: 180px;

    & > div {
      background: ${({ theme }) => theme.voiceCardBackground};
      box-shadow: ${({ theme }) => theme.voiceCardShadow};

      &:hover {
        background: ${({ theme }) => theme.hoverVoiceCardBackground};

        h3 {
          color: white;
        }
        & > :nth-child(1) {
          &>: nth-child(1) {
            svg {
              path {
                fill: white;
              }
            }
          }
        }
      }
      & > :nth-child(4) {
        & > div > button {
          box-shadow: none;
          background-color: #0063b4;
          svg {
            width: 11px;
            height: 11px;
          }
        }
        & > :nth-child(2) {
          background: white;
          box-shadow: ${({ theme }) => theme.voiceProgressbarShadow};
        }
      }
    }
  }

  ${({ isLoading }) =>
    isLoading
      ? `
      align-items: center;
      justify-content: center;
    
    `
      : `
      & > div {
 
        width: 100%;
        min-width: 18%;
        flex:1;

        &:last-child {
          margin: 0;
        }

       
  }
      }
    `}

  @media (max-width: 1100px) {
    & > div {
      min-width: 130px;
    }
  }

  @media (max-width: 850px) {
    & > div {
      min-width: 150px;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
  }
`;

export default AIVoicesCard;
