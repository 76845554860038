import { toast } from "react-toastify";
import { put, select, takeEvery } from "redux-saga/effects";
import {
  CREATE_USER_ASSET_SERVER,
  DELETE_USER_ASSET,
  GET_ASSET_SERVER,
  clearAsset,
  getAssetServer,
  searchAssetResultLoading,
  updateAssetLoading,
  updateHasMoreAsset,
} from "../actions/assetAction";
import { AssetModules } from "../../types/asset";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { getAssets } from "../reducers/assetReducer";

const assetSaga = [
  takeEvery(`${GET_ASSET_SERVER}`, handleGetAssetServer),
  takeEvery(`${GET_ASSET_SERVER}_FAIL`, handleGetAssetServerFail),
  takeEvery(`${GET_ASSET_SERVER}_SUCCESS`, handleGetAssetServerSuccess),

  takeEvery(`${CREATE_USER_ASSET_SERVER}_FAIL`, handleCreateUserAssetServerFail),
  takeEvery(`${CREATE_USER_ASSET_SERVER}_SUCCESS`, handleCreateUserAssetServerSuccess),

  takeEvery(`${DELETE_USER_ASSET}_FAIL`, handleDeleteUserAssetServerFail),
  takeEvery(`${DELETE_USER_ASSET}_SUCCESS`, handleDeleteUserAssetServerSuccess),
];

function* handleGetAssetServer(action: any) {
  const { assetTypeId } = action.payload;

  yield put(updateAssetLoading({ assetTypeId, isLoading: true }));
}

function* handleGetAssetServerFail(action: any) {
  const { assetTypeId } = action.meta.previousAction.payload;

  yield toast.error(`Error while getting ${AssetModules[assetTypeId]}s`);
  yield put(updateAssetLoading({ assetTypeId, isLoading: false }));
}

function* handleGetAssetServerSuccess(action: any) {
  const allAssets = getAssets(yield select());
  const totalLength = action.payload.data.totalRecords;

  const { assetTypeId } = action.meta.previousAction.payload;

  if (allAssets[assetTypeId].data.length >= totalLength) yield put(updateHasMoreAsset({ hasMore: false, assetTypeId }));

  yield put(updateAssetLoading({ assetTypeId, isLoading: false }));
  yield put(searchAssetResultLoading(false, assetTypeId));
}

function* handleCreateUserAssetServerFail(action: any) {
  const { assetTypeId } = action.meta.previousAction.payload;

  yield toast.error(`The ${AssetModules[assetTypeId]} could not be uploaded`);
}

function* handleCreateUserAssetServerSuccess(action: any) {
  const { assetTypeId, pageSize } = action.meta.previousAction.payload;

  yield put(clearAsset({ assetTypeId }));
  yield put(getAssetServer({ keyword: "", pageNumber: 1, assetTypeId, pageSize }));

  yield toast.success(`The ${AssetModules[assetTypeId]} is uploaded`);
}

function* handleDeleteUserAssetServerFail(action: any) {
  const { assetTypeId } = action.meta.previousAction.payload;

  yield toast.error(`The ${AssetModules[assetTypeId]} could not be deleted`);
}

function* handleDeleteUserAssetServerSuccess(action: any) {
  const { assetTypeId } = action.meta.previousAction.payload;

  yield toast.success(`The ${AssetModules[assetTypeId]} has been deleted`);
}

export default assetSaga;
