import { UploadUrlInputFields, UploadUrlInputValues } from "./types";
import { Lock } from "../../components/Icons/Lock";

export const initialValuesUploadUrl = {
  [UploadUrlInputFields.url]: "",
} as UploadUrlInputValues;

export const getUploadUrlFields = [
  {
    type: "text",
    id: UploadUrlInputFields.url,
    name: UploadUrlInputFields.url,
  },
];
