import React from "react";
import styled from "styled-components";

import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { FillLockIcon } from "../Icons/LockIcon";
import { LikeActiveIcon, LikeIcon, PlayIcon } from "../Icons/Icons";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { useDispatch } from "react-redux";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import BinIcon from "../Icons/BinIcon";

interface Props {
  name?: string;
  photo?: string;
  active?: boolean;
  isBookmarked?: boolean;
  isDeleteButton?: boolean;
  handleActive?: () => void;
  handleBookmarked?: (e: any) => void;
  handleClick: (e: any) => void;
  handleCustomHumatarDelete: (e: any) => void;
  isLocked: boolean;
}

const HumanCard = ({
  photo,
  name,
  active,
  isBookmarked,
  isDeleteButton,
  handleActive,
  handleBookmarked,
  handleClick,
  handleCustomHumatarDelete,
  isLocked = false,
}: Props) => {
  const dispatch = useDispatch();
  const handleUpgradePopupOpen = () => {
    const prefilled: NotEnoughCredits = {
      title: "Upgrade the plan to use this humatar",
      description: "Get access to additional premium features and Humatars instantly",
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <Wrapper active={active} onClick={isLocked ? handleUpgradePopupOpen : handleActive}>
      <Image src={photo} />
      <LikeIconWrapper onClick={handleBookmarked}>{!isBookmarked ? <LikeIcon /> : <LikeActiveIcon />}</LikeIconWrapper>
      {!isLocked ? (
        <Bottom>
          <HumanInfo>
            <Name>{name}</Name>
          </HumanInfo>
          <Actions>
            <IconButtonWrapper>
              <IconButton iconButtonTheme={IconButtonThemes.Rounded} icon={<PlayIcon />} onClick={handleClick} />
            </IconButtonWrapper>
          </Actions>
        </Bottom>
      ) : (
        <LockWrapper>
          <Circle>
            <FillLockIcon />
          </Circle>
          <span>
            Unlock the humatar by <b>Upgrading the plan</b>
          </span>
        </LockWrapper>
      )}
      {isLocked && <Blur />}
      <div className="delete-button">
        {isDeleteButton && (
          <IconButton
            icon={<BinIcon />}
            iconButtonTheme={IconButtonThemes.Rounded}
            onClick={(e: any) => {
              e.preventDefault();
              handleCustomHumatarDelete(e);
            }}
          />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  margin: 0 8px 8px 0;
  max-width: 216px;
  height: 220px;
  box-shadow: ${({ theme }) => theme.cardShadow};
  border: 2px solid transparent;
  cursor: pointer;

  &:hover > .delete-button > button {
    opacity: 1;
    transition: 0.2s;
  }

  & > .delete-button > button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    box-shadow: none;
    opacity: 0;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: #fff;
      }
    }
  }

  ${({ active, theme }) =>
    active &&
    `
    border: 2px solid ${theme.activeMenu};
  `}

  @media (max-width: 516px) {
    height: 136px;
    width: 100%;
  }
`;

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 1px;
  left: 0;
  object-fit: cover;
  border-radius: 16px;
`;

const LikeIconWrapper = styled.div<{ active?: boolean }>`
  cursor: pointer;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
`;

const Bottom = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  background: ${({ theme }) => theme.humanCardBackground};
  padding: 20px 12px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 16px 16px;
`;

const HumanInfo = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const Name = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
`;

const Actions = styled.div`
  display: flex;
  column-gap: 8px;
`;

const IconButtonWrapper = styled.div`
  button {
    width: 20px;
    height: 20px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 19px;
  pointer-events: none;
  background: rgba(25, 27, 31, 0.68);
`;

const LockWrapper = styled.div`
  position: absolute;
  bottom: 12px;
  left: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;
  pointer-events: none;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${({ theme }) => theme.white};

    b {
      font-weight: 700;
      color: ${({ theme }) => theme.activeMenu};
    }
  }
`;

const Circle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export default HumanCard;
