export const RenameAssetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8.99996 4.33203L11.6666 6.9987M2.66663 13.3322H5.33329L12.3333 6.33223C12.6869 5.97861 12.8856 5.499 12.8856 4.9989C12.8856 4.4988 12.6869 4.01919 12.3333 3.66557C11.9797 3.31194 11.5001 3.11328 11 3.11328C10.4999 3.11328 10.0202 3.31194 9.66663 3.66557L2.66663 10.6656V13.3322Z"
      stroke="#191B1F"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
