import { ActorModules, CharacterPrefilled } from "../../types/actor";
import { Zone } from "../../types/project";

const ACTORS = "ACTORS_";

export const GET_ACTORS = "GET_ACTORS";
export const GET_ACTORS_SERVER = "GET_ACTORS_SERVER";
export const UPDATE_ACTOR_LOADING = "UPDATE_ACTOR_LOADING";

export const UPDATE_HAS_MORE_ACTORS = "UPDATE_HAS_MORE_ACTORS";
export const SET_PAGE_ACTORS = "SET_PAGE_ACTORS";
export const CLEAR_ACTORS = "CLEAR_ACTORS";

export const GENERATE_VOICE_SERVER = "GENERATE_VOICE_SERVER";
export const CLEAR_VOICE = "CLEAR_VOICE";
export const DOWNLOAD_VOICE_SERVER = "DOWNLOAD_VOICE_SERVER";
export const ZIP_VOICE_SERVER = "ZIP_VOICE_SERVER";
export const BOOKMARK_ACTOR_SERVER = "BOOKMARK_ACTOR_SERVER";
export const RESET_DOWNLOAD_LINK = "RESET_DOWNLOAD_LINK";

export const SEARCH_RESULT_LOADING = "SEARCH_RESULT_LOADING";
export const FILTER_ACTOR_LOADING = "FILTER_ACTOR_LOADING";
export const CLEAR_SEARCH = ACTORS + "CLEAR_SEARCH";

export const GET_DOWNLOAD_HISTORY_SERVER = "GET_DOWNLOAD_HISTORY_SERVER";
// export const UPDATE_DOWNLOAD_HISTORY_LOADING = "UPDATE_DOWNLOAD_HISTORY_LOADING";
export const UPDATE_HAS_MORE_DOWNLOAD_HISTORY = "UPDATE_HAS_MORE_DOWNLOAD_HISTORY";
export const SET_PAGE_DOWNLOAD_HISTORY = "SET_PAGE_DOWNLOAD_HISTORY";
export const CLEAR_DOWNLOAD_HISTORY = "CLEAR_DOWNLOAD_HISTORY";

export const FACESWAP_ACTOR_SERVER = "FACESWAP_ACTOR_SERVER";
export const DELETE_CLONE_VOICE_SERVER = "DELETE_CLONE_VOICE_SERVER";

export const AUDIO_TRANSLATE_PROJECT_ADD = ACTORS + "AUDIO_TRANSLATE_PROJECT_ADD";
export const AUDIO_TO_TRANSLATED_TEXT_SERVER = "AUDIO_TO_TRANSLATED_TEXT_SERVER";
export const GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER = "GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER";
export const CLEAR_CURRENT_SPEECH_2_SPEECH_PROJECT = "CLEAR_CURRENT_SPEECH_2_SPEECH_PROJECT";
export const CLEAR_CURRENT_SPEECH_TRANSLATION_PROJECT = "CLEAR_CURRENT_SPEECH_TRANSLATION_PROJECT";

export const GET_VOICEOVER_HISTORY_SERVER = "GET_VOICEOVER_HISTORY_SERVER";
export const VOICEOVER_HISTORY_PAGE_SIZE = 10;

interface UpdateActorLoadingProps {
  module: ActorModules;
  isLoading: boolean;
}

interface GetActorsProps {
  keyword?: string;
  pageNumber?: number;
  categoryType?: string[];
  voiceAge?: string[];
  isFeMale?: boolean | null;
  mood?: string[];
  content?: string[];
  region?: string[];
  country?: string[];
  language?: string[];
  bookmarked?: boolean | null;
  history?: boolean | null;
  popular?: boolean | null;
  isAIHuman?: boolean;
  VideoTranslate?: boolean | null;
  pageSize?: number;
  isVideoTranslationPage?: boolean | null;
  SpeechCategory?: number;
}

interface UpdateHasMoreActorsProps {
  hasMore: boolean;
}
interface SetPageActorsProps {
  pageNumber: number;
}

interface GenerateVoiceProps {
  data: Zone[];
  isAIHuman?: boolean;
}

interface DownloadVoiceProps {
  voices: string[];
  fileName: string;
  projectId: string | number;
}

interface GetDownloadHistoryServerProps {
  pageNumber: number;
  pageSize: number;
  isHistoryPage?: boolean;
}

interface FaceswapActorServerProps {
  target: string;
  source: string;
}

interface DeleteCloneVoiceServerProps {
  id: number;
  previousFill?: CharacterPrefilled;
}

interface BookmarkActorServerProps {
  id: number;
  action: boolean;
  isScreen?: boolean;
  pathname?: string;
  speechCategory?: number;
}

interface AudioToTranslatedTextServerProps {
  language?: string;
  data: FormData;
}

export const updateHasMoreActors = ({ hasMore }: UpdateHasMoreActorsProps) => ({
  type: UPDATE_HAS_MORE_ACTORS,
  payload: {
    hasMore,
  },
});

export const setPageActors = ({ pageNumber }: SetPageActorsProps) => ({
  type: SET_PAGE_ACTORS,
  payload: { pageNumber },
});

export const updateActorLoading = ({ module, isLoading }: UpdateActorLoadingProps) => ({
  type: UPDATE_ACTOR_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const getActors = ({
  keyword,
  pageNumber,
  categoryType,
  voiceAge,
  isFeMale,
  mood,
  content,
  region,
  country,
  language,
  bookmarked,
  history,
  popular,
}: GetActorsProps) => ({
  type: GET_ACTORS,
  payload: {
    pageNumber,
    keyword,
    categoryType,
    voiceAge,
    isFeMale,
    mood,
    content,
    region,
    country,
    language,
    bookmarked,
    history,
    popular,
    pageSize: 16,
  },
});

export const getActorsServer = ({
  keyword,
  pageNumber,
  categoryType,
  voiceAge,
  isFeMale,
  mood,
  content,
  region,
  country,
  language,
  bookmarked,
  history,
  popular,
  isAIHuman,
  VideoTranslate,
  pageSize,
  isVideoTranslationPage,
  SpeechCategory,
}: GetActorsProps) => ({
  type: GET_ACTORS_SERVER,
  payload: {
    pageNumber,
    isVideoTranslationPage,
    request: {
      method: "POST",
      url: "/actor/list",
      data: {
        pageNumber,
        keyword,
        categoryType: categoryType,
        voiceAge,
        isFeMale,
        mood,
        content,
        region,
        country,
        language,
        bookmarked,
        history,
        popular,
        pageSize: pageSize || 16,
        isAIHuman: isAIHuman || false,
        VideoTranslate,
        SpeechCategory,
      },
    },
  },
});

export const clearActors = () => ({
  type: CLEAR_ACTORS,
});

export const clearSearch = (pathname?: string, speechCategory?: number) => ({
  type: CLEAR_SEARCH,
  payload: { pathname, speechCategory },
});

export const generateVoiceServer = ({ data, isAIHuman }: GenerateVoiceProps) => ({
  type: GENERATE_VOICE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/actor/generateVoice",
      data: {
        data,
        isAIHuman: isAIHuman ?? false,
      },
    },
  },
});

export const clearVoice = () => ({
  type: CLEAR_VOICE,
});

export const downloadVoiceServer = ({ voices, fileName, projectId }: DownloadVoiceProps) => ({
  type: DOWNLOAD_VOICE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/actor/downloadVoice?fileName=${fileName}&projectId=${projectId}`,
      data: voices,
    },
  },
});

export const zipVoiceServer = ({ voices, fileName, projectId }: DownloadVoiceProps) => ({
  type: ZIP_VOICE_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/actor/zipVoices?fileName=${fileName}&projectId=${projectId}`,
      data: voices,
    },
  },
});

export const resetDownloadLink = () => ({
  type: RESET_DOWNLOAD_LINK,
});

export const bookmarkActorServer = ({ id, action, isScreen, pathname, speechCategory }: BookmarkActorServerProps) => ({
  type: BOOKMARK_ACTOR_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/actor/bookmarkActor?actorId=${id}&add=${action}`,
    },
    id,
    action,
    isScreen,
    pathname,
    speechCategory,
  },
});

export const searchActorResultLoading = (loading: boolean) => ({
  type: SEARCH_RESULT_LOADING,
  payload: loading,
});

export const filterActorLoading = (loading: boolean) => ({
  type: FILTER_ACTOR_LOADING,
  payload: loading,
});

export const getDownloadHistoryServer = ({ pageNumber, pageSize, isHistoryPage }: GetDownloadHistoryServerProps) => ({
  type: GET_DOWNLOAD_HISTORY_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/actor/downloadHistory",
      data: {
        pageNumber: pageNumber || 1,
        audioOnly: true,
        PageSize: pageSize || 16,
        AIHumanOnly: true,
      },
    },
    isHistoryPage,
  },
});

export const updateHasMoreDownloadHistory = ({ hasMore }: UpdateHasMoreActorsProps) => ({
  type: UPDATE_HAS_MORE_DOWNLOAD_HISTORY,
  payload: {
    hasMore,
  },
});

export const setPageDownloadHistory = ({ pageNumber }: SetPageActorsProps) => ({
  type: SET_PAGE_DOWNLOAD_HISTORY,
  payload: { pageNumber },
});

export const clearDownloadHistory = () => ({
  type: CLEAR_DOWNLOAD_HISTORY,
});

export const faceswapActorServer = ({ target, source }: FaceswapActorServerProps) => ({
  type: FACESWAP_ACTOR_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/actor/faceswap",
      data: { target, source },
    },
  },
});

export const deleteCloneVoiceServer = ({ id, previousFill }: DeleteCloneVoiceServerProps) => ({
  type: DELETE_CLONE_VOICE_SERVER,
  payload: {
    request: {
      method: "PUT",
      url: `/User/DeleteCloneVoice/${id}`,
    },
    id,
    previousFill,
  },
});

export const audioTranslateProjectAddServer = (data: FormData, projectType: number) => ({
  type: AUDIO_TRANSLATE_PROJECT_ADD,
  payload: {
    request: {
      method: "POST",
      url: "/AudioTranslateProject/add",
      data,
    },
    projectType,
    isReload: true,
  },
});

export const getAudioTranslateProjectByIdServer = (id: number, projectType?: number, isReload?: boolean) => ({
  type: GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/AudioTranslateProject/get?Id=${id}`,
    },
    projectType,
    isReload,
  },
});

export const audioToTranslatedTextServer = ({ language, data }: AudioToTranslatedTextServerProps) => ({
  type: AUDIO_TO_TRANSLATED_TEXT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/AudioTranslateProject/audioToTranslatedText${language ? "?lang=" + language : ""}`,
      data,
    },
  },
});

export const getVoiceoverHistoryServer = (pageNumber: number, isReload?: boolean) => ({
  type: GET_VOICEOVER_HISTORY_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/voiceover/history",
      data: {
        pageNumber,
        pageSize: VOICEOVER_HISTORY_PAGE_SIZE,
      },
    },
    isReload: isReload || false,
  },
});

export const clearCurrentSpeech2SpeechProject = () => ({
  type: CLEAR_CURRENT_SPEECH_2_SPEECH_PROJECT,
});

export const clearCurrentSpeechTranslationProject = () => ({
  type: CLEAR_CURRENT_SPEECH_TRANSLATION_PROJECT,
});
