export const PricingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.81835 17.0575C9.11419 17.3516 9.50085 17.4991 9.88835 17.4991C10.2767 17.4991 10.6642 17.3516 10.9592 17.0566L17.0567 10.96C17.3425 10.6741 17.5 10.2941 17.5 9.88914C17.5 9.48414 17.3425 9.10414 17.0567 8.81831L11.7092 3.47081C11.5717 3.33331 11.3909 3.24831 11.1975 3.23081L3.41085 2.50414C3.16335 2.47914 2.92002 2.56831 2.74419 2.74414C2.56835 2.91998 2.48085 3.16414 2.50335 3.41081L3.23002 11.1975C3.24835 11.3908 3.33335 11.5725 3.47085 11.7091L8.81835 17.0575ZM4.86108 10.7445L9.88775 15.772L15.7694 9.89031L10.7427 4.86281L4.25525 4.25781L4.86108 10.7445ZM8.79867 7.03422C9.287 7.52172 9.287 8.31422 8.79867 8.80172C8.31033 9.29005 7.5195 9.29005 7.03117 8.80172C6.54283 8.31422 6.54283 7.52172 7.03117 7.03422C7.5195 6.54589 8.31033 6.54589 8.79867 7.03422Z"
      fill="url(#paint0_linear_0_7497)"
    />
    <mask id="mask0_0_7497" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88835 17.4991C9.50085 17.4991 9.11419 17.3516 8.81835 17.0575L3.47085 11.7091C3.33335 11.5725 3.24835 11.3908 3.23002 11.1975L2.50335 3.41081C2.48085 3.16414 2.56835 2.91998 2.74419 2.74414C2.92002 2.56831 3.16335 2.47914 3.41085 2.50414L11.1975 3.23081C11.3909 3.24831 11.5717 3.33331 11.7092 3.47081L17.0567 8.81831C17.3425 9.10414 17.5 9.48414 17.5 9.88914C17.5 10.2941 17.3425 10.6741 17.0567 10.96L10.9592 17.0566C10.6642 17.3516 10.2767 17.4991 9.88835 17.4991ZM4.86108 10.7445L9.88775 15.772L15.7694 9.89031L10.7427 4.86281L4.25525 4.25781L4.86108 10.7445ZM8.79867 7.03422C9.287 7.52172 9.287 8.31422 8.79867 8.80172C8.31033 9.29005 7.5195 9.29005 7.03117 8.80172C6.54283 8.31422 6.54283 7.52172 7.03117 7.03422C7.5195 6.54589 8.31033 6.54589 8.79867 7.03422Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_0_7497)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_0_7497"
        x1="5.73068"
        y1="4.46143"
        x2="14.6146"
        y2="15.8844"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
