import { useState } from "react";
import { toast } from "react-toastify";

import { stepsOptions } from "../mocks/videoTranslation";
import { TabsType, VideoTranslationContextTypes, VideoTranslationSteps } from "../types/videoTranslationContext";

const MAX_SIZE = 500 * 1024 * 1024;
const MIN_DURATION = 15;
const MAX_DURATION = 60 * 5;

export const useVideoTranslation = (): VideoTranslationContextTypes => {
  const [uploadedVideo, setUploadedVideo] = useState<File | string | null>(null);
  const [uploadedVideoDuration, setUploadedVideoDuration] = useState<number | null>(null);
  const [selectVideoOptionsActiveTabs, setSelectVideoOptionsActiveTabs] = useState<{
    [key: string]: TabsType | null;
  }>({
    translationTypeTab: null,
    translationOptionTab: null,
    chooseActor: null,
    setPositionTab: null,
  });
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [videoPreviewActor, setVideoPreviewActor] = useState<string | null>(null);
  const [recentlyGeneratedVisible, setRecentlyGeneratedVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState<VideoTranslationSteps>(0);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isEnableCaption, setIsEnableCaption] = useState<boolean>(false);

  const [selectLanguage, setSelectLanguage] = useState("");
  const [selectVideoVoice, setSelectVideoVoice] = useState("");

  const [textTranslated, setTextTranslated] = useState<string>("");

  const handleNextStep = () => {
    const nextStepExists = stepsOptions.find((step, index) => index === currentStep);

    if (nextStepExists) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevStep = () => {
    const prevStepExists = stepsOptions.find((step, index) => index === currentStep);

    if (prevStepExists) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const onChangeLanguageSelect = (newValue: any) => {
    setSelectLanguage(newValue);
  };

  const onChangeVideoVoice = (newValue: any) => {
    setSelectVideoVoice(newValue);
  };

  const toggleRecentlyGeneratedVisible = () => {
    setRecentlyGeneratedVisible((prev) => !prev);
  };

  const handleVideoSelect = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    if (uploadedFile && uploadedFile.size > MAX_SIZE) {
      return toast.error("Exceeds maximum file size of 500 MB");
    } else if (!uploadedFile.type.match(/video\/mp4/)) {
      return toast.error(`Invalid file type: ${uploadedFile.type}`);
    } else {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const video = document.createElement("video");

        video.onloadedmetadata = function () {
          const duration = video.duration;
          setUploadedVideoDuration(duration);

          if (duration < MIN_DURATION || duration > MAX_DURATION) {
            return toast.error("Video duration must be between 15 seconds to 5 minutes");
          } else {
            setUploadedVideo(uploadedFile);
          }
        };

        video.src = URL.createObjectURL(uploadedFile);
      };

      fileReader.readAsArrayBuffer(uploadedFile);
    }
  };

  return {
    uploadedVideoDuration,
    setUploadedVideoDuration,
    selectVideoOptionsActiveTabs,
    setSelectVideoOptionsActiveTabs,
    toggleRecentlyGeneratedVisible,
    recentlyGeneratedVisible,
    uploadedVideo,
    setUploadedVideo,
    handleVideoSelect,
    selectLanguage,
    onChangeLanguageSelect,
    currentStep,
    handleNextStep,
    handlePrevStep,
    videoPreview,
    setVideoPreview,
    videoPreviewActor,
    setVideoPreviewActor,
    isModalOpened,
    setIsModalOpened,
    setCurrentStep,
    isEnableCaption,
    setIsEnableCaption,
    selectVideoVoice,
    onChangeVideoVoice,
    textTranslated,
    setTextTranslated,
  };
};
