import { CSSProperties } from "react";
import { Position, ResizableDelta } from "react-rnd";

export interface ResizeDragObj {
  id: number;
  position: Position;
  size: ResizableDelta;
}

export enum ShapeTypes {
  circle = "circle",
  rectangle = "rectangle",
  triangle = "triangle",
  polygon = "polygon",
  star = "star",
  square = "square",
  heart = "heart",
  arrow = "arrow",
  line = "line",
  custom = "custom",
  customSvg = "customSvg",
}

export enum ShapeBorderStyle {
  solid = "solid",
  dashed = "dashed",
}

export interface ShapeBorder {
  borderStyle: ShapeBorderStyle;
  borderWidth: number;
  borderColor: string;
}

export interface Shape extends ResizeDragObj {
  shape: ShapeTypes;
  shapeImageUrl: string;
  background: string;
  shapeId: number;
  opacity: number;
  border: ShapeBorder;
  shapeDetailId: number;
  layerOrder: number;
  svgData: string | null;
}

export enum TextTypes {
  title = "Title",
  subtitle = "SubTitle",
  bodyText = "BodyText",
}

export type TextAlign = "left" | "center" | "right";

export interface Text extends ResizeDragObj {
  text: string;
  style: CSSProperties;
  textType: TextTypes;
  customTextId: number;
  layerOrder: number;
  displayedFontSize: string;
}

export enum ActorPositionTypes {
  Circle = "Circle",
  FullBody = "FullBody",
  VoiceOnly = "VoiceOnly",
}

export interface Avatar extends ResizeDragObj {
  src: string;
  aiHumanActorId: number;
  actorPositionType: ActorPositionTypes;
  background: string;
  layerOrder: number;
  faceImage: string | null;
  allowCircle: boolean | null;
  isSadtalker: boolean | null;
}

export interface Media extends ResizeDragObj {
  src: string;
  layerOrder: number;
  customImageDetailId: number;
}

export enum BackgroundColor {
  green = "green",
  red = "red",
  yellow = "yellow",
  blue = "blue",
  black = "black",
  pink = "pink",
  orange = "orange",
  brown = "brown",
  azure = "azure",
  cyan = "cyan",
  khaki = "khaki",
  navy = "navy",
}

export interface SceneObject {
  type: ObjectTypes;
  object: Shape | Text | Avatar | Media;
}

export interface Scene {
  id: number;
  slideBackgroundColor: string;
  sceneId: number;
  order: number;
  audioPath?: string;
  activeObjectId: number;
  editableTextId: number;
  background: string | BackgroundColor;
  backgroundId: number;
  backgroundAssetId: number;
  objects: SceneObject[];
  transitionEffectId: number;
  startBackgroundVideoTime: number | null;
  endBackgroundVideoTime: number | null;
  initiallyUpdatedScale: boolean;
}

export enum ObjectTypes {
  texts = "texts",
  shapes = "shapes",
  avatars = "avatars",
  media = "media",
}
