export const MailIcon = () => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.555644 2.27298C0.333496 3.09964 0.333496 4.24006 0.333496 6C0.333496 8.49982 0.333496 9.74973 0.970106 10.626C1.1757 10.9089 1.42456 11.1578 1.70755 11.3634C2.58376 12 3.83367 12 6.3335 12H7.66683C10.1666 12 11.4166 12 12.2928 11.3634C12.5758 11.1578 12.8246 10.9089 13.0302 10.626C13.6668 9.74973 13.6668 8.49982 13.6668 6C13.6668 4.23419 13.6668 3.09204 13.4425 2.26473L12.0406 3.66657C10.9524 4.75476 10.0997 5.60755 9.34482 6.18347C8.5722 6.77296 7.84371 7.11929 7.00007 7.11929C6.15644 7.11929 5.42795 6.77296 4.65532 6.18347C3.90049 5.60755 3.0477 4.75475 1.95952 3.66656L0.656066 2.3631L0.555644 2.27298Z"
      fill="url(#paint0_linear_3002_15756)"
    />
    <path
      d="M1.00016 1.33333L1.07273 1.39338L1.34408 1.6369L2.63958 2.9324C3.76069 4.05351 4.5667 4.85802 5.26191 5.38845C5.94557 5.91007 6.46299 6.11929 7.00007 6.11929C7.53715 6.11929 8.05457 5.91007 8.73824 5.38845C9.43344 4.85802 10.2395 4.05351 11.3606 2.93241L12.8992 1.3938L12.9829 1.31053C12.7773 1.02754 12.5758 0.842209 12.2928 0.63661C11.4166 0 10.1666 0 7.66683 0H6.3335C3.83367 0 2.58376 0 1.70755 0.63661C1.42456 0.842209 1.20576 1.05035 1.00016 1.33333Z"
      fill="url(#paint1_linear_3002_15756)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3002_15756"
        x1="3.20529"
        y1="1.56923"
        x2="10.0957"
        y2="11.4126"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3002_15756"
        x1="3.20529"
        y1="1.56923"
        x2="10.0957"
        y2="11.4126"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
