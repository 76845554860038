import React, { Dispatch, FC } from "react";
import styled from "styled-components";
import { ActiveTabs } from "../../../mocks/faqMocks2";
import { ArrowRightIcon } from "../../../components/Icons/ArrowRightIcon";

interface FaqLeftProps {
  activeTab: ActiveTabs;
  setActiveTab: Dispatch<ActiveTabs>;
}

const FaqLeft: FC<FaqLeftProps> = ({ activeTab, setActiveTab }) => {
  const handleClick = (active: ActiveTabs) => {
    setActiveTab(active);
  };

  return (
    <Wrapper>
      <Button active={activeTab === ActiveTabs.cancellation} onClick={() => handleClick(ActiveTabs.cancellation)}>
        <ButtonText>Cancellation</ButtonText>
        <ArrowRightIcon />
      </Button>
      <Button active={activeTab === ActiveTabs.products} onClick={() => handleClick(ActiveTabs.products)}>
        <ButtonText>Products</ButtonText>
        <ArrowRightIcon />
      </Button>
      {/* <Button active={activeTab === ActiveTabs.other} onClick={() => handleClick(ActiveTabs.other)}>
        <ButtonText>Other</ButtonText>
        <ArrowRightIcon />
      </Button> */}
    </Wrapper>
  );
};

const ButtonText = styled.span`
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Button = styled.button<{ active: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 18px 24px;
  background: ${({ active }) => (active ? "linear-gradient(142deg, #0063B4 17%, #009AF7 86%)" : "#f0f0f3")};
  color: ${({ active }) => (active ? "white" : "#6f7074")};
  box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px rgba(174, 174, 192, 0.3), inset -2px -2px 4px rgba(0, 0, 0, 0.1),
    inset 2px 2px 4px #ffffff;
  border-radius: 8px;

  path {
    stroke: ${({ active }) => active && "white"};
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 14px 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  flex: 1;
  max-width: 287px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export default FaqLeft;
