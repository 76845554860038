import { CircleCheckedIcon } from "../components/Icons/CircleCheckedIcon";
import EmptyIcon from "../components/Icons/EmptyIcon";
import { AtsLogoIcon, CocaColaLogoIcon, NvidiaLogoIcon, TataLogoIcon, YahooLogoIcon } from "../components/Icons/Icons";
import { XCircleIcon } from "../components/Icons/XCircleIcon";
import PriceHumans from "../modules/PricingSubscriptions/components/PriceHumans";
import PriceImage from "../modules/PricingSubscriptions/components/PriceImage";
import PriceStudio from "../modules/PricingSubscriptions/components/PriceStudio";
import PriceVoices from "../modules/PricingSubscriptions/components/PriceVoices";
import { PriceSection } from "../types/pricing";

export interface Subscription {
  groupTitle: string;
  duration: string;
  price: number;
  title: string;
  planID: number;
  checkoutLink: string;
}

export interface IPros {
  text: string;
  tooltip?: string;
}

export interface IUserPrice {
  url: string;
  price: number;
  id: number;
  title: string;
  href: string;
}

export interface IPrice {
  oneUser: IUserPrice;
  fiveUsers: IUserPrice;
}

export interface ILabel {
  text: string | number;
  tooltip?: string;
}

export interface IPricesMock {
  id: number;
  monthlyPrice?: IPrice;
  yearlyPrice?: IPrice;
  pros: IPros[];
  label: ILabel;
}

export interface ITab {
  id: number;
  label: string;
  discount?: number;
  price?: number;
  fiveUsers?: IUserPrice;
  oneUser?: IUserPrice;
  pros?: string[];
}

export interface IPriceItemProps {
  tabs: ITab[];
  prices: IPricesMock[];
  maxSliderValue: number;
  handleToggleClick: () => void;
  isToggle: boolean;
  activeSection: PriceSection;
}

interface IColumn {
  id: number;
  header: string;
  accessor: Columns;
  productName?: string;
  size?: number;
}

interface IData {
  id: number;
  [key: string]: number | { text: string; tooltip: string } | string | JSX.Element;
}

export interface ITable {
  columns: IColumn[];
  data: IData[];
}

export interface IPricingSectionMock {
  [key: string]: {
    component: (props: IPriceItemProps) => JSX.Element;
    tabs: ITab[];
    maxSliderValue: number;
    prices: IPricesMock[];
    table: ITable;
  };
}

enum Columns {
  "feature" = "feature",
  "free" = "free",
  "basic" = "basic",
  "premium" = "premium",
  "professional" = "professional",
  "personal" = "personal",
  "commercial" = "commercial",
  "allInOne" = "allInOne",
}

const voicesColumns: IColumn[] = [
  {
    id: 1,
    header: "Features",
    accessor: Columns.feature,
    size: 318,
  },
  {
    id: 2,
    header: "Free",
    accessor: Columns.free,
    size: 220,
  },
  {
    id: 83104,
    header: "Basic",
    accessor: Columns.basic,
    productName: "Synthesys AI Voices - Monthly - 100 Mins",
    size: 220,
  },
  {
    id: 83109,
    header: "Premium",
    accessor: Columns.premium,
    productName: "Synthesys AI Voices - Monthly - 500 Mins",
    size: 220,
  },
  {
    id: 83110,
    header: "Professional",
    accessor: Columns.professional,
    productName: "Synthesys AI Voices - Monthly - 3600 Mins",
    size: 220,
  },
];

const humansColumns: IColumn[] = [
  {
    id: 1,
    header: "Features",
    accessor: Columns.feature,
    size: 318,
  },
  {
    id: 2,
    header: "Free",
    accessor: Columns.free,
    size: 220,
  },
  {
    id: 83130,
    header: "Basic",
    accessor: Columns.basic,
    productName: "Synthesys AI Humans - Monthly - 50 Mins",
    size: 220,
  },
  {
    id: 83131,
    header: "Premium",
    accessor: Columns.premium,
    productName: "Synthesys AI Humans - Monthly - 300 Mins",
    size: 220,
  },
  {
    id: 83133,
    header: "Professional",
    accessor: Columns.professional,
    productName: "Synthesys AI Humans - Monthly - 1800 Mins",
    size: 220,
  },
];

const imagesColumns = [
  {
    id: 1,
    header: "Features",
    accessor: Columns.feature,
    size: 318,
  },
  {
    id: 5,
    header: "Free",
    accessor: Columns.free,
    size: 245,
  },
  {
    id: 2,
    header: "Personal",
    accessor: Columns.personal,
    size: 245,
  },
  {
    id: 3,
    header: "Commercial",
    accessor: Columns.commercial,
    size: 245,
  },
];

const studioColumns: IColumn[] = [
  {
    id: 1,
    header: "Features",
    accessor: Columns.feature,
  },
  {
    id: 83144,
    header: "All in one",
    accessor: Columns.allInOne,
    productName: "Synthesys AI Bundle Package - Monthly",
  },
];

const voicesTable: ITable = {
  columns: voicesColumns,
  data: [
    {
      id: 1,
      [Columns.feature]: "No. of Minutes of Audio renders",
      [Columns.free]: "5 mins/Mo",
      [Columns.basic]: "100 Mins/Mo",
      [Columns.premium]: "500 mins/mo",
      [Columns.professional]: {
        text: "1800 mins/mo",
        tooltip: "Fair usage policy 60 hours per month",
      },
    },
    {
      id: 2,
      [Columns.feature]: "Ultra Life-like Voices",
      [Columns.free]: "10",
      [Columns.basic]: "10",
      [Columns.premium]: "50",
      [Columns.professional]: "50",
    },
    {
      id: 3,
      [Columns.feature]: "Premium Voices",
      [Columns.free]: "370",
      [Columns.basic]: "370",
      [Columns.premium]: "370",
      [Columns.professional]: "370",
    },
    {
      id: 4,
      [Columns.feature]: "Languages",
      [Columns.free]: "140",
      [Columns.basic]: "140",
      [Columns.premium]: "140",
      [Columns.professional]: "140",
    },
    {
      id: 99,
      [Columns.feature]: "Voice cloning",
      [Columns.free]: "1",
      [Columns.basic]: "2",
      [Columns.premium]: "5",
      [Columns.professional]: "10",
    },
    {
      id: 5,
      [Columns.feature]: {
        text: "Voice settings:",
        tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
      },
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 6,
      [Columns.feature]: {
        text: "Voice settings for emotive texts ",
        tooltip: "Control voice style to create richer content",
      },
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 7,
      [Columns.feature]: {
        text: "Multi-Voice functionality.",
        tooltip:
          "Use different voices for different sentences in a single audio file to create conversational voiceovers",
      },
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: "Organize work with projects and make changes on the fly",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 9,
      [Columns.feature]: "Preview mode",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 10,
      [Columns.feature]: "Download mode (Seperetly or single file)",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 11,
      [Columns.feature]: "Commercial License",
      [Columns.free]: <XCircleIcon />,
      [Columns.basic]: <XCircleIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 12,
      [Columns.feature]: "AI script assistant",
      [Columns.free]: <XCircleIcon />,
      [Columns.basic]: <XCircleIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 13,
      [Columns.feature]: "Coming Soon - - One-click embedding of voice files in blog posts",
      [Columns.free]: <EmptyIcon />,
      [Columns.basic]: <EmptyIcon />,
      [Columns.premium]: <EmptyIcon />,
      [Columns.professional]: <EmptyIcon />,
    },
  ],
};

const humansTable: ITable = {
  columns: humansColumns,
  data: [
    {
      id: 1,
      [Columns.feature]: "No. of Minutes of Video renders",
      [Columns.free]: "5 mins/Mo",
      [Columns.basic]: "50 Mins/Mo",
      [Columns.premium]: "300 mins/mo",
      [Columns.professional]: {
        text: "1200 mins/mo",
        tooltip: "Fair usage policy 30 hours per month",
      },
    },
    {
      id: 2,
      [Columns.feature]: "Premium Actors",
      [Columns.free]: "30 Humatars",
      [Columns.basic]: "30 Humatars",
      [Columns.premium]: "70 Humatars",
      [Columns.professional]: "70 Humatars",
    },
    {
      id: 3,
      [Columns.feature]: "Ultra Life-like Voices",
      [Columns.free]: "40",
      [Columns.basic]: "40",
      [Columns.premium]: "115",
      [Columns.professional]: "115",
    },
    {
      id: 4,
      [Columns.feature]: "Premium Voices",
      [Columns.free]: "370",
      [Columns.basic]: "370",
      [Columns.premium]: "370",
      [Columns.professional]: "370",
    },
    {
      id: 5,
      [Columns.feature]: "Languages",
      [Columns.free]: "140",
      [Columns.basic]: "140",
      [Columns.premium]: "140",
      [Columns.professional]: "140",
    },
    {
      id: 6,
      [Columns.feature]: "Scenes per video",
      [Columns.free]: "6 Scenes",
      [Columns.basic]: "6 Scenes",
      [Columns.premium]: "12 Scenes",
      [Columns.professional]: "12 Scenes",
    },
    {
      id: 99,
      [Columns.feature]: "Voice cloning",
      [Columns.free]: "1",
      [Columns.basic]: "2",
      [Columns.premium]: "5",
      [Columns.professional]: "10",
    },
    {
      id: 7,
      [Columns.feature]: {
        text: "Voice settings:",
        tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
      },
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 8,
      [Columns.feature]: "Aspect ratio (Landscape & Portrait)",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 9,
      [Columns.feature]: "Upload your own images and videos",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 10,
      [Columns.feature]: "Upload music",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 11,
      [Columns.feature]: "Stock images and videos",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 12,
      [Columns.feature]: "Video quality",
      [Columns.free]: "HD (720p)",
      [Columns.basic]: "Full HD (1080p)",
      [Columns.premium]: "Full HD (1080p)",
      [Columns.professional]: "Full HD (1080p)",
    },
    {
      id: 13,
      [Columns.feature]: "Voice Upload",
      [Columns.free]: <XCircleIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 14,
      [Columns.feature]: "Transitions",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 17,
      [Columns.feature]: "Import PPT, PDF",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 18,
      [Columns.feature]: "Video templates",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 19,
      [Columns.feature]: "Faceswap",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 20,
      [Columns.feature]: "Embed humatars to any website",
      [Columns.free]: <CircleCheckedIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 21,
      [Columns.feature]: "Priority Video Processing",
      [Columns.free]: <XCircleIcon />,
      [Columns.basic]: <CircleCheckedIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 15,
      [Columns.feature]: "Commercial License",
      [Columns.free]: <XCircleIcon />,
      [Columns.basic]: <XCircleIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
    {
      id: 16,
      [Columns.feature]: "AI script assistant",
      [Columns.free]: <XCircleIcon />,
      [Columns.basic]: <XCircleIcon />,
      [Columns.premium]: <CircleCheckedIcon />,
      [Columns.professional]: <CircleCheckedIcon />,
    },
  ],
};

const studioTable: ITable = {
  columns: studioColumns,
  data: [
    {
      id: 1,
      [Columns.feature]: "No. of Minutes of Audio renders monthly",
      [Columns.allInOne]: {
        text: "Unlimited",
        tooltip: "Fair usage policy 60 hours per month",
      },
    },
    {
      id: 2,
      [Columns.feature]: "No. of Minutes of Video renders monthly",
      [Columns.allInOne]: {
        text: "Unlimited",
        tooltip: "Fair usage policy 30 hours per month",
      },
    },
    {
      id: 3,
      [Columns.feature]: "No. of Minutes of Video renders monthly",
      [Columns.allInOne]: "Unlimited",
    },
    {
      id: 4,
      [Columns.feature]: "Features",
      [Columns.allInOne]: "Every service includes all the features",
    },
    {
      id: 99,
      [Columns.feature]: "Voice cloning",
      [Columns.allInOne]: "10",
    },
  ],
};

const imagesTable = {
  columns: imagesColumns,
  data: [
    {
      id: 1,
      [Columns.feature]: "Usage",
      [Columns.free]: "50 generations per month",
      [Columns.personal]: "Unlimited",
      [Columns.commercial]: "Unlimited",
    },
    {
      id: 2,
      [Columns.feature]: "Commercial",
      [Columns.free]: <XCircleIcon />,
      [Columns.personal]: <XCircleIcon />,
      [Columns.commercial]: <CircleCheckedIcon />,
    },
    {
      id: 3,
      [Columns.feature]: "Remove Object from an existing image",
      [Columns.free]: <XCircleIcon />,
      [Columns.personal]: <XCircleIcon />,
      [Columns.commercial]: <CircleCheckedIcon />,
    },
    {
      id: 4,
      [Columns.feature]: "Inpainting",
      [Columns.free]: <XCircleIcon />,
      [Columns.personal]: <XCircleIcon />,
      [Columns.commercial]: <CircleCheckedIcon />,
    },
    {
      id: 5,
      [Columns.feature]: "Coming Soon - FaceSwap",
      [Columns.free]: <XCircleIcon />,
      [Columns.personal]: <XCircleIcon />,
      [Columns.commercial]: <CircleCheckedIcon />,
    },
    {
      id: 6,
      [Columns.feature]: "Coming Soon - Remove Video Background",
      [Columns.free]: <XCircleIcon />,
      [Columns.personal]: <XCircleIcon />,
      [Columns.commercial]: <CircleCheckedIcon />,
    },
    {
      id: 7,
      [Columns.feature]: "Coming Soon - Remove Image Background",
      [Columns.free]: <XCircleIcon />,
      [Columns.personal]: <XCircleIcon />,
      [Columns.commercial]: <CircleCheckedIcon />,
    },
  ],
};

export const subscriptions: Subscription[] = [
  {
    groupTitle: "AI Voices",
    duration: "Monthly",
    price: 23,
    title: "Synthesys AI Voices - Monthly - 100 Mins",
    planID: 83104,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/pVrd6N38xbARYjeo8GWGm7BvgXaeMo0L",
  },
  {
    groupTitle: "AI Voices",
    duration: "Monthly",
    price: 33,
    title: "Synthesys AI Voices - Monthly - 100 Mins - 5 Users",
    planID: 83123,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/RwKP1dNXLGorAWPvdAj4mO3x70JZy2MY",
  },
  {
    groupTitle: "AI Voices",
    duration: "Monthly",
    price: 59,
    title: "Synthesys AI Voices - Monthly - 500 Mins",
    planID: 83109,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/4xYynmelqJADXWNnR7W5ML6G81QPO9ZV",
  },
  {
    groupTitle: "AI Voices",
    duration: "Monthly",
    price: 69,
    title: "Synthesys AI Voices - Monthly - 500 Mins - 5 Users",
    planID: 83155,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/ZnyKdYXBJD0a1kmXoaWqVormO37Pg98G",
  },
  {
    groupTitle: "AI Voices",
    duration: "Monthly",
    price: 119,
    title: "Synthesys AI Voices - Monthly - 3600 Mins",
    planID: 83110,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/rY7neP8l4O32dWaELPW5EqG1pRoDw9Va",
  },
  {
    groupTitle: "AI Voices",
    duration: "Monthly",
    price: 129,
    title: "Synthesys AI Voices - Monthly - 3600 Mins - 5 Users",
    planID: 83156,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/qg5wvdGQO1Dl0zbx6Zk6YBVn3EmP7rXb",
  },
  {
    groupTitle: "AI Voices",
    duration: "Yearly",
    price: 228,
    title: "Synthesys AI Voices - Yearly - 100 Min",
    planID: 83105,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/7DbEdNQKwroYezYK2wkV6Ry490aO38Lg",
  },
  {
    groupTitle: "AI Voices",
    duration: "Yearly",
    price: 348,
    title: "Synthesys AI Voices - Yearly - 100 Mins - 5 Users",
    planID: 83124,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/pLoegY7nG1OPZWKaXlj80qmE35Xb62yK",
  },
  {
    groupTitle: "AI Voices",
    duration: "Yearly",
    price: 588,
    title: "Synthesys AI Voices - Yearly - 500 Mins",
    planID: 83108,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/2BKp7ladeP1E0zRwb9WrmNgoYAQ6L9v3",
  },
  {
    groupTitle: "AI Voices",
    duration: "Yearly",
    price: 708,
    title: "Synthesys AI Voices - Yearly - 500 Mins - 5 Users",
    planID: 83157,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/p40L1rPaq3dDQWnJMMWJeGEYwlvb56y9",
  },
  {
    groupTitle: "AI Voices",
    duration: "Yearly",
    price: 1188,
    title: "Synthesys AI Voices - Yearly - 3600 Mins",
    planID: 83112,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/qg5wvdGQO1Dl0zbxZZk6YBVn3EmP7rXb",
  },
  {
    groupTitle: "AI Voices",
    duration: "Yearly",
    price: 1308,
    title: "Synthesys AI Voices - Yearly - 3600 Mins - 5 Users",
    planID: 83158,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/Vbw1x0pvQOam7WyydyWGZAqE3eJ5BMPX",
  },
  {
    groupTitle: "AI Humans",
    duration: "Monthly",
    price: 35,
    title: "Synthesys AI Humans - Monthly - 50 Mins",
    planID: 83130,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/Dr3dlxqOMoZ2NW9G68jQa9VEARK8yvJw",
  },
  {
    groupTitle: "AI Humans",
    duration: "Monthly",
    price: 45,
    title: "Synthesys AI Humans - Monthly - 50 Mins - 5 Users",
    planID: 83135,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/7AB6aKpmg5x2vjVQnYk9MlXQ0R3r1yOo",
  },
  {
    groupTitle: "AI Humans",
    duration: "Monthly",
    price: 69,
    title: "Synthesys AI Humans - Monthly - 300 Mins",
    planID: 83131,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/evOMAJnpd4EbZW8xK9W762KmLw01rR8l",
  },
  {
    groupTitle: "AI Humans",
    duration: "Monthly",
    price: 79,
    title: "Synthesys AI Humans - Monthly - 300 Mins - 5 Users",
    planID: 83132,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/Vn8ZLM3d4JPv7jEeMpjKo2xqagDON1wR",
  },
  {
    groupTitle: "AI Humans",
    duration: "Monthly",
    price: 119,
    title: "Synthesys AI Humans - Monthly - 1800 Mins",
    planID: 83133,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/nZ1d0RJK7lDoAjxnpXWOvXPpGQ6exVE2",
  },
  {
    groupTitle: "AI Humans",
    duration: "Monthly",
    price: 129,
    title: "Synthesys AI Humans - Monthly - 1800 Mins - 5 Users",
    planID: 83128,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/PRNdEAVvryXDLzZ6wxz7nZJ5mlKwG3xp",
  },
  {
    groupTitle: "AI Humans",
    duration: "Yearly",
    price: 348,
    title: "Synthesys AI Humans - Yearly - 50 Mins",
    planID: 83137,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/levy1qGnMwVLYzMbeOWJQdOmAB87N0R3",
  },
  {
    groupTitle: "AI Humans",
    duration: "Yearly",
    price: 468,
    title: "Synthesys AI Humans - Yearly - 50 Mins - 5 Users",
    planID: 83138,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/KZPqBwJpL8meOkrdDPzxa2YovDl7Ad3N",
  },
  {
    groupTitle: "AI Humans",
    duration: "Yearly",
    price: 708,
    title: "Synthesys AI Humans - Yearly - 300 Mins",
    planID: 83127,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/085AZMQlJaVyvWLmJgk6m2YLxENo1BRg",
  },
  {
    groupTitle: "AI Humans",
    duration: "Yearly",
    price: 828,
    title: "Synthesys AI Humans - Yearly - 300 Mins - 5 Users",
    planID: 83140,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/ndONmg2YZwL01k06AQzEyMvG4KPXl8xp",
  },
  {
    groupTitle: "AI Humans",
    duration: "Yearly",
    price: 1188,
    title: "Synthesys AI Humans - Yearly - 1800 Mins",
    planID: 83141,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/pAYXBlDw6VGPdkgr9XWgqx0Z9Q1y5mRo",
  },
  {
    groupTitle: "AI Humans",
    duration: "Yearly",
    price: 1308,
    title: "Synthesys AI Humans - Yearly - 1800 Mins - 5 Users",
    planID: 83129,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/QDBJamy63pGbLk7EP5zr2o4qVxd9lY0v",
  },
  {
    groupTitle: "AI Images",
    duration: "Monthly",
    price: 19,
    title: "Synthesys AI Images - Monthly - Personal License",
    planID: 83143,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/45nv3ME1weJgxz3Z21jdL07plRBGqDoN",
  },
  {
    groupTitle: "AI Images",
    duration: "Monthly",
    price: 29,
    title: "Synthesys AI Images - Monthly - Personal License - 5 Users",
    planID: 83147,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/5Nv0broBqwlyOjJN5JjQ7RXg1L9GaAeM",
  },
  {
    groupTitle: "AI Images",
    duration: "Monthly",
    price: 79,
    title: "Synthesys AI Images - Monthly - Commercial License",
    planID: 83146,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/QVdwavLoEXPAbzXPvnz60OR7m9GeKD8r",
  },
  {
    groupTitle: "AI Images",
    duration: "Monthly",
    price: 89,
    title: "Synthesys AI Images - Monthly - Commercial - 5 Users",
    planID: 83148,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/pVrd6N38xbARYjeoZGWGm7BvgXaeMo0L",
  },
  {
    groupTitle: "Synthesys AI Bundle",
    duration: "Monthly",
    price: 239,
    title: "Synthesys AI Bundle Package - Monthly",
    planID: 83144,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/RMy0raxG9vZVDkDJomzlJEpQwALeKmgq",
  },
  {
    groupTitle: "Synthesys AI Bundle",
    duration: "Monthly",
    price: 249,
    title: "Synthesys AI Bundle Package - Monthly - 5 Users",
    planID: 83150,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/VXQq3DLZY0pNOzpYleW9wo7g8Keld5ab",
  },
  {
    groupTitle: "Synthesys AI Bundle",
    duration: "Yearly",
    price: 2388,
    title: "Synthesys AI Bundle Package - Yearly",
    planID: 83145,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/dwq73e0Z6184Mz59QLzaRKgDrBvAbyQn",
  },
  {
    groupTitle: "Synthesys AI Bundle",
    duration: "Yearly",
    price: 2508,
    title: "Synthesys AI Bundle Package - Yearly - 5 Users",
    planID: 83149,
    checkoutLink: "https://app.paykickstart.com/checkout/plan/7DbEdNQKwroYezYKAwkV6Ry490aO38Lg",
  },
];

export const pricingSectionMock: IPricingSectionMock = {
  [PriceSection.voices]: {
    component: (props) => <PriceVoices {...props} />,
    maxSliderValue: 3,
    tabs: [
      { id: 1, label: "Monthly" },
      { id: 2, label: "Yearly", discount: 20 },
    ],
    prices: [
      {
        id: 1,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/q286AGpJl9de3kwbaZWbmY0V14XLaPrD",
            price: 23,
            id: 83104,
            title: "Synthesys AI Voices - Monthly - 100 Mins",
            href: "#pkmodal8643833a95dd490dfbcc266272da68843286913f",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/qVBwA5YD1K90djOywYWGEovQr4OJnype",
            price: 33,
            id: 83123,
            title: "Synthesys AI Voices - Monthly - 100 Mins - 5 Users",
            href: "#pkmodal7134249a91841ec9712cdd399576fccf95141798",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/OYv1m53w8BoV4jqpBQkGDy9Z7lgAJxKX",
            price: 228,
            id: 83105,
            title: "Synthesys AI Voices - Yearly - 100 Min",
            href: "#pkmodalca37517697a63d7aea602bbd085845c6ed20bb68",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/45nv3ME1weJgxz3ZP9jdL07plRBGqDoN",
            price: 348,
            id: 83124,
            title: "Synthesys AI Voices - Yearly - 100 Mins - 5 Users",
            href: "#pkmodal88cf88ccbd3f4a89f99a16a3246ee54f3328bc3d",
          },
        },
        label: {
          text: "100 min",
        },
        pros: [
          { text: "Access to 400 Premium Voices & 140 Languagess", tooltip: "" },
          { text: "Access to 10+ Ultra Lifelike Synthesys Voices", tooltip: "" },
          {
            text: "Voice settings",
            tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
          },
          {
            text: "Multi-Voice functionality",
            tooltip:
              "Use different voices for different sentences in a single audio file to create conversational voiceovers.",
          },
          { text: "Personal License", tooltip: "" },
          { text: "Voice Cloning - 2 Voices", tooltip: "" },
        ],
      },
      {
        id: 2,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/KZPqBwJpL8meOkrdypzxa2YovDl7Ad3N",
            price: 59,
            id: 83109,
            title: "Synthesys AI Voices - Monthly - 500 Mins",
            href: "#pkmodal0641e90fb0f24255014f02c0f32de2b39d17342c",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/RMy0raxG9vZVDkDJprzlJEpQwALeKmgq",
            price: 69,
            id: 83155,
            title: "Synthesys AI Voices - Monthly - 500 Mins - 5 Users",
            href: "#pkmodalaad67f10009745814370c289a27e2746e16aa888",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/6yK2GVrEARPLgzQAq2zl5Yb4oO38Zwpd",
            price: 588,
            id: 83108,
            title: "Synthesys AI Voices - Yearly - 500 Mins",
            href: "#pkmodala5e13d9f404e6280d1fb64d498b382217a352701",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/dwq73e0Z6184Mz590QzaRKgDrBvAbyQn",
            price: 708,
            id: 83157,
            title: "Synthesys AI Voices - Yearly - 500 Mins - 5 Users",
            href: "#pkmodal0f1d721f0e861273f783ec13dde02ef573c2f062",
          },
        },
        label: {
          text: "500 min",
        },
        pros: [
          { text: "Access to 400 Premium Voices & 140 Languages", tooltip: "" },
          { text: "Access to 40+ Ultra Lifelike Synthesys Voices", tooltip: "" },
          {
            text: "Voice settings",
            tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
          },
          {
            text: "Multi-Voice functionality",
            tooltip:
              "Use different voices for different sentences in a single audio file to create conversational voiceovers",
          },
          { text: "Commercial License", tooltip: "" },
          { text: "Access to AI Writer", tooltip: "" },
          { text: "Voice Cloning - 5 Voices", tooltip: "" },
        ],
      },
      {
        id: 3,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/qgDmG3ZML7PAeWl3R7z4ovpry5NdwEKB",
            price: 119,
            id: 83110,
            title: "Synthesys AI Voices - Monthly - 3600 Mins",
            href: "#pkmodal8eb2e74e49f6008706b754a9a07ed6c20e93304a",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/5Nv0broBqwlyOjJNb2jQ7RXg1L9GaAeM",
            price: 129,
            id: 83156,
            title: "Synthesys AI Voices - Monthly - 3600 Mins - 5 Users",
            href: "#pkmodal53207f0b9f851c92676bedf07a319be97d31bce4",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/pAYXBlDw6VGPdkgrJwWgqx0Z9Q1y5mRo",
            price: 1188,
            id: 83112,
            title: "Synthesys AI Voices - Yearly - 3600 Mins",
            href: "#pkmodalcc5aae93ad1dcdbd0de8b1459ed2222de9817f6d",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/pVrd6N38xbARYjeoa0WGm7BvgXaeMo0L",
            price: 1308,
            id: 83158,
            title: "Synthesys AI Voices - Yearly - 3600 Mins - 5 Users",
            href: "#pkmodalf7879414fd63d7da804e603b3b3693715994c32f",
          },
        },
        label: {
          text: "1800 min",
        },
        pros: [
          { text: "Access to 400 Premium Voices & 140 Languages", tooltip: "" },
          { text: "Access to 100+ Ultra Lifelike Synthesys Voices", tooltip: "" },
          {
            text: "Voice settings",
            tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
          },
          {
            text: "Multi-Voice functionality",
            tooltip:
              "Use different voices for different sentences in a single audio file to create conversational voiceovers.",
          },
          { text: "Commercial License", tooltip: "" },
          { text: "Access to AI Writer", tooltip: "" },
          { text: "Voice Cloning - 10 Voices", tooltip: "" },
        ],
      },
    ],
    table: voicesTable,
  },
  [PriceSection.humans]: {
    component: (props) => <PriceHumans {...props} />,
    maxSliderValue: 3,
    tabs: [
      { id: 1, label: "Monthly" },
      { id: 2, label: "Yearly", discount: 20 },
    ],
    prices: [
      {
        id: 1,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/7DbEdNQKwroYezYK8EkV6Ry490aO38Lg",
            price: 35,
            id: 83130,
            title: "Synthesys AI Humans - Monthly - 50 Mins",
            href: "#pkmodal04ed99f2ae015159eb70cfe8804b9d7970253e4b",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/rY7neP8l4O32dWaEpDW5EqG1pRoDw9Va",
            price: 45,
            id: 83135,
            title: "Synthesys AI Humans - Monthly - 50 Mins - 5 Users",
            href: "#pkmodal3678f42a97ae5c783c0d7fd0a918d61b1edd9f46",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/RMy0raxG9vZVDkDJVxzlJEpQwALeKmgq",
            price: 348,
            id: 83137,
            title: "Synthesys AI Humans - Yearly - 50 Mins",
            href: "#pkmodale4a8716a289f540eace6cf09fcb558f463349430",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/dwq73e0Z6184Mz59ZvzaRKgDrBvAbyQn",
            price: 468,
            id: 83138,
            title: "Synthesys AI Humans - Yearly - 50 Mins - 5 Users",
            href: "#pkmodal281db2d6e9dc4837dc6d3928302b81212f1b64d5",
          },
        },
        label: {
          text: "50 min",
        },
        pros: [
          { text: "30 Premium Humatars" },
          { text: "40 Ultra Life-like Voices" },
          { text: "370 Premium Voices" },
          { text: "140 languages" },
          { text: "Up to 6 scenes per video", tooltip: "each scene is 1 min" },
          { text: "Voice Cloning - 2 Voices", tooltip: "" },
        ],
      },
      {
        id: 2,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/VXQq3DLZY0pNOzpY53W9wo7g8Keld5ab",
            price: 69,
            id: 83131,
            title: "Synthesys AI Humans - Monthly - 300 Mins",
            href: "#pkmodalaea476572b7e02cd2a120a38099f543b01b63a24",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/2BKp7ladeP1E0zRw8MWrmNgoYAQ6L9v3",
            price: 79,
            id: 83132,
            title: "Synthesys AI Humans - Monthly - 300 Mins - 5 Users",
            href: "#pkmodal31c24e3eae250294bd45207e5943b807beb602b7",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/QVdwavLoEXPAbzXP86z60OR7m9GeKD8r",
            price: 708,
            id: 83127,
            title: "Synthesys AI Humans - Yearly - 300 Mins",
            href: "#pkmodal9394f633115174da5f5b6cbd358f55ce056b77ab",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/5Nv0broBqwlyOjJN8gjQ7RXg1L9GaAeM",
            price: 828,
            id: 83140,
            title: "Synthesys AI Humans - Yearly - 300 Mins - 5 Users",
            href: "#pkmodal02f4025f39920d32d4469648f1def40e08d2dc8a",
          },
        },
        label: {
          text: "300 min",
        },
        pros: [
          { text: "70 Premium Humatars" },
          { text: "115 Ultra Life-like Voices" },
          { text: "370 Premium Voices" },
          { text: "140 languages" },
          { text: "Up to 12 scenes per video", tooltip: "each scene is 1 min" },
          { text: "Voice Cloning - 5 Voices", tooltip: "" },
        ],
      },
      {
        id: 3,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/4xYynmelqJADXWNn80W5ML6G81QPO9ZV",
            price: 119,
            id: 83133,
            title: "Synthesys AI Humans - Monthly - 1800 Mins",
            href: "#pkmodalac0e8baa00d210bd9a93bc150d05d826b2c55152",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/ZnyKdYXBJD0a1kmXBwWqVormO37Pg98G",
            price: 129,
            id: 83128,
            title: "Synthesys AI Humans - Monthly - 1800 Mins - 5 Users",
            href: "#pkmodal7dda6bc7646e9f5d7a4825ac6ac8cbde8e55bf7b",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/pVrd6N38xbARYjeoaJWGm7BvgXaeMo0L",
            price: 1188,
            id: 83141,
            title: "Synthesys AI Humans - Yearly - 1800 Mins",
            href: "#pkmodalbd162cd95ea551399b5d7f12948df8caa6299a95",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/7DbEdNQKwroYezYK8NkV6Ry490aO38Lg",
            price: 1308,
            id: 83129,
            title: "Synthesys AI Humans - Yearly - 1800 Mins - 5 Users",
            href: "#pkmodal30192c872eb192809acedbf28beace76cdd1eb94",
          },
        },
        label: {
          text: "1200 min",
        },
        pros: [
          { text: "70 Premium Humatars" },
          { text: "115 Ultra Life-like Voices" },
          { text: "370 Premium Voices" },
          { text: "140 languages" },
          { text: "Up to 12 scenes per video", tooltip: "each scene is 1 min" },
          { text: "Voice Cloning - 10 Voices", tooltip: "" },
        ],
      },
    ],
    table: humansTable,
  },
  [PriceSection.image]: {
    component: (props) => <PriceImage {...props} />,
    maxSliderValue: 0,
    prices: [] as IPricesMock[],
    tabs: [
      {
        id: 1,
        label: "Personal",
        oneUser: {
          price: 19,
          url: "https://app.paykickstart.com/checkout/plan/VXQq3DLZY0pNOzpY57W9wo7g8Keld5ab",
          id: 83143,
          title: "Synthesys AI Images - Monthly - Personal License",
          href: "#pkmodale7959b5c979664745bf6e26abd3bc8305e869d3b",
        },
        fiveUsers: {
          price: 29,
          url: "https://app.paykickstart.com/checkout/plan/pLoegY7nG1OPZWKaOGj80qmE35Xb62yK",
          id: 83147,
          title: "Synthesys AI Images - Monthly - Personal License - 5 Users",
          href: "#pkmodale15f818e2e948ad764274ed252f698cf062d8a45",
        },
        pros: ["Unlimited", "Personal License"],
      },
      {
        id: 2,
        label: "Commercial",
        oneUser: {
          price: 79,
          url: "https://app.paykickstart.com/checkout/plan/ZnyKdYXBJD0a1kmXBNWqVormO37Pg98G",
          id: 83146,
          title: "Synthesys AI Images - Monthly - Commercial License",
          href: "#pkmodal70167202139062817baacbbb36a884ed566e964a",
        },
        fiveUsers: {
          price: 89,
          url: "https://app.paykickstart.com/checkout/plan/DL34Re7XowpanW68VvjdVMAx1JZv5y9K",
          id: 83148,
          title: "Synthesys AI Images - Monthly - Commercial - 5 Users",
          href: "#pkmodal0bdc214473f1f681ed3f50f735654234200dde47",
        },
        pros: [
          "Unlimited ",
          "Remove Object",
          "Inpainting",
          "Commercial License",
          "Coming soon - Faceswap",
          "Coming soon - Remove video background",
          "Coming soon - Remove image background",
        ],
      },
    ],
    table: imagesTable,
  },
  [PriceSection.studio]: {
    component: (props) => <PriceStudio {...props} />,
    maxSliderValue: 3,
    tabs: [
      { id: 1, label: "Monthly" },
      { id: 2, label: "Yearly", discount: 20 },
    ],
    prices: [
      {
        id: 1,
        monthlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/MgZmXl56d4Ro2WdVNEjpLvbq9VJwnO3D",
            price: 239,
            id: 83144,
            title: "Synthesys AI Bundle Package - Monthly",
            href: "#pkmodalda128605e040d0c4310615d23475da63df2d1ef4",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/085AZMQlJaVyvWLmOEk6m2YLxENo1BRg",
            price: 249,
            id: 83150,
            title: "Synthesys AI Bundle Package - Monthly - 5 Users",
            href: "#pkmodal8d31c52cfa964e6b629d9e0628a7d8603d367c1a",
          },
        },
        yearlyPrice: {
          oneUser: {
            url: "https://app.paykickstart.com/checkout/plan/2BKp7ladeP1E0zRw87WrmNgoYAQ6L9v3",
            price: 2388,
            id: 83145,
            title: "Synthesys AI Bundle Package - Yearly",
            href: "#pkmodal40075f91d84b56d5965a0b5eb6fd021e9d7f3b18",
          },
          fiveUsers: {
            url: "https://app.paykickstart.com/checkout/plan/VNvm3yQDqng4BW11VNWEZbXKRdaY96G2",
            price: 2508,
            id: 83149,
            title: "Synthesys AI Bundle Package - Yearly - 5 Users",
            href: "#pkmodal5c5be52a20b58211176370b4e5249398d9d17b03",
          },
        },
        label: {
          text: "0 min",
        },
        pros: [
          { text: "AI Voice Unlimited", tooltip: "Fair usage policy 60 hours per month" },
          { text: "AI Humans Unlimited", tooltip: "Fair usage policy 30 hours per month" },
          { text: "AI Image Unlimited" },
          { text: "Voice Cloning - 10 Voices", tooltip: "" },
        ],
      },
    ],
    table: studioTable,
  },
};

interface IFeature {
  id: number;
  left: { text: string; tooltip: string } | JSX.Element;
  right: { text: string; tooltip: string };
}

export interface IMobileCard {
  header: string;
  features: IFeature[];
  plan?: {
    id: number;
    title: string;
  };
}

export const mobileVoice: IMobileCard[] = [
  {
    header: "Free",
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Audio renders", tooltip: "" },
        right: { text: "5 mins/Mo", tooltip: "" },
      },
      {
        id: 2,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "10", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 5,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings ",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Multi-Voice functionality.",
          tooltip:
            "Use different voices for different sentences in a single audio file to create conversational voiceovers",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: {
          text: "Preview mode",
          tooltip: "",
        },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: {
          text: "Download mode (Seperetly or single file)",
          tooltip: "",
        },
      },
      {
        id: 9,
        left: <XCircleIcon />,
        right: {
          text: "Commercial License",
          tooltip: "",
        },
      },
      {
        id: 10,
        left: <XCircleIcon />,
        right: {
          text: "AI script assistant",
          tooltip: "",
        },
      },
      {
        id: 11,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - - One-click embedding of voice files in blog posts",
          tooltip: "",
        },
      },
    ],
  },
  {
    header: "Basic",
    plan: {
      id: 83104,
      title: "Synthesys AI Voices - Monthly - 100 Mins",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Audio renders", tooltip: "" },
        right: { text: "100 mins/Mo", tooltip: "" },
      },
      {
        id: 2,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "10", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 5,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings ",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Multi-Voice functionality.",
          tooltip:
            "Use different voices for different sentences in a single audio file to create conversational voiceovers",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: {
          text: "Preview mode",
          tooltip: "",
        },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: {
          text: "Download mode (Seperetly or single file)",
          tooltip: "",
        },
      },
      {
        id: 9,
        left: <XCircleIcon />,
        right: {
          text: "Commercial License",
          tooltip: "",
        },
      },
      {
        id: 10,
        left: <XCircleIcon />,
        right: {
          text: "AI script assistant",
          tooltip: "",
        },
      },
      {
        id: 11,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - - One-click embedding of voice files in blog posts",
          tooltip: "",
        },
      },
    ],
  },
  {
    header: "Premium",
    plan: {
      id: 83109,
      title: "Synthesys AI Voices - Monthly - 500 Mins",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Audio renders", tooltip: "" },
        right: { text: "500 mins/Mo", tooltip: "" },
      },
      {
        id: 2,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "50", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 5,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings ",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Multi-Voice functionality.",
          tooltip:
            "Use different voices for different sentences in a single audio file to create conversational voiceovers",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: {
          text: "Preview mode",
          tooltip: "",
        },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: {
          text: "Download mode (Seperetly or single file)",
          tooltip: "",
        },
      },
      {
        id: 9,
        left: <CircleCheckedIcon />,
        right: {
          text: "Commercial License",
          tooltip: "",
        },
      },
      {
        id: 10,
        left: <CircleCheckedIcon />,
        right: {
          text: "AI script assistant",
          tooltip: "",
        },
      },
      {
        id: 11,
        left: <CircleCheckedIcon />,
        right: {
          text: "Coming Soon - - One-click embedding of voice files in blog posts",
          tooltip: "",
        },
      },
    ],
  },
  {
    header: "Professional",
    plan: {
      id: 83110,
      title: "Synthesys AI Voices - Monthly - 3600 Mins",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Audio renders", tooltip: "" },
        right: {
          text: "Unlimited",
          tooltip: "Fair usage policy 60 hours per month",
        },
      },
      {
        id: 2,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "50", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 5,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings ",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Multi-Voice functionality.",
          tooltip:
            "Use different voices for different sentences in a single audio file to create conversational voiceovers",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: {
          text: "Preview mode",
          tooltip: "",
        },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: {
          text: "Download mode (Seperetly or single file)",
          tooltip: "",
        },
      },
      {
        id: 9,
        left: <CircleCheckedIcon />,
        right: {
          text: "Commercial License",
          tooltip: "",
        },
      },
      {
        id: 10,
        left: <CircleCheckedIcon />,
        right: {
          text: "AI script assistant",
          tooltip: "",
        },
      },
      {
        id: 11,
        left: <CircleCheckedIcon />,
        right: {
          text: "Coming Soon - - One-click embedding of voice files in blog posts",
          tooltip: "",
        },
      },
    ],
  },
];

export const mobileHumans: IMobileCard[] = [
  {
    header: "Free",
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Video renders", tooltip: "" },
        right: { text: "5 mins/Mo", tooltip: "" },
      },
      {
        id: 2,
        left: { text: "Premium Actors", tooltip: "" },
        right: { text: "30 Humatars", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "40", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 5,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 99,
        left: { text: "Scenes per video", tooltip: "" },
        right: { text: "6 Scenes", tooltip: "" },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: {
          text: "Aspect ratio (Landscape & Portrait)",
          tooltip: "",
        },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: {
          text: "Upload your own images and videos",
          tooltip: "",
        },
      },
      {
        id: 9,
        left: <CircleCheckedIcon />,
        right: {
          text: "Upload music",
          tooltip: "",
        },
      },
      {
        id: 10,
        left: <CircleCheckedIcon />,
        right: {
          text: "Stock images and videos",
          tooltip: "",
        },
      },
      {
        id: 11,
        left: {
          text: "Video quality",
          tooltip: "",
        },
        right: {
          text: "HD (720p)",
          tooltip: "",
        },
      },
      {
        id: 12,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice Upload",
          tooltip: "",
        },
      },
      {
        id: 13,
        left: <XCircleIcon />,
        right: {
          text: "Priority Video Processing",
          tooltip: "",
        },
      },
      {
        id: 14,
        left: <XCircleIcon />,
        right: {
          text: "Commercial License",
          tooltip: "",
        },
      },
      {
        id: 15,
        left: <XCircleIcon />,
        right: {
          text: "AI script assistant",
          tooltip: "",
        },
      },
      {
        id: 16,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Import PPT, PDF",
          tooltip: "",
        },
      },
      {
        id: 17,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Video templates",
          tooltip: "",
        },
      },
      {
        id: 18,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Faceswap",
          tooltip: "",
        },
      },
      {
        id: 19,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Embed humatars to any website",
          tooltip: "",
        },
      },
    ],
  },
  {
    header: "Basic",
    plan: {
      id: 83130,
      title: "Synthesys AI Humans - Monthly - 50 Mins",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Video renders", tooltip: "" },
        right: { text: "150 mins/Mo", tooltip: "" },
      },
      {
        id: 2,
        left: { text: "Premium Actors", tooltip: "" },
        right: { text: "30 Humatars", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "40", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 5,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 99,
        left: { text: "Scenes per video", tooltip: "" },
        right: { text: "6 Scenes", tooltip: "" },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: {
          text: "Aspect ratio (Landscape & Portrait)",
          tooltip: "",
        },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: {
          text: "Upload your own images and videos",
          tooltip: "",
        },
      },
      {
        id: 9,
        left: <CircleCheckedIcon />,
        right: {
          text: "Upload music",
          tooltip: "",
        },
      },
      {
        id: 10,
        left: <CircleCheckedIcon />,
        right: {
          text: "Stock images and videos",
          tooltip: "",
        },
      },
      {
        id: 11,
        left: {
          text: "Video quality",
          tooltip: "",
        },
        right: {
          text: "Full HD (1080p)",
          tooltip: "",
        },
      },
      {
        id: 12,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice Upload",
          tooltip: "",
        },
      },
      {
        id: 13,
        left: <CircleCheckedIcon />,
        right: {
          text: "Priority Video Processing",
          tooltip: "",
        },
      },
      {
        id: 123,
        left: <XCircleIcon />,
        right: {
          text: "Commercial License",
          tooltip: "",
        },
      },
      {
        id: 15,
        left: <XCircleIcon />,
        right: {
          text: "AI script assistant",
          tooltip: "",
        },
      },
      {
        id: 16,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Import PPT, PDF",
          tooltip: "",
        },
      },
      {
        id: 17,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Video templates",
          tooltip: "",
        },
      },
      {
        id: 18,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Faceswap",
          tooltip: "",
        },
      },
      {
        id: 19,
        left: <EmptyIcon />,
        right: {
          text: "Coming Soon - Embed humatars to any website",
          tooltip: "",
        },
      },
    ],
  },
  {
    header: "Premium",
    plan: {
      id: 83131,
      title: "Synthesys AI Humans - Monthly - 300 Mins",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Video renders", tooltip: "" },
        right: { text: "300 mins/Mo", tooltip: "" },
      },
      {
        id: 2,
        left: { text: "Premium Actors", tooltip: "" },
        right: { text: "70 Humatars", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "115", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 5,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 99,
        left: { text: "Scenes per video", tooltip: "" },
        right: { text: "12 Scenes", tooltip: "" },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: { text: "Aspect ratio (Landscape & Portrait)", tooltip: "" },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: { text: "Upload your own images and videos", tooltip: "" },
      },
      {
        id: 9,
        left: <CircleCheckedIcon />,
        right: { text: "Upload music", tooltip: "" },
      },
      {
        id: 10,
        left: <CircleCheckedIcon />,
        right: { text: "Stock images and videos", tooltip: "" },
      },
      {
        id: 11,
        left: { text: "Video quality", tooltip: "" },
        right: { text: "Full HD (1080p)", tooltip: "" },
      },
      {
        id: 12,
        left: <CircleCheckedIcon />,
        right: { text: "Voice Upload", tooltip: "" },
      },
      {
        id: 13,
        left: <CircleCheckedIcon />,
        right: { text: "Priority Video Processing", tooltip: "" },
      },
      {
        id: 14,
        left: <CircleCheckedIcon />,
        right: { text: "Commercial License", tooltip: "" },
      },
      {
        id: 15,
        left: <CircleCheckedIcon />,
        right: { text: "AI script assistant", tooltip: "" },
      },
      {
        id: 16,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Import PPT, PDF", tooltip: "" },
      },
      {
        id: 17,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Video templates", tooltip: "" },
      },
      {
        id: 18,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Faceswap", tooltip: "" },
      },
      {
        id: 19,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Embed humatars to any website", tooltip: "" },
      },
    ],
  },
  {
    header: "Professional",
    plan: {
      id: 83133,
      title: "Synthesys AI Humans - Monthly - 1800 Mins",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Video renders", tooltip: "" },
        right: {
          text: "Unlimited",
          tooltip: "Fair usage policy 30 hours per month",
        },
      },
      {
        id: 2,
        left: { text: "Premium Actors", tooltip: "" },
        right: { text: "70 Humatars", tooltip: "" },
      },
      {
        id: 3,
        left: { text: "Ultra Life-like Voices", tooltip: "" },
        right: { text: "115", tooltip: "" },
      },
      {
        id: 4,
        left: { text: "Premium Voices", tooltip: "" },
        right: { text: "370", tooltip: "" },
      },
      {
        id: 5,
        left: { text: "Languages", tooltip: "" },
        right: { text: "140", tooltip: "" },
      },
      {
        id: 99,
        left: { text: "Scenes per video", tooltip: "" },
        right: { text: "12 Scenes", tooltip: "" },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: {
          text: "Voice settings",
          tooltip: "Adjust Rate, Pitch, Emphasis, and Pauses to achieve a more suitable tone of voice",
        },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: { text: "Aspect ratio (Landscape & Portrait)", tooltip: "" },
      },
      {
        id: 8,
        left: <CircleCheckedIcon />,
        right: { text: "Upload your own images and videos", tooltip: "" },
      },
      {
        id: 9,
        left: <CircleCheckedIcon />,
        right: { text: "Upload music", tooltip: "" },
      },
      {
        id: 10,
        left: <CircleCheckedIcon />,
        right: { text: "Stock images and videos", tooltip: "" },
      },
      {
        id: 11,
        left: { text: "Video quality", tooltip: "" },
        right: { text: "Full HD (1080p)", tooltip: "" },
      },
      {
        id: 12,
        left: <CircleCheckedIcon />,
        right: { text: "Voice Upload", tooltip: "" },
      },
      {
        id: 13,
        left: <CircleCheckedIcon />,
        right: { text: "Priority Video Processing", tooltip: "" },
      },
      {
        id: 14,
        left: <CircleCheckedIcon />,
        right: { text: "Commercial License", tooltip: "" },
      },
      {
        id: 15,
        left: <CircleCheckedIcon />,
        right: { text: "AI script assistant", tooltip: "" },
      },
      {
        id: 16,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Import PPT, PDF", tooltip: "" },
      },
      {
        id: 17,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Video templates", tooltip: "" },
      },
      {
        id: 18,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Faceswap", tooltip: "" },
      },
      {
        id: 19,
        left: <EmptyIcon />,
        right: { text: "Coming Soon - Embed humatars to any website", tooltip: "" },
      },
    ],
  },
];

export const mobileImages: IMobileCard[] = [
  {
    header: "Personal",
    plan: {
      id: 83143,
      title: "Synthesys AI Images - Monthly - Personal License",
    },
    features: [
      {
        id: 1,
        left: { text: "Usage", tooltip: "" },
        right: { text: "Unlimited", tooltip: "" },
      },
      {
        id: 2,
        left: <XCircleIcon />,
        right: { text: "Commercial", tooltip: "" },
      },
      {
        id: 3,
        left: <XCircleIcon />,
        right: { text: "Remove Object from an existing image", tooltip: "" },
      },
      {
        id: 4,
        left: <XCircleIcon />,
        right: { text: "Inpainting", tooltip: "" },
      },
      {
        id: 5,
        left: <XCircleIcon />,
        right: { text: "Coming Soon - FaceSwap", tooltip: "" },
      },
      {
        id: 6,
        left: <XCircleIcon />,
        right: { text: "Coming Soon - Remove Video Background", tooltip: "" },
      },
      {
        id: 7,
        left: <XCircleIcon />,
        right: { text: "Coming Soon - Remove Image Background", tooltip: "" },
      },
    ],
  },
  {
    header: "Commercial",
    plan: {
      id: 83146,
      title: "Synthesys AI Images - Monthly - Commercial License",
    },
    features: [
      {
        id: 1,
        left: { text: "Usage", tooltip: "" },
        right: { text: "Unlimited", tooltip: "" },
      },
      {
        id: 2,
        left: <CircleCheckedIcon />,
        right: { text: "Commercial", tooltip: "" },
      },
      {
        id: 3,
        left: <CircleCheckedIcon />,
        right: { text: "Remove Object from an existing image", tooltip: "" },
      },
      {
        id: 4,
        left: <CircleCheckedIcon />,
        right: { text: "Inpainting", tooltip: "" },
      },
      {
        id: 5,
        left: <CircleCheckedIcon />,
        right: { text: "Coming Soon - FaceSwap", tooltip: "" },
      },
      {
        id: 6,
        left: <CircleCheckedIcon />,
        right: { text: "Coming Soon - Remove Video Background", tooltip: "" },
      },
      {
        id: 7,
        left: <CircleCheckedIcon />,
        right: { text: "Coming Soon - Remove Image Background", tooltip: "" },
      },
    ],
  },
];

export const mobileStudio: IMobileCard[] = [
  {
    header: "All In One",
    plan: {
      id: 83144,
      title: "Synthesys AI Bundle Package - Monthly",
    },
    features: [
      {
        id: 1,
        left: { text: "No. of Minutes of Audio renders monthly", tooltip: "" },
        right: {
          text: "Unlimited",
          tooltip: "Fair usage policy 60 hours per month",
        },
      },
      {
        id: 1,
        left: { text: "No. of Minutes of Video renders monthly", tooltip: "" },
        right: {
          text: "Unlimited ",
          tooltip: "Fair usage policy 30 hours per month",
        },
      },
      {
        id: 1,
        left: { text: "No. of Minutes of Image renders monthly", tooltip: "" },
        right: { text: "Unlimited ", tooltip: "" },
      },
      {
        id: 1,
        left: { text: "Features", tooltip: "" },
        right: { text: "Every service includes all the features ", tooltip: "" },
      },
    ],
  },
];

export const brands = [
  {
    id: 1,
    icon: <TataLogoIcon />,
  },
  {
    id: 2,
    icon: <YahooLogoIcon />,
  },
  {
    id: 3,
    icon: <NvidiaLogoIcon />,
  },
  {
    id: 4,
    icon: <CocaColaLogoIcon />,
  },
  {
    id: 5,
    icon: <AtsLogoIcon />,
  },
];
