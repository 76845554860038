import React, { FC } from "react";
import styled from "styled-components";

import PricingLeft from "./PricingLeft";
import PricingRight from "./PricingRight";
import { PriceSection } from "../../../types/pricing";

interface PricingProps {
  activeSection: PriceSection;
  setActiveSection: (section: PriceSection) => void;
  handleToggleClick: () => void;
  isToggle: boolean;
  activeGroups: PriceSection[];
}

const Pricing: FC<PricingProps> = ({ activeSection, setActiveSection, handleToggleClick, isToggle, activeGroups }) => {
  return (
    <Wrapper>
      <PricingLeft activeSection={activeSection} setActiveSection={setActiveSection} activeGroups={activeGroups} />
      <PricingRight activeSection={activeSection} handleToggleClick={handleToggleClick} isToggle={isToggle} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
  width: 100%;
  max-width: 1014px;
  margin: 40px auto 0;

  @media (max-width: 768px) {
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    margin-top: 24px;
  }
`;

export default Pricing;
