import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  src: string;
  handleClick: () => void;
}

const FontImage = ({ handleClick, src }: Props) => {
  const [isValid, setIsValid] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Wrapper isValid={isValid} isLoaded={isLoaded}>
      <LazyLoadImage
        src={src}
        onError={() => setIsValid(false)}
        onClick={handleClick}
        onLoadCapture={() => setIsLoaded(true)}
      />
      {!isLoaded && <Cover width={Math.round(Math.random() * 50 + 40)} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isValid: boolean; isLoaded: boolean }>`
  position: relative;
  display: ${({ isValid }) => (isValid ? "block" : "none")};
  padding: 0 10px;

  & img {
    height: 20px;
    width: fit-content;
    cursor: pointer;
    visibility: ${({ isLoaded }) => (isLoaded ? "visible" : "hidden")};
  }
`;

const blink = keyframes`
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`;

const Cover = styled.div<{ width: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => width}%;
  height: 20px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.11);
  animation: ${blink} 2s linear infinite;
  border-radius: 6px;
`;

export default FontImage;
