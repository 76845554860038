export const NvidiaLogoWhite = () => (
  <svg width="139" height="46" viewBox="0 0 139 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7321_27953)">
      <path
        d="M136.259 30.0753V29.6974H136.502C136.639 29.6974 136.821 29.708 136.821 29.8689C136.821 30.0435 136.715 30.0753 136.563 30.0753H136.259ZM136.259 30.341H136.426L136.791 31.0013H137.201L136.791 30.3137C137.003 30.2985 137.185 30.1953 137.185 29.9053C137.185 29.5456 136.943 29.4302 136.518 29.4302H135.91V31.0013H136.259V30.341ZM138.02 30.218C138.02 29.2951 137.307 28.7593 136.502 28.7593C135.698 28.7593 134.984 29.2951 134.984 30.218C134.984 31.141 135.698 31.6783 136.502 31.6783C137.307 31.6783 138.02 31.141 138.02 30.218ZM137.595 30.218C137.595 30.8905 137.094 31.3413 136.502 31.3413V31.3368C135.91 31.3413 135.425 30.8905 135.425 30.218C135.425 29.5456 135.91 29.0963 136.502 29.0963C137.094 29.0963 137.595 29.5456 137.595 30.218Z"
        fill="white"
      />
      <path
        d="M82.3801 14.5982L82.3816 31.1622H87.06V14.5982H82.3801ZM45.5801 14.5754V31.1622H50.2994V18.5677L53.9562 18.5692C55.1675 18.5692 56.0297 18.8713 56.6142 19.4936C57.3534 20.2815 57.6555 21.5535 57.6555 23.879V31.1622H62.2276V21.9983C62.2276 15.4574 58.0593 14.5754 53.9805 14.5754H45.5801ZM89.9122 14.5997V31.1622H97.499C101.541 31.1622 102.86 30.4898 104.289 28.9824C105.297 27.9244 105.948 25.6019 105.948 23.0624C105.948 20.7353 105.397 18.6572 104.435 17.3639C102.701 15.0506 100.204 14.5997 96.4774 14.5997H89.9122ZM94.5526 18.2049H96.564C99.4815 18.2049 101.368 19.5164 101.368 22.9151C101.368 26.3169 99.4815 27.6269 96.564 27.6269H94.5526V18.2049ZM75.6358 14.5997L71.7316 27.7256L67.9898 14.5997H62.941L68.2828 31.1622H75.0256L80.4098 14.5997H75.6358ZM108.125 31.1622H112.803V14.5997H108.125V31.1622ZM121.238 14.6043L114.707 31.1562H119.32L120.354 28.2311H128.077L129.064 31.1562H134.073L127.485 14.6043H121.238ZM124.276 17.625L127.105 25.3788H121.352L124.276 17.625Z"
        fill="white"
      />
      <path
        d="M15.6221 17.8533V15.4897C15.8511 15.4735 16.0828 15.4609 16.3189 15.4537C22.7822 15.2508 27.0216 21.0074 27.0216 21.0074C27.0216 21.0074 22.4424 27.3679 17.5323 27.3679C16.8255 27.3679 16.1918 27.2534 15.6221 27.0623V19.8968C18.138 20.2006 18.6437 21.3121 20.1563 23.8334L23.5205 20.9966C23.5205 20.9966 21.065 17.7758 16.9256 17.7758C16.4749 17.7758 16.044 17.8073 15.6221 17.8533ZM15.6221 10.0469V13.5769C15.8538 13.558 16.0864 13.5435 16.3189 13.5354C25.3071 13.2325 31.1619 20.9055 31.1619 20.9055C31.1619 20.9055 24.4363 29.0842 17.4295 29.0842C16.7877 29.0842 16.1864 29.0247 15.6221 28.9247V31.107C16.1044 31.1683 16.6056 31.2044 17.1275 31.2044C23.6476 31.2044 28.363 27.8745 32.9296 23.9334C33.6859 24.5401 36.7851 26.014 37.4233 26.6603C33.0811 30.2949 22.9634 33.2245 17.2276 33.2245C16.675 33.2245 16.1432 33.1912 15.6221 33.1407V36.2073H40.4043V10.0469H15.6221ZM15.6221 27.0623V28.9247C9.59065 27.8502 7.9167 21.5807 7.9167 21.5807C7.9167 21.5807 10.813 18.3725 15.6221 17.8533V19.8968C15.6176 19.8968 15.6158 19.8959 15.6122 19.8959C13.0891 19.5931 11.1168 21.9503 11.1168 21.9503C11.1168 21.9503 12.2219 25.9202 15.6221 27.0623ZM4.91042 21.3094C4.91042 21.3094 8.48459 16.0351 15.6221 15.4897V13.5769C7.71657 14.2106 0.871094 20.9055 0.871094 20.9055C0.871094 20.9055 4.74816 32.1148 15.6221 33.1407V31.107C7.64265 30.1028 4.91042 21.3094 4.91042 21.3094Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7321_27953">
        <rect width="137.15" height="45.7167" fill="white" transform="translate(0.875 0.141602)" />
      </clipPath>
    </defs>
  </svg>
);
