import styled from "styled-components";
import { Capterra, Star } from "../../../components/Icons/Icons";

interface RatingProps {
  icon?: JSX.Element;
  rating?: string;
  basePage?: boolean;
}
const RatingCard = ({ icon, rating, basePage }: RatingProps) => {
  return (
    <Wrapper basePage={basePage}>
      <IconWrapper basePage={basePage}>{icon || <Capterra />}</IconWrapper>
      <StarWrapper>
        {[1, 2, 3, 4, 5].map((i) => (
          <Star key={i} />
        ))}
      </StarWrapper>
      {basePage ? <BaseNumber>{rating || 4.6}</BaseNumber> : <Number>{rating || 4.6}</Number>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ basePage?: boolean }>`
  width: max-content;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${({ basePage }) =>
    basePage ? "linear-gradient(142deg, rgba(0, 99, 180, 0.04) 17%, rgba(0, 154, 247, 0.04) 86%)" : "#216ba8"};
`;

const IconWrapper = styled.div<{ basePage?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg > path {
    :last-of-type {
      fill: ${({ basePage }) => (basePage ? "url(#paint0_linear_4960_5816)" : "#ffffff")};
    }
  }
`;

const StarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Number = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
`;

const BaseNumber = styled.span`
  background: linear-gradient(142deg, #0063b4 17%, #009af7 86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
`;

export default RatingCard;
