import React, { useEffect, useState } from "react";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import styled from "styled-components";
import {
  LineHeightIcon,
  TextAlignCenterActiveIcon,
  TextAlignCenterIcon,
  TextAlignLeftActiveIcon,
  TextAlignLeftIcon,
  TextAlignRightActiveIcon,
  TextAlignRightIcon,
} from "../../../../../components/Icons/Icons";
import { Text } from "../../../../../types/scene";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

enum TextAlignType {
  left = "left",
  center = "center",
  right = "right",
}

const getTextAlignIcon = {
  [TextAlignType.left]: {
    default: <TextAlignLeftIcon />,
    active: <TextAlignLeftActiveIcon />,
  },
  [TextAlignType.center]: {
    default: <TextAlignCenterIcon />,
    active: <TextAlignCenterActiveIcon />,
  },
  [TextAlignType.right]: {
    default: <TextAlignRightIcon />,
    active: <TextAlignRightActiveIcon />,
  },
};

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Text;
}

const TextAlign = ({ handleSceneObjectChange, currentObject }: Props) => {
  const theme = useSelector(getTheme);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(TextAlignType.left);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleChangeActive = (value: TextAlignType) => {
    if (!currentObject) return;
    handleSceneObjectChange("style", { ...currentObject.style, textAlign: value }, currentObject.id);
    setActive(value);
  };

  useEffect(() => {
    if (!currentObject) return setActive(TextAlignType.left);
    setActive(currentObject.style.textAlign as any);
  }, [currentObject]);

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper isActive={isOpen}>
            <IconButton onClick={handleOpen}>{getTextAlignIcon[active].active}</IconButton>
            {isOpen && <ActiveBackground />}
            <Tooltip
              text="Alignment"
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
              reverseColor
              arrow
            />
          </ButtonWrapper>
        }
      >
        {Object.keys(TextAlignType).map((key) => (
          <MenuListItem key={key} isActive={key === active} onClick={() => handleChangeActive(key as TextAlignType)}>
            {active === key
              ? getTextAlignIcon[key as TextAlignType].active
              : getTextAlignIcon[key as TextAlignType].default}
          </MenuListItem>
        ))}
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div<{ isActive: boolean }>`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const IconButton = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  border-radius: 4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const ActiveBackground = styled.div`
  background: linear-gradient(to bottom right, #0063b4 0%, #009af7 100%);
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  border-radius: 4px;
`;

const MenuListItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  border-radius: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  ${({ isActive, theme }) =>
    !isActive &&
    `
      svg > path {
        fill: ${theme.icon};
      }
    `}
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(10px)",
  left: "0",
  border: "none",
  padding: "12px 16px",
  borderRadius: "12px",
  width: "max-content",
  display: "flex",
  alignItems: "center",
  columnGap: "8px",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default TextAlign;
