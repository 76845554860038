export const OpacityActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.07 4.93L4.92999 19.07C3.11999 17.26 2 14.76 2 12C2 6.48 6.48 2 12 2C14.76 2 17.26 3.12 19.07 4.93Z"
      stroke="url(#paint0_linear_1_16802)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9999 12.0002C21.9999 17.5202 17.5199 22.0002 11.9999 22.0002C9.23994 22.0002 6.73993 20.8802 4.92993 19.0702L19.0699 4.93018C20.8799 6.74018 21.9999 9.24018 21.9999 12.0002Z"
      stroke="url(#paint1_linear_1_16802)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.76001 16.2402L13.41 21.9002"
      stroke="url(#paint2_linear_1_16802)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.29 12.71L17.8701 19.28"
      stroke="url(#paint3_linear_1_16802)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8301 9.16992L21.1201 15.4699"
      stroke="url(#paint4_linear_1_16802)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_16802"
        x1="5.67662"
        y1="4.23223"
        x2="15.7873"
        y2="17.2317"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_16802"
        x1="8.60655"
        y1="7.16241"
        x2="18.7172"
        y2="20.1619"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_16802"
        x1="8.97693"
        y1="16.9804"
        x2="12.3308"
        y2="21.2849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_16802"
        x1="12.7073"
        y1="13.5691"
        x2="16.5973"
        y2="18.5782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_16802"
        x1="16.1848"
        y1="9.99377"
        x2="19.9178"
        y2="14.7857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
