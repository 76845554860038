import React from "react";
import styled from "styled-components";
import { ShapeBorder } from "../../types/scene";

interface Props {
  background: string;
  opacity: number;
  border: ShapeBorder;
}

export const Star = ({ background, opacity, border }: Props) => (
  <StyledDiv background={background} opacity={opacity} className={"shape0"} border={border} />
);

const StyledDiv = styled.div<{ background: string; opacity: number; border: ShapeBorder }>`
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0%, 61% 35%, 100% 35%, 68% 57%, 79% 100%, 50% 70%, 21% 100%, 32% 57%, 0% 35%, 39% 35%);
  opacity: ${({ opacity }) => opacity};
  background: ${({ border }) => border.borderColor};

  &:before {
    content: "''";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    clip-path: polygon(50% 0%, 61% 35%, 100% 35%, 68% 57%, 79% 100%, 50% 70%, 21% 100%, 32% 57%, 0% 35%, 39% 35%);
    width: calc(100% - ${({ border }) => border.borderWidth * 2}px);
    height: calc(100% - ${({ border }) => border.borderWidth * 2}px);
    background: ${({ background }) => background};
    z-index: 1;
  }
`;
