import React from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { UndoIcon } from "../../../components/Icons/Icons";

interface Props {
  back: () => void;
  undo: () => void;
  currentHistoryIndex: number;
  historyLength: number;
}

const ManageChanges = ({ back, undo, currentHistoryIndex, historyLength }: Props) => (
  <Wrapper onClick={(event) => event.stopPropagation()}>
    <ButtonWrapper>
      <IconButtonWrapper isActive={historyLength > 1 && currentHistoryIndex !== 0}>
        <IconButton icon={<UndoIcon />} iconButtonTheme={IconButtonThemes.Transparent} onClick={back} />
      </IconButtonWrapper>
      <IconButtonWrapper isActive={currentHistoryIndex < historyLength - 1 && currentHistoryIndex !== -1}>
        <IconButton icon={<UndoIcon />} iconButtonTheme={IconButtonThemes.Transparent} onClick={undo} />
      </IconButtonWrapper>
    </ButtonWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  span {
    font-family: "Mulish";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  div:last-of-type > button {
    transform: scaleX(-1);
  }
`;

const IconButtonWrapper = styled.div<{ isActive: boolean }>`
  ${({ isActive }) =>
    !isActive
      ? `
      button {
        cursor: not-allowed;
        opacity: 0.4;
      }`
      : ``}
`;

export default ManageChanges;
