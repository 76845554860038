import Select, { components } from "react-select";
import styled, { useTheme } from "styled-components";
import { FC, ReactNode, useState } from "react";

import Voice from "./Voice";
import RadioButton from "../../../components/RadioButton/RadioButton";

interface OptionType {
  actorid: number;
  Name: string;
  src?: string;
}

interface Props {
  optionsList: OptionType[];
  value: string | number;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  actionIcon?: ReactNode;
}

const SelectVoice: FC<Props> = ({ optionsList, onChange, value, disabled, actionIcon }) => {
  const theme: any = useTheme();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [activePlaying, setActivePlaying] = useState<number>();

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "none",
      border: theme.videoTranslationBorder,
      backgroundColor: theme.videoTranslationBackground,
      height: "48px",
      cursor: "pointer",

      "&:hover": {
        border: theme.videoTranslationBorder,
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: "16px",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      paddingRight: "16px",

      div: {
        padding: 0,
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      svg: {
        transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s",
        path: {
          stroke: theme.icon,
        },
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: "none",
      borderRadius: "12px",
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderRadius: "12px",
      maxHeight: "125px",
      border: theme.videoTranslationBorder,
      backgroundColor: theme.videoTranslationBackground,
      boxShadow: "none",
      paddingBottom: "2px",
      paddingTop: "2px",

      "@media (max-width: 1001px)": {
        "::-webkit-scrollbar": {
          display: "none",
        },
      },

      "::-webkit-scrollbar": {
        width: "2px",
      },

      "::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.activeMenu,
      },

      "::-webkit-scrollbar-track": {
        margin: "15px 0",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: theme.primaryText,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: theme.primaryText,
      opacity: 0.6,
    }),
    input: (provided: any) => ({
      ...provided,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      color: theme.primaryText,
    }),
    option: (provided: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      backgroundColor: "transparent",
      color: theme.primaryText,
      padding: "8px 16px",

      "&:active": {
        backgroundColor: "transparent",
        color: theme.activeMenu,
      },
    }),
  };

  const formatOptionLabel = (option: OptionType, { context }: { context: string }) => (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      {context === "menu" && option.src ? (
        <Voice
          src={option?.src}
          title={option?.Name}
          active={option?.actorid === activePlaying}
          onClick={() => setActivePlaying(option?.actorid)}
        />
      ) : (
        option.Name
      )}
      {context === "menu" && (
        <RadioButtonWrapper>
          <RadioButton checked={option.actorid === value} onChange={() => onChange(option.actorid)} />
        </RadioButtonWrapper>
      )}
    </div>
  );

  const handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      onChange(selectedOption.actorid);
    }
  };

  const DropdownIndicator = (props: any) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>{actionIcon}</components.DropdownIndicator>
    );

  return (
    <Select
      value={optionsList.find((option) => option.actorid === value)}
      onChange={handleChange}
      options={optionsList}
      isDisabled={disabled}
      isSearchable={false}
      styles={customStyles}
      menuPlacement="auto"
      menuShouldBlockScroll={true}
      menuPortalTarget={document.body}
      formatOptionLabel={formatOptionLabel}
      components={{ DropdownIndicator }}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};

const RadioButtonWrapper = styled.div`
  margin-left: auto;
`;

export default SelectVoice;
