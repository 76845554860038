import React from "react";
import parse from "html-react-parser";
import styled from "styled-components";

interface Props {
  svgData: string;
  background: string;
  opacity: number;
}

const modifySvgColor = (svgString: string, background: string): string => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
  const svgElement = svgDoc.documentElement;

  const childElements = svgElement.children;
  for (let i = 0; i < childElements.length; i++) {
    const child = childElements[i];

    if (child.getAttribute("stroke")) {
      child.setAttribute("stroke", background);
    } else if (!child.getAttribute("fill")) {
      child.setAttribute("fill", background);
    }
  }

  const serializer = new XMLSerializer();
  const modifiedSvgString = serializer.serializeToString(svgDoc);

  return modifiedSvgString;
};

const CustomSvgShape = ({ svgData, background, opacity }: Props) => {
  return (
    <Wrapper background={background} opacity={opacity} className={"shape0"}>
      {parse(modifySvgColor(svgData, background))}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ background: string; opacity: number }>`
  width: 100%;
  height: 100%;
  opacity: ${({ opacity }) => opacity};

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export default CustomSvgShape;
