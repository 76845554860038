import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeSubscriptionUpdated,
  getChangeSubscriptionsServer,
  getUserPlansServer,
  updateChangeSubscriptions,
  updateChangeSubscriptionsLoading,
  updateSubscriptionServer,
} from "../../redux/actions/plansActions";
import styled from "styled-components";
import PlanCard from "./components/PlanCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useSelector } from "react-redux";
import {
  getChangeSubscriptions,
  getChangeSubscriptionsLoading,
  getIsSubscriptionUpdated,
  getUserPlans,
  getUserPlansLoading,
} from "../../redux/reducers/plansReducer";
import CircularProgress from "../../components/Icons/CircularProgress";
import ChangePlanCard from "./components/ChangePlanCard";
import Button, { ButtonThemes } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { pages } from "../../lib/routeUtils";
import BundlePackageCard from "./components/BundlePackageCard";
import { getProfile } from "../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";

enum PlansTab {
  myPlan,
  changePlan,
}

const DefaultUserPlans = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const plans = useSelector(getUserPlans);

  const plansLoading = useSelector(getUserPlansLoading);
  const isSubscriptionUpdated = useSelector(getIsSubscriptionUpdated);
  const profile = useSelector(getProfile);

  const changeSubscriptions = useSelector(getChangeSubscriptions);
  const changeSubscriptionsLoading = useSelector(getChangeSubscriptionsLoading);

  useEffect(() => {
    dispatch(getUserPlansServer());
  }, []);

  useEffect(() => {
    if (!plans.length) return;
    dispatch(getChangeSubscriptionsServer({ userLicenseId: plans[0].userLicenseID }));
  }, [plans]);

  useEffect(() => {
    if (!isSubscriptionUpdated) return;
    navigate(pages.main());
    dispatch(changeSubscriptionUpdated({ status: false }));
  }, [isSubscriptionUpdated]);

  const activePlans = plans.filter((plan) => !plan.isCancelled);

  const bundlePlans = activePlans.filter((item) => item.productName.toLowerCase().includes("business"));

  const displayBundlePackageCard = !bundlePlans.length && !!activePlans.length;

  const handleUpdateMethodOpen = () => dispatch(updatePopup({ popup: Popups.paymentMethodPKSPopup, status: true }));

  return (
    <Wrapper>
      <MobileOnly>
        <Sidebar mobile />
      </MobileOnly>
      <Top>
        <Title>My plan</Title>
        {!!activePlans.length && <UpdatePayment onClick={handleUpdateMethodOpen}>Update Payment Method</UpdatePayment>}
      </Top>
      {plansLoading || changeSubscriptionsLoading ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : plans.length ? (
        <MySubscriptionsWrapper inRow={!displayBundlePackageCard}>
          <MyPlansCards inRow={!displayBundlePackageCard}>
            {plans.map((plan) => (
              <PlanCard plan={plan} key={plan.invoiceID} />
            ))}
          </MyPlansCards>
          {displayBundlePackageCard && <BundlePackageCard userPLans={plans} subscriptions={changeSubscriptions} />}
        </MySubscriptionsWrapper>
      ) : profile.hidePricing ? (
        <EmptyCase>There are no subscription plans.</EmptyCase>
      ) : (
        <EmptyCase>
          There are no subscription plans. For more information on our pricing,{" "}
          <StyledLink to={pages.pricingSubscriptions()}>click here</StyledLink>.
        </EmptyCase>
      )}
    </Wrapper>
  );
};

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  column-gap: 285px;

  & button {
    width: fit-content;
    padding: 2px 16px;
  }

  @media (max-width: 1001px) {
    justify-content: space-between;
    column-gap: 20px;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-family: Mulish;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
`;

const UpdatePayment = styled.div`
  cursor: pointer;
  min-width: 75px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const BackButton = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  height: 100%;
`;

const ChangePlanCardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    width: calc(50% - 10px);
  }

  @media (max-width: 768px) {
    & > div {
      width: 100%;
    }
  }
`;

const MySubscriptionsWrapper = styled.div<{ inRow: boolean }>`
  display: flex;
  column-gap: 24px;

  ${({ inRow }) =>
    inRow
      ? ``
      : `
  & > div {
    width: calc(50% - 12px);
  }
  `}
`;

const MyPlansCards = styled.div<{ inRow: boolean }>`
  display: flex;
  ${({ inRow }) =>
    inRow
      ? `
      row-gap: 13px;
      column-gap: 24px;
      width: 100%;

      & > div {
        width: calc(50% - 12px);
      }
      flex-wrap: wrap;
      `
      : `
      flex-direction: column;
      row-gap: 13px;
      `}
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & path {
    fill: ${({ theme }) => theme.activeMenu};
  }
`;

const EmptyCase = styled.div`
  text-align: center;
  margin-top: 40px;
  color: ${({ theme }) => theme.primaryText};
`;

const StyledLink = styled(Link)`
  color: #009af7;
  text-decoration: underline;
`;

export default DefaultUserPlans;
