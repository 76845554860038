import { StoreType } from "../../types/store";
import { GET_TRANSITIONS_SERVER, UPDATE_TRANSITION_LOADING } from "../actions/transitionAction";

export interface transitionStateType {
  data: [];
  isLoading: boolean;
}

const transitionInitialState: transitionStateType = {
  data: [],
  isLoading: false,
};

const transitionsReducer = (state = transitionInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_TRANSITION_LOADING: {
      const { isLoading } = action.payload;
      return { ...state, isLoading };
    }

    case `${GET_TRANSITIONS_SERVER}_SUCCESS`: {
      return {
        ...state,
        data: action.payload.data.data,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export const getTransitions = (state: StoreType) => state.transition.data;
export const getTransitionsLoading = (state: StoreType) => state.transition.isLoading;

export default transitionsReducer;
