import React from "react";
import styled from "styled-components";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { EditVideoIcon } from "../../../components/Icons/EditVideoIcon";
import { TrashIcon } from "../../../components/Icons/TrashIcon";
import Switch from "../../../components/Switch/Switch";

interface Props {
  handleDeleteClick: (e: any) => void;
}

const Settings = ({ handleDeleteClick }: Props) => {
  return (
    <Wrapper>
      <Top>
        <Title>Settings</Title>
        <Actions>
          {/* <StyledButton text="Edit as new" buttonTheme={ButtonThemes.Secondary} icon={<EditVideoIcon />} /> */}
          <StyledButton
            text="Trash"
            buttonTheme={ButtonThemes.Secondary}
            icon={<TrashIcon />}
            onClick={(e) => handleDeleteClick(e)}
          />
        </Actions>
      </Top>
      {/* <PublicVideoWrapper>
        <SetPublicText>Set Video as Public</SetPublicText>
        <Switch />
      </PublicVideoWrapper> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-width: 272px;
  flex-direction: column;
  row-gap: 24px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 14px 24px;
  height: 48px;
  border-radius: 12px;
  justify-content: flex-start;
`;

const PublicVideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & label {
    height: 20px;
    width: 34px;
    min-width: 34px;
  }

  & .slider-range:before {
    width: 16px;
    height: 16px;
  }
`;

const SetPublicText = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export default Settings;
