import React from "react";

export const PlusIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg ">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 9.16797H10.8334V7.5013C10.8334 7.04297 10.4584 6.66797 10 6.66797C9.54169 6.66797 9.16669 7.04297 9.16669 7.5013V9.16797H7.50002C7.04169 9.16797 6.66669 9.54297 6.66669 10.0013C6.66669 10.4596 7.04169 10.8346 7.50002 10.8346H9.16669V12.5013C9.16669 12.9596 9.54169 13.3346 10 13.3346C10.4584 13.3346 10.8334 12.9596 10.8334 12.5013V10.8346H12.5C12.9584 10.8346 13.3334 10.4596 13.3334 10.0013C13.3334 9.54297 12.9584 9.16797 12.5 9.16797Z"
      fill="white"
    />
    <mask id="mask0_1_6676" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="6" y="6" width="8" height="8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 9.16797H10.8334V7.5013C10.8334 7.04297 10.4584 6.66797 10 6.66797C9.54169 6.66797 9.16669 7.04297 9.16669 7.5013V9.16797H7.50002C7.04169 9.16797 6.66669 9.54297 6.66669 10.0013C6.66669 10.4596 7.04169 10.8346 7.50002 10.8346H9.16669V12.5013C9.16669 12.9596 9.54169 13.3346 10 13.3346C10.4584 13.3346 10.8334 12.9596 10.8334 12.5013V10.8346H12.5C12.9584 10.8346 13.3334 10.4596 13.3334 10.0013C13.3334 9.54297 12.9584 9.16797 12.5 9.16797Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_6676)"></g>
  </svg>
);

export const PlusIconGrey = () => <img className="plusIcon" src="/images/PlusCircleIcon.png" alt="plus circle icon" />;
