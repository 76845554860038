export const DETECT_LANGUAGE = "DETECT_LANGUAGE";
export const GET_ALL_SUPPORTED_LANGUAGES = "GET_ALL_SUPPORTED_LANGUAGES";
export const TRANSLATE_TEXT = "TRANSLATE_TEXT";
export const UPDATE_LANGUAGE_LOADING = "UPDATE_LANGUAGE_LOADING";

interface DetectLanguageServerProps {
  textInput: string;
}

interface TranslateTextServerProps {
  textData: string[];
  targetLanguage: string;
  sourceLanguage: string;
}

interface UpdateLanguageLoadingProps {
  isLoading: boolean;
}

export const detectLanguageServer = ({ textInput }: DetectLanguageServerProps) => ({
  type: DETECT_LANGUAGE,
  payload: {
    request: {
      method: "GET",
      url: `/project/detectLanguage?textInput=${textInput}`,
    },
  },
});

export const getAllSupportedLanguagesServer = () => ({
  type: GET_ALL_SUPPORTED_LANGUAGES,
  payload: {
    request: {
      method: "GET",
      url: `/project/getAllSupportedLanguages`,
    },
  },
});

export const translateTextServer = ({ textData, targetLanguage, sourceLanguage }: TranslateTextServerProps) => ({
  type: TRANSLATE_TEXT,
  payload: {
    request: {
      method: "POST",
      url: `/project/translateText`,
      data: {
        textData,
        targetLanguage,
        sourceLanguage,
      },
    },
  },
});

export const updateLanguageLoading = ({ isLoading }: UpdateLanguageLoadingProps) => ({
  type: UPDATE_LANGUAGE_LOADING,
  payload: {
    isLoading,
  },
});
