import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import ProgressBar from "../ProgressBar/ProgressBar";
import Button, { ButtonThemes } from "../Button/Button";
import { TrimImagePrefilled } from "../../types/image";
import { getAssets } from "../../redux/reducers/assetReducer";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { Asset, createUserAssetServer } from "../../redux/actions/assetAction";

interface Props {
  open: boolean;
  onClose: () => void;
}

const TrimImaegePopup = ({ open, onClose }: Props) => {
  const isFirstRender = useRef(true);
  const editorRef = useRef<any>(null);

  const dispatch = useDispatch();

  const { file, handleSelectImage }: TrimImagePrefilled = useSelector(getPrefilled);
  const [scale, setScale] = useState<number>(0);

  const assets = useSelector(getAssets);
  const isLoading = assets[Asset.InPaintingVisualAssets].uploadLoading;
  const uploadedImage = assets[Asset.InPaintingVisualAssets].latestUpload;

  const handleScaleChange = (e: any) => setScale(e.target.value);

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const handleUploadSubmit = () => {
    if (!editorRef.current) return;
    const canvasScaled = editorRef.current?.getImageScaledToCanvas().toDataURL();

    const formData = new FormData();
    formData.append("AssetTypeId", "8");
    formData.append("Status", "1");
    formData.append("FileName", file.name);
    formData.append("Base64Image", canvasScaled);

    dispatch(
      createUserAssetServer({
        data: formData,
        assetTypeId: Asset.InPaintingVisualAssets,
      }),
    );
  };

  useEffect(() => {
    if (!isFirstRender.current && !isLoading) {
      handleClose();

      if (uploadedImage) handleSelectImage(uploadedImage);
    }

    isFirstRender.current = false;
  }, [isLoading]);

  return (
    <Wrapper>
      <Modal className="trim-image" open={open} onClose={handleClose} maxWidth={420} maxHeight={704}>
        <InnerWrapper>
          <AvatarEditor
            ref={editorRef}
            image={file}
            style={{ width: "100%", height: "100%", maxHeight: "512px" }}
            width={512}
            height={512}
            border={50}
            color={[0, 0, 0, 0.7]}
            scale={scale == 0 ? 1 : scale / 10}
            rotate={0}
          />
          <ProgressBar
            title=""
            value={scale}
            onChange={isLoading ? () => null : handleScaleChange}
            activeBarLabel="Scale"
            step={10}
          />
          <Button
            disabled={isLoading}
            text={isLoading ? "Loading..." : "Upload"}
            onClick={isLoading ? () => null : handleUploadSubmit}
            buttonTheme={ButtonThemes.Primary}
          />
        </InnerWrapper>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-close-icon {
    @media (max-width: 735px), (max-height: 800px), (max-width: 949px) and (max-height: 500px) {
      top: 15px;
      right: 15px;
    }
    @media (max-width: 949px) and (max-height: 860px) {
      top: 15px;
      right: 15px;
    }
    @media (max-width: 949px) and (max-height: 660px) {
      top: 12px;
      right: 12px;
    }
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding-top: 20px;
`;

export default TrimImaegePopup;
