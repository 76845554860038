import { useContext, useState } from "react";
import styled from "styled-components";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import Tabs from "../../modules/AIImages/components/Tabs";
import DrawingCursor from "../DrawingCursor/DrawingCursor";
import useCanvasDrawing from "../../hooks/useCanvasDrawing";
import {
  BrushMinusIcon,
  BrushPlusIcon,
  Eraser,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  PopupBrush,
  PopupNextIcon,
  PopupPrevIcon,
} from "../Icons/Icons";
import { AIImagesContext } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { inPaintRenderServer } from "../../redux/actions/imagesActions";
import { getInPaintGenerationLoading } from "../../redux/reducers/imagesReducer";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import IconButton, { IconButtonThemes } from "../Button/IconButton";

const MIN_BRUSH_SIZE = 1;
const MAX_BRUSH_SIZE = 50;

interface Props {
  open: boolean;
  onClose: () => void;
}

const brushColor = "#fff";
const canvasElementId = "drawing-canvas";

const RemoveAreaPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const { isEdit = false } = useSelector(getPrefilled);
  const isLoading = useSelector(getInPaintGenerationLoading);

  const [brushSize, setBrushSize] = useState<number>(25);
  const [scaleRatio, setScaleRatio] = useState<number>(1);

  const { text, inpaintImage: image } = useContext(AIImagesContext);
  const { canvasRef, isEraseMode, switchMode, undo, redo, isUndoDisabled, isRedoDisabled, getImageBase64 } =
    useCanvasDrawing({
      brushOptions: { sizePx: brushSize, color: brushColor },
      bgImageOptions: { url: image, maxAllowedWidthPx: 759, maxAllowedHeightPx: 540 },
    });

  const activeTab = isEraseMode ? tabs[0].label : tabs[1].label;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setBrushSize(newValue);
  };

  const brushSizeMinus = () => {
    if (brushSize <= MIN_BRUSH_SIZE) return;
    setBrushSize((prev) => prev - 1);
  };

  const brushSizePlus = () => {
    if (brushSize >= MAX_BRUSH_SIZE) return;
    setBrushSize((prev) => prev + 1);
  };

  const handleActive = (tabId: string) => {
    if (tabId !== activeTab) switchMode();
  };

  const handleVisualise = () => {
    const base64 = getImageBase64();
    const formData = new FormData();
    formData.append("TextInput", text);
    formData.append("pngData", base64);
    formData.append("inPaint", image);

    dispatch(inPaintRenderServer(formData, isEdit));
  };

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const handleBack = () => {
    handleClose();
    // dispatch(
    //   updatePopup({
    //     popup: Popups.uploadAIImageAssetPopup,
    //     status: true,
    //   }),
    // );
  };

  return (
    <Modal maxWidth={1178} maxHeight={656} onClose={handleClose} open={open} className="ai-images-removearea">
      <OuterWrapper>
        <RemoveAreaWrapper>
          <TopContent>
            <BackButton>
              <Button
                text="Back"
                buttonTheme={ButtonThemes.Secondary}
                icon={<img src="/images/arrow-left.svg" />}
                onClick={handleBack}
              />
            </BackButton>
            <TitleWrapper>
              <span>Remove area</span>
              <IconWrapper>
                <div
                  onClick={!isLoading && !isUndoDisabled ? undo : undefined}
                  style={{ opacity: !isUndoDisabled ? 1 : 0.4 }}
                >
                  <PopupPrevIcon />
                </div>
                <div
                  onClick={!isLoading && !isRedoDisabled ? redo : undefined}
                  style={{ opacity: !isRedoDisabled ? 1 : 0.4 }}
                >
                  <PopupNextIcon />
                </div>
              </IconWrapper>
            </TitleWrapper>
            <EditWrapper>
              <TabsWrapper>
                <Tabs disabled={isLoading} data={tabs} active={activeTab} handleActive={handleActive} />
              </TabsWrapper>
              <EditTextWrapper>
                <span>Brush size</span>
                <span>{brushSize} px</span>
              </EditTextWrapper>
              <SliderWrapper>
                <IconButton
                  iconButtonTheme={IconButtonThemes.Transparent}
                  icon={<BrushMinusIcon />}
                  onClick={brushSizeMinus}
                />
                <StyledSlider
                  aria-label="Slider"
                  type="range"
                  value={brushSize}
                  min={MIN_BRUSH_SIZE}
                  max={MAX_BRUSH_SIZE}
                  onChange={handleChange}
                />
                <IconButton
                  iconButtonTheme={IconButtonThemes.Transparent}
                  icon={<BrushPlusIcon />}
                  onClick={brushSizePlus}
                />
              </SliderWrapper>
            </EditWrapper>
          </TopContent>
          <BottomContent>
            <ButtonWrapper>
              <Button text={isLoading ? "Loading..." : "Visualise"} onClick={handleVisualise} disabled={isLoading} />
              <CancelButtonWrapper>
                <Button text="Cancel" buttonTheme={ButtonThemes.Secondary} onClick={handleClose} />
              </CancelButtonWrapper>
            </ButtonWrapper>
          </BottomContent>
        </RemoveAreaWrapper>
        <TransformWrapper
          minScale={1}
          maxScale={5}
          wheel={{ step: 0.1 }}
          panning={{ disabled: true }}
          doubleClick={{ disabled: true }}
          disablePadding
          onTransformed={(_ref, values) => setScaleRatio(values.scale)}
          disabled={!image}
        >
          {({ zoomIn, zoomOut }) => (
            <PictureWrapper>
              {isLoading && (
                <Blur>
                  <CircularProgress color="#009af7" />
                </Blur>
              )}
              <CanvasWrapper>
                <TransformComponent>
                  <canvas id={canvasElementId} ref={canvasRef} />
                  <DrawingCursor targetElId={canvasElementId} cursorSizePx={brushSize} />
                </TransformComponent>
              </CanvasWrapper>
              <ZoomWrapper>
                <button onClick={() => zoomIn(0.25)}>
                  <MagnifyingGlassPlus />
                </button>
                <span>{Math.round(scaleRatio * 100)}%</span>
                <button onClick={() => zoomOut(0.25)}>
                  <MagnifyingGlassMinus />
                </button>
              </ZoomWrapper>
            </PictureWrapper>
          )}
        </TransformWrapper>
      </OuterWrapper>
    </Modal>
  );
};

const tabs = [
  { label: "Erase", startAdornment: <Eraser /> },
  { label: "Restore", startAdornment: <PopupBrush /> },
];

const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const RemoveAreaWrapper = styled.div`
  max-width: 315px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 24px;
`;

const PictureWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const EditWrapper = styled.div`
  height: 161px;
  width: 100%;
  padding: 24px 16px;
  gap: 16px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 14px 0px 12px 0px;

  span {
    font-family: Mulish;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -1px;
    color: ${({ theme }) => theme.primaryText};
  }
`;

const EditTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0px 0px 0px;

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${({ theme }) => theme.primaryText};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;

  svg {
    cursor: pointer;
  }
`;
const TabsWrapper = styled.div`
  div button svg path {
    stroke: white;
  }
`;
const ButtonWrapper = styled.div``;

const TopContent = styled.div``;

const BottomContent = styled.div``;

const BackButton = styled.div``;

const CancelButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  button span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
`;

const ZoomWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding-top: 12px;

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${({ theme }) => theme.primaryText};
  }

  button {
    appearance: none;
    background: transparent;
    border: none;
    padding: 0;
  }

  svg {
    cursor: pointer;
  }
`;

const CanvasWrapper = styled.div`
  width: 759px;
  height: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  border-radius: 20px;
  z-index: 2;
`;

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 16px;

  button {
    height: auto;
    width: auto;
  }
`;

const StyledSlider = styled.input<{ value: number; min: number; max: number }>`
  flex: 1;
  width: 100%;
  height: 2px;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  position: relative;

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      #0063b4 0%,
      #009af7 ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%,
      ${(props) => props.theme.primaryBackground}
        ${(props) => ((props.value - props.min) / (props.max - props.min)) * 100}%,
      ${(props) => props.theme.primaryBackground} 100%
    );
    height: 2px;
    box-shadow: ${({ theme }) => theme.cardShadow}35;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
    cursor: pointer;
    margin-top: -9px;
  }
`;

export default RemoveAreaPopup;
