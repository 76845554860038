export const CopyIconV3 = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3334 17.2V22.8C21.3334 27.4667 19.4667 29.3333 14.8001 29.3333H9.20008C4.53341 29.3333 2.66675 27.4667 2.66675 22.8V17.2C2.66675 12.5333 4.53341 10.6667 9.20008 10.6667H14.8001C19.4667 10.6667 21.3334 12.5333 21.3334 17.2Z"
      fill="#0063B4"
    />
    <path
      d="M22.7999 2.66667H17.1999C12.9611 2.66667 11.0433 4.21774 10.7265 7.98756C10.6798 8.54252 11.1349 9.00001 11.6918 9.00001H14.7999C20.3999 9.00001 22.9999 11.6 22.9999 17.2V20.3081C22.9999 20.865 23.4574 21.3201 24.0124 21.2735C27.7822 20.9567 29.3333 19.0388 29.3333 14.8V9.20001C29.3333 4.53334 27.4666 2.66667 22.7999 2.66667Z"
      fill="#0063B4"
    />
  </svg>
);
