import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";

import TitleWithAction from "../TitleWithAction";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../../components/Button/IconButton";
import {
  getCurrentPageHumatars,
  getHasMoreHumatars,
  getHumatarList,
  getHumatarListLoading,
  uploadingHumatarLoading,
} from "../../../../redux/reducers/humatarReducer";
import { VideoEditorContext } from "../../../../App";
import { FillLockIcon } from "../../../../components/Icons/LockIcon";
import { PlusIcon, SearchFilterIcon } from "../../../../components/Icons/Icons";
import { Popups, updatePopup } from "../../../../redux/actions/popupsActions";
import { clearHumatars, getHumatarsServer } from "../../../../redux/actions/humatarActions";
import { NotEnoughCredits } from "../../../../types/notEnoughCredits";
import { ActorPositionTypes } from "../../../../types/scene";
import { getProfile } from "../../../../redux/reducers/profileReducer";
import { pages } from "../../../../lib/routeUtils";
import { HumatarProps, humatarTabs } from "../../../../mocks/humans";
import Tabs from "../Tabs";
import { useSearchParams } from "react-router-dom";

const ACCEPTED_FORMATS = "video/mp4, video/quicktime, .mov";

interface Props {
  canvasSize: { width: number; height: number };
}

const HumatarTab = ({ canvasSize }: Props) => {
  const dispatch = useDispatch();
  const { handleAddAvatar } = useContext(VideoEditorContext);
  const data = useSelector(getHumatarList);
  const hasMore = useSelector(getHasMoreHumatars);
  const isLoading = useSelector(getHumatarListLoading);
  const currentPage = useSelector(getCurrentPageHumatars);
  const uploadHumatarLoading = useSelector(uploadingHumatarLoading);

  const [activeTab, setActiveTab] = useState(HumatarProps.ALL_ACTORS);
  const [searchParams] = useSearchParams();

  const selectedTab = activeTab === HumatarProps.ALL_ACTORS ? "Humatars" : activeTab;

  const tab = searchParams.get("tab");

  useEffect(() => {
    if (tab === "custom") {
      setActiveTab(HumatarProps.CUSTOM);
    }
  }, [tab]);

  const handleActiveTab = (tab: string) => {
    if (uploadHumatarLoading) return;

    setActiveTab(tab as HumatarProps);
  };

  const handlePopupOpen = () => {
    dispatch(
      updatePopup({
        popup: Popups.aIHumansPopup,
        status: true,
        prefilled: {
          type: selectedTab,
          setAddAvatar: handleAddAvatar,
        },
      }),
    );
  };

  const handleUpgradePopupOpen = () => {
    const prefilled: NotEnoughCredits = {
      title: "Upgrade the plan to use this humatar",
      description: "Get access to additional premium features and Humatars instantly",
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const fetchMore = () => {
    dispatch(
      getHumatarsServer({
        keyword: "",
        pageNumber: currentPage + 1,
        category: [`${selectedTab}`],
      }),
    );
  };

  const handleAddInstantHumatar = () => {
    dispatch(updatePopup({ popup: Popups.selectTypeCustomAvatarPopup, status: true, prefilled: { noRefresh: false } }));
  };

  useEffect(() => {
    dispatch(clearHumatars());
    dispatch(
      getHumatarsServer({
        keyword: "",
        pageNumber: 1,
        category: [`${selectedTab}`],
      }),
    );
  }, [activeTab]);

  useEffect(() => {
    if (uploadHumatarLoading) {
      setActiveTab(HumatarProps.CUSTOM);
    }
  }, [uploadHumatarLoading]);

  const content = {
    [HumatarProps.ALL_ACTORS]: (
      <ImagesWrapper id="scrollableHumatars">
        <InfiniteScroll
          next={fetchMore}
          hasMore={hasMore}
          loader={
            isLoading ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : null
          }
          dataLength={data?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableHumatars"
        >
          {data &&
            data?.map(({ aiHumanActorId, photo, isLocked, allowCircle, category }, index) => (
              <ImageWrapper key={aiHumanActorId} onClick={isLocked ? handleUpgradePopupOpen : () => null}>
                <img
                  src={photo}
                  onClick={() =>
                    handleAddAvatar(
                      photo,
                      aiHumanActorId,
                      allowCircle,
                      canvasSize,
                      category === "Custom" ? true : false,
                      ActorPositionTypes.FullBody,
                    )
                  }
                />
                {isLocked && (
                  <>
                    <Circle>
                      <FillLockIcon />
                    </Circle>
                    <Blur />
                    <Tooltip
                      className={getStyle(index)}
                      text={
                        <>
                          Unlock the humatar by <b onClick={handleUpgradePopupOpen}>Upgrading the plan</b>
                        </>
                      }
                      position="bottom"
                      reverseColor
                      arrow
                    />
                  </>
                )}
              </ImageWrapper>
            ))}
        </InfiniteScroll>
      </ImagesWrapper>
    ),
    [HumatarProps.SEATED]: (
      <ImagesWrapper id="scrollableSeated">
        <InfiniteScroll
          next={fetchMore}
          hasMore={hasMore}
          loader={
            isLoading ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : null
          }
          dataLength={data?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableSeated"
        >
          {data &&
            data?.map(({ aiHumanActorId, photo, isLocked, allowCircle, category }, index) => (
              <ImageWrapper key={aiHumanActorId} onClick={isLocked ? handleUpgradePopupOpen : () => null}>
                <img
                  src={photo}
                  onClick={() =>
                    handleAddAvatar(
                      photo,
                      aiHumanActorId,
                      allowCircle,
                      canvasSize,
                      category === "Custom" ? true : false,
                      ActorPositionTypes.FullBody,
                    )
                  }
                />
                {isLocked && (
                  <>
                    <Circle>
                      <FillLockIcon />
                    </Circle>
                    <Blur />
                    <Tooltip
                      className={getStyle(index + 1)}
                      text={
                        <>
                          Unlock the humatar by <b onClick={handleUpgradePopupOpen}>Upgrading the plan</b>
                        </>
                      }
                      position="bottom"
                      reverseColor
                      arrow
                    />
                  </>
                )}
              </ImageWrapper>
            ))}
        </InfiniteScroll>
      </ImagesWrapper>
    ),
    [HumatarProps.CUSTOM]: (
      <ImagesWrapper id="scrollableCustom">
        <UploadWrapper onClick={handleAddInstantHumatar}>
          {uploadHumatarLoading ? <CircularProgress color="#009af7" /> : <PlusIcon />}
        </UploadWrapper>
        <InfiniteScroll
          next={fetchMore}
          hasMore={hasMore}
          loader={
            isLoading ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : null
          }
          dataLength={data?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableCustom"
        >
          {data &&
            data?.map(({ aiHumanActorId, photo, isLocked, allowCircle, category }, index) => (
              <ImageWrapper key={aiHumanActorId} onClick={isLocked ? handleUpgradePopupOpen : () => null}>
                <img
                  src={photo}
                  onClick={() =>
                    handleAddAvatar(
                      photo,
                      aiHumanActorId,
                      allowCircle,
                      canvasSize,
                      category === "Custom" ? true : false,
                      ActorPositionTypes.FullBody,
                    )
                  }
                />
                {isLocked && (
                  <>
                    <Circle>
                      <FillLockIcon />
                    </Circle>
                    <Blur />
                    <Tooltip
                      className={getStyle(index + 1)}
                      text={
                        <>
                          Unlock the humatar by <b onClick={handleUpgradePopupOpen}>Upgrading the plan</b>
                        </>
                      }
                      position="bottom"
                      reverseColor
                      arrow
                    />
                  </>
                )}
              </ImageWrapper>
            ))}
        </InfiniteScroll>
      </ImagesWrapper>
    ),
  };

  return (
    <Wrapper>
      <TitleWithAction
        type="humatar"
        title="Choose Actor"
        action={
          <IconButton
            iconButtonTheme={IconButtonThemes.Transparent}
            icon={<SearchFilterIcon />}
            onClick={handlePopupOpen}
          />
        }
      >
        <Tabs data={humatarTabs} active={activeTab} disabled={uploadHumatarLoading} handleActive={handleActiveTab} />
        <Content>{content[activeTab]}</Content>
      </TitleWithAction>
    </Wrapper>
  );
};

const getStyle = (index: number) => {
  if (index % 3 === 0) return "item-1";
  else if (index % 3 === 1) return "item-2";
  else return "item-3";
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 9px;
  border-radius: 20px;
  position: relative;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const ImagesWrapper = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-content: start;
  flex-flow: row wrap;
  row-gap: 8px;
  column-gap: 4px;
  margin-bottom: 24px;
  height: 100%;
  max-height: 100%;
  overflow: hidden auto;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-bottom: 0;
    `}

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 16px;
  background-color: #d6d6d6;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

  & > div:last-of-type {
    width: max-content;
    max-width: 180px;
    z-index: 3;
    box-shadow: none;
    border-radius: 4px;
    padding: 8px 12px;
    top: 100%;
    left: 26%;
    background: ${({ theme }) => theme.white};

    &:after {
      border-color: transparent transparent ${({ theme }) => theme.white};
    }

    span {
      font-size: 12px !important;
      line-height: 16px !important;
      color: ${({ theme }) => theme.darkText} !important;

      b {
        font-weight: 700;
        color: ${({ theme }) => theme.activeMenu};
      }
    }
  }

  .item-1 {
    left: 97px !important;

    &:after {
      left: 13px;
    }
  }

  .item-2 {
    left: 30px !important;

    &:after {
      left: auto;
      right: 95px;
    }
  }

  .item-3 {
    left: -20px !important;

    &:after {
      left: auto;
      right: 43px;
    }
  }

  &:hover > div:last-of-type {
    opacity: 1;
    visibility: visible;
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Circle = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 2;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: rgba(25, 27, 31, 0.68);
`;

const UploadWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 16px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme.activeMenu};

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

export default HumatarTab;
