export const DesktopIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14.2225C20 14.8347 19.552 15.3336 19 15.3336H12.087H11.913H5.00002C4.44802 15.3336 4.00002 14.8347 4.00002 14.2225V5.3336C4.00002 4.72138 4.44802 4.22249 5.00002 4.22249H19C19.552 4.22249 20 4.72138 20 5.3336V14.2225ZM19 2H5C3.346 2 2 3.49556 2 5.33333V14.2222C2 16.06 3.346 17.5556 5 17.5556H11V19.7778H7C6.45 19.7778 6 20.2778 6 20.8889C6 21.5 6.45 22 7 22H17C17.55 22 18 21.5 18 20.8889C18 20.2778 17.55 19.7778 17 19.7778H13V17.5556H19C20.654 17.5556 22 16.06 22 14.2222V5.33333C22 3.49556 20.654 2 19 2Z"
      fill="url(#paint0_linear_1094_9341)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1094_9341"
        x1="6.30769"
        y1="4.61538"
        x2="18.1538"
        y2="19.8462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
