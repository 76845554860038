export const ArrowDown1616 = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.78 5.96667L9.4333 10.3133C8.91997 10.8267 8.07997 10.8267 7.56664 10.3133L3.21997 5.96667"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
