import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { pages } from "../lib/routeUtils";
import { BasePageLoader } from "../components/Icons/Icons";
import { getIsAuthorized } from "../redux/reducers/authReducer";
import { getLoadingPage } from "../redux/reducers/profileReducer";
import DesktopRecommended from "../components/DesktopRecommended/DesktopRecommended";

const withPrivateRoute = (Component: any) => {
  const Auth = (props: any) => {
    const isAuthorized = useSelector(getIsAuthorized);
    const navigate = useNavigate();
    const loadingPage = useSelector(getLoadingPage);

    useEffect(() => {
      if (!isAuthorized) {
        // Navigate.push(pages.signIn());
        navigate(pages.signIn());
      }
    }, [isAuthorized]);

    return isAuthorized ? (
      !loadingPage ? (
        <>
          <DesktopView>
            <Component {...props} />
          </DesktopView>
          <MobileView>
            <DesktopRecommended />
          </MobileView>
        </>
      ) : (
        <CircularProgressWrapper>
          <BasePageLoader />
        </CircularProgressWrapper>
      )
    ) : (
      <Placeholder />
    );
  };

  return Auth;
};

const Placeholder = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  min-height: 100vh;
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.primaryBackground};

  svg {
    height: 100px;
    width: 100px;
  }
`;

const DesktopView = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileView = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    height: 100vh;
  }
`;

export default withPrivateRoute;
