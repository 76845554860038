import styled from "styled-components";

const NotFoundPage = () => {
  return (
    <Page>
      <h1>404</h1>
      <div>
        <h2>This page could not be found.</h2>
      </div>
    </Page>
  );
};

const Page = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  h1 {
    display: inline-block;
    margin: 0 20px 0 0;
    padding: 0 23px 0 0;
    font-size: 24px;
    font-weight: 500;
    vertical-align: top;
    line-height: 49px;
    color: ${({ theme }) => theme.primaryText};
    border-right: ${({ theme }) => theme.videoTranslationBorder};
  }

  div {
    display: inline-block;

    h2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 49px;
      margin: 0;
      color: ${({ theme }) => theme.primaryText};
    }
  }

  @media (max-width: 321px) {
    h1 {
      font-size: 18px;
      margin: 0 14px 0 0;
      padding: 0 17px 0 0;
    }

    div {
      h2 {
        font-size: 12px;
      }
    }
  }
`;

export default NotFoundPage;
