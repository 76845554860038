import React, { useEffect } from "react";
import Button, { Color } from "./Button";
import { PlanTypes } from "./PlanCard";

interface SelectPlanButtonProps {
  popupHash: string;
  buttonColor: string;
  selected?: boolean;
  hasSubscription?: boolean;
  updateSubscription?: () => void;
  planType: PlanTypes;
}
const SelectPlanButton = ({
  popupHash,
  buttonColor,
  selected,
  hasSubscription,
  updateSubscription,
  planType,
}: SelectPlanButtonProps) => {
  const checkPlan = (type: PlanTypes) => {
    switch (type) {
      case "PERSONAL":
        return "Get Personal";
      case "CREATOR":
        return "Get Creator";
      case "BUSINESS UNLIMITED":
        return "Get Business";
      default:
        return "Get Started";
    }
  };

  const buttonTitle = checkPlan(planType);

  useEffect(() => {
    if (popupHash === "") return;
    const hash = popupHash.replace("#pkmodal", "");
    const links = document.getElementsByClassName(`${popupHash}1`);
    if (!links) return;

    Array.from(links).forEach((link) => {
      link.setAttribute("data-pk-is-inited", "1");
      link.setAttribute("href", "javascript:void(0)");
      if (hash) {
        link.setAttribute(
          "onclick",
          `
              const func = async () => {
                return await new Promise((res, rej) => {
                  let counter = 0;
                  const interval = setInterval(() => {
                    if (counter > 100) {
                      clearInterval(interval);
                      rej(new Error("Error!!! Restart the page!"));
                    }
                    const widget = document.getElementById("pk-widget-${hash}");
                    if (!widget) {
                      counter++;
                      return;
                    }
                    clearInterval(interval);
                    res(window.PKWIDGET.openModal(this));
                  }, 100);
                });
              };
              try {
                func();
                window.PKWIDGET.openModal(this);
              } catch (error) {
                console.log(error);
              }
      `,
        );
      } else {
        link.setAttribute("onclick", "return window.PKWIDGET.openModal(this);");
      }
    });
  }, [popupHash]);

  if (!popupHash) return <div />;

  return hasSubscription ? (
    <Button color={buttonColor as Color} disabled={selected} onClick={updateSubscription}>
      {selected ? "Selected" : "Update subscription"}
    </Button>
  ) : (
    <a
      className={popupHash + "1"}
      href={popupHash}
      data-pk-hash={selected ? "" : popupHash.slice(8, popupHash.length + 1)}
    >
      <Button color={buttonColor as Color} disabled={selected}>
        {selected ? "Selected" : (buttonTitle as string)}
      </Button>
    </a>
  );
};

export default SelectPlanButton;
