import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";

import BinIcon from "../../../../../components/Icons/BinIcon";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import Button, { ButtonThemes } from "../../../../../components/Button/Button";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { Avatar } from "../../../../../types/scene";
import { ThemeTypes } from "../../../../../types/theme";
import { VideoEditorContext } from "../../../../../App";
import { useFaceswap } from "../../../../../hooks/useFaceswap";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { getProfile } from "../../../../../redux/reducers/profileReducer";
import { faceswapActorServer } from "../../../../../redux/actions/actorActions";
import { FaceswapIcon, ImportIcon } from "../../../../../components/Icons/Icons";
import { getFaceswapActor, getFaceswapActorLoading } from "../../../../../redux/reducers/actorReducer";

const ACCEPTED_FORMATS = "image/png, image/jpg, image/jpeg";

interface Props {
  currentObject: Avatar;
}

const AvatarFaceswap = ({ currentObject }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const theme = useSelector(getTheme);
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const [isOpen, setIsOpen] = useState(false);
  const [activeFaceswap, setActiveFaceswap] = useState<string>(currentObject.src);
  const { updateFaceImage } = useContext(VideoEditorContext);

  const faceswapActor = useSelector(getFaceswapActor);
  const faceswapActorLoading = useSelector(getFaceswapActorLoading);

  const {
    uploadFaceswapLoading,
    faceswap,
    faceswapLoading,
    faceswapHasMore,
    fetchMore,
    handleUpload,
    handleUserAssetDelete,
  } = useFaceswap({
    isOpen,
    setActiveFaceswap,
    currentObject,
  });

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    if (faceswapActorLoading) return;
    setIsOpen(false);
  };

  const handleActiveFaceSwap = (src: string) => {
    if (faceswapActorLoading) return;
    setActiveFaceswap(src);
  };

  const onSubmit = () => {
    if (!activeFaceswap || activeFaceswap === currentObject.src) {
      return handleClose();
    }

    dispatch(faceswapActorServer({ target: currentObject.src, source: activeFaceswap }));
  };

  useEffect(() => {
    if (!isFirstRender.current && faceswapActor && !faceswapActorLoading) {
      updateFaceImage(faceswapActor);
      handleClose();
    }

    isFirstRender.current = false;
  }, [faceswapActor, faceswapActorLoading]);

  const buttonDisabled = uploadFaceswapLoading || faceswapActorLoading;

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper>
            <IconButton
              disabled={!profile.isFaceswapHumatarEnabled}
              iconButtonTheme={IconButtonThemes.Rounded}
              icon={<FaceswapIcon />}
              onClick={handleOpen}
            />
            <Tooltip
              text={"Faceswap"}
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
              reverseColor
              arrow
            />
            {isOpen && <ActiveBackground />}
          </ButtonWrapper>
        }
      >
        <HeadingWrapper>
          <span>Faceswap</span>
          {/* <IconButton iconButtonTheme={IconButtonThemes.Transparent} icon={<SearchFilterIcon />} /> */}
        </HeadingWrapper>
        <FaceswapWrapper id="faceswapDiv">
          <ImageWrapper active={activeFaceswap === currentObject.src}>
            <img src={currentObject.src} onClick={() => handleActiveFaceSwap(currentObject.src)} />
          </ImageWrapper>
          <InfiniteScroll
            next={fetchMore}
            hasMore={faceswapHasMore}
            loader={
              faceswapLoading ? (
                <ImagesWrapper>
                  <ImageWrapper />
                  <ImageWrapper />
                </ImagesWrapper>
              ) : null
            }
            dataLength={faceswap?.length}
            style={{ display: "contents" }}
            scrollableTarget="faceswapDiv"
          >
            {faceswap &&
              faceswap?.map((data, index: number) => (
                <ImageWrapper key={index} active={activeFaceswap === data.path}>
                  <img src={data.path} onClick={() => handleActiveFaceSwap(data.path)} />
                  {!!data.userId && (
                    <IconButton
                      icon={<BinIcon />}
                      iconButtonTheme={IconButtonThemes.Secondary}
                      onClick={(e: any) => handleUserAssetDelete(e, data.userAssetID)}
                    />
                  )}
                </ImageWrapper>
              ))}
          </InfiniteScroll>
        </FaceswapWrapper>
        <ActionWrapper>
          <Button
            text={faceswapActorLoading ? "Loading..." : "Apply"}
            disabled={faceswapActorLoading}
            onClick={onSubmit}
          />
          {!isGuest && (
            <div>
              <Button
                text={uploadFaceswapLoading ? "Loading..." : "Upload"}
                disabled={buttonDisabled}
                icon={<ImportIcon />}
                buttonTheme={ButtonThemes.Transparent}
              />
              {!buttonDisabled && (
                <input type="file" id="faceswap" name="faceswap" accept={ACCEPTED_FORMATS} onChange={handleUpload} />
              )}
            </div>
          )}
        </ActionWrapper>
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
    }
  }
`;

const ActiveBackground = styled.div`
  background: linear-gradient(to bottom right, #0063b4 0%, #009af7 100%);
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  border-radius: 12px;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryText};
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;

  & > div {
    position: relative;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      svg > path {
        fill: ${({ theme }) => theme.activeMenu};
      }

      span {
        font-weight: 600;
        background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
`;

const FaceswapWrapper = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-content: start;
  flex-flow: row wrap;
  row-gap: 12px;
  column-gap: 8px;
  margin-bottom: 24px;
  height: 100%;
  max-height: 264px;
  overflow: hidden auto;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-bottom: 0;
    `}

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ImageWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 16px;
  background-color: #d6d6d6;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.secondaryText};

  ${({ active, theme }) =>
    active &&
    `
        border: 2px solid ${theme.activeMenu};
    `}

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover > button {
    opacity: 1;
    transition: 0.2s;
  }

  button {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    box-shadow: none;
    opacity: 0;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: red;
      }
    }
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  align-content: start;
  flex-flow: row wrap;
  row-gap: 12px;
  column-gap: 8px;

  & > div {
    background: linear-gradient(-90deg, #e9e9e9, #d6d6d6);
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(25px)",
  left: "0",
  border: "none",
  padding: "24px",
  width: "304px",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
});

export default AvatarFaceswap;
