import React from "react";

export const BlueArrowDown = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99998 10.6667C7.84798 10.6667 7.69665 10.6154 7.57331 10.512L3.57331 7.1787C3.29065 6.94336 3.25198 6.5227 3.48798 6.24003C3.72331 5.95736 4.14331 5.91936 4.42665 6.1547L8.00731 9.1387L11.582 6.26203C11.8686 6.03136 12.2886 6.0767 12.5193 6.36336C12.75 6.65003 12.7046 7.06936 12.418 7.3007L8.41798 10.5194C8.29598 10.6174 8.14798 10.6667 7.99998 10.6667Z"
      fill="url(#paint0_linear_1_5203)"
    />
    <mask id="mask0_1_5203" maskUnits="userSpaceOnUse" x="3" y="6" width="10" height="5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 10.6667C7.84798 10.6667 7.69665 10.6154 7.57331 10.512L3.57331 7.1787C3.29065 6.94336 3.25198 6.5227 3.48798 6.24003C3.72331 5.95736 4.14331 5.91936 4.42665 6.1547L8.00731 9.1387L11.582 6.26203C11.8686 6.03136 12.2886 6.0767 12.5193 6.36336C12.75 6.65003 12.7046 7.06936 12.418 7.3007L8.41798 10.5194C8.29598 10.6174 8.14798 10.6667 7.99998 10.6667Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_5203)">
      <rect width="16" height="16" fill="url(#paint1_linear_1_5203)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_5203"
        x1="5.34359"
        y1="6.61058"
        x2="7.2701"
        y2="11.5647"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_5203"
        x1="3.44615"
        y1="2.09231"
        x2="12.9231"
        y2="14.2769"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
