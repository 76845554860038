import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import HumanCard from "../../../../../../components/HumanCard/HumanCard";
import CircularProgress from "../../../../../../components/Icons/CircularProgress";
import { Humatar, HumatarPreviewPrefilled } from "../../../../../../types/humatar";
import { Popups, updatePopup } from "../../../../../../redux/actions/popupsActions";
import { bookmarkHumatarServer } from "../../../../../../redux/actions/humatarActions";

const MAX_LENGTH = 5;

interface Props {
  data: Humatar[];
  loading: boolean;
}

const normalizeName = (name: string): string => {
  return name.replace(/\s#\d+$/, "").trim();
};

const AIHumansCard = ({ data, loading }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filteredData: Humatar[] = [];
  const seenNames = new Set<string>();

  data.forEach((actor) => {
    const normalizedName = normalizeName(actor.name);
    if (!seenNames.has(normalizedName)) {
      filteredData.push(actor);
      seenNames.add(normalizedName);
    }
  });
  const handleBookmarked = (e: any, id: number) => {
    e.stopPropagation();

    const isBookmarked = data?.find((human) => human.aiHumanActorId === id)?.isBookmarked;

    if (isBookmarked) dispatch(bookmarkHumatarServer(id, false));
    else dispatch(bookmarkHumatarServer(id, true));
  };

  const handlePopupOpen = (e: any, human: Humatar) => {
    e.stopPropagation();

    const prefilled: HumatarPreviewPrefilled = {
      human,
      setSelectedActor: () => null,
      isPopup: false,
    };

    dispatch(
      updatePopup({
        popup: Popups.aIHumansPreviewPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <OuterWrapper isLoading={loading}>
      {loading ? (
        <CircularProgress color="#009af7" />
      ) : (
        filteredData
          .slice(0, MAX_LENGTH)
          .map((human) => (
            <HumanCard
              key={human.aiHumanActorId}
              photo={human.photo}
              name={human.name}
              isDeleteButton={false}
              isLocked={human.isLocked}
              isBookmarked={human.isBookmarked}
              handleActive={() => navigate(`/ai-humans?actor=${human.name.replace("#", "№")}`)}
              handleBookmarked={(e: any) => handleBookmarked(e, human.aiHumanActorId)}
              handleClick={(e: any) => handlePopupOpen(e, human)}
              handleCustomHumatarDelete={() => null}
            />
          ))
      )}
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  & > div {
    &:hover {
      background: ${({ theme }) => theme.hoverVoiceCardBackground};
      & > :nth-child(2) {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }

  ${({ isLoading }) =>
    isLoading
      ? `
      align-items: center;
      justify-content: center;
    `
      : `
      & > div {
        flex:1;
        min-width:17%;
        min-height:220px;
      }
    `}

  @media (max-width: 850px) {
    & > div {
      min-width: 170px;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;

    & > div {
      min-width: 170px;
    }
  }
`;

export default AIHumansCard;
