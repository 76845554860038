import styled from "styled-components";
import { useEffect, useState } from "react";

import Pagination from "./Pagination";
import CardWrapper from "./CardWrapper";
import HistoryItem from "./HistoryItem";
import Select from "../../../components/Select/Select";
import { ArrowsDownUp, DropDawnPlanIcon } from "../../../components/Icons/Icons";

const DEFAULT_MIN_VALUE = 0;

interface HistoryData {
  img?: string;
  name: string;
  projectTime: any;
  id: string;
  output?: string;
  guid?: string;
}

interface Props {
  data: HistoryData[];
  isLoading: boolean;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
  menuOptions: string[];
  selectedHistoryLenghtPerPage: number;
  paginate: (pageNumber: number) => void;
  handleSelectHistoryLengthPerPage: (lengthPerPage: number) => void;
}

const HistoryCard = ({
  data,
  isLoading,
  totalPages,
  totalRecords,
  currentPage,
  menuOptions,
  selectedHistoryLenghtPerPage,
  paginate,
  handleSelectHistoryLengthPerPage,
}: Props) => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const [lastHistoryItemIndexForShow, setLastHistoryItemIndexForShow] = useState(0);
  const [firstHistoryItemIndex, setFirstHistoryItemIndex] = useState(0);
  const [currentPageData, setCurrentPageData] = useState<HistoryData[]>(data);
  const [activeProject, setActiveProject] = useState<number | null>(null);

  const handleActiveProject = (e: any, id: number) => {
    e.preventDefault();
    setActiveProject(id);
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const updatePaginateData = () => {
    const updatedLastHistoryItemIndex = currentPage * selectedHistoryLenghtPerPage;
    const updatedLastHistoryItemIndexForShow =
      updatedLastHistoryItemIndex < totalRecords ? updatedLastHistoryItemIndex : totalRecords;
    setLastHistoryItemIndexForShow(updatedLastHistoryItemIndexForShow);

    const updatedFirstHistoryItemIndex = updatedLastHistoryItemIndex - selectedHistoryLenghtPerPage;
    setFirstHistoryItemIndex(updatedFirstHistoryItemIndex);

    const sortedData = data.slice().sort((a, b) => {
      let dateA = new Date(a.projectTime + "Z").getTime();
      let dateB = new Date(b.projectTime + "Z").getTime();
      if (sortColumn === "File Name") {
        return sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      } else if (sortColumn === "Created") {
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });

    setCurrentPageData(sortedData);
  };

  useEffect(() => {
    updatePaginateData();
  }, [selectedHistoryLenghtPerPage, currentPage, sortColumn, sortOrder, data]);

  console.log("data", data);
  return (
    <CardWrapper>
      <Content>
        <TitlesWrapper>
          {tableTitles.map((item) => (
            <div key={item.title} onClick={() => handleSort(item.title)}>
              <span>{item.title}</span>
              {item.icon}
            </div>
          ))}
        </TitlesWrapper>
        {isLoading
          ? Array.from({ length: selectedHistoryLenghtPerPage }).map((_, index) => (
              <SkeletonWrapper key={index} style={{ alignItems: "center" }}>
                <Skeleton style={{ width: "32px", height: "32px", marginRight: "8px" }} />
                <Skeleton style={{ width: "100%", height: "20px" }} />
                <Skeleton style={{ width: "100%", height: "20px", marginLeft: "auto" }} />
              </SkeletonWrapper>
            ))
          : currentPageData &&
            currentPageData.map((project) => (
              <HistoryItem
                key={project.id}
                {...project}
                guid={project.guid}
                menuOptions={menuOptions}
                pageSize={selectedHistoryLenghtPerPage}
                active={parseInt(project.id) === activeProject}
                setActiveProject={setActiveProject}
                handleActiveProject={(e: any) => handleActiveProject(e, parseInt(project.id))}
              />
            ))}
        <BottomWrapper>
          <span>
            {firstHistoryItemIndex < DEFAULT_MIN_VALUE ? DEFAULT_MIN_VALUE : firstHistoryItemIndex + 1}-
            {lastHistoryItemIndexForShow} of {totalRecords} items
          </span>
          <Pagination
            currentPage={currentPage}
            historyLenghtPerPage={selectedHistoryLenghtPerPage}
            historyLenth={totalPages}
            paginate={paginate}
            disabled={isLoading}
          />
          <ShowWrapper>
            <span>Show</span>
            <SelectWrapper>
              <Select
                value={selectedHistoryLenghtPerPage}
                onChange={handleSelectHistoryLengthPerPage}
                optionsList={options}
                disabled={isLoading}
                defaultValueText=""
                actionIcon={<DropDawnPlanIcon />}
              />
            </SelectWrapper>
          </ShowWrapper>
        </BottomWrapper>
      </Content>
    </CardWrapper>
  );
};

const options = {
  data: [
    {
      id: "1",
      label: "8",
      value: 8,
    },
    {
      id: "2",
      label: "10",
      value: 10,
    },
  ],
};

const tableTitles = [
  { title: "File Name", icon: <ArrowsDownUp /> },
  { title: "Created", icon: <ArrowsDownUp /> },
];

const TitlesWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px 0px 8px 0px;
  border-bottom: 1px solid #dfdfdf;

  span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    color: #6f7074;
    display: flex;
    align-items: centers;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;

    &:last-child {
      max-width: 170px;
      margin-right: 30px;
      width: 100%;
      margin-left: auto;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 0px 0px;

  position: relative;

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #191b1f;
  }
`;

const ShowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6f7074;
  }
`;

const SelectWrapper = styled.div`
  div > div:nth-child(2) {
    width: 58px;
    height: 32px;
    border-radius: 8px;
  }

  div > div:nth-child(3) {
    top: -76px;
  }

  div > div:nth-child(3) {
    span {
      border: none;
    }
  }

  div svg {
    top: 16px;
    right: 10px;
    margin-left: -3px;
  }
  p {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Skeleton = styled("div")({
  background: "linear-gradient(-90deg, #e9e9e9, #c4c3c3)",
  backgroundSize: "400% 400%",
  animation: "gradient 3s ease infinite",
  borderRadius: "8px",
  maxWidth: "200px",

  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
});

const SkeletonWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px 0px 8px 0px;
  position: relative;
`;

export default HistoryCard;
