export const AddActorIcon = () => (
  <svg width="86" height="73" viewBox="0 0 86 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M78.109 72.323H7.89104C4.09213 72.323 1 69.2597 1 65.4962V8.37944C1 4.616 4.09213 1.55273 7.89104 1.55273H78.109C81.9079 1.55273 85 4.616 85 8.37944V65.4962C85 69.2597 81.9079 72.323 78.109 72.323Z"
      fill="white"
    />
    <path
      d="M78.109 1.55273H7.89104C4.09213 1.55273 1 4.616 1 8.37944V11.4427H85V8.37944C85 4.5985 81.9079 1.55273 78.109 1.55273Z"
      fill="#147FD6"
    />
    <path
      d="M78.109 72.323H7.89104C4.09213 72.323 1 69.2597 1 65.4962V8.37944C1 4.616 4.09213 1.55273 7.89104 1.55273H78.109C81.9079 1.55273 85 4.616 85 8.37944V65.4962C85 69.2597 81.9079 72.323 78.109 72.323Z"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path d="M1 11.4424H45.244" stroke="#111C3A" strokeWidth="0.968128" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M9.48156 8.0819C10.3305 8.0819 11.0188 7.40008 11.0188 6.55901C11.0188 5.71795 10.3305 5.03613 9.48156 5.03613C8.63257 5.03613 7.94434 5.71795 7.94434 6.55901C7.94434 7.40008 8.63257 8.0819 9.48156 8.0819Z"
      fill="white"
    />
    <path
      d="M15.7726 8.0819C16.6216 8.0819 17.3098 7.40008 17.3098 6.55901C17.3098 5.71795 16.6216 5.03613 15.7726 5.03613C14.9236 5.03613 14.2354 5.71795 14.2354 6.55901C14.2354 7.40008 14.9236 8.0819 15.7726 8.0819Z"
      fill="white"
    />
    <path
      d="M22.0616 8.0819C22.9106 8.0819 23.5989 7.40008 23.5989 6.55901C23.5989 5.71795 22.9106 5.03613 22.0616 5.03613C21.2127 5.03613 20.5244 5.71795 20.5244 6.55901C20.5244 7.40008 21.2127 8.0819 22.0616 8.0819Z"
      fill="white"
    />
    <path
      d="M29.3768 44.5077L22.4504 45.838C20.1004 46.2931 18.0154 47.6234 16.6196 49.5664L16.5312 49.689C15.4357 51.2469 14.835 53.0848 14.835 54.9753V58.7212H50.5977V54.8002C50.5977 53.0148 50.0676 51.2644 49.0781 49.759C47.6999 47.676 45.5442 46.2406 43.0882 45.768L36.3915 44.4727H29.3768V44.5077Z"
      fill="#147FD6"
    />
    <path
      d="M26.3552 34.5643C26.5319 34.8269 26.7262 35.0895 27.0266 35.212C27.3447 35.352 27.7157 35.3345 28.0691 35.2995C28.5992 35.247 29.1823 35.177 29.5357 34.7919C29.73 34.5818 29.836 34.3193 29.9244 34.0392C30.3484 32.6213 30.2424 31.116 29.9597 29.6631C29.783 28.8054 29.7124 27.2825 28.7052 26.9149C27.9808 26.6523 27.115 27.0549 26.5672 27.5451C25.7367 28.2977 25.6661 29.418 25.5777 30.4683C25.4717 31.8861 25.5954 33.3565 26.3552 34.5643Z"
      fill="#10002B"
    />
    <path
      d="M25.9851 34.7917C26.2148 35.1418 26.4798 35.4569 26.8685 35.6319C27.2926 35.807 27.752 35.7895 28.1937 35.737C28.6001 35.702 28.9889 35.6319 29.3599 35.4569C29.7486 35.2643 30.0314 34.9493 30.1904 34.5467C30.9148 32.8837 30.5968 31.0108 30.3141 29.2954C30.1197 28.1051 29.837 26.5647 28.3528 26.4246C27.5577 26.3546 26.7095 26.7747 26.1618 27.3174C25.614 27.8775 25.349 28.6127 25.243 29.3654C24.9956 31.1858 24.9779 33.1988 25.9851 34.7917C26.2855 35.2643 27.0452 34.8267 26.7449 34.3541C25.9851 33.1638 25.9321 31.746 26.0381 30.3631C26.0911 29.768 26.1264 29.1378 26.3738 28.5952C26.6212 28.0525 27.0982 27.5974 27.6813 27.4049C27.964 27.3174 28.2644 27.2648 28.5471 27.3699C28.6885 27.4224 28.8652 27.5624 28.9359 27.685C29.2009 28.0876 29.3069 28.5777 29.3776 29.0678C29.6073 30.3981 29.89 31.7635 29.6603 33.1288C29.5896 33.5139 29.519 33.969 29.3246 34.3191C29.1656 34.6167 28.9005 34.7392 28.5825 34.8092C28.2291 34.8968 27.8227 34.9318 27.4693 34.8968C27.1159 34.8617 26.9039 34.6342 26.7095 34.3541C26.4268 33.8815 25.667 34.3191 25.9851 34.7917Z"
      fill="#111C3A"
    />
    <path
      d="M39.4485 32.4644C39.4485 32.4644 40.6856 31.3791 41.092 31.9042C41.7105 32.7094 41.6575 36.0353 40.5972 36.2453C40.1731 36.3328 39.3955 35.8777 39.3955 35.8777L39.4485 32.4644Z"
      fill="white"
    />
    <path
      d="M39.4485 32.4644C39.4485 32.4644 40.6856 31.3791 41.092 31.9042C41.7105 32.7094 41.6575 36.0353 40.5972 36.2453C40.1731 36.3328 39.3955 35.8777 39.3955 35.8777L39.4485 32.4644Z"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.1969 32.4644C26.1969 32.4644 24.9599 31.3791 24.5534 31.9042C23.9349 32.7094 23.9879 36.0353 25.0482 36.2453C25.4723 36.3328 26.2499 35.8777 26.2499 35.8777L26.1969 32.4644Z"
      fill="white"
    />
    <path
      d="M26.1969 32.4644C26.1969 32.4644 24.9599 31.3791 24.5534 31.9042C23.9349 32.7094 23.9879 36.0353 25.0482 36.2453C25.4723 36.3328 26.2499 35.8777 26.2499 35.8777L26.1969 32.4644Z"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.0875 49.4254H32.6988C30.8612 49.4254 29.377 47.9551 29.377 46.1346V35.8945H36.4093V46.1346C36.4093 47.9375 34.9251 49.4254 33.0875 49.4254Z"
      fill="white"
    />
    <path
      d="M33.0875 49.4254H32.6988C30.8612 49.4254 29.377 47.9551 29.377 46.1346V35.8945H36.4093V46.1346C36.4093 47.9375 34.9251 49.4254 33.0875 49.4254Z"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.609 32.4637C40.0684 37.6275 38.1424 40.4983 36.3578 42.0737C34.4142 43.7891 31.4811 43.8241 29.4314 42.2312C24.9434 38.7128 26.2686 32.4813 26.2686 32.4813C26.2686 32.4813 27.7352 25.9171 31.6754 25.9171C35.598 25.8996 39.1849 27.8426 39.609 32.4637Z"
      fill="white"
    />
    <path
      d="M39.609 32.4637C40.0684 37.6275 38.1424 40.4983 36.3578 42.0737C34.4142 43.7891 31.4811 43.8241 29.4314 42.2312C24.9434 38.7128 26.2686 32.4813 26.2686 32.4813C26.2686 32.4813 27.7352 25.9171 31.6754 25.9171C35.598 25.8996 39.1849 27.8426 39.609 32.4637Z"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.4032 28.3506C27.8378 28.2106 27.2724 28.0531 26.7776 27.7555C26.3889 27.5104 26.0355 27.1954 25.7351 26.8453C24.9047 25.9 24.3393 24.5347 24.8517 23.3794C25.0284 22.9943 25.3111 22.6617 25.6291 22.3817C26.6363 21.5064 28.0498 21.1914 29.3927 21.2614C30.7356 21.3314 32.0254 21.769 33.2799 22.2591C33.9337 22.5217 34.5875 22.7843 35.2236 23.0643C36.6725 23.712 38.6161 25.2349 37.6443 27.0553C36.7431 28.7708 34.1634 28.8583 32.5025 28.8408C31.1243 28.8408 29.7461 28.6832 28.4032 28.3506Z"
      fill="#10002B"
    />
    <path
      d="M28.5104 27.9304C27.9274 27.7904 27.3619 27.6328 26.8495 27.3003C26.4078 27.0027 26.0191 26.5826 25.7187 26.145C25.1886 25.3748 24.8352 24.307 25.3123 23.4318C25.8247 22.5216 26.8849 22.0314 27.8744 21.8214C28.9875 21.5938 30.1184 21.7514 31.1962 22.0314C32.3977 22.364 33.5639 22.8366 34.7124 23.3443C35.5428 23.7119 36.3203 24.1495 36.9211 24.8321C37.4511 25.4448 37.6278 26.215 37.2038 26.9502C36.815 27.6153 36.0906 27.9654 35.3485 28.1755C34.465 28.403 33.5639 28.438 32.6451 28.438C31.2492 28.403 29.871 28.2455 28.5104 27.9304C27.9627 27.8079 27.7153 28.6481 28.2807 28.7706C29.659 29.0857 31.0902 29.2607 32.5037 29.2782C33.4225 29.2957 34.3767 29.2607 35.2778 29.0682C36.1789 28.8756 37.1154 28.4906 37.6985 27.7554C38.3876 26.8977 38.529 25.7949 37.9812 24.8321C37.5041 23.9569 36.6207 23.2918 35.7372 22.8541C35.1541 22.5566 34.5357 22.3115 33.9173 22.0664C33.2812 21.8039 32.6274 21.5588 31.9736 21.3488C30.7015 20.9462 29.3762 20.7186 28.051 20.8936C26.8849 21.0512 25.6657 21.5588 24.9059 22.4865C24.4995 22.9767 24.2521 23.5368 24.2168 24.167C24.1815 24.7796 24.3405 25.3923 24.6055 25.9349C25.1356 27.0902 26.1251 28.0529 27.3266 28.4905C27.6446 28.6131 27.9627 28.6831 28.2807 28.7706C28.8285 28.9107 29.0759 28.0704 28.5104 27.9304Z"
      fill="#111C3A"
    />
    <path
      d="M39.7133 32.2884C40.1374 32.1484 40.3494 31.6933 40.4907 31.2732C40.7911 30.4155 41.0031 29.5402 41.1445 28.665C41.2682 27.8248 41.3212 26.9846 41.2859 26.1444C41.2682 25.4442 41.1798 24.7265 40.7558 24.1489C39.6603 22.661 37.6813 23.3962 37.0629 24.8316C36.3561 26.4245 36.5505 28.455 37.2219 30.0304C37.593 30.8531 38.6001 32.6735 39.7133 32.2884Z"
      fill="#10002B"
    />
    <path
      d="M39.8396 32.7102C40.7231 32.3776 40.9528 31.3799 41.1825 30.5747C41.4652 29.6119 41.6242 28.6142 41.6949 27.6164C41.8186 25.971 41.8363 23.6604 39.9456 23.0128C39.2919 22.7852 38.5674 22.9077 37.9667 23.2403C37.3129 23.5904 36.8712 24.1681 36.6061 24.8507C35.9524 26.4961 36.1291 28.4566 36.7828 30.067C37.0655 30.7322 37.4013 31.3799 37.8783 31.9225C38.3554 32.4826 39.0622 32.9553 39.8396 32.7102C40.3874 32.5351 40.1577 31.6949 39.6099 31.87C39.5923 31.87 39.4332 31.905 39.5039 31.905C39.4509 31.905 39.3802 31.905 39.3272 31.905C39.3979 31.9225 39.2742 31.8875 39.2565 31.8875C39.1859 31.87 39.2212 31.8875 39.1329 31.835C38.9208 31.7124 38.7618 31.5724 38.5851 31.3799C38.2494 30.9948 37.9667 30.5221 37.7546 30.0495C37.1716 28.8242 36.9419 27.2838 37.2246 25.9185C37.3659 25.3058 37.5603 24.7282 38.0374 24.3081C38.4084 23.9755 38.8855 23.783 39.3626 23.8005C39.8573 23.818 40.3344 24.1856 40.5994 24.6757C40.9528 25.3408 40.9175 26.1986 40.8998 26.9337C40.8821 27.8965 40.7584 28.8417 40.5287 29.787C40.4227 30.2596 40.2814 30.7322 40.1047 31.2048C40.0163 31.4499 39.8926 31.8175 39.6276 31.905C39.3979 31.9925 39.2565 32.2026 39.3272 32.4476C39.3449 32.6227 39.6099 32.7977 39.8396 32.7102Z"
      fill="#111C3A"
    />
    <path
      d="M30.7014 33.2169C30.7014 33.7069 30.4717 34.092 30.2067 34.092C29.924 34.092 29.7119 33.7069 29.7119 33.2169C29.7119 32.7268 29.9416 32.3418 30.2067 32.3418C30.4717 32.3418 30.7014 32.7443 30.7014 33.2169Z"
      fill="#10002B"
    />
    <path
      d="M35.1711 33.3038C35.1711 33.7939 34.9414 34.1789 34.6764 34.1789C34.3937 34.1789 34.1816 33.7939 34.1816 33.3038C34.1816 32.8138 34.4113 32.4287 34.6764 32.4287C34.9591 32.4287 35.1711 32.8138 35.1711 33.3038Z"
      fill="#10002B"
    />
    <path
      d="M28.7051 31.0996H31.5852"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.2471 31.0996H36.1095"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.7612 33.1814C31.6552 33.724 31.4785 34.2316 31.1781 34.7043C30.8776 35.1769 30.5419 35.597 30.5242 36.1746C30.4889 37.3299 31.6905 38.2051 32.7862 37.82C33.0159 37.75 33.1573 37.5225 33.0866 37.2774C33.0336 37.0673 32.7685 36.8923 32.5388 36.9798C32.2207 37.0848 31.8673 37.0498 31.6199 36.8048C31.3725 36.5597 31.3371 36.1921 31.4785 35.877C31.6375 35.5095 31.9379 35.1769 32.1147 34.8093C32.3444 34.3717 32.5035 33.8991 32.5918 33.4264C32.6272 33.1989 32.5211 32.9538 32.2914 32.8838C32.097 32.8138 31.8142 32.9363 31.7612 33.1814Z"
      fill="#111C3A"
    />
    <path
      d="M32.3997 40.1848C33.6542 40.2023 34.8911 39.4497 35.4035 38.3119C35.4919 38.1018 35.4742 37.8393 35.2445 37.7167C35.0501 37.6117 34.7497 37.6467 34.6437 37.8743C34.5907 37.9968 34.5377 38.1018 34.467 38.2068C34.4317 38.2594 34.3964 38.3294 34.3433 38.3819C34.308 38.4344 34.414 38.3119 34.3257 38.3994C34.308 38.4169 34.2903 38.4519 34.255 38.4694C34.1667 38.5569 34.0783 38.662 33.99 38.732C33.9723 38.7495 33.937 38.767 33.9193 38.7845C33.8309 38.872 33.99 38.732 33.884 38.802C33.8309 38.837 33.7779 38.872 33.7249 38.907C33.6189 38.977 33.5129 39.0295 33.3892 39.0821C33.3715 39.0821 33.2832 39.1346 33.3362 39.0996C33.3892 39.0821 33.3009 39.1171 33.2832 39.1171C33.2302 39.1346 33.1595 39.1521 33.1065 39.1696C32.9828 39.2046 32.8415 39.2396 32.7178 39.2571C32.6471 39.2746 32.8238 39.2571 32.7001 39.2571C32.6648 39.2571 32.6294 39.2571 32.6118 39.2571C32.5411 39.2571 32.4704 39.2571 32.3997 39.2571C32.17 39.2571 31.9403 39.4672 31.958 39.6947C31.9757 39.9923 32.1524 40.1848 32.3997 40.1848Z"
      fill="#111C3A"
    />
    <path
      d="M50.5458 58.6857H15.7372C11.7793 58.6857 8.56348 55.4999 8.56348 51.5789V25.7249C8.56348 21.804 11.7793 18.6182 15.7372 18.6182H50.5458C54.5037 18.6182 57.7195 21.804 57.7195 25.7249V51.5789C57.7195 55.4999 54.5037 58.6857 50.5458 58.6857Z"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.0732 27.161H63.9201C62.8599 27.161 61.9941 26.3033 61.9941 25.253V24.8679C61.9941 23.8177 62.8599 22.96 63.9201 22.96H78.0732C79.1334 22.96 79.9992 23.8177 79.9992 24.8679V25.253C79.9992 26.3033 79.1334 27.161 78.0732 27.161Z"
      fill="#10002B"
    />
    <path
      d="M63.6211 30.1191H74.4524"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.2666 48.3242H74.0979"
      stroke="#111C3A"
      strokeWidth="0.968128"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M77.985 44.5084H63.9909C62.8777 44.5084 61.9766 43.6157 61.9766 42.5129V35.9838C61.9766 34.881 62.8777 33.9883 63.9909 33.9883H77.985C79.0981 33.9883 79.9993 34.881 79.9993 35.9838V42.5129C79.9993 43.6157 79.0981 44.5084 77.985 44.5084Z"
      fill="#147FD6"
    />
    <path
      d="M77.985 63.7809H63.9909C62.8777 63.7809 61.9766 62.8882 61.9766 61.7854V55.2562C61.9766 54.1535 62.8777 53.2607 63.9909 53.2607H77.985C79.0981 53.2607 79.9993 54.1535 79.9993 55.2562V61.7854C79.9993 62.8882 79.0981 63.7809 77.985 63.7809Z"
      fill="#147FD6"
    />
    <path
      d="M47.5832 22.906L50.5589 20.6119C51.1469 20.1602 51.8788 19.9105 52.6227 19.9105H71.425C74.1488 19.9105 76.3566 17.7234 76.3566 15.025V4.88551C76.3566 2.18719 74.1488 0 71.425 0H52.1308C49.407 0 47.1992 2.18719 47.1992 4.88551V22.7159C47.1992 22.9179 47.4272 23.0249 47.5832 22.906Z"
      fill="black"
    />
    <path
      d="M68.6836 11.4391C69.5726 11.2567 70.144 10.3949 69.9599 9.51419C69.7758 8.6335 68.9059 8.06742 68.0169 8.24982C67.1279 8.43222 66.5565 9.29402 66.7406 10.1747C66.9247 11.0554 67.7946 11.6215 68.6836 11.4391Z"
      fill="white"
    />
    <path
      d="M63.0817 10.836C63.6322 10.1208 63.4932 9.09894 62.7712 8.55364C62.0493 8.00833 61.0178 8.14606 60.4674 8.86126C59.9169 9.57646 60.056 10.5983 60.7779 11.1436C61.4998 11.6889 62.5313 11.5512 63.0817 10.836Z"
      fill="white"
    />
    <path
      d="M56.7786 10.3055C57.033 9.44213 56.5327 8.53797 55.6613 8.28596C54.7898 8.03395 53.8771 8.52952 53.6227 9.39285C53.3683 10.2562 53.8686 11.1604 54.74 11.4124C55.6115 11.6644 56.5242 11.1688 56.7786 10.3055Z"
      fill="white"
    />
  </svg>
);
