import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/themeReducer";

export const SupportSidebarIcon = () => {
  const theme = useSelector(getTheme);
  const stroke = theme === "light" ? "#3B3D47" : "#ffffff";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M10 15C10.5178 15 10.9375 14.5803 10.9375 14.0625C10.9375 13.5447 10.5178 13.125 10 13.125C9.48223 13.125 9.0625 13.5447 9.0625 14.0625C9.0625 14.5803 9.48223 15 10 15Z"
        fill={stroke}
      />
      <path
        d="M10 11.2504V10.6254C10.4326 10.6254 10.8556 10.4971 11.2153 10.2567C11.575 10.0163 11.8554 9.6747 12.021 9.27499C12.1866 8.87527 12.2299 8.43544 12.1455 8.01111C12.0611 7.58677 11.8527 7.197 11.5468 6.89107C11.2409 6.58514 10.8511 6.3768 10.4268 6.2924C10.0024 6.20799 9.56259 6.25131 9.16288 6.41688C8.76317 6.58245 8.42153 6.86282 8.18116 7.22256C7.9408 7.58229 7.8125 8.00522 7.8125 8.43787"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
    </svg>
  );
};
