import styled from "styled-components";

import { ReactElement, cloneElement } from "react";
import { CircleCheckedIconV2 } from "../../../../components/Icons/CircleCheckedIconV2";
import { TabsType } from "../../../../types/videoTranslationContext";
import { TranslationTabProps } from "../../../../types/videoTranslation";
import Tooltip from "../../../../components/Tooltip/Tooltip";

interface Props {
  data: { value: TabsType; content: TranslationTabProps; disabledValue?: boolean; tooltip?: string }[];
  active: TabsType | null;
  handleActive: (status: TabsType) => void;
  withChecked?: boolean;
  error?: any;
  disabled?: boolean;
  children: ReactElement<TranslationTabProps>;
}

const VideoTranslationTabsWrapper = ({
  data,
  active,
  handleActive,
  withChecked = true,
  error,
  disabled = false,
  children,
}: Props) => {
  const handleClick = (value: any) => {
    handleActive(value);
  };

  return (
    <Wrapper>
      <ContentWrapper className="video-translation-tabs-wrapper">
        {data.map(({ value, content, disabledValue, tooltip }, index) => (
          <Content
            key={value}
            className={value === active ? "active" : "not-active"}
            disabled={disabled || disabledValue}
            onClick={() => !(disabled || disabledValue) && handleClick(value)}
          >
            {cloneElement(children, content)}
            {withChecked && (
              <CircleCheckedIconWrapper>
                <CircleCheckedIconV2 />
              </CircleCheckedIconWrapper>
            )}
            {tooltip && <Tooltip className="tab-tooltip" text={tooltip} position="bottom" reverseColor />}
          </Content>
        ))}
      </ContentWrapper>
      {error && <ErrorText className="tabs-error-text">{error}</ErrorText>}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ withOpacity?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0px 0px 20px 0px;
  width: 100%;
  .active {
    opacity: 1;
    img {
      opacity: 1;
    }
    border: 1px solid #3091d7;
    & > :nth-child(2) {
      width: 16px;
      height: 16px;
    }
  }

  .not-active {
    border: ${({ theme }) => theme.videoTranslationBorder};
  }
`;

const Content = styled.div<{ disabled?: boolean }>`
  border-radius: 8px;
  width: 100%;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};

  &:not(:has(.tab-tooltip)) {
    overflow: hidden;
  }

  & > div {
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  }

  &:hover > .tab-tooltip {
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    border-radius: 4px;
    padding: 4px 8px 6px !important;
    top: auto;
    bottom: 0;
    width: 100%;

    & > span {
      font-size: 11px !important;
      line-height: 14px !important;
      text-align: left;
      text-transform: none;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ErrorText = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  color: #aa2121;
  width: 100% !important;
  height: auto !important;
`;

const CircleCheckedIconWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 0px;
  height: 0px;
  overflow: hidden;
  display: flex;

  & > svg {
    width: 14px;
    height: 14px;
  }
`;

export default VideoTranslationTabsWrapper;
