import { FC, ReactNode } from "react";
import styled from "styled-components";

import { leftSignInReviews, rightSignInReviews } from "../mocks/signin";
import ReviewCard from "../components/ReviewCard/ReviewCard";

interface IFormProps {
  children: ReactNode;
  variant?: "default" | "without-images";
}

const AuthLayout: FC<IFormProps> = ({ children, variant = "default" }) => {
  return (
    <Wrapper>
      <AuthWrapper>
        {children}
        {variant === "default" && (
          <AuthImageWrapper>
            <SignInCardWrapper variant="left">
              {leftSignInReviews.map((card) => (
                <ReviewCard key={card.id} {...card} />
              ))}
            </SignInCardWrapper>
            <SignInCardWrapper variant="right">
              {rightSignInReviews.map((card) => (
                <ReviewCard key={card.id} {...card} />
              ))}
            </SignInCardWrapper>
          </AuthImageWrapper>
        )}
      </AuthWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.signInBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const AuthWrapper = styled("div")`
  max-width: 1318px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 102px;

  @media (max-width: 1201px) {
    gap: 40px;
  }
`;

const AuthImageWrapper = styled("div")`
  display: flex;
  gap: 24px;
  width: 504px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 504px;
    height: 115px;
    pointer-events: none;
    z-index: 5;
    background: ${({ theme }) => theme.authBackgroundBefore};
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 504px;
    height: 128px;
    z-index: 5;
    pointer-events: none;
    background: ${({ theme }) => theme.authBackgroundAfter};
    transform: matrix(1, 0, 0, -1, 0, 0);
  }

  @media (max-width: 1201px) {
    gap: 16px;
  }
  @media (max-width: 1001px) {
    display: none;
  }
`;

const SignInCardWrapper = styled.div<{ variant?: "right" | "left" }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 240px;

  ${({ variant }) =>
    variant === "left" &&
    `
    animation: leftCards 30s infinite;

  `}

  ${({ variant }) =>
    variant === "right" &&
    `
    animation: rightCards 30s infinite;
    `}

  @keyframes leftCards {
    0% {
      transform: translateY(-55%);
    }
    50% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-55%);
    }
  }

  @keyframes rightCards {
    0% {
      transform: translateY(0%);
    }
    50% {
      transform: translateY(-25%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

export default AuthLayout;
