import { ChangeEvent } from "react";

export enum VideoTranslationSteps {
  UploadVideo,
  SelectVideoOptions,
  DownloadVideo,
}

export enum TranslationTypeTabsType {
  TranslateVideo = "TranslateVideo",
  AddActor = "AddActor",
}

export enum TranslationOptionTabsType {
  TranslateWithLipsyncing = 0,
  TranslateOnlyAudio = 1,
}

export enum SetPositionTabsType {
  LeftBottom = 1,
  RigtBottom = 2,
}

export type TabsType = TranslationOptionTabsType | TranslationTypeTabsType | SetPositionTabsType | number | null;

export interface VideoTranslationContextTypes {
  uploadedVideoDuration: number | null;
  setUploadedVideoDuration: (value: number | null) => void;

  selectVideoOptionsActiveTabs: { [key: string]: TabsType | null };
  setSelectVideoOptionsActiveTabs: (tabs: Record<string, TabsType | null>) => void;
  toggleRecentlyGeneratedVisible: () => void;
  recentlyGeneratedVisible: boolean;
  uploadedVideo: File | string | null;
  setUploadedVideo: (file: File | string | null) => void;
  handleVideoSelect: ({ target }: any) => void;
  selectLanguage: any;
  onChangeLanguageSelect: (newLanguage: any) => void;
  currentStep: number;
  handleNextStep: () => void;
  handlePrevStep: () => void;
  videoPreview: string | null;
  setVideoPreview: (src: string) => void;
  videoPreviewActor: string | null;
  setVideoPreviewActor: (src: string | null) => void;
  isModalOpened: boolean;
  setIsModalOpened: (value: boolean) => void;
  setCurrentStep: (index: number) => void;
  isEnableCaption: boolean;
  setIsEnableCaption: (isEnableCaption: boolean) => void;
  selectVideoVoice: string | number;
  onChangeVideoVoice: (newVideoVoice: any) => void;
  textTranslated: string;
  setTextTranslated: (textTranslated: string) => void;
}
