export const JumbCutIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <rect y="9" width="13" height="17" rx="2" fill="url(#paint0_linear_2977_21256)" />
    </g>
    <g opacity="0.5">
      <path
        d="M15 11C15 9.89543 15.8954 9 17 9H26C27.1046 9 28 9.89543 28 11V24C28 25.1046 27.1046 26 26 26H17C15.8954 26 15 25.1046 15 24V11Z"
        fill="url(#paint1_linear_2977_21256)"
      />
    </g>
    <rect x="2.5" y="2.5" width="23" height="17" rx="4" fill="url(#paint2_linear_2977_21256)" />
    <path d="M11.1387 11H16.8613" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 13.8613V8.13867" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <defs>
      <linearGradient
        id="paint0_linear_2977_21256"
        x1="13"
        y1="17.5"
        x2="0.614152"
        y2="14.742"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1680D3" />
        <stop offset="1" stopColor="#ADD6F2" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2977_21256"
        x1="28"
        y1="17.5"
        x2="15.6142"
        y2="14.742"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1680D3" />
        <stop offset="1" stopColor="#C0DFF4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2977_21256"
        x1="7.45385"
        y1="4.72308"
        x2="16.4316"
        y2="20.3398"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
