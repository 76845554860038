import styled from "styled-components";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  active?: boolean;
  width: number;
  directionLeft?: boolean;
}

const SideBarLayout = ({ active, children, width, directionLeft }: Props) => {
  return (
    <OuterWrapper width={width} active={active} directionLeft={directionLeft}>
      {children}
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div<{ active?: boolean; width: number; directionLeft?: boolean }>`
  width: 0%;
  min-width: 0%;
  opacity: 1;
  transition: 0.2s;
  position: relative;
  display: flex;
  visibility: hidden;

  ${({ active, width }) =>
    active &&
    `
    margin: 0px 0px 0px 0px;
    display: inline-flex;
    min-width: ${width}px;
    width: ${width}px;
    visibility: visible;        
  `}

  ${({ directionLeft }) =>
    directionLeft &&
    `
    justify-content: end;     
  `}
  
  ${({ directionLeft, active }) =>
    directionLeft &&
    !active &&
    `
    margin: 0px 0px 0px -40px;     
  `}
`;

export default SideBarLayout;
