import { StoreType } from "../../types/store";
import { SumoModules } from "../../types/sumo";
import { SET_ACTIVE_PLAN, UPDATE_SUMO_LOADING } from "../actions/sumoActions";

export interface sumoStateType {
  [SumoModules.planLink]: {
    isLoading: boolean;
  };
  [SumoModules.activePlan]: {
    isLoading: boolean;
    activePlan: string;
  };
}

const sumoInitialState: sumoStateType = {
  [SumoModules.planLink]: {
    isLoading: false,
  },
  [SumoModules.activePlan]: {
    isLoading: false,
    activePlan: "",
  },
};

const sumoReducer = (state = sumoInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_SUMO_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as SumoModules], isLoading } };
    }
    case SET_ACTIVE_PLAN: {
      return { ...state, [SumoModules.activePlan]: { ...state[SumoModules.activePlan], activePlan: action.payload } };
    }
    default: {
      return { ...state };
    }
  }
};

export const getPlanLinkLoading = (state: StoreType) => state.sumo[SumoModules.planLink].isLoading;
export const getActivePlanLoading = (state: StoreType) => state.sumo[SumoModules.activePlan].isLoading;
export const getActivePlan = (state: StoreType) => state.sumo[SumoModules.activePlan].activePlan;

export default sumoReducer;
