import {
  AddActorIcon,
  AudioTranslateIcon,
  CheckIconThick,
  LipsyncingTranslateIcon,
  ProfileIconRound,
  TranslateVideoIcon,
} from "../components/Icons/Icons";

import { UploadVideoTabs } from "../types/videoTranslation";
import {
  SetPositionTabsType,
  TranslationOptionTabsType,
  TranslationTypeTabsType,
} from "../types/videoTranslationContext";

export const languageOptions = {
  data: [
    {
      value: "BG",
      label: "Bulgarian",
      icon: "https://flagcdn.com/w40/bg.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "CS",
      label: "Czech",
      icon: "https://flagcdn.com/w40/cz.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "DA",
      label: "Danish",
      icon: "https://flagcdn.com/w40/dk.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "DE",
      label: "German",
      icon: "https://flagcdn.com/w40/de.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "EL",
      label: "Greek",
      icon: "https://flagcdn.com/w40/gr.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "EN-GB",
      label: "English (British)",
      icon: "https://flagcdn.com/w40/gb.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "EN-US",
      label: "English (American)",
      icon: "https://flagcdn.com/w40/us.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "ES",
      label: "Spanish",
      supports_formality: true,
      icon: "https://flagcdn.com/w40/es.png",
      endAddornment: <CheckIconThick />,
    },
    {
      value: "ET",
      label: "Estonian",
      icon: "https://flagcdn.com/w40/et.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "FI",
      label: "Finnish",
      icon: "https://flagcdn.com/w40/fi.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "FR",
      label: "French",
      icon: "https://flagcdn.com/w40/fr.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "HU",
      label: "Hungarian",
      icon: "https://flagcdn.com/w40/hu.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "ID",
      label: "Indonesian",
      icon: "https://flagcdn.com/w40/id.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "IT",
      label: "Italian",
      icon: "https://flagcdn.com/w40/it.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "JA",
      label: "Japanese",
      icon: "https://flagcdn.com/w40/jp.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "KO",
      label: "Korean",
      icon: "https://flagcdn.com/w40/kr.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "LT",
      label: "Lithuanian",
      icon: "https://flagcdn.com/w40/lt.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "LV",
      label: "Latvian",
      icon: "https://flagcdn.com/w40/lv.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "NB",
      label: "Norwegian (Bokmål)",
      icon: "https://flagcdn.com/w40/no.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "NL",
      label: "Dutch",
      icon: "https://flagcdn.com/w40/nl.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "PL",
      label: "Polish",
      icon: "https://flagcdn.com/w40/pl.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "PT-BR",
      label: "Portuguese (Brazilian)",
      icon: "https://flagcdn.com/w40/br.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "PT-PT",
      label: "Portuguese (European)",
      icon: "https://flagcdn.com/w40/pt.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "RO",
      label: "Romanian",
      icon: "https://flagcdn.com/w40/ro.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "RU",
      label: "Russian",
      icon: "https://flagcdn.com/w40/ru.png",
      supports_formality: true,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "SK",
      label: "Slovak",
      icon: "https://flagcdn.com/w40/sk.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "SL",
      label: "Slovenian",
      icon: "https://flagcdn.com/w40/sl.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "SV",
      label: "Swedish",
      icon: "https://flagcdn.com/w40/sv.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "TR",
      label: "Turkish",
      icon: "https://flagcdn.com/w40/tr.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "UK",
      label: "Ukrainian",
      icon: "https://flagcdn.com/w40/ua.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
    {
      value: "ZH",
      label: "Chinese (simplified)",
      icon: "https://flagcdn.com/w40/cn.png",
      supports_formality: false,
      endAddornment: <CheckIconThick />,
    },
  ],
};

export const videoVoicesOptions = [
  {
    actorid: 0,
    Name: "Cloned",
  },
  {
    actorid: 3466,
    Name: "Rachel",
    src: "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys-v2/VoiceActorSamples/b6f24b25-a52b-4897-9635-3e0f87444919.mp3",
  },
  {
    actorid: 3467,
    Name: "Domi",
    src: "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys-v2/VoiceActorSamples/d08e6144-72d2-436b-a646-4d984f83476d.mp3",
  },
  {
    actorid: 3468,
    Name: "Drew",
    src: "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys-v2/VoiceActorSamples/9a86b11f-d8bd-4e96-aacf-2c4f7b739ee7.mp3",
  },
  {
    actorid: 3469,
    Name: "Clyde",
    src: "https://d9f35555a8b3e9044c8d-95c21efaab8093d23d4124e599a618ee.ssl.cf5.rackcdn.com/synthesys-v2/VoiceActorSamples/b65dbedf-903e-4b73-abc7-8dddef92f1a4.mp3",
  },
];

export const stepsOptions = [
  {
    step: "UploadVideo",
  },
  {
    step: "SelectVideoOptions",
  },
  {
    step: "DownloadVideo",
  },
];

export const recentlyGeneratedVideosData = [
  {
    src: "/images/video-translation/recently-video-1.png",
    id: "1",
  },
  { src: "/images/video-translation/recently-video-2.png", id: "2" },
  { src: "/images/video-translation/recently-video-3.png", id: "3" },
];

export const advantagesData = [
  { text: "Translate videos with avatars to any language", id: "1" },
  { text: "Translate videos without avatars to any language", id: "2" },
  { text: "Translate videos and add avatar if there is not", id: "3" },
];

export const UploadTabsOption = [
  {
    value: UploadVideoTabs.Upload,
    title: "Upload",
  },
  {
    value: UploadVideoTabs.YouTube,
    title: "YouTube",
    disabled: true,
    isDisplay: false,
  },
  {
    value: UploadVideoTabs.TikTok,
    title: "TikTok",
    disabled: true,
    isDisplay: false,
  },
  {
    value: UploadVideoTabs.XTwitter,
    title: "X (Twitter) ",
    disabled: true,
    isDisplay: false,
  },
  {
    value: UploadVideoTabs.Vimeo,
    title: "Vimeo",
    disabled: true,
    isDisplay: false,
  },
  {
    value: UploadVideoTabs.OtherURL,
    title: "Other URL",
    disabled: true,
    isDisplay: false,
  },
];

// export const chooseActorData = [
//   {
//     value: 1,
//     children: <ChooseActorTab avatar="/images/video-translation/actor-preview-1.png" />,
//     actorImg: "",
//   },
//   {
//     value: 2,
//     children: <ChooseActorTab avatar="/images/video-translation/actor-preview-2.png" />,
//     actorImg: "/images/video-translation/actor-2.png",
//   },
//   {
//     value: 3,
//     children: <ChooseActorTab avatar="/images/video-translation/actor-preview-3.png" />,
//     actorImg: "",
//   },
//   {
//     value: 4,
//     children: <ChooseActorTab avatar="/images/video-translation/actor-preview-3.png" />,
//     actorImg: "",
//   },
// ];

export const translationTypeTabsOption = [
  {
    value: TranslationTypeTabsType.TranslateVideo,
    content: {
      icon: <AddActorIcon />,
      title: "Translate video",
      description: "Translate audio only or an actor through lip-syncing",
    },
  },
  {
    value: TranslationTypeTabsType.AddActor,
    content: {
      icon: <TranslateVideoIcon />,
      title: "Add an actor to a video",
      description: "Choose an actor from our list and select the language",
    },
  },
];

export const translationOptionTabsOption = [
  {
    value: TranslationOptionTabsType.TranslateWithLipsyncing,
    content: {
      icon: <LipsyncingTranslateIcon />,
      title: "Translate with lipsyncing",
      description: "Change actor's language",
    },
  },
  {
    value: TranslationOptionTabsType.TranslateOnlyAudio,
    content: {
      icon: <AudioTranslateIcon />,
      title: "Translate Only the audio",
      description: "Translate audio of the video",
    },
  },
];

export const setPositionTabsOption = [
  {
    value: SetPositionTabsType.LeftBottom,
    content: {
      icon: <ProfileIconRound />,
      title: "Left bottom",
      position: { bottom: "6px", left: "6px" },
    },
  },
  {
    value: SetPositionTabsType.RigtBottom,
    content: {
      icon: <ProfileIconRound />,
      title: "Right bottom",
      position: { bottom: "6px", right: "6px" },
    },
  },
];

interface LanguageRequirementsTooltipProps {
  [key: string]: string;
}

export const languageRequirementsTooltip: LanguageRequirementsTooltipProps = {
  ["Supported Input/Output"]:
    "Bulgarian, Czech, Danish, German, Greek, English (British), English (American), Spanish, Estonian, Finnish, French, Hungarian, Indonesian, Italian, Japanese, Korean, Lithuanian, Latvian, Norwegian (Bokmål), Dutch, Polish, Portuguese (Brazilian), Portuguese (European), Romanian, Russian, Slovak, Slovenian, Swedish, Turkish, Ukrainian, Chinese (simplified)",
  // Input:
  //   "Input: Arabic, Danish, Dutch, English, Finnish, French, German, Hebrew, Hindi, Italian, Japanese, Korean, Mandarin, Polish, Portuguese, Romanian, Russian, Spanish, Turkish",
  // Output:
  //   "English - American Accent, English - Your Accent, Arabic, Bulgarian, Mandarin, Croatian, Czech, Danish, Dutch, Filipino, Finnish, French, German, Greek, Hindi, Indonesian, Italian, Japanese, Korean, Malay, Polish, Portuguese, Romanian, Slovak, Spanish, Swedish, Tamil, Turkish, Ukrainian",
};

export const videoRequirementsTooltip = [
  "Please refrain from using background music and excessive noise, as they may negatively affect the audio output quality.",
  "Ensure that the resolution falls within the range of 360x360 to 4096x4096.",
  "Keep the video duration between 15 seconds to 5 mins**. For videos of up to 30 minutes (voice translation only) please check your license.",
];

export const requirementsRorLipSyncTooltip = [
  "Please limit the number of participants to one person.",
  "Avoid extreme or rapid head movements.",
  "Avoid glasses.",
  "Avoid side face.",
  "Avoid close-ups.",
  "Face must be in all frames.",
];
