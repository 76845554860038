import * as Sentry from "@sentry/react";

export const modules = {
  AIVoices: "AI Voices",
  AIHumans: "AI Humans",
  AIImages: "AI Images",
};

export const section = {
  voiceGeneration: "Voice Generation",
  voiceDownload: "Voice Download",
  zippingVoice: "Zipping Voice",
  gettingActors: "Getting Actors",
  savingProject: "Saving Project",
  gettingImages: "Getting Images",
  gettingVideo: "Getting Video",
  gettingSoundtracks: "Getting Soundtracks",
  creatingProject: "Creating Project",
  createProjectWithoutSlides: "Create Project Without Slides",
  gettingHumatars: "Getting Humatars",
  gettingShapes: "Getting Shapes",
  gettingTemplates: "Getting Templates",
  gettingTransitions: "Getting Transitions",
  createdParagraphWithNonExistingActor: "Created Paragraph With Non Existing Actor",
  gettingLanguages: "Getting Languages",
  gettingHistoryImages: "Getting History Images",
  gettingGalleryImages: "Getting Gallery Images",
  gettingHumatarPreview: "Getting Humatar Preview",
  uploadingHumatar: "Uploading Humatar",
  gettingDownloadHistory: "Getting Download History",
  gettingPromptToVideoList: "Getting Prompt To Video List",
  gettingGalleryVideos: "Getting Gallery Videos",
};

// interface ISentryError {
//   [item: string]: {
//     title: string;
//     toast: string;
//     code: number;
//     tags: {
//       module: string;
//       section: string;
//       code: number;
//     };
//   };
// }

interface SentryErrorsProps {
  errorType: any;
  details: Record<string, string>;
}

export const sentryErrors = ({ errorType, details }: SentryErrorsProps) => {
  Sentry.captureException(new Error(errorType.title), {
    tags: errorType.tags,
    extra: {
      details,
    },
  });
};

export const SentryErrors = {
  SERVER_ERROR_WHILE_GENERATING_VOICE: {
    title: "Server Error While Generating Voice - 1002",
    toast: "Error while generating your voice. Please contact support with code 1002",
    code: 1002,
    tags: {
      module: modules.AIVoices,
      section: section.voiceGeneration,
      code: 1002,
    },
  },
  SERVER_ERROR_WHILE_DOWNLOADING_VOICE: {
    title: "Server Error While Downloading Voice - 1003",
    toast: "Error while downloading your voice. Please contact support with code 1003",
    code: 1003,
    tags: {
      module: modules.AIVoices,
      section: section.voiceDownload,
      code: 1003,
    },
  },
  SERVER_ERROR_WHILE_ZIPPING_VOICE: {
    title: "Server Error While Zipping Voice - 1004",
    toast: "Error while zipping your voice. Please contact support with code 1004",
    code: 1004,
    tags: {
      module: modules.AIVoices,
      section: section.zippingVoice,
      code: 1004,
    },
  },
  SERVER_ERROR_WHILE_GETTING_ACTORS: {
    title: "Server Error While Getting Actors - 1101",
    toast: "Error while getting actors. Please contact support with code 1101",
    code: 1101,
    tags: {
      module: modules.AIVoices,
      section: section.gettingActors,
      code: 1101,
    },
  },
  SERVER_ERROR_WHILE_SAVING_PROJECT: {
    title: "Server Error While Updating/Creating Project - 1102",
    code: 1102,
    tags: {
      module: modules.AIVoices,
      section: section.savingProject,
      code: 1102,
    },
  },
  PROJECT_WITH_EMPTY_PARAGRAPHS: {
    title: "Project with empty paragraphs - 1103",
    toast: "There was an issue while saving/getting your project, please contact support with code 1103",
    code: 1103,
    tags: {
      module: modules.AIVoices,
      code: 1103,
    },
  },
  SERVER_ERROR_WHILE_GETTING_IMAGES: {
    title: "Error while getting images - 2000",
    toast: "Error while getting images. Please contact support with code 2000",
    code: 2000,
    tags: {
      module: modules.AIHumans,
      section: section.gettingImages,
      code: 2000,
    },
  },
  SERVER_ERROR_WHILE_GETTING_VIDEO: {
    title: "Error while getting video - 2001",
    toast: "Error while getting video. Please contact support with code 2001",
    code: 2001,
    tags: {
      module: modules.AIHumans,
      section: section.gettingVideo,
      code: 2001,
    },
  },
  SERVER_ERROR_WHILE_GETTING_SOUNDTRACKS: {
    title: "Error while getting soundtrack - 2002",
    toast: "Error while getting soundtrack. Please contact support with code 2002",
    code: 2002,
    tags: {
      module: modules.AIHumans,
      section: section.gettingSoundtracks,
      code: 2002,
    },
  },
  SERVER_ERROR_WHILE_CREATE_PROJECT: {
    title: "Create Project failed - 2003",
    toast: "Create Project failed. Please contact support with code 2003",
    code: 2003,
    tags: {
      module: modules.AIHumans,
      section: section.creatingProject,
      code: 2003,
    },
  },
  CREATE_PROJECT_WITHOUT_SLIDES: {
    title: "The project must have at least one scene - 2004",
    toast: "The project must have at least one scene. Please contact support with code 2004",
    code: 2004,
    tags: {
      module: modules.AIHumans,
      section: section.createProjectWithoutSlides,
      code: 2004,
    },
  },
  SERVER_ERROR_WHILE_GETTING_HUMATARS: {
    title: "Error while getting humatars - 2005",
    toast: "Error while getting humatars. Please contact support with code 2005",
    code: 2005,
    tags: {
      module: modules.AIHumans,
      section: section.gettingHumatars,
      code: 2005,
    },
  },
  SERVER_ERROR_WHILE_GETTING_SHAPES: {
    title: "Error while getting shapes - 2006",
    toast: "Error while getting shapes. Please contact support with code 2006",
    code: 2006,
    tags: {
      module: modules.AIHumans,
      section: section.gettingShapes,
      code: 2006,
    },
  },
  SERVER_ERROR_WHILE_GETTING_TEMPLATES: {
    title: "Error while getting templates - 2007",
    toast: "Error while getting templates. Please contact support with code 2007",
    code: 2007,
    tags: {
      module: modules.AIHumans,
      section: section.gettingTemplates,
      code: 2007,
    },
  },
  SERVER_ERROR_WHILE_GETTING_TRANSITIONS: {
    title: "Error while getting transitions - 2008",
    toast: "Error while getting transitions. Please contact support with code 2008",
    code: 2008,
    tags: {
      module: modules.AIHumans,
      section: section.gettingTransitions,
      code: 2008,
    },
  },
  CREATED_PARAGRAPH_WITH_NON_EXISTING_ACTOR: {
    title: "Created paragraph with non-existing actor - 2009",
    toast: "Created paragraph with non-existing actor. Please contact support with code 2009",
    code: 2009,
    tags: {
      module: modules.AIHumans,
      section: section.createdParagraphWithNonExistingActor,
      code: 2009,
    },
  },
  SERVER_ERROR_WHILE_GETTING_LANGUAGES: {
    title: "Error while getting languages - 2010",
    toast: "Error while getting languages. Please contact support with code 2010",
    code: 2010,
    tags: {
      module: modules.AIHumans,
      section: section.gettingLanguages,
      code: 2010,
    },
  },
  SERVER_ERROR_WHILE_GETTING_HUMATAR_PREVIEW: {
    title: "Error while getting humatar preview - 2011",
    toast: "Error while getting humatar preview. Please contact support with code 2011",
    code: 2011,
    tags: {
      module: modules.AIHumans,
      section: section.gettingHumatarPreview,
      code: 2011,
    },
  },
  SERVER_ERROR_WHILE_UPLOADING_HUMATAR: {
    title: "Error while uploading humatar - 2012",
    toast: "Error while uploading humatar. Please contact support with code 2012",
    code: 2012,
    tags: {
      module: modules.AIHumans,
      section: section.uploadingHumatar,
      code: 2012,
    },
  },
  SERVER_ERROR_WHILE_GETTING_DOWNLOAD_HISTORY: {
    title: "Error while getting download history - 2013",
    toast: "Error while getting download history. Please contact support with code 2013",
    code: 2013,
    tags: {
      module: modules.AIHumans,
      section: section.gettingDownloadHistory,
      code: 2013,
    },
  },
  AUDIO_PLAYBACK_ERROR: {
    title: "Audio playback error - 2992",
    code: 2992,
    tags: {
      code: 2992,
    },
  },
  EMPTRY_PROJECT_WHEN_GENERATING_A_PROJECT: {
    title: "Empty project when generating a project - 2993",
    code: 2993,
    tags: {
      code: 2993,
    },
  },
  EMPTRY_PROJECT_WHEN_UPDATING_A_PROJECT: {
    title: "Empty project when updating a project - 2994",
    code: 2994,
    tags: {
      code: 2994,
    },
  },
  EMPTRY_PROJECT_WHEN_CREATING_A_PROJECT: {
    title: "Empty project when creating a project - 2995",
    code: 2995,
    tags: {
      code: 2995,
    },
  },
  NO_SELECTED_ACTOR: {
    title: "There is no selected actor in the paragraph - 2996",
    code: 2996,
    tags: {
      code: 2996,
    },
  },
  EMPTY_SLIDES: {
    title: "The slides are empty - 2997",
    code: 2997,
    tags: {
      code: 2997,
    },
  },
  BACKGROUND_OF_THE_SCENE_WAS_NOT_FOUND: {
    title: "The background of the scene was not found - 2998",
    code: 2998,
    tags: {
      code: 2998,
    },
  },
  NON_EXISTENT_TAB: {
    title: "Non existent tab on the AI Humans page - 2999",
    code: 2999,
    tags: {
      code: 2999,
    },
  },
  SERVER_ERROR_WHILE_GETTING_HISTORY_IMAGES: {
    title: "Server Error While Getting History Images - 3001",
    toast: "Error while getting history images. Please contact support with code 3001",
    code: 3001,
    tags: {
      module: modules.AIImages,
      section: section.gettingHistoryImages,
      code: 3001,
    },
  },
  SERVER_ERROR_WHILE_GETTING_GALLERY_IMAGES: {
    title: "Server Error While Getting Gallery Images - 3002",
    toast: "Error while getting gallery images. Please contact support with code 3002",
    code: 3002,
    tags: {
      module: modules.AIImages,
      section: section.gettingGalleryImages,
      code: 3002,
    },
  },
  SERVER_ERROR_WHILE_GETTING_PROMPT_TO_VIDEO_LIST: {
    title: "Server Error While Getting Prompt To Video List - 3003",
    toast: "Error while getting videos. Please contact support with code 3003",
    code: 3003,
    tags: {
      module: modules.AIImages,
      section: section.gettingPromptToVideoList,
      code: 3003,
    },
  },
  SERVER_ERROR_WHILE_GETTING_GALLERY_VIDEOS: {
    title: "Server Error While Getting Gallery Videos - 3004",
    toast: "Error while getting gallery videos. Please contact support with code 3004",
    code: 3004,
    tags: {
      module: modules.AIImages,
      section: section.gettingGalleryVideos,
      code: 3004,
    },
  },
  PAGE_CRASH: {
    title: "Page Crash - 9999",
    code: 9999,
    tags: {
      code: 9999,
    },
  },
};
