export const DropdownDelete = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66634 10.6666C6.66634 11.0333 6.36634 11.3333 5.99967 11.3333C5.63301 11.3333 5.33301 11.0333 5.33301 10.6666V7.99992C5.33301 7.63325 5.63301 7.33325 5.99967 7.33325C6.36634 7.33325 6.66634 7.63325 6.66634 7.99992V10.6666ZM10.6663 10.6666C10.6663 11.0333 10.3663 11.3333 9.99967 11.3333C9.63301 11.3333 9.33301 11.0333 9.33301 10.6666V7.99992C9.33301 7.63325 9.63301 7.33325 9.99967 7.33325C10.3663 7.33325 10.6663 7.63325 10.6663 7.99992V10.6666ZM12 12.6666C12 13.0339 11.7013 13.3333 11.3333 13.3333H4.66667C4.29867 13.3333 4 13.0339 4 12.6666V5.33325H12V12.6666ZM6.66602 2.88523C6.66602 2.7819 6.80868 2.66656 6.99935 2.66656H8.99935C9.19002 2.66656 9.33268 2.7819 9.33268 2.88523V3.9999H6.66602V2.88523ZM13.9997 3.99992H13.333H10.6663V2.88525C10.6663 2.02925 9.91901 1.33325 8.99967 1.33325H6.99967C6.08034 1.33325 5.33301 2.02925 5.33301 2.88525V3.99992H2.66634H1.99967C1.63301 3.99992 1.33301 4.29992 1.33301 4.66659C1.33301 5.03325 1.63301 5.33325 1.99967 5.33325H2.66634V12.6666C2.66634 13.7693 3.56367 14.6666 4.66634 14.6666H11.333C12.4357 14.6666 13.333 13.7693 13.333 12.6666V5.33325H13.9997C14.3663 5.33325 14.6663 5.03325 14.6663 4.66659C14.6663 4.29992 14.3663 3.99992 13.9997 3.99992Z"
      fill="#FF0000"
    />
    <mask id="mask0_493_13806" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66634 10.6666C6.66634 11.0333 6.36634 11.3333 5.99967 11.3333C5.63301 11.3333 5.33301 11.0333 5.33301 10.6666V7.99992C5.33301 7.63325 5.63301 7.33325 5.99967 7.33325C6.36634 7.33325 6.66634 7.63325 6.66634 7.99992V10.6666ZM10.6663 10.6666C10.6663 11.0333 10.3663 11.3333 9.99967 11.3333C9.63301 11.3333 9.33301 11.0333 9.33301 10.6666V7.99992C9.33301 7.63325 9.63301 7.33325 9.99967 7.33325C10.3663 7.33325 10.6663 7.63325 10.6663 7.99992V10.6666ZM12 12.6666C12 13.0339 11.7013 13.3333 11.3333 13.3333H4.66667C4.29867 13.3333 4 13.0339 4 12.6666V5.33325H12V12.6666ZM6.66602 2.88523C6.66602 2.7819 6.80868 2.66656 6.99935 2.66656H8.99935C9.19002 2.66656 9.33268 2.7819 9.33268 2.88523V3.9999H6.66602V2.88523ZM13.9997 3.99992H13.333H10.6663V2.88525C10.6663 2.02925 9.91901 1.33325 8.99967 1.33325H6.99967C6.08034 1.33325 5.33301 2.02925 5.33301 2.88525V3.99992H2.66634H1.99967C1.63301 3.99992 1.33301 4.29992 1.33301 4.66659C1.33301 5.03325 1.63301 5.33325 1.99967 5.33325H2.66634V12.6666C2.66634 13.7693 3.56367 14.6666 4.66634 14.6666H11.333C12.4357 14.6666 13.333 13.7693 13.333 12.6666V5.33325H13.9997C14.3663 5.33325 14.6663 5.03325 14.6663 4.66659C14.6663 4.29992 14.3663 3.99992 13.9997 3.99992Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_13806)"></g>
  </svg>
);
