import styled from "styled-components";
import { ReactNode } from "react";

import SectionTitle, { SectionTitleProps } from "./SectionTitle";
import SectionSubTitle, { SectionSubTitleProps } from "./SectionSubTitle";

export interface SectionHeadingProps extends SectionTitleProps, SectionSubTitleProps {
  children?: ReactNode;
  headingAlignMd?: string;
}

const SectionHeading = ({
  subtitle,
  subtitleAlignMd,
  subtitleAlignLg,
  subtitleTextColor,
  subtitleSpace,
  subtitleItemsList,
  addSubtitleClass,
  subtitleMaxWidth,
  headingAlignMd,
  subtitleFontSize,
  children,
  ...titleProps
}: SectionHeadingProps) => {
  return (
    <Wrapper className="sectionHeading" headingAlignMd={headingAlignMd}>
      <SectionTitle {...titleProps} />
      {children}
      {subtitle && (
        <SectionSubTitle
          subtitle={subtitle}
          subtitleAlignMd={subtitleAlignMd}
          subtitleAlignLg={subtitleAlignLg}
          subtitleTextColor={subtitleTextColor}
          subtitleSpace={subtitleSpace}
          subtitleItemsList={subtitleItemsList}
          addSubtitleClass={addSubtitleClass}
          subtitleMaxWidth={subtitleMaxWidth}
          subtitleFontSize={subtitleFontSize}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ headingAlignMd?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: 768px) {
    gap: 12px;
    align-items: ${({ headingAlignMd }) => headingAlignMd || "flex-start"};
  }

  @media (min-width: 1000px) {
    gap: 16px;
  }
`;

export default SectionHeading;
