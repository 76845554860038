import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/themeReducer";

export const ClockCounterClockwiseIcon = () => {
  const theme = useSelector(getTheme);
  const stroke = theme === "light" ? "#231F20" : "#ffffff";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 6.25V10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M13.2476 11.875L10 10"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M5.61194 7.79031H2.48694V4.66531"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
      <path
        d="M5.13865 14.8614C6.10014 15.8228 7.32514 16.4776 8.65876 16.7429C9.99238 17.0082 11.3747 16.872 12.631 16.3517C13.8872 15.8313 14.9609 14.9501 15.7164 13.8195C16.4718 12.689 16.875 11.3597 16.875 10C16.875 8.64026 16.4718 7.31105 15.7164 6.18046C14.9609 5.04987 13.8872 4.16868 12.631 3.64833C11.3747 3.12798 9.99238 2.99183 8.65876 3.2571C7.32514 3.52238 6.10014 4.17716 5.13865 5.13864L2.487 7.79029"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ fill: "none" }}
      />
    </svg>
  );
};
