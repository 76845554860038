import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IPages, pages } from "../../../lib/pricingRoutes";
import { LogoIcon } from "../../Icons/Icons";
import { footerSocialMocks } from "../../../mocks/footerMocks";
import FooterCopyright from "./FooterCopyright";

const FooterFirstColumn = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Link to={pages[IPages.main]()}>
          <a aria-label="Go to Home">
            <LogoIcon />
          </a>
        </Link>
      </LogoWrapper>
      <Row>
        {footerSocialMocks.map((item) => {
          const href = item.isMail ? `mailto:${item.href}` : item.href;
          const label = item.isMail ? "contact us by e-mail" : `go to our ${item.title}`;
          return (
            <a title={item.title} key={item.id} href={href} aria-label={label} target="_blank" rel="noreferrer">
              <SocialButton>{item.icon}</SocialButton>
            </a>
          );
        })}
      </Row>

      <Copyright>
        <FooterCopyright />
      </Copyright>
    </Wrapper>
  );
};

const LogoWrapper = styled.div`
  svg {
    width: 160px;
    height: 40px;
  }

  @media (max-width: 768px) {
    svg {
      width: 150px;
      height: 45px;
    }
  }
`;

const SocialButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #dde8f2;
`;

const Row = styled.div`
  display: flex;
  column-gap: 12px;

  @media (max-width: 768px) {
    column-gap: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Copyright = styled.div`
  @media (max-width: 899px) {
    display: none;
  }
`;

export default FooterFirstColumn;
