export enum AssetModules {
  image = 1,
  video = 2,
  soundtrack = 3,
  sample = 4,
  media = 5,
  faceswap = 6,
  visualAsset = 7,
  inPaintingVisualAsset = 8,
}

export interface AssetType {
  assetTypeId: number;
  expiryDateTime: string;
  fileName: string;
  insertDateTime: string;
  path: string;
  status: number;
  userAssetID: number;
  userId: number;
}

export interface ShapeStateObject {
  shapeId: number;
  shapeName: string;
  shapeImageUrl: string;
}

export type CropImage = {
  path: string;
  typeId: number;
  formData: FormData;
  fileType: string;
  pageSize?: number;
};
