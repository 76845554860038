export const disabledPhotorealisticModel = ["Horizontal", "Vertical"];

export const ratiosText2Video = {
  label: "Aspect ratio",
  secondaryDropdownStyle: true,
  id: "ratio",
  data: [
    {
      id: "1",
      label: "Square - 768x768",
      width: 768,
      height: 768,
      value: "Square - 768x768",
      resolutionLabel: "768x768",
      resolution: "768",
      size: "square",
    },
    {
      id: "2",
      label: "Horizontal - 1024x576",
      width: 1024,
      height: 576,
      value: "Horizontal - 1024x576",
      resolutionLabel: "1024x576",
      resolution: "768",
      size: "horizontal",
    },
    {
      id: "3",
      label: "Vertical - 576x1024",
      width: 576,
      height: 1024,
      value: "Vertical - 576x1024",
      resolutionLabel: "576x1024",
      resolution: "768",
      size: "vertical",
    },
  ],
};

export const resolutionsText2Video = {
  label: "Resolution",
  id: "resolution",
  data: [
    {
      id: "1",
      label: "768p",
      width: 768,
      height: 768,
      secondaryLabel: "768x768",
      value: "768",
      upscale: "1x",
    },
  ],
};

export const imagesStoryRatios = {
  label: "Aspect ratio",
  secondaryDropdownStyle: true,
  id: "ratio",
  data: [
    {
      id: "1",
      label: "Square - 1024x1024",
      width: 512,
      height: 512,
      value: "Square - 1024x1024",
      resolutionLabel: "1024x1024",
      resolution: "1024",
      size: "square",
    },
    {
      id: "2",
      label: "Horizontal - 1024x768",
      width: 1024,
      height: 768,
      value: "Horizontal - 1024x768",
      resolutionLabel: "1024x768",
      resolution: "1024",
      size: "horizontal",
    },
    {
      id: "3",
      label: "Vertical - 768x1024",
      width: 768,
      height: 1024,
      value: "Vertical - 768x1024",
      resolutionLabel: "768x1024",
      resolution: "1024",
      size: "vertical",
    },
    {
      id: "4",
      label: "Square - 2048x2048",
      width: 512,
      height: 512,
      value: "Square - 2048x2048",
      resolutionLabel: "2048x2048",
      resolution: "2048",
      size: "square",
    },
    {
      id: "5",
      label: "Horizontal - 3072x1280",
      width: 1536,
      height: 640,
      value: "Horizontal - 3072x1280",
      resolutionLabel: "3072x1280",
      resolution: "2048",
      size: "horizontal",
    },
    {
      id: "6",
      label: "Vertical - 1280x3072",
      width: 640,
      height: 1536,
      value: "Vertical - 1280x3072",
      resolutionLabel: "1280x3072",
      resolution: "2048",
      size: "vertical",
    },
  ],
};

export const ratios = {
  label: "Aspect ratio",
  secondaryDropdownStyle: true,
  id: "ratio",
  data: [
    {
      id: "1",
      label: "Square - 1024x1024",
      width: 512,
      height: 512,
      value: "Square - 1024x1024",
      resolutionLabel: "1024x1024",
      resolution: "1024",
      size: "square",
    },
    {
      id: "2",
      label: "Horizontal - 1536x640",
      width: 1536,
      height: 640,
      value: "Horizontal - 1536x640",
      resolutionLabel: "1536x640",
      resolution: "1024",
      size: "horizontal",
    },
    {
      id: "3",
      label: "Vertical - 640x1536",
      width: 640,
      height: 1536,
      value: "Vertical - 640x1536",
      resolutionLabel: "640x1536",
      resolution: "1024",
      size: "vertical",
    },
    {
      id: "4",
      label: "Square - 2048x2048",
      width: 512,
      height: 512,
      value: "Square - 2048x2048",
      resolutionLabel: "2048x2048",
      resolution: "2048",
      size: "square",
    },
    {
      id: "5",
      label: "Horizontal - 3072x1280",
      width: 1536,
      height: 640,
      value: "Horizontal - 3072x1280",
      resolutionLabel: "3072x1280",
      resolution: "2048",
      size: "horizontal",
    },
    {
      id: "6",
      label: "Vertical - 1280x3072",
      width: 640,
      height: 1536,
      value: "Vertical - 1280x3072",
      resolutionLabel: "1280x3072",
      resolution: "2048",
      size: "vertical",
    },
  ],
};

export const resolutions = {
  label: "Resolution",
  id: "resolution",
  data: [
    {
      id: "1",
      label: "1024p",
      width: 512,
      height: 512,
      secondaryLabel: "1024x1024",
      value: "1024",
      upscale: "1x",
    },
    {
      id: "2",
      label: "2048p",
      width: 512,
      height: 512,
      secondaryLabel: "2048x2048",
      value: "2048",
      upscale: "2x",
    },
  ],
};

export const artists = {
  label: "Artist",
  id: "artist",
  data: [
    {
      id: "none",
      label: "none",
      value: "",
    },
    {
      id: "1",
      label: "Anne Stokes",
      value: "Anne Stokes",
    },
    {
      id: "2",
      label: "Georges Surat",
      value: "Georges Surat",
    },
    {
      id: "3",
      label: "Vincent van Gogh",
      value: "Vincent van Gogh",
    },
    {
      id: "4",
      label: "Hieronymus Bosch",
      value: "Hieronymus Bosch",
    },
    {
      id: "5",
      label: "Salvador Dali",
      value: "Salvador Dali",
    },
    {
      id: "6",
      label: "Frida Kahlo",
      value: "Frida Kahlo",
    },
    {
      id: "7",
      label: "Andy Warhol",
      value: "Andy Warhol",
    },
    {
      id: "8",
      label: "Gustav Klimt",
      value: "Gustav Klimt",
    },
    {
      id: "9",
      label: "Edward Hopper",
      value: "Edward Hopper",
    },
    {
      id: "10",
      label: "Giotto",
      value: "Giotto",
    },
    {
      id: "11",
      label: "Diego Velázquez",
      value: "Diego Velázquez",
    },
    {
      id: "12",
      label: "Alexei Savrasov",
      value: "Alexei Savrasov",
    },
    {
      id: "13",
      label: "Albert Bierstadt",
      value: "Albert Bierstadt",
    },
    {
      id: "14",
      label: "Gil Elvgren",
      value: "Gil Elvgren",
    },
    {
      id: "15",
      label: "Roy Lichenstein",
      value: "Roy Lichenstein",
    },
    {
      id: "16",
      label: "Thomas Kinkade",
      value: "Thomas Kinkade",
    },
    {
      id: "17",
      label: "Basquiat",
      value: "Basquiat",
    },
    {
      id: "18",
      label: "Simon Stålenhag",
      value: "Simon Stålenhag",
    },
    {
      id: "19",
      label: "Pablo Picasso",
      value: "Pablo Picasso",
    },
    {
      id: "20",
      label: "Amedeo Modigliani",
      value: "Amedeo Modigliani",
    },
  ],
};

export const illustrations = {
  label: "Illustrations",
  id: "illustrations",
  data: [
    {
      id: "none",
      label: "none",
      value: "",
    },
    {
      id: "1",
      label: "Anime",
      value: "Anime",
    },
    {
      id: "2",
      label: "Digital painting",
      value: "Digital painting",
    },
    {
      id: "3",
      label: "Doodle",
      value: "Doodle",
    },
    {
      id: "4",
      label: "Pencil drawing",
      value: "Pencil drawing",
    },
    {
      id: "5",
      label: "Tattoo",
      value: "Tattoo",
    },
    {
      id: "6",
      label: "Collage",
      value: "Collage",
    },
    {
      id: "7",
      label: "Patent drawing",
      value: "Patent drawing",
    },
    {
      id: "8",
      label: "Acrylic on canvas",
      value: "Acrylic on canvas",
    },
    {
      id: "9",
      label: "Watercolor",
      value: "Watercolor",
    },
    {
      id: "10",
      label: "Pencil sketch",
      value: "Pencil sketch",
    },
    {
      id: "11",
      label: "Pixar",
      value: "Pixar",
    },
    {
      id: "12",
      label: "Comic book art",
      value: "Comic book art",
    },
    {
      id: "13",
      label: "Coloured pencil",
      value: "Coloured pencil",
    },
    {
      id: "14",
      label: "Vintage Disney",
      value: "Vintage Disney",
    },
    {
      id: "15",
      label: "Crayon",
      value: "Crayon",
    },
    {
      id: "16",
      label: "Stencil",
      value: "Stencil",
    },
    {
      id: "17",
      label: "Claymation",
      value: "Claymation",
    },
    {
      id: "18",
      label: "Storybook",
      value: "Storybook",
    },
    {
      id: "19",
      label: "Oil painting",
      value: "Oil painting",
    },
    {
      id: "20",
      label: "Abstract illustration",
      value: "Abstract illustration",
    },
  ],
};

export const artStyles = {
  label: "Art style",
  id: "artStyle",
  data: [
    {
      id: "none",
      label: "none",
      value: "",
    },
    {
      id: "1",
      label: "Neo-Expressionism",
      value: "Neo-Expressionism",
    },
    {
      id: "2",
      label: "Minimalism",
      value: "Minimalism",
    },
    {
      id: "3",
      label: "Pop Art",
      value: "Pop Art",
    },
    {
      id: "4",
      label: "Abstract Expressionism",
      value: "Abstract Expressionism",
    },
    {
      id: "5",
      label: "Colour field painting, abstract",
      value: "Colour field painting, abstract",
    },
    {
      id: "6",
      label: "Street art",
      value: "Street art",
    },
    {
      id: "7",
      label: "Art Nouveau",
      value: "Art Nouveau",
    },
    {
      id: "8",
      label: "Baroque",
      value: "Baroque",
    },
    {
      id: "9",
      label: "Post-impressionism",
      value: "Post-impressionism",
    },
    {
      id: "10",
      label: "Art deco, vintage, poster",
      value: "Art deco, vintage, poster",
    },
    {
      id: "11",
      label: "Mexican Muralism",
      value: "Mexican Muralism",
    },
    {
      id: "12",
      label: "Surrealism",
      value: "Surrealism",
    },
    {
      id: "13",
      label: "Bauhaus",
      value: "Bauhaus",
    },
    {
      id: "14",
      label: "De Stijl",
      value: "De Stijl",
    },
    {
      id: "15",
      label: "Suprematism, abstract, geometric",
      value: "Suprematism, abstract, geometric",
    },
    {
      id: "16",
      label: "Dadaism",
      value: "Dadaism",
    },
    {
      id: "17",
      label: "Russian Constructivism",
      value: "Russian Constructivism",
    },
    {
      id: "18",
      label: "Orphism",
      value: "Orphism",
    },
    {
      id: "19",
      label: "Expressionism",
      value: "Expressionism",
    },
    {
      id: "20",
      label: "Futurism",
      value: "Futurism",
    },
    {
      id: "21",
      label: "Cubism",
      value: "Cubism",
    },
  ],
};

export const stylesArr = {
  label: "Style",
  id: "style",
  data: [
    {
      id: "none",
      label: "none",
      value: "",
    },
    { id: "1", label: "3d-model", value: "3d-model" },
    { id: "2", label: "Abstract", value: "Abstract" },
    { id: "3", label: "Advertising", value: "Advertising" },
    { id: "4", label: "Alien", value: "Alien" },
    { id: "5", label: "analog-film", value: "analog-film" },
    { id: "6", label: "anime", value: "anime" },
    { id: "7", label: "Architectural", value: "Architectural" },
    { id: "8", label: "base", value: "base" },
    { id: "9", label: "Craft Clay", value: "Craft Clay" },
    { id: "10", label: "cinematic", value: "cinematic" },
    { id: "11", label: "Collage", value: "Collage" },
    { id: "12", label: "comic-book", value: "comic-book" },
    { id: "13", label: "digital-art", value: "digital-art" },
    { id: "14", label: "Disco", value: "Disco" },
    { id: "15", label: "Dreamscape", value: "Dreamscape" },
    { id: "16", label: "Dystopian", value: "Dystopian" },
    { id: "17", label: "Enhance", value: "Enhance" },
    { id: "18", label: "Fairy Tale", value: "Fairy Tale" },
    { id: "19", label: "fantasy-art", value: "fantasy-art" },
    { id: "20", label: "Fighting Game", value: "Fighting Game" },
    { id: "21", label: "Film Noir", value: "Film Noir" },
    { id: "22", label: "Food Photography", value: "Food Photography" },
    { id: "23", label: "Flat Papercut", value: "Flat Papercut" },
    { id: "24", label: "Gothic", value: "Gothic" },
    { id: "25", label: "Graffiti", value: "Graffiti" },
    { id: "26", label: "Grunge", value: "Grunge" },
    { id: "27", label: "HDR", value: "HDR" },
    { id: "28", label: "Horror", value: "Horror" },
    { id: "29", label: "Hyperrealism", value: "Hyperrealism" },
    { id: "30", label: "Impressionist", value: "Impressionist" },
    { id: "31", label: "isometric", value: "isometric" },
    { id: "32", label: "Kirigami", value: "Kirigami" },
    { id: "33", label: "Legend of Zelda", value: "Legend of Zelda" },
    { id: "34", label: "line-art", value: "line-art" },
    { id: "35", label: "Long Exposure", value: "Long Exposure" },
    { id: "36", label: "low-poly", value: "low-poly" },
    { id: "37", label: "Minecraft", value: "Minecraft" },
    { id: "38", label: "Minimalist", value: "Minimalist" },
    { id: "39", label: "Modeling Compound", value: "Modeling Compound" },
    { id: "40", label: "modeling-compound", value: "modeling-compound" },
    { id: "41", label: "Monochrome", value: "Monochrome" },
    { id: "42", label: "Nautical", value: "Nautical" },
    { id: "43", label: "Neon Noir", value: "Neon Noir" },
    { id: "44", label: "neon-punk", value: "neon-punk" },
    { id: "45", label: "Origami", value: "Origami" },
    { id: "46", label: "Paper Mache", value: "Paper Mache" },
    { id: "47", label: "Paper Quilling", value: "Paper Quilling" },
    { id: "48", label: "Papercut Collage", value: "Papercut Collage" },
    { id: "49", label: "Papercut Shadow Box", value: "Papercut Shadow Box" },
    { id: "50", label: "photographic", value: "photographic" },
    { id: "51", label: "Pixel Art", value: "Pixel Art" },
    { id: "52", label: "Pointillism", value: "Pointillism" },
    { id: "53", label: "Pokémon", value: "Pokémon" },
    { id: "54", label: "Pop Art", value: "Pop Art" },
    { id: "55", label: "Real Estate", value: "Real Estate" },
    { id: "56", label: "Renaissance", value: "Renaissance" },
    { id: "57", label: "Retro Arcade", value: "Retro Arcade" },
    { id: "58", label: "Retro Game", value: "Retro Game" },
    { id: "59", label: "RPG Fantasy Game", value: "RPG Fantasy Game" },
    { id: "60", label: "Silhouette", value: "Silhouette" },
    { id: "61", label: "Space", value: "Space" },
    { id: "62", label: "Stacked Papercut", value: "Stacked Papercut" },
    { id: "63", label: "Steampunk", value: "Steampunk" },
    { id: "64", label: "Strategy Game", value: "Strategy Game" },
    { id: "65", label: "Street Fighter", value: "Street Fighter" },
    { id: "66", label: "Super Mario", value: "Super Mario" },
    { id: "67", label: "Surrealist", value: "Surrealist" },
    { id: "68", label: "Techwear Fashion", value: "Techwear Fashion" },
    { id: "69", label: "Terrazzo", value: "Terrazzo" },
    { id: "70", label: "Tile Texture", value: "Tile Texture" },
    { id: "71", label: "Tilt-Shift", value: "Tilt-Shift" },
    { id: "72", label: "Tribal", value: "Tribal" },
    { id: "73", label: "Typography", value: "Typography" },
    { id: "74", label: "Watercolor", value: "Watercolor" },
  ],
};

interface SubStyles {
  [key: string]: string[];
}

export const subStylesArr: SubStyles = {
  Ads: [
    "Advertising",
    "Automotive",
    "Corporate",
    "Fashion Editorial",
    "Food Photography",
    "Luxury",
    "Real Estate",
    "Retail",
  ],
  Artstyle: [
    "Abstract",
    "Abstract Expressionism",
    "Art Deco",
    "Art Nouveau",
    "Constructivist",
    "Cubist",
    "Expressionist",
    "Graffiti",
    "Hyperrealism",
    "Impressionist",
    "Pointillism",
    "Pop Art",
    "Psychedelic",
    "Renaissance",
    "Steampunk",
    "Surrealist",
    "Typography",
    "Watercolor",
  ],
  Futuristic: [
    "Biomechanical",
    "Biomechanical Cyberpunk",
    "Cybernetic",
    "Cybernetic Robot",
    "Cyberpunk Cityscape",
    "Futuristic",
    "Retro Cyberpunk",
    "Retro Futurism",
    "Sci-Fi",
    "Vaporwave",
  ],
  Game: [
    "Bubble Bobble",
    "Cyberpunk Game",
    "Fighting Game",
    "GTA",
    "Mario",
    "Minecraft",
    "Pokémon",
    "Retro Arcade",
    "Retro Game",
    "RPG Fantasy Game",
    "Strategy Game",
    "Street Fighter",
    "Zelda",
  ],
  Misc: [
    "Architectural",
    "Disco",
    "Dreamscape",
    "Dystopian",
    "Fairy Tale",
    "Gothic",
    "Grunge",
    "Horror",
    "Kawaii",
    "Lovecraftian",
    "Macabre",
    "Manga",
    "Metropolis",
    "Minimalist",
    "Monochrome",
    "Nautical",
    "Space",
    "Stained Glass",
    "Techwear Fashion",
    "Tribal",
    "Zentangle",
  ],
  Papercraft: [
    "Collage",
    "Flat Papercut",
    "Kirigami",
    "Paper Mache",
    "Paper Quilling",
    "Papercut Collage",
    "Papercut Shadow Box",
    "Stacked Papercut",
    "Thick Layered Papercut",
  ],
  Photo: ["Alien", "Film Noir", "HDR", "Long Exposure", "Neon Noir", "Silhouette", "Tilt-Shift"],
};

export const motions = {
  label: "Motion",
  secondaryDropdownStyle: true,
  id: "motion",
  data: [
    {
      id: "low",
      label: "Slow",
      value: "low",
    },
    {
      id: "medium",
      label: "Medium",
      value: "medium",
    },
    {
      id: "high",
      label: "High",
      value: "high",
    },
  ],
};

export const extendVideoOprions = {
  label: "Up to...",
  secondaryDropdownStyle: true,
  id: "imagesQty",
  data: [
    {
      id: "0",
      label: "None",
      value: "",
    },
    {
      id: "1",
      label: "add up to 4 seconds",
      value: "4",
    },
    {
      id: "2",
      label: "add up to 8 seconds",
      value: "8",
    },
  ],
};
