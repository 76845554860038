interface Props {
  text: string;
  artist: string;
  illustration: string;
  artStyle: string;
}

export const generateAiImageQuery = ({ text, artist, illustration, artStyle }: Props): string => {
  let result = text;

  if (artist) result += `,${artist}`;
  if (illustration) result += `,${illustration}`;
  if (artStyle) result += `,${artStyle}`;

  return result;
};
