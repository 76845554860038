import styled from "styled-components";

import {
  PurpleStar,
  Microphone,
  Profile2User,
  MagicPen,
  Data2,
  MicrophoneIcon,
  CopyIcon,
  Gallery,
  Translate,
  Canva,
  CopyIconV2,
  CopyIconV3,
  MicrophoneIconV2,
  ChatGPT,
  LogoIcon,
  Logo,
  Profile2UserBlue,
  UserCircleAdd,
  UserSquare,
} from "../../../../components/Icons/Icons";
import { useState } from "react";
import Tabs, { HomeNavbarTabData, HomeNavbarTabs } from "./NavbarTabs";
import ContentCard, { ContentCardData } from "./ContentCard";
import { Link } from "react-router-dom";

const tabsData: HomeNavbarTabData[] = [
  { icon: <PurpleStar />, title: "Featured Tools", value: HomeNavbarTabs.FeaturedTools },
  { icon: <Microphone />, title: "AI Voices", value: HomeNavbarTabs.AIVoices },
  { icon: <Profile2User />, title: "AI Humans", value: HomeNavbarTabs.AIHumans },
  { icon: <MagicPen />, title: "Synthesys Lab", value: HomeNavbarTabs.SynthesysLab },
  // { icon: <Data2 />, title: "AI Workflows", value: HomeNavbarTabs.AIWorkflows },
];

export interface Props {
  TabContent: { [key: string]: (ContentCardData & { onClick?: () => void })[] };
}

const NavbarCard = ({ TabContent }: Props) => {
  const [activeTab, setActiveTab] = useState(HomeNavbarTabs.FeaturedTools);
  const handleActiveTab = (tab: HomeNavbarTabs) => setActiveTab(tab);

  return (
    <Wrapper>
      <Title>How do you want to get started?</Title>
      <Tabs data={tabsData} active={activeTab} handleActive={handleActiveTab} />
      <ContentCardWrapper>
        {TabContent[activeTab].map((item) =>
          item.isDisabled ? (
            <div key={item.title}>
              <ContentCard {...item} key={item.title} />
            </div>
          ) : (
            <Link to={item?.link?.to as string} target={item.link?.target} rel="noopener noreferrer" key={item.title}>
              <ContentCard {...item} key={item.title} />
            </Link>
          ),
        )}
      </ContentCardWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 28px 24px 24px 24px;
  border-radius: 12px;
  background: radial-gradient(
      59.54% 124.01% at 49.58% -3.54%,
      #019bf8 0%,
      rgba(0, 184, 224, 0) 99.99%,
      rgba(1, 155, 248, 0) 100%
    ),
    linear-gradient(0deg, #0063b4, #0063b4);
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const ContentCardWrapper = styled.div`
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
  display: flex;
  height: 100%;
  padding: 28px 24px 24px 24px;
  border-radius: 12px;

  a {
    max-width: 24%;
    min-width: 240px;
    width: 100%;
  }

  & > div {
    max-width: 24%;
    min-width: 240px;
    width: 100%;
  }

  @media (max-width: 700px) {
    max-width: 48%;
    min-width: 48%;
  }
  @media (max-width: 550px) {
    max-width: 100%;
    min-width: 100%%;
  }
`;

const Title = styled.div`
  font-family: Mulish;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.04em;
  text-align: center;
  color: #ffffff;
  margin: 0px 0px 30px;
`;
export default NavbarCard;
