import React from "react";
import BackgroundLogo from "./BackgroundLogo";
import styled from "styled-components";
import Button from "../Button/Button";
import { LogoIcon } from "../Icons/LogoIcon";
import { Link } from "react-router-dom";

const DesktopRecommended = () => {
  return (
    <>
      <Wrapper>
        <StyledLogo>
          <LogoIcon />
        </StyledLogo>
        <ImageWrapper>
          <BackgroundWrapper>
            <BackgroundLogo />
          </BackgroundWrapper>
          <StyledImage src="/images/DesktopPicture.png" />
        </ImageWrapper>
        <div>
          <Description>Desktop browser recommended for better experience</Description>
          <Link to="https://synthesys.io/">
            <StyledButton>Visit Synthesys Website</StyledButton>
          </Link>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: none;
  justify-content: space-evenly;
  width: 100%;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

const StyledLogo = styled("div")`
  width: 230px;
  height: 55px;
  margin-bottom: 50px;

  svg {
    path {
      fill: #0063b4;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 0px;
`;

const BackgroundWrapper = styled.div`
  display: flex;
  width: 100%;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const StyledImage = styled("img")`
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  text-align: center;
  width: 75%;
`;

const Description = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  padding: 0 25px;
`;

const StyledButton = styled(Button)`
  max-width: 250px;
  padding: 14px 32px;
  margin-top: 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(180deg, #0063b4 16.78%, #009af7 85.53%);
`;

export default DesktopRecommended;
