import styled from "styled-components";
import { Star } from "../../../components/Icons/Icons";

interface RatingProps {
  icon: JSX.Element;
  rating: string;
}
const RatingCard = ({ icon, rating }: RatingProps) => {
  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <StarWrapper>
        {[1, 2, 3, 4, 5].map((i) => (
          <Star key={i} />
        ))}
      </StarWrapper>
      <Number>{rating}</Number>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: max-content;
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #216ba8;

  @media (max-width: 767px) {
    padding: 6px 8px;
  }

  @media (max-width: 420px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    svg {
      width: auto;
      height: 14px;
    }
  }
`;

const StarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 767px) {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

const Number = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export default RatingCard;
