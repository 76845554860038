import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

interface TabProps {
  active?: boolean;
}

interface ITab {
  value: string;
  label: string;
}

interface TabsProps {
  tabs: ITab[];
  activeTab: string;
  onClickTab: (tab: string) => void;
}

const Tabs = ({ tabs, activeTab, onClickTab }: TabsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerScrollLeft = containerRef.current.scrollLeft;
      const activeTabIndex = tabs.findIndex((tab) => tab.value === activeTab);

      const activeTabElement = containerRef.current.children[activeTabIndex] as HTMLElement;
      const tabWidth = activeTabElement.offsetWidth || 0;
      const tabLeft = activeTabElement.offsetLeft || 0;

      if (containerScrollLeft + containerWidth < tabLeft + tabWidth * (tabs.length - 3)) {
        containerRef.current.scrollTo({
          left: tabWidth * 2,
          behavior: "smooth",
        });
        return;
      }
      if (containerScrollLeft + containerWidth > tabLeft + tabWidth * (tabs.length - 3)) {
        containerRef.current.scrollTo({
          left: -tabWidth * 2,
          behavior: "smooth",
        });
        return;
      }
    }
  }, [activeTab, tabs]);

  const handleClickTab = (tab: string) => {
    onClickTab(tab);
  };

  return (
    <TabContainer ref={containerRef}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          data-tab={tab.value}
          active={activeTab === tab.value}
          onClick={() => handleClickTab(tab.value)}
        >
          <span>{tab.label}</span>
        </Tab>
      ))}
    </TabContainer>
  );
};

const TabContainer = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    background-color: transparent;
  }
`;

const Tab = styled.button<TabProps>`
  padding: 4px 10px;
  width: max-content;
  max-width: 151px;
  white-space: nowrap;

  ${({ active }) =>
    active &&
    css`
      span {
        background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    `}

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};
    font-family: Mulish;
  }

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  -webkit-appearance: none;
  background: ${({ theme }) => theme.primaryBackground};

  &:hover {
    p {
      color: #0077ff;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: ${({ active }) => (active ? "1px" : "0px")};
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  }
`;

export default Tabs;
