import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/themeReducer";

export const UserCircleAdd = () => {
  const theme = useSelector(getTheme);
  const stroke = theme === "light" ? "#0063B4" : "#00B8E0";

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2799 18.3066C17.3491 18.3066 19.0265 16.6292 19.0265 14.56C19.0265 12.4907 17.3491 10.8133 15.2799 10.8133C13.2106 10.8133 11.5332 12.4907 11.5332 14.56C11.5332 16.6292 13.2106 18.3066 15.2799 18.3066Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M22.1999 26.9333C22.1999 23.8267 19.1065 21.2933 15.2799 21.2933C11.4532 21.2933 8.35986 23.8133 8.35986 26.9333"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M27.9998 16.6667C27.9998 23.6667 22.3332 29.3333 15.3332 29.3333C8.33317 29.3333 2.6665 23.6667 2.6665 16.6667C2.6665 9.66667 8.33317 4 15.3332 4C17.0798 4 18.7465 4.34665 20.2665 4.98665C20.0932 5.51999 19.9998 6.08 19.9998 6.66667C19.9998 7.66667 20.2798 8.61333 20.7732 9.41333C21.0398 9.86666 21.3865 10.28 21.7865 10.6266C22.7198 11.48 23.9598 12 25.3332 12C25.9198 12 26.4798 11.9066 26.9998 11.72C27.6398 13.24 27.9998 14.92 27.9998 16.6667Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M30.6667 6.66665C30.6667 7.09331 30.6133 7.50664 30.5067 7.90664C30.3867 8.43997 30.1733 8.95998 29.8933 9.41331C29.2533 10.4933 28.2267 11.3333 27 11.72C26.48 11.9066 25.92 12 25.3333 12C23.96 12 22.72 11.4799 21.7867 10.6266C21.3867 10.2799 21.04 9.86664 20.7733 9.41331C20.28 8.61331 20 7.66665 20 6.66665C20 6.07998 20.0933 5.51997 20.2667 4.98663C20.52 4.2133 20.9467 3.52001 21.5067 2.94668C22.48 1.94668 23.84 1.33331 25.3333 1.33331C26.9067 1.33331 28.3333 2.01334 29.2933 3.10667C30.1467 4.05334 30.6667 5.30665 30.6667 6.66665Z"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M27.32 6.63995H23.3467"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M25.3335 4.69336V8.68001"
        stroke={stroke}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
    </svg>
  );
};
