import styled from "styled-components";
import debounce from "lodash.debounce";
import InfiniteScroll from "react-infinite-scroll-component";
import { ChromePicker } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";

import Tabs from "../Tabs";
import TitleWithAction from "../TitleWithAction";
import Textfield from "../../../../components/Textfield/Textfield";
import { BackgroundProps, backgroundTabs, colors } from "../../../../mocks/humans";
import { VideoEditorContext } from "../../../../App";
import {
  Asset,
  clearAsset,
  createUserAssetServer,
  getAssetServer,
  searchAssetResultLoading,
} from "../../../../redux/actions/assetAction";
import { getProfile } from "../../../../redux/reducers/profileReducer";
import { PlusIcon, SearchIcon } from "../../../../components/Icons/Icons";
import { useImageBackground } from "../../../../hooks/useImageBackground";
import { useVideoBackground } from "../../../../hooks/useVideoBackground";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../../components/Button/IconButton";
import BinIcon from "../../../../components/Icons/BinIcon";
import { Popups, updatePopup } from "../../../../redux/actions/popupsActions";
import { AssetModules, CropImage } from "../../../../types/asset";
import { ConfirmationDelete, DeleteType } from "../../../../types/confirmationDelete";
import { useDebounce } from "../../../../hooks/useDebounce";

const ACCEPTED_FORMATS_IMAGES = "image/png, image/jpg, image/jpeg";
const ACCEPTED_FORMATS_VIDEOS = "video/mp4, video/quicktime, .mov";
const MAX_SIZE = 52428800;

const searchTimeoutDelay = 500;

enum AcceptedFormat {
  ImagePng = "image/png",
  ImageJpg = "image/jpg",
  ImageJpeg = "image/jpeg",
  VideoMp4 = "video/mp4",
  VideoMov = "video/quicktime",
}

interface Props {
  setBackgroundRef: (b: boolean) => void;
}

const BackgroundTab = ({ setBackgroundRef }: Props) => {
  const dispatch = useDispatch();
  const inputRef: any = useRef(null);
  const isFirstRender = useRef(false);
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;

  const { images, imageLoading, imageHasMore, imageCurrentPage, searchImageLoading, imageUploadLoading } =
    useImageBackground();
  const { videos, videoLoading, videoHasMore, videoCurrentPage, searchVideoLoading, videoUploadLoading } =
    useVideoBackground();
  const { handleBackgroundChange, handleBackgroundColorChange, currentScene, activeSceneId } =
    useContext(VideoEditorContext);

  const [activeBackground, setActiveBackground] = useState(backgroundTabs[0]);
  const [uploadedBackground, setUploadedBackground] = useState<string>("");
  const [errorSize, setErrorSize] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [loadedVideos, setLoadedVideos] = useState<any>([]);
  const [loadedImages, setLoadedImages] = useState<any>([]);
  const [color, setColor] = useState("#867D2D");
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (!currentScene) return;
    setColor(currentScene.slideBackgroundColor);
  }, [currentScene]);

  const colorPickerRef = useRef(null);

  const backgroundColor = useDebounce({ value: color, delay: 50 });

  const handleVideoLoaded = (index: number) => {
    const updatedVideos = [...loadedVideos];
    updatedVideos[index].loaded = true;
    setLoadedVideos(updatedVideos);
  };

  const handleImageLoaded = (index: number) => {
    const updatedImages = [...loadedImages];
    updatedImages[index].loaded = true;
    setLoadedImages(updatedImages);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleActiveBackground = (background: string) => {
    setActiveBackground(background as BackgroundProps);
  };

  const debounceHandleGetBackground = useRef(
    debounce(async (assetTypeId: Asset, search?: string) => {
      dispatch(
        getAssetServer({
          keyword: search,
          pageNumber: 1,
          assetTypeId,
          pageSize: 60,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  const fetchMore = (search: string) => {
    if (activeBackground === BackgroundProps.IMAGE) {
      dispatch(
        getAssetServer({
          keyword: search,
          pageNumber: imageCurrentPage + 1,
          assetTypeId: Asset.Images,
          pageSize: 60,
        }),
      );
    } else {
      dispatch(
        getAssetServer({
          keyword: search,
          pageNumber: videoCurrentPage + 1,
          assetTypeId: Asset.Videos,
          pageSize: 60,
        }),
      );
    }
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    if (uploadedFile && uploadedFile.size > MAX_SIZE) {
      setErrorSize(true);
    } else {
      const formData = new FormData();
      formData.append(
        "AssetTypeId",
        uploadedFile.type === AcceptedFormat.VideoMp4 || uploadedFile.type === AcceptedFormat.VideoMov ? "2" : "1",
      );
      formData.append("Status", "1");
      formData.append("FileName", uploadedFile.name);
      formData.append("File", uploadedFile);

      setUploadedBackground(URL.createObjectURL(uploadedFile));
      setErrorSize(false);

      if (
        uploadedFile.type === AcceptedFormat.ImageJpeg ||
        uploadedFile.type === AcceptedFormat.ImageJpg ||
        uploadedFile.type === AcceptedFormat.ImagePng
      ) {
        const prefilled: CropImage = {
          path: URL.createObjectURL(uploadedFile),
          typeId: Asset.Images,
          formData,
          fileType: uploadedFile.type,
          pageSize: 60,
        };

        dispatch(
          updatePopup({
            popup: Popups.cropImagePopup,
            status: true,
            prefilled,
          }),
        );
      } else {
        dispatch(
          createUserAssetServer({
            data: formData,
            assetTypeId: Asset.Videos,
            pageSize: 60,
          }),
        );
      }
    }
  };

  const handleUserAssetDelete = (e: any, id: number, assetTypeId: Asset) => {
    if (!id) {
      throw Error(`The ID ${AssetModules[assetTypeId]} is missing when deleting`);
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: `Are you sure you want to delete ${AssetModules[assetTypeId]}?`,
      description: `The ${AssetModules[assetTypeId]} will be removed and the action cannot be undone`,
      typeId: assetTypeId,
      type: DeleteType.Asset,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleColorChange = (event: any) => {
    // if (!currentObject) return;
    const value = event.target.value;
    // const {
    //   rgb: { r, g, b, a },
    // } = event;
    // const rgba = `rgba(${r}, ${g}, ${b}, ${a})`;
    // handleSceneObjectChange("background", rgba, currentObject.id);
    setColor(value);
  };

  const handlePickerToogle = () => {
    // setIsPickerOpen(!isPickerOpen);
    if (!colorPickerRef.current) return;
    (colorPickerRef.current as HTMLElement).click();
  };

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    if (!backgroundColor) return;
    handleBackgroundColorChange(backgroundColor);
  }, [backgroundColor]);

  useEffect(() => {
    // TODO
    dispatch(searchAssetResultLoading(true, activeBackground === BackgroundProps.IMAGE ? Asset.Images : Asset.Videos));
    dispatch(clearAsset({ assetTypeId: activeBackground === BackgroundProps.IMAGE ? Asset.Images : Asset.Videos }));
    debounceHandleGetBackground(activeBackground === BackgroundProps.IMAGE ? Asset.Images : Asset.Videos, search);
  }, [search]);

  useEffect(() => {
    // TODO
    if (isFirstRender.current) {
      dispatch(clearAsset({ assetTypeId: activeBackground === BackgroundProps.IMAGE ? Asset.Images : Asset.Videos }));
      debounceHandleGetBackground(activeBackground === BackgroundProps.IMAGE ? Asset.Images : Asset.Videos, search);
      setBackgroundRef(activeBackground === BackgroundProps.VIDEO);
    }
  }, [activeBackground]);

  useEffect(() => {
    isFirstRender.current = true;
  }, []);

  useEffect(() => {
    if (uploadedBackground && (!imageUploadLoading || !videoUploadLoading) && inputRef.current.value) {
      inputRef.current.value = null;
    }
  }, [uploadedBackground, imageUploadLoading, videoUploadLoading]);

  useEffect(() => {
    const newVideos = videos?.map((video) => {
      return {
        ...video,
        loaded: false,
      };
    });
    setLoadedVideos(newVideos);
  }, [videos]);

  useEffect(() => {
    const newImages = images?.map((image) => {
      return {
        ...image,
        loaded: false,
      };
    });
    setLoadedImages(newImages);
  }, [images]);

  const content = {
    [BackgroundProps.IMAGE]: (
      <ImagesWrapper id="scrollableDiv">
        {!isGuest && (
          <UploadWrapper>
            <input
              ref={inputRef}
              type="file"
              id="background"
              name="background"
              accept={ACCEPTED_FORMATS_IMAGES}
              onChange={handleUpload}
            />
            {imageUploadLoading ? <CircularProgress color="#009af7" /> : <PlusIcon />}
          </UploadWrapper>
        )}
        {imageLoading && !loadedImages.length ? (
          <CircularProgressWrapper style={{ height: !isGuest ? "calc(100% - 73px)" : "100%" }}>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : (
          <InfiniteScroll
            next={() => !searchImageLoading && fetchMore(search)}
            hasMore={imageHasMore}
            loader={
              imageLoading ? (
                <>
                  <Placeholder />
                  <Placeholder />
                  <Placeholder />
                </>
              ) : null
            }
            dataLength={images?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableDiv"
          >
            {images?.map((image: any, index: number) => (
              <ImageWrapper key={index}>
                {!image.loaded && <Placeholder />}
                <img
                  onClick={() => handleBackgroundChange(image.path, image.userAssetID, 1)}
                  src={image.path}
                  onLoad={() => handleImageLoaded(index)}
                  alt=""
                />
                {!!image.userId && (
                  <IconButton
                    icon={<BinIcon />}
                    iconButtonTheme={IconButtonThemes.Secondary}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleUserAssetDelete(e, image.userAssetID, Asset.Images);
                    }}
                  />
                )}
              </ImageWrapper>
            ))}
          </InfiniteScroll>
        )}
      </ImagesWrapper>
    ),
    [BackgroundProps.VIDEO]: (
      <ImagesWrapper id="scrollableDiv">
        {!isGuest && (
          <UploadWrapper>
            <input
              ref={inputRef}
              type="file"
              id="background"
              name="background"
              accept={ACCEPTED_FORMATS_VIDEOS}
              onChange={handleUpload}
            />
            {videoUploadLoading ? <CircularProgress color="#009af7" /> : <PlusIcon />}
          </UploadWrapper>
        )}
        {videoLoading && !loadedVideos.length ? (
          <CircularProgressWrapper style={{ height: !isGuest ? "calc(100% - 73px)" : "100%" }}>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : (
          <InfiniteScroll
            next={() => !searchVideoLoading && fetchMore(search)}
            hasMore={videoHasMore}
            loader={
              videoLoading ? (
                <>
                  <Placeholder />
                  <Placeholder />
                  <Placeholder />
                </>
              ) : null
            }
            dataLength={videos?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableDiv"
          >
            {videos?.map((video: any, index: number) => (
              <VideoWrapper key={index}>
                {!video.loaded && <Placeholder />}
                <video
                  src={video.path}
                  onClick={() => handleBackgroundChange(video.path, video.userAssetID, 2)}
                  onLoadedData={() => handleVideoLoaded(index)}
                />
                {!!video.userId && (
                  <IconButton
                    icon={<BinIcon />}
                    iconButtonTheme={IconButtonThemes.Secondary}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleUserAssetDelete(e, video.userAssetID, Asset.Videos);
                    }}
                  />
                )}
              </VideoWrapper>
            ))}
          </InfiniteScroll>
        )}
      </ImagesWrapper>
    ),
    [BackgroundProps.COLOR]: (
      <ImagesWrapper>
        <ColorWithPickerWrapper>
          <ColorWrapper backgroundColor={backgroundColor} onClick={handlePickerToogle}>
            <span>Custom color</span>
            <input ref={colorPickerRef} type="color" onChange={handleColorChange} color={backgroundColor} hidden />
          </ColorWrapper>
        </ColorWithPickerWrapper>
        {colors?.map((color, index: number) => (
          <ColorWrapper
            onClick={() => handleBackgroundColorChange(color)}
            key={index}
            style={{ backgroundColor: color }}
          />
        ))}
      </ImagesWrapper>
    ),
  };

  return (
    <Wrapper>
      <TitleWithAction title="Background">
        <Tabs data={backgroundTabs} active={activeBackground} handleActive={handleActiveBackground} />
        {(activeBackground === BackgroundProps.IMAGE || activeBackground === BackgroundProps.VIDEO) && (
          <Textfield
            value={search}
            placeholder="Search for images/videos…"
            startAdornment={<SearchIcon />}
            onKeyDown={(event) => {
              if (event.key === "Backspace" || event.key === "Delete") {
                event.stopPropagation();
              }
            }}
            onChange={handleSearchChange}
          />
        )}
        <Content>{content[activeBackground]}</Content>
      </TitleWithAction>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 9px;
  border-radius: 20px;
  position: relative;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  margin-top: 12px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Size = styled.span<{ sizeError?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background: ${({ theme }) => theme.button};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  ${({ sizeError }) =>
    sizeError &&
    `
       color: red;
       background: none !important;
        -webkit-text-fill-color: red !important;
  `}
`;

const ImagesWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  overflow: hidden auto;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 8px;
  column-gap: 8px;
  margin-bottom: 24px;
  align-content: start;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 57px;
  height: 57px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  &:hover > button {
    opacity: 1;
    transition: 0.2s;
  }

  button {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    box-shadow: none;
    opacity: 0;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: red;
      }
    }
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 57px;
  height: 57px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  &:hover > button {
    opacity: 1;
    transition: 0.2s;
  }

  button {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    box-shadow: none;
    opacity: 0;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: red;
      }
    }
  }
`;

const Placeholder = styled.div`
  width: 57px;
  height: 57px;
  border-radius: 12px;
  background: linear-gradient(-90deg, #e9e9e9, #c4c3c3);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ColorWithPickerWrapper = styled.div`
  position: relative;
`;

const ColorWrapper = styled.div<{ backgroundColor?: string }>`
  position: relative;
  width: 120px;
  height: 80px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;

  span {
    margin-top: 14px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: ${({ theme }) => theme.primaryText};
  }

  ${({ backgroundColor }) =>
    !!backgroundColor &&
    `
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 116px;
          height: 100%;
          border: 2px solid ${backgroundColor};
          border-radius: 12px;
        }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${backgroundColor};
      }
    `}
`;

const UploadWrapper = styled.div`
  position: relative;
  width: 57px;
  height: 57px;
  border-radius: 16px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme.activeMenu};

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

export default BackgroundTab;
