import { EditorState } from "draft-js";

import TextTab from "./tabs/TextTab";
import MediaTab from "./tabs/MediaTab";
import ShapesTab from "./tabs/ShapesTab";
import HumatarTab from "./tabs/HumatarTab";
import TemplateTab from "./tabs/TemplateTab";
import SoundtrackTab from "./tabs/SoundtrackTab";
import TransitionTab from "./tabs/TransitionTab";
import BackgroundTab from "./tabs/BackgroundTab";
import { ProfileHumanSidebarType } from "../../../types/human";
import { HumansParagraph } from "../../../types/humansProject";
import { SentryErrors, sentryErrors } from "../../../lib/sentry";

interface Props {
  activeSubSidebarData: any;
  setBackgroundRef: (b: boolean) => void;
  backgroundSoundTrackId: number | null;
  handleBackgroundSoundTrackId: (id: number) => void;
  canvasSize: { width: number; height: number };
  paragraphs: HumansParagraph[];
  setParagraphs: (nextParagraphs: HumansParagraph[]) => void;
  setEditorContent: React.Dispatch<React.SetStateAction<EditorState[]>>;
}

const Sidebar = ({
  activeSubSidebarData,
  setBackgroundRef,
  backgroundSoundTrackId,
  handleBackgroundSoundTrackId,
  canvasSize,
  setEditorContent,
  paragraphs,
  setParagraphs,
}: Props) => {
  switch (activeSubSidebarData.type) {
    case ProfileHumanSidebarType.Background:
      return <BackgroundTab setBackgroundRef={setBackgroundRef} />;
    case ProfileHumanSidebarType.Humatar:
      return <HumatarTab canvasSize={canvasSize} />;
    case ProfileHumanSidebarType.Shapes:
      return <ShapesTab canvasSize={canvasSize} />;
    case ProfileHumanSidebarType.Soundtrack:
      return (
        <SoundtrackTab
          backgroundSoundTrackId={backgroundSoundTrackId}
          handleBackgroundSoundTrackId={handleBackgroundSoundTrackId}
        />
      );
    case ProfileHumanSidebarType.Text:
      return <TextTab />;
    case ProfileHumanSidebarType.Transitions:
      return <TransitionTab />;
    case ProfileHumanSidebarType.Media:
      return <MediaTab />;
    case ProfileHumanSidebarType.Templates:
      return <TemplateTab paragraphs={paragraphs} setParagraphs={setParagraphs} setEditorContent={setEditorContent} />;
    default:
      sentryErrors({
        errorType: SentryErrors.NON_EXISTENT_TAB,
        details: {
          type: activeSubSidebarData.type,
        },
      });
      return <></>;
  }
};

export default Sidebar;
