import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getPaymentMethodPKSServer } from "../../redux/actions/plansActions";
import { useSelector } from "react-redux";
import { getProfile } from "../../redux/reducers/profileReducer";
import { getPaymentMethodPKS, getPaymentMethodPKSLoading, getUserPlans } from "../../redux/reducers/plansReducer";
import CircularProgress from "../Icons/CircularProgress";

interface Props {
  open: boolean;
  onClose: () => void;
}

const PaymentMethodPKSPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const plans = useSelector(getUserPlans);
  const paymentMethodPKS = useSelector(getPaymentMethodPKS);
  const paymentMethodPKSLoading = useSelector(getPaymentMethodPKSLoading);

  useEffect(() => {
    const activePlans = plans.filter((plan) => !plan.isCancelled);
    if (!activePlans.length) return onClose();

    dispatch(getPaymentMethodPKSServer({ existingUserLicenseId: activePlans[0].userLicenseID }));
  }, [plans]);

  return (
    <Wrapper className={open ? "active" : ""}>
      <div className="wrapper">
        {paymentMethodPKSLoading ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : (
          paymentMethodPKS && <FrameWrapper dangerouslySetInnerHTML={{ __html: paymentMethodPKS }} />
        )}
        <div className="closePopup" onClick={onClose}>
          <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M777.856 280.192l-33.92-33.952-231.872 231.872-231.84-231.872-33.984 33.888 231.872 231.904-231.84 231.84 33.888 33.984 231.904-231.904 231.84 231.872 33.952-33.888-231.872-231.904z"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
      <Darkener onClick={onClose} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: none;

  &.active {
    opacity: 1;
    display: block;
  }

  .wrapper {
    width: 80%;
    height: 80%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  .closePopup {
    width: 30px;
    position: absolute;
    top: -40px;
    right: -40px;
    height: auto;
    z-index: 30;
    cursor: pointer;

    @media (max-width: 1100px) {
      right: 30px;
    }
  }
}
`;

const FrameWrapper = styled.div`
  height: 100%;
  width: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    overflow-y: scroll !important;
  }
`;

const Darkener = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #000;
  opacity: 0.8;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & path {
    fill: ${({ theme }) => theme.activeMenu};
  }
`;

export default PaymentMethodPKSPopup;
