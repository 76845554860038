import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProgressBar from "./ProgressBar";
import { pages } from "../../../lib/routeUtils";
import { downloadFile } from "../../../lib/downloadFile";
import { DownloadIcon, PlayIcon } from "../../../components/Icons/Icons";
import { VideoTranslationStatus } from "../../../types/videoTranslation";

interface Props {
  status: VideoTranslationStatus;
  updateDateTime: string;
  estimatedTime: number;
  videoTranslateProjectId: number;
  outputUrl: string;
}

const RecentlyGeneratedVideo = ({
  status,
  updateDateTime,
  estimatedTime,
  videoTranslateProjectId,
  outputUrl,
}: Props) => {
  const navigate = useNavigate();

  const [loadedVideos, setLoadedVideos] = useState<any>([]);
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const statusText = {
    [VideoTranslationStatus.Pending]: "Pending...",
    [VideoTranslationStatus.InProgress]: "Generating...",
    [VideoTranslationStatus.Completed]: "Completed",
    [VideoTranslationStatus.Failed]: "Failed To Generate",
  };

  const lastUpdateTime = new Date(updateDateTime + "Z");
  const timeElapsed = (Number(currentTime) - Number(lastUpdateTime)) / 1000;
  const percentComplete = (timeElapsed / Number(estimatedTime)) * 100;

  const handleVideoLoad = (videoSrc: string) => {
    setLoadedVideos((prevLoadedVideos: any) => ({
      ...prevLoadedVideos,
      [videoSrc]: true,
    }));
  };

  useEffect(() => {
    if (status === VideoTranslationStatus.InProgress || status === VideoTranslationStatus.Pending) {
      const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [status]);

  const translationStatus = statusText[status as VideoTranslationStatus];
  const isCompleted = status === VideoTranslationStatus.Completed;
  const hasFailed = status === VideoTranslationStatus.Failed;

  return (
    <Wrapper onClick={() => navigate(`${pages.videoTranslation()}/${videoTranslateProjectId}`)}>
      {isCompleted ? (
        <>
          <video src={outputUrl} onLoadedData={() => handleVideoLoad(outputUrl)} />
          {!loadedVideos[outputUrl] ? (
            <Placeholder />
          ) : (
            <>
              <PlayIconButton>
                <PlayIcon />
              </PlayIconButton>
              <DownloadButtonWrapper onClick={(e: any) => downloadFile(e, outputUrl)}>
                <DownloadIcon />
              </DownloadButtonWrapper>
            </>
          )}
        </>
      ) : (
        <StatusWrapper status={status}>
          <div>
            <span>
              {translationStatus} <p>project #{videoTranslateProjectId}</p>
            </span>
            {!hasFailed && <ProgressBar value={percentComplete} maxHeight={12} />}
          </div>
        </StatusWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  width: 205px;
  height: 105px;

  @media (max-width: 400px) {
    max-width: 100%;
    max-height: 128px;
    width: 100%;
  }

  video {
    width: 205px;
    height: 105px;
    object-fit: cover;
    @media (max-width: 400px) {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    button,
    div {
      display: flex;
    }
  }
`;

const StatusWrapper = styled.div<{ status: VideoTranslationStatus }>`
  width: 205px;
  height: 105px;
  padding: 12px;
  background: #6d6d6d33;
  position: relative;
  display: flex;
  align-items: ${({ status }) => (status === VideoTranslationStatus.Failed ? "center" : "end")};
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 166px;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      text-transform: capitalize;

      p {
        max-width: 166px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

const PlayIconButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 32px;
  height: 32px;
  padding: 8.08px 5.91px 7.92px 11.09px;
  border-radius: 54.02px;
  angle: -0 deg;
  box-shadow: 3.3762717247009277px 3.3762717247009277px 6.7525434494018555px 0px #aeaec033,
    1.3505089282989502px 1.3505089282989502px 2.7010178565979004px 0px #ffffff inset,
    -1.3505089282989502px -1.3505089282989502px 2.7010178565979004px 0px #0000001a inset;
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
`;

const DownloadButtonWrapper = styled.div`
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
  align-items: center;
  background: #f0f0f3;
  padding: 0px 6px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  max-height: 24px;
  box-shadow: -2px -2px 4px 0px #0000001a inset;

  span {
    color: #191b1f;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  }
  svg {
    width: 11px;
    height: 11px;
    path {
      fill: #191b1f;
    }
  }
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #e9e9e9, #c4c3c3);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @media (max-width: 400px) {
    width: 100%;
    height: 100%;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default RecentlyGeneratedVideo;
