import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import { deleteAccountLoading } from "../../redux/reducers/profileReducer";
import { deleteAccountServer } from "../../redux/actions/profileActions";

interface Props {
  open: boolean;
  onClose: () => void;
}

const DeleteAccountPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const isLoading = useSelector(deleteAccountLoading);

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  const handleDeleteAccount = () => {
    dispatch(deleteAccountServer());
  };

  useEffect(() => {
    if (!isFirstRender.current && !isLoading) {
      handleClose();
    }

    isFirstRender.current = false;
  }, [isLoading]);

  return (
    <Wrapper>
      <Modal
        className="download-popup"
        title="Are you sure you want to delete your account?"
        open={open}
        onClose={handleClose}
        closeIcon={false}
        maxWidth={500}
        maxHeight={256}
        description="This action cannot be undone. It will permanently remove all your projects, assets and your account"
      >
        <Form>
          <span>
            <b>Note:</b> deleting the account does not cancel the subscription. Please contact our support team to
            cancel your subscription.
          </span>
          <ButtonWrapper>
            <Button text="Cancel" buttonTheme={ButtonThemes.Outline} onClick={handleClose} />
            <Button
              text={isLoading ? "Loading..." : "Delete Account"}
              onClick={isLoading ? () => null : handleDeleteAccount}
            />
          </ButtonWrapper>
        </Form>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-wrapper {
    z-index: 103;
  }

  .modal-darkener {
    z-index: 102;
  }
`;

const Form = styled.form`
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.6;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 32px;

  button {
    max-height: 48px;
  }
`;

export default DeleteAccountPopup;
