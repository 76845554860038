import React, { useContext } from "react";
import styled from "styled-components";

import { ObjectTypes, SceneObject, Text } from "../../../types/scene";

import Chip from "../../../components/Chip/Chip";

import { cutText } from "../../../lib/textUtils";
import { VideoEditorContext } from "../../../App";

const ObjectChips = () => {
  const { handleChangeActiveObject, handleRemoveTextChip, currentScene } = useContext(VideoEditorContext);

  const objects = currentScene?.objects.filter((obj: SceneObject) => obj.type === ObjectTypes.texts) || [];
  const activeId = currentScene?.activeObjectId || 0;

  // setScenes((scenes) => scenes.map((scene) => (scene.id === currentScene.id ? { ...scene, texts: newObj } : scene)));
  const handleRemove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: number) => {
    event.stopPropagation();
    handleRemoveTextChip(id);
  };
  return (
    <ChipsWrapper>
      {objects.map(({ object: obj }: { object: any }) => (
        <ChipWrapper key={obj.id} active={obj.id === activeId}>
          <Chip
            onRemove={() => handleChangeActiveObject(obj.id)}
            onClose={(event) => handleRemove(event, obj.id)}
            title={cutText((obj as Text).text)}
          />
        </ChipWrapper>
      ))}
    </ChipsWrapper>
  );
};

const ChipsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
`;

const ChipWrapper = styled.div<{ active: boolean }>`
  & span {
    ${({ active, theme }) =>
      active
        ? `background: ${theme.button};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;`
        : `color: ${theme.primaryText}66;`}
  }

  & > div {
    cursor: pointer;
    ${({ active }) => (!active ? "border: none" : "")}
  }

  & svg path {
    fill: #9a9b9e;
  }
`;

export default ObjectChips;
