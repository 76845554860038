import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import HistoryTabItem from "./HistoryTabItem";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import {
  getVoiceoverHistory,
  getVoiceoverHistoryHasMore,
  getVoiceoverHistoryLoading,
  getVoiceoverHistoryPageNumber,
} from "../../../../redux/reducers/actorReducer";
import { getVoiceoverHistoryServer } from "../../../../redux/actions/actorActions";

const HistoryTab = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [oneAudioPlaying, setOneAudioPlaying] = useState("");

  const history = useSelector(getVoiceoverHistory);
  const hasMore = useSelector(getVoiceoverHistoryHasMore);
  const isLoading = useSelector(getVoiceoverHistoryLoading);
  const pageNumber = useSelector(getVoiceoverHistoryPageNumber);

  const dispatch = useDispatch();

  const getMoreVoiceoverHistory = () => {
    dispatch(getVoiceoverHistoryServer(pageNumber));
  };

  const handleChangeOneAudioPlaying = (output: string) => {
    setOneAudioPlaying(output);
  };

  useEffect(() => {
    if (!isLoading) {
      setInitialLoading(false);
    }
  }, [isLoading]);

  return (
    <Wrapper>
      {initialLoading ? (
        <EmptyState>
          <CircularProgress color="#fff" />
        </EmptyState>
      ) : !history.length ? (
        <EmptyState>
          <span>No history yet.</span>
        </EmptyState>
      ) : (
        <Content>
          <BoxWrapper>
            <ItemsList>
              {history.map((historyItem) => (
                <HistoryTabItem
                  key={historyItem.output}
                  historyItem={historyItem}
                  oneAudioPlaying={oneAudioPlaying}
                  handleChangeOneAudioPlaying={handleChangeOneAudioPlaying}
                />
              ))}
            </ItemsList>
          </BoxWrapper>
          {hasMore && !isLoading && (
            <ShowMore
              buttonTheme={ButtonThemes.Transparent}
              isLoading={false}
              onClick={getMoreVoiceoverHistory}
              text="Show more"
            />
          )}
          {isLoading && (
            <LoaderWrapper>
              <CircularProgress color="#fff" />
            </LoaderWrapper>
          )}
        </Content>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;

  padding-bottom: 20px;
  margin-bottom: 20px;

  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  // height: 100%;
  max-width: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px 20px 0;

  img {
    width: 121px;
    height: 120px;
    border-radius: 50%;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  margin-left: 25px;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ShowMore = styled(Button)<{ isLoading: boolean }>`
  // position: absolute;
  //   z-index: 6;
  // bottom: 0px;

  display: flex;
  height: 36px;
  justify-content: center;
  margin-top: 19px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.41px;
  background: ${({ theme }) => theme.button};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${({ isLoading }) =>
    isLoading &&
    `
    display: none;
  `}

  span {
    color: #106cb6;
  }

  @media (max-width: 1001px) {
    display: none;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export default HistoryTab;
