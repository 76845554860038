import { useRef, useEffect, useState, useCallback } from "react";

type UseCanvasDrawingReturnType = {
  canvasRef: (node: HTMLCanvasElement) => void;
  isEraseMode: boolean;
  isUndoDisabled: boolean;
  isRedoDisabled: boolean;
  switchMode: () => void;
  eraseAll: () => void;
  undo: () => void;
  redo: () => void;
  getImageBase64: () => string;
};

type UseCanvasDrawingProps = {
  brushOptions: {
    sizePx: number;
    color: string;
  };
  bgImageOptions: {
    url: string;
    maxAllowedWidthPx: number;
    maxAllowedHeightPx: number;
  };
};

const useCanvasDrawing = ({ brushOptions, bgImageOptions }: UseCanvasDrawingProps): UseCanvasDrawingReturnType => {
  const initialStateSavedRef = useRef<boolean>(false);
  const canvasMeasuredRef = useCallback((node: HTMLCanvasElement) => setCanvas(node), []);

  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [isEraseMode, setIsEraseMode] = useState<boolean>(false);
  const [drawing, setDrawing] = useState(false);
  const [lastX, setLastX] = useState(0);
  const [lastY, setLastY] = useState(0);
  const [undoStack, setUndoStack] = useState<string[]>([]);
  const [redoStack, setRedoStack] = useState<string[]>([]);

  const isUndoDisabled = undoStack.length < 2;
  const isRedoDisabled = redoStack.length < 1;

  useEffect(() => {
    const ctx = canvas?.getContext("2d");
    if (ctx) setContext(ctx);
  }, [canvas]);

  useEffect(() => {
    if (!initialStateSavedRef.current && canvas && context) {
      saveDrawingState();

      initialStateSavedRef.current = true;
    }
  }, [context, canvas]);

  useEffect(() => {
    if (bgImageOptions.url) {
      const image = new Image();
      image.src = bgImageOptions.url;

      image.onload = () => {
        const widthRatio = image.naturalWidth / bgImageOptions.maxAllowedWidthPx;
        const heightRatio = image.naturalHeight / bgImageOptions.maxAllowedHeightPx;
        const divisor = Math.max(widthRatio, heightRatio);

        const width = image.naturalWidth / divisor;
        const height = image.naturalHeight / divisor;

        if (canvas) {
          canvas.width = width;
          canvas.height = height;

          canvas.style.display = "block";
          canvas.style.backgroundSize = "contain";
          canvas.style.backgroundRepeat = "no-repeat";
          canvas.style.backgroundImage = `url(${bgImageOptions.url})`;
          canvas.style.cursor = "none";

          if (initialStateSavedRef.current) {
            setIsEraseMode(false);

            const nextUndoStack = canvas && context ? [canvas.toDataURL()] : [];
            setUndoStack(nextUndoStack);
            setRedoStack([]);
          }
        }
      };

      return () => {
        image.onload = null;
      };
    }
  }, [bgImageOptions.url, canvas]);

  useEffect(() => {
    if (canvas) {
      const startDrawing = function (this: GlobalEventHandlers, e: MouseEvent) {
        setDrawing(true);
        setLastX(e.offsetX);
        setLastY(e.offsetY);
      };

      const stopDrawing = () => {
        if (drawing) {
          setDrawing(false);
          saveDrawingState();
        }
      };

      const handleMouseMove = function (this: GlobalEventHandlers, e: MouseEvent) {
        if (!drawing || !context) return;

        const newX = e.offsetX;
        const newY = e.offsetY;

        if (isEraseMode) {
          context.save();
          context.globalCompositeOperation = "destination-out";
          context.beginPath();
          context.arc(newX, newY, brushOptions.sizePx / 2, 0, 2 * Math.PI, false);
          context.fill();
          context.restore();
        } else {
          context.beginPath();
          context.moveTo(lastX, lastY);
          context.lineTo(newX, newY);
          context.strokeStyle = brushOptions.color;
          context.lineWidth = brushOptions.sizePx;
          context.lineCap = "round";
          context.stroke();
          context.closePath();
          setLastX(newX);
          setLastY(newY);
        }
      };

      canvas.addEventListener("mousedown", startDrawing);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", stopDrawing);
      canvas.addEventListener("mouseout", stopDrawing);

      return () => {
        canvas?.removeEventListener("mousemove", handleMouseMove);
        canvas?.removeEventListener("mouseup", stopDrawing);
        canvas?.removeEventListener("mouseout", stopDrawing);
      };
    }
  }, [drawing, context, lastX, lastY, canvas]);

  const switchMode = () => setIsEraseMode((prev) => !prev);

  const eraseAll = () => {
    if (context && canvas) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      saveDrawingState();
    }
  };

  const saveDrawingState = () => {
    if (canvas && context) {
      const imageData = canvas.toDataURL();
      setUndoStack((stack) => [...stack, imageData]);
      setRedoStack([]);
    }
  };

  const undo = () => {
    if (isUndoDisabled) return;

    if (canvas && context) {
      const currImageData = undoStack[undoStack.length - 1];
      const lastImageData = undoStack[undoStack.length - 2];

      const image = new Image();
      image.src = lastImageData;

      image.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0);

        setUndoStack((stack) => stack.slice(0, stack.length - 1));
        setRedoStack((stack) => [...stack, currImageData]);
      };
    }
  };

  const redo = () => {
    if (isRedoDisabled) return;

    if (canvas && context) {
      const nextImageData = redoStack[redoStack.length - 1];

      const image = new Image();
      image.src = nextImageData;

      image.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, 0, 0);

        setRedoStack((stack) => stack.slice(0, stack.length - 1));
        setUndoStack((stack) => [...stack, nextImageData]);
      };
    }
  };

  const getImageBase64 = (): string => {
    if (undoStack.length) return undoStack.slice(-1).pop() || "";
    return "";
  };

  return {
    canvasRef: canvasMeasuredRef,
    isEraseMode,
    isUndoDisabled,
    isRedoDisabled,
    switchMode,
    eraseAll,
    undo,
    redo,
    getImageBase64,
  };
};

export default useCanvasDrawing;
