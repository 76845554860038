export const PlanIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99997 11.6666H7.49997C7.03997 11.6666 6.66664 11.2933 6.66664 10.8333C6.66664 10.3733 7.03997 9.99996 7.49997 9.99996H9.99997C10.46 9.99996 10.8333 10.3733 10.8333 10.8333C10.8333 11.2933 10.46 11.6666 9.99997 11.6666ZM6.66664 14.1666C6.66664 13.7066 7.03997 13.3333 7.49997 13.3333H12.5C12.9608 13.3333 13.3333 13.7066 13.3333 14.1666C13.3333 14.6266 12.9608 15 12.5 15H7.49997C7.03997 15 6.66664 14.6266 6.66664 14.1666ZM14.5369 16.6666H5.46272C5.20772 16.6666 5.00022 16.48 5.00022 16.25V3.74996C5.00022 3.51996 5.20772 3.33329 5.46272 3.33329H10.0002V5.95829C10.0002 7.26829 11.0144 8.33329 12.2619 8.33329H15.0002V16.25C15.0002 16.48 14.7927 16.6666 14.5369 16.6666ZM11.6669 4.14829L13.9519 6.66663H12.2619C11.9336 6.66663 11.6669 6.34913 11.6669 5.95829V4.14829ZM16.4502 6.93996L11.9136 1.93996C11.7552 1.76579 11.5319 1.66663 11.2961 1.66663H5.46272C4.28856 1.66663 3.33356 2.60163 3.33356 3.74996V16.25C3.33356 17.3983 4.28856 18.3333 5.46272 18.3333H14.5369C15.7111 18.3333 16.6669 17.3983 16.6669 16.25V7.49996C16.6669 7.29246 16.5894 7.09329 16.4502 6.93996Z"
      fill="#191B1F"
    />
    <mask id="mask0_493_11342" maskUnits="userSpaceOnUse" x="3" y="1" width="14" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99997 11.6666H7.49997C7.03997 11.6666 6.66664 11.2933 6.66664 10.8333C6.66664 10.3733 7.03997 9.99996 7.49997 9.99996H9.99997C10.46 9.99996 10.8333 10.3733 10.8333 10.8333C10.8333 11.2933 10.46 11.6666 9.99997 11.6666ZM6.66664 14.1666C6.66664 13.7066 7.03997 13.3333 7.49997 13.3333H12.5C12.9608 13.3333 13.3333 13.7066 13.3333 14.1666C13.3333 14.6266 12.9608 15 12.5 15H7.49997C7.03997 15 6.66664 14.6266 6.66664 14.1666ZM14.5369 16.6666H5.46272C5.20772 16.6666 5.00022 16.48 5.00022 16.25V3.74996C5.00022 3.51996 5.20772 3.33329 5.46272 3.33329H10.0002V5.95829C10.0002 7.26829 11.0144 8.33329 12.2619 8.33329H15.0002V16.25C15.0002 16.48 14.7927 16.6666 14.5369 16.6666ZM11.6669 4.14829L13.9519 6.66663H12.2619C11.9336 6.66663 11.6669 6.34913 11.6669 5.95829V4.14829ZM16.4502 6.93996L11.9136 1.93996C11.7552 1.76579 11.5319 1.66663 11.2961 1.66663H5.46272C4.28856 1.66663 3.33356 2.60163 3.33356 3.74996V16.25C3.33356 17.3983 4.28856 18.3333 5.46272 18.3333H14.5369C15.7111 18.3333 16.6669 17.3983 16.6669 16.25V7.49996C16.6669 7.29246 16.5894 7.09329 16.4502 6.93996Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_11342)"></g>
  </svg>
);
