export const MicrophoneIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 31C28.42 31 32 27.42 32 23V12C32 7.58 28.42 4 24 4C19.58 4 16 7.58 16 12V23C16 27.42 19.58 31 24 31Z"
      stroke="url(#paint0_linear_2507_1163)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7002 19.2998V22.6998C8.7002 31.1398 15.5602 37.9998 24.0002 37.9998C32.4402 37.9998 39.3002 31.1398 39.3002 22.6998V19.2998"
      stroke="url(#paint1_linear_2507_1163)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2202 12.8602C23.0202 12.2002 24.9802 12.2002 26.7802 12.8602"
      stroke="#0AD6DC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.2202 12.8602C23.0202 12.2002 24.9802 12.2002 26.7802 12.8602"
      stroke="url(#paint2_linear_2507_1163)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3999 17.1001C23.4599 16.8201 24.5599 16.8201 25.6199 17.1001"
      stroke="#0AD6DC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3999 17.1001C23.4599 16.8201 24.5599 16.8201 25.6199 17.1001"
      stroke="url(#paint3_linear_2507_1163)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 38V44"
      stroke="url(#paint4_linear_2507_1163)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2507_1163"
        x1="19.4462"
        y1="7.53077"
        x2="35.3543"
        y2="19.6513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2507_1163"
        x1="15.291"
        y1="21.7452"
        x2="24.1524"
        y2="40.3888"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2507_1163"
        x1="22.4178"
        y1="12.43"
        x2="22.4594"
        y2="13.0321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2507_1163"
        x1="23.0934"
        y1="16.9176"
        x2="23.1064"
        y2="17.1736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2507_1163"
        x1="24.2154"
        y1="38.7846"
        x2="25.7178"
        y2="39.1066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);

export const MicrophoneIconV2 = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.4934 12.16C24.9734 12.16 24.56 12.5733 24.56 13.0933V15.2C24.56 19.92 20.72 23.76 16 23.76C11.28 23.76 7.44003 19.92 7.44003 15.2V13.08C7.44003 12.56 7.0267 12.1467 6.5067 12.1467C5.9867 12.1467 5.57336 12.56 5.57336 13.08V15.1867C5.57336 20.6133 9.7467 25.08 15.0667 25.56V28.4C15.0667 28.92 15.48 29.3333 16 29.3333C16.52 29.3333 16.9334 28.92 16.9334 28.4V25.56C22.24 25.0933 26.4267 20.6133 26.4267 15.1867V13.08C26.4134 12.5733 26 12.16 25.4934 12.16Z"
      fill="#0063B4"
    />
    <path
      d="M16 2.66667C12.7467 2.66667 10.1067 5.30667 10.1067 8.56001V15.3867C10.1067 18.64 12.7467 21.28 16 21.28C19.2534 21.28 21.8934 18.64 21.8934 15.3867V8.56001C21.8934 5.30667 19.2534 2.66667 16 2.66667ZM17.7467 11.9333C17.6534 12.28 17.3467 12.5067 17 12.5067C16.9334 12.5067 16.8667 12.4933 16.8 12.48C16.28 12.3333 15.7334 12.3333 15.2134 12.48C14.7867 12.6 14.3734 12.3467 14.2667 11.9333C14.1467 11.52 14.4 11.0933 14.8134 10.9867C15.6 10.7733 16.4267 10.7733 17.2134 10.9867C17.6134 11.0933 17.8534 11.52 17.7467 11.9333ZM18.4534 9.34667C18.3334 9.66667 18.04 9.85334 17.72 9.85334C17.6267 9.85334 17.5467 9.84001 17.4534 9.81334C16.52 9.46667 15.48 9.46667 14.5467 9.81334C14.1467 9.96001 13.6934 9.74667 13.5467 9.34667C13.4 8.94667 13.6134 8.49334 14.0134 8.36001C15.2934 7.89334 16.7067 7.89334 17.9867 8.36001C18.3867 8.50667 18.6 8.94667 18.4534 9.34667Z"
      fill="#0063B4"
    />
  </svg>
);
