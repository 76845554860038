import styled from "styled-components";

import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import DefaultUserPricing from "./DefaultUserPricing";
import Navigation from "../../components/Navigation/Navigation";
import Button, { ButtonThemes } from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { pages } from "../../lib/routeUtils";

const PricingSubscriptions = () => {
  const navigate = useNavigate();

  const hadnleBackClick = () => {
    navigate(-1);
  };

  return (
    <OuterWrapper>
      <Navigation
        startAdornment={
          <Button
            text="Back"
            onClick={hadnleBackClick}
            buttonTheme={ButtonThemes.Secondary}
            icon={<img src="/images/arrow-left.svg" />}
          />
        }
      />
      <Wrapper>
        <DefaultUserPricing />
      </Wrapper>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  padding: 20px 40px;

  @media (max-width: 1001px) {
    padding: 20px 16px;
  }
`;

const Wrapper = styled("div")`
  width: 1222px;
  max-width: 90%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  //overflow: hidden;

  font-family: Mulish !important;

  @media (max-width: 1001px) {
    width: 100%;
    max-width: 100%;
  }
`;

export default withPrivateRoute(PricingSubscriptions);
