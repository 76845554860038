import React from "react";
import styled from "styled-components";
import { ShapeBorder } from "../../types/scene";

interface Props {
  background: string;
  opacity: number;
  border: ShapeBorder;
}

export const Arrow = ({ background, opacity }: Props) => (
  <StyledDiv background={background} opacity={opacity} className={"shape0"} />
);

const StyledDiv = styled.div<{ background: string; opacity: number }>`
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 50%, 82% 100%, 77% 97%, 93% 55%, 0 54%, 0 46%, 93% 46%, 77% 3%, 82% 0);
  background: ${({ background }) => background};
  opacity: ${({ opacity }) => opacity};
`;
