export const AtsLogoWhite = () => (
  <svg width="82" height="46" viewBox="0 0 82 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.214844 44.7153H80.219V3.57031H0.214844V44.7153Z" fill="white" />
    <path d="M0.314453 44.9059H80.7949V1.57031H0.314453V44.9059Z" fill="white" />
    <path
      d="M16.3368 25.332L18.7799 18.8621L21.1791 25.332H16.3368ZM20.5122 11.835H17.0425L8.82422 33.4879H13.1583L14.8881 29.0406H22.6649L24.3963 33.4879H28.7305L20.5122 11.835Z"
      fill="#08508B"
    />
    <path d="M34.9269 33.5029H30.7329V15.3695H25.6035V11.7917H39.7928V15.3695H34.9269V33.5029Z" fill="#08508B" />
    <path
      d="M59.1498 18.2568C59.0621 21.534 62.5444 23.2004 64.0783 24.1603C65.2011 24.8635 66.9317 25.847 67.3462 27.2163C67.58 27.987 67.2955 28.8355 66.8726 29.3167C66.399 29.8553 65.608 30.1558 64.7798 30.133C63.1167 30.0891 62.1982 28.6995 61.1354 27.0905L60.9666 26.8347L58.0938 29.6957L58.1436 29.7835C59.7146 32.5423 61.9762 34.04 64.6802 34.1117C67.0169 34.1733 68.9434 33.2143 70.2004 31.9278C71.3992 30.6995 71.9775 28.9494 71.8711 26.8659C71.7225 23.902 68.615 22.1089 66.3652 20.7176C64.4987 19.5636 63.2392 19.3357 63.2805 17.8035C63.316 16.4367 64.6633 15.617 65.808 15.6474C66.9857 15.6778 68.2166 16.3151 69.184 17.3957L69.2912 17.5156L71.5917 14.463L71.5123 14.3811C70.0485 12.8548 67.7877 11.8341 65.7523 11.7792C62.0243 11.6822 59.2478 14.5584 59.1498 18.2568Z"
      fill="#08508B"
    />
    <path
      d="M45.5486 30.3445C44.1236 30.3445 42.984 28.8638 42.984 27.5257C42.984 25.9876 44.5483 24.9568 45.6879 24.1177L49.0234 28.5033C48.1403 29.3399 46.8622 30.3445 45.5486 30.3445ZM46.9095 14.5537C48.0196 14.4929 48.6485 15.5499 48.7009 16.5275C48.7549 17.5321 48.3353 18.2251 47.6296 18.8786L46.9475 19.5016L45.8466 18.134C45.4786 17.6781 45.281 17.1589 45.2506 16.6009C45.1975 15.598 45.7707 14.6145 46.9095 14.5537ZM54.5731 28.5497L56.8322 26.1657L54.4474 23.3418L52.065 25.7841L49.0023 21.9168C50.7633 20.648 52.5732 18.8465 52.4483 16.5292C52.2786 13.3786 49.7764 11.4724 46.674 11.6404C43.6011 11.8059 41.3217 14.0261 41.4906 17.1496C41.5826 18.8507 42.3981 20.2048 43.4694 21.5184L42.1533 22.3862C40.215 23.6719 39.0449 25.5148 39.0449 27.8608C39.0449 31.4065 41.7261 34.0058 45.346 34.0058C47.7672 34.0058 49.735 32.8332 51.5036 31.3246L53.2427 33.4469H58.345L54.5731 28.5497Z"
      fill="#08508B"
    />
  </svg>
);
