export const languages = [
  {
    id: "Afrikaans (South Africa)",
    value: "Afrikaans (South Africa)",
    label: "Afrikaans (South Africa)",
  },
  {
    id: "Albanian (Albania)",
    value: "Albanian (Albania)",
    label: "Albanian (Albania)",
  },
  {
    id: "Amharic (Ethiopia)",
    value: "Amharic (Ethiopia)",
    label: "Amharic (Ethiopia)",
  },
  {
    id: "Arabic (Algeria)",
    value: "Arabic (Algeria)",
    label: "Arabic (Algeria)",
  },
  {
    id: "Arabic (Bahrain)",
    value: "Arabic (Bahrain)",
    label: "Arabic (Bahrain)",
  },
  {
    id: "Arabic (Egypt)",
    value: "Arabic (Egypt)",
    label: "Arabic (Egypt)",
  },
  {
    id: "Arabic (Iraq)",
    value: "Arabic (Iraq)",
    label: "Arabic (Iraq)",
  },
  {
    id: "Arabic (Jordan)",
    value: "Arabic (Jordan)",
    label: "Arabic (Jordan)",
  },
  {
    id: "Arabic (Kuwait)",
    value: "Arabic (Kuwait)",
    label: "Arabic (Kuwait)",
  },
  {
    id: "Arabic (Lebanon)",
    value: "Arabic (Lebanon)",
    label: "Arabic (Lebanon)",
  },
  {
    id: "Arabic (Libya)",
    value: "Arabic (Libya)",
    label: "Arabic (Libya)",
  },
  {
    id: "Arabic (Morocco)",
    value: "Arabic (Morocco)",
    label: "Arabic (Morocco)",
  },
  {
    id: "Arabic (Oman)",
    value: "Arabic (Oman)",
    label: "Arabic (Oman)",
  },
  {
    id: "Arabic (Qatar)",
    value: "Arabic (Qatar)",
    label: "Arabic (Qatar)",
  },
  {
    id: "Arabic (Saudi Arabia)",
    value: "Arabic (Saudi Arabia)",
    label: "Arabic (Saudi Arabia)",
  },
  {
    id: "Arabic (Syria)",
    value: "Arabic (Syria)",
    label: "Arabic (Syria)",
  },
  {
    id: "Arabic (Tunisia)",
    value: "Arabic (Tunisia)",
    label: "Arabic (Tunisia)",
  },
  {
    id: "Arabic (United Arab Emirates)",
    value: "Arabic (United Arab Emirates)",
    label: "Arabic (United Arab Emirates)",
  },
  {
    id: "Arabic (Yemen)",
    value: "Arabic (Yemen)",
    label: "Arabic (Yemen)",
  },
  {
    id: "Armenian (Armenia)",
    value: "Armenian (Armenia)",
    label: "Armenian (Armenia)",
  },
  {
    id: "Azerbaijani (Latin, Azerbaijan)",
    value: "Azerbaijani (Latin, Azerbaijan)",
    label: "Azerbaijani (Latin, Azerbaijan)",
  },
  {
    id: "Bangla (Bangladesh)",
    value: "Bangla (Bangladesh)",
    label: "Bangla (Bangladesh)",
  },
  {
    id: "Basque",
    value: "Basque",
    label: "Basque",
  },
  {
    id: "Bengali (India)",
    value: "Bengali (India)",
    label: "Bengali (India)",
  },
  {
    id: "Bosnian (Bosnia and Herzegovina)",
    value: "Bosnian (Bosnia and Herzegovina)",
    label: "Bosnian (Bosnia and Herzegovina)",
  },
  {
    id: "Bulgarian (Bulgaria)",
    value: "Bulgarian (Bulgaria)",
    label: "Bulgarian (Bulgaria)",
  },
  {
    id: "Burmese (Myanmar)",
    value: "Burmese (Myanmar)",
    label: "Burmese (Myanmar)",
  },
  {
    id: "Catalan (Spain)",
    value: "Catalan (Spain)",
    label: "Catalan (Spain)",
  },
  {
    id: "Chinese (Cantonese, Traditional)",
    value: "Chinese (Cantonese, Traditional)",
    label: "Chinese (Cantonese, Traditional)",
  },
  {
    id: "Chinese (Mandarin, Simplified)",
    value: "Chinese (Mandarin, Simplified)",
    label: "Chinese (Mandarin, Simplified)",
  },
  {
    id: "Chinese (Taiwanese Mandarin, Traditional)",
    value: "Chinese (Taiwanese Mandarin, Traditional)",
    label: "Chinese (Taiwanese Mandarin, Traditional)",
  },
  {
    id: "Croatian (Croatia)",
    value: "Croatian (Croatia)",
    label: "Croatian (Croatia)",
  },
  {
    id: "Czech (Czechia)",
    value: "Czech (Czechia)",
    label: "Czech (Czechia)",
  },
  {
    id: "Danish (Denmark)",
    value: "Danish (Denmark)",
    label: "Danish (Denmark)",
  },
  {
    id: "Dutch (Belgium)",
    value: "Dutch (Belgium)",
    label: "Dutch (Belgium)",
  },
  {
    id: "Dutch (Netherlands)",
    value: "Dutch (Netherlands)",
    label: "Dutch (Netherlands)",
  },
  {
    id: "English (Australia)",
    value: "English (Australia)",
    label: "English (Australia)",
  },
  {
    id: "English (Canada)",
    value: "English (Canada)",
    label: "English (Canada)",
  },
  {
    id: "English (Hong Kong SAR)",
    value: "English (Hong Kong SAR)",
    label: "English (Hong Kong SAR)",
  },
  {
    id: "English (India)",
    value: "English (India)",
    label: "English (India)",
  },
  {
    id: "English (Ireland)",
    value: "English (Ireland)",
    label: "English (Ireland)",
  },
  {
    id: "English (Kenya)",
    value: "English (Kenya)",
    label: "English (Kenya)",
  },
  {
    id: "English (New Zealand)",
    value: "English (New Zealand)",
    label: "English (New Zealand)",
  },
  {
    id: "English (Nigeria)",
    value: "English (Nigeria)",
    label: "English (Nigeria)",
  },
  {
    id: "English (Philippines)",
    value: "English (Philippines)",
    label: "English (Philippines)",
  },
  {
    id: "English (Singapore)",
    value: "English (Singapore)",
    label: "English (Singapore)",
  },
  {
    id: "English (South Africa)",
    value: "English (South Africa)",
    label: "English (South Africa)",
  },
  {
    id: "English (Tanzania)",
    value: "English (Tanzania)",
    label: "English (Tanzania)",
  },
  {
    id: "English (United Kingdom)",
    value: "English (United Kingdom)",
    label: "English (United Kingdom)",
  },
  {
    id: "English (United States)",
    value: "English (United States)",
    label: "English (United States)",
  },
  {
    id: "Estonian (Estonia)",
    value: "Estonian (Estonia)",
    label: "Estonian (Estonia)",
  },
  {
    id: "Finnish (Finland)",
    value: "Finnish (Finland)",
    label: "Finnish (Finland)",
  },
  {
    id: "French (Belgium)",
    value: "French (Belgium)",
    label: "French (Belgium)",
  },
  {
    id: "French (Canada)",
    value: "French (Canada)",
    label: "French (Canada)",
  },
  {
    id: "French (France)",
    value: "French (France)",
    label: "French (France)",
  },
  {
    id: "French (Switzerland)",
    value: "French (Switzerland)",
    label: "French (Switzerland)",
  },
  {
    id: "Galician",
    value: "Galician",
    label: "Galician",
  },
  {
    id: "Georgian (Georgia)",
    value: "Georgian (Georgia)",
    label: "Georgian (Georgia)",
  },
  {
    id: "German (Austria)",
    value: "German (Austria)",
    label: "German (Austria)",
  },
  {
    id: "German (Germany)",
    value: "German (Germany)",
    label: "German (Germany)",
  },
  {
    id: "German (Switzerland)",
    value: "German (Switzerland)",
    label: "German (Switzerland)",
  },
  {
    id: "Greek (Greece)",
    value: "Greek (Greece)",
    label: "Greek (Greece)",
  },
  {
    id: "Gujarati (India)",
    value: "Gujarati (India)",
    label: "Gujarati (India)",
  },
  {
    id: "Hebrew (Israel)",
    value: "Hebrew (Israel)",
    label: "Hebrew (Israel)",
  },
  {
    id: "Hindi (India)",
    value: "Hindi (India)",
    label: "Hindi (India)",
  },
  {
    id: "Hungarian (Hungary)",
    value: "Hungarian (Hungary)",
    label: "Hungarian (Hungary)",
  },
  {
    id: "Icelandic (Iceland)",
    value: "Icelandic (Iceland)",
    label: "Icelandic (Iceland)",
  },
  {
    id: "Indonesian (Indonesia)",
    value: "Indonesian (Indonesia)",
    label: "Indonesian (Indonesia)",
  },
  {
    id: "Irish (Ireland)",
    value: "Irish (Ireland)",
    label: "Irish (Ireland)",
  },
  {
    id: "Italian (Italy)",
    value: "Italian (Italy)",
    label: "Italian (Italy)",
  },
  {
    id: "Japanese (Japan)",
    value: "Japanese (Japan)",
    label: "Japanese (Japan)",
  },
  {
    id: "Javanese (Latin, Indonesia)",
    value: "Javanese (Latin, Indonesia)",
    label: "Javanese (Latin, Indonesia)",
  },
  {
    id: "Kannada (India)",
    value: "Kannada (India)",
    label: "Kannada (India)",
  },
  {
    id: "Kazakh (Kazakhstan)",
    value: "Kazakh (Kazakhstan)",
    label: "Kazakh (Kazakhstan)",
  },
  {
    id: "Khmer (Cambodia)",
    value: "Khmer (Cambodia)",
    label: "Khmer (Cambodia)",
  },
  {
    id: "Korean (Korea)",
    value: "Korean (Korea)",
    label: "Korean (Korea)",
  },
  {
    id: "Lao (Laos)",
    value: "Lao (Laos)",
    label: "Lao (Laos)",
  },
  {
    id: "Latvian (Latvia)",
    value: "Latvian (Latvia)",
    label: "Latvian (Latvia)",
  },
  {
    id: "Lithuanian (Lithuania)",
    value: "Lithuanian (Lithuania)",
    label: "Lithuanian (Lithuania)",
  },
  {
    id: "Macedonian (North Macedonia)",
    value: "Macedonian (North Macedonia)",
    label: "Macedonian (North Macedonia)",
  },
  {
    id: "Malay (Malaysia)",
    value: "Malay (Malaysia)",
    label: "Malay (Malaysia)",
  },
  {
    id: "Malayalam (India)",
    value: "Malayalam (India)",
    label: "Malayalam (India)",
  },
  {
    id: "Maltese (Malta)",
    value: "Maltese (Malta)",
    label: "Maltese (Malta)",
  },
  {
    id: "Marathi (India)",
    value: "Marathi (India)",
    label: "Marathi (India)",
  },
  {
    id: "Nepali (Nepal)",
    value: "Nepali (Nepal)",
    label: "Nepali (Nepal)",
  },
  {
    id: "Norwegian BokmÃ¥l (Norway)",
    value: "Norwegian BokmÃ¥l (Norway)",
    label: "Norwegian BokmÃ¥l (Norway)",
  },
  {
    id: "Pashto (Afghanistan)",
    value: "Pashto (Afghanistan)",
    label: "Pashto (Afghanistan)",
  },
  {
    id: "Persian (Iran)",
    value: "Persian (Iran)",
    label: "Persian (Iran)",
  },
  {
    id: "Polish (Poland)",
    value: "Polish (Poland)",
    label: "Polish (Poland)",
  },
  {
    id: "Portuguese (Brazil)",
    value: "Portuguese (Brazil)",
    label: "Portuguese (Brazil)",
  },
  {
    id: "Portuguese (Portugal)",
    value: "Portuguese (Portugal)",
    label: "Portuguese (Portugal)",
  },
  {
    id: "Romanian (Romania)",
    value: "Romanian (Romania)",
    label: "Romanian (Romania)",
  },
  {
    id: "Russian (Russia)",
    value: "Russian (Russia)",
    label: "Russian (Russia)",
  },
  {
    id: "Serbian (Cyrillic, Serbia)",
    value: "Serbian (Cyrillic, Serbia)",
    label: "Serbian (Cyrillic, Serbia)",
  },
  {
    id: "Sinhala (Sri Lanka)",
    value: "Sinhala (Sri Lanka)",
    label: "Sinhala (Sri Lanka)",
  },
  {
    id: "Slovak (Slovakia)",
    value: "Slovak (Slovakia)",
    label: "Slovak (Slovakia)",
  },
  {
    id: "Slovenian (Slovenia)",
    value: "Slovenian (Slovenia)",
    label: "Slovenian (Slovenia)",
  },
  {
    id: "Somali (Somalia)",
    value: "Somali (Somalia)",
    label: "Somali (Somalia)",
  },
  {
    id: "Spanish (Argentina)",
    value: "Spanish (Argentina)",
    label: "Spanish (Argentina)",
  },
  {
    id: "Spanish (Bolivia)",
    value: "Spanish (Bolivia)",
    label: "Spanish (Bolivia)",
  },
  {
    id: "Spanish (Chile)",
    value: "Spanish (Chile)",
    label: "Spanish (Chile)",
  },
  {
    id: "Spanish (Colombia)",
    value: "Spanish (Colombia)",
    label: "Spanish (Colombia)",
  },
  {
    id: "Spanish (Costa Rica)",
    value: "Spanish (Costa Rica)",
    label: "Spanish (Costa Rica)",
  },
  {
    id: "Spanish (Cuba)",
    value: "Spanish (Cuba)",
    label: "Spanish (Cuba)",
  },
  {
    id: "Spanish (Dominican Republic)",
    value: "Spanish (Dominican Republic)",
    label: "Spanish (Dominican Republic)",
  },
  {
    id: "Spanish (Ecuador)",
    value: "Spanish (Ecuador)",
    label: "Spanish (Ecuador)",
  },
  {
    id: "Spanish (El Salvador)",
    value: "Spanish (El Salvador)",
    label: "Spanish (El Salvador)",
  },
  {
    id: "Spanish (Equatorial Guinea)",
    value: "Spanish (Equatorial Guinea)",
    label: "Spanish (Equatorial Guinea)",
  },
  {
    id: "Spanish (Guatemala)",
    value: "Spanish (Guatemala)",
    label: "Spanish (Guatemala)",
  },
  {
    id: "Spanish (Honduras)",
    value: "Spanish (Honduras)",
    label: "Spanish (Honduras)",
  },
  {
    id: "Spanish (Mexico)",
    value: "Spanish (Mexico)",
    label: "Spanish (Mexico)",
  },
  {
    id: "Spanish (Nicaragua)",
    value: "Spanish (Nicaragua)",
    label: "Spanish (Nicaragua)",
  },
  {
    id: "Spanish (Panama)",
    value: "Spanish (Panama)",
    label: "Spanish (Panama)",
  },
  {
    id: "Spanish (Paraguay)",
    value: "Spanish (Paraguay)",
    label: "Spanish (Paraguay)",
  },
  {
    id: "Spanish (Peru)",
    value: "Spanish (Peru)",
    label: "Spanish (Peru)",
  },
  {
    id: "Spanish (Puerto Rico)",
    value: "Spanish (Puerto Rico)",
    label: "Spanish (Puerto Rico)",
  },
  {
    id: "Spanish (Spain)",
    value: "Spanish (Spain)",
    label: "Spanish (Spain)",
  },
  {
    id: "Spanish (United States)",
    value: "Spanish (United States)",
    label: "Spanish (United States)",
  },
  {
    id: "Spanish (Uruguay)",
    value: "Spanish (Uruguay)",
    label: "Spanish (Uruguay)",
  },
  {
    id: "Spanish (Venezuela)",
    value: "Spanish (Venezuela)",
    label: "Spanish (Venezuela)",
  },
  {
    id: "Sundanese (Indonesia)",
    value: "Sundanese (Indonesia)",
    label: "Sundanese (Indonesia)",
  },
  {
    id: "Swahili (Kenya)",
    value: "Swahili (Kenya)",
    label: "Swahili (Kenya)",
  },
  {
    id: "Swahili (Tanzania)",
    value: "Swahili (Tanzania)",
    label: "Swahili (Tanzania)",
  },
  {
    id: "Swedish (Sweden)",
    value: "Swedish (Sweden)",
    label: "Swedish (Sweden)",
  },
  {
    id: "Tamil (India)",
    value: "Tamil (India)",
    label: "Tamil (India)",
  },
  {
    id: "Tamil (Singapore)",
    value: "Tamil (Singapore)",
    label: "Tamil (Singapore)",
  },
  {
    id: "Tamil (Sri Lanka)",
    value: "Tamil (Sri Lanka)",
    label: "Tamil (Sri Lanka)",
  },
  {
    id: "Telugu (India)",
    value: "Telugu (India)",
    label: "Telugu (India)",
  },
  {
    id: "Thai (Thailand)",
    value: "Thai (Thailand)",
    label: "Thai (Thailand)",
  },
  {
    id: "Turkish (Turkey)",
    value: "Turkish (Turkey)",
    label: "Turkish (Turkey)",
  },
  {
    id: "Ukrainian (Ukraine)",
    value: "Ukrainian (Ukraine)",
    label: "Ukrainian (Ukraine)",
  },
  {
    id: "Urdu (India)",
    value: "Urdu (India)",
    label: "Urdu (India)",
  },
  {
    id: "Urdu (Pakistan)",
    value: "Urdu (Pakistan)",
    label: "Urdu (Pakistan)",
  },
  {
    id: "Uzbek (Latin, Uzbekistan)",
    value: "Uzbek (Latin, Uzbekistan)",
    label: "Uzbek (Latin, Uzbekistan)",
  },
  {
    id: "Vietnamese (Vietnam)",
    value: "Vietnamese (Vietnam)",
    label: "Vietnamese (Vietnam)",
  },
  {
    id: "Welsh (United Kingdom)",
    value: "Welsh (United Kingdom)",
    label: "Welsh (United Kingdom)",
  },
  {
    id: "Zulu (South Africa)",
    value: "Zulu (South Africa)",
    label: "Zulu (South Africa)",
  },
];
