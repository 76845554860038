import React, { useContext, useState } from "react";
import styled from "styled-components";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { BringLayerToFrontIcon, GetLayerBackIcon, LayersActiveIcon } from "../../../../../components/Icons/Icons";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { ChangeLayer } from "../../../../../hooks/useVideoEditor";
import { VideoEditorContext } from "../../../../../App";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";

const getListItem = {
  [ChangeLayer.raise]: {
    text: "Bring to front",
    icon: <BringLayerToFrontIcon />,
  },
  [ChangeLayer.lower]: {
    text: "Send to back",
    icon: <GetLayerBackIcon />,
  },
};

const AvatarLayers = () => {
  const theme = useSelector(getTheme);
  const { changeObjectLayer } = useContext(VideoEditorContext);
  const [active, setActive] = useState<ChangeLayer>();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const handleChangeActive = (type: ChangeLayer) => {
    changeObjectLayer(type);
    setActive(type);
  };

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper>
            <IconButton
              iconButtonTheme={IconButtonThemes.Rounded}
              icon={<LayersActiveIcon />}
              className={isOpen ? "active" : "not-active"}
              onClick={handleOpen}
            />
            <Tooltip
              text={"Layers"}
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
              reverseColor
              arrow
            />
            {isOpen && <ActiveBackground />}
          </ButtonWrapper>
        }
      >
        {Object.keys(ChangeLayer).map((type) => (
          <MenuListItem key={type} isActive={type === active} onClick={() => handleChangeActive(type as ChangeLayer)}>
            {getListItem[type as ChangeLayer].icon}
            {getListItem[type as ChangeLayer].text}
          </MenuListItem>
        ))}
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  position: relative;
`;

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const ActiveBackground = styled.div`
  background: linear-gradient(to bottom right, #0063b4 0%, #009af7 100%);
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px) !important;
  height: calc(100% + 2px) !important;
  border-radius: 12px;
`;

const MenuListItem = styled.div<{ isActive: boolean }>`
  user-select: none;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  column-gap: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};

  & svg {
    width: 16px;
    height: 16px;
  }
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(25px)",
  left: "0",
  border: "none",
  padding: "12px 0",
  width: "166px",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default AvatarLayers;
