import React from "react";

export const Invisible = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4" clipPath="url(#clip0_655_8507)">
      <path
        d="M8 14C3 14 0.2 8.53333 0.0666667 8.26667C-9.93411e-09 8.06667 -9.93411e-09 7.86667 0.0666667 7.66667C0.933333 6.06667 2.13333 4.66667 3.66667 3.53333C3.93333 3.26667 4.33333 3.33333 4.6 3.66667C4.8 3.93333 4.8 4.4 4.46667 4.6C3.2 5.53333 2.2 6.66667 1.4 8C2 9.06667 4.4 12.6667 8 12.6667C9.26667 12.6667 10.5333 12.2 11.5333 11.4C11.8 11.2 12.2667 11.2667 12.4667 11.5333C12.6667 11.8 12.6 12.2667 12.3333 12.4667C11.1333 13.4667 9.6 14 8 14ZM13.8667 10.8C13.7333 10.8 13.5333 10.7333 13.4667 10.6667C13.2 10.4 13.1333 10 13.4 9.73333C13.8667 9.2 14.2667 8.66667 14.6 8.06667C14 7 11.6 3.4 8 3.4C7.6 3.4 7.13333 3.46667 6.73333 3.53333C6.4 3.53333 6.06667 3.33333 5.93333 3C5.8 2.66667 6.06667 2.26667 6.4 2.2C6.93333 2.06667 7.46667 2 8 2C13 2 15.8 7.46667 15.9333 7.73333C16 7.93333 16 8.13333 15.9333 8.33333C15.5333 9.13333 15 9.86667 14.4 10.6C14.2667 10.7333 14.0667 10.8 13.8667 10.8ZM7.93333 10.7333C7.26667 10.7333 6.6 10.4667 6.13333 10C5.6 9.53333 5.33333 8.86667 5.26667 8.13333C5.26667 7.4 5.46667 6.73333 6 6.2C6.06667 6.13333 6.06667 6.13333 6.13333 6.06667C6.4 5.8 6.8 5.8 7.06667 6.06667C7.33333 6.33333 7.33333 6.73333 7.06667 7L7 7.06667C6.73333 7.33333 6.6 7.66667 6.66667 8C6.66667 8.33333 6.8 8.66667 7.06667 8.93333C7.6 9.46667 8.46667 9.4 8.93333 8.86667C9.2 8.6 9.6 8.6 9.86667 8.86667C10.1333 9.13333 10.1333 9.53333 9.86667 9.8C9.4 10.4 8.66667 10.7333 7.93333 10.7333Z"
        fill="#231F20"
      />
      <path
        d="M15.3333 16C15.1333 16 15 15.9333 14.8667 15.8L0.2 1.13333C-0.0666667 0.866667 -0.0666667 0.466667 0.2 0.2C0.466667 -0.0666667 0.866667 -0.0666667 1.13333 0.2L15.8 14.8667C16.0667 15.1333 16.0667 15.5333 15.8 15.8C15.6667 15.9333 15.5333 16 15.3333 16Z"
        fill="#231F20"
      />
    </g>
    <defs>
      <clipPath id="clip0_655_8507">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
