import styled from "styled-components";
interface Props {
  title?: string;
  bottomText?: string;
  value: number;
  maxWidth?: number;
  maxHeight?: number;
}

const ProgressBar = ({ title, bottomText, value, maxWidth, maxHeight }: Props) => (
  <Wrapper>
    {title && <span className="progress-bar-title">{title}</span>}
    <Row>
      <ProgressBarLineWrapper maxWidth={maxWidth} maxHeight={maxHeight}>
        <progress value={value} max={100} />
      </ProgressBarLineWrapper>
      {bottomText && <span className="progress-bar-bottom-text">{bottomText}</span>}
    </Row>
  </Wrapper>
);

const Wrapper = styled.div<{ maxWidth?: number; maxHeight?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;

  width: 100%;

  span {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: ${({ theme }) => theme.primaryText};
    margin: 0px 0px 20px 0px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: 100%;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #6f7074;
    margin: 8px 0px 0px 0px;
  }
`;

const ProgressBarLineWrapper = styled.div<{ maxWidth?: number; maxHeight?: number }>`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  border-radius: 7px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 450)}px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 14)}px;

  progress {
    width: 100%;
    border-radius: 39px;
  }
  progress::-webkit-progress-value {
    background-color: #0063b4;
    border-radius: 39px;
  }
  progress::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.videoTranslationProgressBar};
    border-radius: 39px;
  }
`;

const ProgressBarLine = styled.input<{ width: number }>`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 80px;
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  ::-webkit-slider-runnable-track {
    background: ${({ theme, width }) => `linear-gradient(to right, ${theme.activeMenu} ${width}%, transparent 0)`};
    height: 16px;
  }
  ::-ms-thumb {
    opacity: 0;
  }
`;

export default ProgressBar;
