import { put, select, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  BOOKMARK_ACTOR_SERVER,
  clearActors,
  CLEAR_SEARCH,
  DOWNLOAD_VOICE_SERVER,
  filterActorLoading,
  GENERATE_VOICE_SERVER,
  getActorsServer,
  GET_ACTORS,
  GET_ACTORS_SERVER,
  searchActorResultLoading,
  updateActorLoading,
  updateHasMoreActors,
  ZIP_VOICE_SERVER,
  GET_DOWNLOAD_HISTORY_SERVER,
  updateHasMoreDownloadHistory,
  FACESWAP_ACTOR_SERVER,
  DELETE_CLONE_VOICE_SERVER,
  GET_VOICEOVER_HISTORY_SERVER,
  AUDIO_TO_TRANSLATED_TEXT_SERVER,
  GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER,
  AUDIO_TRANSLATE_PROJECT_ADD,
  getAudioTranslateProjectByIdServer,
  getVoiceoverHistoryServer,
  clearCurrentSpeechTranslationProject,
  clearCurrentSpeech2SpeechProject,
} from "../actions/actorActions";
import { ActorModules } from "../../types/actor";
import { getActorsList, getDownloadHistory } from "../reducers/actorReducer";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { Popups, updatePopup } from "../actions/popupsActions";

const actorSagas = [
  takeEvery(GET_ACTORS, handleGetActors),

  takeEvery(`${GET_ACTORS_SERVER}`, handleGetActorsServer),
  takeEvery(`${GET_ACTORS_SERVER}_FAIL`, handleGetActorsServerFail),
  takeEvery(`${GET_ACTORS_SERVER}_SUCCESS`, handleGetActorsServerSuccess),

  takeEvery(`${GENERATE_VOICE_SERVER}_FAIL`, handleGenerateVoiceServerFail),

  takeEvery(`${DOWNLOAD_VOICE_SERVER}_FAIL`, handleDownloadVoiceServerFail),
  takeEvery(`${ZIP_VOICE_SERVER}_FAIL`, handleZipVoiceServerFail),

  takeEvery(`${BOOKMARK_ACTOR_SERVER}_FAIL`, handleBookmarkActorServerFail),
  takeEvery(`${BOOKMARK_ACTOR_SERVER}_SUCCESS`, handleBookmarkActorServerSuccess),

  takeEvery(`${GET_DOWNLOAD_HISTORY_SERVER}`, handleGetDownloadHistoryServer),
  takeEvery(`${GET_DOWNLOAD_HISTORY_SERVER}_FAIL`, handleGetDownloadHistoryServerFail),
  takeEvery(`${GET_DOWNLOAD_HISTORY_SERVER}_SUCCESS`, handleGetDownloadHistoryServerSuccess),

  takeEvery(FACESWAP_ACTOR_SERVER, handleFaceswapActorServer),
  takeEvery(`${FACESWAP_ACTOR_SERVER}_SUCCESS`, handleFaceswapActorServerSuccess),
  takeEvery(`${FACESWAP_ACTOR_SERVER}_FAIL`, handleFaceswapActorServerFail),

  takeEvery(`${DELETE_CLONE_VOICE_SERVER}_SUCCESS`, handleDeleteVoiceServerSuccess),
  takeEvery(`${DELETE_CLONE_VOICE_SERVER}_FAIL`, handleDeleteVoiceServerFail),

  takeEvery(CLEAR_SEARCH, handleClearSearch),

  takeEvery(`${GET_VOICEOVER_HISTORY_SERVER}_FAIL`, handleGetVoiceoverHistoryFail),

  takeEvery(AUDIO_TO_TRANSLATED_TEXT_SERVER, handleAudioToTranslatedTextServer),
  takeEvery(`${AUDIO_TO_TRANSLATED_TEXT_SERVER}_SUCCESS`, handleAudioToTranslatedTextServerSuccess),
  takeEvery(`${AUDIO_TO_TRANSLATED_TEXT_SERVER}_FAIL`, handleAudioToTranslatedTextServerFail),

  takeEvery(AUDIO_TRANSLATE_PROJECT_ADD, handleAudioTranslateProjectAddServer),
  takeEvery(`${AUDIO_TRANSLATE_PROJECT_ADD}_SUCCESS`, handleAudioTranslateProjectAddServerSuccess),
  takeEvery(`${AUDIO_TRANSLATE_PROJECT_ADD}_FAIL`, handleAudioTranslateProjectAddServerFail),

  takeEvery(GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER, handleGetAudioTranslateProjectByIdServer),
  takeEvery(`${GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER}_SUCCESS`, handleGetAudioTranslateProjectByIdServerSuccess),
  takeEvery(`${GET_AUDIO_TRANSLATE_PROJECT_BY_ID_SERVER}_FAIL`, handleGetAudioTranslateProjectByIdServerFail),
];

function* handleGetActors(action: any) {
  yield put(getActorsServer({ ...action.payload }));
}

function* handleGetActorsServer(action: any) {
  const { isVideoTranslationPage } = action.payload;
  const { VideoTranslate, categoryType } = action.payload.request.data;

  if (isVideoTranslationPage) {
    if (VideoTranslate) {
      yield put(updateActorLoading({ module: ActorModules.voiceTranslate, isLoading: true }));
      return;
    }

    if (categoryType) {
      yield put(updateActorLoading({ module: ActorModules.clonedVoices, isLoading: true }));
      return;
    }
  } else {
    yield put(updateActorLoading({ module: ActorModules.actorList, isLoading: true }));
  }
}

function* handleGetActorsServerFail(action: any) {
  const { isVideoTranslationPage } = action.meta.previousAction.payload;
  const { VideoTranslate, categoryType } = action.meta.previousAction.payload.request.data;

  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_ACTORS.toast);

  if (isVideoTranslationPage) {
    if (VideoTranslate) {
      yield put(updateActorLoading({ module: ActorModules.voiceTranslate, isLoading: false }));
      return;
    }

    if (categoryType) {
      yield put(updateActorLoading({ module: ActorModules.clonedVoices, isLoading: false }));
      return;
    }
  } else {
    yield put(updateActorLoading({ module: ActorModules.actorList, isLoading: false }));
  }

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_ACTORS,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetActorsServerSuccess(action: any) {
  const allActors = getActorsList(yield select());
  const totalLength = action.payload.data.totalRecords;
  const { isVideoTranslationPage } = action.meta.previousAction.payload;
  const { VideoTranslate, categoryType } = action.meta.previousAction.payload.request.data;

  if (isVideoTranslationPage) {
    if (VideoTranslate) {
      yield put(updateActorLoading({ module: ActorModules.voiceTranslate, isLoading: false }));
      return;
    }

    if (categoryType) {
      yield put(updateActorLoading({ module: ActorModules.clonedVoices, isLoading: false }));
      return;
    }
  } else {
    if (allActors.length >= totalLength) yield put(updateHasMoreActors({ hasMore: false }));

    yield put(updateActorLoading({ module: ActorModules.actorList, isLoading: false }));
    yield put(searchActorResultLoading(false));
    yield put(filterActorLoading(false));
  }
}

function* handleGenerateVoiceServerFail(action: any) {
  const zones = action?.meta?.previousAction?.payload?.request?.data?.data || [];

  if (action?.error?.response?.data?.message === "Security notice: Trial limit has reached") {
    yield put(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled: { title: "You have reached the Free user limit.", description: "Upgrade now to continue" },
      }),
    );
  } else {
    yield toast.error(action?.error?.response?.data?.message || SentryErrors.SERVER_ERROR_WHILE_GENERATING_VOICE.toast);
  }

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GENERATING_VOICE,
    details: {
      responseFromApi: action?.error?.response?.data || null,
      zones: JSON.stringify(zones || []),
    },
  });
}

function* handleDownloadVoiceServerFail(action: any) {
  const zones = action?.meta?.previousAction?.payload?.request?.data?.data || [];

  if (action?.error?.response?.data?.message === "Download limit reached") {
    const prefilled: NotEnoughCredits = {
      title: "Download limit reached",
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
    };

    yield put(
      updatePopup({
        popup: Popups.estimatedPopup,
        status: false,
      }),
    );

    yield put(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  } else {
    yield toast.error(
      action?.error?.response?.data?.message || SentryErrors.SERVER_ERROR_WHILE_DOWNLOADING_VOICE.toast,
    );

    yield sentryErrors({
      errorType: SentryErrors.SERVER_ERROR_WHILE_DOWNLOADING_VOICE,
      details: {
        responseFromApi: action?.error?.response?.data || null,
        zones: JSON.stringify(zones || []),
      },
    });
  }
}

function* handleZipVoiceServerFail(action: any) {
  const zones = action?.meta?.previousAction?.payload?.request?.data?.data || [];
  if (action?.error?.response?.data?.message === "Download limit reached") {
    yield toast.error("Download limit reached");
  } else {
    yield toast.error(SentryErrors.SERVER_ERROR_WHILE_ZIPPING_VOICE.toast);

    yield sentryErrors({
      errorType: SentryErrors.SERVER_ERROR_WHILE_ZIPPING_VOICE,
      details: {
        responseFromApi: action?.error?.response?.data || null,
        zones: JSON.stringify(zones || []),
      },
    });
  }
}

function* handleBookmarkActorServerFail() {
  yield toast.error("Error while actor bookmarked");
}

function* handleBookmarkActorServerSuccess(action: any) {
  const isBookmarked = action.meta.previousAction.payload.isScreen;
  const speechCategory = action.meta.previousAction.payload.speechCategory;
  const pathname = action.meta.previousAction.payload.pathname;

  if (isBookmarked) {
    yield put(clearActors());
    yield put(filterActorLoading(true));
    yield put(
      getActorsServer({
        pageNumber: 1,
        bookmarked: isBookmarked,
        isAIHuman: pathname === "/ai-humans" && true,
        SpeechCategory: speechCategory,
      }),
    );
  }
}

function* handleClearSearch(action: any) {
  const { pathname, speechCategory } = action.payload;

  yield put(clearActors());
  yield put(
    getActorsServer({
      keyword: "",
      pageNumber: 1,
      categoryType: [],
      voiceAge: [],
      mood: [],
      content: [],
      region: [],
      language: [],
      country: [],
      popular: true,
      isAIHuman: pathname === "/ai-humans" && true,
      SpeechCategory: speechCategory,
    }),
  );
}

function* handleGetDownloadHistoryServer() {
  yield put(updateActorLoading({ module: ActorModules.downloadHistory, isLoading: true }));
}

function* handleGetDownloadHistoryServerSuccess(action: any) {
  const allDownloadHistory = getDownloadHistory(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allDownloadHistory.length >= totalLength) yield put(updateHasMoreDownloadHistory({ hasMore: false }));

  yield put(updateActorLoading({ module: ActorModules.downloadHistory, isLoading: false }));
}

function* handleGetDownloadHistoryServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_DOWNLOAD_HISTORY.toast);
  yield put(updateActorLoading({ module: ActorModules.downloadHistory, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_DOWNLOAD_HISTORY,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleFaceswapActorServer() {
  yield put(updateActorLoading({ module: ActorModules.faceswapActor, isLoading: true }));
}

function* handleFaceswapActorServerSuccess() {
  yield put(updateActorLoading({ module: ActorModules.faceswapActor, isLoading: false }));
}

function* handleFaceswapActorServerFail(action: any) {
  yield toast.error(action.error.response.data.message || "An error occurred while faceswap actor");
  yield put(updateActorLoading({ module: ActorModules.faceswapActor, isLoading: false }));
}

function* handleDeleteVoiceServerSuccess(action: any) {
  const { previousFill } = action.meta.previousAction.payload;
  const { requestForActorServer } = previousFill;

  yield put(clearActors());
  yield put(getActorsServer(requestForActorServer));
  yield toast.success("The actor has been deleted");
}

function* handleDeleteVoiceServerFail() {
  yield toast.error("Error while deleting actor");
}

function* handleGetVoiceoverHistoryFail() {
  yield toast.error("Error while getting history");
}

function* handleAudioToTranslatedTextServer() {
  yield put(updateActorLoading({ module: ActorModules.audioToTranslatedText, isLoading: true }));
}

function* handleAudioToTranslatedTextServerSuccess() {
  yield put(updateActorLoading({ module: ActorModules.audioToTranslatedText, isLoading: false }));
}

function* handleAudioToTranslatedTextServerFail() {
  yield toast.error("An error occurred while converting audio to text");
  yield put(updateActorLoading({ module: ActorModules.audioToTranslatedText, isLoading: false }));
}

function* handleAudioTranslateProjectAddServer(action: any) {
  const { projectType } = action.payload;

  if (projectType === 1) {
    yield put(clearCurrentSpeechTranslationProject());
    yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: true }));
    return;
  }
  yield put(clearCurrentSpeech2SpeechProject());
  yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: true }));
}

function* handleAudioTranslateProjectAddServerSuccess(action: any) {
  const { audioTranslateProjectId: id } = action.payload.data.data;
  const { projectType, isReload } = action.meta.previousAction.payload;

  yield put(getAudioTranslateProjectByIdServer(id, projectType, isReload));
}

function* handleAudioTranslateProjectAddServerFail(action: any) {
  yield toast.error(action?.error?.response?.data?.message || "Failed to save project");
  yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: false }));
  yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: false }));
}

function* handleGetAudioTranslateProjectByIdServer(action: any) {
  const { projectType } = action.payload;

  if (projectType === 1) {
    yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: true }));
    return;
  }

  if (projectType === 2) {
    yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: true }));
    return;
  }

  yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: true }));
  yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: true }));
}

function* handleGetAudioTranslateProjectByIdServerSuccess(action: any) {
  const { status } = action.payload.data.data;
  const { isReload } = action.meta.previousAction.payload;

  if (status === 3) {
    yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: false }));
    yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: false }));
    if (isReload) {
      yield put(getVoiceoverHistoryServer(1, true));
    }
  } else if (status === 4) {
    yield toast.error("Failed to generate project");
    yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: false }));
    yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: false }));
  }
}

function* handleGetAudioTranslateProjectByIdServerFail() {
  yield toast.error("Failed to get project");
  yield put(updateActorLoading({ module: ActorModules.speech2Speech, isLoading: false }));
  yield put(updateActorLoading({ module: ActorModules.speechTranslation, isLoading: false }));
}

export default actorSagas;
