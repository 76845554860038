import React from "react";
import Modal from "../Modal/Modal";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button, { ButtonThemes } from "../Button/Button";
import { useSelector } from "react-redux";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";

interface Props {
  open: boolean;
  onClose: () => void;
}

const CancelSubscriptionPopup = ({ onClose, open }: Props) => {
  const prefilled = useSelector(getPrefilled);
  const dispatch = useDispatch();

  const handleContinue = () => {
    dispatch(updatePopup({ popup: Popups.cancelSubscriptionPopup, status: false }));
    dispatch(
      updatePopup({
        popup: Popups.subscriptionCanceledPopup,
        status: true,
        prefilled: { invoiceID: prefilled?.invoiceID },
      }),
    );
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth={630} maxHeight={260}>
      <Wrapper>
        <TopWrapper>
          <Title>We are sorry to see you go </Title>
          <Subtitle>
            Your subscription will be active until <Bold>{prefilled?.nextInvoice}</Bold>.<br /> After that you won’t be
            able to create new projects.
          </Subtitle>
        </TopWrapper>
        <Actions>
          <ContinueButton buttonTheme={ButtonThemes.Secondary} text="Continue cancellation" onClick={handleContinue} />
          <Button text="Keep Subscription" onClick={onClose} />
        </Actions>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 542px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;

  & button {
    width: calc(50% - 12px);
  }
`;

const ContinueButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.cardShadow};
  height: 48px;
  border-radius: 12px;
`;

export default CancelSubscriptionPopup;
