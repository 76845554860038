import React, { useContext, useEffect, useState } from "react";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import styled from "styled-components";
import { DropdownIcon } from "../../../../../components/Icons/DropdownIcon";
import { Scene, Text } from "../../../../../types/scene";
import { addPx, removePx } from "../../../../../lib/stylesHelper";
import { ThemeTypes } from "../../../../../types/theme";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import { VideoEditorContext } from "../../../../../App";

interface Props {
  currentObject: Text;
}

const fontsSizeOptions = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96];

const TextFontSize = ({ currentObject }: Props) => {
  const { currentScene, setScenes, scenes } = useContext(VideoEditorContext);

  const theme = useSelector(getTheme);
  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(199);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleChangeActive = (value: number) => {
    if (!currentObject) return;
    const coefficient = +removePx(currentObject.displayedFontSize) / +removePx(String(currentObject.style.fontSize));

    const newScenes = scenes.map((scene) => {
      if (scene.id !== currentScene.id) return scene;
      return {
        ...scene,
        objects: currentScene.objects.map((sceneObject) => {
          if (sceneObject.object.id !== currentObject.id) return sceneObject;
          return {
            ...sceneObject,
            object: {
              ...sceneObject.object,
              style: {
                ...currentObject.style,
                fontSize: addPx(String(value)),
                lineHeight: addPx(String(value * 1.37)),
              },
              displayedFontSize: addPx(String(value * coefficient)),
            },
          };
        }),
      };
    });

    setScenes(newScenes);

    setActive(value);
    handleClose();
  };

  useEffect(() => {
    if (!currentObject) return setActive(0);
    const newValue = +removePx(currentObject.style.fontSize as string);
    setActive(newValue);
  }, [currentObject]);

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <DropdownWrapper isActive={isOpen} onClick={handleOpen}>
            <ValueText>{active}</ValueText>
            <DropdownIcon />
            <Tooltip
              text="Font Size"
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
              reverseColor
              arrow
            />
          </DropdownWrapper>
        }
      >
        <MenuWrapper>
          {fontsSizeOptions.map((fontSize) => (
            <MenuListItem isActive={fontSize === active} onClick={() => handleChangeActive(fontSize)} key={fontSize}>
              <ValueText>{fontSize}</ValueText>
            </MenuListItem>
          ))}
        </MenuWrapper>
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 2px;
  cursor: pointer;
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
`;

const ValueText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};
`;

const MenuWrapper = styled.div`
  max-height: 200px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0;
  }
`;

const MenuListItem = styled.div<{ isActive: boolean }>`
  user-select: none;
  padding: 1px 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translate(-20px, 10px)",
  left: "0",
  border: "none",
  padding: "10px 2px 10px 0",
  width: "max-content",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default TextFontSize;
