export const ProfileIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6666 9.33366C18.6666 7.86299 17.4706 6.66699 16 6.66699C14.5293 6.66699 13.3333 7.86299 13.3333 9.33366C13.3333 10.8043 14.5293 12.0003 16 12.0003C17.4706 12.0003 18.6666 10.8043 18.6666 9.33366ZM21.3333 9.33333C21.3333 12.2747 18.9413 14.6667 16 14.6667C13.0586 14.6667 10.6666 12.2747 10.6666 9.33333C10.6666 6.392 13.0586 4 16 4C18.9413 4 21.3333 6.392 21.3333 9.33333ZM6.66663 26.6663C6.66663 21.5197 10.8546 17.333 16 17.333C21.1453 17.333 25.3333 21.5197 25.3333 26.6663C25.3333 27.4023 24.7373 27.9997 24 27.9997C23.2626 27.9997 22.6666 27.4023 22.6666 26.6663C22.6666 22.9903 19.676 19.9997 16 19.9997C12.324 19.9997 9.33329 22.9903 9.33329 26.6663C9.33329 27.4023 8.73729 27.9997 7.99996 27.9997C7.26263 27.9997 6.66663 27.4023 6.66663 26.6663Z"
      fill="white"
    />
    <mask id="mask0_999_9691" maskUnits="userSpaceOnUse" x="6" y="4" width="20" height="24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6666 9.33366C18.6666 7.86299 17.4706 6.66699 16 6.66699C14.5293 6.66699 13.3333 7.86299 13.3333 9.33366C13.3333 10.8043 14.5293 12.0003 16 12.0003C17.4706 12.0003 18.6666 10.8043 18.6666 9.33366ZM21.3333 9.33333C21.3333 12.2747 18.9413 14.6667 16 14.6667C13.0586 14.6667 10.6666 12.2747 10.6666 9.33333C10.6666 6.392 13.0586 4 16 4C18.9413 4 21.3333 6.392 21.3333 9.33333ZM6.66663 26.6663C6.66663 21.5197 10.8546 17.333 16 17.333C21.1453 17.333 25.3333 21.5197 25.3333 26.6663C25.3333 27.4023 24.7373 27.9997 24 27.9997C23.2626 27.9997 22.6666 27.4023 22.6666 26.6663C22.6666 22.9903 19.676 19.9997 16 19.9997C12.324 19.9997 9.33329 22.9903 9.33329 26.6663C9.33329 27.4023 8.73729 27.9997 7.99996 27.9997C7.26263 27.9997 6.66663 27.4023 6.66663 26.6663Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_999_9691)"></g>
  </svg>
);
