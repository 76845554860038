export const CircleCheckReversedicon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="vuesax/linear/tick-circle">
      <g id="tick-circle">
        <path
          id="Vector"
          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
          stroke="url(#paint0_linear_4_61)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.75 12L10.58 14.83L16.25 9.17001"
          stroke="url(#paint1_linear_4_61)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4_61"
        x1="6.30769"
        y1="4.61538"
        x2="18.1538"
        y2="19.8462"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4_61"
        x1="9.58077"
        y1="9.91017"
        x2="12.4058"
        y2="15.3648"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
