import { ChangeSubscription, UserPlan } from "../../types/plans";

export const GET_USER_PLANS_SERVER = "GET_USER_PLANS_SERVER";
export const UPDATE_USER_PLANS = "UPDATE_USER_PLANS";
export const UPDATE_USER_PLANS_LOADING = "UPDATE_USER_PLANS_LOADING";
export const CANCEL_SUBSCRIPTION_SERVER = "CANCEL_SUBSCRIPTION_SERVER";
export const GET_CHANGE_SUBSCRIPTIONS_SERVER = "GET_CHANGE_SUBSCRIPTIONS_SERVER";
export const UPDATE_CHANGE_SUBSCRIPTIONS = "UPDATE_CHANGE_SUBSCRIPTIONS";
export const UPDATE_CHANGE_SUBSCRIPTIONS_LOADING = "UPDATE_CHANGE_SUBSCRIPTIONS_LOADING";
export const UPDATE_SUBSCRIPTION_SERVER = "UPDATE_SUBSCRIPTION_SERVER";
export const CHANGE_SUBSCRIPTION_UPDATED = "CHANGE_SUBSCRIPTION_UPDATED";
export const ADD_TO_LOADING_PLANS = "ADD_TO_LOADING_PLANS";
export const REMOVE_FROM_LOADING_PLANS = "REMOVE_FROM_LOADING_PLANS";
export const GET_PAYMENT_METHOD_PKS_SERVER = "GET_PAYMENT_METHOD_PKS_SERVER";
export const UPDATE_PAYMENT_METHOD_PKS = "UPDATE_PAYMENT_METHOD_PKS";
export const UPDATE_PAYMENT_METHOD_PKS_LOADING = "UPDATE_PAYMENT_METHOD_PKS_LOADING";

interface UpdateStatusProps {
  status: boolean;
}

interface UpdatePlanProps {
  data: UserPlan[];
}

interface CancelSubscriptionProps {
  invoiceID: string;
}

interface GetChangeSubscriptionsProps {
  userLicenseId: number;
}

interface UpdateChangeSubscriptionsProps {
  data: ChangeSubscription[];
}

interface UpdateSubscriptionServerProps {
  existingUserLicenseId: number;
  newPlanId: number;
}

interface UpdateLoadingPlansProps {
  id: number;
}

interface GetPaymentPKSProps {
  existingUserLicenseId: number;
}

interface UpdatePaymentsPKSProps {
  data: string;
}

export const getUserPlansServer = () => ({
  type: GET_USER_PLANS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/user/getSubscriptions",
    },
  },
});

export const updateUserPlans = ({ data }: UpdatePlanProps) => ({
  type: UPDATE_USER_PLANS,
  payload: { data },
});

export const updatePlansLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_USER_PLANS_LOADING,
  payload: { status },
});

export const cancelSubscriptionServer = ({ invoiceID }: CancelSubscriptionProps) => ({
  type: CANCEL_SUBSCRIPTION_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/user/cancelSubscriptions?InvoiceID=${invoiceID}`,
    },
  },
});

export const getChangeSubscriptionsServer = ({ userLicenseId }: GetChangeSubscriptionsProps) => ({
  type: GET_CHANGE_SUBSCRIPTIONS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/user/getChangeSubscriptions?userLicenseId=${userLicenseId}`,
    },
  },
});

export const updateChangeSubscriptions = ({ data }: UpdateChangeSubscriptionsProps) => ({
  type: UPDATE_CHANGE_SUBSCRIPTIONS,
  payload: { data },
});

export const updateChangeSubscriptionsLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_CHANGE_SUBSCRIPTIONS_LOADING,
  payload: { status },
});

export const updateSubscriptionServer = ({ existingUserLicenseId, newPlanId }: UpdateSubscriptionServerProps) => ({
  type: UPDATE_SUBSCRIPTION_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/user/changeSubscription?existingUserLicenseId=${existingUserLicenseId}&newPlanId=${newPlanId}`,
    },
    newPlanId,
  },
});

export const changeSubscriptionUpdated = ({ status }: UpdateStatusProps) => ({
  type: CHANGE_SUBSCRIPTION_UPDATED,
  payload: { status },
});

export const addToLoadingPlans = ({ id }: UpdateLoadingPlansProps) => ({
  type: ADD_TO_LOADING_PLANS,
  payload: { id },
});

export const removeFromLoadingPlans = ({ id }: UpdateLoadingPlansProps) => ({
  type: REMOVE_FROM_LOADING_PLANS,
  payload: { id },
});

export const getPaymentMethodPKSServer = ({ existingUserLicenseId }: GetPaymentPKSProps) => ({
  type: GET_PAYMENT_METHOD_PKS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/user/getPKSUrl?existingUserLicenseId=${existingUserLicenseId}`,
    },
  },
});

export const updatePaymentPKS = ({ data }: UpdatePaymentsPKSProps) => ({
  type: UPDATE_PAYMENT_METHOD_PKS,
  payload: { data },
});

export const updatePaymentPKSLoading = ({ status }: UpdateStatusProps) => ({
  type: UPDATE_PAYMENT_METHOD_PKS_LOADING,
  payload: { status },
});
