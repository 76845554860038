export const AIHumansPause = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 3.50033C3.5 3.34562 3.56146 3.19724 3.67085 3.08785C3.78025 2.97845 3.92862 2.91699 4.08333 2.91699H5.25C5.40471 2.91699 5.55308 2.97845 5.66248 3.08785C5.77187 3.19724 5.83333 3.34562 5.83333 3.50033V10.5003C5.83333 10.655 5.77187 10.8034 5.66248 10.9128C5.55308 11.0222 5.40471 11.0837 5.25 11.0837H4.08333C3.92862 11.0837 3.78025 11.0222 3.67085 10.9128C3.56146 10.8034 3.5 10.655 3.5 10.5003V3.50033Z"
      stroke="url(#paint0_linear_4251_57333)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.16667 3.50033C8.16667 3.34562 8.22812 3.19724 8.33752 3.08785C8.44692 2.97845 8.59529 2.91699 8.75 2.91699H9.91667C10.0714 2.91699 10.2197 2.97845 10.3291 3.08785C10.4385 3.19724 10.5 3.34562 10.5 3.50033V10.5003C10.5 10.655 10.4385 10.8034 10.3291 10.9128C10.2197 11.0222 10.0714 11.0837 9.91667 11.0837H8.75C8.59529 11.0837 8.44692 11.0222 8.33752 10.9128C8.22812 10.8034 8.16667 10.655 8.16667 10.5003V3.50033Z"
      stroke="url(#paint1_linear_4251_57333)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4251_57333"
        x1="5.00769"
        y1="3.98494"
        x2="9.97497"
        y2="9.45908"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4251_57333"
        x1="5.00769"
        y1="3.98494"
        x2="9.97497"
        y2="9.45908"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
