import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useContext, useEffect, useRef } from "react";
import { EditorState } from "draft-js";
import { useDispatch, useSelector } from "react-redux";

import TitleWithAction from "../TitleWithAction";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import {
  getCurrentPageTemplates,
  getHasMoreTemplates,
  getTemplates,
  getTemplatesLoading,
  importTemplateLoading,
} from "../../../../redux/reducers/templateReducer";
import { VideoEditorContext } from "../../../../App";
import { ImportIcon } from "../../../../components/Icons/Icons";
import { HumansParagraph } from "../../../../types/humansProject";
import { getProfile } from "../../../../redux/reducers/profileReducer";
import { getHumansTemplate } from "../../../../redux/reducers/humansProjectReducer";
import { clearCurrentHumansProject, getProjectServer } from "../../../../redux/actions/humansProjectActions";
import { clearTemplates, getTemplatesServer, importTemplateServer } from "../../../../redux/actions/templateAction";

const ACCEPTED_FORMATS = "application/pdf";

interface Props {
  paragraphs: HumansParagraph[];
  setParagraphs: (nextParagraphs: HumansParagraph[]) => void;
  setEditorContent: React.Dispatch<React.SetStateAction<EditorState[]>>;
}

const TemplateTab = ({ paragraphs, setParagraphs, setEditorContent }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(false);
  const data = useSelector(getTemplates);
  const isLoading = useSelector(getTemplatesLoading);
  const hasMore = useSelector(getHasMoreTemplates);
  const currentPage = useSelector(getCurrentPageTemplates);
  const humansProject = useSelector(getHumansTemplate);
  const isImportTemplate = useSelector(importTemplateLoading);
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;

  const { addSlides } = useContext(VideoEditorContext);

  const fetchMore = () => {
    dispatch(
      getTemplatesServer({
        keyword: "",
        pageNumber: currentPage + 1,
        pageSize: 24,
      }),
    );
  };

  useEffect(() => {
    dispatch(clearCurrentHumansProject());
    dispatch(clearTemplates());
    dispatch(
      getTemplatesServer({
        keyword: "",
        pageNumber: 1,
        pageSize: 24,
      }),
    );
  }, []);

  const handleNewScene = (projectId: number) => {
    if (!projectId) return;

    dispatch(getProjectServer(projectId, true, true));
  };

  const handleAddScene = () => {
    if (!humansProject) return;

    addSlides(humansProject, setEditorContent, setParagraphs, paragraphs);
  };

  const handleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append("File", uploadedFile);

    dispatch(importTemplateServer({ formData }));
  };

  useEffect(() => {
    if (isFirstRender.current && humansProject) {
      handleAddScene();
    }

    isFirstRender.current = true;
  }, [humansProject]);

  return (
    <Wrapper>
      <TitleWithAction
        title="Templates"
        action={
          !isGuest && (
            <ButtonWrapper>
              <Button
                buttonTheme={ButtonThemes.Secondary}
                icon={!isImportTemplate && <ImportIcon />}
                text={isImportTemplate ? "Loading..." : "Upload/Import PDF"}
              />
              <input type="file" id="template" name="template" accept={ACCEPTED_FORMATS} onChange={handleUpload} />
            </ButtonWrapper>
          )
        }
      >
        <Content>
          <ImagesWrapper id="scrollableTemplates">
            <InfiniteScroll
              next={fetchMore}
              hasMore={hasMore}
              loader={
                isLoading ? (
                  <CircularProgressWrapper>
                    <CircularProgress color="#009af7" />
                  </CircularProgressWrapper>
                ) : null
              }
              dataLength={data?.length}
              style={{ display: "contents" }}
              scrollableTarget="scrollableTemplates"
            >
              {data &&
                data?.map(({ projectId, coverImage, title }) => (
                  <ImageWrapper key={projectId} onClick={() => handleNewScene(projectId)}>
                    <img src={coverImage} alt={title} />
                  </ImageWrapper>
                ))}
            </InfiniteScroll>
          </ImagesWrapper>
        </Content>
      </TitleWithAction>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    cursor: pointer;
  }
`;

const Content = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 9px;
  border-radius: 20px;
  position: relative;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const ImagesWrapper = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-content: start;
  flex-flow: row wrap;
  row-gap: 8px;
  column-gap: 8px;
  margin-bottom: 24px;
  height: 100%;
  max-height: 100%;
  overflow: hidden auto;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      align-items: center;
      justify-content: center;
      height: 100%;
      margin-bottom: 0;
    `}

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 80px;
  border-radius: 16px;
  background-color: #d6d6d6;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default TemplateTab;
