export const LockIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 10.6663C7.63184 10.6663 7.33317 10.3677 7.33317 9.99967C7.33317 9.63167 7.63184 9.33301 7.99984 9.33301C8.36784 9.33301 8.6665 9.63167 8.6665 9.99967C8.6665 10.3677 8.36784 10.6663 7.99984 10.6663ZM7.99984 7.99967C6.89717 7.99967 5.99984 8.89701 5.99984 9.99967C5.99984 11.1023 6.89717 11.9997 7.99984 11.9997C9.1025 11.9997 9.99984 11.1023 9.99984 9.99967C9.99984 8.89701 9.1025 7.99967 7.99984 7.99967ZM11.9998 12.6663C11.9998 13.0343 11.7012 13.333 11.3332 13.333H4.6665C4.2985 13.333 3.99984 13.0343 3.99984 12.6663V7.33301C3.99984 6.96501 4.2985 6.66634 4.6665 6.66634H5.33317H6.6665H9.33317H10.6665H11.3332C11.7012 6.66634 11.9998 6.96501 11.9998 7.33301V12.6663ZM6.6665 4.07367C6.6665 3.29767 7.2645 2.66634 7.99984 2.66634C8.73517 2.66634 9.33317 3.29767 9.33317 4.07367V5.33301H6.6665V4.07367ZM11.3332 5.33301H10.6665V4.07368C10.6665 2.56301 9.4705 1.33301 7.99984 1.33301C6.52917 1.33301 5.33317 2.56301 5.33317 4.07368V5.33301H4.6665C3.56384 5.33301 2.6665 6.23034 2.6665 7.33301V12.6663C2.6665 13.769 3.56384 14.6663 4.6665 14.6663H11.3332C12.4358 14.6663 13.3332 13.769 13.3332 12.6663V7.33301C13.3332 6.23034 12.4358 5.33301 11.3332 5.33301Z"
      fill="#191B1F"
    />
    <mask id="mask0_1012_10873" maskUnits="userSpaceOnUse" x="2" y="1" width="12" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 10.6663C7.63184 10.6663 7.33317 10.3677 7.33317 9.99967C7.33317 9.63167 7.63184 9.33301 7.99984 9.33301C8.36784 9.33301 8.6665 9.63167 8.6665 9.99967C8.6665 10.3677 8.36784 10.6663 7.99984 10.6663ZM7.99984 7.99967C6.89717 7.99967 5.99984 8.89701 5.99984 9.99967C5.99984 11.1023 6.89717 11.9997 7.99984 11.9997C9.1025 11.9997 9.99984 11.1023 9.99984 9.99967C9.99984 8.89701 9.1025 7.99967 7.99984 7.99967ZM11.9998 12.6663C11.9998 13.0343 11.7012 13.333 11.3332 13.333H4.6665C4.2985 13.333 3.99984 13.0343 3.99984 12.6663V7.33301C3.99984 6.96501 4.2985 6.66634 4.6665 6.66634H5.33317H6.6665H9.33317H10.6665H11.3332C11.7012 6.66634 11.9998 6.96501 11.9998 7.33301V12.6663ZM6.6665 4.07367C6.6665 3.29767 7.2645 2.66634 7.99984 2.66634C8.73517 2.66634 9.33317 3.29767 9.33317 4.07367V5.33301H6.6665V4.07367ZM11.3332 5.33301H10.6665V4.07368C10.6665 2.56301 9.4705 1.33301 7.99984 1.33301C6.52917 1.33301 5.33317 2.56301 5.33317 4.07368V5.33301H4.6665C3.56384 5.33301 2.6665 6.23034 2.6665 7.33301V12.6663C2.6665 13.769 3.56384 14.6663 4.6665 14.6663H11.3332C12.4358 14.6663 13.3332 13.769 13.3332 12.6663V7.33301C13.3332 6.23034 12.4358 5.33301 11.3332 5.33301Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1012_10873)"></g>
  </svg>
);

export const FillLockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8 11.5666C8.60015 11.5666 9.08666 11.0801 9.08666 10.48C9.08666 9.87983 8.60015 9.39331 8 9.39331C7.39985 9.39331 6.91333 9.87983 6.91333 10.48C6.91333 11.0801 7.39985 11.5666 8 11.5666Z"
      fill="white"
    />
    <path
      d="M12.1867 6.35325V5.51992C12.1867 3.71992 11.7534 1.33325 8.00004 1.33325C4.24671 1.33325 3.81337 3.71992 3.81337 5.51992V6.35325C1.94671 6.58659 1.33337 7.53325 1.33337 9.85992V11.0999C1.33337 13.8333 2.16671 14.6666 4.90004 14.6666H11.1C13.8334 14.6666 14.6667 13.8333 14.6667 11.0999V9.85992C14.6667 7.53325 14.0534 6.58659 12.1867 6.35325ZM8.00004 12.4933C6.88671 12.4933 5.98671 11.5866 5.98671 10.4799C5.98671 9.36659 6.89337 8.46659 8.00004 8.46659C9.10671 8.46659 10.0134 9.37325 10.0134 10.4799C10.0134 11.5933 9.11337 12.4933 8.00004 12.4933ZM4.90004 6.29325C4.84671 6.29325 4.80004 6.29325 4.74671 6.29325V5.51992C4.74671 3.56659 5.30004 2.26659 8.00004 2.26659C10.7 2.26659 11.2534 3.56659 11.2534 5.51992V6.29992C11.2 6.29992 11.1534 6.29992 11.1 6.29992H4.90004V6.29325Z"
      fill="white"
    />
  </svg>
);
