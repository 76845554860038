import styled from "styled-components";

import Button from "../../../components/Button/Button";
import { AIImagesSidebarTabs } from "../../../hooks/useAiImages";
import { AddIcon, EmptyGalleryIcon } from "../../../components/Icons/Icons";

interface Props {
  type: AIImagesSidebarTabs;
}

const EmptyHistory = ({ type }: Props) => (
  <Wrapper>
    <EmptyGalleryIcon />
    <ButtonWrapper>
      <Button icon={<AddIcon />} />
    </ButtonWrapper>
    <span>{type === AIImagesSidebarTabs.Text2Image ? "No image history yet" : "No video yet"}</span>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  span {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 20px;
    color: #757679;
  }
`;

const ButtonWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 19px;
  position: absolute;
  bottom: 51px;
  right: 69px;
  box-shadow: -4px -4px 8px 0px #49494933;
  box-shadow: 4px 4px 8px 0px #00000099;

  button {
    border-radius: 16px;
    height: 100%;
    width: 100%;
  }
`;

export default EmptyHistory;
