import { useSelector } from "react-redux";
import { getTheme } from "../../redux/reducers/themeReducer";

export const UserSquare = () => {
  const theme = useSelector(getTheme);
  const stroke = theme === "light" ? "#0063B4" : "#00B8E0";

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.1868 28.8267C23.0135 29.1733 21.6268 29.3334 20.0001 29.3334H12.0001C10.3735 29.3334 8.98681 29.1733 7.81348 28.8267C8.10681 25.36 11.6668 22.6266 16.0001 22.6266C20.3335 22.6266 23.8935 25.36 24.1868 28.8267Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M19.9998 2.66669H11.9998C5.33317 2.66669 2.6665 5.33335 2.6665 12V20C2.6665 25.04 4.1865 27.8 7.81317 28.8267C8.1065 25.36 11.6665 22.6266 15.9998 22.6266C20.3332 22.6266 23.8932 25.36 24.1865 28.8267C27.8132 27.8 29.3332 25.04 29.3332 20V12C29.3332 5.33335 26.6665 2.66669 19.9998 2.66669ZM15.9998 18.8933C13.3598 18.8933 11.2265 16.7467 11.2265 14.1067C11.2265 11.4667 13.3598 9.33335 15.9998 9.33335C18.6398 9.33335 20.7732 11.4667 20.7732 14.1067C20.7732 16.7467 18.6398 18.8933 15.9998 18.8933Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
      <path
        d="M20.7732 14.1067C20.7732 16.7467 18.6399 18.8933 15.9999 18.8933C13.3599 18.8933 11.2266 16.7467 11.2266 14.1067C11.2266 11.4667 13.3599 9.33331 15.9999 9.33331C18.6399 9.33331 20.7732 11.4667 20.7732 14.1067Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="stroke-color"
        style={{ fill: "none" }}
      />
    </svg>
  );
};
