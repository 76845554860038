import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Tabs from "./components/Tabs";
import Select from "../../components/Select/Select";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import CircularProgress from "../../components/Icons/CircularProgress";
import GalleryImageCard from "../../components/ProjectCard/GalleryImageCard";
import GalleryVideoCard from "../../components/ProjectCard/GalleryVideoCard";
import ProjectCardLarge from "../../components/ProjectCard/ProjectCardLarge";
import { Project } from "../../types/project";
import { useMyStudio } from "../../hooks/useMyStudio";
import { BlueArrowDown } from "../../components/Icons/Icons";
import { GalleryImage, GalleryVideo } from "../../types/image";
import { getProfile } from "../../redux/reducers/profileReducer";

export enum AIHumansStatus {
  All = 0,
  Draft = 1,
  InProgress = 2,
  Completed = 3,
  Failure = 4,
}

export enum ProjectsDisplayMode {
  aiHuman = "ai-humans",
  aiVoices = "actors",
  aiImages = "ai-images",
  aiVideos = "ai-videos",
}

const projectDisplayModeTabs = [
  {
    value: ProjectsDisplayMode.aiHuman,
    title: "AI Humans",
  },
  {
    value: ProjectsDisplayMode.aiVoices,
    title: "AI Voices",
  },
  {
    value: ProjectsDisplayMode.aiImages,
    title: "AI Images",
  },
  {
    value: ProjectsDisplayMode.aiVideos,
    title: "AI Videos",
  },
];

const aiHumansStatusOption = [
  {
    value: AIHumansStatus.All,
    title: "All",
  },
  {
    value: AIHumansStatus.Completed,
    title: "Completed",
  },
  {
    value: AIHumansStatus.Draft,
    title: "Draft",
  },
];

const options = {
  data: [
    {
      id: "1",
      label: "All",
      value: AIHumansStatus.All,
    },
    {
      id: "2",
      label: "Completed",
      value: AIHumansStatus.Completed,
    },
    {
      id: "3",
      label: "Draft",
      value: AIHumansStatus.Draft,
    },
  ],
};

const MyStudio = () => {
  const profile = useSelector(getProfile);

  const {
    featuresList: { list, isLoading, hasMore, currentPage },
    isStatusLoading,
    activeProject,
    selectedStatus,
    activeProjectTypeId,
    onChangeSelect,
    handleActiveProject,
    getProjectListFunc,
    getGalleryImagesFunc,
    getGalleryVideosFunc,
    setActiveProject,
    handleActiveDisplayMode,
    activeDisplayMode,
  } = useMyStudio({ options, aiHumansStatusOption, projectDisplayModeTabs });

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <MobileOnly>
            <MyStudioTitle>My Projects</MyStudioTitle>
          </MobileOnly>
          <div style={{ width: "100%" }}>
            <MobileOnly>
              <Sidebar mobile />
            </MobileOnly>
            <MyStudioTitleAndActionsWrapper>
              <MyStudioTitleAndActionsContent>
                <DesktopOnly>
                  <MyStudioTitle>My Studio</MyStudioTitle>
                </DesktopOnly>
              </MyStudioTitleAndActionsContent>
              <TopActionWrapper>
                {profile.allowToAIHuman && (
                  <Tabs
                    data={projectDisplayModeTabs}
                    active={activeDisplayMode}
                    handleActive={handleActiveDisplayMode}
                  />
                )}
                {activeDisplayMode === ProjectsDisplayMode.aiHuman && (
                  <SelectWrapper>
                    <Select
                      value={selectedStatus}
                      onChange={onChangeSelect}
                      optionsList={options}
                      defaultValueText="Sort By"
                      actionIcon={<BlueArrowDown />}
                    />
                  </SelectWrapper>
                )}
              </TopActionWrapper>
            </MyStudioTitleAndActionsWrapper>
          </div>
          {!isLoading &&
            !list.length &&
            activeDisplayMode !== ProjectsDisplayMode.aiImages &&
            activeDisplayMode !== ProjectsDisplayMode.aiVideos && <EmptyCase>There are no projects yet</EmptyCase>}
          {!isLoading && !list.length && activeDisplayMode === ProjectsDisplayMode.aiImages && (
            <EmptyCase>There are no images yet</EmptyCase>
          )}
          {!isLoading && !list.length && activeDisplayMode === ProjectsDisplayMode.aiVideos && (
            <EmptyCase>There are no videos yet</EmptyCase>
          )}
          <MyStudioContentWrapper
            id="scrollableDiv"
            displayMode={activeDisplayMode}
            isLoading={isLoading && !list.length}
          >
            <MyStudioContent
              {...{
                selectedValue: activeDisplayMode,
                isStatusLoading,
                getProjectListFunc,
                currentPage,
                activeProjectTypeId,
                hasMore,
                isLoading,
                list,
                activeProject,
                setActiveProject,
                handleActiveProject,
                getGalleryImagesFunc,
                getGalleryVideosFunc,
              }}
            />
          </MyStudioContentWrapper>
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

interface MyStudioContentProps {
  selectedValue: ProjectsDisplayMode;
  isStatusLoading: boolean;
  getProjectListFunc: ({ currentPage, activeProjectTypeId }: any) => void;
  currentPage: number;
  activeProjectTypeId?: number;
  hasMore: boolean;
  isLoading: boolean;
  list: Project[] | GalleryImage[] | GalleryVideo[];
  activeProject?: number | null;
  setActiveProject: (activeProject: number | null) => void;
  handleActiveProject: (e: any, id?: number) => void;
  getGalleryImagesFunc: (currentPage: number) => void;
  getGalleryVideosFunc: (currentPage: number) => void;
  maxItems?: number;
}

export const MyStudioContent = ({
  selectedValue,
  isStatusLoading,
  getProjectListFunc,
  currentPage,
  activeProjectTypeId,
  hasMore,
  isLoading,
  list,
  activeProject,
  setActiveProject,
  handleActiveProject,
  getGalleryImagesFunc,
  getGalleryVideosFunc,
  maxItems,
}: MyStudioContentProps) => {
  //const limitedList = list ? list.slice(0, maxItems) : [];
  const limitedList = maxItems !== undefined ? list?.slice(0, maxItems) || [] : list || [];
  console.log(limitedList, "limitedList");
  switch (selectedValue) {
    case ProjectsDisplayMode.aiVoices:
      return (
        <InfiniteScroll
          next={() => !isStatusLoading && getProjectListFunc({ currentPage, activeProjectTypeId })}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as Project[])?.map((project) => (
              <Link key={project.projectId} to={`/actors?projectId=${project.projectId}`}>
                <ProjectCard
                  project={project}
                  title={project?.title}
                  description={project.subTitle}
                  projectTime={project?.updateDateTime}
                  image={project?.coverImage}
                  active={project.projectId === activeProject}
                  setActiveProject={setActiveProject}
                  handleActiveProject={(e: any) => handleActiveProject(e, project.projectId)}
                />
              </Link>
            ))}
        </InfiniteScroll>
      );
    case ProjectsDisplayMode.aiHuman:
      return (
        <InfiniteScroll
          next={() => !isStatusLoading && getProjectListFunc({ currentPage, activeProjectTypeId })}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as Project[])?.map((project) => (
              <Link key={project.projectId} to={`/ai-humans?projectId=${project.projectId}`}>
                <ProjectCardLarge
                  project={project}
                  title={project?.title}
                  projectTime={project?.updateDateTime}
                  image={project?.coverImage}
                  status={project?.status}
                  output={project?.output}
                  active={project.projectId === activeProject}
                  setActiveProject={setActiveProject}
                  handleActiveProject={(e: any) => handleActiveProject(e, project.projectId)}
                />
              </Link>
            ))}
        </InfiniteScroll>
      );
    case ProjectsDisplayMode.aiImages:
      return (
        <InfiniteScroll
          next={() => getGalleryImagesFunc(currentPage)}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as GalleryImage[])?.map((image) => (
              <GalleryImageCard key={image.visualGalleryID} image={image} />
            ))}
        </InfiniteScroll>
      );
    case ProjectsDisplayMode.aiVideos:
      return (
        <InfiniteScroll
          next={() => getGalleryVideosFunc(currentPage)}
          hasMore={hasMore}
          loader={isLoading ? <CircularProgress color="#009af7" /> : null}
          dataLength={limitedList?.length}
          style={{ display: "contents" }}
          scrollableTarget="scrollableDiv"
        >
          {limitedList &&
            (limitedList as GalleryVideo[])?.map((video) => (
              <GalleryVideoCard key={video.videoGalleryID} video={video} />
            ))}
        </InfiniteScroll>
      );
    default:
      return <></>;
  }
};

const EmptyCase = styled.div`
  text-align: center;
  margin-top: 40px;
  color: ${({ theme }) => theme.primaryText};
`;

const TopActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  .container {
    max-width: 1208px;
  }

  @media (max-width: 1441px) {
    .container {
      max-width: 966px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const DesktopOnly = styled.div`
  flex: 1;

  @media (max-width: 1001px) {
    display: none;
  }
`;

const MyStudioTitleAndActionsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: 1001px) {
    margin-top: 0;
  }
`;

const MyStudioTitleAndActionsContent = styled.div<{ selectedValue?: ProjectsDisplayMode }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:last-of-type {
    display: flex;
    column-gap: 24px;
    align-items: center;
  }

  @media (max-width: 1001px) {
    flex-direction: column;
    align-items: start;
    gap: 10px;

    & > div:last-of-type {
      width: 100%;
    }
  }

  ${({ selectedValue }) =>
    selectedValue === ProjectsDisplayMode.aiVoices &&
    `
     justify-content: end;

      @media (max-width: 1150px) {
        flex-direction: row;
        & > div:last-of-type {
          width: auto;
        }
      }

     @media (max-width: 1001px) {
        & > div:last-of-type {
          width: 100%;
        }
      }
    `}
`;

const MyStudioTitle = styled("h1")`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
  flex: 1;

  @media (max-width: 1001px) {
    margin-bottom: 16px;
  }
`;

const SelectWrapper = styled.div`
  width: 150px;

  .selector-container {
    div {
      box-shadow: none;
    }
    align-items: center;
    .select-option-list {
      background-color: ${({ theme }) => theme.secondaryText};
    }
    span {
      padding: 3px;
      border: none;
      color: ${({ theme }) => theme.primaryText};
    }
    svg {
      background: none !important;
      top: 17px;
    }
  }
`;

const MyStudioContentWrapper = styled("div")<{ displayMode: ProjectsDisplayMode; isLoading?: boolean }>`
  position: relative;
  display: flex;
  align-content: stretch;
  overflow: hidden auto;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  max-height: 100%;
  gap: ${({ displayMode }) => (displayMode === ProjectsDisplayMode.aiVoices ? "14px" : "12px")};
  margin-top: 14px;
  padding: 8px 8px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  .project-card {
    width: 226px;
  }

  .project-card-large {
    width: 227px;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    `}

  @media (max-width: 1345px) {
    .project-card {
      width: 220px;
    }

    .project-card-large {
      width: 236px;
    }
  }

  @media (max-width: 550px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: ${({ displayMode }) =>
      displayMode === ProjectsDisplayMode.aiVoices
        ? "repeat(auto-fit, minmax(220px, 1fr))"
        : "repeat(auto-fit, minmax(236px, 1fr))"};
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 37px 0;
  }
`;

export default withPrivateRoute(MyStudio);
