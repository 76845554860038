import React from "react";
import styled from "styled-components";

import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import Button from "../../components/Button/Button";
import { ButtonThemes } from "../../components/Button/Button";

const PricingPage = () => {
  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <Title>Pricing</Title>
          <Row>
            <Column>
              <PlanTitle>
                $199<PlanSmall>/mo</PlanSmall>
              </PlanTitle>
              <ul>
                <li>
                  <CheckmarkIcon /> <span>Up to 60 hours/mo</span>
                </li>
                <li>
                  <CheckmarkIcon /> <span>Seamless integration using API Key</span>
                </li>
                <li>
                  <CheckmarkIcon /> <span>Access to 40+ Real Human Voices</span>
                </li>
                <li>
                  <CheckmarkIcon /> <span>Access to 140+ Languages & 370+ voices</span>
                </li>
                <li>
                  <CheckmarkIcon /> <span>Priority support</span>
                </li>
              </ul>
              <a
                href="https://app.paykickstart.com/checkout/plan/RwKP1dNXLGorAWPdv8W4mO3x70JZy2MY"
                target="_blank"
                rel="noreferrer"
              >
                <Button text={"Start"} />
              </a>
            </Column>
            <Column className="custom">
              <CustomTitle>Enterprise Pricing</CustomTitle>
              <ul>
                <li>
                  <CheckmarkIcon /> <span> Looking for additional features? Our Sales Team is here to help. </span>
                </li>
              </ul>
              <a
                href="https://1l1y8nl2ulc.typeform.com/to/LKNERYHt"
                target="_blank"
                rel="noreferrer"
                style={{ marginTop: "auto" }}
              >
                <Button text={"Contact us"} buttonTheme={ButtonThemes.Outline} />
              </a>
            </Column>
          </Row>
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const CheckmarkIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8125 3.93777L5.6875 10.0625L2.625 7.00028"
      stroke="url(#paint0_linear_0_2811)"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_2811"
        x1="4.60385"
        y1="4.7387"
        x2="7.66282"
        y2="10.6384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);

const Row = styled("div")`
  display: flex;
  width: 100%;
  column-gap: 16px;
  margin-top: 24px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
  }
`;

const Column = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.mainCardShadowPricing};
  border-radius: 16px;
  padding: 40px;
  width: 50%;
  max-width: 480px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 410px) {
    padding: 30px;
  }

  ul {
    margin-top: 14px;
    margin-bottom: 32px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #6f7074;
    li {
      margin: 8px 0;
      width: 100%;
      display: flex;
      align-items: flex-start;
      column-gap: 8px;
      svg {
        display: block;
        margin-top: 4px;
      }
    }
  }

  &.custom {
    button {
      border-width: 2px;
    }
  }
`;

const PlanTitle = styled("div")`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.primaryText};
`;

const CustomTitle = styled("div")`
  font-weight: 600;
  font-size: 32px;
  line-height: 54px;
  color: ${({ theme }) => theme.primaryText};
`;

const PlanSmall = styled("span")`
  color: #6f7074;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-left: 8px;
  letter-spacing: 0.5px;
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  display: flex;
  min-height: 100vh;

  .container {
    max-width: 1200px;
  }
`;

const Content = styled.div``;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const DesktopOnly = styled.div`
  flex: 1;

  @media (max-width: 1001px) {
    display: none;
  }
`;

const Title = styled("h1")`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
  flex: 1;
`;

const ApiAccessContentWrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 16px;

  & > div {
    flex: 1;
  }

  @media (max-width: 1001px) {
    flex-direction: column;

    & > div {
      max-width: 100% !important;
    }
  }
`;

export default withPrivateRoute(PricingPage);
