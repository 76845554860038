export const DropdownGalleryEdit = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.00001 6.66667C6.73639 6.66667 7.33334 6.06971 7.33334 5.33333C7.33334 4.59695 6.73639 4 6.00001 4C5.26363 4 4.66667 4.59695 4.66667 5.33333C4.66667 6.06971 5.26363 6.66667 6.00001 6.66667Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66666 1.33325H6C2.66666 1.33325 1.33333 2.66659 1.33333 5.99992V9.99992C1.33333 13.3333 2.66666 14.6666 6 14.6666H10C13.3333 14.6666 14.6667 13.3333 14.6667 9.99992V6.66659"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.76 1.72669L10.34 4.14669C10.2467 4.24002 10.1533 4.42002 10.14 4.55335L10.0067 5.48002C9.96001 5.81335 10.1933 6.04669 10.5267 6.00002L11.4533 5.86669C11.58 5.84669 11.7667 5.76002 11.86 5.66669L14.28 3.24669C14.7 2.82669 14.8933 2.34669 14.28 1.73335C13.66 1.10669 13.18 1.30669 12.76 1.72669Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4133 2.07324C12.62 2.80658 13.1933 3.37991 13.9267 3.58658"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.78 12.6333L5.06667 10.4266C5.59333 10.0733 6.35333 10.1133 6.82667 10.52L7.04667 10.7133C7.56667 11.16 8.40667 11.16 8.92667 10.7133L11.7 8.33329C12.22 7.88662 13.06 7.88662 13.58 8.33329L14.6667 9.26662"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
