export const FullBodyHumatarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3335 4.66667C9.3335 3.93133 8.7355 3.33333 8.00016 3.33333C7.26483 3.33333 6.66683 3.93133 6.66683 4.66667C6.66683 5.402 7.26483 6 8.00016 6C8.7355 6 9.3335 5.402 9.3335 4.66667ZM10.6668 4.66667C10.6668 6.13733 9.47083 7.33333 8.00016 7.33333C6.5295 7.33333 5.3335 6.13733 5.3335 4.66667C5.3335 3.196 6.5295 2 8.00016 2C9.47083 2 10.6668 3.196 10.6668 4.66667ZM3.3335 13.3333C3.3335 10.76 5.4275 8.66667 8.00016 8.66667C10.5728 8.66667 12.6668 10.76 12.6668 13.3333C12.6668 13.7013 12.3688 14 12.0002 14C11.6315 14 11.3335 13.7013 11.3335 13.3333C11.3335 11.4953 9.83816 10 8.00016 10C6.16216 10 4.66683 11.4953 4.66683 13.3333C4.66683 13.7013 4.36883 14 4.00016 14C3.6315 14 3.3335 13.7013 3.3335 13.3333Z"
      fill="url(#paint0_linear_1_10629)"
    />
    <mask
      id="mask0_1_10629"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="2"
      width="10"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3335 4.66667C9.3335 3.93133 8.7355 3.33333 8.00016 3.33333C7.26483 3.33333 6.66683 3.93133 6.66683 4.66667C6.66683 5.402 7.26483 6 8.00016 6C8.7355 6 9.3335 5.402 9.3335 4.66667ZM10.6668 4.66667C10.6668 6.13733 9.47083 7.33333 8.00016 7.33333C6.5295 7.33333 5.3335 6.13733 5.3335 4.66667C5.3335 3.196 6.5295 2 8.00016 2C9.47083 2 10.6668 3.196 10.6668 4.66667ZM3.3335 13.3333C3.3335 10.76 5.4275 8.66667 8.00016 8.66667C10.5728 8.66667 12.6668 10.76 12.6668 13.3333C12.6668 13.7013 12.3688 14 12.0002 14C11.6315 14 11.3335 13.7013 11.3335 13.3333C11.3335 11.4953 9.83816 10 8.00016 10C6.16216 10 4.66683 11.4953 4.66683 13.3333C4.66683 13.7013 4.36883 14 4.00016 14C3.6315 14 3.3335 13.7013 3.3335 13.3333Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_10629)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_1_10629"
        x1="5.34375"
        y1="3.56923"
        x2="12.6771"
        y2="10.9026"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
