import styled, { css } from "styled-components";
import {
  CheckIcon1616White,
  Flash,
  InfoIcon1818Grey,
  InfoIcon1818White,
  PercentLimited,
} from "../../../components/Icons/Icons";
import { IExternalLinks } from "../../../lib/routeUtils";
import Button from "../../PricingPage2/components/Button";
import SelectPlanButton from "../../PricingPage2/components/SelectPlanButton";
import Tooltip from "../../PricingPage2/components/Tooltip";

type TransparentPlanCardTypes = "Free" | "Monthly" | "Yearly";

export interface TransparentPlanCardCardProps {
  plan: TransparentPlanCardTypes;
  isLimited: boolean;
  price: string;
  trigger: string;
  yearlyBilled: string;
  list: {
    text: React.ReactNode;
    important?: boolean;
    tooltip: string;
  }[];
}

const TransparentPlanCard = ({ plan, isLimited, price, trigger, yearlyBilled, list }: TransparentPlanCardCardProps) => {
  const yearly = plan === "Yearly";

  return (
    <Wrapper isBusiness={yearly}>
      {yearly && (
        <BusinessWrapper>
          <div>
            <MostPopular>Most popular</MostPopular>
            <Flash />
          </div>
        </BusinessWrapper>
      )}
      <TopInfo>
        <TopWrapper>
          <Title isBusiness={yearly}>{plan}</Title>
          <LimitedLable isLimited={isLimited}>
            <PercentLimited />
            <span>Limited Time 50% OFF </span>
          </LimitedLable>
          <PriceWrapper>
            <MonthlyPrice isBusiness={yearly}>
              <span className="newPrice">
                ${price}
                {plan !== "Free" && <span className="month">/m</span>}
              </span>
            </MonthlyPrice>
            <YearlyPrice yearlyBilled={yearlyBilled} isBusiness={yearly}>
              ${yearlyBilled} Billed Yearly
            </YearlyPrice>
          </PriceWrapper>
        </TopWrapper>
        <ButtonWrapper isBusiness={yearly}>
          {plan === "Free" ? (
            <Button color="pricingOutlineWhite" linkPath={IExternalLinks.translator} type="link">
              Start for free
            </Button>
          ) : (
            <SelectPlanButton
              popupHash={trigger}
              buttonColor={yearly ? "pricingBlue" : "pricingOutlineWhite"}
              planType={"FREE"}
            />
          )}
          <Prefer isBusiness={yearly}>
            <img src="/images/pricing/prefer.png" alt="people who prefer" width={78} height={24} />
            <span>Preferred by 76% of experts!</span>
          </Prefer>
        </ButtonWrapper>
      </TopInfo>
      <Separator isBusiness={yearly} />
      <BottomInfo>
        <ProsWrapper>
          {list.map(({ text, tooltip, important }, idx) => (
            <Pros isBusiness={yearly} isImportant={important} key={idx}>
              <span className="checkWrapper">
                <CheckIcon1616White />
              </span>
              <ProsText isBusiness={yearly}>
                {text}
                {tooltip && (
                  <span className="tooltipWrapper">
                    {yearly ? <InfoIcon1818Grey /> : <InfoIcon1818White />}
                    <Tooltip title={tooltip} />
                  </span>
                )}
              </ProsText>
            </Pros>
          ))}
        </ProsWrapper>
      </BottomInfo>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isBusiness: boolean }>`
  width: calc((100% - 48px) / 3);
  min-width: 380px;
  display: flex;
  padding: 40px 20px 32px 20px;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: ${({ isBusiness }) => (isBusiness ? "transparent" : "#2a74b1")};
  position: relative;

  @media screen and (max-width: 440px) {
    min-width: 330px;
    padding: 16px;
  }
`;

const BusinessWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 105%;
  border-radius: 16px;
  background: #ffffff;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
  }

  @media screen and (max-width: 440px) {
    height: 107%;
    div {
      padding: 16px;
    }
  }
`;

const MostPopular = styled.span`
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%);

  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
`;

const TopInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 1;

  @media screen and (max-width: 440px) {
    gap: 24px;
  }
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media screen and (max-width: 440px) {
    gap: 16px;
  }
`;

const Title = styled.p<{ isBusiness: boolean }>`
  color: ${({ isBusiness }) => (isBusiness ? "#191B1F" : "#ffffff")};
  font-size: 28px;
  font-weight: 600;
  line-height: 1.14;

  @media screen and (max-width: 440px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: -0.96px;
  }
`;

const LimitedLable = styled.div<{ isLimited: boolean }>`
  visibility: ${({ isLimited }) => (isLimited ? "visible" : "hidden")};

  width: max-content;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 60px;
  background: #0aae7d;

  span {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MonthlyPrice = styled.div<{ isBusiness: boolean }>`
  display: flex;
  gap: 16px;
  align-items: baseline;

  .oldPrice {
    color: ${({ isBusiness }) => (isBusiness ? "#6F7074" : "#f3f4f9")};
    font-size: 25px;
    font-weight: 500;
    line-height: 26px;
    position: relative;

    .crossedMark {
      position: absolute;
      top: 0;
      left: 5%;
      opacity: ${({ isBusiness }) => (isBusiness ? "0.4" : "0.5")};

      svg {
        fill: ${({ isBusiness }) => (isBusiness ? "#3b3d47" : "#ffffff")};
        width: 55px;
        height: auto;
      }
    }
  }

  .newPrice {
    color: ${({ isBusiness }) => (isBusiness ? "#191B1F" : "#ffffff")};
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;

    .month {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
`;

const YearlyPrice = styled.p<{ yearlyBilled: string; isBusiness: boolean }>`
  visibility: ${({ yearlyBilled }) => (yearlyBilled !== "" ? "visible" : "hidden")};
  color: ${({ isBusiness }) => (isBusiness ? "#3B3D47" : "#ffffff")};

  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;

  span {
    font-weight: 600;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div<{ isBusiness: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  background: ${({ isBusiness }) => (isBusiness ? "#ddf0ff" : "transparent")};
  padding-bottom: 8px;

  .primaryButton {
    width: 100%;
    height: 48px;

    .primaryButtonText {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      letter-spacing: -0.41px;
    }
  }
`;

const Prefer = styled.div<{ isBusiness: boolean }>`
  visibility: ${({ isBusiness }) => (isBusiness ? "visible" : "hidden")};

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  img {
    display: block;
    width: 78px;
    height: auto;
  }

  span {
    color: #3b3d47;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
  }

  @media screen and (max-width: 440px) {
    span {
      font-size: 12px;
    }
  }
`;

const Separator = styled.div<{ isBusiness: boolean }>`
  background: ${({ isBusiness }) => (isBusiness ? "#D2D2D9" : "#458ac4")};

  width: 100%;
  height: 1px;
  z-index: 1;
`;

const BottomInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  z-index: 1;
`;

const ProsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media screen and (max-width: 440px) {
    gap: 12px;
  }
`;

const Pros = styled.li<{ isBusiness: boolean; isImportant?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  width: 100%;

  .checkWrapper {
    align-self: flex-start;
    display: flex;
    padding-top: 3px;

    svg > path {
      stroke: ${({ isBusiness }) => (isBusiness ? "#0274d1" : "#ffffff")};
    }
  }

  ${({ isImportant, isBusiness }) =>
    isImportant &&
    css`
      ::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        border-radius: 4px;
        background: ${isBusiness ? "#DDF0FF" : "rgba(255, 255, 255, 0.2)"};
      }
    `}

  @media screen and (max-width: 440px) {
    .checkWrapper > svg {
      width: 16px;
      height: auto;
    }
  }
`;

const ProsText = styled.p<{ isBusiness: boolean }>`
  color: ${({ isBusiness }) => (isBusiness ? "#3B3D47" : "#ffffff")};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: -0.38px;
  position: relative;

  .tooltipWrapper {
    position: absolute;
    right: -19px;
    top: 3px;
    display: flex;
    cursor: help;

    .tooltip {
      max-width: 190px;
    }

    :hover .tooltip {
      opacity: 1;
    }

    svg {
      width: 17px;
      height: auto;
    }
  }

  @media screen and (max-width: 440px) {
    font-size: 12px;

    .tooltipWrapper {
      right: -17px;
      top: 2px;

      svg {
        width: 15px;
        height: auto;
      }
    }
  }
`;

export default TransparentPlanCard;
