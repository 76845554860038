import { AuthModules } from "../../types/auth";
import {
  ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER,
  GET_CELLO_TOKEN_SERVER,
  GET_OAUTH,
  GET_TOKEN_USER,
  GET_USER_BY_APPSUMO_TOKEN_SERVER,
  LOGOUT,
  REGISTER_GUEST_USER_SERVER,
  SET_AUTH_TOKEN,
  SET_IS_AUTHORIZED,
  SIGN_IN_SERVER,
  SIGN_IN_WITH_GOOGLE_SERVER,
  SIGN_UP_SERVER,
  UPDATE_AUTH_LOADING,
} from "../actions/authActions";
import { StoreType } from "../../types/store";
import { REHYDRATE } from "redux-persist";
import { parseISO } from "date-fns";
import { getAuthStatusData } from "../../lib/getAuthStatusData";
import { toast } from "react-toastify";
import Navigate from "../../lib/Navigate";
import { pages } from "../../lib/routeUtils";

export interface authStateType {
  [AuthModules.signIn]: {
    isLoading: boolean;
  };
  [AuthModules.signUp]: {
    isLoading: boolean;
  };
  [AuthModules.recoverPassword]: {
    isLoading: boolean;
  };
  [AuthModules.checkMail]: {
    isLoading: boolean;
  };
  [AuthModules.resetPassword]: {
    isLoading: boolean;
  };
  [AuthModules.getTokenUser]: {
    isLoading: boolean;
  };
  [AuthModules.appsumoLogin]: {
    isLoadingGetUser: boolean;
    isLoadingLogin: boolean;
  };
  [AuthModules.registerGuestUser]: {
    isLoading: boolean;
  };
  [AuthModules.signInWithGoogle]: {
    isLoading: boolean;
  };
  [AuthModules.oauth]: {
    redirect_uri: string;
    state: string;
  };
  [AuthModules.cello]: {
    token?: string;
  };
  isAuthorized: boolean;
  tokens: {
    access: string;
    accessExpiration: Date | null;
  };
  user: {
    email?: string;
    name?: string;
  };
  isHydrated: boolean;
}

const authInitialState: authStateType = {
  [AuthModules.signIn]: {
    isLoading: false,
  },
  [AuthModules.appsumoLogin]: {
    isLoadingGetUser: false,
    isLoadingLogin: false,
  },
  [AuthModules.signUp]: {
    isLoading: false,
  },
  [AuthModules.recoverPassword]: {
    isLoading: false,
  },
  [AuthModules.checkMail]: {
    isLoading: false,
  },
  [AuthModules.resetPassword]: {
    isLoading: false,
  },
  [AuthModules.getTokenUser]: {
    isLoading: false,
  },
  [AuthModules.registerGuestUser]: {
    isLoading: false,
  },
  [AuthModules.signInWithGoogle]: {
    isLoading: false,
  },
  [AuthModules.oauth]: {
    redirect_uri: "",
    state: "",
  },
  [AuthModules.cello]: {
    token: "",
  },
  isAuthorized: false,
  tokens: {
    access: "",
    accessExpiration: null,
  },
  user: {
    email: "",
    name: "",
  },
  isHydrated: false,
};

const authReducer = (state = authInitialState, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const data = action.payload;
      if (data) {
        const { access, accessExpiration } = data?.auth?.tokens || {};

        return {
          ...state,
          ...getAuthStatusData({ access, accessExpiration }),
          // [AuthModules.oauth]: {
          //   ...state[AuthModules.oauth],
          //   ...data?.auth?.oauth,
          // },
          isHydrated: true,
        };
      }
      return { ...state, isHydrated: true };
    }

    case LOGOUT: {
      return { ...state, ...authInitialState };
    }

    case SET_IS_AUTHORIZED: {
      return { ...state, isAuthorized: action.payload };
    }
    case UPDATE_AUTH_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as AuthModules], isLoading } };
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        isAuthorized: action.payload.isAuthorized,
        tokens: {
          access: action.payload.accessToken,
        },
      };
    }
    case `${GET_TOKEN_USER}_SUCCESS`: {
      if (action.payload) {
        const { email, firstName, lastName } = action.payload.data.data;

        return {
          ...state,
          user: {
            email,
            name: `${firstName} ${lastName}`,
          },
        };
      } else {
        toast.error("User already exists!");
        Navigate.push(pages.main());
        return { ...state };
      }
    }
    case `${ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER}_SUCCESS`:
    case `${SIGN_IN_WITH_GOOGLE_SERVER}_SUCCESS`:
    case `${REGISTER_GUEST_USER_SERVER}_SUCCESS`:
    case `${SIGN_UP_SERVER}_SUCCESS`:
    case `${SIGN_IN_SERVER}_SUCCESS`: {
      if (action.payload.data.data) {
        const { token, expiration: expirationString } = action.payload.data.data;

        return {
          ...state,
          tokens: { access: token || state.tokens.access, accessExpiration: parseISO(expirationString) },
        };
      } else {
        return { ...state };
      }
    }
    case ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER: {
      return {
        ...state,
        [AuthModules.appsumoLogin]: {
          ...state[AuthModules.appsumoLogin],
          isLoadingLogin: true,
        },
      };
    }
    case `${ACTIVE_USER_BY_APPSUMO_TOKEN_SERVER}_FAIL`: {
      toast.error("Failed to active your user from AppSumo, please contact Synthesys support");
      return {
        ...state,
        [AuthModules.appsumoLogin]: {
          ...state[AuthModules.appsumoLogin],
          isLoadingLogin: false,
        },
      };
    }
    case GET_USER_BY_APPSUMO_TOKEN_SERVER: {
      return {
        ...state,
        [AuthModules.appsumoLogin]: {
          ...state[AuthModules.appsumoLogin],
          isLoadingGetUser: true,
        },
      };
    }
    case `${GET_USER_BY_APPSUMO_TOKEN_SERVER}_SUCCESS`: {
      const email = action?.payload?.data?.data;
      return {
        ...state,
        [AuthModules.appsumoLogin]: {
          ...state[AuthModules.appsumoLogin],
          isLoadingGetUser: false,
        },
        user: {
          email,
        },
      };
    }
    case `${GET_USER_BY_APPSUMO_TOKEN_SERVER}_FAIL`: {
      return {
        ...state,
        [AuthModules.appsumoLogin]: {
          ...state[AuthModules.appsumoLogin],
          isLoadingGetUser: false,
        },
      };
    }
    case GET_OAUTH: {
      return {
        ...state,
        [AuthModules.oauth]: action.payload,
      };
    }
    case `${GET_CELLO_TOKEN_SERVER}_SUCCESS`: {
      const { data: token } = action.payload.data;

      return {
        ...state,
        [AuthModules.cello]: {
          ...state[AuthModules.cello],
          token,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getIsAuthorized = (state: StoreType) => state.auth.isAuthorized;
export const getIsSignInLoading = (state: StoreType) => state.auth[AuthModules.signIn].isLoading;
export const getIsSignUpLoading = (state: StoreType) => state.auth[AuthModules.signUp].isLoading;
export const getIsRecoverPasswordLoading = (state: StoreType) => state.auth[AuthModules.recoverPassword].isLoading;
export const getIsResetPasswordLoading = (state: StoreType) => state.auth[AuthModules.resetPassword].isLoading;
export const getIsTokenUserLoading = (state: StoreType) => state.auth[AuthModules.getTokenUser].isLoading;
export const getIsHydrated = (state: StoreType) => state.auth.isHydrated;
export const getUser = (state: StoreType) => state.auth.user;
export const getIsCheckMailLoading = (state: StoreType) => state.auth[AuthModules.checkMail].isLoading;
export const registerGuestUserLoading = (state: StoreType) => state.auth[AuthModules.registerGuestUser].isLoading;
export const getIsSignInWithGoogleLoading = (state: StoreType) => state.auth[AuthModules.signInWithGoogle].isLoading;
export const getAppsumoLoginDetails = (state: StoreType) => state.auth[AuthModules.appsumoLogin];
export const getOauth = (state: StoreType) => state.auth[AuthModules.oauth];
export const getCelloToken = (state: StoreType) => state.auth[AuthModules.cello].token;

export default authReducer;
