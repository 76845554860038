import styled from "styled-components";
import Button from "../../../components/Button/Button";

interface Props {
  data: any;
  active: string;
  disabled?: boolean;
  handleActive: (background: string) => void;
}

const Tabs = ({ data, active, disabled = false, handleActive }: Props) => {
  return (
    <Wrapper disabled={disabled}>
      {data.map((type: string, order: number) => (
        <Button
          key={order}
          text={type}
          className={type === active ? "active" : "not-active"}
          onClick={() => handleActive(type)}
        />
      ))}
    </Wrapper>
  );
};

export const Wrapper = styled.div<{ disabled?: boolean }>`
  padding: 4px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.themeSwitcherShadow};
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: 30px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
  `}

  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }
  ::-webkit-scrollbar-track {
    margin: 0 15px;
  }

  button {
    border-radius: 52px;
    height: 30px;
    min-width: 88px;

    span {
      font-size: 14px;
      line-height: 24px;
    }

    &.not-active {
      background: transparent;
      box-shadow: none;
      opacity: 0.4;

      span {
        font-weight: 500;
        color: ${({ theme }) => theme.primaryText};
      }
    }
  }
`;

export default Tabs;
