export const WorldIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.09999 5.25H11.9M2.09999 8.75H11.9M6.70835 1.75C5.72564 3.32477 5.20464 5.14375 5.20464 7C5.20464 8.85625 5.72564 10.6752 6.70835 12.25M7.29167 1.75C8.27438 3.32477 8.79538 5.14375 8.79538 7C8.79538 8.85625 8.27438 10.6752 7.29167 12.25M1.75 7C1.75 7.68944 1.8858 8.37213 2.14963 9.00909C2.41347 9.64605 2.80018 10.2248 3.28769 10.7123C3.7752 11.1998 4.35395 11.5865 4.99091 11.8504C5.62787 12.1142 6.31056 12.25 7 12.25C7.68944 12.25 8.37213 12.1142 9.00909 11.8504C9.64605 11.5865 10.2248 11.1998 10.7123 10.7123C11.1998 10.2248 11.5865 9.64605 11.8504 9.00909C12.1142 8.37213 12.25 7.68944 12.25 7C12.25 5.60761 11.6969 4.27226 10.7123 3.28769C9.72774 2.30312 8.39239 1.75 7 1.75C5.60761 1.75 4.27226 2.30312 3.28769 3.28769C2.30312 4.27226 1.75 5.60761 1.75 7Z"
      stroke="url(#paint0_linear_1_6993)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_6993"
        x1="4.01154"
        y1="3.12308"
        x2="10.2308"
        y2="11.1192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
