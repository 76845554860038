export const BringLayerToFrontIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99998 14.6663H9.99998C13.3333 14.6663 14.6666 13.333 14.6666 9.99967V5.99967C14.6666 2.66634 13.3333 1.33301 9.99998 1.33301H5.99998C2.66665 1.33301 1.33331 2.66634 1.33331 5.99967V9.99967C1.33331 13.333 2.66665 14.6663 5.99998 14.6663Z"
      stroke="url(#paint0_linear_29_615)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6.33984L8 4.33984L10 6.33984"
      stroke="url(#paint1_linear_29_615)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 4.33984V9.67318"
      stroke="url(#paint2_linear_29_615)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 11.0068C6.59333 11.8735 9.40667 11.8735 12 11.0068"
      stroke="url(#paint3_linear_29_615)"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_29_615"
        x1="4.20511"
        y1="3.0766"
        x2="12.1025"
        y2="13.2304"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_29_615"
        x1="6.86154"
        y1="4.60138"
        x2="7.68728"
        y2="6.72471"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_29_615"
        x1="8.21538"
        y1="5.03728"
        x2="9.7005"
        y2="5.3953"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_29_615"
        x1="5.72308"
        y1="11.0918"
        x2="5.77308"
        y2="11.8831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
