import {
  AtsLogoWhite,
  CocaColaLogoWhite,
  NvidiaLogoWhite,
  TataLogoWhite,
  YahooLogoWhite,
} from "../components/Icons/Icons";
import { IExternalLinks } from "../lib/routeUtils";

export const brands = [
  {
    id: 1,
    icon: <TataLogoWhite />,
    path: IExternalLinks.tcs,
  },
  {
    id: 2,
    icon: <YahooLogoWhite />,
    path: IExternalLinks.yahooInc,
  },
  {
    id: 3,
    icon: <NvidiaLogoWhite />,
    path: IExternalLinks.nVidia,
  },
  {
    id: 4,
    icon: <CocaColaLogoWhite />,
    path: IExternalLinks.cocaCola,
  },
  {
    id: 5,
    icon: <AtsLogoWhite />,
    path: IExternalLinks.ats,
  },
];
