export const VideoIcon2626 = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.664 15.816L18.621 12.9994L21.664 10.1838V15.816ZM16.2487 17.3328C16.2487 17.9308 15.7634 18.4161 15.1654 18.4161H5.41536C4.81736 18.4161 4.33203 17.9308 4.33203 17.3328V8.6661C4.33203 8.06918 4.81736 7.58276 5.41536 7.58276H15.1654C15.7634 7.58276 16.2487 8.06918 16.2487 8.6661V17.3328ZM22.7624 7.74085C22.0842 7.4451 21.2977 7.57402 20.7571 8.07235L18.416 10.239V8.66602C18.416 6.87418 16.9579 5.41602 15.166 5.41602H5.41602C3.62418 5.41602 2.16602 6.87418 2.16602 8.66602V17.3327C2.16602 19.1256 3.62418 20.5827 5.41602 20.5827H15.166C16.9579 20.5827 18.416 19.1256 18.416 17.3327V15.7608L20.7571 17.9264C21.1038 18.2481 21.5534 18.416 22.0116 18.416C22.2651 18.416 22.5197 18.3651 22.7624 18.2589C23.4221 17.9708 23.8327 17.3544 23.8327 16.6513V9.34852C23.8327 8.64543 23.4221 8.02902 22.7624 7.74085Z"
      fill="#191B1F"
    />
    <mask id="mask0_1_365" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="2" y="5" width="22" height="16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.664 15.816L18.621 12.9994L21.664 10.1838V15.816ZM16.2487 17.3328C16.2487 17.9308 15.7634 18.4161 15.1654 18.4161H5.41536C4.81736 18.4161 4.33203 17.9308 4.33203 17.3328V8.6661C4.33203 8.06918 4.81736 7.58276 5.41536 7.58276H15.1654C15.7634 7.58276 16.2487 8.06918 16.2487 8.6661V17.3328ZM22.7624 7.74085C22.0842 7.4451 21.2977 7.57402 20.7571 8.07235L18.416 10.239V8.66602C18.416 6.87418 16.9579 5.41602 15.166 5.41602H5.41602C3.62418 5.41602 2.16602 6.87418 2.16602 8.66602V17.3327C2.16602 19.1256 3.62418 20.5827 5.41602 20.5827H15.166C16.9579 20.5827 18.416 19.1256 18.416 17.3327V15.7608L20.7571 17.9264C21.1038 18.2481 21.5534 18.416 22.0116 18.416C22.2651 18.416 22.5197 18.3651 22.7624 18.2589C23.4221 17.9708 23.8327 17.3544 23.8327 16.6513V9.34852C23.8327 8.64543 23.4221 8.02902 22.7624 7.74085Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_365)"></g>
  </svg>
);
