import { useState, useEffect } from "react";
import styled from "styled-components";
import { CrossIcon } from "../Icons/CrossIcon";

interface Props {
  targetElId: string;
  cursorSizePx: number;
}

const borderWidthPx = 1;

const DrawingCursor = ({ targetElId, cursorSizePx }: Props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);

    const element = document.getElementById(targetElId);

    const handleMouseMove = (event: MouseEvent) => {
      const { x: elX, y: elY } = element?.getBoundingClientRect() || { x: 0, y: 0 };
      const x = event.clientX - elX - (cursorSizePx + 2 * borderWidthPx) / 2;
      const y = event.clientY - elY - (cursorSizePx + 2 * borderWidthPx) / 2;

      setPosition({ x, y });
    };

    element?.addEventListener("mouseenter", handleMouseEnter);
    element?.addEventListener("mousemove", handleMouseMove);
    element?.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      element?.removeEventListener("mouseenter", handleMouseEnter);
      element?.removeEventListener("mousemove", handleMouseMove);
      element?.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [cursorSizePx]);

  return (
    <Cursor
      style={{
        left: position.x,
        top: position.y,
        display: isVisible ? "flex" : "none",
        width: `${cursorSizePx + 2 * borderWidthPx}px`,
        height: `${cursorSizePx + 2 * borderWidthPx}px`,
      }}
    >
      <CrossIcon />
    </Cursor>
  );
};

const Cursor = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  border-radius: 50%;
  border: ${borderWidthPx}px dashed black;
  justify-content: center;
  align-items: center;
`;

export default DrawingCursor;
