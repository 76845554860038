import { languages } from "./languages";

export const settingOptionsList = {
  countries: {
    label: "Country",
    id: "country",
    data: [
      {
        id: "Afghanistan",
        label: "Afghanistan",
        value: "Afghanistan",
      },
      {
        id: "Albania",
        label: "Albania",
        value: "Albania",
      },
      {
        id: "Algeria",
        label: "Algeria",
        value: "Algeria",
      },
      {
        id: "Argentina",
        label: "Argentina",
        value: "Argentina",
      },
      {
        id: "Armenia",
        label: "Armenia",
        value: "Armenia",
      },
      {
        id: "Australia",
        label: "Australia",
        value: "Australia",
      },
      {
        id: "Austria",
        label: "Austria",
        value: "Austria",
      },
      {
        id: "Bahrain",
        label: "Bahrain",
        value: "Bahrain",
      },
      {
        id: "Bangladesh",
        label: "Bangladesh",
        value: "Bangladesh",
      },
      {
        id: "Basque",
        label: "Basque",
        value: "Basque",
      },
      {
        id: "Belgium",
        label: "Belgium",
        value: "Belgium",
      },
      {
        id: "Bolivia",
        label: "Bolivia",
        value: "Bolivia",
      },
      {
        id: "Bosnia and Herzegovina",
        label: "Bosnia and Herzegovina",
        value: "Bosnia and Herzegovina",
      },
      {
        id: "Brazil",
        label: "Brazil",
        value: "Brazil",
      },
      {
        id: "Bulgaria",
        label: "Bulgaria",
        value: "Bulgaria",
      },
      {
        id: "Cambodia",
        label: "Cambodia",
        value: "Cambodia",
      },
      {
        id: "Canada",
        label: "Canada",
        value: "Canada",
      },
      {
        id: "Cantonese, Traditional",
        label: "Cantonese, Traditional",
        value: "Cantonese, Traditional",
      },
      {
        id: "Chile",
        label: "Chile",
        value: "Chile",
      },
      {
        id: "Colombia",
        label: "Colombia",
        value: "Colombia",
      },
      {
        id: "Costa Rica",
        label: "Costa Rica",
        value: "Costa Rica",
      },
      {
        id: "Croatia",
        label: "Croatia",
        value: "Croatia",
      },
      {
        id: "Cuba",
        label: "Cuba",
        value: "Cuba",
      },
      {
        id: "Cyrillic, Serbia",
        label: "Cyrillic, Serbia",
        value: "Cyrillic, Serbia",
      },
      {
        id: "Czechia",
        label: "Czechia",
        value: "Czechia",
      },
      {
        id: "Denmark",
        label: "Denmark",
        value: "Denmark",
      },
      {
        id: "Dominican Republic",
        label: "Dominican Republic",
        value: "Dominican Republic",
      },
      {
        id: "Ecuador",
        label: "Ecuador",
        value: "Ecuador",
      },
      {
        id: "Egypt",
        label: "Egypt",
        value: "Egypt",
      },
      {
        id: "El Salvador",
        label: "El Salvador",
        value: "El Salvador",
      },
      {
        id: "Equatorial Guinea",
        label: "Equatorial Guinea",
        value: "Equatorial Guinea",
      },
      {
        id: "Estonia",
        label: "Estonia",
        value: "Estonia",
      },
      {
        id: "Ethiopia",
        label: "Ethiopia",
        value: "Ethiopia",
      },
      {
        id: "Finland",
        label: "Finland",
        value: "Finland",
      },
      {
        id: "France",
        label: "France",
        value: "France",
      },
      {
        id: "Galician",
        label: "Galician",
        value: "Galician",
      },
      {
        id: "Georgia",
        label: "Georgia",
        value: "Georgia",
      },
      {
        id: "Germany",
        label: "Germany",
        value: "Germany",
      },
      {
        id: "Greece",
        label: "Greece",
        value: "Greece",
      },
      {
        id: "Guatemala",
        label: "Guatemala",
        value: "Guatemala",
      },
      {
        id: "Honduras",
        label: "Honduras",
        value: "Honduras",
      },
      {
        id: "Hong Kong SAR",
        label: "Hong Kong SAR",
        value: "Hong Kong SAR",
      },
      {
        id: "Hungary",
        label: "Hungary",
        value: "Hungary",
      },
      {
        id: "Iceland",
        label: "Iceland",
        value: "Iceland",
      },
      {
        id: "India",
        label: "India",
        value: "India",
      },
      {
        id: "Indonesia",
        label: "Indonesia",
        value: "Indonesia",
      },
      {
        id: "Iran",
        label: "Iran",
        value: "Iran",
      },
      {
        id: "Iraq",
        label: "Iraq",
        value: "Iraq",
      },
      {
        id: "Ireland",
        label: "Ireland",
        value: "Ireland",
      },
      {
        id: "Israel",
        label: "Israel",
        value: "Israel",
      },
      {
        id: "Italy",
        label: "Italy",
        value: "Italy",
      },
      {
        id: "Japan",
        label: "Japan",
        value: "Japan",
      },
      {
        id: "Jordan",
        label: "Jordan",
        value: "Jordan",
      },
      {
        id: "Kazakhstan",
        label: "Kazakhstan",
        value: "Kazakhstan",
      },
      {
        id: "Kenya",
        label: "Kenya",
        value: "Kenya",
      },
      {
        id: "Korea",
        label: "Korea",
        value: "Korea",
      },
      {
        id: "Kuwait",
        label: "Kuwait",
        value: "Kuwait",
      },
      {
        id: "Laos",
        label: "Laos",
        value: "Laos",
      },
      {
        id: "Latin, Azerbaijan",
        label: "Latin, Azerbaijan",
        value: "Latin, Azerbaijan",
      },
      {
        id: "Latin, Indonesia",
        label: "Latin, Indonesia",
        value: "Latin, Indonesia",
      },
      {
        id: "Latin, Uzbekistan",
        label: "Latin, Uzbekistan",
        value: "Latin, Uzbekistan",
      },
      {
        id: "Latvia",
        label: "Latvia",
        value: "Latvia",
      },
      {
        id: "Lebanon",
        label: "Lebanon",
        value: "Lebanon",
      },
      {
        id: "Libya",
        label: "Libya",
        value: "Libya",
      },
      {
        id: "Lithuania",
        label: "Lithuania",
        value: "Lithuania",
      },
      {
        id: "Malaysia",
        label: "Malaysia",
        value: "Malaysia",
      },
      {
        id: "Malta",
        label: "Malta",
        value: "Malta",
      },
      {
        id: "Mandarin, Simplified",
        label: "Mandarin, Simplified",
        value: "Mandarin, Simplified",
      },
      {
        id: "Mexico",
        label: "Mexico",
        value: "Mexico",
      },
      {
        id: "Morocco",
        label: "Morocco",
        value: "Morocco",
      },
      {
        id: "Myanmar",
        label: "Myanmar",
        value: "Myanmar",
      },
      {
        id: "Nepal",
        label: "Nepal",
        value: "Nepal",
      },
      {
        id: "Netherlands",
        label: "Netherlands",
        value: "Netherlands",
      },
      {
        id: "New Zealand",
        label: "New Zealand",
        value: "New Zealand",
      },
      {
        id: "Nicaragua",
        label: "Nicaragua",
        value: "Nicaragua",
      },
      {
        id: "Nigeria",
        label: "Nigeria",
        value: "Nigeria",
      },
      {
        id: "North Macedonia",
        label: "North Macedonia",
        value: "North Macedonia",
      },
      {
        id: "Norway",
        label: "Norway",
        value: "Norway",
      },
      {
        id: "Oman",
        label: "Oman",
        value: "Oman",
      },
      {
        id: "Pakistan",
        label: "Pakistan",
        value: "Pakistan",
      },
      {
        id: "Panama",
        label: "Panama",
        value: "Panama",
      },
      {
        id: "Paraguay",
        label: "Paraguay",
        value: "Paraguay",
      },
      {
        id: "Peru",
        label: "Peru",
        value: "Peru",
      },
      {
        id: "Philippines",
        label: "Philippines",
        value: "Philippines",
      },
      {
        id: "Poland",
        label: "Poland",
        value: "Poland",
      },
      {
        id: "Portugal",
        label: "Portugal",
        value: "Portugal",
      },
      {
        id: "Puerto Rico",
        label: "Puerto Rico",
        value: "Puerto Rico",
      },
      {
        id: "Qatar",
        label: "Qatar",
        value: "Qatar",
      },
      {
        id: "Romania",
        label: "Romania",
        value: "Romania",
      },
      {
        id: "Russia",
        label: "Russia",
        value: "Russia",
      },
      {
        id: "Saudi Arabia",
        label: "Saudi Arabia",
        value: "Saudi Arabia",
      },
      {
        id: "Singapore",
        label: "Singapore",
        value: "Singapore",
      },
      {
        id: "Slovakia",
        label: "Slovakia",
        value: "Slovakia",
      },
      {
        id: "Slovenia",
        label: "Slovenia",
        value: "Slovenia",
      },
      {
        id: "Somalia",
        label: "Somalia",
        value: "Somalia",
      },
      {
        id: "South Africa",
        label: "South Africa",
        value: "South Africa",
      },
      {
        id: "Spain",
        label: "Spain",
        value: "Spain",
      },
      {
        id: "Sri Lanka",
        label: "Sri Lanka",
        value: "Sri Lanka",
      },
      {
        id: "Sweden",
        label: "Sweden",
        value: "Sweden",
      },
      {
        id: "Switzerland",
        label: "Switzerland",
        value: "Switzerland",
      },
      {
        id: "Syria",
        label: "Syria",
        value: "Syria",
      },
      {
        id: "Taiwanese Mandarin, Traditional",
        label: "Taiwanese Mandarin, Traditional",
        value: "Taiwanese Mandarin, Traditional",
      },
      {
        id: "Tanzania",
        label: "Tanzania",
        value: "Tanzania",
      },
      {
        id: "Thailand",
        label: "Thailand",
        value: "Thailand",
      },
      {
        id: "Tunisia",
        label: "Tunisia",
        value: "Tunisia",
      },
      {
        id: "Turkey",
        label: "Turkey",
        value: "Turkey",
      },
      {
        id: "Ukraine",
        label: "Ukraine",
        value: "Ukraine",
      },
      {
        id: "United Arab Emirates",
        label: "United Arab Emirates",
        value: "United Arab Emirates",
      },
      {
        id: "United Kingdom",
        label: "United Kingdom",
        value: "United Kingdom",
      },
      {
        id: "United States",
        label: "United States",
        value: "United States",
      },
      {
        id: "Uruguay",
        label: "Uruguay",
        value: "Uruguay",
      },
      {
        id: "Venezuela",
        label: "Venezuela",
        value: "Venezuela",
      },
      {
        id: "Vietnam",
        label: "Vietnam",
        value: "Vietnam",
      },
      {
        id: "Yemen",
        label: "Yemen",
        value: "Yemen",
      },
    ],
  },
  languages: {
    label: "Language",
    id: "languages",
    data: languages,
  },
  timeZones: {
    label: "Time preference",
    id: "timeZone",
    data: [
      {
        id: "1",
        value: "newYork",
        label: "New York",
      },
      {
        id: "2",
        value: "kyiv",
        label: "Kyiv",
      },
      {
        id: "3",
        value: "paris",
        label: "Paris",
      },
    ],
  },
};
