import { StoreType } from "../../types/store";
import { AiHumansProjectModules, Project } from "../../types/humansProject";
import {
  CLEAR_CURRENT_HUMANS_PROJECT,
  CREATE_HUMANS_PROJECT,
  CREATE_HUMANS_PROJECT_SERVER,
  GENERATE_HUMAN_VOICES_SERVER,
  GET_HUMANS_PROJECT_SERVER,
  Status,
  UPDATE_HUMANS_PROJECT_LOADING,
  UPDATE_HUMANS_PROJECT_SERVER,
} from "../actions/humansProjectActions";

export interface humansProjectStateType {
  [AiHumansProjectModules.project]: {
    project: Project | null;
    isLoading: boolean;
    selectedTemplate: Project | null;
  };
  [AiHumansProjectModules.generateAudio]: {
    audio: string[];
    isGeneratingAudio: boolean;
  };
  [AiHumansProjectModules.generateVideo]: {
    isLoading: boolean;
  };
  [AiHumansProjectModules.loadingPage]: {
    isLoading: boolean;
  };
}

const humansProjectInitialState: humansProjectStateType = {
  [AiHumansProjectModules.project]: {
    project: null,
    isLoading: false,
    selectedTemplate: null,
  },
  [AiHumansProjectModules.generateAudio]: {
    audio: [],
    isGeneratingAudio: false,
  },
  [AiHumansProjectModules.generateVideo]: {
    isLoading: false,
  },
  [AiHumansProjectModules.loadingPage]: {
    isLoading: false,
  },
};

const humansProjectReducer = (state = humansProjectInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_HUMANS_PROJECT_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as AiHumansProjectModules], isLoading } };
    }

    case CREATE_HUMANS_PROJECT: {
      const {
        projectTypeId,
        title,
        slides,
        isHorizontal,
        resolution,
        projectCategoryId,
        projectId,
        backgroundSoundTrackId,
        canvasWidth,
        enableCaption,
      } = action.payload;

      return {
        ...state,
        [AiHumansProjectModules.project]: {
          ...state[AiHumansProjectModules.project],
          project: {
            ...state[AiHumansProjectModules.project].project,
            slides,
            isHorizontal,
            title,
            projectTypeId,
            resolution,
            projectCategoryId,
            projectId,
            backgroundSoundTrackId,
            canvasWidth,
            enableCaption,
          },
        },
      };
    }
    case GENERATE_HUMAN_VOICES_SERVER: {
      return {
        ...state,
        [AiHumansProjectModules.generateAudio]: {
          ...state[AiHumansProjectModules.generateAudio],
          isGeneratingAudio: true,
          audio: action.payload,
        },
      };
    }
    case `${CREATE_HUMANS_PROJECT_SERVER}_SUCCESS`:
    case `${UPDATE_HUMANS_PROJECT_SERVER}_SUCCESS`:
    case `${GET_HUMANS_PROJECT_SERVER}_SUCCESS`: {
      const {
        slides,
        title,
        projectId,
        resolution,
        projectCategoryId,
        projectTypeId,
        status,
        isHorizontal,
        backgroundSoundTrackId,
        canvasWidth,
        output,
        completeDateTime,
        totalDuration,
        enableCaption,
      } = action.payload.data.data;

      const { isTemplate, isTemplateTab } = action.meta.previousAction.payload;

      if (isTemplateTab) {
        return {
          ...state,
          [AiHumansProjectModules.project]: {
            ...state[AiHumansProjectModules.project],
            selectedTemplate: {
              ...state[AiHumansProjectModules.project].project,
              totalDuration,
              slides,
              title,
              projectId: isTemplate ? 0 : projectId,
              resolution,
              projectTypeId,
              projectCategoryId,
              status: isTemplate ? Status.isDraft : status,
              isHorizontal,
              backgroundSoundTrackId,
              canvasWidth,
              output,
              completeDateTime,
            },
          },
        };
      }

      return {
        ...state,
        [AiHumansProjectModules.project]: {
          ...state[AiHumansProjectModules.project],
          project: {
            ...state[AiHumansProjectModules.project].project,
            totalDuration,
            slides,
            title,
            projectId: isTemplate ? 0 : projectId,
            resolution,
            projectTypeId,
            projectCategoryId,
            status: isTemplate ? Status.isDraft : status,
            isHorizontal,
            backgroundSoundTrackId,
            canvasWidth,
            output,
            completeDateTime,
            enableCaption,
          },
        },
      };
    }
    case CLEAR_CURRENT_HUMANS_PROJECT: {
      return {
        ...state,
        [AiHumansProjectModules.project]: {
          ...state[AiHumansProjectModules.project],
          project: null,
          isLoading: false,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getHumansProject = (state: StoreType) => state.humansProject[AiHumansProjectModules.project].project;
export const getHumansProjectLoading = (state: StoreType) =>
  state.humansProject[AiHumansProjectModules.project].isLoading;
export const getHumansTemplate = (state: StoreType) =>
  state.humansProject[AiHumansProjectModules.project].selectedTemplate;

export const getHumansProjectAudio = (state: StoreType) =>
  state.humansProject[AiHumansProjectModules.generateAudio].audio;

export const getGenerateVideoLoading = (state: StoreType) =>
  state.humansProject[AiHumansProjectModules.generateVideo].isLoading;

export const getLoadingPage = (state: StoreType) => state.humansProject[AiHumansProjectModules.loadingPage].isLoading;

export default humansProjectReducer;
