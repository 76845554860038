export const Gallery = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.43994 25.3467L3.41328 25.3733C3.05328 24.5867 2.82661 23.6933 2.73328 22.7067C2.82661 23.68 3.07994 24.56 3.43994 25.3467Z"
      fill="#0063B4"
    />
    <path
      d="M12 13.84C13.7526 13.84 15.1733 12.4192 15.1733 10.6667C15.1733 8.91408 13.7526 7.49333 12 7.49333C10.2474 7.49333 8.82666 8.91408 8.82666 10.6667C8.82666 12.4192 10.2474 13.84 12 13.84Z"
      fill="#0063B4"
    />
    <path
      d="M21.5866 2.66667H10.4133C5.55996 2.66667 2.66663 5.56 2.66663 10.4133V21.5867C2.66663 23.04 2.91996 24.3067 3.41329 25.3733C4.55996 27.9067 7.01329 29.3333 10.4133 29.3333H21.5866C26.44 29.3333 29.3333 26.44 29.3333 21.5867V18.5333V10.4133C29.3333 5.56 26.44 2.66667 21.5866 2.66667ZM27.16 16.6667C26.12 15.7733 24.44 15.7733 23.4 16.6667L17.8533 21.4267C16.8133 22.32 15.1333 22.32 14.0933 21.4267L13.64 21.0533C12.6933 20.2267 11.1866 20.1467 10.12 20.8667L5.13329 24.2133C4.83996 23.4667 4.66663 22.6 4.66663 21.5867V10.4133C4.66663 6.65334 6.65329 4.66667 10.4133 4.66667H21.5866C25.3466 4.66667 27.3333 6.65334 27.3333 10.4133V16.8133L27.16 16.6667Z"
      fill="#0063B4"
    />
  </svg>
);
