import { useEffect, useMemo, useState } from "react";
import { ContentState, EditorState, SelectionState, convertToRaw } from "draft-js";
import { sum } from "../lib/editorSum";
import { IActor } from "../types/actor";
import { generateEditorStyle } from "../lib/generateEditorStyle";
import { sidebarBoxes } from "../mocks/sidebarBoxes";
// import { Paragraphs, Zone } from "../types/project";
import { Scene } from "../types/scene";
import { HumansParagraph } from "../types/humansProject";
import { Zone } from "../types/project";
import { SentryErrors, sentryErrors } from "../lib/sentry";

export const useHumanParagraph = (actors: IActor[], scenes: Scene[], activeSceneId: number) => {
  const [paragraphs, setParagraphsState] = useState<HumansParagraph[]>([]);

  const [lastSel, setLastSel] = useState<SelectionState>(SelectionState.createEmpty(""));
  const [editorContent, setEditorContent] = useState<EditorState[]>([
    EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromText(""))),
  ]);
  const [featureActive, setFeatureActive] = useState<number>(0);

  const [styleMap, setStyleMap] = useState<Draft.DraftComponent.Base.DraftStyleMap | undefined>({
    pause: {
      backgroundColor: "#e859ff",
      borderRadius: "20px",
      width: "20px",
      color: "#fff",
      //eslint-disable-next-line
      //@ts-ignore
      userModify: "read-only",
      padding: "2px 5px",
      fontSize: "11px",
      position: "relative",
      top: "-2px",
    },
    isActive_active: {
      borderRadius: "5px",
      border: "1px solid #009AF7",
    },
    isLoading_loading: {
      backgroundImage: "url('/images/editor/loading.gif')",
      backgroundRepeat: "no-repeat, no-repeat",
      paddingRight: "40px",
      backgroundSize: "23px 90%",
      backgroundPosition: "100% 2px, 0 2px",
    },
    isGenerated_generated: {
      borderRadius: "5px",
      border: "1px dashed #009AF7",
    },
  });

  const activeSceneIndex = useMemo(
    () => scenes.findIndex((scene) => scene.id === activeSceneId),
    [scenes, activeSceneId],
  );

  const paragraphActive = activeSceneIndex !== -1 ? activeSceneIndex : 0;

  const paragraphActor = paragraphs[paragraphActive as number]?.actor;
  const paragraphActorsList = paragraphs[paragraphActive as number]?.actorsList;

  useEffect(() => {
    if (!styleMap) return;
    for (let i = 0; i < editorContent.length; i++) {
      const content = convertToRaw(editorContent[i].getCurrentContent());
      content.blocks.forEach((block) => {
        const element = document.querySelector(`span[data-offset-key="${block.key}-0-0"]`) as HTMLElement;
        let paddings: number[] = [];
        let bgSizes: string[] = [];

        const url = block.inlineStyleRanges
          .reduce((acc: any, elem: any) => {
            if (styleMap[elem.style] && styleMap[elem.style]?.backgroundImage) {
              const img = new Image();
              //eslint-disable-next-line
              //@ts-ignore
              img.src = styleMap[elem.style].backgroundImage.split("'")[1];
              img.width *= 28 / img.height;

              acc.push(styleMap[elem.style].backgroundImage);
              //eslint-disable-next-line
              //@ts-ignore
              paddings.push(parseInt(styleMap[elem.style].paddingRight.replace("px", "")));
              //eslint-disable-next-line
              //@ts-ignore
              bgSizes.push(styleMap[elem.style].backgroundSize);
            }

            return acc;
          }, [])
          .join(",");

        if (element && paddings.length) {
          block.inlineStyleRanges.forEach((inline) => {
            if (styleMap[inline.style]) {
              Object.entries(styleMap[inline.style]).forEach(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (style) => (element.style[style[0]] = style[1]),
              );
            }
          });
          element.style.backgroundImage = url;
          element.style.paddingRight = `${paddings.reduce((acc, pd) => (acc += pd), 0)}px`;
          element.style.backgroundPosition = paddings
            .map((pd, index, inital) => `calc(100% - ${sum(inital.slice(0, index))}px) 2px`, 0)
            .join(",");
          element.style.backgroundSize = bgSizes.join(",");
        }
      });
    }
  }, [editorContent]);

  useEffect(() => {
    let generatedStyledMap = {};

    const styles = sidebarBoxes.reduce((acc, box, index) => {
      box.values.forEach((value) => {
        acc[`${box.title}_${value.text}`] = generateEditorStyle(value.text, box.background);
      });

      return acc;
    }, {} as any);

    generatedStyledMap = {
      ...styleMap,
      ...styles,
    };

    setStyleMap(generatedStyledMap);
  }, []);

  const setParagraphs = (nextParagraphs: HumansParagraph[]) => {
    const orderedParagraphs = nextParagraphs.sort((a, b) => a.order - b.order);
    setParagraphsState(orderedParagraphs);
  };

  const handleEditorContent = (index: number) => (newEditorContent: any) => {
    setEditorContent(
      editorContent.map((content: any, order: number) => (order === index ? newEditorContent : content)),
    );
  };

  const handleTextParagraph = (index: number, sceneId: number) => (payload: Zone[]) => {
    let newParagraphs = [...paragraphs];

    const currentActor = paragraphs[index]?.actor;
    const currentActorId = paragraphs[index]?.actorId;

    const defaultActor = actors?.[0];
    const defaultActorId = actors?.[0]?.actorId;

    if (!currentActor || !currentActorId) {
      sentryErrors({
        errorType: SentryErrors.CREATED_PARAGRAPH_WITH_NON_EXISTING_ACTOR,
        details: {
          zones: JSON.stringify(payload || []),
        },
      });
    }

    newParagraphs[index] = {
      order: index,
      actor: currentActor || defaultActor,
      actorId: currentActorId || defaultActorId,
      data: payload,
      sceneId,
    };

    setParagraphs(newParagraphs);
  };

  const calcParagraphsLength = (paragraphs: HumansParagraph[]): number[] =>
    paragraphs.map((paragraph) => paragraph.data.reduce((acc, data) => (acc += data.text.length), 0));

  const getSelectedZone = () => {
    const activeKey = lastSel.getAnchorKey();

    if (
      (paragraphActive || paragraphActive === 0) &&
      activeKey &&
      editorContent[paragraphActive] &&
      paragraphs[paragraphActive]
    ) {
      const state = editorContent[paragraphActive];
      let content = convertToRaw(state?.getCurrentContent());

      const index = content.blocks.findIndex((block) => block.key === activeKey) / 2;

      const zone = paragraphs[paragraphActive].data[index];
      if (!zone) return;
      return { ...zone, actorId: paragraphs[paragraphActive].actorId };
    }
  };

  const duplicateParagraph = (index: number) => {
    const current = paragraphs.find((_, order) => order === index);

    if (!current) return;
    const prevParagraph = { ...current };
    const newParagraphs = paragraphs
      .map((paragraph) => {
        if (paragraph.order > current.order) {
          paragraph.order++;
        }
        return paragraph;
      })
      .concat({ ...prevParagraph, order: current.order + 1, sceneId: 0 });

    setParagraphs([...newParagraphs]);
    setEditorContent([...editorContent.slice(0, index + 1), ...editorContent.slice(index, editorContent.length)]);
  };

  const selectedZone = getSelectedZone();

  return {
    paragraphs,
    setParagraphs,
    paragraphActor,
    paragraphActorsList,
    handleTextParagraph,
    handleEditorContent,
    featureActive,
    setFeatureActive,
    lastSel,
    setLastSel,
    editorContent,
    setEditorContent,
    styleMap,
    calcParagraphsLength,
    selectedZone,
    paragraphActive,
    duplicateParagraph,
  };
};
