import styled from "styled-components";
import { useState } from "react";

import Uploaded from "./Uploaded";
import RecentSection from "./RecentSection";
import SideBarLayout from "./layout/SideBarLayout";
import Button from "../../../components/Button/Button";
import { ArrowLeft, ArrowRight } from "../../../components/Icons/Icons";

const AIImagesLeftSidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);

  return (
    <SideBarLayout width={240} active={sidebarVisible} directionLeft={true}>
      <OuterWrapper>
        <Uploaded />
        <RecentSection />
        <SidebarVisibleButtonWrapper>
          <Button
            icon={sidebarVisible ? <ArrowLeft /> : <ArrowRight />}
            onClick={() => setSidebarVisible((prev) => !prev)}
          />
        </SidebarVisibleButtonWrapper>
      </OuterWrapper>
    </SideBarLayout>
  );
};

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f0f0f3;
  position: relative;
`;

const SidebarVisibleButtonWrapper = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -20px;
  box-shadow: none;
  visibility: visible;

  &.open button {
    border-radius: 12px 0px 0px 12px;
    width: 20px;
  }

  button {
    box-shadow: none;
    opacity: 1;
    height: 32px;
    width: 20px;
    border-radius: 0px 12px 12px 0px;
  }

  svg {
    margin: 0px 2px 0px 0px;
  }
`;

export default AIImagesLeftSidebar;
