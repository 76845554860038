import React from "react";
import Modal from "../Modal/Modal";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Button, { ButtonThemes } from "../Button/Button";
import { useSelector } from "react-redux";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { updateSubscriptionServer } from "../../redux/actions/plansActions";

interface Props {
  open: boolean;
  onClose: () => void;
}

const SelectSubscriptionPopup = ({ onClose, open }: Props) => {
  const prefilled = useSelector(getPrefilled);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!prefilled?.existingUserLicenseId || !prefilled?.newPlanId) return;
    dispatch(
      updateSubscriptionServer({
        existingUserLicenseId: prefilled.existingUserLicenseId,
        newPlanId: prefilled.newPlanId,
      }),
    );
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth={630} maxHeight={260}>
      <Wrapper>
        <TopWrapper>
          <Title>Confirm subscription update</Title>
          <Subtitle>This plan will automatically replace your current subscription.</Subtitle>
        </TopWrapper>
        <Actions>
          <ContinueButton buttonTheme={ButtonThemes.Secondary} text="Back" onClick={onClose} />
          <Button text="Confirm" onClick={handleSubmit} />
        </Actions>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 542px;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 24px;

  & button {
    width: calc(50% - 12px);
  }
`;

const ContinueButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.cardShadow};
  height: 48px;
  border-radius: 12px;
`;

export default SelectSubscriptionPopup;
