import { Filter } from "./filter";
import { Paragraphs } from "./project";

export interface IActor {
  actorId: number;
  actorTypeId?: number;
  userId?: number;
  flagPath?: string;
  name: string;
  photo: string;
  styles?: string;
  isNew?: boolean;
  voiceType?: string;
  categoryType?: string;
  voiceName?: string;
  brief?: string;
  language?: string;
  languageName?: string;
  isFeMale?: boolean;
  audioSampleLink?: string;
  status?: number;
  insertDateTime?: Date;
  isBookmarked?: boolean;
  inputAudio?: string;
  badges?: string[];
}

export interface IActorsList {
  pageNumber: number;
  pageSize: number;
  firstPage: string;
  lastPage: string;
  totalPages: number;
  totalRecords: number;
  nextPage?: any;
  previousPage?: any;
  data: IActor[];
}

export enum ActorModules {
  "actorList" = "actorList",
  "downloadAudio" = "downloadAudio",
  "downloadHistory" = "downloadHistory",
  "faceswapActor" = "faceswapActor",
  "clonedVoices" = "clonedVoices",
  "voiceTranslate" = "voiceTranslate",
  "voiceoverHistory" = "voiceoverHistory",
  "audioToTranslatedText" = "audioToTranslatedText",
  "speechTranslation" = "speechTranslation",
  "speech2Speech" = "speech2Speech",
}

export type RequestForActorServer = {
  keyword?: string;
  pageNumber?: number;
  filters?: Filter;
  isFeMale?: boolean | null;
  bookmarked?: boolean | null;
  popular?: boolean | null;
  history?: boolean | null;
  isAIHuman?: boolean;
  speechCategory?: number;
};

export type CharacterPrefilled = {
  actors: IActor[];
  active: number;
  isSingleActorSelectionOnly?: boolean;
  paragraphs: Paragraphs[];
  currentParagraphActor: IActor;
  currentParagraphActorsList: IActor[];
  speechCategory?: number;
  setActorActive: (actors: IActor[]) => void;
  requestForActorServer?: RequestForActorServer;
  previousFill?: CharacterPrefilled;
  isNoFilters?: boolean;
};

export interface VoiceoverHistoryItem {
  category: string;
  insertDateTime: string;
  output: string;
  title: string;
}
