export const ImageIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 13.333C11.7707 13.333 12.6667 12.437 12.6667 11.333C12.6667 10.229 11.7707 9.33301 10.6667 9.33301C9.56269 9.33301 8.66669 10.229 8.66669 11.333C8.66669 12.437 9.56269 13.333 10.6667 13.333ZM24 25.3331H8.74799L18.088 17.5397C18.416 17.2611 19.0107 17.2624 19.332 17.5384L25.3333 22.6584V23.9997C25.3333 24.7357 24.736 25.3331 24 25.3331ZM8.00002 6.66699H24C24.736 6.66699 25.3334 7.26433 25.3334 8.00033V19.1523L21.0627 15.5097C19.7427 14.387 17.6774 14.387 16.3694 15.5017L6.66669 23.5977V8.00033C6.66669 7.26433 7.26402 6.66699 8.00002 6.66699ZM24 4H8C5.79467 4 4 5.79467 4 8V24C4 26.2053 5.79467 28 8 28H24C26.2053 28 28 26.2053 28 24V8C28 5.79467 26.2053 4 24 4Z"
      fill="white"
    />
    <mask id="mask0_999_9674" maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 13.333C11.7707 13.333 12.6667 12.437 12.6667 11.333C12.6667 10.229 11.7707 9.33301 10.6667 9.33301C9.56269 9.33301 8.66669 10.229 8.66669 11.333C8.66669 12.437 9.56269 13.333 10.6667 13.333ZM24 25.3331H8.74799L18.088 17.5397C18.416 17.2611 19.0107 17.2624 19.332 17.5384L25.3333 22.6584V23.9997C25.3333 24.7357 24.736 25.3331 24 25.3331ZM8.00002 6.66699H24C24.736 6.66699 25.3334 7.26433 25.3334 8.00033V19.1523L21.0627 15.5097C19.7427 14.387 17.6774 14.387 16.3694 15.5017L6.66669 23.5977V8.00033C6.66669 7.26433 7.26402 6.66699 8.00002 6.66699ZM24 4H8C5.79467 4 4 5.79467 4 8V24C4 26.2053 5.79467 28 8 28H24C26.2053 28 28 26.2053 28 24V8C28 5.79467 26.2053 4 24 4Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_999_9674)"></g>
  </svg>
);
