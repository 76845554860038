import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import Tabs from "../Tabs";
import TitleWithAction from "../TitleWithAction";
import BinIcon from "../../../../components/Icons/BinIcon";
import Button, { ButtonThemes } from "../../../../components/Button/Button";
import CircularProgress from "../../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../../components/Button/IconButton";
import { VideoEditorContext } from "../../../../App";
import { ImportIcon, PlusIcon } from "../../../../components/Icons/Icons";
import { MediaType, useMedia } from "../../../../hooks/useMedia";
import { MediaProps, mediaTabs } from "../../../../mocks/humans";
import { getProfile } from "../../../../redux/reducers/profileReducer";

const ACCEPTED_FORMATS = "image/png, image/jpg, image/jpeg";

const MediaTab = () => {
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;

  const [loadedMedia, setLoadedMedia] = useState<MediaType[]>([]);
  const [loadedAIImage, setLoadedAIImage] = useState<any[]>([]);
  const [activeMedia, setActiveMedia] = useState(mediaTabs[0]);

  const {
    uploadMediaLoading,
    media,
    mediaLoading,
    mediaHasMore,
    fetchMore,
    images,
    imagesLoading,
    imagesHasMore,
    handleUpload,
    handleUserAssetDelete,
  } = useMedia({ activeMedia });
  const { handleAddMedia } = useContext(VideoEditorContext);

  const handleImageLoaded = (index: number) => {
    const updatedMedia = [...loadedMedia];
    updatedMedia[index].loaded = true;
    setLoadedMedia(updatedMedia);
  };

  const handleAIImageLoaded = (index: number) => {
    const updatedAIImage = [...loadedAIImage];
    updatedAIImage[index].loaded = true;
    setLoadedAIImage(updatedAIImage);
  };

  const handleActiveMedia = (media: string) => setActiveMedia(media as MediaProps);

  useEffect(() => {
    const newMedia = media?.map((m) => {
      return {
        ...m,
        loaded: false,
      };
    });
    setLoadedMedia(newMedia);
  }, [media]);

  useEffect(() => {
    const newAIImages = images?.map((m) => {
      return {
        ...m,
        loaded: false,
      };
    });
    setLoadedAIImage(newAIImages);
  }, [images]);

  const content = {
    [MediaProps.MEDIA]: (
      <MediaWrapper id="scrollableMediaDiv">
        {!isGuest && (
          <UploadWrapper>
            <input type="file" id="media" name="media" accept={ACCEPTED_FORMATS} onChange={handleUpload} />
            {uploadMediaLoading ? <CircularProgress color="#009af7" /> : <PlusIcon />}
          </UploadWrapper>
        )}
        {mediaLoading && !loadedMedia?.length ? (
          <CircularProgressWrapper style={{ height: !isGuest ? "calc(100% - 73px)" : "100%" }}>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : (
          <InfiniteScroll
            next={fetchMore}
            hasMore={mediaHasMore}
            loader={
              mediaLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#009af7" />
                </CircularProgressWrapper>
              ) : null
            }
            dataLength={media?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableMediaDiv"
          >
            {media?.map((media: any, index: number) => (
              <ImageWrapper key={index}>
                {!media.loaded && <Placeholder />}
                <img
                  onClick={() => handleAddMedia(media.path)}
                  src={media.path}
                  onLoad={() => handleImageLoaded(index)}
                  alt=""
                />
                {!!media.userId && (
                  <IconButton
                    icon={<BinIcon />}
                    iconButtonTheme={IconButtonThemes.Secondary}
                    onClick={(e: any) => handleUserAssetDelete(e, media.userAssetID)}
                  />
                )}
              </ImageWrapper>
            ))}
          </InfiniteScroll>
        )}
      </MediaWrapper>
    ),
    [MediaProps.AIIMAGES]: (
      <MediaWrapper id="scrollableMediaDiv">
        {imagesLoading && !loadedAIImage?.length ? (
          <CircularProgressWrapper>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : (
          <InfiniteScroll
            next={fetchMore}
            hasMore={imagesHasMore}
            loader={
              imagesLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#009af7" />
                </CircularProgressWrapper>
              ) : null
            }
            dataLength={images?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableMediaDiv"
          >
            {images.map((image: any, index: number) => (
              <ImageWrapper key={image.visualHistoryID}>
                {!image.loaded && <Placeholder />}
                <img
                  onClick={() => handleAddMedia(image.path)}
                  src={image.path}
                  onLoad={() => handleAIImageLoaded(index)}
                />
              </ImageWrapper>
            ))}
          </InfiniteScroll>
        )}
      </MediaWrapper>
    ),
  };

  return (
    <Wrapper>
      <TitleWithAction title="Media">
        <Tabs data={mediaTabs} active={activeMedia} handleActive={handleActiveMedia} />
        <Content>
          {!isGuest ? (
            content[activeMedia]
          ) : (
            <SignUpLink to="/signup">
              <span>Sign Up</span>
            </SignUpLink>
          )}
        </Content>
      </TitleWithAction>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  padding: 9px;
  border-radius: 20px;
  min-height: 150px;
  overflow: hidden auto;
  width: 272px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MediaWrapper = styled.div`
  position: relative;
  overflow: hidden auto;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: row wrap;
  row-gap: 8px;
  column-gap: 8px;
  margin-bottom: 24px;
  align-content: start;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 57px;
  height: 57px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }

  &:hover > button {
    opacity: 1;
    transition: 0.2s;
  }

  button {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    box-shadow: none;
    opacity: 0;

    svg {
      width: 12px;
      height: 12px;

      path {
        fill: red;
      }
    }
  }
`;

const UploadWrapper = styled.div`
  position: relative;
  width: 57px;
  height: 57px;
  border-radius: 16px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${({ theme }) => theme.activeMenu};

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Placeholder = styled.div`
  width: 57px;
  height: 57px;
  border-radius: 12px;
  background: linear-gradient(-90deg, #e9e9e9, #c4c3c3);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const SignUpLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-decoration: underline;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.activeMenu};
  }
`;

export default MediaTab;
