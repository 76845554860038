import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { formatDistance } from "date-fns";
import { useNavigate } from "react-router-dom";

import MenuItem from "../ActionMenu/MenuItem";
import ActionMenu from "../ActionMenu/ActionMenu";
import Tooltip from "../../modules/MyStudio/components/Tooltip";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import ProgressBarCircle from "../../modules/MyStudio/components/ProgressBarCircle";
import {
  ClockIcon,
  DownloadIcon,
  DuplicateIcon,
  EditIcon,
  EditIconProjectCardLarge,
  OpenIcon,
  OptionsIcon,
  TrashIcon,
} from "../Icons/Icons";
import { Project } from "../../types/project";
import { downloadFile } from "../../lib/downloadFile";
import { AIHumansStatus } from "../../modules/MyStudio";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { renameProjectServer } from "../../redux/actions/projectAction";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";
import { getProjectServer } from "../../redux/actions/humansProjectActions";
import { pages } from "../../lib/routeUtils";

interface ProjectCardProps {
  project: Project;
  title: string;
  projectTime?: string;
  image?: string;
  status?: AIHumansStatus;
  output?: string;
  active?: boolean;
  setActiveProject: any;
  handleActiveProject: (e: any) => void;
}

const ProjectCardLarge = ({
  project,
  title,
  projectTime,
  image,
  status,
  output,
  active,
  setActiveProject,
  handleActiveProject,
}: ProjectCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState<string>(title);
  const [menuOpen, setMenuOpen] = useState<number>();
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  const handleChange = (e: any) => setProjectName(e.target.value);

  const handleKeyPress = (e: any, type?: "blur") => {
    if (e.code === "Enter" || type) {
      if (!project) throw Error("Project entity is missing while renaming");
      if (project && projectName !== project?.title) {
        dispatch(
          renameProjectServer({
            projectId: project?.projectId,
            projectTypeId: project?.projectTypeId,
            newTitle: projectName,
            oldTitle: project?.title,
          }),
        );
      }
      setActiveProject(null);
    }
  };

  useEffect(() => {
    setProjectName(project.title);
  }, [project.title]);

  const renameProject = (e: any) => {
    handleActiveProject(e);
    handleCloseMenu(e);
  };

  const handleDuplicate = (e: any) => {
    navigate(pages.aiHumans() + `?template=${project?.projectId}`);

    handleCloseMenu(e);
  };

  const handleDeleteClick = (e: any) => {
    if (!project.projectId) {
      throw Error("Project entity is missing while deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: project.projectId,
      title: "Are you sure you want to delete project " + project.title,
      description: "The project will be removed and the action cannot be undone",
      typeId: 2,
      type: DeleteType.Project,
      status,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleOpenMenu = (e: any, id: number) => {
    e.preventDefault();

    setMenuOpen(id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();

    setMenuOpen(-1);
  };

  const menu = [
    // {
    //   icon: <EditIconProjectCardLarge />,
    // },
    {
      icon: <OptionsIcon />,
      options: [
        {
          name: "Duplicate",
          icon: <DuplicateIcon />,
          hidden: status === AIHumansStatus.InProgress,
        },
        {
          name: "Download",
          icon: <DownloadIcon />,
          hidden: status !== AIHumansStatus.Completed,
        },
        {
          name: "Open this video",
          icon: <OpenIcon />,
          hidden: status !== AIHumansStatus.Completed,
        },
        {
          name: "Rename",
          icon: <EditIcon />,
        },
        {
          name: "Delete",
          icon: <TrashIcon />,
        },
      ],
    },
  ];

  const lastUpdateTime = new Date(projectTime + "Z");

  const timeElapsed = (Number(currentTime) - Number(lastUpdateTime)) / 1000;
  const percentComplete = (timeElapsed / Number(project.estimatedTime)) * 100;

  useEffect(() => {
    if (status === AIHumansStatus.InProgress) {
      const intervalId = setInterval(() => setCurrentTime(new Date()), 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [status]);

  return (
    <Wrapper className="project-card-large">
      <ImgWrapper>
        {image ? <Image src={image} alt={title} /> : <EmptyImage />}
        {status !== AIHumansStatus.Draft && (
          <ProgressBarCircleWrapper>
            <ProgressBarCircle percent={percentComplete} status={status} />
          </ProgressBarCircleWrapper>
        )}
        <ActionWrapper>
          {menu.map((m, order) => (
            <ActionMenu
              key={order}
              position={{
                x: "left",
                y: "bottom",
              }}
              open={menuOpen === order}
              menuStyle={
                status !== AIHumansStatus.Completed
                  ? { top: "-30px", right: "25px", padding: "8px 12px" }
                  : { top: "-60px", right: "25px", padding: "8px 12px" }
              }
              handleClose={handleCloseMenu}
              trigger={
                <IconButton
                  iconButtonTheme={IconButtonThemes.Primary}
                  icon={m.icon}
                  onClick={order === 0 ? (e: any) => handleOpenMenu(e, order) : () => null}
                />
              }
            >
              {m.options && (
                <MenuWrapper>
                  {m.options.map((option, index) =>
                    option.hidden ? (
                      <></>
                    ) : (
                      <MenuItem
                        key={index}
                        icon={option.icon}
                        onClick={(e: any) => {
                          e.preventDefault();
                          option.name === "Duplicate" && handleDuplicate(e);
                          option.name === "Download" && downloadFile(e, output || "", status);
                          option.name === "Open this video" && navigate(`/video/${project.projectId}`);
                          option.name === "Rename" && renameProject(e);
                          option.name === "Delete" && handleDeleteClick(e);
                        }}
                      >
                        {option.name}
                      </MenuItem>
                    ),
                  )}
                </MenuWrapper>
              )}
            </ActionMenu>
          ))}
        </ActionWrapper>
      </ImgWrapper>
      <Fade>
        {status === AIHumansStatus.Failure && (
          <StatusIconWrapper>
            <Tooltip />
          </StatusIconWrapper>
        )}
        <TitleWrapper>
          {!active ? (
            <>
              <Title>{title}</Title>
            </>
          ) : (
            <Textfield
              autoFocus
              variant={TextfieldVariant.project}
              value={projectName}
              placeholder="Enter project name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onBlur={(e) => handleKeyPress(e, "blur")}
            />
          )}
        </TitleWrapper>
        {projectTime && (
          <ProjectTime>
            <ProjectTimeText>Edited {formatDistance(new Date(projectTime + "Z"), new Date())} ago</ProjectTimeText>
            <ClockIcon />
          </ProjectTime>
        )}
      </Fade>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 236px;
  height: 196px;
  padding: 14px;
  // overflow: hidden;
  background: #f0f0f3;
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  position: relative;
  background: ${({ theme }) => theme.characterActionsBackground};
`;

const ImgWrapper = styled.div`
  height: 120px;
  border-radius: 12px;
  position: relative;
`;

const ProgressBarCircleWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const Fade = styled.div`
  height: auto;

  display: flex;
  flex-direction: column;
  padding: 8px 0px 14px;
  gap: 1px;

  @media (max-width: 550px) {
    align-items: center;
    row-gap: 2px;
    height: 60px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 4px;

  button {
    svg > path {
      fill: ${({ theme }) => theme.icon};
    }
  }
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryText};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  max-width: 200px;

  @media (max-width: 550px) {
    line-height: 14px;
  }
`;

const ProjectTime = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 2px;
  align-items: center;
  justify-content: start;
  opacity: 0.6;
  svg {
    path {
      fill: ${({ theme }) => theme.primaryText};
    }
  }
`;

const ProjectTimeText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryText};
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 12px;
`;

const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.disableIcon}33;
  border-radius: 3px;
`;

const StatusIconWrapper = styled.div`
  display: flex;
  margin-right: 4px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 12px;
  right: 12px;

  button {
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
`;

const MenuWrapper = styled.div`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export default ProjectCardLarge;
