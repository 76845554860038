import styled from "styled-components";

interface Props {
  data: { title: string; value: string }[];
  active: string;
  handleActive: (status: string) => void;
}

const HomeSectionTabs = ({ data, active, handleActive }: Props) => {
  return (
    <Wrapper>
      {data.map((item) => (
        <Content
          key={item.value}
          className={item.value === active ? "active" : "not-active"}
          onClick={() => handleActive(item.value)}
        >
          {item.title}
        </Content>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 7px 12px;
  gap: 16px;
  cursor: pointer;

  .active {
    background-color: #0063b4;
    color: white;
    border: 1px solid #0063b4;
  }

  @media (max-width: 700px) {
  }
`;

const Content = styled.div`
  box-shadow: ${({ theme }) => theme.homeSectionTabsShadow};
  background: ${({ theme }) => theme.homeContentCardBg};
  padding: 7px 12px 7px 12px;
  border-radius: 8px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => theme.homeSectionTabs};
`;

export default HomeSectionTabs;
