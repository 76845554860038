export const SearchFilterIcon = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16667 13.8333C7.70667 13.8333 7.33334 13.46 7.33334 13C7.33334 12.54 7.70667 12.1667 8.16667 12.1667C8.62667 12.1667 9 12.54 9 13C9 13.46 8.62667 13.8333 8.16667 13.8333ZM16.5 12.1667H10.5125C10.1675 11.1992 9.25167 10.5 8.16667 10.5C7.08167 10.5 6.16584 11.1992 5.82084 12.1667H1.50001C1.03917 12.1667 0.666672 12.5392 0.666672 13C0.666672 13.4608 1.03917 13.8333 1.50001 13.8333H5.82084C6.16584 14.8008 7.08167 15.5 8.16667 15.5C9.25167 15.5 10.1675 14.8008 10.5125 13.8333H16.5C16.9608 13.8333 17.3333 13.4608 17.3333 13C17.3333 12.5392 16.9608 12.1667 16.5 12.1667ZM14.8333 8.83333C14.3733 8.83333 14 8.46 14 8C14 7.54 14.3733 7.16667 14.8333 7.16667C15.2933 7.16667 15.6667 7.54 15.6667 8C15.6667 8.46 15.2933 8.83333 14.8333 8.83333ZM14.8333 5.5C13.7483 5.5 12.8325 6.19917 12.4875 7.16667H1.50001C1.03917 7.16667 0.666672 7.53917 0.666672 8C0.666672 8.46083 1.03917 8.83333 1.50001 8.83333H12.4875C12.8325 9.80083 13.7483 10.5 14.8333 10.5C16.2117 10.5 17.3333 9.37833 17.3333 8C17.3333 6.62167 16.2117 5.5 14.8333 5.5ZM4.83334 2.16667C5.29334 2.16667 5.66667 2.54 5.66667 3C5.66667 3.46 5.29334 3.83333 4.83334 3.83333C4.37334 3.83333 4.00001 3.46 4.00001 3C4.00001 2.54 4.37334 2.16667 4.83334 2.16667ZM1.5 3.83333H2.4875C2.8325 4.80083 3.74834 5.5 4.83334 5.5C5.91834 5.5 6.83417 4.80083 7.17917 3.83333H16.5C16.9608 3.83333 17.3333 3.46083 17.3333 3C17.3333 2.53917 16.9608 2.16667 16.5 2.16667H7.17917C6.83417 1.19917 5.91834 0.5 4.83334 0.5C3.74834 0.5 2.8325 1.19917 2.4875 2.16667H1.5C1.03917 2.16667 0.666672 2.53917 0.666672 3C0.666672 3.46083 1.03917 3.83333 1.5 3.83333Z"
      fill="url(#paint0_linear_493_11024)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_493_11024"
        x1="4.25642"
        y1="2.46154"
        x2="12.8694"
        y2="14.7658"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
