export const ChatIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25893 16.5935C3.14074 14.7085 2.74914 12.4802 3.15767 10.3269C3.56619 8.17359 4.74674 6.2435 6.47762 4.89903C8.20851 3.55455 10.3707 2.8882 12.5581 3.02508C14.7455 3.16196 16.8077 4.09267 18.3575 5.64244C19.9073 7.1922 20.838 9.25442 20.9749 11.4418C21.1118 13.6293 20.4454 15.7914 19.101 17.5223C17.7565 19.2532 15.8264 20.4338 13.6731 20.8423C11.5198 21.2508 9.29148 20.8593 7.40647 19.7411L7.40649 19.741L4.29806 20.6291C4.16946 20.6658 4.03337 20.6675 3.90389 20.634C3.77441 20.6004 3.65627 20.5329 3.56169 20.4383C3.46711 20.3437 3.39955 20.2256 3.366 20.0961C3.33245 19.9666 3.33413 19.8305 3.37088 19.7019L4.259 16.5935L4.25893 16.5935Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
      fill="white"
    />
    <path
      d="M7.5 13.125C8.12132 13.125 8.625 12.6213 8.625 12C8.625 11.3787 8.12132 10.875 7.5 10.875C6.87868 10.875 6.375 11.3787 6.375 12C6.375 12.6213 6.87868 13.125 7.5 13.125Z"
      fill="white"
    />
    <path
      d="M16.5 13.125C17.1213 13.125 17.625 12.6213 17.625 12C17.625 11.3787 17.1213 10.875 16.5 10.875C15.8787 10.875 15.375 11.3787 15.375 12C15.375 12.6213 15.8787 13.125 16.5 13.125Z"
      fill="white"
    />
  </svg>
);

export const ChatAPIIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2957)">
      <path
        d="M4.1556 19.1564L4.15591 19.1566C4.22537 19.185 4.3006 19.2 4.375 19.2C4.52439 19.2 4.67161 19.1415 4.78117 19.0319L8.36309 15.45H17.125C18.2697 15.45 19.2 14.5197 19.2 13.375V2.875C19.2 1.73029 18.2697 0.8 17.125 0.8H2.875C1.73029 0.8 0.8 1.73029 0.8 2.875V13.375C0.8 14.5197 1.73029 15.45 2.875 15.45H3.8V18.625C3.8 18.8572 3.94011 19.0677 4.1556 19.1564ZM1.95 2.875C1.95 2.36521 2.36521 1.95 2.875 1.95H17.125C17.6348 1.95 18.05 2.36521 18.05 2.875V13.375C18.05 13.8848 17.6348 14.3 17.125 14.3H8.125C7.97182 14.3 7.82581 14.3611 7.71808 14.4688L4.95 17.2369V14.875C4.95 14.5575 4.69246 14.3 4.375 14.3H2.875C2.36521 14.3 1.95 13.8848 1.95 13.375V2.875Z"
        fill="url(#paint0_linear_1_2957)"
        stroke="url(#paint1_linear_1_2957)"
        strokeWidth="0.4"
      />
      <path
        d="M8.75004 10.7461C8.52004 10.7461 8.33337 10.5594 8.33337 10.3294V7.41276C8.33337 6.95276 7.96004 6.57943 7.50004 6.57943C7.04004 6.57943 6.66671 6.95276 6.66671 7.41276V10.3294C6.66671 10.5594 6.48004 10.7461 6.25004 10.7461C6.02004 10.7461 5.83337 10.5594 5.83337 10.3294V7.41276C5.83337 6.49359 6.58087 5.74609 7.50004 5.74609C8.41921 5.74609 9.16671 6.49359 9.16671 7.41276V10.3294C9.16671 10.5594 8.98004 10.7461 8.75004 10.7461Z"
        fill="url(#paint2_linear_1_2957)"
      />
      <path
        d="M8.75004 9.07943H6.25004C6.02004 9.07943 5.83337 8.89276 5.83337 8.66276C5.83337 8.43276 6.02004 8.24609 6.25004 8.24609H8.75004C8.98004 8.24609 9.16671 8.43276 9.16671 8.66276C9.16671 8.89276 8.98004 9.07943 8.75004 9.07943Z"
        fill="url(#paint3_linear_1_2957)"
      />
      <path
        d="M12.0834 10.7461H11.25C11.02 10.7461 10.8334 10.5594 10.8334 10.3294V6.16276C10.8334 5.93276 11.02 5.74609 11.25 5.74609H12.0834C13.2325 5.74609 14.1667 6.68026 14.1667 7.82943V8.66276C14.1667 9.81193 13.2325 10.7461 12.0834 10.7461ZM11.6667 9.91276H12.0834C12.7725 9.91276 13.3334 9.35193 13.3334 8.66276V7.82943C13.3334 7.14026 12.7725 6.57943 12.0834 6.57943H11.6667V9.91276Z"
        fill="url(#paint4_linear_1_2957)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_2957"
        x1="4.87692"
        y1="3.35385"
        x2="15.5385"
        y2="17.0615"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_2957"
        x1="4.87692"
        y1="3.35385"
        x2="15.5385"
        y2="17.0615"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_2957"
        x1="6.55132"
        y1="6.39994"
        x2="9.57093"
        y2="8.98818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_2957"
        x1="6.55132"
        y1="8.35507"
        x2="6.74215"
        y2="9.33648"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_2957"
        x1="11.5513"
        y1="6.39994"
        x2="14.5709"
        y2="8.98818"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <clipPath id="clip0_1_2957">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
