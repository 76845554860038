import { toast } from "react-toastify";
import { put, select, takeEvery } from "redux-saga/effects";

import {
  ADD_VIDEO_TRANSLATE_PROJECT_SERVER,
  FACE_DETECT_SERVER,
  GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER,
  GET_VIDEO_TRANSLATE_PROJECTS_SERVER,
  GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER,
  GET_YOUTUBE_URL_SERVER,
  getVideoTranslateProjectsServer,
  updateVideoServiceLoading,
  updateVideoTranslateIsLoading,
} from "../actions/videoTranslationActions";
import { UploadVideoTabs, VideoTranslationModules } from "../../types/videoTranslation";
import Navigate from "../../lib/Navigate";
import { pages } from "../../lib/routeUtils";
import { getProfile } from "../reducers/profileReducer";
import { Popups, updatePopup } from "../actions/popupsActions";
import { SignUpPopupTypes } from "../../types/signUpPopupTypes";

const videoTranslationSaga = [
  takeEvery(GET_VIDEO_TRANSLATE_PROJECTS_SERVER, handleGetVideoTranslateProjects),
  takeEvery(`${GET_VIDEO_TRANSLATE_PROJECTS_SERVER}_SUCCESS`, handleGetVideoTranslateProjectsSuccess),
  takeEvery(`${GET_VIDEO_TRANSLATE_PROJECTS_SERVER}_FAIL`, handleGetVideoTranslateProjectsFail),

  takeEvery(GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER, handleGetVideoTranslateProjectById),
  takeEvery(`${GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER}_SUCCESS`, handleGetVideoTranslateProjectByIdSuccess),
  takeEvery(`${GET_VIDEO_TRANSLATE_PROJECT_BY_ID_SERVER}_FAIL`, handleGetVideoTranslateProjectByIdFail),

  takeEvery(ADD_VIDEO_TRANSLATE_PROJECT_SERVER, handleGenerateVideoTranslateProject),
  takeEvery(`${ADD_VIDEO_TRANSLATE_PROJECT_SERVER}_SUCCESS`, handleGenerateVideoTranslateProjectSuccess),
  takeEvery(`${ADD_VIDEO_TRANSLATE_PROJECT_SERVER}_FAIL`, handleGenerateVideoTranslateProjectFail),

  takeEvery(FACE_DETECT_SERVER, handleFaceDetect),
  takeEvery(`${FACE_DETECT_SERVER}_SUCCESS`, handleFaceDetectSuccess),
  takeEvery(`${FACE_DETECT_SERVER}_FAIL`, handleFaceDetectFail),

  takeEvery(GET_YOUTUBE_URL_SERVER, handleGetYoutubeUrlServer),
  takeEvery(`${GET_YOUTUBE_URL_SERVER}_SUCCESS`, handleGetYoutubeUrlServerSuccess),
  takeEvery(`${GET_YOUTUBE_URL_SERVER}_FAIL`, handleGetYoutubeUrlServerFail),

  takeEvery(GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER, handleGenerateVideoToTranslateTextServer),
  takeEvery(`${GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER}_SUCCESS`, handleGenerateVideoToTranslateTextServerSuccess),
  takeEvery(`${GENERATE_VIDEO_TO_TRANSLATE_TEXT_SERVER}_FAIL`, handleGenerateVideoToTranslateTextServerFail),
];

function* handleGetVideoTranslateProjects(action: any) {
  const { isLoading } = action.payload;

  if (!isLoading) {
    return;
  }

  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.allVideos, isLoading: true }));
}

function* handleGetVideoTranslateProjectsSuccess() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.allVideos, isLoading: false }));
}

function* handleGetVideoTranslateProjectsFail() {
  yield toast.error("An error occurred while retrieving recently generated videos");

  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.allVideos, isLoading: false }));
}

function* handleGetVideoTranslateProjectById() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.singleVideo, isLoading: true }));
}

function* handleGetVideoTranslateProjectByIdSuccess() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.singleVideo, isLoading: false }));
}

function* handleGetVideoTranslateProjectByIdFail() {
  yield toast.error("An error occurred while retrieving recently generated video");

  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.singleVideo, isLoading: false }));
}

function* handleGenerateVideoTranslateProject() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideo, isLoading: true }));
}

function* handleGenerateVideoTranslateProjectSuccess(action: any) {
  const { videoTranslateProjectId } = action.payload.data.data;

  const { roleId } = getProfile(yield select());
  const isGuest = roleId === 3;

  const prefilled: SignUpPopupTypes = {
    type: "VideoTranslation",
    link: { projectName: "video-translation", projectId: videoTranslateProjectId },
    isClick: true,
    projectSave: () => Navigate.push("/signup"),
  };

  if (isGuest) yield put(updatePopup({ popup: Popups.signUpPopup, status: true, prefilled }));

  yield put(getVideoTranslateProjectsServer({ isLoading: true }));
  yield Navigate.push(`${pages.videoTranslation()}/${videoTranslateProjectId}`);

  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideo, isLoading: false }));
}

function* handleGenerateVideoTranslateProjectFail() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideo, isLoading: false }));
}

function* handleFaceDetect() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.faceDetect, isLoading: true }));
}

function* handleFaceDetectSuccess() {
  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.faceDetect, isLoading: false }));
}

function* handleFaceDetectFail() {
  yield toast.error("An error occurred while detecting a face");

  yield put(updateVideoTranslateIsLoading({ module: VideoTranslationModules.faceDetect, isLoading: false }));
}

function* handleGetYoutubeUrlServer() {
  yield put(updateVideoServiceLoading({ videoServiceType: UploadVideoTabs.YouTube, isLoading: true }));
}

function* handleGetYoutubeUrlServerSuccess(action: any) {
  yield put({ type: `${FACE_DETECT_SERVER}_SUCCESS`, payload: { data: { data: action.payload.data.data } } });
  yield put(updateVideoServiceLoading({ videoServiceType: UploadVideoTabs.YouTube, isLoading: false }));
}

function* handleGetYoutubeUrlServerFail() {
  yield toast.error("Couldn't find video");

  yield put(updateVideoServiceLoading({ videoServiceType: UploadVideoTabs.YouTube, isLoading: false }));
}

function* handleGenerateVideoToTranslateTextServer() {
  yield put(
    updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideoToTranslateText, isLoading: true }),
  );
}

function* handleGenerateVideoToTranslateTextServerSuccess() {
  yield put(
    updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideoToTranslateText, isLoading: false }),
  );
}

function* handleGenerateVideoToTranslateTextServerFail() {
  yield toast.error("Couldn't find translated text");

  yield put(
    updateVideoTranslateIsLoading({ module: VideoTranslationModules.generateVideoToTranslateText, isLoading: false }),
  );
}

export default videoTranslationSaga;
