import styled from "styled-components";
import Tooltip from "../Tooltip/Tooltip";
import { useState } from "react";

interface Props {
  title: string;
  background: string;
  values: {
    id?: string | number;
    text: string;
    label?: string;
  }[];
  modifier?: string;
  step: number;
  value: number;
  handleChange: (e: any) => void;
  disabled?: boolean;
}

const ProfileActorSidebarBox = ({
  title,
  background,
  values,
  modifier,
  step,
  value,
  handleChange,
  disabled,
}: Props) => {
  const [hoverIndex, setHoverIndex] = useState(0);
  const percent = 100 / values.length;

  const throttledHandleProgressBarHover = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const xCord = e.clientX;
    const left = target.getBoundingClientRect().left;
    const cordDiff = Math.abs(left - xCord);
    const width = target.clientWidth;
    const index = Math.floor(((cordDiff / width) * 100) / percent);

    setHoverIndex(index);
  };

  return (
    <Wrapper>
      <Content>
        <Heading disabled={disabled}>
          <span>{title}</span>
          {!disabled && <div style={{ background }} />}
        </Heading>
        <ProgressBar>
          <ProgressBarLine
            value={value}
            type="range"
            min="0"
            max="100"
            step={step}
            width={value}
            onChange={handleChange}
            onMouseMove={throttledHandleProgressBarHover}
            disabled={disabled}
          />
          {!disabled && (
            <Tooltip
              text={`${values[Math.min(hoverIndex, values.length - 1)].text}${modifier ? modifier : ""}`}
              position="top"
              style={{
                boxShadow: "none",
                borderRadius: "4px",
                padding: "4px 8px 6px",
                left: `calc(${hoverIndex * percent}% + 6px)`,
                bottom: "15px",
                pointerEvents: "none",
              }}
              arrow
            />
          )}
        </ProgressBar>
        <Footer>
          {values.map((value) => (
            <span key={value.id}>{value.label ? value.label + (modifier ? modifier : "") : ""}</span>
          ))}
        </Footer>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.secondaryBackground};
  // box-shadow: ${({ theme }) => theme.secondaryCardShadow};
  border: 1px solid #35354a;
  border-radius: 12px;
  width: 232px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
`;

const Heading = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${({ disabled }) => (disabled ? "#4B4B51" : "#999BAF")};
    text-transform: capitalize;
  }

  div {
    width: 24px;
    height: 16px;
    border-radius: 28px;
  }
`;

const ProgressBar = styled.div`
  height: 16px;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.secondaryBackground};
  box-shadow: ${({ theme }) => theme.secondaryInputShadow};
  border-radius: 60px;
  display: flex;
  align-items: center;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const ProgressBarLine = styled.input<{ width: number }>`
  background: ${({ theme }) => theme.secondaryBackground};
  box-shadow: ${({ theme }) => theme.secondaryCardShadow};
  border-radius: 80px;
  width: 100%;
  height: 16px;
  -webkit-appearance: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};

  ::-webkit-slider-runnable-track {
    background: ${({ theme, width, disabled }) =>
      `linear-gradient(to right, ${
        disabled ? theme.progressBarLineDisabled : theme.activeMenu
      } ${width}%, transparent 0)`};
    border-radius: 80px;
    height: 11px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: ${({ theme, disabled }) => (disabled ? theme.progressBarLineDisabled : theme.white)};
    width: 12px;
    height: 12px;
    padding: 2px;
    margin-top: -1px;
    border: 3px solid ${({ theme }) => theme.activeMenu};
    border-radius: 50%;
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.secondaryText};
    opacity: 0.4;
  }
`;

export default ProfileActorSidebarBox;
