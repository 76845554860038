import styled from "styled-components";
import { useSelector } from "react-redux";

import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import SumoUserPlans from "./SumoUserPlans";
import { getProfile } from "../../redux/reducers/profileReducer";
import DefaultUserPlans from "./DefaultUserPlans";

const MyPlan = () => {
  const profile = useSelector(getProfile);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (profile.isJvzooUser) navigate(pages.main());
  // }, [profile]);

  return (
    <Wrapper>
      <SidebarLayout>{profile?.isAppSumoUser ? <SumoUserPlans /> : <DefaultUserPlans />}</SidebarLayout>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;

  font-family: Mulish !important;
`;

export default withPrivateRoute(MyPlan);
