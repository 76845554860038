import React from "react";
import styled from "styled-components";
import { PopupButton } from "@typeform/embed-react";

const EnterprisePackage = () => {
  return (
    <Wrapper>
      <Text>
        Are you looking for <br />
        an enterprise package?
      </Text>
      <PopupButton id="RyEAi5YQ">
        <ButtonText>Let’s talk</ButtonText>
      </PopupButton>
    </Wrapper>
  );
};

const ButtonText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: -0.41px;
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.primaryText};
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  max-width: 1014px;
  width: 100%;
  margin: 72px auto 140px;
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;

  button {
    position: relative;
    width: 160px;
    height: 44px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    padding: 2px;
    z-index: 15;
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%) !important;

    &:hover {
      opacity: 0.8;
    }

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      background: ${({ theme }) => theme.primaryBackground};
      z-index: -1;
      border-radius: 10px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    padding: 24px 16px;
    margin: 40px auto 100px;
  }
`;

export default EnterprisePackage;
