import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { Shape } from "../../../../../types/scene";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

interface Props {
  currentObject: Shape;
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  setBorderWidth: React.Dispatch<React.SetStateAction<number>>;
}

const BorderColor = ({ currentObject, handleSceneObjectChange, setBorderWidth }: Props) => {
  const [value, setValue] = useState("");
  const color = useDebounce({ value, delay: 100 });
  const ref = useRef(null);

  const handleColoChange = (event: any) => {
    if (!currentObject) return;
    setValue(event.target.value);
    const borderWidth = currentObject.border.borderWidth;
    if (borderWidth)
      handleSceneObjectChange("border", { ...currentObject.border, borderColor: event.target.value }, currentObject.id);
    else {
      handleSceneObjectChange(
        "border",
        { ...currentObject.border, borderColor: event.target.value, borderWidth: 1 },
        currentObject.id,
      );
      setBorderWidth(1);
    }
  };

  const handleClick = () => {
    if (!ref.current) return;
    (ref.current as any).click();
  };

  useEffect(() => {
    if (!currentObject) return setValue("");
    setValue(currentObject.border.borderColor as string);
  }, [currentObject]);

  return (
    <div>
      <ButtonWrapper>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={
            <ColorInput
              ref={ref}
              borderColor={color || "#000000"}
              type="color"
              onChange={handleColoChange}
              value={color}
            />
          }
          onClick={handleClick}
        />
        <Tooltip
          text={"Border color"}
          position="bottom"
          style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
          reverseColor
          arrow
        />
      </ButtonWrapper>
    </div>
  );
};

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
    }
  }
`;

const ColorInput = styled.input<{ borderColor: string }>`
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  -webkit-appearance: none;
  &::-webkit-color-swatch {
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 50%;
    background: ${({ theme }) => theme.primaryBackground} !important;
    padding: 0;
  }

  &::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
  }
`;

export default BorderColor;
