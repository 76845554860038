import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import RadioButton from "../RadioButton/RadioButton";
import Button, { ButtonThemes } from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { useDispatch } from "react-redux";
import { getHumatarPreviewServer } from "../../redux/actions/humatarActions";
import { getHumatarPreview, getHumatarPreviewLoading } from "../../redux/reducers/humatarReducer";
import { HumatarPreviewPrefilled } from "../../types/humatar";

interface Props {
  open: boolean;
  onClose: () => void;
}

const AIHumansPreviewPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const { human, setSelectedActor, isPopup = true }: HumatarPreviewPrefilled = useSelector(getPrefilled);
  const humatarPreview = useSelector(getHumatarPreview);
  const newHumatarPreview = humatarPreview.filter(({ aiHumanActorId }) => aiHumanActorId === human.aiHumanActorId);
  const humatarPreviewLoading = useSelector(getHumatarPreviewLoading);
  const [selectedLang, setSelectedLang] = useState<string>("");
  const [videoLoaded, setVideoLoaded] = useState(false);

  const activeVideo = newHumatarPreview.find(({ language }) => language === selectedLang)?.preview;

  const handleSelectLanguage = (language: string) => {
    setSelectedLang(language);
  };

  const handleVideoLoaded = () => setVideoLoaded(true);

  const onSave = () => {
    setSelectedActor(human);
    onClose();
  };

  useEffect(() => {
    if (!newHumatarPreview.length) dispatch(getHumatarPreviewServer(human.aiHumanActorId));
  }, []);

  useEffect(() => {
    if (newHumatarPreview.length) setSelectedLang(newHumatarPreview?.[0].language);
  }, [humatarPreview]);

  useEffect(() => {
    if (activeVideo) setVideoLoaded(false);
  }, [activeVideo]);

  return (
    <Wrapper>
      <Modal className="ai-humans-preview" open={open} onClose={onClose} maxWidth={1178} maxHeight={590}>
        <Content>
          <ActionsWrapper>
            <Button
              className="btn-back"
              buttonTheme={ButtonThemes.Secondary}
              icon={<img src="/images/arrow-left.svg" />}
              text="Back"
              style={{ justifyContent: "start" }}
              onClick={onClose}
            />
            <Title>{human.name}</Title>
            <Description>
              Confident and authoritative tone, delivering information with clarity and assurance.
            </Description>
            <LanguagesWrapper isLoading={humatarPreviewLoading}>
              {humatarPreviewLoading ? (
                <CircularProgress color="#009af7" />
              ) : (
                newHumatarPreview.map(({ language }, index) => (
                  <Item key={language} onClick={() => handleSelectLanguage(language)}>
                    {/* <img src={flag} alt={name} /> */}
                    <span>{language}</span>
                    <RadioButton
                      id={index}
                      label={language}
                      value={selectedLang}
                      checked={language === selectedLang}
                      onChange={() => handleSelectLanguage(language)}
                    />
                  </Item>
                ))
              )}
            </LanguagesWrapper>
            {isPopup && <Button text="Choose actor" onClick={onSave} />}
            <Button
              buttonTheme={ButtonThemes.Secondary}
              style={{ height: "20px", marginTop: "14px " }}
              text="Cancel"
              onClick={onClose}
            />
          </ActionsWrapper>
          <VideoWrapper>
            {humatarPreviewLoading ? (
              <CirclularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CirclularProgressWrapper>
            ) : (
              <>
                {!videoLoaded && <Placeholder />}
                <video src={activeVideo} autoPlay loop onLoadedData={handleVideoLoaded} />
              </>
            )}
          </VideoWrapper>
        </Content>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .modal-wrapper {
    z-index: 102;
  }

  .modal-darkener {
    z-index: 101;
  }

  .modal-close-icon {
    top: 20px;
    right: 20px;
  }
`;

const Content = styled.div`
  display: flex;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 379px;
  padding-left: 40px;
  padding-right: 24px;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.primaryText};
  margin-top: 14px;
  margin-bottom: 4px;
`;

const Description = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #6f7074;
`;

const LanguagesWrapper = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 32px;

  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 272px;
      max-height: 272px;
    `}
`;

const Item = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  img {
    width: 16px;
    height: 12px;
    border-radius: 2px;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryText};
    margin-left: 8px;
  }

  div {
    margin-left: auto;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 759px;
  max-width: 759px;
  height: 510px;
  max-height: 510px;
  border-radius: 16px;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const CirclularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #e9e9e9, #c4c3c3);
  background-size: 400% 400%;
  animation: gradient 3s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default AIHumansPreviewPopup;
