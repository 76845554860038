import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getCurrentPageDownloadHistory,
  getDownloadHistory,
  getDownloadHistoryLoading,
  getDownloadHistoryTotalPages,
  getDownloadHistoryTotalRecords,
} from "../redux/reducers/actorReducer";
import {
  getCurrentPageHistoryImages,
  getHistoryImages,
  getHistoryImagesLoading,
  getHistoryImagesTotalPages,
  getHistoryImagesTotalRecords,
} from "../redux/reducers/imagesReducer";
import {
  getCurrentPageProjects,
  getProjectList,
  getProjectListLoading,
  getProjectListTotalPages,
  getProjectListTotalRecords,
} from "../redux/reducers/projectReducer";
import { getVideoTranslateProjectsServer } from "../redux/actions/videoTranslationActions";
import { clearProjectList, getProjectListServer, setPageProjects } from "../redux/actions/projectAction";
import { clearHistoryImages, getAllHistoryImagesServer, setPageHistoryImages } from "../redux/actions/imagesActions";
import { clearDownloadHistory, getDownloadHistoryServer, setPageDownloadHistory } from "../redux/actions/actorActions";
import { getVideoTranslateProjects, getVideoTranslateProjectsLoading } from "../redux/reducers/videoTranslationReducer";

const DEFAULT_LENGTH_PER_PAGE = 8;

interface UpdateData {
  type: string;
  pageNumber?: number;
  pageSize?: number;
}

export enum HistoryMode {
  aiVideo = "ai-humans",
  aiVoices = "actors",
  aiImages = "ai-images",
  videoTranslate = "videoTranslate",
}

export const historyModeTabs = [
  {
    value: HistoryMode.aiVideo,
    title: "AI Humans",
  },
  {
    value: HistoryMode.aiVoices,
    title: "AI Voices",
  },
  {
    value: HistoryMode.aiImages,
    title: "AI Images",
  },
  {
    value: HistoryMode.videoTranslate,
    title: "Video Translate",
  },
];

export const useHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const historyData = useSelector(getDownloadHistory);
  const historyDataLoading = useSelector(getDownloadHistoryLoading);
  const historyDataTotalPages = useSelector(getDownloadHistoryTotalPages);
  const historyDataTotalRecords = useSelector(getDownloadHistoryTotalRecords);
  const historyDataCurrentPage = useSelector(getCurrentPageDownloadHistory);

  const historyImages = useSelector(getHistoryImages);
  const historyImagesLoading = useSelector(getHistoryImagesLoading);
  const historyImagesTotalPages = useSelector(getHistoryImagesTotalPages);
  const historyImagesTotalRecords = useSelector(getHistoryImagesTotalRecords);
  const historyImagesCurrentPage = useSelector(getCurrentPageHistoryImages);

  const projectData = useSelector(getProjectList);
  const projectListLoading = useSelector(getProjectListLoading);
  const projectListTotalPages = useSelector(getProjectListTotalPages);
  const projectListTotalRecords = useSelector(getProjectListTotalRecords);
  const projectListCurrentPage = useSelector(getCurrentPageProjects);

  const videoTranslateData = useSelector(getVideoTranslateProjects);
  const videoTranslateDataLoading = useSelector(getVideoTranslateProjectsLoading);
  const [videoTranslateCurrentPage, setVideoTranslateCurrentPage] = useState(1);

  const [selectedHistoryLenghtPerPage, setSelectedHistoryLenghtPerPage] = useState(DEFAULT_LENGTH_PER_PAGE);
  const [activeHistoryMode, setActiveHistoryMode] = useState(historyModeTabs[0].value);

  const selectData = {
    [HistoryMode.aiVoices]: {
      data: historyData.map((item) => ({
        img: "",
        name: item.voiceOverText,
        projectTime: item.insertDateTime,
        id: item.voiceOverRequestID.toString(),
        output: item.mp3Path,
      })),
      isLoading: historyDataLoading,
      totalPages: historyDataTotalPages,
      currentPage: historyDataCurrentPage,
      totalRecords: historyDataTotalRecords,
      menuOptions: ["Download"],
    },
    [HistoryMode.aiImages]: {
      data: historyImages.map((item) => ({
        img: item.path,
        name: item.fileName,
        projectTime: item.insertDateTime,
        id: item.visualHistoryID.toString(),
        output: item.path,
      })),
      isLoading: historyImagesLoading,
      totalPages: historyImagesTotalPages,
      currentPage: historyImagesCurrentPage,
      totalRecords: historyImagesTotalRecords,
      menuOptions: ["Download"],
    },
    [HistoryMode.aiVideo]: {
      data: projectData.map((item) => ({
        img: item.coverImage,
        name: item.title,
        projectTime: item.insertDateTime,
        id: (item.projectId as number).toString(),
        output: item.output,
      })),
      isLoading: projectListLoading,
      totalPages: projectListTotalPages,
      currentPage: projectListCurrentPage,
      totalRecords: projectListTotalRecords,
      menuOptions: ["Download", "Rename", "Delete"],
    },
    [HistoryMode.videoTranslate]: {
      data: videoTranslateData
        .slice(
          videoTranslateCurrentPage * selectedHistoryLenghtPerPage - selectedHistoryLenghtPerPage,
          videoTranslateCurrentPage * selectedHistoryLenghtPerPage,
        )
        .map((item: any) => ({
          img: "",
          name: item.title,
          projectTime: item.insertDateTime,
          id: item.videoTranslateProjectId.toString(),
          output: item.outputUrl,
          guid: item.guid,
        })),
      isLoading: videoTranslateDataLoading,
      totalPages: Math.ceil(videoTranslateData?.length / selectedHistoryLenghtPerPage),
      currentPage: videoTranslateCurrentPage,
      totalRecords: videoTranslateData.length,
      menuOptions: ["Download", "Embed on website"],
    },
  };

  const handleUpdateData = ({ type, pageNumber, pageSize }: UpdateData) => {
    switch (type) {
      case HistoryMode.aiVoices:
        dispatch(clearDownloadHistory());

        if (pageNumber) {
          dispatch(setPageDownloadHistory({ pageNumber }));
        }

        dispatch(
          getDownloadHistoryServer({ pageNumber: pageNumber || 1, pageSize: pageSize || 8, isHistoryPage: true }),
        );
        return;
      case HistoryMode.aiImages:
        dispatch(clearHistoryImages());

        if (pageNumber) {
          dispatch(setPageHistoryImages({ pageNumber }));
        }

        dispatch(
          getAllHistoryImagesServer({ pageNumber: pageNumber || 1, pageSize: pageSize || 8, isHistoryPage: true }),
        );
        return;
      case HistoryMode.aiVideo:
        dispatch(clearProjectList());

        if (pageNumber) {
          dispatch(setPageProjects({ pageNumber }));
        }

        dispatch(
          getProjectListServer({
            pageNumber: pageNumber || 1,
            pageSize: pageSize || 8,
            projectTypeId: 2,
            status: 3,
            isHistoryPage: true,
          }),
        );
        return;
      case HistoryMode.videoTranslate:
        if (pageNumber) {
          setVideoTranslateCurrentPage(pageNumber);
        }

        dispatch(getVideoTranslateProjectsServer({}));
        return;
      default:
        return;
    }
  };

  const handlePaginate = (pageNumber: number) => {
    handleUpdateData({ type: activeHistoryMode, pageNumber, pageSize: selectedHistoryLenghtPerPage });
  };

  const handleSelectHistoryLengthPerPage = (lengthPerPage: number) => {
    setSelectedHistoryLenghtPerPage(lengthPerPage);
    handleUpdateData({ type: activeHistoryMode, pageNumber: 1, pageSize: lengthPerPage });
  };

  const handleActiveHistoryMode = (tab: HistoryMode) => {
    setActiveHistoryMode(tab);

    const params = new URLSearchParams(location.search);
    params.set("filter", tab);

    navigate(`?${params.toString()}`);
  };

  useEffect(() => {
    handleUpdateData({ type: activeHistoryMode });
  }, [activeHistoryMode]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("filter") || "";
    setActiveHistoryMode(filterValue as HistoryMode);

    if (!filterValue) {
      navigate(`?filter=ai-humans`);
    }
  }, [location]);

  const { data, isLoading, totalPages, totalRecords, currentPage, menuOptions } =
    selectData[activeHistoryMode as HistoryMode] || {};

  return {
    data: data || [],
    isLoading,
    totalPages,
    currentPage,
    menuOptions,
    totalRecords,
    activeHistoryMode,
    selectedHistoryLenghtPerPage,
    handlePaginate,
    handleActiveHistoryMode,
    handleSelectHistoryLengthPerPage,
  };
};
