import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal/Modal";
import Button, { ButtonThemes } from "../Button/Button";
import CircularProgress from "../Icons/CircularProgress";
import Textfield, { TextfieldVariant } from "../Textfield/Textfield";
import { Video } from "../../types/video";
import { InfoIcon } from "../Icons/Icons";
import { CustomAvatar } from "./SelectTypeCustomAvatarPopup";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { uploadHumatarServer } from "../../redux/actions/humatarActions";
import { getCustomHumatar, uploadingHumatarLoading } from "../../redux/reducers/humatarReducer";
import Tooltip from "../Tooltip/Tooltip";
import { talkingImageTooltip } from "../../mocks/humans";
import Alert from "../../modules/AIHumans/components/Alert";
import { Status } from "../../redux/actions/humansProjectActions";
import FailUploadAlert from "../FailUploadAlert/FailUploadAlert";

const MAX_VIDEO_SIZE = 500 * 1024 * 1024;
const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5 MB
const MAX_IMAGE_RESOLUTION = 1024;

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddCustomAvatarPopup = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const { noRefresh, type } = useSelector(getPrefilled);
  const customHumatar = useSelector(getCustomHumatar);

  const [title, setTitle] = useState("");
  const [uploadedFile, setUploadedFile] = useState<Video | null>(null);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [errorSizeSample, setErrorSizeSample] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const uploadHumatarLoading = useSelector(uploadingHumatarLoading);

  const allFields = {
    [CustomAvatar.InstantHumatar]: {
      icon: "/images/ai-humans/humatar.svg",
      popupTitle: "Add Instant Humatar",
      dragAndDropTitle: (
        <>
          Drag and drop your video, or browse <span>video</span>
        </>
      ),
      dragAndDropFileSupported: "File Supported: Video - .mp4",
      dragAndDropFileSize: "Maximum Size: Video - 500 MB",
      isConfirmationField: true,
      acceptedFormats: "video/mp4",
      action: uploadHumatarServer,
      infoText: (
        <Link to="/files/instructions.pdf" target="_blank">
          Shooting Instant Avatars Footage - Instructions
        </Link>
      ),
    },
    [CustomAvatar.TalkingImage]: {
      icon: "/images/ai-humans/talking-image.svg",
      popupTitle: "Add Talking Image",
      dragAndDropTitle: (
        <>
          Drag and drop your image, or browse <span>image</span>
        </>
      ),
      dragAndDropFileSupported: "File Supported: Images - .jpg, .png",
      dragAndDropFileSize: "Maximum Size: Images - 5 MB. Maximum resolution: 1024x1024",
      isConfirmationField: false,
      acceptedFormats: "image/jpeg, image/png",
      action: uploadHumatarServer,
      infoText: (
        <ItemWrapper>
          <span>Recommended photo requirements</span>
          <InfoIcon />
          <Tooltip
            text={
              <ol>
                {talkingImageTooltip.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </ol>
            }
            position="top-left"
            reverseColor
          />
        </ItemWrapper>
      ),
    },
  };

  const {
    popupTitle,
    dragAndDropTitle,
    dragAndDropFileSupported,
    dragAndDropFileSize,
    isConfirmationField,
    acceptedFormats,
    action,
    infoText,
    icon,
  } = allFields[type as CustomAvatar];

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);

  const sampleUpload = async ({ target }: any) => {
    const uploadedFile = target.files && target.files[0];

    if (!uploadedFile) {
      return;
    }

    const isVideo = uploadedFile.type === "video/mp4";
    const isImage = uploadedFile.type === "image/jpeg" || uploadedFile.type === "image/png";

    if (isVideo || isImage) {
      if (uploadedFile.size > (isVideo ? MAX_VIDEO_SIZE : MAX_IMAGE_SIZE)) {
        setErrorSizeSample(true);
        return;
      }

      if (isImage) {
        const imageSize = await getImageSizeAsync(uploadedFile);
        if (imageSize.width > MAX_IMAGE_RESOLUTION || imageSize.height > MAX_IMAGE_RESOLUTION) {
          setErrorSizeSample(true);
          return;
        }
      }

      setErrorSizeSample(false);
      setUploadedFile({
        id: Math.random(),
        name: uploadedFile.name,
        video: isVideo ? URL.createObjectURL(uploadedFile) : null,
        image: isImage ? URL.createObjectURL(uploadedFile) : null,
        file: uploadedFile,
      });
    }
  };

  const getImageSizeAsync = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleAddCustomAvatar = (action: (formData: FormData, filename: string, noRefresh?: boolean) => any) => () => {
    if (!uploadedFile) return;
    const formData = new FormData();
    const filename = uploadedFile.file?.name || "";
    formData.append("File", uploadedFile?.file as File);
    formData.append("name", title);

    dispatch(action(formData, filename, noRefresh));
  };

  const handleClose = () => {
    if (uploadHumatarLoading) return;

    onClose();
  };

  useEffect(() => {
    if (!isFirstRender.current && !uploadHumatarLoading && !customHumatar.isError) {
      onClose();
    }

    isFirstRender.current = false;
  }, [uploadHumatarLoading]);

  return (
    <>
      <Modal
        title={popupTitle}
        className="addVoiceAudio"
        open={open}
        onClose={handleClose}
        maxWidth={660}
        maxHeight={1069}
      >
        <Content>
          <Textfield
            label="Actor's name"
            variant={TextfieldVariant.noneAdornment}
            value={title}
            placeholder="Untitled"
            onChange={handleTitleChange}
          />
          <MainContent>
            <VideokHeader>
              <span>Sample</span>
            </VideokHeader>
            {!uploadedFile?.video && !uploadedFile?.image ? (
              <DragAndDropWrapper>
                <input type="file" ref={inputRef} multiple accept={acceptedFormats} onChange={sampleUpload} />
                <img src={icon} />
                <h3>{dragAndDropTitle}</h3>
                <SampleFormat>{dragAndDropFileSupported}</SampleFormat>
                <SampleFormat sizeError={errorSizeSample}>{dragAndDropFileSize}</SampleFormat>
              </DragAndDropWrapper>
            ) : (
              <>
                {uploadedFile?.video && (
                  <video controls width="100%" height="auto">
                    <source src={uploadedFile.video} type="video/mp4" />
                  </video>
                )}
                {uploadedFile?.image && (
                  <img
                    src={uploadedFile.image}
                    alt="Preview"
                    style={{ width: "100%", height: "auto", objectFit: "contain" }}
                  />
                )}
              </>
            )}
          </MainContent>
          <InformationWrapper>{infoText}</InformationWrapper>
          {isConfirmationField && (
            <ConfirmWrapper>
              <input type="checkbox" checked={checkBox} onChange={() => setCheckBox(!checkBox)} required />
              <span>
                I confirm that I have all rights and consent to upload the video footage, and that the
                platform-generated content will not be used for illegal, fraudulent, or harmful purposes. As a customer,
                I agree to abide by Synthesys's{" "}
                <a href="https://synthesys.io/ai-terms/" target="_blank" rel="noreferrer">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="https://synthesys.io/ai-privacy-policy/" target="_blank" rel="noreferrer">
                  Privacy Policy.
                </a>
              </span>
            </ConfirmWrapper>
          )}
          <ActionsWrapper>
            <Button
              disabled={uploadHumatarLoading}
              text="Cancel"
              onClick={handleClose}
              buttonTheme={ButtonThemes.Outline}
            />
            <Button
              disabled={uploadHumatarLoading || (isConfirmationField && !checkBox) || uploadedFile == null}
              text={uploadHumatarLoading ? <CircularProgress /> : "Add"}
              onClick={handleAddCustomAvatar(action)}
            />
          </ActionsWrapper>
        </Content>
      </Modal>

      {customHumatar.isError && (
        <AlertWrapper>
          <FailUploadAlert status={Status.isError} text={customHumatar.errorMessage} />
        </AlertWrapper>
      )}
    </>
  );
};

export const AlertWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 90%;
  z-index: 9999;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 16px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  video {
    max-height: 300px;
    max-width: 578px;
  }

  img {
    max-height: 300px;
    max-width: 578px;
  }
`;

const VideokHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.4;
  }
`;

const DragAndDropWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.primaryBackground};
  border: 1px dashed #c2c2c2;
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 20px;
  height: 187px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  min-height: 115px;

  & > h3 {
    font-family: "Mulish";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.primaryText};
    margin-top: 4px;
    text-align: center;

    & > span {
      background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  & > input {
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
  }

  @media (max-height: 775px) {
    height: 100px;
    gap: 4px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > h3 {
      font-size: 14px;
      margin-top: 0;
    }
  }

  @media (max-width: 500px) {
    height: 130px;
    gap: 4px;

    & > svg {
      width: 24px;
      height: 24px;
    }

    & > h3 {
      font-size: 12px;
      margin-top: 0;
    }
  }
`;

const SampleFormat = styled.p<{ sizeError?: boolean }>`
  font-family: "Mulish";
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  color: ${({ theme }) => theme.primaryText};
  opacity: 0.4;
  text-align: center;

  ${({ sizeError }) =>
    sizeError &&
    `
       color: red;
  `}

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

const InformationWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }

  a {
    color: ${({ theme }) => theme.primaryText};
    text-decoration: underline;
    font-size: 14px;
  }

  @media (max-width: 520px) and (max-height: 775px) {
    span {
      font-size: 8px;
    }
  }
`;

const ConfirmWrapper = styled.label`
  display: flex;
  align-items: start;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: ${({ theme }) => theme.primaryText};
    text-decoration: underline;
  }

  input {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  input[type="checkbox"] {
    background-color: transparent;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 155%;
    color: ${({ theme }) => theme.primaryText};
    opacity: 0.6;
  }

  @media (max-width: 520px) and (max-height: 775px) {
    span {
      font-size: 8px;
    }
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 18px;
  gap: 24px;

  button {
    width: 100%;
    height: 52px;

    svg {
      display: block;
    }
  }

  @media (min-width: 950px) and (max-height: 720px) {
    height: auto;
    margin-top: 0;

    button {
      max-height: 48px;

      & > span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const SoundtrackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 4px 0;
  overflow: hidden auto;
  max-height: 200px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-height: 650px) {
    max-height: 120px;
  }

  @media (max-width: 520px) and (max-height: 775px) {
    max-height: 120px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 4px;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #6f7074;
  }

  svg {
    width: 14px;
    height: 14px;
    path {
      stroke: #6f7074;
    }
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    border-radius: 4px;
    padding: 4px 8px 6px !important;
    width: 250%;

    & > span {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > ol {
        margin-inline-start: 12px;
        margin-block-start: 4px;
        margin-block-end: 4px;

        & > li {
          list-style-type: decimal !important;
          margin-bottom: 12px;
          font-size: 11px !important;
          line-height: 14px !important;
          text-align: left;
          text-transform: none;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & > span,
      li::marker {
        font-size: 11px !important;
        line-height: 14px !important;
        text-align: left;
        text-transform: none;
      }
    }
  }

  @media (max-width: 900px) {
    & > div {
      display: none;
    }
  }

  @media (max-width: 700px) {
    span {
      font-size: 10px;
    }
  }
`;

export default AddCustomAvatarPopup;
