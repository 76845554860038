import React, { ReactNode } from "react";
import styled from "styled-components";

import Navigation from "../components/Navigation/Navigation";
import Sidebar from "../components/Sidebar/Sidebar";

interface Props {
  children: ReactNode;
}

const SidebarLayout = ({ children }: Props) => (
  <Wrapper>
    <Sidebar />
    <Content>
      <Navigation />
      <ChildrenWrapper className="container">{children}</ChildrenWrapper>
    </Content>
  </Wrapper>
);

const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  padding: 0px 32px 0px 0px;
  gap: 26px;
  flex: 1;
  overflow: hidden;

  @media (max-width: 1001px) {
    padding: 0px 16px;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  flex: 1;
  overflow: hidden;
`;

const ChildrenWrapper = styled("div")`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 88px);
  width: 100%;
`;

export default SidebarLayout;
