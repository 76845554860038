export const GET_ASSET_SERVER = "GET_ASSET_SERVER";
export const UPDATE_ASSET_LOADING = "UPDATE_ASSET_LOADING";
export const UPDATE_HAS_MORE_ASSET = "UPDATE_HAS_MORE_ASSET";
export const SET_PAGE_ASSET = "SET_PAGE_ASSET";
export const CLEAR_ASSET = "CLEAR_ASSET";
export const CREATE_USER_ASSET_SERVER = "CREATE_USER_ASSET_SERVER";
export const SEARCH_ASSET_RESULT_LOADING = "SEARCH_ASSET_RESULT_LOADING";
export const DELETE_USER_ASSET = "DELETE_USER_ASSET";
export enum Asset {
  Images = 1,
  Videos = 2,
  Soundtracks = 3,
  Sample = 4,
  Media = 5,
  Faceswap = 6,
  VisualAssets = 7,
  InPaintingVisualAssets = 8,
}

interface UpdateAssetLoadingProps {
  assetTypeId: Asset;
  isLoading: boolean;
}

interface AssetProps {
  keyword?: string;
  pageNumber?: number;
  pageSize?: number;
  assetTypeId: Asset;
  sortWith?: string;
  sortByDesc?: boolean;
}

interface UpdateHasMoreAssetProps {
  hasMore: boolean;
  assetTypeId: Asset;
}
interface SetPageAssetProps {
  pageNumber: number;
  assetTypeId: Asset;
}

interface ClearAssetProps {
  assetTypeId: Asset;
}

interface CreateUserAsserServerProps {
  data: FormData;
  assetTypeId: Asset;
  pageSize?: number;
}

interface DeleteUserAssetServerProps {
  id: number;
  assetTypeId: Asset;
}

export const updateHasMoreAsset = ({ hasMore, assetTypeId }: UpdateHasMoreAssetProps) => ({
  type: UPDATE_HAS_MORE_ASSET,
  payload: {
    hasMore,
    assetTypeId,
  },
});

export const setPageAsset = ({ pageNumber, assetTypeId }: SetPageAssetProps) => ({
  type: SET_PAGE_ASSET,
  payload: { pageNumber, assetTypeId },
});

export const updateAssetLoading = ({ assetTypeId, isLoading }: UpdateAssetLoadingProps) => ({
  type: UPDATE_ASSET_LOADING,
  payload: {
    assetTypeId,
    isLoading,
  },
});

export const getAssetServer = ({ keyword, pageNumber, pageSize, assetTypeId }: AssetProps) => ({
  type: GET_ASSET_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/User/getAllUserAssets",
      data: {
        keyword,
        pageNumber,
        pageSize: pageSize || 24,
        assetTypeId,
      },
    },
    assetTypeId,
  },
});

export const clearAsset = ({ assetTypeId }: ClearAssetProps) => ({
  type: CLEAR_ASSET,
  payload: { assetTypeId },
});

export const createUserAssetServer = ({ data, assetTypeId, pageSize }: CreateUserAsserServerProps) => ({
  type: CREATE_USER_ASSET_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/User/createUserAsset",
      data,
    },
    assetTypeId,
    pageSize,
  },
});

export const searchAssetResultLoading = (loading: boolean, assetTypeId: Asset) => ({
  type: SEARCH_ASSET_RESULT_LOADING,
  payload: {
    loading,
    assetTypeId,
  },
});

export const deleteUserAssetServer = ({ id, assetTypeId }: DeleteUserAssetServerProps) => ({
  type: DELETE_USER_ASSET,
  payload: {
    request: {
      method: "DELETE",
      url: `/User/deleteUserAsset/${id}`,
    },
    id,
    assetTypeId,
  },
});
