import React, { useEffect, useState } from "react";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import styled from "styled-components";
import {
  BoldTextActiveIcon,
  BoldTextIcon,
  FontStyleActiveIcon,
  FontStyleIcon,
  ItalicActiveIcon,
  ItalicIcon,
  UnderlineTextActiveIcon,
  UnderlineTextIcon,
} from "../../../../../components/Icons/Icons";
import { Text } from "../../../../../types/scene";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Text;
}

const FontStyle = ({ handleSceneObjectChange, currentObject }: Props) => {
  const theme = useSelector(getTheme);
  const [isOpen, setIsOpen] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderlined, setIsUnderlined] = useState(false);
  const [isBold, setIsBold] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleIsItalicChange = () => {
    if (!currentObject) return;
    const newStyle = currentObject.style.fontStyle === "italic" ? "inherit" : "italic";
    handleSceneObjectChange("style", { ...currentObject.style, fontStyle: newStyle }, currentObject.id);
    setIsItalic(!isItalic);
  };

  const handleIsUnderlinedChange = () => {
    if (!currentObject) return;
    const newStyle = currentObject.style.textDecoration === "underline" ? "none" : "underline";
    handleSceneObjectChange("style", { ...currentObject.style, textDecoration: newStyle }, currentObject.id);
    setIsUnderlined(!isUnderlined);
  };

  const handleBoldChange = () => {
    if (!currentObject) return;
    const newStyle = currentObject.style.fontWeight === "700" ? "400" : "700";
    handleSceneObjectChange("style", { ...currentObject.style, fontWeight: newStyle }, currentObject.id);
    setIsBold(!isBold);
  };

  useEffect(() => {
    if (!currentObject) {
      setIsItalic(false);
      setIsBold(false);
      setIsUnderlined(false);
      return;
    }
    setIsItalic(currentObject.style.fontStyle === "italic");
    setIsUnderlined(currentObject.style.textDecoration === "underline");
    setIsBold(currentObject.style.fontWeight === "700");
  }, [currentObject]);

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper isActive={isOpen}>
            <IconButton onClick={handleOpen}>
              <FontStyleActiveIcon />
            </IconButton>
            <Tooltip
              text="Text Options"
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
              reverseColor
              arrow
            />
          </ButtonWrapper>
        }
      >
        <MenuListItem isActive={isItalic} onClick={handleIsItalicChange}>
          {isItalic ? <ItalicActiveIcon /> : <ItalicIcon />}
        </MenuListItem>
        <MenuListItem isActive={isUnderlined} onClick={handleIsUnderlinedChange}>
          {isUnderlined ? <UnderlineTextActiveIcon /> : <UnderlineTextIcon />}
        </MenuListItem>
        <MenuListItem isActive={isBold} onClick={handleBoldChange}>
          {isBold ? <BoldTextActiveIcon /> : <BoldTextIcon />}
        </MenuListItem>
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const ButtonWrapper = styled.div<{ isActive: boolean }>`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  ${({ isActive, theme }) =>
    !isActive &&
    `
      svg > rect {
        fill: ${theme.icon};
      }
    `}
`;

const IconButton = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  border-radius: 4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const MenuListItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  border-radius: 4px;
  cursor: pointer;
  width: 24px;
  height: 24px;

  ${({ isActive, theme }) =>
    !isActive &&
    `
      svg > path {
        stroke: ${theme.icon};
      }

      svg > rect {
        fill: ${theme.icon};
      }
    `}

  ${({ isActive, theme }) =>
    isActive &&
    `
      svg > rect {
        fill: ${theme.menuListItemActive};
      }
    `}
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(10px)",
  left: "0",
  border: "none",
  padding: "12px 16px",
  borderRadius: "12px",
  width: "max-content",
  display: "flex",
  alignItems: "center",
  columnGap: "8px",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default FontStyle;
