import { toast } from "react-toastify";
import { Language } from "../../types/language";
import { StoreType } from "../../types/store";
import {
  DETECT_LANGUAGE,
  GET_ALL_SUPPORTED_LANGUAGES,
  TRANSLATE_TEXT,
  UPDATE_LANGUAGE_LOADING,
} from "../actions/languageAction";

export interface languageStateType {
  data: Language[];
  search: string;
  isLoading: boolean;
  targetLanguage: string;
  sourceLanguage: string;
  detectLoading: boolean;
  textTranslateData: string[];
  textTranslateLoading: boolean;
}

const assetInitialState: languageStateType = {
  data: [],
  search: "",
  isLoading: false,
  targetLanguage: "",
  sourceLanguage: "",
  detectLoading: false,
  textTranslateData: [],
  textTranslateLoading: false,
};

const languageReducer = (state = assetInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_LANGUAGE_LOADING: {
      const { isLoading } = action.payload;

      return { ...state, isLoading };
    }
    case DETECT_LANGUAGE: {
      return { ...state, detectLoading: true, sourceLanguage: "" };
    }
    case `${DETECT_LANGUAGE}_FAIL`: {
      return { ...state, detectLoading: false, sourceLanguage: "" };
    }
    case `${DETECT_LANGUAGE}_SUCCESS`: {
      const { language } = action.payload.data.data;

      return { ...state, sourceLanguage: language.toUpperCase(), detectLoading: false };
    }
    case TRANSLATE_TEXT: {
      return { ...state, textTranslateLoading: true };
    }
    case `${TRANSLATE_TEXT}_FAIL`: {
      toast.error("The translation of the text failed.");

      return { ...state, textTranslateLoading: false };
    }
    case `${TRANSLATE_TEXT}_SUCCESS`: {
      const { targetLanguage } = action.meta.previousAction.payload.request.data;
      const { data } = action.payload.data;

      return { ...state, textTranslateData: data, textTranslateLoading: false, targetLanguage };
    }
    case `${GET_ALL_SUPPORTED_LANGUAGES}_SUCCESS`: {
      const { data } = action.payload.data;

      return { ...state, data };
    }
    default: {
      return { ...state };
    }
  }
};

export const getLanguages = (state: StoreType) => state.language.data;
export const getLanguagesLoading = (state: StoreType) => state.language.isLoading;
export const getTargetLanguage = (state: StoreType) => state.language.targetLanguage;
export const getSourceLanguage = (state: StoreType) => state.language.sourceLanguage;
export const getLanguageDetectLoading = (state: StoreType) => state.language.detectLoading;
export const getTranslateTextLoading = (state: StoreType) => state.language.textTranslateLoading;
export const getTranslateTextData = (state: StoreType) => state.language.textTranslateData;

export default languageReducer;
