export const RefreshMessageIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6668 8.00004C14.6668 11.68 11.6802 14.6667 8.00016 14.6667C4.32016 14.6667 2.0735 10.96 2.0735 10.96M2.0735 10.96H5.08683M2.0735 10.96V14.2934M1.3335 8.00004C1.3335 4.32004 4.2935 1.33337 8.00016 1.33337C12.4468 1.33337 14.6668 5.04004 14.6668 5.04004M14.6668 5.04004V1.70671M14.6668 5.04004H11.7068"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
