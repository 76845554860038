export const DarkThemeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65646 4.80639C6.28982 5.06598 5.95324 5.36662 5.64819 5.70687C3.55906 8.03211 3.62726 11.7528 5.79998 14.0003C6.94831 15.189 8.49114 15.8519 10.144 15.8658C10.1623 15.8665 10.1814 15.8665 10.2004 15.8665C11.832 15.8665 13.3638 15.2337 14.5195 14.0802C14.76 13.8405 14.9785 13.5831 15.1743 13.3088C12.8674 13.5963 10.5025 12.8234 8.82258 11.1471C7.14336 9.47082 6.36608 7.11111 6.65646 4.80639ZM10.2003 17.3331C10.1769 17.3331 10.1541 17.3331 10.1307 17.3324C8.08188 17.3148 6.16874 16.4935 4.74544 15.0203C2.04915 12.2302 1.96482 7.61266 4.55625 4.72719C5.3636 3.82745 6.34913 3.15063 7.48499 2.71579C7.75264 2.61166 8.05695 2.67619 8.26154 2.87858C8.46613 3.08023 8.53432 3.38308 8.43533 3.65293C7.61552 5.89311 8.17428 8.42734 9.85863 10.1088C11.5437 11.7902 14.0846 12.3482 16.3321 11.5299C16.6019 11.4301 16.9055 11.5005 17.1064 11.7044C17.3073 11.9104 17.3711 12.214 17.2677 12.4817C16.8835 13.4811 16.3071 14.3684 15.5548 15.1186C14.1227 16.5485 12.2235 17.3331 10.2003 17.3331Z"
        fill="#23406B"
      />
    </g>
  </svg>
);
