import React, { FC } from "react";
import styled from "styled-components";

interface CustomDotProps {
  onClick?: any;
}
const CustomDot: FC<CustomDotProps> = ({ onClick }) => {
  return (
    <DotContainer onClick={onClick}>
      <Dot className="dot" />
    </DotContainer>
  );
};

const DotContainer = styled.div`
  cursor: pointer;
  margin-top: -14px;
  li.foo {
    background: red;
  }
  li.slick-active {
    width: 100px;
  }
  @media (max-width: 768px) {
    margin-top: -4px;
  }
`;

const Dot = styled.div<{ active?: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #bfc0c8;
  transition: transform 0.2s ease-in-out, border-radius 0.2s ease-in-out;
`;

export default CustomDot;
