import { ReactNode, useState } from "react";
import styled from "styled-components";

import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { Status } from "../../redux/actions/humansProjectActions";
import { CloseIconV2, FolderIcon, SuccessIcon } from "../Icons/Icons";
import { CircleCheckedIcon } from "../Icons/CircleCheckedIcon";

interface Props {
  status?: Status;
  text?: ReactNode;
  closeIcon?: boolean;
  output?: string;
}

const SucessUploadAlert = ({ status, text, closeIcon = true, output }: Props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => setOpen(false);

  return (
    <Wrapper open={open} variant={status} className="alert-wrapper">
      {content({ status, text, output })}
      {closeIcon && (
        <IconButton
          icon={<CloseIconV2 />}
          iconButtonTheme={IconButtonThemes.Transparent}
          onClick={handleClose}
          className="close-icon"
        />
      )}
    </Wrapper>
  );
};

const content = ({ status, text, output }: Props) => {
  switch (status) {
    case Status.isCompleted:
      return (
        <>
          <Heading>
            <CircleCheckedIcon />
            <Title>Your files have been uploaded successfully!</Title>
          </Heading>
          <Description>
            <FolderIcon />
            <b>{text}</b> <a href="/ai-humans?tab=custom">Open Custom AI Humans avatars</a>
          </Description>
        </>
      );
    default:
      return <div></div>;
  }
};

const Heading = styled.div`
  display: flex;
  width: 100%;
  column-gap: 6px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #191b1f;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 5px;
  color: #191b1f;

  svg {
    transform: scale(1.5) translateY(3.5px);
    width: 30px !important;

    rect {
      fill: transparent;
    }

    path {
      fill: #292d32;
      opaciy: 0.7;
    }
  }

  a {
    background: var(--Linear, linear-gradient(142deg, #0063b4 16.78%, #009af7 85.53%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }

  b {
    color: #6f7074;
    font-weight: 500;
  }
`;

const Wrapper = styled.div<{ open?: boolean; variant?: Status }>`
  padding: 15px;
  border-radius: 12px;
  width: 100%;
  gap: 12px;
  z-index: 70;
  width: 660px;
  max-width: 100%;
  visibility: visible;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fff;

    span {
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .close-icon {
    position: absolute;
    top: 10px;
    opacity: 0.7;
    right: 10px;

    svg {
      path {
        fill: #6f7074 !important;
      }
    }
  }

  .failure-icon {
    path {
      stroke: white;
    }
  }

  svg {
    width: 22px;
    height: 22px;
  }

  button {
    margin-left: auto;

    svg > path {
      fill: initial;
    }
  }

  ${({ open }) =>
    !open &&
    `
    display: none;
    `}
`;

export default SucessUploadAlert;
