import styled from "styled-components";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClick?: (e: any) => void;
}

const CardWrapper = ({ children }: Props) => {
  return (
    <OuterWrapper>
      <Content>{children}</Content>
    </OuterWrapper>
  );
};

const OuterWrapper = styled.div`
  padding: 24px;
  border-radius: 16px;
  gap: 14px;
  /* box-shadow: 5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
    -2px -2px 4px 0px #0000001a inset; */
  box-shadow: ${({ theme }) => theme.cardShadow};
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export default CardWrapper;
