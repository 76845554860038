export const Translate = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9066 2.66667H6.69329C3.99996 2.66667 2.66663 4.00001 2.66663 6.69334V11.92C2.66663 14.6667 3.99996 16 6.69329 15.9333H11.92C14.6666 16 16 14.6667 15.9333 11.9067V6.69334C16 4.00001 14.6666 2.66667 11.9066 2.66667ZM12.0133 13.0133C11.1066 13.0133 10.2266 12.6667 9.49329 12.0533C8.66663 12.6533 7.66663 13.0133 6.58663 13.0133C6.03996 13.0133 5.58663 12.56 5.58663 12.0133C5.58663 11.4667 6.03996 11.0133 6.58663 11.0133C7.94663 11.0133 9.07996 10.08 9.49329 8.8H6.58663C6.03996 8.8 5.58663 8.34667 5.58663 7.8C5.58663 7.25334 6.03996 6.8 6.58663 6.8H8.30663C8.35996 6.29334 8.77329 5.89334 9.29329 5.89334C9.81329 5.89334 10.2266 6.29334 10.28 6.8H10.6266C10.64 6.8 10.6533 6.8 10.6533 6.8H10.68H12C12.5466 6.8 13 7.25334 13 7.8C13 8.34667 12.56 8.8 12 8.8H11.56C11.44 9.44001 11.1866 10.04 10.8533 10.5867C11.2133 10.8533 11.6 11.0133 12.0133 11.0133C12.56 11.0133 13.0133 11.4667 13.0133 12.0133C13.0133 12.56 12.56 13.0133 12.0133 13.0133Z"
      fill="#0063B4"
    />
    <path
      d="M12 30.3333C6.30663 30.3333 1.66663 25.6933 1.66663 20C1.66663 19.4533 2.11996 19 2.66663 19C3.21329 19 3.66663 19.4533 3.66663 20C3.66663 23.9467 6.41329 27.2533 10.1066 28.12L9.74663 27.52C9.46663 27.04 9.61329 26.4267 10.0933 26.1467C10.56 25.8667 11.1866 26.0133 11.4666 26.4933L12.8666 28.8267C13.0533 29.1333 13.0533 29.52 12.88 29.8267C12.6933 30.1333 12.36 30.3333 12 30.3333Z"
      fill="#0063B4"
    />
    <path
      d="M29.3333 13C28.7866 13 28.3333 12.5467 28.3333 12C28.3333 8.05334 25.5866 4.74667 21.8933 3.88001L22.2533 4.48C22.5333 4.96 22.3866 5.57334 21.9066 5.85334C21.44 6.13334 20.8133 5.98667 20.5333 5.50667L19.1333 3.17334C18.9466 2.86667 18.9466 2.48001 19.12 2.17334C19.3066 1.86667 19.64 1.66667 20 1.66667C25.6933 1.66667 30.3333 6.30667 30.3333 12C30.3333 12.5467 29.88 13 29.3333 13Z"
      fill="#0063B4"
    />
    <path
      d="M22.56 15.8C18.8266 15.8 15.7866 18.8267 15.7866 22.5733C15.7866 26.3067 18.8133 29.3467 22.56 29.3467C26.2933 29.3467 29.3333 26.32 29.3333 22.5733C29.3333 18.8267 26.3066 15.8 22.56 15.8ZM25.8666 25.7867C25.3733 26.0267 24.7733 25.84 24.52 25.3333L24.2933 24.88H20.84L20.6133 25.3333C20.44 25.68 20.08 25.88 19.72 25.88C19.5733 25.88 19.4133 25.84 19.28 25.7733C18.7866 25.52 18.5866 24.92 18.8266 24.4267L21.68 18.7333C21.8533 18.4 22.2 18.1867 22.5733 18.1867C22.9466 18.1867 23.2933 18.4 23.4666 18.7467L26.32 24.44C26.56 24.9333 26.36 25.5333 25.8666 25.7867Z"
      fill="#0063B4"
    />
    <path d="M21.84 22.88H23.2933L22.56 21.4267L21.84 22.88Z" fill="#0063B4" />
  </svg>
);
