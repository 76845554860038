import { ProfileModules, User } from "../../types/profile";
import {
  GENERATE_API_KEY_SERVER,
  GET_MY_PROFILE_SERVER,
  REVOKE_API_KEY_SERVER,
  UPDATE_PROFILE,
  UPDATE_PROFILE_LOADING,
  UPLOAD_PROFILE_PIC,
} from "../actions/profileActions";
import { StoreType } from "../../types/store";
import { SIGN_IN_SERVER, SIGN_IN_WITH_GOOGLE_SERVER } from "../actions/authActions";

export interface profileStateType {
  [ProfileModules.myProfile]: {
    profile: User;
    isLoading: boolean;
  };
  [ProfileModules.updateProfile]: {
    isLoading: boolean;
  };
  [ProfileModules.changePassword]: {
    isLoading: boolean;
  };
  [ProfileModules.checkCloneVoice]: {
    isLoading: boolean;
  };
  [ProfileModules.checkCustomAvatar]: {
    isLoading: boolean;
  };
  [ProfileModules.cloneVoice]: {
    isLoading: boolean;
  };
  [ProfileModules.generateApiKey]: {
    isLoading: boolean;
  };
  [ProfileModules.loadingPage]: {
    isLoading: boolean;
  };
  [ProfileModules.deleteAccount]: {
    isLoading: boolean;
  };
}

const profileInitialState: profileStateType = {
  [ProfileModules.myProfile]: {
    profile: {} as User,
    isLoading: false,
  },
  [ProfileModules.updateProfile]: {
    isLoading: false,
  },
  [ProfileModules.changePassword]: {
    isLoading: false,
  },
  [ProfileModules.checkCloneVoice]: {
    isLoading: false,
  },
  [ProfileModules.checkCustomAvatar]: {
    isLoading: false,
  },
  [ProfileModules.cloneVoice]: {
    isLoading: false,
  },
  [ProfileModules.generateApiKey]: {
    isLoading: false,
  },
  [ProfileModules.loadingPage]: {
    isLoading: true,
  },
  [ProfileModules.deleteAccount]: {
    isLoading: false,
  },
};

const profileReducer = (state = profileInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_PROFILE_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as ProfileModules], isLoading } };
    }
    case `${GET_MY_PROFILE_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: action.payload.data.data,
        },
      };
    }
    case `${GENERATE_API_KEY_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: {
            ...state[ProfileModules.myProfile].profile,
            apiKey: action.payload.data.data,
          },
        },
      };
    }
    case `${REVOKE_API_KEY_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: {
            ...state[ProfileModules.myProfile].profile,
            apiKey: "",
          },
        },
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: action.payload,
        },
      };
    }
    case UPLOAD_PROFILE_PIC: {
      return {
        ...state,
        [ProfileModules.myProfile]: {
          ...state[ProfileModules.myProfile],
          profile: {
            ...state[ProfileModules.myProfile].profile,
            profilePic: action.payload,
          },
        },
      };
    }
    case `${SIGN_IN_WITH_GOOGLE_SERVER}_SUCCESS`:
    case `${SIGN_IN_SERVER}_SUCCESS`: {
      if (action.payload.data.data) {
        const { user } = action.payload.data.data;

        return {
          ...state,
          [ProfileModules.myProfile]: {
            ...state[ProfileModules.myProfile],
            profile: user,
          },
        };
      } else {
        return { ...state };
      }
    }
    default: {
      return { ...state };
    }
  }
};

export const getProfile = (state: StoreType) => state.profile[ProfileModules.myProfile].profile;
export const getProfileLoading = (state: StoreType) => state.profile[ProfileModules.myProfile].isLoading;

export const getChangePasswordLoading = (state: StoreType) => state.profile[ProfileModules.changePassword].isLoading;

export const getCheckCloneVoiceLoading = (state: StoreType) => state.profile[ProfileModules.checkCloneVoice].isLoading;
export const getCloneVoiceLoading = (state: StoreType) => state.profile[ProfileModules.cloneVoice].isLoading;

export const getCheckCustomAvatarLoading = (state: StoreType) =>
  state.profile[ProfileModules.checkCustomAvatar].isLoading;

export const getGenerateApiKeyLoading = (state: StoreType) => state.profile[ProfileModules.generateApiKey].isLoading;
export const deleteAccountLoading = (state: StoreType) => state.profile[ProfileModules.deleteAccount].isLoading;

export const getUpdateProfileLoading = (state: StoreType) => state.profile[ProfileModules.updateProfile].isLoading;

export const getLoadingPage = (state: StoreType) => state.profile[ProfileModules.loadingPage].isLoading;

export default profileReducer;
