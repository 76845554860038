import styled from "styled-components";

import Tabs from "./components/Tabs";
import HistoryCard from "./components/HistoryCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import { historyModeTabs, useHistory } from "../../hooks/useHistory";

const HistoryPage = () => {
  const {
    data,
    isLoading,
    totalPages,
    currentPage,
    menuOptions,
    totalRecords,
    activeHistoryMode,
    selectedHistoryLenghtPerPage,
    handlePaginate,
    handleActiveHistoryMode,
    handleSelectHistoryLengthPerPage,
  } = useHistory();

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <Title>History</Title>
          <MobileOnly>
            <Sidebar mobile />
          </MobileOnly>
          <ProductWrapper>
            <TabsWrapper>
              <Tabs data={historyModeTabs} active={activeHistoryMode} handleActive={handleActiveHistoryMode} />
            </TabsWrapper>
          </ProductWrapper>
          <HistoryCard
            data={data}
            isLoading={isLoading}
            totalPages={totalPages}
            totalRecords={totalRecords}
            currentPage={currentPage}
            menuOptions={menuOptions}
            paginate={handlePaginate}
            selectedHistoryLenghtPerPage={selectedHistoryLenghtPerPage}
            handleSelectHistoryLengthPerPage={handleSelectHistoryLengthPerPage}
          />
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const Title = styled("div")`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
  margin-bottom: 16px;
`;

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  .container {
    max-width: 1208px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;

  @media (max-width: 1001px) {
    flex-direction: column;
    margin-bottom: 24px;
  }
`;

const TabsWrapper = styled("div")`
  max-width: 546px;
  width: 100%;
  div {
    max-width: 100%;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

export default HistoryPage;
