import { AssetModules, AssetType } from "../../types/asset";
import { StoreType } from "../../types/store";
import {
  CLEAR_ASSET,
  CREATE_USER_ASSET_SERVER,
  DELETE_USER_ASSET,
  GET_ASSET_SERVER,
  SEARCH_ASSET_RESULT_LOADING,
  SET_PAGE_ASSET,
  UPDATE_ASSET_LOADING,
  UPDATE_HAS_MORE_ASSET,
} from "../actions/assetAction";

export interface assetStateType {
  lastUploadedAsset: AssetType | null;
  assets: {
    [key: string]: {
      data: AssetType[];
      search: string;
      hasMore: boolean;
      pageNumber: number;
      isLoading: boolean;
      searchLoading: boolean;
      uploadLoading: boolean;
      latestUpload?: string | null;
      isDelete: boolean;
    };
  };
}

const assetInitialState: assetStateType = {
  lastUploadedAsset: null,
  assets: {
    [AssetModules.image]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
    [AssetModules.video]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
    [AssetModules.soundtrack]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
    [AssetModules.sample]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      latestUpload: null,
      isDelete: false,
    },
    [AssetModules.media]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
    [AssetModules.faceswap]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
    [AssetModules.visualAsset]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
    [AssetModules.inPaintingVisualAsset]: {
      data: [],
      search: "",
      hasMore: true,
      pageNumber: 0,
      isLoading: false,
      searchLoading: false,
      uploadLoading: false,
      isDelete: false,
    },
  },
};

const assetReducer = (state = assetInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_ASSET_LOADING: {
      const { assetTypeId, isLoading } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: { ...state.assets[assetTypeId], isLoading },
          },
        };
      } else {
        return { ...state };
      }
    }
    case UPDATE_HAS_MORE_ASSET: {
      const { assetTypeId, hasMore } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              hasMore,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case SET_PAGE_ASSET: {
      const { assetTypeId, pageNumber } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              pageNumber,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case CLEAR_ASSET: {
      const { assetTypeId } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              data: [],
              pageNumber: 0,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case SEARCH_ASSET_RESULT_LOADING: {
      const { assetTypeId, loading } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              searchLoading: loading,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case CREATE_USER_ASSET_SERVER: {
      const { assetTypeId } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              uploadLoading: true,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case `${CREATE_USER_ASSET_SERVER}_FAIL`:
    case `${CREATE_USER_ASSET_SERVER}_SUCCESS`: {
      const { assetTypeId } = action.meta.previousAction.payload;

      if (assetTypeId) {
        const latestUpload = action?.payload?.data?.data?.path;
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              latestUpload,
              uploadLoading: false,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case DELETE_USER_ASSET: {
      const { id, assetTypeId } = action.payload;
      const data = state.assets[assetTypeId].data.filter(({ userAssetID }) => userAssetID !== id);

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              data,
              isDelete: true,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case `${DELETE_USER_ASSET}_FAIL`:
    case `${DELETE_USER_ASSET}_SUCCESS`: {
      const { assetTypeId } = action.meta.previousAction.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              isDelete: false,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case GET_ASSET_SERVER: {
      const { assetTypeId } = action.payload;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              hasMore: true,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    case `${GET_ASSET_SERVER}_SUCCESS`: {
      const { assetTypeId } = action.meta.previousAction.payload;
      const { pageNumber, data } = action.payload.data;

      if (assetTypeId) {
        return {
          ...state,
          assets: {
            ...state.assets,
            [assetTypeId]: {
              ...state.assets[assetTypeId],
              data: pageNumber === 1 ? data : [...state.assets[assetTypeId].data, ...data],
              search: action.meta.previousAction.payload.request.data.keyword,
              pageNumber: state.assets[assetTypeId].pageNumber + 1,
            },
          },
        };
      } else {
        return { ...state };
      }
    }
    default: {
      return { ...state };
    }
  }
};

export const getAssets = (state: StoreType) => state.asset.assets;

export default assetReducer;
