import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Text } from "../../../../../types/scene";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Text;
}

const TextColor = ({ handleSceneObjectChange, currentObject }: Props) => {
  const [value, setValue] = useState("");

  const handleColoChange = (event: any) => {
    if (!currentObject) return;
    handleSceneObjectChange("style", { ...currentObject.style, color: event.target.value }, currentObject.id);
    setValue(event.target.value);
  };

  useEffect(() => {
    if (!currentObject) return setValue("");
    setValue(currentObject.style.color as string);
  }, [currentObject]);

  return (
    <Wrapper>
      <ColorInput type="color" onChange={handleColoChange} value={value} />
      <Tooltip
        text="Color"
        position="bottom"
        style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
        reverseColor
        arrow
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const ColorInput = styled.input`
  display: block;
  -webkit-appearance: none;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primaryText};
  cursor: pointer;

  &::-webkit-color-swatch {
    border: 1px solid ${({ theme }) => theme.primaryText};
    border-radius: 50%;
    padding: 0;
  }

  &::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
  }
`;

export default TextColor;
