import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ReactNode, useContext, useEffect, useState } from "react";

import Loader from "../../../components/Loader/Loader";
import Tooltip from "../../../components/Tooltip/Tooltip";
import IconButton from "../../../components/Button/IconButton";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import {
  CanvaIcon,
  CreateVariationsIcon,
  Download,
  InfoIcon,
  Logo,
  MaximizeIcon,
  VideoIcon,
} from "../../../components/Icons/Icons";
import {
  getFaceSwapProcessLoading,
  getGeneratedImages,
  getGeneratedImagesLoading,
  getGeneratedVideos,
  getGeneratedVideosLoading,
  getLipsSyncedVideoLoading,
  getUploadFaceSwapImage,
  getUpscaleImageLoading,
  getUpscaleVideoLoading,
} from "../../../redux/reducers/imagesReducer";
import { AIImagesContext } from "../../../App";
import { downloadImage } from "../../../lib/downloadImage";
import { EnlargedPicture } from "../../../types/enlargedPicture";
import { AIImagesSidebarTabs } from "../../../hooks/useAiImages";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import {
  getLipsSyncedVideoServer,
  getPromptToVideoByIdServer,
  getSwappedFaceVideoServer,
  swapFaceVideoServer,
  syncLipsVideoServer,
  upscaleImageServer,
  upscaleVideoServer,
} from "../../../redux/actions/imagesActions";
import { Text2Video } from "../../../types/text2Video";
import { FaceswapIconNew } from "../../../components/Icons/FaceswapIcon";
import { LipsyncIcon } from "../../../components/Icons/LipsyncingTranslateIcon";
import { FaceSwapType } from "../../../types/faceSwapType";

const AIImagesContent = () => {
  const dispatch = useDispatch();
  const images = useSelector(getGeneratedImages);
  const isImagesLoading = useSelector(getGeneratedImagesLoading);

  const video = useSelector(getGeneratedVideos);
  const isVideoLoading = useSelector(getGeneratedVideosLoading);
  const isFaceSwapLoading = useSelector(getFaceSwapProcessLoading);

  const upscaleImageLoading = useSelector(getUpscaleImageLoading);
  const upscaleVideoLoading = useSelector(getUpscaleVideoLoading);
  const faceSwapImage = useSelector(getUploadFaceSwapImage);
  const lipsyncLoading = useSelector(getLipsSyncedVideoLoading);

  const [menuOpen, setMenuOpen] = useState<number>();

  const { activeTab, handleUploadedImage, setActiveTab, outputsAmount, lipsyncFile } = useContext(AIImagesContext);

  const fullSize = !images.length || isImagesLoading || isVideoLoading || upscaleImageLoading || upscaleVideoLoading;

  const createVideo = (e: any, url: string) => {
    e.preventDefault();
    e.stopPropagation();

    setActiveTab(AIImagesSidebarTabs.Text2Video);
    handleUploadedImage(url);
  };

  const upscale = (e: any, url: string, id: number) => {
    e.preventDefault();
    e.stopPropagation();

    if (activeTab === AIImagesSidebarTabs.Text2Image) {
      dispatch(upscaleImageServer({ url, id }));
    } else {
      dispatch(upscaleVideoServer({ url }));
    }
  };

  const initiateFaceSwap = (videoUrl: string, faceImgUrl: string) => {
    if (!faceImgUrl) return;
    dispatch(swapFaceVideoServer({ faceswapType: FaceSwapType.video, source: faceImgUrl, target: videoUrl }));
  };

  const initiateLipsync = (videoUrl: string, audioFile: File | null) => {
    if (!audioFile) return;

    const formData = new FormData();
    formData.append("InputVideo", videoUrl);
    formData.append("InputAudioFile", audioFile);
    dispatch(syncLipsVideoServer({ formData }));
  };

  const menu = [
    {
      icon: <FaceswapIconNew />,
      name: "FaceSwap",
      visible: activeTab === AIImagesSidebarTabs.Text2Video,
      handleClick: (_e: any, url: string) => initiateFaceSwap(url, faceSwapImage),
    },
    {
      icon: <LipsyncIcon />,
      name: "LipSync",
      visible: activeTab === AIImagesSidebarTabs.Text2Video,
      handleClick: (_e: any, url: string) => initiateLipsync(url, lipsyncFile),
    },
    {
      icon: <CreateVariationsIcon />,
      name: "Create Variations",
      visible: activeTab === AIImagesSidebarTabs.Text2Image,
      handleClick: (e: any, url: string) => handleUploadedImage(url),
    },
    {
      icon: <MaximizeIcon />,
      name: "Upscale",
      visible: true,
      handleClick: (e: any, url: string, id: number) => upscale(e, url, id),
    },
    {
      icon: <VideoIcon />,
      name: "Create Video",
      visible: activeTab === AIImagesSidebarTabs.Text2Image,
      handleClick: (e: any, url: string) => createVideo(e, url),
    },
  ];

  const handleCloseMenu = (e: any) => {
    e.preventDefault();

    setMenuOpen(-1);
  };

  useEffect(() => {
    let intervalId: any;

    if (video && isVideoLoading) {
      intervalId = setInterval(() => {
        dispatch(getPromptToVideoByIdServer({ id: video.prompt2VideoProjectId }));
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isVideoLoading, video?.status]);

  useEffect(() => {
    let intervalId: any;

    if (video && isFaceSwapLoading) {
      intervalId = setInterval(() => {
        dispatch(getSwappedFaceVideoServer({ faceswapId: video.faceswapProjectId }));
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isFaceSwapLoading, video?.status]);

  useEffect(() => {
    let intervalId: any;

    if (video && video.lipsyncProjectId && lipsyncLoading) {
      intervalId = setInterval(() => {
        dispatch(getLipsSyncedVideoServer({ lipsyncId: video.lipsyncProjectId }));
      }, 15000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [lipsyncLoading, video?.status, video?.lipsyncProjectId]);

  const content: { [key: string]: ReactNode } = {
    [AIImagesSidebarTabs.Text2Image]: (
      <>
        {!isImagesLoading &&
          !upscaleImageLoading &&
          images &&
          images.map((image) => (
            <ImgWrapper key={image.visualHistoryID}>
              <StyledImg
                src={image.path}
                alt={image.fileName}
                onClick={() => {
                  const prefilled: EnlargedPicture = {
                    pic: image,
                    isView: false,
                  };
                  dispatch(updatePopup({ popup: Popups.enlargedImagePopup, status: true, prefilled }));
                }}
              />
              <ActionWrapper>
                {menu.map(
                  (item, order) =>
                    item.visible && (
                      <ActionMenu
                        key={order}
                        position={{
                          x: "left",
                          y: "bottom",
                        }}
                        open={menuOpen === order}
                        handleClose={handleCloseMenu}
                        trigger={
                          <IconButtonWrapper>
                            <Tooltip className="action-tooltip" text={item.name} position="bottom" arrow />
                            <IconButton
                              icon={item.icon}
                              onClick={(e: any) => item.handleClick(e, image.path, image.visualHistoryID)}
                            />
                          </IconButtonWrapper>
                        }
                      />
                    ),
                )}
              </ActionWrapper>
              <DownloadButtonWrapper>
                <Tooltip className="action-tooltip" text="Download" position="top" arrow />
                <IconButton icon={<Download />} onClick={(e: any) => downloadImage(e, image.path)} />
              </DownloadButtonWrapper>
            </ImgWrapper>
          ))}

        {!isImagesLoading &&
          !upscaleImageLoading &&
          !images.length &&
          Array.from({ length: outputsAmount }, (value, index) => (
            <Placeholder key={index}>
              <Logo />
            </Placeholder>
          ))}
        {(isImagesLoading || upscaleImageLoading) && (
          <LoaderWrapper>
            <Loader width={10} size={120} />
          </LoaderWrapper>
        )}
      </>
    ),
    [AIImagesSidebarTabs.Text2Video]: (
      <>
        {!isVideoLoading && !upscaleVideoLoading && !isFaceSwapLoading && !lipsyncLoading && video && (
          <VideoWrapper>
            <VideoContent>
              {video.prompt && video.prompt !== "-" && (
                <InfoWrapper>
                  <Tooltip text={video.prompt} arrow position="bottom" />
                  <InfoIcon />
                </InfoWrapper>
              )}
              <StyledVideo
                src={video.outputUrl}
                preload="auto"
                autoPlay={true}
                loop={true}
                controls={true}
                onClick={() => {
                  const prefilled: Text2Video = {
                    prompt: video.prompt,
                    size: video.size,
                    outputUrl: video.outputUrl,
                    fileName: video.title,
                  };
                  dispatch(updatePopup({ popup: Popups.text2VideoPopup, status: true, prefilled }));
                }}
              />

              <SendToCanvaWrapper>
                <span>Send to</span>
                <CanvaIcon />
                <span>to generate a talking avatar</span>
              </SendToCanvaWrapper>
            </VideoContent>
            <ActionWrapper>
              {menu.map(
                (item, order) =>
                  item.visible && (
                    <ActionMenu
                      key={order}
                      position={{
                        x: "left",
                        y: "bottom",
                      }}
                      open={menuOpen === order}
                      handleClose={handleCloseMenu}
                      trigger={
                        <IconButtonWrapper>
                          <Tooltip className="action-tooltip" text={item.name} position="bottom" arrow />
                          <IconButton
                            icon={item.icon}
                            onClick={(e: any) => item.handleClick(e, video.outputUrl, video.prompt2VideoProjectId)}
                          />
                        </IconButtonWrapper>
                      }
                    />
                  ),
              )}
            </ActionWrapper>
          </VideoWrapper>
        )}

        {!isVideoLoading && !upscaleVideoLoading && !isFaceSwapLoading && !lipsyncLoading && !video && (
          <Placeholder style={{ width: "100%", height: "100%" }}>
            <Logo />
          </Placeholder>
        )}
        {(isVideoLoading || upscaleVideoLoading || isFaceSwapLoading || lipsyncLoading) && (
          <LoaderWrapper>
            <Loader width={10} size={120} />
          </LoaderWrapper>
        )}
      </>
    ),
  };

  return (
    <>
      <Content fullSize={fullSize}>{content[activeTab]}</Content>
    </>
  );
};

const Content = styled.div<{ fullSize: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  width: 100%;
  margin-left: 15px;

  ${({ fullSize }) =>
    fullSize &&
    `
      width: 100%;
      justify-content: center;
      align-items: center;
  `}

  ::-webkit-scrollbar {
    width: 4px;
    background: rgba(25, 27, 31, 0.06);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
    height: 10px;
  }

  img {
    /* width: 100%;
    height: 100%;
    display: block; */
  }

  p {
    color: #191b1f;
  }
`;

const VideoContent = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  background-color: #e6e6ec;
`;

const StyledImg = styled.img`
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const StyledVideo = styled.video`
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const ImgWrapper = styled.div`
  cursor: pointer;
  width: calc(25% - 6px);
  position: relative;
  margin-bottom: auto;

  &:hover {
    div {
      display: flex;
    }
  }

  @media (max-width: 1500px) {
    width: calc(50% - 4px);
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover {
    div {
      display: flex;
    }
  }
`;

const ActionWrapper = styled.div`
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 7px;
  right: 8px;
  display: none;

  button {
    width: 40px;
    height: 40px;
    max-width: 40px;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    background: rgba(53, 53, 74, 1);
    opacity: 0.8;
  }

  .menu-wrapper {
    background: #ffffff;
  }

  span:last-of-type > div > div {
    right: 0;
    left: auto;
    transform: translateX(0);

    &::after {
      right: 13px;
      left: auto;
    }
  }
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: #e6e6ec;

  width: calc(50% - 4px);
  height: calc(50% - 4px);

  svg > path {
    fill: rgba(190, 190, 200, 1);
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const IconButtonWrapper = styled.div`
  display: block;
  position: relative;

  button:disabled {
    cursor: not-allowed;
  }

  & > div {
    box-shadow: 0px 0.5px 21.29px 0px rgba(21, 21, 23, 0.05);
    border-radius: 6px;
    padding: 4px;
    min-width: max-content;

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.23px;
    }
  }

  :hover > div {
    opacity: 0.8;
    visibility: visible;
  }
`;

const SendToCanvaWrapper = styled.div`
  position: absolute;
  bottom: 98px;
  left: 12px;
  width: 30px;
  height: 30px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  cursor: not-allowed;

  svg {
    position: absolute;
    left: 3px;
    transition: left 0.3s ease;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #009af7;
    display: none;
    transition: display 0.3s ease;
  }

  &:hover {
    border-radius: 10px;
    padding: 4px 8px;
    width: auto;

    svg {
      left: 65px;
    }

    span {
      display: flex;
    }

    span:last-of-type {
      margin-left: 22px;
    }
  }

  @media (max-width: 976px) {
    &:hover {
      width: 30px;
      height: 30px;
      padding: 4px;
      border-radius: 50%;

      svg {
        left: 3px;
      }

      span {
        display: none;
      }
    }
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  display: block !important;
  top: 16px;
  left: 12px;
  z-index: 2;

  svg > path {
    fill: none;
    stroke: white;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  & > div {
    box-shadow: 0px 0.5px 21.29px 0px rgba(21, 21, 23, 0.05);
    border-radius: 6px;
    padding: 4px;
    min-width: max-content;
    max-width: 250px;
    left: -4px;
    transform: translateX(0);

    &::after {
      left: 12px;
      bottom: 98%;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.23px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const DownloadButtonWrapper = styled.div`
  display: block;
  position: absolute;
  bottom: 10px;
  right: 8px;
  display: none;

  button {
    width: 40px;
    height: 40px;
    max-width: 40px;
    border: none;
    box-shadow: none;
    border-radius: 8px;
    background: #0286dc;

    & :disabled {
      cursor: not-allowed;
    }
  }

  & > div {
    box-shadow: 0px 0.5px 21.29px 0px rgba(21, 21, 23, 0.05);
    border-radius: 6px;
    padding: 4px;
    min-width: max-content;
    right: 0;
    left: auto;
    transform: translateX(0);

    span {
      font-size: 24px;
      font-weight: 400;
      line-height: 18.23px;
    }
    ::after {
      right: 13px;
      left: auto;
    }
  }

  :hover > div {
    opacity: 0.8;
    visibility: visible;
  }
`;

const LoaderWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default AIImagesContent;
