import { put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  CANCEL_SUBSCRIPTION_SERVER,
  GET_CHANGE_SUBSCRIPTIONS_SERVER,
  GET_PAYMENT_METHOD_PKS_SERVER,
  GET_USER_PLANS_SERVER,
  UPDATE_SUBSCRIPTION_SERVER,
  addToLoadingPlans,
  changeSubscriptionUpdated,
  getUserPlansServer,
  removeFromLoadingPlans,
  updateChangeSubscriptions,
  updateChangeSubscriptionsLoading,
  updatePaymentPKS,
  updatePaymentPKSLoading,
  updatePlansLoading,
  updateUserPlans,
} from "../actions/plansActions";
import { Popups, updatePopup } from "../actions/popupsActions";

const plansSaga = [
  takeEvery(`${GET_USER_PLANS_SERVER}`, handleGetPlans),
  takeEvery(`${GET_USER_PLANS_SERVER}_FAIL`, handleGetPlansFail),
  takeEvery(`${GET_USER_PLANS_SERVER}_SUCCESS`, handleGetPlansSuccess),

  takeEvery(`${CANCEL_SUBSCRIPTION_SERVER}`, handleCancelSubscription),
  takeEvery(`${CANCEL_SUBSCRIPTION_SERVER}_FAIL`, handleCancelSubscriptionFail),
  takeEvery(`${CANCEL_SUBSCRIPTION_SERVER}_SUCCESS`, handleCancelSubscriptionSuccess),

  takeEvery(`${GET_CHANGE_SUBSCRIPTIONS_SERVER}`, handleGetChangeSubscription),
  takeEvery(`${GET_CHANGE_SUBSCRIPTIONS_SERVER}_FAIL`, handleGetChangeSubscriptionFail),
  takeEvery(`${GET_CHANGE_SUBSCRIPTIONS_SERVER}_SUCCESS`, handleGetChangeSubscriptionSuccess),

  takeEvery(`${UPDATE_SUBSCRIPTION_SERVER}`, handleUpdateSubscription),
  takeEvery(`${UPDATE_SUBSCRIPTION_SERVER}_FAIL`, handleUpdateSubscriptionFail),
  takeEvery(`${UPDATE_SUBSCRIPTION_SERVER}_SUCCESS`, handleUpdateSubscriptionSuccess),

  takeEvery(`${GET_PAYMENT_METHOD_PKS_SERVER}`, hadnleGetPaymentMethodPKS),
  takeEvery(`${GET_PAYMENT_METHOD_PKS_SERVER}_FAIL`, hadnleGetPaymentMethodPKSFail),
  takeEvery(`${GET_PAYMENT_METHOD_PKS_SERVER}_SUCCESS`, hadnleGetPaymentMethodPKSSuccess),
];

function* handleGetPlans() {
  yield put(updatePlansLoading({ status: true }));
}

function* handleGetPlansFail() {
  yield put(updatePlansLoading({ status: false }));
  toast.error("Could not find your plans. Try again later!");
}

function* handleGetPlansSuccess(action: any) {
  const { data } = action.payload.data;
  yield put(updateUserPlans({ data }));
  yield put(updatePlansLoading({ status: false }));
}

function* handleCancelSubscription() {
  yield put(updatePlansLoading({ status: true }));
}

function* handleCancelSubscriptionFail() {
  yield put(updatePlansLoading({ status: false }));
  toast.error("Could not cancel your subscription. Try again later!");
}

function* handleCancelSubscriptionSuccess() {
  yield put(updatePlansLoading({ status: false }));
  yield put(getUserPlansServer());
  toast.success("The subscription was successfully cancelled");
}

function* handleGetChangeSubscription() {
  yield put(updateChangeSubscriptionsLoading({ status: true }));
}

function* handleGetChangeSubscriptionFail() {
  yield put(updateChangeSubscriptionsLoading({ status: false }));
  toast.error("Could not find the subscriptions! Try again later!");
}

function* handleGetChangeSubscriptionSuccess(action: any) {
  const { data } = action.payload.data;
  yield put(updateChangeSubscriptionsLoading({ status: false }));
  yield put(updateChangeSubscriptions({ data }));
}

function* handleUpdateSubscription(action: any) {
  const { newPlanId } = action.payload;
  yield put(addToLoadingPlans({ id: newPlanId }));
}

function* handleUpdateSubscriptionFail(action: any) {
  const { newPlanId } = action.meta.previousAction.payload;
  yield put(removeFromLoadingPlans({ id: newPlanId }));
  const { message } = action.error.response.data;
  toast.error(message);
  yield put(getUserPlansServer());
}

function* handleUpdateSubscriptionSuccess(action: any) {
  const { newPlanId } = action.meta.previousAction.payload;
  yield put(removeFromLoadingPlans({ id: newPlanId }));
  yield put(changeSubscriptionUpdated({ status: true }));
  yield put(updatePopup({ popup: Popups.changedSubscriptionPopup, status: true }));
  yield put(getUserPlansServer());
}

function* hadnleGetPaymentMethodPKS() {
  yield put(updatePaymentPKSLoading({ status: true }));
}

function* hadnleGetPaymentMethodPKSFail() {
  yield put(updatePaymentPKSLoading({ status: false }));
  yield put(updatePopup({ popup: Popups.paymentMethodPKSPopup, status: false }));
  toast.error("Something went wrong!");
}

function* hadnleGetPaymentMethodPKSSuccess(action: any) {
  const { data } = action.payload.data;
  yield put(updatePaymentPKSLoading({ status: false }));
  yield put(updatePaymentPKS({ data }));
}

export default plansSaga;
