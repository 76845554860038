import React from "react";
import styled from "styled-components";

import { CheckIcon1616White } from "../../../components/Icons/Icons";

const StrongText = styled.span`
  font-weight: 700;
`;

const list = [
  {
    text: (
      <>
        <StrongText>Turbo Speed</StrongText> Generations
      </>
    ),
  },
  {
    text: (
      <>
        <StrongText>Priority</StrongText> support
      </>
    ),
  },
  {
    text: (
      <>
        <StrongText>Unlimited</StrongText> Premium Custom Avatars
      </>
    ),
  },
  {
    text: (
      <>
        <StrongText>Unlimited</StrongText> Premium Voice Clones
      </>
    ),
  },
  {
    text: (
      <>
        Max Duration of <StrongText>30 mins</StrongText> per video
      </>
    ),
  },
  {
    text: (
      <>
        Text to Speech <StrongText>API</StrongText>
      </>
    ),
  },
  {
    text: (
      <>
        Simultaneous logins for <StrongText>5 team members</StrongText>
      </>
    ),
  },
  {
    text: (
      <>
        <StrongText>Video Translate</StrongText>
      </>
    ),
  },
];

interface EnterprisePackageListProps {
  listClass?: string;
}

const EnterprisePackageList = ({ listClass }: EnterprisePackageListProps) => {
  return (
    <ListWrapper className={listClass}>
      <ul>
        {list.slice(0, 4).map(({ text }, idx) => (
          <ListItem key={idx}>
            <span className="checkWrapper">
              <CheckIcon1616White />
            </span>
            <ItemText>{text}</ItemText>
          </ListItem>
        ))}
      </ul>
      <ul>
        {list.slice(4).map(({ text }, idx) => (
          <ListItem key={idx}>
            <span className="checkWrapper">
              <CheckIcon1616White />
            </span>
            <ItemText>{text}</ItemText>
          </ListItem>
        ))}
      </ul>
    </ListWrapper>
  );
};

const ListWrapper = styled.div`
  display: flex;
  gap: 48px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    gap: 16px;

    ul {
      gap: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: row;
    justify-content: center;
    gap: 24px;
  }

  @media screen and (max-width: 559px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;

  .checkWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: auto;
    }
  }
`;

const ItemText = styled.p`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75;

  @media screen and (max-width: 440px) {
    font-size: 14px;
  }
`;

export default EnterprisePackageList;
