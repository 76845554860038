import React from "react";
import styled from "styled-components";

import Tooltip from "../../../../../components/Tooltip/Tooltip";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { TrimIcon } from "../../../../../components/Icons/Icons";

interface Props {
  onClick: () => void;
}

const TrimAction = ({ onClick }: Props) => (
  <Wrapper>
    <IconButton iconButtonTheme={IconButtonThemes.Rounded} icon={<TrimIcon />} onClick={onClick} />
    <Tooltip
      text={"Trim"}
      position="bottom"
      style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
      reverseColor
      arrow
    />
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
`;

export default TrimAction;
