import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { DraftInlineStyleType, EditorState, RawDraftContentState, convertFromRaw, convertToRaw } from "draft-js";

import LanguageModal from "./LanguageModal";
import AIHumansEditorTabs from "./AIHumansEditorTabs";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Editor, { Block } from "../../../components/Editor/Editor";
import Soundtrack from "../../../components/Sountrack/Soundtrack";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import CircularProgress from "../../../components/Icons/CircularProgress";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { Avatar, ObjectTypes, Scene, SceneObject } from "../../../types/scene";
import { Zone } from "../../../types/project";
import { IActor } from "../../../types/actor";
import {
  getActorsList,
  getCurrentPageDownloadHistory,
  getDownloadHistory,
  getDownloadHistoryLoading,
  getHasMoreDownloadHistory,
} from "../../../redux/reducers/actorReducer";
import { cutText } from "../../../lib/textUtils";
import { AssetModules } from "../../../types/asset";
import { dataToState } from "../../../lib/projectUtils";
import { featuresSettings } from "../../../mocks/humans";
import { setInlineStyle } from "../../../lib/setInlineStyle";
import { HumansParagraph } from "../../../types/humansProject";
import { getAssets } from "../../../redux/reducers/assetReducer";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../../../types/confirmationDelete";
import { clearDownloadHistory, getDownloadHistoryServer } from "../../../redux/actions/actorActions";
import { getTranslateTextData, getTranslateTextLoading } from "../../../redux/reducers/languageReducer";
import { Asset, clearAsset, createUserAssetServer, getAssetServer } from "../../../redux/actions/assetAction";
import Button, { ButtonThemes } from "../../../components/Button/Button";

const MAX_SAMPLE_SIZE = 262144000;

export enum EditorTabs {
  textEditor = "textEditor",
  uploadVoice = "uploadVoice",
  allVoices = "allVoices",
}

const tabsPanelOptions = [
  {
    value: EditorTabs.textEditor,
    label: "Text Script",
  },
  {
    value: EditorTabs.uploadVoice,
    label: "My Voices",
  },
  {
    value: EditorTabs.allVoices,
    label: "Generated Voices",
  },
];

interface Props {
  paragraphs: HumansParagraph[];
  selectedZone: Zone | undefined;
  paragraphActive: number;
  handleEditorContent: any;
  handleTextParagraph: any;
  featureActive: number;
  setFeatureActive: any;
  editorContent: EditorState[];
  lastSel: any;
  setLastSel: any;
  paragraphActor: any;
  paragraphActorsList: any;
  calcParagraphsLength: number[];
  styleMap: Draft.DraftComponent.Base.DraftStyleMap | undefined;
  currentScene: Scene;
  scenes: Scene[];
  activeSceneId: number;
  setActorActive: (actors: IActor[]) => void;
  setIsPlayScript: (isPlayScript: boolean) => void;
  setEditorContent: Dispatch<SetStateAction<EditorState[]>>;
  setParagraphs: (nextParagraphs: HumansParagraph[]) => void;
  handleUpdateSoundtrack: (audioPath: string) => void;
}

const AIHumansEditor = ({
  paragraphs,
  selectedZone,
  paragraphActive,
  handleEditorContent,
  editorContent,
  handleTextParagraph,
  handleUpdateSoundtrack,
  featureActive,
  setFeatureActive,
  lastSel,
  setLastSel,
  paragraphActor,
  paragraphActorsList,
  calcParagraphsLength,
  styleMap,
  currentScene,
  scenes,
  activeSceneId,
  setActorActive,
  setIsPlayScript,
  setEditorContent,
  setParagraphs,
}: Props) => {
  const textLength = calcParagraphsLength[paragraphActive];

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [menuOpen, setMenuOpen] = useState<number>();
  const [tabValue, setTabValue] = useState<EditorTabs>(EditorTabs.textEditor);
  // const [voice, setVoice] = useState<Voice | null>(null);
  const [activeSoundtrack, setActiveSoundtrack] = useState<number>(0);
  const [selectedPathSoundtrack, setSelectedPathSoundtrack] = useState<string>("");
  const [errorSizeSample, setErrorSizeSample] = useState<boolean>(false);
  const prevScenes = useRef<Scene[] | null>(null);

  const dispatch = useDispatch();
  const actors = useSelector(getActorsList);
  const translateTextData = useSelector(getTranslateTextData);
  const translateTextLoading = useSelector(getTranslateTextLoading);

  const donwloadHistoryList = useSelector(getDownloadHistory);
  const donwloadHistoryLoading = useSelector(getDownloadHistoryLoading);
  const downloadHistoryHasMore = useSelector(getHasMoreDownloadHistory);
  const downloadHistoryCurrentPage = useSelector(getCurrentPageDownloadHistory);

  const currentObject = currentScene?.objects?.find((obj: SceneObject) => obj.type === ObjectTypes.avatars)?.object as
    | Avatar
    | undefined;

  const assets = useSelector(getAssets);
  // const latestUpload = assets[Asset.Sample].latestUpload;
  const uploadSampleLoading = assets[Asset.Sample].uploadLoading;
  const samples = assets[Asset.Sample].data;
  const samplesLoading = assets[Asset.Sample].isLoading;
  const samplesHasMore = assets[Asset.Sample].hasMore;
  const samplesCurrentPage = assets[Asset.Sample].pageNumber;
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const isFreeUser = profile.aiHumanFreeUser;
  const AI_HUMANS_LIMITATION = isFreeUser ? 500 : currentObject?.isSadtalker ? 1000 : 2000;

  const activeSceneIdx = scenes.findIndex((scene) => scene.id === activeSceneId);
  const activeScene = scenes[activeSceneIdx];

  const newTabsPanelOptions = isGuest
    ? tabsPanelOptions.filter(({ value }) => value !== EditorTabs.uploadVoice)
    : tabsPanelOptions;

  const fetchMore = () => {
    if (tabValue === EditorTabs.uploadVoice) {
      dispatch(
        getAssetServer({
          keyword: "",
          pageNumber: samplesCurrentPage + 1,
          assetTypeId: Asset.Sample,
        }),
      );
    } else {
      dispatch(
        getDownloadHistoryServer({
          pageNumber: downloadHistoryCurrentPage + 1,
          pageSize: 16,
        }),
      );
    }
  };

  const handleOpenMenu = (id: number) => setMenuOpen(id);

  const handleCloseMenu = () => setMenuOpen(-1);

  const handleSoundtrackSelected = (path: string) => {
    if (selectedPathSoundtrack === path) {
      setSelectedPathSoundtrack("");
      handleUpdateSoundtrack("");
    } else {
      setSelectedPathSoundtrack(path);
      handleUpdateSoundtrack(path);
    }
  };

  const handleTabsChange = (newValue: EditorTabs) => setTabValue(newValue);

  const handleChange = (order: number, step: number) => (e: any) => {
    const editorIndex = scenes.findIndex((scene) => scene.id === activeSceneId);
    let content: RawDraftContentState = convertToRaw(editorContent[editorIndex].getCurrentContent());
    const key = content.blocks[featureActive].key;
    const activeFeatureSettings: any = featuresSettings.features[order];
    const pause = content.blocks[featureActive + 1];

    if (activeFeatureSettings.title === "pause") {
      const value = activeFeatureSettings.values[Math.round(e.target.value / step)].text + "s";

      if (!pause) {
        content.blocks.push(
          new Block({
            key: Math.random().toString(16).slice(2).slice(0, 5),
            text: value,
            inlineStyles: [{ offset: 0, length: value.length, style: "pause" }],
          }),
          new Block({
            key: Math.random().toString(16).slice(2).slice(0, 5),
            text: "",
            inlinyStyles: [],
          }),
        );
      } else {
        pause.text = value;
        pause.inlineStyleRanges = [{ offset: 0, length: value.length, style: "pause" as DraftInlineStyleType }];
      }
    } else
      content = setInlineStyle(content, key, {
        [activeFeatureSettings.title]: activeFeatureSettings.values[Math.round(e.target.value / step)].text,
      });

    const newState = EditorState.forceSelection(EditorState.createWithContent(convertFromRaw(content)), lastSel);

    handleEditorContent(editorIndex)(newState);
  };

  const sampleUpload = ({ target }: any) => {
    const uploadedFile = target.files[0];

    if (!uploadedFile) return;

    if (uploadedFile && uploadedFile.size > MAX_SAMPLE_SIZE) {
      setErrorSizeSample(true);
    } else {
      const audio = new Audio();
      audio.addEventListener("loadedmetadata", () => {
        const durationInSeconds = audio.duration;
        const durationInMinutes = Math.floor(durationInSeconds / 60);

        if (durationInMinutes > 1) {
          toast.error("Please make sure your audio duration is less than 1 minute");
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          return;
        } else {
          const formData = new FormData();
          formData.append("AssetTypeId", "4");
          formData.append("Status", "1");
          formData.append("FileName", uploadedFile.name);
          formData.append("File", uploadedFile);

          setErrorSizeSample(false);
          dispatch(
            createUserAssetServer({
              data: formData,
              assetTypeId: Asset.Sample,
            }),
          );
        }
      });

      audio.src = URL.createObjectURL(uploadedFile);
    }
  };

  const handleUserAssetDelete = (e: any, id: number) => {
    if (!id) {
      throw Error(`The ID ${AssetModules[Asset.Sample]} is missing when deleting`);
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id,
      title: `Are you sure you want to delete ${AssetModules[Asset.Sample]}?`,
      description: `The ${AssetModules[Asset.Sample]} will be removed and the action cannot be undone`,
      typeId: Asset.Sample,
      type: DeleteType.Asset,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  useEffect(() => {
    dispatch(clearAsset({ assetTypeId: Asset.Sample }));
    dispatch(
      getAssetServer({
        keyword: "",
        pageNumber: 1,
        assetTypeId: Asset.Sample,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(clearDownloadHistory());
    dispatch(
      getDownloadHistoryServer({
        pageNumber: 1,
        pageSize: 16,
      }),
    );
  }, []);

  const handlePlayingSoundtrack = (id: number) => setActiveSoundtrack(id - 1);
  useEffect(() => {
    if (prevScenes.current && prevScenes.current.length && prevScenes.current.length < scenes.length) {
      const insertIndex = prevScenes.current.findIndex((scene, index) => scene?.id !== scenes[index]?.id);
      const isDuplicated = insertIndex !== -1;

      if (!isDuplicated) setEditorContent([...editorContent, EditorState.createEmpty()]);
    }

    if (prevScenes.current && prevScenes.current.length && prevScenes.current.length > scenes.length) {
      const deletedIndex = prevScenes.current.findIndex((scene, index) => scene?.id !== scenes[index]?.id);
      const newEditorContent = editorContent.filter((_, index) => index !== deletedIndex);

      if (newEditorContent.length) {
        setEditorContent(newEditorContent);
        setParagraphs(paragraphs.filter((_, index) => index !== deletedIndex));
      }
    }

    prevScenes.current = scenes;
  }, [scenes.length]);

  useEffect(() => {
    prevScenes.current = scenes;
  }, [scenes]);

  useEffect(() => {
    if (translateTextData.length) {
      const newParagraphs = paragraphs.map((paragraph, index: number) =>
        index === paragraphActive
          ? {
              ...paragraph,
              data: paragraph.data.map((item, order: number) => {
                return { ...item, text: translateTextData[order] || item.text };
              }),
            }
          : paragraph,
      );
      setParagraphs(newParagraphs);
      setEditorContent(newParagraphs.map((paragraph) => dataToState(paragraph.data, 0)));
    }
  }, [translateTextData]);

  const handleCharacterPopupOpen = () => {
    dispatch(
      updatePopup({
        popup: Popups.characterPopup,
        status: true,
        prefilled: {
          actors,
          active: featuresSettings?.id,
          isSingleActorSelectionOnly: true,
          paragraphs,
          currentParagraphActor: paragraphActor,
          currentParagraphActorsList: paragraphActor ? [paragraphActor] : [],
          setActorActive,
        },
      }),
    );
  };

  const tabsContent = {
    [EditorTabs.textEditor]: (
      <>
        <DropdownWrapper>
          <Dropdown
            flag={paragraphActor?.flagPath}
            language={paragraphActor?.languageName}
            photo={paragraphActor?.photo || ""}
            name={paragraphActor?.name}
            actors={actors}
            isSingleActorSelectionOnly={true} // This is done to make sure only one
            // actor can be selected, as AI Voices allow many actors, but AI Humans will only 1
            currentParagraphActor={paragraphActor}
            currentParagraphActorsList={paragraphActor ? [paragraphActor] : []}
            active={featuresSettings?.id}
            setActorActive={setActorActive}
            paragraphs={paragraphs}
            deleteDisplay={false}
            onDelete={(e: any) => console.log("")}
            menuButtonText="Change voice actor"
          />
          <Button buttonTheme={ButtonThemes.Secondary} text="Change Voice Actor" onClick={handleCharacterPopupOpen} />
          <ActionWrapper>
            {featuresSettings.features.map((feature, index) => {
              const activeData = selectedZone;
              const activeFeatureValue = activeData?.features?.find(
                (features) => features.key === feature.defaultTitle,
              )?.value;

              const barId = feature.values?.find((value) => value.text === activeFeatureValue)?.id;

              const barDefaultId = feature.values?.find((value) => value.text === feature.defaultValue)?.id;
              const barValue = feature.step ? ((barId as number) - 1) * feature.step : 0;
              const barDefaultValue = feature.step ? ((barDefaultId as number) - 1) * feature.step : 0;

              const activeBarLabel = feature.values?.find((value) => value.id === barId)?.label;

              return (
                <IconButtonWrapper key={feature.id} isTranslate={feature.title === "translate"}>
                  <ActionMenu
                    position={{
                      x: "left",
                      y: "bottom",
                    }}
                    open={index === menuOpen}
                    handleClose={handleCloseMenu}
                    trigger={
                      <ButtonWrapper>
                        <IconButton
                          iconButtonTheme={IconButtonThemes.Rounded}
                          icon={feature.icon}
                          onClick={() => handleOpenMenu(index)}
                        />
                        <Tooltip
                          text={feature.title}
                          position="bottom"
                          style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
                          reverseColor
                          arrow
                        />
                      </ButtonWrapper>
                    }
                  >
                    {feature.step ? (
                      <ProgressBar
                        {...feature}
                        onChange={handleChange(index, feature.step)}
                        value={!isNaN(barValue) ? barValue : barDefaultValue}
                        activeBarLabel={activeBarLabel || feature.defaultValue?.toString()}
                      />
                    ) : (
                      <LanguageModal paragraph={paragraphs[paragraphActive]?.data} setMenuOpen={setMenuOpen} />
                    )}
                  </ActionMenu>
                </IconButtonWrapper>
              );
            })}
          </ActionWrapper>
        </DropdownWrapper>
        {activeScene && editorContent[activeSceneIdx] && (
          <Editor
            key={activeScene.id}
            index={activeSceneIdx}
            editorContent={editorContent[activeSceneIdx]}
            setEditorContent={handleEditorContent(activeSceneIdx)}
            handleTextParagraph={handleTextParagraph(activeSceneIdx, activeScene.id)}
            setFeatureActive={setFeatureActive}
            lastSel={lastSel}
            setLastSel={setLastSel}
            paragraphs={paragraphs}
            styleMap={styleMap}
            textLength={textLength}
            limitation={AI_HUMANS_LIMITATION}
            defaultPause={0}
            placeholder={"Enter your text..."}
          />
        )}
        <Footer>
          <span>
            {textLength}/{AI_HUMANS_LIMITATION}
          </span>
        </Footer>
      </>
    ),
    [EditorTabs.uploadVoice]: (
      <SoundtrackWrapper id="scrollableSampleDiv">
        {samplesLoading && !samples.length ? (
          <CircularProgressWrapper>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : (
          <InfiniteScroll
            next={fetchMore}
            hasMore={samplesHasMore}
            loader={
              samplesLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#009af7" />
                </CircularProgressWrapper>
              ) : null
            }
            dataLength={samples?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableSampleDiv"
          >
            {samples.map((data, index: number) => {
              const selectedSoundtrack = data.path === currentScene.audioPath;

              return (
                <Soundtrack
                  key={index}
                  active={index === activeSoundtrack + 1}
                  title={cutText(data.fileName, 40)}
                  deleteActive={!!data.userId}
                  soundtrack={data.path}
                  selectedSoundtrack={selectedSoundtrack}
                  variant="editor"
                  handleSelect={() => handleSoundtrackSelected(data.path)}
                  onClick={() => handlePlayingSoundtrack(index)}
                  handleDelete={(e: any) => handleUserAssetDelete(e, data.userAssetID)}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </SoundtrackWrapper>
    ),
    [EditorTabs.allVoices]: (
      <SoundtrackWrapper id="scrollableAllVoicesDiv">
        {donwloadHistoryLoading && !donwloadHistoryList.length ? (
          <CircularProgressWrapper>
            <CircularProgress color="#009af7" />
          </CircularProgressWrapper>
        ) : (
          <InfiniteScroll
            next={fetchMore}
            hasMore={downloadHistoryHasMore}
            loader={
              donwloadHistoryLoading ? (
                <CircularProgressWrapper>
                  <CircularProgress color="#009af7" />
                </CircularProgressWrapper>
              ) : null
            }
            dataLength={donwloadHistoryList?.length}
            style={{ display: "contents" }}
            scrollableTarget="scrollableAllVoicesDiv"
          >
            {donwloadHistoryList.map((data, index: number) => {
              const selectedSoundtrack = data.mp3Path === currentScene.audioPath;
              return (
                <Soundtrack
                  key={index}
                  active={index === activeSoundtrack + 1}
                  title={cutText(data.voiceOverText, 40)}
                  soundtrack={data.mp3Path}
                  variant="editor"
                  selectedSoundtrack={selectedSoundtrack}
                  handleSelect={() => handleSoundtrackSelected(data.mp3Path)}
                  onClick={() => handlePlayingSoundtrack(index)}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </SoundtrackWrapper>
    ),
  };

  useEffect(() => {
    const activeScene = scenes.find((scene) => scene.id === activeSceneId);
    const voice = activeScene?.audioPath;
    if (activeScene && voice) {
      // setTabValue(EditorTabs.uploadVoice);
    } else {
      setTabValue(EditorTabs.textEditor);
    }
  }, [activeSceneId]);

  return (
    <Wrapper isLoading={translateTextLoading}>
      {translateTextLoading ? (
        <span>Please wait, the text is being translated</span>
      ) : (
        <>
          <AIHumansEditorTabs
            fileInputRef={fileInputRef}
            paragraphs={paragraphs}
            selectedZone={selectedZone}
            paragraphActive={paragraphActive}
            data={newTabsPanelOptions}
            setParagraphs={setParagraphs}
            active={tabValue}
            handleTabsChange={handleTabsChange}
            uploadSampleLoading={uploadSampleLoading}
            handleUpload={sampleUpload}
            setIsPlayScript={setIsPlayScript}
          />
          <Content>{tabValue && tabsContent[tabValue]}</Content>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isLoading: boolean }>`
  background: ${({ theme }) => theme.editor};
  border-radius: 20px;
  width: 100%;
  min-height: 224px;
  max-height: 224px;
  position: relative;

  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
    pointer-events: none;
    border-radius: 20px;
    overflow: hidden;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: ${({ theme }) => theme.editorShadow};
    z-index: 5;
    pointer-events: none;
    border-radius: 20px;
    overflow: hidden;
  }

  .RichEditorContainer {
    padding: 16px;
    margin-top: 0;
    max-height: 123px;
    min-height: 123px;
    overflow: hidden auto;
    margin-right: 20px;

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: ${({ theme }) => theme.activeMenu};
    }

    ::-webkit-scrollbar-track {
      margin: 15px;
    }
  }
`;

const Content = styled.div`
  border-top: 2px solid ${({ theme }) => theme.editorLineBorder};
  margin-top: -2px;
`;

const DropdownWrapper = styled.div`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.editorDropDownContent};
  display: flex;
  align-items: center;

  .dropdown-menu {
    min-width: 15rem;
  }

  .name {
    opacity: 1;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;

  button {
    background: ${({ theme }) => theme.primaryBackground};
    box-shadow: ${({ theme }) => theme.editorButtonShadow};
    border-radius: 8px;
    max-width: 32px;
    min-width: 32px;
    height: 32px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const IconButtonWrapper = styled.div<{ isTranslate: boolean }>`
  position: relative;
  width: 32px;
  height: 32px;

  .menu-wrapper {
    top: 38px;
    right: -4px;
    padding: 16px;
    min-width: 240px;
    background: ${({ theme }) => theme.editor};
    box-shadow: ${({ theme }) => theme.editorMenuShadow};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: none;

    .progress-bar-title {
      font-size: 16px !important;
      text-transform: capitalize;
    }
  }

  ${({ isTranslate }) =>
    isTranslate &&
    `
    .menu-wrapper {
      top: -148px;
      right: 35px;
      min-height: 296px;
      max-height: 296px;
      overflow: hidden;
    }
  `}
`;

const Footer = styled.div`
  padding: 0 16px 24px 0;
  display: flex;
  width: 90px;
  justify-content: end;
  margin-top: auto;
  position: absolute;
  z-index: 5;
  right: 0;
  bottom: -10px;
  text-align: center;

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.primaryText}66;
    background-color: ${({ theme }) => theme.editor};
  }
`;

const SoundtrackWrapper = styled.div`
  padding: 12px 24px 16px 16px;
  position: relative;
  overflow: hidden auto;
  height: 100%;
  min-height: 154px;
  max-height: 154px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-content: start;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  .soundtrack-delete {
    width: auto;
    height: auto;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

export default AIHumansEditor;
