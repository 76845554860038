import styled from "styled-components";

import { EditorTabs } from "./AIHumansEditor";
import { PlayIcon } from "../../../components/Icons/PlayIcon";
import useVoiceGeneration from "../../../hooks/useVoiceGeneration";
import { Paragraphs, Zone } from "../../../types/project";
import { checkIfZoneCached, checkIfZoneMatchNoAuthor, getAllZones } from "../../../lib/editorUtils";
import { generateVoiceServer } from "../../../redux/actions/actorActions";
import { useDispatch, useSelector } from "react-redux";
import { getProjectAudio } from "../../../redux/reducers/projectReducer";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { PauseIcon } from "../../../components/Icons/PauseIcon";
import { Dispatch, SetStateAction, useEffect } from "react";
import { HumansParagraph } from "../../../types/humansProject";
import { GenerateAudioParams } from "../../../types/generateAudio";

interface Props {
  paragraphs: HumansParagraph[];
  setParagraphs: (nextParagraphs: HumansParagraph[]) => void;
  selectedZone?: Zone;
  currentParagraph: HumansParagraph;
  setIsPlayScript: (isPlayScript: boolean) => void;
}

const AIHumansScriptPlayer = ({
  paragraphs,
  setParagraphs,
  selectedZone,
  currentParagraph,
  setIsPlayScript,
}: Props) => {
  const { cachedZonesAudio } = useSelector(getProjectAudio);

  const generateZoneByIndex = (startZone: number) => {
    generateAudio({
      startZone,
    });
  };
  const dispatch = useDispatch();

  const {
    audioPlayer,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    handleBackClick,
    playing,
    isFetching,
    currentTime,
    duration,
    audioLoading,
    handlePlayClick,
  } = useVoiceGeneration({
    selectedZone,
    paragraphs: [currentParagraph],
    generateZoneByIndex,
    setIsPlayScript,
  });

  const generateAudio = ({ startZone }: GenerateAudioParams) => {
    // const paragraphsModified = removeEmptyParagrahs(removeEmptyZones(JSON.parse(JSON.stringify(paragraphs))));
    const allZones = getAllZones([currentParagraph]);
    const currentZone = startZone || startZone === 0 ? allZones[startZone] : selectedZone;

    if (currentZone) {
      const currentZoneIndex = allZones.findIndex((zone) => checkIfZoneMatchNoAuthor(zone, currentZone));
      const finalCurrentZone = allZones[currentZoneIndex];
      const isZoneCached = checkIfZoneCached(finalCurrentZone, cachedZonesAudio);
      if (!isZoneCached) {
        dispatch(generateVoiceServer({ data: [finalCurrentZone], isAIHuman: true }));
      } else {
        // TODO: sentry error - this exxception shouldn't be called
      }
    } else {
      // TODO: sentry error - this exxception shouldn't be called
    }
  };

  const handlePlaySceneScript = () => {
    handlePlayClick();
  };

  return (
    <PlayScript onClick={handlePlaySceneScript}>
      <audio ref={audioPlayer} onTimeUpdate={onPlaying} onLoadedMetadata={onLoadedMetadata} onLoadStart={onLoadStart} />
      <IconWrapper>
        {isFetching || audioLoading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress color="#F0F0F3" />
          </div>
        ) : !playing || currentTime === duration ? (
          <PlayIcon />
        ) : (
          <PauseIcon />
        )}
      </IconWrapper>
      Play script
    </PlayScript>
  );
};

const IconWrapper = styled.div`
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    display: block;
    width: 10px;
    height: auto;
    transform: translateX(1px);
  }
`;

const PlayScript = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 7px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryText};
`;

export default AIHumansScriptPlayer;
