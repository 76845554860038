import React, { FC } from "react";
import styled from "styled-components";

interface ITab {
  id: number;
  label: string;
  discount?: number;
}

interface ButtonTabsProps {
  tabs: ITab[];
  onChange: (value: number) => void;
  activeTab: number;
}

const ButtonTabs: FC<ButtonTabsProps> = ({ tabs, activeTab, onChange }) => {
  return (
    <>
      <Wrapper>
        {tabs.map((tab) => (
          <Tab key={tab.id} isActive={activeTab === tab.id} onClick={() => onChange(tab.id)}>
            {tab.label}
            {tab.discount && (
              <Discount>
                <DiscountText>-20%</DiscountText>
              </Discount>
            )}
          </Tab>
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: 2px;
  padding: 4px;
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 60px;
`;

const Tab = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  min-width: 140px;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? "600" : "500")};
  color: ${({ isActive }) => (isActive ? "#1C8CD8" : "#6F7074")};
  border-radius: 52px;
  padding: 6px 12px;
  z-index: 15;
  background: ${({ isActive }) =>
    isActive ? "linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%)" : "transparent"};
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    background: ${({ theme }) => theme.primaryBackground};
    z-index: -1;
    border-radius: 52px;
  }
`;

const Discount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 60px;
`;

const DiscountText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export default ButtonTabs;
