import styled from "styled-components";
import { TransparentPlanCardCardProps } from "../../modules/TransparentPricing/Components/TransparentPlanCard";

const StrongText = styled.span`
  font-weight: 700;
`;

export const transparentPlanCardsMocks: TransparentPlanCardCardProps[] = [
  {
    plan: "Free",
    isLimited: false,
    price: "0",
    trigger: "",
    yearlyBilled: "",
    list: [
      {
        text: "3 minutes of free AI Video Translation",
        tooltip: "",
      },
      {
        text: "Translate video & audio into 31+ languages",
        tooltip: "",
      },
      {
        text: "Automated machine translation",
        tooltip: "",
      },
      {
        text: "Lip-sync feature",
        tooltip: "",
      },
      {
        text: "Transferred voice cloning to other languages",
        tooltip: "",
      },
      {
        text: "Multispeaker (coming soon)",
        tooltip: "",
      },
    ],
  },
  {
    plan: "Monthly",
    isLimited: false,
    price: "39",
    trigger: "#pkmodal076a85f1ff06b069b79faab77b209e5ce36ae0a8",
    yearlyBilled: "",
    list: [
      {
        text: <StrongText>AI Video Translate Unlimited</StrongText>,
        important: true,
        tooltip: "",
      },
      {
        text: <StrongText>Translate video & audio into 31+ languages</StrongText>,
        tooltip: "",
      },
      {
        text: <StrongText>Automated machine translation</StrongText>,
        tooltip: "",
      },
      {
        text: "Lip-sync feature",
        tooltip: "",
      },
      {
        text: "Transferred voice cloning to other languages",
        tooltip: "",
      },
      {
        text: "Multispeaker (coming soon)",
        tooltip: "",
      },
      {
        text: "30 Days Money Back Guarantee",
        tooltip: "",
      },
    ],
  },
  {
    plan: "Yearly",
    isLimited: true,
    price: "33",
    trigger: "#pkmodal810d72bcd19502778b2caad3d708bbdc9f2448ad",
    yearlyBilled: "397",
    list: [
      {
        text: <StrongText>AI Video Translate Unlimited</StrongText>,
        important: true,
        tooltip: "",
      },
      {
        text: <StrongText>Translate video & audio into 31+ languages</StrongText>,
        tooltip: "",
      },
      {
        text: <StrongText>Automated machine translation </StrongText>,
        tooltip: "",
      },
      {
        text: "Lip-sync feature",
        tooltip: "",
      },
      {
        text: "Transfered voice cloning to other languages",
        tooltip: "",
      },
      {
        text: "Multispeaker (coming soon)",
        tooltip: "",
      },
      {
        text: "30 Days Money Back Guarantee",
        tooltip: "",
      },
    ],
  },
];
