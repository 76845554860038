import { AiHumansProjectModules, Slide } from "../../types/humansProject";

export const CREATE_HUMANS_PROJECT = "CREATE_HUMANS_PROJECT";
export const UPDATE_HUMANS_PROJECT_SERVER = "UPDATE_HUMANS_PROJECT_SERVER";
export const CREATE_HUMANS_PROJECT_SERVER = "CREATE_HUMANS_PROJECT_SERVER";
export const GET_HUMANS_PROJECT_SERVER = "GET_HUMANS_PROJECT_SERVER";
export const GENERATE_HUMAN_VOICES_SERVER = "GENERATE_HUMAN_VOICES_SERVER";
export const UPDATE_HUMANS_PROJECT_LOADING = "UPDATE_HUMANS_PROJECT_LOADING";
export const CLEAR_CURRENT_HUMANS_PROJECT = "CLEAR_CURRENT_HUMANS_PROJECT";

export enum Status {
  isDraft = 1,
  isProgress,
  isCompleted,
  isError,
}

interface UpdateHumansProjectLoadingProps {
  module: AiHumansProjectModules;
  isLoading: boolean;
}

interface CreateProjectServerProps {
  projectTypeId: number | undefined;
  projectId?: number;
  projectCategoryId: number;
  title: string | undefined;
  resolution: string;
  slides: Slide[] | null;
  status?: Status;
  isHorizontal?: boolean;
  canvasWidth?: number;
  backgroundSoundTrackId: number | null;
  enableCaption?: boolean;
}

interface GenerateHumanVoicesProps {
  data: string[];
}

export const updateHumansProjectLoading = ({ module, isLoading }: UpdateHumansProjectLoadingProps) => ({
  type: UPDATE_HUMANS_PROJECT_LOADING,
  payload: {
    module,
    isLoading,
  },
});

interface CreateProjectProps {
  projectTypeId: number | undefined;
  projectId?: number;
  projectCategoryId?: number;
  title: string | undefined;
  resolution?: string;
  slides: Slide[] | null;
  status?: Status;
  isHorizontal?: boolean;
  canvasWidth?: number;
  backgroundSoundTrackId?: number | null;
  enableCaption?: boolean;
}

export const createProject = ({
  projectTypeId,
  title,
  slides,
  resolution,
  projectCategoryId,
  canvasWidth,
  projectId,
  isHorizontal,
  enableCaption,
}: CreateProjectProps) => ({
  type: CREATE_HUMANS_PROJECT,
  payload: {
    projectTypeId,
    title,
    slides,
    isHorizontal: isHorizontal || true,
    projectCategoryId: projectCategoryId || 1,
    resolution: resolution || "1920x1080",
    projectId: projectId || 0,
    canvasWidth,
    backgroundSoundTrackId: null,
    enableCaption: enableCaption || false,
  },
});

export const createProjectServer = ({
  projectTypeId,
  title,
  resolution,
  slides,
  status,
  isHorizontal,
  canvasWidth,
  backgroundSoundTrackId,
  enableCaption,
}: CreateProjectServerProps) => ({
  type: CREATE_HUMANS_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/create",
      data: {
        projectTypeId,
        title,
        slides,
        projectCategoryId: 1,
        resolution,
        status,
        isHorizontal,
        canvasWidth,
        backgroundSoundTrackId,
        enableCaption: enableCaption || false,
      },
    },
  },
});

export const updateProjectServer = ({
  projectTypeId,
  title,
  resolution,
  slides,
  status,
  projectId,
  isHorizontal,
  backgroundSoundTrackId,
  canvasWidth,
  enableCaption,
}: CreateProjectServerProps) => ({
  type: UPDATE_HUMANS_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/update",
      data: {
        projectTypeId,
        title,
        slides,
        projectCategoryId: 1,
        resolution,
        status,
        projectId,
        isHorizontal,
        backgroundSoundTrackId,
        canvasWidth,
        enableCaption: enableCaption || false,
      },
    },
  },
});

export const clearCurrentHumansProject = () => ({
  type: CLEAR_CURRENT_HUMANS_PROJECT,
});

export const getProjectServer = (projectId: number, isTemplate?: boolean, isTemplateTab?: boolean) => ({
  type: GET_HUMANS_PROJECT_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/project/get?Id=${projectId}`,
    },
    isTemplate,
    isTemplateTab,
  },
});

export const generateHumansVoicesServer = ({ data }: GenerateHumanVoicesProps) => ({
  type: GENERATE_HUMAN_VOICES_SERVER,
  payload: data,
});
