import styled from "styled-components";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { formatDistance } from "date-fns";

import MenuItem from "../../../components/ActionMenu/MenuItem";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import Textfield, { TextfieldVariant } from "../../../components/Textfield/Textfield";
import { downloadFile } from "../../../lib/downloadFile";
import { renameProjectServer } from "../../../redux/actions/projectAction";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { ConfirmationDelete, DeleteType } from "../../../types/confirmationDelete";
import { DownloadIcon, EditIcon, OpenIcon, OptionsIcon, ShareIcon, TrashIcon } from "../../../components/Icons/Icons";

interface Props {
  id: string;
  name: string;
  img?: string;
  output?: string;
  active: boolean;
  projectTime: any;
  pageSize: number;
  menuOptions: string[];
  setActiveProject: any;
  guid?: string;
  handleActiveProject: (e: any) => void;
}

const HistoryItem = ({
  img,
  name,
  projectTime,
  id,
  output,
  active,
  pageSize,
  menuOptions,
  setActiveProject,
  handleActiveProject,
  guid,
}: Props) => {
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState<string | null>(null);
  const [projectName, setProjectName] = useState<string>(name);

  const handleChange = (e: any) => setProjectName(e.target.value);

  const handleOpenMenu = (e: any, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(id === menuOpen ? null : id);
  };

  const handleCloseMenu = (e: any) => {
    e.preventDefault();

    setMenuOpen(null);
  };

  const renameProject = (e: any) => {
    handleActiveProject(e);
    handleCloseMenu(e);
  };

  const handleKeyPress = (e: any, type?: "blur") => {
    if (e.code === "Enter" || type) {
      if (!id) throw Error("Project entity is missing while renaming");
      if (id && projectName !== name) {
        dispatch(
          renameProjectServer({
            projectId: parseInt(id),
            projectTypeId: 2,
            newTitle: projectName,
            oldTitle: name,
          }),
        );
      }
      setActiveProject(null);
    }
  };

  const handleDeleteClick = (e: any) => {
    if (!id) {
      throw Error("Project entity is missing while deleting");
    }
    e.stopPropagation();
    e.preventDefault();

    const prefilled: ConfirmationDelete = {
      id: parseInt(id),
      title: "Are you sure you want to delete project " + name,
      description: "The project will be removed and the action cannot be undone",
      typeId: 2,
      type: DeleteType.Project,
      status: 3,
      pageSize,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const actionMenu = [
    {
      icon: <OptionsIcon />,
      options: menuOptions?.map((option) => ({
        name: option,
        icon: recogniseIcon(option),
      })),
    },
  ];

  const embedOnWebsite = () => {
    dispatch(
      updatePopup({
        popup: Popups.embedOnWebsitePopup,
        status: true,
        prefilled: {
          guid,
        },
      }),
    );
  };

  return (
    <Wrapper>
      {img && <StyledImg src={img ? img : "./"} avatar={false} />}
      {!active ? (
        <>
          <span>{name}</span>
        </>
      ) : (
        <Textfield
          autoFocus
          variant={TextfieldVariant.project}
          value={projectName}
          placeholder="Enter project name"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onBlur={(e) => handleKeyPress(e, "blur")}
        />
      )}
      <ProjectTimeText>{projectTime && formatDistance(new Date(projectTime + "Z"), new Date())} ago</ProjectTimeText>
      <ActionWrapper>
        {actionMenu.map((m, order) => (
          <ActionMenu
            key={order}
            position={{
              x: "left",
              y: "bottom",
            }}
            open={menuOpen === id}
            menuStyle={{ top: "-10px", right: "25px", padding: "8px 12px" }}
            handleClose={handleCloseMenu}
            trigger={
              <IconButton
                iconButtonTheme={IconButtonThemes.Transparent}
                icon={m.icon}
                onClick={(e: any) => handleOpenMenu(e, id)}
              />
            }
          >
            {m.options && (
              <MenuWrapper>
                {m.options.map((option, index) => (
                  <MenuItem
                    key={index}
                    icon={option.icon}
                    onClick={(e: any) => {
                      e.preventDefault();
                      option.name === "Download" && downloadFile(e, output || "");
                      option.name === "Rename" && renameProject(e);
                      option.name === "Delete" && handleDeleteClick(e);
                      option.name === "Embed on website" && embedOnWebsite();
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </MenuWrapper>
            )}
          </ActionMenu>
        ))}
      </ActionWrapper>
    </Wrapper>
  );
};

const recogniseIcon = (name: string) => {
  switch (name) {
    case "Download":
      return <DownloadIcon />;
    case "Rename":
      return <EditIcon />;
    case "Open this video":
      return <OpenIcon />;
    case "Delete":
      return <TrashIcon />;
    case "Embed on website":
      return (
        <ShareIconWrapper>
          <ShareIcon />
        </ShareIconWrapper>
      );
    default:
      return <></>;
  }
};

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding: 8px 0px 8px 0px;
  position: relative;

  span {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: ${({ theme }) => theme.primaryText};
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

const StyledImg = styled.img<{ avatar?: boolean }>`
  width: 32px;
  height: 32px;

  ${({ avatar }) =>
    avatar &&
    `
     border-radius: ${avatar ? "32px" : "0px"};
    `}
  margin-right: 8px;
`;

const MenuWrapper = styled.div`
  width: 140px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 16px;
  right: 14px;

  span {
    button {
      width: 20px;
      height: 20px;
      border: none;
      box-shadow: none;
      border-radius: 6px;

      svg > path {
        fill: ${({ theme }) => theme.primaryText};
      }
    }
  }

  .menu-wrapper {
    border: none;
    /* box-shadow: 5px 5px 10px 0px #aeaec04d, -5px -5px 10px 0px #ffffff, 2px 2px 4px 0px #ffffff inset,
      -2px -2px 4px 0px #0000001a inset; */
    box-shadow: ${({ theme }) => theme.cardShadow};
    max-width: 180px;

    div {
      color: #191b1f;
      -webkit-text-fill-color: unset;
      background: none;
      padding: 3px 0px;
      font-weight: 500;
      svg > path {
        fill: #191b1f;
      }
    }
  }
`;

const ProjectTimeText = styled.p`
  display: flex;
  align-items: center;
  max-width: 200px;
  width: 100%;
  margin-left: auto;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7074;
`;

const ShareIconWrapper = styled.div`
  svg {
    min-width: 16px;
  }
`;

export default HistoryItem;
