import { StoreType } from "../../types/store";
import { Project, ProjectList, LoadingStyles, ProjectModules, Zone } from "../../types/project";
import {
  CLEAR_CURRENT_PROJECT,
  CLEAR_PROJECT_LIST,
  CREATE_PROJECT_SERVER,
  DELETE_PROJECT_SERVER,
  GET_PROJECT_LIST_REFRESH_SERVER,
  GET_PROJECT_LIST_SERVER,
  GET_PROJECT_SERVER,
  PLAY_AUDIO,
  REDIRECT_TO_PROJECT,
  RENAME_PROJECT_SERVER,
  SET_CACHED_ZONES,
  SET_PAGE_PROJECTS,
  UPDATE_HAS_MORE_PROJECTS,
  UPDATE_PROJECT_LOADING,
} from "../actions/projectAction";
import { GENERATE_VOICE_SERVER } from "../actions/actorActions";
import { checkIfZoneCached } from "../../lib/editorUtils";
import { toast } from "react-toastify";
import { SentryErrors, sentryErrors } from "../../lib/sentry";
import { REHYDRATE } from "redux-persist";

export interface projectStateType {
  [ProjectModules.projectList]: {
    data: Project[];
    isLoading: boolean;
    isDeleteLoading: boolean;
    hasMore: boolean;
    pageNumber: number;
    isStatusLoading: boolean;
    totalPages: number;
    totalRecords: number;
  };
  [ProjectModules.project]: {
    project: Project | null;
    audio: {
      isAudioReady: boolean;
      loadingZonesAudio: Zone[]; // all loading zones at the moment
      cachedZonesAudio: Zone[]; // all cached zones
    };
    isLoading: boolean;
  };
  [ProjectModules.autoSave]: {
    isLoading: boolean;
  };
  isHydrated: boolean;
  redirectTo: string;
}

const projectInitialState: projectStateType = {
  [ProjectModules.projectList]: {
    data: [],
    isLoading: false,
    isDeleteLoading: false,
    hasMore: true,
    pageNumber: 0,
    isStatusLoading: false,
    totalPages: 0,
    totalRecords: 0,
  },
  [ProjectModules.project]: {
    project: null,
    audio: {
      isAudioReady: false,
      loadingZonesAudio: [],
      cachedZonesAudio: [],
    },
    isLoading: false,
  },
  [ProjectModules.autoSave]: {
    isLoading: false,
  },
  isHydrated: false,
  redirectTo: "",
};

const profileReducer = (state = projectInitialState, action: any) => {
  switch (action.type) {
    case REHYDRATE: {
      const data = action.payload;
      if (data) {
        return {
          ...state,
          ...data.project,
        };
      }
      return { ...state };
    }
    case UPDATE_PROJECT_LOADING: {
      const { module, isLoading } = action.payload;
      return { ...state, [module]: { ...state[module as ProjectModules], isLoading } };
    }
    case UPDATE_HAS_MORE_PROJECTS: {
      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          hasMore: action.payload.hasMore,
        },
      };
    }
    case GET_PROJECT_LIST_SERVER: {
      const { isStatusLoading } = action.payload.request;

      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          hasMore: true,
          isStatusLoading: isStatusLoading || false,
        },
      };
    }
    case `${GET_PROJECT_LIST_SERVER}_SUCCESS`: {
      const { isHistoryPage } = action.meta.previousAction.payload.request;
      const { pageNumber, data, totalPages, totalRecords } = action.payload.data;

      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          data: !isHistoryPage
            ? pageNumber === 1
              ? data
              : [...state[ProjectModules.projectList].data, ...data]
            : data,
          pageNumber: !isHistoryPage ? state[ProjectModules.projectList].pageNumber + 1 : pageNumber,
          isStatusLoading: false,
          totalPages,
          totalRecords,
        },
      };
    }
    case `${GET_PROJECT_LIST_REFRESH_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;

      const newData = state[ProjectModules.projectList].data.map((project) => {
        const mathchingObj = data.find((matchObj: Project) => project.projectId === matchObj.projectId);

        if (mathchingObj) return mathchingObj;
        else return project;
      });

      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          data: newData,
        },
      };
    }
    case `${GET_PROJECT_LIST_SERVER}_FAIL`: {
      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          isStatusLoading: false,
        },
      };
    }
    case SET_PAGE_PROJECTS: {
      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          pageNumber: action.payload.pageNumber,
        },
      };
    }
    case DELETE_PROJECT_SERVER: {
      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          isDeleteLoading: true,
        },
      };
    }
    case `${DELETE_PROJECT_SERVER}_SUCCESS`: {
      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          isDeleteLoading: false,
        },
      };
    }
    case GENERATE_VOICE_SERVER: {
      const audiosToGenerate: Zone[] = action.payload.request.data.data.map((zone: Zone) => ({
        ...zone,
        isLoading: true,
      }));
      const loadingZonesAudio: Zone[] = state[ProjectModules.project].audio.loadingZonesAudio;
      return {
        ...state,
        [ProjectModules.project]: {
          ...state[ProjectModules.project],
          audio: {
            ...state[ProjectModules.project].audio,
            loadingZonesAudio: [...loadingZonesAudio, ...audiosToGenerate],
          },
        },
      };
    }
    case `${GENERATE_VOICE_SERVER}_SUCCESS`: {
      if (!action?.payload?.data?.data || !Array.isArray(action?.payload?.data?.data)) {
        toast.error("Please contact support, error happened while generating voice");

        sentryErrors({
          errorType: SentryErrors.SERVER_ERROR_WHILE_GENERATING_VOICE,
          details: {
            data: JSON.stringify(action.meta.previousAction.payload.request || {}),
            responseFromApi: action?.error?.response?.data || null,
          },
        });

        return {
          ...state,
          [ProjectModules.project]: {
            ...state[ProjectModules.project],
            audio: {
              ...state[ProjectModules.project].audio,
              loadingZonesAudio: [],
            },
          },
        };
      } else {
        // Here we will cache newly generated voices
        const generatedZones: Zone[] = action.payload.data.data;
        const cachedZones: Zone[] = state[ProjectModules.project].audio.cachedZonesAudio;
        const loadedZones = action.meta.previousAction.payload.request.data.data;
        const loadingZonesAudio: Zone[] = state[ProjectModules.project].audio.loadingZonesAudio.filter(
          (zone) => !checkIfZoneCached(zone, loadedZones),
        );

        return {
          ...state,
          [ProjectModules.project]: {
            ...state[ProjectModules.project],
            audio: {
              ...state[ProjectModules.project].audio,
              loadingZonesAudio,
              cachedZonesAudio: [...cachedZones, ...generatedZones],
            },
          },
        };
      }
    }
    case `${GENERATE_VOICE_SERVER}_FAIL`: {
      const loadedZones = action.meta.previousAction.payload.request.data.data;
      const loadingZonesAudio: Zone[] = state[ProjectModules.project].audio.loadingZonesAudio.filter(
        (zone) => !checkIfZoneCached(zone, loadedZones),
      );
      return {
        ...state,
        [ProjectModules.project]: {
          ...state[ProjectModules.project],
          audio: {
            ...state[ProjectModules.project].audio,
            loadingZonesAudio,
          },
        },
      };
    }

    case PLAY_AUDIO: {
      return {
        ...state,
        [ProjectModules.project]: {
          ...state[ProjectModules.project],
          audio: {
            ...state[ProjectModules.project].audio,
            isAudioReady: true,
          },
        },
      };
    }
    // case `${DOWNLOAD_VOICE_SERVER}_SUCCESS`: {
    //   return {
    //     ...state,
    //     [ActorModules.downloadAudio]: {
    //       ...state[ActorModules.downloadAudio],
    //       data: action.payload.data,
    //     },
    //   };
    // }
    // case CLEAR_VOICE: {
    //   return {
    //     ...state,
    //     [ActorModules.audioList]: {
    //       ...state[ActorModules.audioList],
    //       data: [],
    //     },
    //   };
    // }

    case CLEAR_PROJECT_LIST: {
      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          data: [],
          pageNumber: 0,
        },
      };
    }

    case SET_CACHED_ZONES: {
      const cachedZones: Zone[] = state[ProjectModules.project].audio.cachedZonesAudio;
      const generatedZones = action.payload.zones;

      return {
        ...state,
        [ProjectModules.project]: {
          ...state[ProjectModules.project],
          audio: {
            ...state[ProjectModules.project].audio,
            cachedZonesAudio: [...cachedZones, ...generatedZones],
          },
        },
      };
    }

    case CLEAR_CURRENT_PROJECT: {
      return {
        ...state,
        [ProjectModules.project]: {
          ...state[ProjectModules.project],
          project: null,
          isLoading: false,
        },
      };
    }
    case `${CREATE_PROJECT_SERVER}_SUCCESS`:
    case `${GET_PROJECT_SERVER}_SUCCESS`: {
      const { paragraphs, title, projectId, isSimple } = action.payload.data.data;
      const paragraphsData = paragraphs.map(({ data, actorId, order, actor }: any) => ({
        data,
        actorId,
        order,
        actor,
      }));

      return {
        ...state,
        [ProjectModules.project]: {
          ...state[ProjectModules.project],
          project: {
            ...state[ProjectModules.project].project,
            paragraphs: paragraphsData,
            title,
            projectId,
            isSimple,
          },
        },
      };
    }
    case RENAME_PROJECT_SERVER: {
      const { projectId, projectTypeId, title } = action.payload.request.data;

      const newProjects = state[ProjectModules.projectList].data.map((project) =>
        project.projectTypeId === projectTypeId && project.projectId === projectId ? { ...project, title } : project,
      );

      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          data: newProjects,
        },
      };
    }
    case `${RENAME_PROJECT_SERVER}_FAIL`: {
      const { projectId, projectTypeId } = action.meta.previousAction.payload.request.data;
      const { oldTitle } = action.meta.previousAction.payload;

      const oldProjects = state[ProjectModules.projectList].data.map((project) =>
        project.projectTypeId === projectTypeId && project.projectId === projectId
          ? { ...project, title: oldTitle }
          : project,
      );

      toast.error("Error while renaming project");

      return {
        ...state,
        [ProjectModules.projectList]: {
          ...state[ProjectModules.projectList],
          data: oldProjects,
        },
      };
    }

    case REDIRECT_TO_PROJECT: {
      const { projectName, projectId } = action.payload;

      return {
        ...state,
        redirectTo: projectName && projectId ? `/${projectName}?projectId=${projectId}` : "",
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getProjectList = (state: StoreType) => state.project[ProjectModules.projectList].data;
export const getProjectListLoading = (state: StoreType) => state.project[ProjectModules.projectList].isLoading;
export const getIsDeleteLoading = (state: StoreType) => state.project[ProjectModules.projectList].isDeleteLoading;

export const getProject = (state: StoreType) => state.project[ProjectModules.project].project;
export const getProjectLoading = (state: StoreType) => state.project[ProjectModules.project].isLoading;
export const getProjectAudio = (state: StoreType) => state.project[ProjectModules.project].audio;

export const getAutoSaveLoading = (state: StoreType) => state.project[ProjectModules.autoSave].isLoading;

export const getHasMoreProjects = (state: StoreType) => state.project[ProjectModules.projectList].hasMore;
export const getCurrentPageProjects = (state: StoreType) => state.project[ProjectModules.projectList].pageNumber;
export const getIsStatusLoading = (state: StoreType) => state.project[ProjectModules.projectList].isStatusLoading;

export const getProjectListTotalPages = (state: StoreType) => state.project[ProjectModules.projectList].totalPages;
export const getProjectListTotalRecords = (state: StoreType) => state.project[ProjectModules.projectList].totalRecords;

export const getRedirectToProject = (state: StoreType) => state.project.redirectTo;

export default profileReducer;
