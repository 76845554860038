export const YahooLogoWhite = () => (
  <svg width="190" height="46" viewBox="0 0 190 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7321_27951)">
      <path
        d="M59.822 11.2779V13.6368C58.478 11.8814 55.9545 10.6745 53.047 10.6745C46.1622 10.6745 41.0603 16.7364 41.0603 23.868C41.0603 31.1916 46.1348 37.0615 53.047 37.0615C55.9545 37.0615 58.478 35.9095 59.822 34.0443V36.4032H68.4074V11.2505H59.822V11.2779ZM71.3698 0.141602V36.4306H80.2569V23.0177C80.2569 20.4119 81.5186 18.8484 83.521 18.8484C85.4684 18.8484 86.5931 20.1925 86.5931 22.716V36.4306H95.4802V20.6313C95.4802 14.5146 92.1612 10.7019 86.8399 10.7019C83.9873 10.7019 81.7655 11.7991 80.202 13.774V0.169028H71.3698V0.141602ZM152.615 23.868C152.615 31.3836 146.855 37.0615 139.175 37.0615C131.495 37.0615 125.735 31.3836 125.735 23.868C125.735 16.3523 131.495 10.6745 139.175 10.6745C146.855 10.6745 152.615 16.3523 152.615 23.868ZM124.226 23.868C124.226 31.3836 118.466 37.0615 110.786 37.0615C103.106 37.0615 97.3454 31.3836 97.3454 23.868C97.3454 16.3523 103.106 10.6745 110.786 10.6745C118.439 10.6745 124.226 16.3523 124.226 23.868ZM12.3418 11.2779L23.0941 36.6226L19.2266 45.7566H28.6623L42.953 11.2779H33.5721L27.7571 26.0349L21.9969 11.2779H12.3418ZM54.8573 29.0521C51.8949 29.0521 49.6457 26.8029 49.6457 23.868C49.6457 20.9605 51.8949 18.7113 54.8573 18.7113C57.7648 18.7113 60.014 20.9605 60.014 23.868C60.014 26.8029 57.7648 29.0521 54.8573 29.0521ZM115.778 23.868C115.778 21.0702 113.666 18.7936 110.758 18.7936C107.851 18.7936 105.739 21.0428 105.739 23.868C105.739 26.6932 107.851 28.9424 110.758 28.9424C113.666 28.9424 115.778 26.6932 115.778 23.868ZM144.195 23.868C144.195 21.0702 142.083 18.7936 139.175 18.7936C136.268 18.7936 134.156 21.0428 134.156 23.868C134.156 26.6932 136.268 28.9424 139.175 28.9424C142.083 28.9424 144.195 26.6932 144.195 23.868ZM153.74 31.1093C153.74 34.2637 156.209 36.8695 159.5 36.8695C162.902 36.8695 165.48 34.154 165.48 30.8899C165.48 27.6807 163.011 25.1297 159.72 25.1297C156.291 25.1297 153.74 27.9001 153.74 31.1093ZM166.385 0.141602L156.949 22.9354H167.482L176.918 0.141602H166.385Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7321_27951">
        <rect width="189.724" height="45.7167" fill="white" transform="translate(0.150391 0.141602)" />
      </clipPath>
    </defs>
  </svg>
);
