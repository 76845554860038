import { ShapesModules } from "../../types/shapes";

export const GET_SHAPES_SERVER = "GET_SHAPES_SERVER";
export const UPDATE_SHAPE_LOADING = "UPDATE_SHAPE_LOADING";
export const GET_ALL_SHAPES_BY_CATEGORY_SERVER = "GET_ALL_SHAPES_BY_CATEGORY_SERVER";

interface UpdateShapeLoadingProps {
  isLoading: boolean;
  module: ShapesModules;
}

export const updateShapeLoading = ({ module, isLoading }: UpdateShapeLoadingProps) => ({
  type: UPDATE_SHAPE_LOADING,
  payload: {
    isLoading,
    module,
  },
});

export const getShapesServer = () => ({
  type: GET_SHAPES_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/shape/list",
    },
  },
});

export const getAllShapesByCategoryServer = (keyword: string) => ({
  type: GET_ALL_SHAPES_BY_CATEGORY_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/shape/getAllByCategory",
      data: {
        keyword,
      },
    },
  },
});
