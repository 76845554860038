export const RippleDissolveIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="5" width="28" height="18" rx="4" fill="url(#paint0_linear_2969_22004)" />
    <circle cx="14" cy="14" r="7.2207" fill="#79C9FF" stroke="#1160A0" strokeWidth="0.515765" />
    <g filter="url(#filter0_i_2969_22004)">
      <circle cx="14" cy="13.9994" r="5.15765" fill="#A7DBFF" />
    </g>
    <circle cx="14" cy="13.9994" r="5.15765" stroke="#1160A0" strokeWidth="0.515765" />
    <circle cx="14" cy="14.0008" r="3.09459" fill="#C7E8FF" stroke="#1160A0" strokeWidth="0.515765" />
    <defs>
      <filter
        id="filter0_i_2969_22004"
        x="8.58447"
        y="8.58398"
        width="11.1082"
        height="11.1072"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.27714" dy="0.27714" />
        <feGaussianBlur stdDeviation="0.27714" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2969_22004" />
      </filter>
      <linearGradient
        id="paint0_linear_2969_22004"
        x1="6.03077"
        y1="7.35385"
        x2="14.8308"
        y2="24.9538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
