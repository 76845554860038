import { toast } from "react-toastify";
import { ShapeStateObject } from "../../types/asset";
import { ShapesModules } from "../../types/shapes";
import { StoreType } from "../../types/store";
import { GET_ALL_SHAPES_BY_CATEGORY_SERVER, GET_SHAPES_SERVER, UPDATE_SHAPE_LOADING } from "../actions/shapesAction";

export interface ShapesCategory {
  categoryName: string;
  keyword: string;
  shapeId: number;
  shapeImageUrl: string;
  shapeName: string;
  svgData: string | null;
}

export interface shapesStateType {
  [ShapesModules.shapesList]: {
    data: ShapeStateObject[];
    isLoading: boolean;
  };
  [ShapesModules.allShapesByCategory]: {
    [key: string]: {
      data: ShapesCategory[];
      isLoading: boolean;
    };
  };
}

const shapesInitialState: shapesStateType = {
  [ShapesModules.shapesList]: {
    data: [],
    isLoading: false,
  },
  [ShapesModules.allShapesByCategory]: {
    Shapes: {
      data: [],
      isLoading: false,
    },
    Frames: {
      data: [],
      isLoading: false,
    },
    Icons: {
      data: [],
      isLoading: false,
    },
    Images: {
      data: [],
      isLoading: false,
    },
    Stickers: {
      data: [],
      isLoading: false,
    },
    Videos: {
      data: [],
      isLoading: false,
    },
  },
};

const shapesReducer = (state = shapesInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_SHAPE_LOADING: {
      const { module, isLoading } = action.payload;

      return { ...state, [module]: { ...state[module as ShapesModules], isLoading } };
    }

    case `${GET_SHAPES_SERVER}_SUCCESS`: {
      return {
        ...state,
        shapesList: {
          ...state.shapesList,
          data: action.payload.data.data,
        },
      };
    }

    case GET_ALL_SHAPES_BY_CATEGORY_SERVER: {
      const { keyword } = action.payload.request.data;

      if (keyword) {
        return {
          ...state,
          [ShapesModules.allShapesByCategory]: {
            ...state[ShapesModules.allShapesByCategory],
            [keyword]: {
              ...state[ShapesModules.allShapesByCategory][keyword],
              isLoading: true,
            },
          },
        };
      } else {
        return { ...state };
      }
    }

    case `${GET_ALL_SHAPES_BY_CATEGORY_SERVER}_SUCCESS`: {
      const { data } = action.payload.data;
      const { keyword } = action.meta.previousAction.payload.request.data;

      if (keyword) {
        const newData = data.find(({ categoryName }: ShapesCategory) => categoryName === keyword)?.categoryData;

        return {
          ...state,
          [ShapesModules.allShapesByCategory]: {
            ...state[ShapesModules.allShapesByCategory],
            [keyword]: {
              ...state[ShapesModules.allShapesByCategory][keyword],
              data: newData,
              isLoading: false,
            },
          },
        };
      } else {
        return { ...state };
      }
    }

    case `${GET_ALL_SHAPES_BY_CATEGORY_SERVER}_FAIL`: {
      const { keyword } = action.meta.previousAction.payload.request.data;

      toast.error("Error while getting shapes by category");

      if (keyword) {
        return {
          ...state,
          [ShapesModules.allShapesByCategory]: {
            ...state[ShapesModules.allShapesByCategory],
            [keyword]: {
              ...state[ShapesModules.allShapesByCategory][keyword],
              isLoading: false,
            },
          },
        };
      } else {
        return { ...state };
      }
    }

    default: {
      return { ...state };
    }
  }
};

export const getShapes = (state: StoreType) => state.shapes[ShapesModules.shapesList].data;
export const getShapesLoading = (state: StoreType) => state.shapes[ShapesModules.shapesList].isLoading;

export const getAllShapesByCategory = (state: StoreType) => state.shapes[ShapesModules.allShapesByCategory];

export default shapesReducer;
