import styled from "styled-components";

import { TranslationTabProps } from "../../../../types/videoTranslation";

const TranslationOptionTab = ({ icon, title, description }: TranslationTabProps) => {
  return (
    <TranslationOptionWrapper>
      <TranslationOptionTop>
        {icon} <span>{title}</span>
      </TranslationOptionTop>
      <TranslationOptionDescription>{description}</TranslationOptionDescription>
    </TranslationOptionWrapper>
  );
};

const TranslationOptionWrapper = styled("div")`
  width: 100%;
  height: 76px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.videoTranslationBackground};
`;

const TranslationOptionTop = styled("div")`
  display: flex;
  gap: 8px;
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${({ theme }) => theme.primaryText};
  }

  svg {
    path {
      stroke: ${({ theme }) => theme.primaryText};
    }
  }
`;

const TranslationOptionDescription = styled("div")`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6f7074;
`;

export default TranslationOptionTab;
