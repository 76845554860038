import React from "react";

export const Lock = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 13.3337C9.53935 13.3337 9.16602 12.9603 9.16602 12.5003C9.16602 12.0403 9.53935 11.667 9.99935 11.667C10.4593 11.667 10.8327 12.0403 10.8327 12.5003C10.8327 12.9603 10.4593 13.3337 9.99935 13.3337ZM10 10.0002C8.62167 10.0002 7.5 11.1219 7.5 12.5002C7.5 13.8786 8.62167 15.0002 10 15.0002C11.3783 15.0002 12.5 13.8786 12.5 12.5002C12.5 11.1219 11.3783 10.0002 10 10.0002ZM15 15.8337C15 16.2937 14.6267 16.6671 14.1667 16.6671H5.83333C5.37333 16.6671 5 16.2937 5 15.8337V9.16707C5 8.70707 5.37333 8.33374 5.83333 8.33374H6.66667H8.33333H11.6667H13.3333H14.1667C14.6267 8.33374 15 8.70707 15 9.16707V15.8337ZM8.33301 5.09283C8.33301 4.12283 9.08051 3.33366 9.99967 3.33366C10.9188 3.33366 11.6663 4.12283 11.6663 5.09283V6.66699H8.33301V5.09283ZM14.1663 6.66699H13.333V5.09282C13.333 3.20449 11.838 1.66699 9.99967 1.66699C8.16134 1.66699 6.66634 3.20449 6.66634 5.09282V6.66699H5.83301C4.45467 6.66699 3.33301 7.78866 3.33301 9.16699V15.8337C3.33301 17.212 4.45467 18.3337 5.83301 18.3337H14.1663C15.5447 18.3337 16.6663 17.212 16.6663 15.8337V9.16699C16.6663 7.78866 15.5447 6.66699 14.1663 6.66699Z"
        fill="#231F20"
      />
      <mask id="mask0_655_8526" maskUnits="userSpaceOnUse" x="3" y="1" width="14" height="18">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99935 13.3337C9.53935 13.3337 9.16602 12.9603 9.16602 12.5003C9.16602 12.0403 9.53935 11.667 9.99935 11.667C10.4593 11.667 10.8327 12.0403 10.8327 12.5003C10.8327 12.9603 10.4593 13.3337 9.99935 13.3337ZM10 10.0002C8.62167 10.0002 7.5 11.1219 7.5 12.5002C7.5 13.8786 8.62167 15.0002 10 15.0002C11.3783 15.0002 12.5 13.8786 12.5 12.5002C12.5 11.1219 11.3783 10.0002 10 10.0002ZM15 15.8337C15 16.2937 14.6267 16.6671 14.1667 16.6671H5.83333C5.37333 16.6671 5 16.2937 5 15.8337V9.16707C5 8.70707 5.37333 8.33374 5.83333 8.33374H6.66667H8.33333H11.6667H13.3333H14.1667C14.6267 8.33374 15 8.70707 15 9.16707V15.8337ZM8.33301 5.09283C8.33301 4.12283 9.08051 3.33366 9.99967 3.33366C10.9188 3.33366 11.6663 4.12283 11.6663 5.09283V6.66699H8.33301V5.09283ZM14.1663 6.66699H13.333V5.09282C13.333 3.20449 11.838 1.66699 9.99967 1.66699C8.16134 1.66699 6.66634 3.20449 6.66634 5.09282V6.66699H5.83301C4.45467 6.66699 3.33301 7.78866 3.33301 9.16699V15.8337C3.33301 17.212 4.45467 18.3337 5.83301 18.3337H14.1663C15.5447 18.3337 16.6663 17.212 16.6663 15.8337V9.16699C16.6663 7.78866 15.5447 6.66699 14.1663 6.66699Z"
          fill="#231F20"
        />
      </mask>
      <g mask="url(#mask0_655_8526)" />
    </g>
  </svg>
);

export const LockBlue = () => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99992 12.3333C6.53992 12.3333 6.16659 11.96 6.16659 11.5C6.16659 11.04 6.53992 10.6667 6.99992 10.6667C7.45992 10.6667 7.83325 11.04 7.83325 11.5C7.83325 11.96 7.45992 12.3333 6.99992 12.3333ZM6.99992 8.99999C5.62159 8.99999 4.49992 10.1217 4.49992 11.5C4.49992 12.8783 5.62159 14 6.99992 14C8.37825 14 9.49992 12.8783 9.49992 11.5C9.49992 10.1217 8.37825 8.99999 6.99992 8.99999ZM11.9999 14.8333C11.9999 15.2933 11.6266 15.6667 11.1666 15.6667H2.83325C2.37325 15.6667 1.99992 15.2933 1.99992 14.8333V8.16666C1.99992 7.70666 2.37325 7.33332 2.83325 7.33332H3.66659H5.33325H8.66659H10.3333H11.1666C11.6266 7.33332 11.9999 7.70666 11.9999 8.16666V14.8333ZM5.33325 4.09249C5.33325 3.12249 6.08075 2.33332 6.99992 2.33332C7.91909 2.33332 8.66658 3.12249 8.66658 4.09249V5.66666H5.33325V4.09249ZM11.1666 5.66666H10.3333V4.09249C10.3333 2.20416 8.83825 0.666656 6.99992 0.666656C5.16158 0.666656 3.66658 2.20416 3.66658 4.09249V5.66666H2.83325C1.45492 5.66666 0.333252 6.78832 0.333252 8.16666V14.8333C0.333252 16.2117 1.45492 17.3333 2.83325 17.3333H11.1666C12.5449 17.3333 13.6666 16.2117 13.6666 14.8333V8.16666C13.6666 6.78832 12.5449 5.66666 11.1666 5.66666Z"
      fill="url(#paint0_linear_126_5066)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_126_5066"
        x1="3.20505"
        y1="2.84614"
        x2="13.3862"
        y2="13.3182"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
