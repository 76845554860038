import React from "react";

const ArrowDown = () => {
  const id = "loadericon_" + Math.random().toString(16).slice(2);
  const url = "url(#" + id + ")";

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16673 8.33291C4.16673 8.14458 4.23006 7.95541 4.36006 7.79958C4.65423 7.44625 5.18006 7.39875 5.53339 7.69291L10.0092 11.4229L14.4776 7.82708C14.8359 7.53875 15.3609 7.59541 15.6492 7.95375C15.9376 8.31291 15.8809 8.83708 15.5226 9.12625L10.5226 13.1496C10.2134 13.3979 9.77173 13.3946 9.46673 13.1404L4.46673 8.97375C4.26923 8.80875 4.16673 8.57208 4.16673 8.33291Z"
        fill={url}
      />
      <mask id="mask0_601_5285" maskUnits="userSpaceOnUse" x="4" y="7" width="12" height="7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.16673 8.33291C4.16673 8.14458 4.23006 7.95541 4.36006 7.79958C4.65423 7.44625 5.18006 7.39875 5.53339 7.69291L10.0092 11.4229L14.4776 7.82708C14.8359 7.53875 15.3609 7.59541 15.6492 7.95375C15.9376 8.31291 15.8809 8.83708 15.5226 9.12625L10.5226 13.1496C10.2134 13.3979 9.77173 13.3946 9.46673 13.1404L4.46673 8.97375C4.26923 8.80875 4.16673 8.57208 4.16673 8.33291Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_601_5285)"></g>
      <defs>
        <linearGradient id={id} x1="14.3076" y1="8.75644" x2="10.1378" y2="15.2426" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0063B4" />
          <stop offset="1" stopColor="#009AF7" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ArrowDown;
