import React, { useContext } from "react";
import { RefObject, useEffect, useRef, useState } from "react";
import { VideoEditorContext } from "../App";

const INTERVALS = 13;

export const convertToHHMMSS = (val: number) => {
  const secNum = parseInt(val.toString(), 10);
  let hours: string | number = Math.floor(secNum / 3600);
  let minutes: string | number = Math.floor((secNum - hours * 3600) / 60);
  let seconds: string | number = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  let time;

  if (hours === "00") {
    time = minutes + ":" + seconds;
  } else {
    time = hours + ":" + minutes + ":" + seconds;
  }

  return time;
};

interface Props {
  videoSrc: string;
}

export const useVideoTrim = ({ videoSrc }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState(false);
  const [value, setValue] = useState<number[]>([0, 0]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [previewRefs, setPreviewRefs] = useState<RefObject<HTMLCanvasElement>[]>([]);
  const sliderWidth = 770;

  const right = (value[0] / videoDuration) * 100;
  const left = ((videoDuration - value[1]) / videoDuration) * 100;
  const cursorPosition = (currentTime / videoDuration) * 100;
  const intervalDuration = videoDuration / INTERVALS;
  const intervals = Array.from({ length: INTERVALS }, (_, index) => index * intervalDuration).slice(0, -2);

  const { currentScene, handleBackgroundVideoTime } = useContext(VideoEditorContext);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    handleBackgroundVideoTime(newValue as number[]);
  };

  const handlePlay = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.currentTime !== value[0]) {
        videoElement.currentTime = value[0];
      }

      videoElement.play();
      setPlaying(true);
    }
  };

  const handlePause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.pause();
      setPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTimeValueSeconds = videoRef.current.currentTime;
      setCurrentTime(currentTimeValueSeconds);

      const videoElement = videoRef.current;
      if (videoElement.currentTime >= value[1]) {
        if (playing) {
          handlePlay();
        } else {
          videoElement.currentTime = value[0];
        }
      }

      if (videoElement.currentTime <= value[0]) {
        videoElement.currentTime = value[0];
      }
    }
  };

  useEffect(() => {
    if (videoRef && videoRef.current) {
      const videoElement = videoRef.current;
      videoElement.onloadedmetadata = () => {
        setVideoDuration(videoElement.duration);

        if (currentScene.startBackgroundVideoTime && currentScene.endBackgroundVideoTime) {
          setValue([currentScene.startBackgroundVideoTime, currentScene.endBackgroundVideoTime]);
        } else {
          setValue([0, videoElement.duration]);
        }

        const numPreviews = Math.ceil(sliderWidth / (70 * (16 / 9)));

        const drawTimeline = async () => {
          for (let i = 0; i < previewRefs.length; i++) {
            const seekedPromise = new Promise<void>((resolve) => {
              const onSeeked = () => {
                const ref = previewRefs[i];
                const ctx = ref.current?.getContext("2d");
                ctx?.drawImage(videoElement, 0, 0, ctx.canvas.width, ctx.canvas.height);
                videoElement.removeEventListener("seeked", onSeeked);
                resolve();
              };
              videoElement.addEventListener("seeked", onSeeked);
            });

            videoElement.currentTime = (i / numPreviews) * videoElement.duration;

            await seekedPromise;
          }
        };

        requestAnimationFrame(drawTimeline);
      };
    }
  }, [videoSrc, videoRef, previewRefs, sliderWidth]);

  useEffect(() => {
    setPreviewRefs(
      Array.from(
        {
          length: Math.ceil(sliderWidth / (70 * (16 / 9))),
        },
        () => React.createRef<HTMLCanvasElement>(),
      ),
    );
  }, [sliderWidth, setPreviewRefs]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      setPlaying(false);
    }
  }, [value]);

  return {
    currentTime,
    videoRef,
    previewRefs,
    playing,
    value,
    right,
    left,
    videoDuration,
    cursorPosition,
    intervals,
    handleChange,
    convertToHHMMSS,
    handlePlay,
    handlePause,
    handleTimeUpdate,
    setCurrentTime,
  };
};
