export const PrevIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 15.9855L10.678 12.0025L15.5 8.09146V15.9855ZM16.45 6.20146C15.716 5.85646 14.849 5.95846 14.241 6.46146L9.141 10.6735C9.088 10.7175 9.047 10.7695 9 10.8185V7.00046C9 6.44746 8.552 6.00046 8 6.00046C7.448 6.00046 7 6.44746 7 7.00046V17.0005C7 17.5525 7.448 18.0005 8 18.0005C8.552 18.0005 9 17.5525 9 17.0005V13.1815C9.047 13.2305 9.088 13.2825 9.142 13.3265L14.241 17.5405C14.609 17.8435 15.073 18.0015 15.544 18.0015C15.851 18.0015 16.161 17.9345 16.45 17.7985C17.098 17.4935 17.5 16.8855 17.5 16.2135V7.78746C17.5 7.11446 17.098 6.50646 16.45 6.20146Z"
      fill="url(#paint0_linear_493_11097)"
    />
    <mask id="mask0_493_11097" maskUnits="userSpaceOnUse" x="7" y="5" width="11" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 15.9855L10.678 12.0025L15.5 8.09146V15.9855ZM16.45 6.20146C15.716 5.85646 14.849 5.95846 14.241 6.46146L9.141 10.6735C9.088 10.7175 9.047 10.7695 9 10.8185V7.00046C9 6.44746 8.552 6.00046 8 6.00046C7.448 6.00046 7 6.44746 7 7.00046V17.0005C7 17.5525 7.448 18.0005 8 18.0005C8.552 18.0005 9 17.5525 9 17.0005V13.1815C9.047 13.2305 9.088 13.2825 9.142 13.3265L14.241 17.5405C14.609 17.8435 15.073 18.0015 15.544 18.0015C15.851 18.0015 16.161 17.9345 16.45 17.7985C17.098 17.4935 17.5 16.8855 17.5 16.2135V7.78746C17.5 7.11446 17.098 6.50646 16.45 6.20146Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_11097)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_493_11097"
        x1="9.26154"
        y1="7.56857"
        x2="16.5459"
        y2="15.7619"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
