export const FacebookIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 2.32124C14 1.08808 12.9119 0 11.6788 0H2.32124C1.08808 0 0 1.08808 0 2.32124V11.6787C0 12.9119 1.08808 14 2.32124 14H7.03627V8.70466H5.29534V6.38342H7.03627V5.44041C7.03627 3.84456 8.19689 2.46632 9.64767 2.46632H11.5337V4.78756H9.64767C9.43005 4.78756 9.21243 5.00518 9.21243 5.44041V6.38342H11.5337V8.70466H9.21243V14H11.6788C12.9119 14 14 12.9119 14 11.6787V2.32124Z"
      fill="url(#paint0_linear_2325_12316)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2325_12316"
        x1="3.01539"
        y1="1.83077"
        x2="11.3077"
        y2="12.4923"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
