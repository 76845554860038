import {
  AtsLogoWhite,
  CocaColaLogoWhite,
  NvidiaLogoWhite,
  TataLogoWhite,
  YahooLogoWhite,
} from "../../components/Icons/Icons";
import { IExternalLinks } from "../../lib/routeUtils";

export const brands = [
  {
    key: "TSC",
    logo: <TataLogoWhite />,
    path: IExternalLinks.tcs,
  },
  {
    key: "Yahoo",
    logo: <YahooLogoWhite />,
    path: IExternalLinks.yahooInc,
  },
  {
    key: "Nvidia",
    logo: <NvidiaLogoWhite />,
    path: IExternalLinks.nVidia,
  },
  {
    key: "CocaCola",
    logo: <CocaColaLogoWhite />,
    path: IExternalLinks.cocaCola,
  },
  {
    key: "ATS",
    logo: <AtsLogoWhite />,
    path: IExternalLinks.ats,
  },
];
