export const Eraser = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.75 11H10.75" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1.70499 8.79011L3.45999 10.5451C4.04499 11.1301 4.99999 11.1301 5.57999 10.5451L10.795 5.33011C11.38 4.74511 11.38 3.7901 10.795 3.2101L9.04001 1.45511C8.45501 0.870107 7.50001 0.870107 6.92001 1.45511L1.70499 6.6701C1.11999 7.2501 1.11999 8.20511 1.70499 8.79011Z"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.81006 4.56494L7.68506 8.43994"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M2.01001 8.83L4.83499 6" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3.42004 10.245L6.25003 7.41504"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
