import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

declare global {
  interface Window {
    Beacon: any;
  }
}

interface Props {
  children: ReactElement;
}

const BeaconProvider = ({ children }: Props) => {
  const location = useLocation();
  const scriptSrc = "https://beacon-v2.helpscout.net";
  const beaconContainerId = "beacon-container";

  useEffect(() => {
    const allowedPaths = ["/ai-images", "/ai-humans"];

    const isAllowedPath = allowedPaths.some((path) => location.pathname.includes(path));

    if (!isAllowedPath) {
      const beaconContainer = document.getElementById(beaconContainerId);
      if (beaconContainer) {
        beaconContainer.parentNode?.removeChild(beaconContainer);
      }

      const existingScripts = document.querySelectorAll(`script[src*='${scriptSrc}']`);
      existingScripts.forEach((script) => {
        script.parentNode?.removeChild(script);
      });

      return;
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = scriptSrc;

    document.head.appendChild(script);

    window.Beacon = function (t: any, n: any, a: any) {
      window.Beacon.readyQueue.push({ method: t, options: n, data: a });
    };
    window.Beacon.readyQueue = [];
    window.Beacon("init", "bd6fa3b8-d915-4178-bf83-0fe9c780c145");

    let beaconContainer = document.getElementById(beaconContainerId);
    if (!beaconContainer) {
      beaconContainer = document.createElement("div");
      beaconContainer.id = beaconContainerId;
      document.body.appendChild(beaconContainer);
    }
  }, [location]);

  return children;
};

export default BeaconProvider;
