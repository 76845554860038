import { useContext } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import TextArea from "../../../components/TextArea/TextArea";
import { AIImagesContext } from "../../../App";
import { AIImagesSidebarTabs } from "../../../hooks/useAiImages";
import { NotEnoughCredits } from "../../../types/notEnoughCredits";
import { getProfile } from "../../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { Brush, GalleryAdd, SearchbarCloseIcon, SearchbarFlash } from "../../../components/Icons/Icons";
import {
  getGeneratedImagesLoading,
  getGeneratedVideosLoading,
  getUploadImageToVisualGalleryLoading,
  getUpscaleImageLoading,
  getUpscaleVideoLoading,
} from "../../../redux/reducers/imagesReducer";
import Select from "../../../components/Select/Select";
import { extendVideoOprions } from "../../../mocks/aiImages";

const Visualise = () => {
  const dispatch = useDispatch();
  const generatedImagesLoading = useSelector(getGeneratedImagesLoading);
  const upscaleImageLoading = useSelector(getUpscaleImageLoading);
  const generatedVideosLoading = useSelector(getGeneratedVideosLoading);
  const upscaleVideoLoading = useSelector(getUpscaleVideoLoading);
  const uploadImageLoading = useSelector(getUploadImageToVisualGalleryLoading);
  const { aiImagesAllowed, aiImagesUsed, aiVideosAllowed, aiVideosUsed } = useSelector(getProfile);
  const { extendVideo, setExtendVideo } = useContext(AIImagesContext);

  const isLoading =
    generatedImagesLoading ||
    generatedVideosLoading ||
    uploadImageLoading ||
    upscaleImageLoading ||
    upscaleVideoLoading;

  const { text, setText, handleOpenUpload, handleOpenRemoveArea, handleVisualise, activeTab, uploadedImage } =
    useContext(AIImagesContext);

  const handleUploadClick = () => {
    if (isLoading) return;
    handleOpenUpload();
  };

  const handleBrushClick = () => {
    if (isLoading) return;
    handleOpenRemoveArea();
  };

  const handleExtendChange = (value: string) => {
    setExtendVideo(value);
  };

  const notEnoughCreditsPopupOpen = (apiAllowed: number, apiLeft: number) => {
    const apiUsed = apiAllowed - apiLeft;
    const progress = (100 * (apiAllowed - apiUsed)) / apiAllowed;

    const prefilled: NotEnoughCredits = {
      title: "You Don’t Have Enough Credits",
      progressBar: { title: "Credits left", timeLeft: `${apiLeft} out of ${apiAllowed}`, progress },
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const isTextareaDisabled = (activeTab === AIImagesSidebarTabs.Text2Video && !!uploadedImage) || isLoading;
  const isInpaintingDisable = isLoading || !text.length;
  const isNotEnoughCredits =
    activeTab === AIImagesSidebarTabs.Text2Video ? aiVideosUsed >= aiVideosAllowed : aiImagesUsed >= aiImagesAllowed;
  const apiAllowed = activeTab === AIImagesSidebarTabs.Text2Image ? aiImagesAllowed || 0 : aiVideosAllowed || 0;
  const apiLeft = apiAllowed - (activeTab === AIImagesSidebarTabs.Text2Image ? aiImagesUsed || 0 : aiVideosUsed || 0);

  return (
    <>
      <OuterWrapper>
        <Container>
          <TextAreaWrapper>
            <TextArea
              placeholder="Describe an image you want to visualise"
              rows={1}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
              disabled={isTextareaDisabled}
            />
            <IconsWrapper>
              {text && (
                <ButtonIcon onClick={isLoading ? () => null : () => setText("")} disabled={isLoading}>
                  <SearchbarCloseIcon />
                </ButtonIcon>
              )}
              <ButtonIcon onClick={handleUploadClick} disabled={isLoading}>
                <GalleryAdd />
              </ButtonIcon>
            </IconsWrapper>
            {activeTab === AIImagesSidebarTabs.Text2Video && (
              <SelectWrapper>
                <Select
                  value={extendVideo}
                  onChange={handleExtendChange}
                  optionsList={extendVideoOprions}
                  defaultValueText={extendVideo}
                  variant="popup"
                  // disabled={isLoading || !isUltraHDAIImageEnabled || isFreeUser}
                  // disabled={false}
                />
              </SelectWrapper>
            )}
          </TextAreaWrapper>
          <Button
            onClick={
              isNotEnoughCredits ? () => notEnoughCreditsPopupOpen(apiAllowed, apiLeft) : () => handleVisualise()
            }
            disabled={isLoading}
          >
            <SearchbarFlash />
            <span>Generate</span>
          </Button>
        </Container>
      </OuterWrapper>
    </>
  );
};

const OuterWrapper = styled.div`
  padding-bottom: 30px;
  width: 100%;
`;

const Container = styled.label`
  display: flex;
`;

const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px 0px 0px 30px;
  background: #292937;
  border: 1px solid #686d783b;
  padding: 21.5px 24px;
  color: #ffffff;

  textarea {
    color: #ffffff;
    font-family: Mulish;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 0px;

    ::placeholder {
      color: #ababb9;
      font-family: Mulish;
      font-size: 13px;
      font-weight: 500;
      line-height: 2;
      display: flex;
    }
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21.5px 34px;
  gap: 4px;
  background: #0063b4;
  color: #ffffff;
  border-radius: 0px 30px 30px 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.4px;

  gap: 4px;

  &::disabled {
    background: red;
  }

  & svg > path {
    fill: #ffffff;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 23px;
  padding-left: 10px;

  & svg > path {
    stroke: #ffffffab;
  }
`;

const ButtonIcon = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  display: flex;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

const SelectWrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: -35px;
  border-top: 1px solid #0063b4;

  p {
    color: #ffffff;
  }

  div {
    display: flex;
    justify-content: space-between;
    height: 100%;

    label {
      display: none;
    }

    & > div:nth-child(2) {
      position: relative;
      width: 148px;
      height: 34px;
      box-shadow: none;
      background: #292937;
      border-radius: 0px;
    }

    & > div:nth-child(3) {
      width: 148px;
      height: auto;
      max-height: 150px;
      border-radius: 0px;
      position: absolute;
      right: 0px;
      top: 34px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      z-index: 25;
    }

    & svg > path {
      fill: #ffffff;
    }
  }
`;

export default Visualise;
