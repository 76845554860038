export const PopupUploadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.83325 10.5C8.83325 10.5212 8.83404 10.5422 8.83559 10.563L6.8128 9.14001C6.43613 8.87501 5.91697 8.96585 5.65197 9.34251C5.38697 9.71835 5.4778 10.2383 5.85447 10.5033L9.1878 12.8483C9.33197 12.9492 9.49947 13 9.66697 13C9.8428 13 10.0195 12.9442 10.167 12.8333L13.5003 10.3333C13.8686 10.0575 13.9436 9.53501 13.667 9.16668C13.3911 8.79835 12.8686 8.72251 12.5003 9.00001L10.4999 10.5002V10.5V3.83333C10.4999 3.37333 10.1274 3 9.66658 3C9.20575 3 8.83325 3.37333 8.83325 3.83333V10.5ZM4.66679 15.5V14.6659C4.66679 14.2075 4.29179 13.8325 3.83346 13.8325C3.37512 13.8325 3.00012 14.2075 3.00012 14.6659V16.319C3.00004 16.3238 3 16.3286 3 16.3333C3 16.7917 3.375 17.1667 3.83333 17.1667H15.5C15.8502 17.1667 16.1517 16.9478 16.2745 16.6401C16.3127 16.5448 16.3337 16.441 16.3337 16.3325V14.6659C16.3337 14.2075 15.9587 13.8325 15.5003 13.8325C15.042 13.8325 14.667 14.2075 14.667 14.6659V15.5H4.66679Z"
      fill="white"
    />
  </svg>
);
