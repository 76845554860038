import { BrowserRouter, Route, Routes } from "react-router-dom";

import { pages } from "./lib/routeUtils";

import HomePage from "./modules/Home";
import SignIn from "./modules/SignIn";
import SignUp from "./modules/SignUp";
import NavigateSetter from "./lib/NavigateSetter";
import Settings from "./modules/Settings";
import MyStudio from "./modules/MyStudio";
import RecoverPassword from "./modules/RecoverPassword";

import AppDialogs from "./components/AppPopups/AppPopups";
import ResetPassword from "./modules/ResetPassword";
import ActorPage from "./modules/Actors/ActorPage";
import ApiAccess from "./modules/ApiAccess";
import PricingPage from "./modules/Pricing/PricingPage";
import AIImagesPage from "./modules/AIImages";
import FreeTrial from "./modules/FreeTrial";
import MyPlan from "./modules/MyPlan";
import SignUpAppSumo from "./modules/SignUpAppSumo";
import AIHumansModule from "./modules/AIHumans/AIHumansPage";
import VideoPage from "./modules/VideoPage/VideoPage";
import PricingSubscriptions from "./modules/PricingSubscriptions/PricingSubscriptions";
import TrainingPage from "./modules/Training/TrainingPage";
import AssetsPage from "./modules/Assets/AssetsPage";
import VideoTranslationPage from "./modules/VideoTranslation/";
import HistoryPage from "./modules/History";
import PricingPage2 from "./modules/PricingPage2/PricingPage2";
import ActivateEmail from "./modules/ActivateEmail/ActivateEmail";
import TransparentPricingPage from "./modules/TransparentPricing/TransparentPricingPage";
import BeaconProvider from "./providers/BeaconProvider";
import BotsonicWidget from "./providers/BiosonicProvider";
import NotFoundPage from "./modules/NotFound";
import CelloProvider from "./providers/СelloProvider";
import { useSelector } from "react-redux";
import { getCelloToken } from "./redux/reducers/authReducer";

const Router = () => {
  const token = useSelector(getCelloToken);

  return (
    <BrowserRouter>
      {token && <CelloProvider />}
      <BotsonicWidget>
        <BeaconProvider>
          <Routes>
            <Route path={pages.main()} element={<HomePage />} />
            <Route path={pages.myPlan()} element={<MyPlan />} />
            <Route path={pages.actors()} element={<ActorPage />} />
            <Route path="/activate_appsumo/:token" element={<SignUpAppSumo />} />
            <Route path="/actors?projectId=:id" element={<ActorPage />} />
            <Route path="/actors?audioProjectId=:id" element={<ActorPage />} />
            <Route path="/actors?actor=:name" element={<ActorPage />} />
            <Route path="/actors?tab=:tab" element={<ActorPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup?token=:token" element={<SignUp />} />
            <Route path="/activate-email" element={<ActivateEmail />} />
            <Route path={pages.signIn()} element={<SignIn />} />
            <Route path={pages.recoverPassword()} element={<RecoverPassword />} />
            <Route path={pages.resetPassword()} element={<ResetPassword />} />
            <Route path={pages.apiAccess()} element={<ApiAccess />} />
            <Route path={pages.pricing()} element={<PricingPage />} />
            <Route path={pages.pricingSubscriptions()} element={<PricingPage2 />} />
            <Route path="/pricing/subscriptions?plan=:name" element={<PricingSubscriptions />} />
            <Route path={pages.settings()} element={<Settings />} />
            <Route path={pages.myProjects()} element={<MyStudio />} />
            <Route path={pages.aiHumans()} element={<AIHumansModule />} />
            <Route path="/ai-humans?actor=:name" element={<AIHumansModule />} />
            <Route path="/ai-humans?template=:id" element={<AIHumansModule />} />
            <Route path={pages.aiImages()} element={<AIImagesPage />} />
            <Route path="/free-trial/:page?" element={<FreeTrial />} />
            <Route path="/video/:id?" element={<VideoPage />} />
            <Route path={pages.training()} element={<TrainingPage />} />
            <Route path={pages.assets()} element={<AssetsPage />} />
            <Route path={pages.videoTranslation()} element={<VideoTranslationPage />} />
            <Route path="/video-translation/:id" element={<VideoTranslationPage />} />
            <Route path={pages.transparentPricing()} element={<TransparentPricingPage />} />
            <Route path="/history" element={<HistoryPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BeaconProvider>
      </BotsonicWidget>
      <NavigateSetter />
      <AppDialogs />
    </BrowserRouter>
  );
};

export default Router;
