import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  GetProjectListServerProps,
  clearProjectList,
  getProjectListRefreshServer,
  getProjectListServer,
} from "../redux/actions/projectAction";
import {
  getCurrentPageGalleryImages,
  getCurrentPageGalleryVideos,
  getGalleryImages,
  getGalleryImagesLoading,
  getGalleryVideos,
  getGalleryVideosLoading,
  getHasMoreGalleryImages,
  getHasMoreGalleryVideos,
} from "../redux/reducers/imagesReducer";
import {
  getCurrentPageProjects,
  getHasMoreProjects,
  getIsStatusLoading,
  getProjectList,
  getProjectListLoading,
} from "../redux/reducers/projectReducer";
import { AIHumansStatus, ProjectsDisplayMode } from "../modules/MyStudio";
import {
  clearGalleryImages,
  clearGalleryVideos,
  getAllGalleryImagesServer,
  getAllGalleryVideosServer,
} from "../redux/actions/imagesActions";

interface Props {
  options: {
    data: {
      id: string;
      label: string;
      value: AIHumansStatus;
    }[];
  };
  aiHumansStatusOption: {
    value: AIHumansStatus;
    title: string;
  }[];

  projectDisplayModeTabs: {
    value: ProjectsDisplayMode;
    title: string;
  }[];
}

export const useMyStudio = ({ options, aiHumansStatusOption, projectDisplayModeTabs }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const projectList = useSelector(getProjectList);
  const projectListLoading = useSelector(getProjectListLoading);
  const hasMore = useSelector(getHasMoreProjects);
  const currentPage = useSelector(getCurrentPageProjects);
  const isStatusLoading = useSelector(getIsStatusLoading);

  const galleryImages = useSelector(getGalleryImages);
  const galleryImagesLoading = useSelector(getGalleryImagesLoading);
  const galleryImagesHasMore = useSelector(getHasMoreGalleryImages);
  const galleryImagesCurrentPage = useSelector(getCurrentPageGalleryImages);

  const galleryVideos = useSelector(getGalleryVideos);
  const galleryVideosLoading = useSelector(getGalleryVideosLoading);
  const galleryVideosHasMore = useSelector(getHasMoreGalleryVideos);
  const galleryVideosCurrentPage = useSelector(getCurrentPageGalleryVideos);

  const [currentSelectValue, setCurrentSelectValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<AIHumansStatus | null>(null);
  const [activeDisplayMode, setActiveDisplayMode] = useState(projectDisplayModeTabs[0].value);
  const [activeProject, setActiveProject] = useState<number | null>();
  const [activeStatus, setActiveStatus] = useState<AIHumansStatus | null>(null);
  const [activeProjectTypeId, setActiveProjectTypeId] = useState<number>();

  const activeProjectType = {
    [ProjectsDisplayMode.aiVoices]: 1,
    [ProjectsDisplayMode.aiHuman]: 2,
    [ProjectsDisplayMode.aiImages]: 3,
    [ProjectsDisplayMode.aiVideos]: 4,
  };

  const featuresList = {
    [ProjectsDisplayMode.aiVoices]: {
      list: projectList,
      isLoading: projectListLoading,
      hasMore,
      currentPage,
    },
    [ProjectsDisplayMode.aiHuman]: {
      list: projectList,
      isLoading: projectListLoading,
      hasMore,
      currentPage,
    },
    [ProjectsDisplayMode.aiImages]: {
      list: galleryImages,
      isLoading: galleryImagesLoading,
      hasMore: galleryImagesHasMore,
      currentPage: galleryImagesCurrentPage,
    },
    [ProjectsDisplayMode.aiVideos]: {
      list: galleryVideos,
      isLoading: galleryVideosLoading,
      hasMore: galleryVideosHasMore,
      currentPage: galleryVideosCurrentPage,
    },
  };

  const handleActiveStatus = (status: AIHumansStatus) => {
    setActiveStatus(status);
  };

  const handleActiveDisplayMode = (tab: ProjectsDisplayMode) => {
    setActiveDisplayMode(tab);
    const params = new URLSearchParams(location.search);
    params.set("filter", tab);

    navigate(`?${params.toString()}`);
    setActiveProjectTypeId(activeProjectType[tab]);
  };

  const onChangeSelect = (newValue: AIHumansStatus) => {
    setSelectedStatus(newValue);
  };

  const handleActiveProject = (e: any, id?: number) => {
    e.preventDefault();

    setActiveProject(id);
  };

  const onChangeCurrentValue = (val: string) => {
    setCurrentSelectValue(val);
  };

  const getProjectListFunc = ({ currentPage, activeProjectTypeId }: any) => {
    let data: GetProjectListServerProps = {
      keyword: "",
      pageNumber: currentPage + 1,
      pageSize: 40,
      projectTypeId: activeProjectTypeId,
    };

    if (activeProjectTypeId === activeProjectType[ProjectsDisplayMode.aiHuman]) {
      data = {
        ...data,
        status: selectedStatus,
        isStatusLoading: true,
      };
    }

    dispatch(getProjectListServer(data));
  };

  const getGalleryImagesFunc = (galleryImagesCurrentPage: number) => {
    dispatch(getAllGalleryImagesServer({ keyword: "", pageNumber: galleryImagesCurrentPage + 1, pageSize: 40 }));
  };

  const getGalleryVideosFunc = (galleryVideosCurrentPage: number) => {
    dispatch(getAllGalleryVideosServer({ keyword: "", pageNumber: galleryVideosCurrentPage + 1, pageSize: 40 }));
  };

  useEffect(() => {
    if (activeDisplayMode === ProjectsDisplayMode.aiVoices || activeDisplayMode === ProjectsDisplayMode.aiHuman) {
      dispatch(clearProjectList());

      if (activeProjectTypeId) {
        getProjectListFunc({ currentPage: 0, activeProjectTypeId });
      }
    } else if (activeDisplayMode === ProjectsDisplayMode.aiImages) {
      dispatch(clearGalleryImages());

      getGalleryImagesFunc(0);
    } else {
      dispatch(clearGalleryVideos());

      getGalleryVideosFunc(0);
    }
  }, [activeDisplayMode, selectedStatus, activeProjectTypeId]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (activeDisplayMode === ProjectsDisplayMode.aiHuman && selectedStatus === AIHumansStatus.All) {
      intervalId = setInterval(() => dispatch(getProjectListRefreshServer()), 10000);

      return () => {
        clearInterval(intervalId);
      };
    } else {
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [activeDisplayMode, selectedStatus]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("filter") || "";
    setActiveDisplayMode(filterValue as ProjectsDisplayMode);
    setActiveProjectTypeId(activeProjectType[filterValue as ProjectsDisplayMode]);

    if (!filterValue) {
      const params = new URLSearchParams(location.search);
      params.set("filter", "ai-humans");
      navigate(`?${params.toString()}`);
    }
  }, [location]);

  return {
    featuresList: featuresList[activeDisplayMode] ?? featuresList[ProjectsDisplayMode.aiHuman],
    isStatusLoading,
    currentSelectValue,
    activeProject,
    selectedStatus,
    activeStatus,
    activeProjectTypeId,
    handleActiveStatus,
    onChangeSelect,
    handleActiveProject,
    onChangeCurrentValue,
    getProjectListFunc,
    getGalleryImagesFunc,
    getGalleryVideosFunc,
    setActiveProject,
    handleActiveDisplayMode,
    activeDisplayMode,
  };
};
