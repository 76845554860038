import React, { FC } from "react";
import styled, { css } from "styled-components";

interface ToggleProps {
  isOn: boolean;
  onToggle: () => void;
  disable?: boolean;
}

const Toggle: FC<ToggleProps> = ({ isOn, onToggle, disable }) => {
  return (
    <StyledToggle isOn={isOn} onClick={onToggle} disable={disable}>
      <StyledThumb />
    </StyledToggle>
  );
};

const StyledToggle = styled.div<{ isOn: boolean; disable?: boolean }>`
  width: 26px;
  height: 16px;
  background: ${({ isOn, theme }) => (isOn ? "#3182CE" : theme.switcher)};
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: ${({ isOn }) => (isOn ? "flex-end" : "flex-start")};
  transition: background-color 0.2s ease;
  cursor: pointer;

  ${({ disable }) =>
    disable &&
    css`
      pointer-events: none;
      cursor: none;
      opacity: 0.5;
    `}
`;

const StyledThumb = styled.div`
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  margin: 2px;
`;

export default Toggle;
