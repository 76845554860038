import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ButtonTabs from "../../PricingSubscriptions/components/ButtonTabs";
import Toggle from "../../PricingSubscriptions/components/Toggle";
import Button from "../../../components/Button/Button";
import { CheckIcon, InfoGreyIcon, InfoIcon } from "../../../components/Icons/Icons";
import { ChangeSubscription, UserPlan, UserPlanPeriod } from "../../../types/plans";
import { useDispatch } from "react-redux";
import { updateSubscriptionServer } from "../../../redux/actions/plansActions";
import { useSelector } from "react-redux";
import { getLoadingPlans } from "../../../redux/reducers/plansReducer";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { Link } from "react-router-dom";
import { pages } from "../../../lib/routeUtils";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { numberDecorator } from "../../../lib/numberDecorator";
import Tooltip from "../../PricingSubscriptions/components/Tooltip";

interface Props {
  subscriptions: ChangeSubscription[];
  userPLans: UserPlan[];
}

const BundlePackageCard = ({ subscriptions, userPLans }: Props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [plans, setPlans] = useState<Record<string, ChangeSubscription>>({});
  const [currentPlan, setCurrentPlan] = useState<ChangeSubscription>();

  const loadingPlans = useSelector(getLoadingPlans);

  const dispatch = useDispatch();

  // const handleToggleClick = () => {
  //   setIsToggle(!isToggle);
  // };

  const handleChangeTab = (value: number) => {
    setActiveTab(value);
  };

  useEffect(() => {
    if (!subscriptions) return;
    const monthly = subscriptions.find(
      (item) => item.title.toLowerCase().includes("ai bundle") && item.duration === UserPlanPeriod.monthly,
    );
    const yearly = subscriptions.find(
      (item) => item.title.toLowerCase().includes("ai bundle") && item.duration === UserPlanPeriod.yearly,
    );
    if (!monthly || !yearly) return;
    setPlans({ monthly, yearly });
  }, [subscriptions]);

  useEffect(() => {
    if (!Object.keys(plans).length) return;
    setCurrentPlan(plans?.monthly);
  }, [plans]);

  useEffect(() => {
    if (activeTab === 1) {
      return setCurrentPlan(plans?.monthly);
    } else {
      return setCurrentPlan(plans?.yearly);
    }
  }, [activeTab]);

  const activeUserPlans = userPLans.filter((plan) => !plan.isCancelled);

  const handleChangeSubscription = () => {
    if (!currentPlan || !activeUserPlans.length) return;
    dispatch(
      updatePopup({
        popup: Popups.changeSubscriptionPopup,
        status: true,
        prefilled: {
          existingUserLicenseId: activeUserPlans[0].userLicenseID,
          newPlanId: currentPlan.planID,
        },
      }),
    );
  };

  return (
    <Card>
      <Top>
        <Header>
          <Title>Upgrade to {currentPlan?.title}</Title>
          <Link to={`${pages.pricingSubscriptions()}#bundle`}>
            <ViewPlanText>View plan</ViewPlanText>
          </Link>
        </Header>
        <ButtonTabs
          tabs={[
            { id: 1, label: "Monthly" },
            { id: 2, label: "Yearly", discount: 20 },
          ]}
          activeTab={activeTab}
          onChange={handleChangeTab}
        />
        <Price>
          $ {numberDecorator(currentPlan?.duration === UserPlanPeriod.monthly ? 99 : 69)}
          <PriceDuration>/{"mo"}</PriceDuration>
        </Price>
        {/* <ToggleWrapper>
          <Toggle isOn={isToggle} onToggle={handleToggleClick} />
          <ToggleText>UP to 5 users</ToggleText>
          <TooltipWrapper>
            <InfoGreyIcon />
            <Tooltip title="5 users will be allowed to access the same user account" />
          </TooltipWrapper>
        </ToggleWrapper> */}
      </Top>
      {activeUserPlans.length ? (
        <Button
          text="Upgrade your plan"
          onClick={handleChangeSubscription}
          icon={currentPlan?.planID && loadingPlans.includes(currentPlan.planID) && <CircularProgress />}
        />
      ) : (
        <a href={currentPlan?.checkoutLink} target="_blank" rel="noreferrer">
          <Button text="Upgrade your plan" />
        </a>
      )}
      <ProsWrapper>
        {details.map(({ text }, index) => (
          <Pros key={index}>
            <CheckIcon />
            <ProsText>{text}</ProsText>
          </Pros>
        ))}
      </ProsWrapper>
    </Card>
  );
};

const StrongText = styled.span`
  font-weight: 700;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.mainCardShadowPricing};
  border-radius: 16px;
  padding: 40px;
  height: fit-content;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 600;
`;

const ViewPlanText = styled.div`
  min-width: 75px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Price = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: end;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  color: ${({ theme }) => theme.primaryText};

  @media (max-width: 768px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }
`;

const PriceDuration = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #6f7074;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const ToggleText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #6f7074;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  height: 16px;

  & .tooltip {
    max-width: 200px;
  }

  &:hover .tooltip {
    opacity: 1;
  }
`;

const ProsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const Pros = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;

  &:hover .tooltip {
    opacity: 1;
  }
`;

const ProsText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6f7074;
`;

const details = [
  {
    text: <StrongText>AI Synthesys Suite Unlimited Generations</StrongText>,
    tooltip:
      "Create without boundaries with our limitless AI Voice, AI Humans, & 250 image generation. Unlimited is subject to a fair usage soft limit which is 1 hour of generations per 24 hours. If your generations reaches the fair use limit, it will revert to Relax mode after 24 hours. Extensions and expedited options available upon request.",
  },
  {
    text: <StrongText>Standard Speed Generation</StrongText>,
    important: true,
    tooltip: "Enjoy a seamless creative process with standard speed generation—effortless and perfectly paced.",
  },
  {
    text: <StrongText>Voice cloning 5 Voices Pro</StrongText>,
    tooltip:
      "Diverse Voices, Infinite Narratives - Add depth and diversity to your creations with voice cloning for up to 5 voices, unlocking endless narrative possibilities. The cloned voices can be used across the entire Synthesys suite, including AI Voices and AI Humans.",
  },
  {
    text: "Commercial License",
    tooltip: "",
  },
];

export default BundlePackageCard;
