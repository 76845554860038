export const FrameCircle = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="3" fill="white" stroke="url(#paint0_linear_1012_10009)" strokeWidth="2" />
    <defs>
      <linearGradient
        id="paint0_linear_1012_10009"
        x1="2.86154"
        y1="2.52308"
        x2="5.23077"
        y2="5.56923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
