import React, { FC, useState, useEffect, useRef } from "react";
import styled from "styled-components";

interface AccordionDetailsProps {
  children: React.ReactNode;
  active?: boolean;
}

interface StyledAccordionProps {
  active?: boolean;
  maxHeight: number;
}

const AccordionDetails: FC<AccordionDetailsProps> = ({ children, active }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState<number>(typeof window !== "undefined" ? window.innerWidth : 0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (contentRef.current) {
        const height = contentRef.current.scrollHeight;
        setContentHeight(height);
      }
    });

    return () => clearTimeout(timer);
  }, [screenWidth, active]);

  return (
    <StyledAccordionDetails
      active={active}
      maxHeight={contentHeight}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <div ref={contentRef}>{children}</div>
    </StyledAccordionDetails>
  );
};

const StyledAccordionDetails = styled.div<StyledAccordionProps>`
  max-height: ${({ active, maxHeight }) => (active ? `${maxHeight}px` : 0)};
  overflow: hidden;
  transition: max-height 0.4s, padding-top 0.4s;
  cursor: default;
  padding-top: ${({ active }) => (active ? "6px" : "0")};
`;

export default AccordionDetails;
