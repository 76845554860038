import styled from "styled-components";
import { useSelector } from "react-redux";
import { useEffect, useState, useContext, ChangeEvent } from "react";

import Loader from "../../../components/Loader/Loader";
import TextArea from "../../../components/TextArea/TextArea";
import Button, { ButtonThemes } from "../../../components/Button/Button";
import { VideoTranslationContext } from "../../../App";
import { EditIcon } from "../../../components/Icons/EditIcon";
import { TranslationTypeTabsType } from "../../../types/videoTranslationContext";
import { getTranslatedText } from "../../../redux/reducers/videoTranslationReducer";

interface Props {
  translationTypeTab: TranslationTypeTabsType | null;
}

const TranslatedText = ({ translationTypeTab }: Props) => {
  const { data, isLoading } = useSelector(getTranslatedText);

  const [isEdit, setIsEdit] = useState(false);

  const { selectLanguage, uploadedVideo, textTranslated, setTextTranslated } = useContext(VideoTranslationContext);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTextTranslated(e.target.value);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setTextTranslated(data);
  };

  const handleSave = () => {
    if (!uploadedVideo || !selectLanguage) return;

    setIsEdit(false);
  };

  useEffect(() => {
    if (isLoading) setIsEdit(false);
  }, [isLoading]);

  useEffect(() => {
    setTextTranslated(data);
  }, [data]);

  if (!translationTypeTab || translationTypeTab !== TranslationTypeTabsType.TranslateVideo) {
    return null;
  }

  return (
    <Wrapper>
      <Header>
        <span>Translated text</span>
        {!isEdit ? (
          <Button
            disabled={isLoading}
            buttonTheme={ButtonThemes.Transparent}
            icon={<EditIcon />}
            text="Edit"
            onClick={() => setIsEdit(true)}
          />
        ) : (
          <ButtonWrapper>
            <Button buttonTheme={ButtonThemes.Transparent} text="Cancel" onClick={handleCancel} />
            <Button buttonTheme={ButtonThemes.Transparent} text="Save" onClick={handleSave} />
          </ButtonWrapper>
        )}
      </Header>
      {!isEdit ? (
        <TextWrapper isLoading={isLoading}>{isLoading ? <Loader width={3} /> : textTranslated}</TextWrapper>
      ) : (
        <TextAreaWrapper>
          <TextArea
            value={textTranslated}
            onChange={handleChange}
            rows={9}
            placeholder="Type your translation here..."
          />
        </TextAreaWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  width: 100%;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${({ theme }) => theme.primaryText};
  }

  & > button {
    width: auto;
    gap: 8px;

    svg > path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

const ButtonWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextWrapper = styled("div")<{ isLoading: boolean }>`
  display: flex;
  height: auto;
  max-height: 120px;
  overflow: hidden auto;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.greyText};

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d9d9d9;
  }

  ::-webkit-scrollbar-track {
    margin: 5px 0;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
        align-items: center;
        justify-content: center;
        overflow: hidden;
    `}
`;

const TextAreaWrapper = styled("div")`
  position: relative;
  display: flex;

  &:before {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    border: ${({ theme }) => theme.videoTranslationBorder};
    background-color: ${({ theme }) => theme.videoTranslationBackground};
    pointer-events: none;
    border-radius: 8px;
  }

  textarea {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.greyText};
    width: calc(100% - 8px);
    z-index: 1;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d9d9d9;
    }

    ::-webkit-scrollbar-track {
      margin: 5px 0;
    }
  }

  @media (max-width: 900px) {
    &:before {
      right: -8px;
      bottom: -8px;
    }

    textarea {
      width: 100%;
    }
  }
`;

export default TranslatedText;
