import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";

import AuthLayout from "../../layouts/AuthLayout";
import FormikField from "../../components/FormInput/FormikField";
import CircularProgress from "../../components/Icons/CircularProgress";
import {
  getIsSignInWithGoogleLoading,
  getIsSignUpLoading,
  getIsTokenUserLoading,
  getUser,
} from "../../redux/reducers/authReducer";
import { pages } from "../../lib/routeUtils";
import { FORM_TEXT } from "../../constants/Form";
import { Google } from "../../components/Icons/Google";
import { SignUpFormValues } from "../../forms/auth/signUp/types";
import { signUpSchema } from "../../forms/auth/signUp/validation";
import { getSignUpFields, initialValuesSignUp } from "../../forms/auth/signUp/form";
import {
  Oauth,
  SignInWithGoogleProps,
  SignUpProps,
  getTokenUser,
  signInWithGoogleServer,
  signUpServer,
} from "../../redux/actions/authActions";
import { LogoIcon } from "../../components/Icons/LogoIcon";
import { Twitter } from "../../components/Icons/Twitter";

const SignUp = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [route, setRoute] = useState<string>("");
  const [state, setState] = useState("");
  const [redirect, setRedirect] = useState("");
  const [celloCode, setCelloCode] = useState("");

  const user = useSelector(getUser);
  const isLoading = useSelector(getIsSignUpLoading);
  const getTokenUserLoading = useSelector(getIsTokenUserLoading);
  const signInWithGoogleLoading = useSelector(getIsSignInWithGoogleLoading);

  const [showPassword, setShowPassword] = useState<Record<string, any>>({
    password: false,
    confirmPassword: false,
  });
  const [checkBox, setCheckBox] = useState<boolean>(false);

  const isRedirectToChat = (redirectString: string) => redirectString.includes("chat.openai.com");

  const onSubmit = (values: SignUpFormValues) => {
    const isChatGPT = isRedirectToChat(redirect);

    if (!checkBox) {
      toast.error("You have not accepted the privacy policy");
      return;
    }

    if (!(values.confirmPassword === values.password)) {
      toast.error("Passwords are not identical");
      return;
    }

    let signUpParams: SignUpProps = {
      name: values.name,
      email: values.email,
      password: values.password,
      token: user.email ? route : "Trial",
      FromChatGPT: isChatGPT,
    };

    if (celloCode) {
      signUpParams = { ...signUpParams, cello_code: celloCode };
    }

    dispatch(signUpServer(signUpParams));
  };

  const handleShowPassword = (value: boolean, type: string) => {
    setShowPassword({ ...showPassword, [type]: value });
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      let signUpParams: SignInWithGoogleProps = {
        isSignUp: true,
        IdToken: tokenResponse.access_token,
        FromChatGPT: isRedirectToChat(redirect),
      };

      if (celloCode) {
        signUpParams = { ...signUpParams, cello_code: celloCode };
      }

      dispatch(signInWithGoogleServer(signUpParams));
    },
    onError: () => toast.error("Issue with Google provider. Try again later!"),
  });

  useEffect(() => {
    const redirectUri = searchParams.get("redirect_uri");
    const stateParam = searchParams.get("state");

    setRoute(searchParams.get("token") || "");
    setCelloCode(searchParams.get("ucc") || "");

    if (redirectUri) {
      const decodedRedirectUri = decodeURIComponent(redirectUri);
      setRedirect(decodedRedirectUri);
    }

    if (stateParam) {
      setState(stateParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (redirect && state) {
      dispatch(Oauth({ redirect_uri: redirect, state }));
    }
  }, [redirect, state]);

  useEffect(() => {
    if (route) {
      dispatch(getTokenUser(route));
    }
  }, [route]);

  useEffect(() => {
    if (user?.name) {
      const name = user?.name || "";
      const email = user?.email || "";
      setValues({ name, email, password: "", confirmPassword: "" });
    }
  }, [user]);

  useGoogleOneTapLogin({
    onSuccess: (tokenResponse) => {
      let signUpParams: SignInWithGoogleProps = {
        isSignUp: true,
        IdToken: tokenResponse.credential || "",
        FromChatGPT: isRedirectToChat(redirect),
      };

      if (celloCode) {
        signUpParams = { ...signUpParams, cello_code: celloCode };
      }

      dispatch(signInWithGoogleServer(signUpParams));
    },
    onError: () => toast.error("Issue with Google provider. Try again later!"),
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setValues,
    handleSubmit: handleFormikSubmit,
  } = useFormik({
    initialValues: initialValuesSignUp,
    validationSchema: signUpSchema,
    onSubmit,
  });

  return (
    <AuthLayout>
      <FormContent>
        <LogoWrapper>
          <LogoIcon />
        </LogoWrapper>
        <Wrapper>
          <FormTitle>{FORM_TEXT.register.title}</FormTitle>
          <FormMain onSubmit={handleFormikSubmit}>
            {getTokenUserLoading ? (
              <CircularProgressWrapper>
                <CircularProgress color="#009af7" />
              </CircularProgressWrapper>
            ) : (
              getSignUpFields.map((field) => (
                <FormikField
                  key={field.id}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  field={field}
                  values={values}
                  handleChange={handleChange}
                  isVisible={showPassword[field.name]}
                  setIsVisible={handleShowPassword}
                  disabled={!user.email ? false : field.disabled}
                />
              ))
            )}
            <FormCheckBoxWrapper style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" checked={checkBox} onChange={() => setCheckBox(!checkBox)} />I agree to the{" "}
              <div>
                &nbsp;
                <a href="https://synthesys.io/ai-terms/" target="_blank" rel="noreferrer">
                  Terms
                </a>{" "}
                and{" "}
                <a href="https://synthesys.io/ai-privacy-policy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </div>
            </FormCheckBoxWrapper>
            <FormSubmitButton type="submit">
              {isLoading ? <CircularProgress /> : FORM_TEXT.register.submitButton}{" "}
            </FormSubmitButton>
            <GoogleAuth onClick={() => login()}>
              <Google />
              <span>Google</span>
            </GoogleAuth>
            <FormLastSection>
              <FormLastQuestion>{FORM_TEXT.register.question}</FormLastQuestion>
              <Link to={pages.signIn()}>{FORM_TEXT.register.anotherPage}</Link>
            </FormLastSection>
          </FormMain>
          {signInWithGoogleLoading && (
            <Blur>
              <CircularProgress color="#009af7" />
            </Blur>
          )}
          <FormLastSection isInfo>
            <FormLastQuestion>Need help? Contact us at</FormLastQuestion>
            <a href="mailto:support@synthesys.io">support@synthesys.io</a>
          </FormLastSection>
        </Wrapper>
      </FormContent>
    </AuthLayout>
  );
};

const FormContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  justify-content: flex-end;
`;

const Wrapper = styled("div")`
  position: relative;
  padding: 56px 72px;
  background: ${({ theme }) => theme.white};
  border-radius: 24px;

  @media (max-width: 1150px) {
    padding: 56px 36px;
  }

  @media (max-height: 750px) {
    padding: 56px 36px 36px;
  }

  @media (max-width: 601px) {
    padding: 56px 24px 24px;
  }
`;

const LogoWrapper = styled("div")`
  position: absolute;
  z-index: 1;
  top: 28px;
  left: 36px;

  svg {
    width: 120px;
    height: 28px;
  }

  @media (max-width: 601px) {
    left: 24px;
  }
`;

const FormTitle = styled("h1")`
  margin-bottom: 12px;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 60px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.signInText};
  text-align: left;

  @media (min-width: 280px) and (max-width: 375px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

const FormMain = styled("form")`
  margin-top: 20px;
  width: 100%;
  min-width: 353px;
  max-width: 353px;

  & > div {
    margin-bottom: 14px;
  }

  & > div:last-of-type {
    margin-bottom: 0;
  }

  & > div > input {
    box-shadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset -5px -5px 4px rgba(255, 255, 255, 0.3);
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.reviewText};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: inset 5px 5px 4px rgba(174, 174, 192, 0.2), inset 0px 0px 0px 30px rgba(240, 240, 243, 1) !important;
      -webkit-text-fill-color: ${({ theme }) => theme.reviewText};
    }

    &:focus {
      background-color: ${({ theme }) => theme.inputBackground};
    }
    &::placeholder {
      color: ${({ theme }) => theme.reviewText};
    }
  }

  @media (min-width: 280px) and (max-width: 424px) {
    min-width: 300px;
    max-width: 300px;
    input {
      min-width: auto;
    }
  }

  @media (max-height: 1001px) {
    margin-top: 10px;
  }
`;

const GoogleAuth = styled.div`
  display: flex;
  height: 60px;
  padding: 0 12px;
  margin: 24px 0;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 2px solid ${({ theme }) => theme.buttonGreyText}44;
  background-color: ${({ theme }) => theme.white};
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.inputBackground};
  }

  span {
    font-family: "Mulish", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.signInText};
  }

  & > svg {
    width: 18px;
    height: 18px;
  }

  @media (min-width: 320px) and (max-width: 750px) {
    height: 45px;
    font-size: 14px;
    margin: 14px 0;

    span {
      font-size: 14px;
    }
  }

  @media (max-height: 750px) {
    height: 45px;
    margin: 14px 0;

    span {
      font-size: 14px;
    }
  }
`;

const FormCheckBoxWrapper = styled("label")`
  display: block;
  position: relative;
  padding-right: 30px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.signInText};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: ${({ theme }) => theme.signInText};
    text-decoration: underline;
  }

  input {
    margin-right: 7px;
  }

  input[type="checkbox"] {
    background-color: transparent;
  }

  @media (max-width: 750px) {
    font-size: 12px;
  }

  @media (max-height: 750px) {
    font-size: 12px;
  }
`;

const FormSubmitButton = styled("button")`
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  width: 100%;
  height: 60px;
  margin-top: 32px;
  border: none;
  background: ${({ theme }) => theme.buttonSignIn};
  border-radius: 12px;

  @media (min-width: 320px) and (max-width: 750px) {
    height: 45px;
    font-size: 14px;
    margin-top: 25px;
  }

  @media (max-height: 750px) {
    height: 45px;
    font-size: 14px;
  }
`;

const FormLastQuestion = styled("p")`
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.signInText};
  text-align: center;
  opacity: 0.6;
`;

const FormLastSection = styled("div")<{ isInfo?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: center;
  align-items: center;

  a {
    background: ${({ theme }) => theme.button};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: ${({ isInfo }) => (isInfo ? "4px" : "8px")};
    font-family: "Mulish", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.activeMenu};
    -webkit-text-decoration-color: ${({ theme }) => theme.activeMenu};
  }

  @media (min-width: 320px) and (max-width: 750px) {
    margin-top: 20px;
  }

  @media (min-width: 751px) and (max-width: 1150px) {
    margin-top: 32px;
  }

  @media (max-height: 750px) {
    margin-top: 20px;
  }
`;

const CircularProgressWrapper = styled("div")`
  min-height: 292px;
  max-height: 292px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  z-index: 2;
`;

export default SignUp;
