import { useSelector } from "react-redux";
import { getAssets } from "../redux/reducers/assetReducer";
import { Asset } from "../redux/actions/assetAction";

export const useImageBackground = () => {
  const assets = useSelector(getAssets);
  const images = assets[Asset.Images].data;
  const imageLoading = assets[Asset.Images].isLoading;
  const imageHasMore = assets[Asset.Images].hasMore;
  const imageCurrentPage = assets[Asset.Images].pageNumber;
  const searchImageLoading = assets[Asset.Images].searchLoading;
  const imageUploadLoading = assets[Asset.Images].uploadLoading;

  return {
    images,
    imageLoading,
    imageHasMore,
    imageCurrentPage,
    searchImageLoading,
    imageUploadLoading,
  };
};
