import styled from "styled-components";

import { TranslationTabProps } from "../../../../types/videoTranslation";

const TranslationTypeTab = ({ icon, title, description }: TranslationTabProps) => {
  return (
    <TranslationTypeWrapper>
      <TranslationTypeTop>{icon}</TranslationTypeTop>
      <TranslationTypeBottom>
        <TranslationTypeTitle>{title}</TranslationTypeTitle>
        <TranslationTypeDescription>{description}</TranslationTypeDescription>
      </TranslationTypeBottom>
    </TranslationTypeWrapper>
  );
};

const TranslationTypeWrapper = styled("div")`
  background-color: ${({ theme }) => theme.videoTranslationBackground};
  width: 100%;
  height: 198px;
  padding: 16px;
  border-radius: 8px;
  border: 1px;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const TranslationTypeTop = styled("div")`
  background-color: ${({ theme }) => theme.videoTranslationTabBackground};
  width: 100%;
  max-height: 90px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  svg {
    display: flex;
    position: static;
  }
`;

const TranslationTypeBottom = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 4px;
`;

const TranslationTypeTitle = styled("div")`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const TranslationTypeDescription = styled("div")`
  color: #6f7074;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

export default TranslationTypeTab;
