export const MagicPen = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4 10L24.4133 11.9867L20.4133 7.98667L22.4 6C22.96 5.44 23.68 5.17333 24.4 5.17333C25.12 5.17333 25.84 5.44 26.4 6C27.5067 7.10667 27.5067 8.89333 26.4 10Z"
      fill="#FFA215"
    />
    <path
      d="M23.48 12.9333L9.06669 27.3333C7.96003 28.44 6.17336 28.44 5.06669 27.3333C3.96003 26.2267 3.96003 24.44 5.06669 23.3333L19.48 8.93333L23.48 12.9333Z"
      fill="#FFA215"
    />
    <path
      d="M13.6667 4.66667L14.2133 2.81333C14.2667 2.64 14.2133 2.45333 14.0933 2.32C13.9733 2.18667 13.76 2.13333 13.5867 2.18667L11.7333 2.73333L9.88001 2.18667C9.70667 2.13333 9.52001 2.18667 9.38667 2.30667C9.25334 2.44 9.21334 2.62667 9.26667 2.8L9.80001 4.66667L9.25334 6.52C9.20001 6.69333 9.25334 6.88 9.37334 7.01333C9.50667 7.14667 9.69334 7.18667 9.86667 7.13333L11.7333 6.6L13.5867 7.14667C13.64 7.16 13.68 7.17333 13.7333 7.17333C13.8667 7.17333 13.9867 7.12 14.0933 7.02667C14.2267 6.89333 14.2667 6.70667 14.2133 6.53333L13.6667 4.66667Z"
      fill="#FFA215"
    />
    <path
      d="M8.3333 12.6667L8.87996 10.8133C8.9333 10.64 8.87996 10.4533 8.75996 10.32C8.62663 10.1867 8.43997 10.1467 8.26663 10.2L6.39997 10.7333L4.54663 10.1867C4.3733 10.1333 4.18663 10.1867 4.0533 10.3067C3.91997 10.44 3.87997 10.6267 3.9333 10.8L4.46663 12.6667L3.91997 14.52C3.86663 14.6933 3.91997 14.88 4.03997 15.0133C4.1733 15.1467 4.35997 15.1867 4.5333 15.1333L6.38663 14.5867L8.23996 15.1333C8.27996 15.1467 8.3333 15.1467 8.38663 15.1467C8.51996 15.1467 8.63997 15.0933 8.74663 15C8.87997 14.8667 8.91996 14.68 8.86663 14.5067L8.3333 12.6667Z"
      fill="#FFA215"
    />
    <path
      d="M28.3334 19.3333L28.88 17.48C28.9334 17.3067 28.88 17.12 28.76 16.9867C28.6267 16.8533 28.44 16.8133 28.2667 16.8667L26.4134 17.4133L24.56 16.8667C24.3867 16.8133 24.2 16.8667 24.0667 16.9867C23.9334 17.12 23.8934 17.3067 23.9467 17.48L24.4934 19.3333L23.9467 21.1867C23.8934 21.36 23.9467 21.5467 24.0667 21.68C24.2 21.8133 24.3867 21.8533 24.56 21.8L26.4134 21.2533L28.2667 21.8C28.3067 21.8133 28.36 21.8133 28.4134 21.8133C28.5467 21.8133 28.6667 21.76 28.7734 21.6667C28.9067 21.5333 28.9467 21.3467 28.8934 21.1733L28.3334 19.3333Z"
      fill="#FFA215"
    />
  </svg>
);
