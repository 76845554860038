export const PitchIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.95841 11.0834H4.66675C2.33341 11.0834 1.16675 10.5001 1.16675 7.58342V4.66675C1.16675 2.33341 2.33341 1.16675 4.66675 1.16675H9.33342C11.6667 1.16675 12.8334 2.33341 12.8334 4.66675V7.58342C12.8334 9.91675 11.6667 11.0834 9.33342 11.0834H9.04175C8.86092 11.0834 8.68592 11.1709 8.57508 11.3167L7.70008 12.4834C7.31508 12.9967 6.68508 12.9967 6.30008 12.4834L5.42508 11.3167C5.33175 11.1884 5.11591 11.0834 4.95841 11.0834Z"
      stroke="url(#paint0_linear_1_18815)"
      strokeWidth="1.17647"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33119 6.41667H9.33643"
      stroke="url(#paint1_linear_1_18815)"
      strokeWidth="1.56863"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99732 6.41667H7.00256"
      stroke="url(#paint2_linear_1_18815)"
      strokeWidth="1.56863"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66346 6.41667H4.6687"
      stroke="url(#paint3_linear_1_18815)"
      strokeWidth="1.56863"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_18815"
        x1="3.67957"
        y1="2.69697"
        x2="10.6156"
        y2="11.5881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_18815"
        x1="9.16811"
        y1="6.20128"
        x2="9.5138"
        y2="6.64484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_18815"
        x1="6.83425"
        y1="6.20128"
        x2="7.17993"
        y2="6.64484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_18815"
        x1="4.50039"
        y1="6.20128"
        x2="4.84607"
        y2="6.64484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
