import React from "react";
import styled from "styled-components";

const FooterCopyright = () => {
  return (
    <Wrapper>
      <CopyrightText>Nooveau Media Ltd 2023 ©&nbsp;All&nbsp;rights&nbsp;reserved</CopyrightText>
      <Address>111 Watling gate 1, 297&#8209;303&nbsp;Edgware&nbsp;Road, London,&nbsp;NW9&nbsp;6NB</Address>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Address = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #191b1f;
  opacity: 0.6;
`;

const CopyrightText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #191b1f;
  opacity: 0.6;
`;

export default FooterCopyright;
