import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import Loader from "../../components/Loader/Loader";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getIsAuthorized } from "../../redux/reducers/authReducer";
import Navigate from "../../lib/Navigate";
import { pages } from "../../lib/routeUtils";

const ActivateEmail = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [route, setRoute] = useState<string>("");

  useEffect(() => {
    setRoute(searchParams.get("token") || "");
  }, [searchParams]);

  useEffect(() => {
    if (route && route.length) {
      dispatch(
        setAuthToken({
          isAuthorized: true,
          accessToken: route,
        }),
      );
    }
  }, [route]);

  useEffect(() => {
    if (isAuthorized) Navigate.push(pages.main("mail"));
  }, [isAuthorized]);

  return (
    <Wrapper>
      <Loader size={100} />
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  min-height: 100vh;
  width: 100%;
  display: flex;

  & > div {
    height: 100px;
    width: 100px;
    margin: auto;
  }
`;

export default ActivateEmail;
