import React, { useContext } from "react";
import styled from "styled-components";
import DragResizeFrame from "../../../components/Frame/Frame";
import { ObjectTypes, ResizeDragObj } from "../../../types/scene";
import DragResizeWrapper from "./DragResizeWrapper";
import { VideoEditorContext } from "../../../App";

interface Props {
  index: number;
  obj: ResizeDragObj;
  objectType: ObjectTypes;
  dragHandleClassName?: string;
  children: React.ReactNode;
  nodeId?: string;
}

const SceneObjectWrapper = ({ index, obj, objectType, dragHandleClassName, children, nodeId }: Props) => {
  const { handleChangeActiveObject, currentScene } = useContext(VideoEditorContext);
  const { activeObjectId, editableTextId } = currentScene;

  const handleChangeActive = (event: React.MouseEvent<HTMLElement>, id: number) => {
    event.stopPropagation();
    if (objectType === ObjectTypes.texts) return;
    handleChangeActiveObject(id);
  };

  return (
    <DragResizeWrapper
      index={index}
      key={obj.id}
      id={obj.id}
      position={obj.position}
      size={obj.size}
      objType={objectType}
      dragHandleClassName={dragHandleClassName}
      lockRatio={objectType === ObjectTypes.avatars}
      nodeId={nodeId}
    >
      <ObjectWrapper
        onClick={(event) => handleChangeActive(event, obj.id)}
        active={activeObjectId === obj.id}
        className="scene-object-wrapper"
      >
        <DragResizeFrameWrapper active={activeObjectId === obj.id && editableTextId !== obj.id} className="scene-frame">
          <DragResizeFrame />
        </DragResizeFrameWrapper>
        {children}
      </ObjectWrapper>
    </DragResizeWrapper>
  );
};

const ObjectWrapper = styled.div<{ active: boolean }>`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  border: ${({ active }) => (active ? "2px solid #0066ce" : "2px solid transparent")};
`;

const DragResizeFrameWrapper = styled.div<{ active: boolean }>`
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
`;

export default SceneObjectWrapper;
