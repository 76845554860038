import AIHumansPage from ".";
import { VideoEditorContext } from "../../App";
import { useVideoEditor } from "../../hooks/useVideoEditor";

const AIHumansModule = () => {
  const videoEditor = useVideoEditor();

  return (
    <>
      <VideoEditorContext.Provider value={videoEditor}>
        <AIHumansPage />
      </VideoEditorContext.Provider>
    </>
  );
};

export default AIHumansModule;
