import { useSelector } from "react-redux";
import { getAssets } from "../redux/reducers/assetReducer";
import { Asset } from "../redux/actions/assetAction";

export const useVideoBackground = () => {
  const assets = useSelector(getAssets);
  const videos = assets[Asset.Videos].data;
  const videoLoading = assets[Asset.Videos].isLoading;
  const videoHasMore = assets[Asset.Videos].hasMore;
  const videoCurrentPage = assets[Asset.Videos].pageNumber;
  const searchVideoLoading = assets[Asset.Videos].searchLoading;
  const videoUploadLoading = assets[Asset.Videos].uploadLoading;

  return {
    videos,
    videoLoading,
    videoHasMore,
    videoCurrentPage,
    searchVideoLoading,
    videoUploadLoading,
  };
};
