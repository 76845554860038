export const actors = [
  {
    actorId: 1,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 2,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 3,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 4,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 5,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 6,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 7,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 8,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 9,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 10,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 11,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 12,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 13,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 14,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 15,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 16,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 17,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 18,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 19,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 20,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 21,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 22,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 23,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
  {
    actorId: 24,
    name: "Steave L.",
    photo: "/images/jeffrey-keenan-pUhxoSapPFA-unsplash1.png",
    language: "/images/flag.png",
  },
];

export enum EditorSelect {
  Simple = "Simple",
  Advanced = "Advanced",
}

export const editorSelectOptions = [
  {
    label: EditorSelect.Simple,
    value: EditorSelect.Simple,
  },
  {
    label: EditorSelect.Advanced,
    value: EditorSelect.Advanced,
  },
];
