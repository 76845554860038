import { ReactNode } from "react";
import styled from "styled-components";

export type AlignText = "center" | "inherit" | "justify" | "left" | "right";

export interface SectionTitleProps {
  title?: ReactNode;
  titleAlignMd?: AlignText;
  titleAlignLg?: AlignText;
  titleFontWeight?: string;
  titleFontSize?: string;
  titleMaxWidth?: string;
}

const SectionTitle = ({ title, ...props }: SectionTitleProps) => {
  return (
    <Title className="sectionTitle" {...props}>
      {title}
    </Title>
  );
};

const Title = styled.h2<SectionTitleProps>`
  width: 100%;
  max-width: ${({ titleMaxWidth }) => titleMaxWidth || "100%"};

  font-weight: 700;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.04em;
  text-align: left;

  color: #191b1f;

  @media (min-width: 768px) {
    font-size: ${({ titleFontSize }) => titleFontSize || "38px"};
    text-align: ${({ titleAlignMd }) => titleAlignMd || "left"};
    ${({ titleFontWeight }) => titleFontWeight && `font-weight: ${titleFontWeight}`}
  }

  @media (min-width: 1000px) {
    line-height: 1.37;
    text-align: ${(props) => props.titleAlignLg};
  }
`;

export default SectionTitle;
