import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button, { ButtonThemes } from "../../components/Button/Button";
import AIVoicesCard from "./components/homeSection/sections/AIVoices/AIVoicesCard";
import AIHumansCard from "./components/homeSection/sections/AIVoices/AIHumansCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLayout from "../../layouts/SidebarLayout";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import { products } from "../../mocks/products";
import { getProfile } from "../../redux/reducers/profileReducer";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { getMyProfileServer } from "../../redux/actions/profileActions";
import { clearActors, getActorsServer } from "../../redux/actions/actorActions";
import { clearHumatars, getHumatarsServer } from "../../redux/actions/humatarActions";
import { getActorsList, getActorsListLoading } from "../../redux/reducers/actorReducer";
import { getHumatarList, getHumatarListLoading } from "../../redux/reducers/humatarReducer";
import { PricingIcon } from "../../components/Icons/Icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { pages } from "../../lib/routeUtils";
import { NotEnoughCredits } from "../../types/notEnoughCredits";
import { getOauth } from "../../redux/reducers/authReducer";
import NavbarCard from "./components/navbarCard";
import HomeSection from "./components/homeSection/HomeSection";
import { TrainingCards } from "./components/homeSection/sections/training/TrainingCards";
import { TrainingTab } from "../Training/TrainingPage";
import { HomeNavbarTabs } from "./components/navbarCard/NavbarTabs";
import { AIHumansData, AIVoicesData, FeatureToolsData, SynthesysLabData } from "../../mocks/home/navbarCardMocks";
import { useMyStudio } from "../../hooks/useMyStudio";
import { MyStudioContent } from "../MyStudio";
import { CustomAvatar } from "../../components/Popups/SelectTypeCustomAvatarPopup";

export enum HomeTabs {
  AIVoices = "AIVoices",
  AIHumans = "AIHumans",
  AIImages = "AIImages",
}

const TrainingTabs = [
  { value: "AI Voices", title: "AI Voices" },
  { value: "AI Humans", title: "AI Humans" },
  { value: "AI Images", title: "AI Images" },
  { value: "Video Translate", title: "Video Translate" },
];

const AIVoicesTabs = [
  { value: "AI Voices", title: "AI Voices" },
  { value: "AI Humans", title: "AI Humans" },
];

export enum AIHumansStatus {
  All = 0,
  Draft = 1,
  InProgress = 2,
  Completed = 3,
  Failure = 4,
}

export enum ProjectsDisplayMode {
  aiHuman = "ai-humans",
  aiVoices = "actors",
  aiImages = "ai-images",
  aiVideos = "ai-videos",
}

const projectDisplayModeTabs = [
  {
    value: ProjectsDisplayMode.aiHuman,
    title: "AI Humans",
  },
  {
    value: ProjectsDisplayMode.aiVoices,
    title: "AI Voices",
  },
  {
    value: ProjectsDisplayMode.aiImages,
    title: "AI Images",
  },
  {
    value: ProjectsDisplayMode.aiVideos,
    title: "AI Videos",
  },
];

const aiHumansStatusOption = [
  {
    value: AIHumansStatus.All,
    title: "All",
  },
  {
    value: AIHumansStatus.Completed,
    title: "Completed",
  },
  {
    value: AIHumansStatus.Draft,
    title: "Draft",
  },
];

const options = {
  data: [
    {
      id: "1",
      label: "All",
      value: AIHumansStatus.All,
    },
    {
      id: "2",
      label: "Completed",
      value: AIHumansStatus.Completed,
    },
    {
      id: "3",
      label: "Draft",
      value: AIHumansStatus.Draft,
    },
  ],
};

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    allowToAIHuman,
    allowToAIVoice,
    voiceCloneAllowed = 0,
    hidePricing,
    allowToCloneAIHuman,
    voiceCloneUsed = 0,
    hasUnlimitedVoiceClone,
  } = useSelector(getProfile);

  const {
    featuresList: { list, isLoading, hasMore, currentPage },
    isStatusLoading,
    activeProject,
    selectedStatus,
    activeProjectTypeId,
    onChangeSelect,
    handleActiveProject,
    getProjectListFunc,
    getGalleryImagesFunc,
    getGalleryVideosFunc,
    setActiveProject,
    handleActiveDisplayMode,
    activeDisplayMode,
  } = useMyStudio({ options, aiHumansStatusOption, projectDisplayModeTabs });

  const actors = useSelector(getActorsList);
  const actorsLoading = useSelector(getActorsListLoading);
  const humatars = useSelector(getHumatarList);
  const humatarsLoading = useSelector(getHumatarListLoading);
  const { redirect_uri, state } = useSelector(getOauth);

  const [searchParams] = useSearchParams();

  const modifiedProducts = products.map((product) => {
    if (!allowToAIHuman && product.title === "AI Humans") {
      return {
        ...product,
        link: "",
      };
    }

    if (!allowToAIVoice && product.title === "AI Voices") {
      return {
        ...product,
        link: "",
      };
    }

    return product;
  });

  const upgradeYourPlan = (link?: string) => {
    const prefilled: NotEnoughCredits = {
      title: "Upgrade your plan to access this feature",
      description: "Don't worry, you can easily upgrade your plan to get more credits and access additional features.",
      link,
    };

    dispatch(
      updatePopup({
        popup: Popups.notEnoughCreditsPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleAddInstantHumatar = () => {
    dispatch(
      updatePopup({
        popup: Popups.selectTypeCustomAvatarPopup,
        status: true,
        prefilled: { noRefresh: true, prefilledType: CustomAvatar.InstantHumatar },
      }),
    );
  };

  const handleAddTalkingImages = () => {
    dispatch(
      updatePopup({
        popup: Popups.selectTypeCustomAvatarPopup,
        status: true,
        prefilled: { noRefresh: true, prefilledType: CustomAvatar.TalkingImage },
      }),
    );
  };

  useEffect(() => {
    dispatch(clearActors());
    dispatch(
      getActorsServer({
        keyword: "",
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        mood: [],
        content: [],
        region: [],
        language: [],
        country: [],
        popular: true,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(clearHumatars());
    dispatch(
      getHumatarsServer({
        keyword: "",
        pageNumber: 1,
        categoryType: [],
        voiceAge: [],
        isFeMale: null,
        region: [],
        popular: true,
        category: ["Custom", "Seated", "Humatars"],
      }),
    );
  }, []);

  useEffect(() => {
    const cloneParam = searchParams.get("clone");

    if (cloneParam?.toLowerCase() === "true") {
      dispatch(updatePopup({ popup: Popups.addVoiceAudioPopup, status: true }));
      return;
    }
  }, [searchParams]);

  useEffect(() => {
    if (redirect_uri && state) {
      dispatch(updatePopup({ popup: Popups.addVoiceAudioPopup, status: true }));
    }
  }, []);

  const NavbarCardTabsContent = {
    [HomeNavbarTabs.FeaturedTools]: FeatureToolsData.map((item) =>
      item.title === "Cloning Voice"
        ? {
            ...item,
            onClick: () =>
              voiceCloneAllowed === 0 || voiceCloneAllowed <= voiceCloneUsed
                ? upgradeYourPlan()
                : dispatch(getMyProfileServer({ checkCloneVoice: true })),
          }
        : { ...item },
    ),
    [HomeNavbarTabs.AIVoices]: AIVoicesData.map((item) =>
      item.title === "Multilingual Voice Cloning"
        ? {
            ...item,
            onClick: () =>
              voiceCloneAllowed === 0 || voiceCloneAllowed <= voiceCloneUsed
                ? upgradeYourPlan()
                : dispatch(getMyProfileServer({ checkCloneVoice: true })),
          }
        : { ...item },
    ),
    [HomeNavbarTabs.AIHumans]: AIHumansData.map((item) => {
      if (item.title === "Talking Images") {
        return { ...item, onClick: () => handleAddTalkingImages() };
      } else if (item.title === "Instant avatars") {
        return { ...item, onClick: () => handleAddInstantHumatar() };
      } else if (item.title === "Custom Studio Avatars") {
        if (allowToCloneAIHuman) {
          return { ...item };
        } else {
          return { ...item, isDisabled: true, onClick: () => upgradeYourPlan("https://synthesys.io/custom-avatar/") };
        }
      } else {
        return { ...item };
      }
    }),
    [HomeNavbarTabs.SynthesysLab]: SynthesysLabData.map((item) => ({
      ...item,
    })),
    [HomeNavbarTabs.AIWorkflows]: SynthesysLabData.map((item) => ({
      ...item,
    })),
  };

  const [trainingActiveTab, setTrainingActiveTab] = useState(TrainingTabs[0].value);
  const [AIVoicesActiveTab, setAIVoicesActiveTab] = useState(AIVoicesTabs[0].value);
  const handleTrainingActiveTab = (tab: string) => {
    const params = new URLSearchParams(location.search);
    params.set("training-filter", tab);
    navigate(`?${params.toString()}`);
    setTrainingActiveTab(tab);
  };

  const handleAIVoicesActiveTab = (tab: string) => setAIVoicesActiveTab(tab);
  const trainingSectionContent: { [key: string]: React.ReactElement } = {
    "AI Voices": <TrainingCards tabValue={TrainingTab.voices} />,
    "AI Humans": <TrainingCards tabValue={TrainingTab.humans} />,
    "AI Images": <TrainingCards tabValue={TrainingTab.images} />,
    "Video Translate": <TrainingCards tabValue={TrainingTab.translate} />,
  };

  const AIVoicesSectionContent: { [key: string]: React.ReactElement } = {
    "AI Voices": <AIVoicesCard data={actors} loading={actorsLoading} />,
    "AI Humans": <AIHumansCard data={humatars} loading={humatarsLoading} />,
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("training-filter") || "";

    handleTrainingActiveTab(filterValue as any);
    if (!filterValue) {
      const params = new URLSearchParams(location.search);
      params.set("training-filter", "AI Voices");
      navigate(`?${params.toString()}`);
    }
  }, [location.href]);

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <MobileOnly>
            <Sidebar mobile />
          </MobileOnly>
          <NavbarCard TabContent={NavbarCardTabsContent} />
          <HomeSection
            tabs={TrainingTabs}
            content={trainingSectionContent[trainingActiveTab]}
            activeTab={trainingActiveTab}
            title="Training"
            link={{ to: `/training?training-filter=${trainingActiveTab}`, text: "View All" }}
            handleActiveTab={handleTrainingActiveTab}
          />
          <HomeSection
            tabs={AIVoicesTabs}
            content={AIVoicesSectionContent[AIVoicesActiveTab]}
            activeTab={AIVoicesActiveTab}
            title="Get Started"
            handleActiveTab={handleAIVoicesActiveTab}
            link={{
              to: `${AIVoicesActiveTab === "AI Voices" ? "/actors" : "/ai-humans"}`,
              text: "Create new project",
            }}
          />
          <HomeSection
            tabs={projectDisplayModeTabs}
            content={
              <MyStudioContentWrapper
                id="scrollableDiv"
                displayMode={activeDisplayMode}
                isLoading={isLoading && !list.length}
              >
                <MyStudioContent
                  {...{
                    selectedValue: activeDisplayMode,
                    isStatusLoading,
                    getProjectListFunc,
                    currentPage,
                    activeProjectTypeId,
                    hasMore,
                    isLoading,
                    list,
                    activeProject,
                    setActiveProject,
                    handleActiveProject,
                    getGalleryImagesFunc,
                    getGalleryVideosFunc,
                    maxItems: 5,
                  }}
                />
              </MyStudioContentWrapper>
            }
            activeTab={activeDisplayMode}
            title={"My Studio"}
            handleActiveTab={handleActiveDisplayMode as (props: string) => void}
            link={{ text: "View all", to: "/my-projects" }}
          />
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const Wrapper = styled("div")`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  @media (max-width: 1001px) {
    & .navigation {
      visibility: unset;
      height: 88px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  flex: 1;
  gap: 47px;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track {
    margin: 17px 0;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

const MyStudioContentWrapper = styled("div")<{ displayMode: ProjectsDisplayMode; isLoading?: boolean }>`
  position: relative;
  display: flex;
  align-content: stretch;
  overflow: hidden auto;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  max-height: 100%;
  gap: ${({ displayMode }) => (displayMode === ProjectsDisplayMode.aiVoices ? "14px" : "12px")};
  margin-top: 14px;
  padding: 8px 8px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  .project-card {
    width: 226px;
    background: ${({ theme }) => theme.voiceCardBackground};
    box-shadow: ${({ theme }) => theme.voiceCardShadow};
    &:hover {
      background: ${({ theme }) => theme.hoverVoiceCardBackground};
      & > div {
        div,
        span {
          color: white;
          svg > path {
            fill: white;
          }
        }
      }
    }
  }

  .project-card-large {
    width: 227px;
    background: ${({ theme }) => theme.voiceCardBackground};
    box-shadow: ${({ theme }) => theme.voiceCardShadow};
    &:hover {
      background: ${({ theme }) => theme.hoverVoiceCardBackground};
      & > :nth-child(2) {
        div {
          p {
            color: white;
          }
          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    `}

  @media (max-width: 1345px) {
    .project-card {
      width: 220px;
    }

    .project-card-large {
      width: 236px;
    }
  }

  @media (max-width: 550px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: ${({ displayMode }) =>
      displayMode === ProjectsDisplayMode.aiVoices
        ? "repeat(auto-fit, minmax(220px, 1fr))"
        : "repeat(auto-fit, minmax(236px, 1fr))"};
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 37px 0;
  }
`;

export default withPrivateRoute(HomePage);
