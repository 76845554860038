export const Profile2User = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.66667C8.50663 2.66667 5.66663 5.50667 5.66663 9C5.66663 12.4267 8.34663 15.2 11.84 15.32C11.9466 15.3067 12.0533 15.3067 12.1333 15.32C12.16 15.32 12.1733 15.32 12.2 15.32C12.2133 15.32 12.2133 15.32 12.2266 15.32C15.64 15.2 18.32 12.4267 18.3333 9C18.3333 5.50667 15.4933 2.66667 12 2.66667Z"
      fill="#2DC597"
    />
    <path
      d="M18.7733 18.8667C15.0533 16.3867 8.98661 16.3867 5.23995 18.8667C3.54661 20 2.61328 21.5333 2.61328 23.1733C2.61328 24.8133 3.54661 26.3333 5.22661 27.4533C7.09328 28.7067 9.54661 29.3333 11.9999 29.3333C14.4533 29.3333 16.9066 28.7067 18.7733 27.4533C20.4533 26.32 21.3866 24.8 21.3866 23.1467C21.3733 21.5067 20.4533 19.9867 18.7733 18.8667Z"
      fill="#2DC597"
    />
    <path
      d="M26.6534 9.78667C26.8667 12.3733 25.0267 14.64 22.48 14.9467C22.4667 14.9467 22.4667 14.9467 22.4534 14.9467H22.4134C22.3334 14.9467 22.2534 14.9467 22.1867 14.9733C20.8934 15.04 19.7067 14.6267 18.8134 13.8667C20.1867 12.64 20.9734 10.8 20.8134 8.8C20.72 7.72 20.3467 6.73333 19.7867 5.89333C20.2934 5.64 20.88 5.48 21.48 5.42667C24.0934 5.2 26.4267 7.14667 26.6534 9.78667Z"
      fill="#2DC597"
    />
    <path
      d="M29.32 22.12C29.2133 23.4133 28.3867 24.5333 27 25.2933C25.6667 26.0267 23.9867 26.3733 22.32 26.3333C23.28 25.4667 23.84 24.3867 23.9467 23.24C24.08 21.5867 23.2933 20 21.72 18.7333C20.8267 18.0267 19.7867 17.4667 18.6533 17.0533C21.6 16.2 25.3067 16.7733 27.5867 18.6133C28.8133 19.6 29.44 20.84 29.32 22.12Z"
      fill="#2DC597"
    />
  </svg>
);

export const Profile2UserBlue = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9998 2.66669C8.5065 2.66669 5.6665 5.50669 5.6665 9.00002C5.6665 12.4267 8.3465 15.2 11.8398 15.32C11.9465 15.3067 12.0532 15.3067 12.1332 15.32C12.1598 15.32 12.1732 15.32 12.1998 15.32C12.2132 15.32 12.2132 15.32 12.2265 15.32C15.6398 15.2 18.3198 12.4267 18.3332 9.00002C18.3332 5.50669 15.4932 2.66669 11.9998 2.66669Z"
      fill="#0063B4"
    />
    <path
      d="M18.7733 18.8667C15.0533 16.3867 8.98661 16.3867 5.23995 18.8667C3.54661 20 2.61328 21.5333 2.61328 23.1733C2.61328 24.8133 3.54661 26.3333 5.22661 27.4533C7.09328 28.7067 9.54661 29.3333 11.9999 29.3333C14.4533 29.3333 16.9066 28.7067 18.7733 27.4533C20.4533 26.32 21.3866 24.8 21.3866 23.1467C21.3733 21.5067 20.4533 19.9867 18.7733 18.8667Z"
      fill="#0063B4"
    />
    <path
      d="M26.6535 9.78664C26.8668 12.3733 25.0268 14.64 22.4801 14.9466C22.4668 14.9466 22.4668 14.9466 22.4535 14.9466H22.4135C22.3335 14.9466 22.2535 14.9466 22.1868 14.9733C20.8935 15.04 19.7068 14.6266 18.8135 13.8666C20.1868 12.64 20.9735 10.8 20.8135 8.79997C20.7201 7.71997 20.3468 6.7333 19.7868 5.8933C20.2935 5.63997 20.8801 5.47997 21.4801 5.42664C24.0935 5.19997 26.4268 7.14664 26.6535 9.78664Z"
      fill="#0063B4"
    />
    <path
      d="M29.32 22.12C29.2133 23.4133 28.3867 24.5333 27 25.2933C25.6667 26.0267 23.9867 26.3733 22.32 26.3333C23.28 25.4667 23.84 24.3867 23.9467 23.24C24.08 21.5867 23.2933 20 21.72 18.7333C20.8267 18.0267 19.7867 17.4667 18.6533 17.0533C21.6 16.2 25.3067 16.7733 27.5867 18.6133C28.8133 19.6 29.44 20.84 29.32 22.12Z"
      fill="#0063B4"
    />
  </svg>
);
