// import { seoMocks } from "@/mocks/seoMocks";
// import { IPages } from "@/lib/routeUtils";
// import Seo from "@/components/Seo/Seo";

import PageLayout from "../../layouts/PricingLayout";
import Hero from "./Components/Hero";
import Container from "../../components/Container/Container";
import SectionsStack from "../../components/SectionsStack/SectionsStack";
import EnterprisePackage from "../PricingPage2/components/EnterprisePackage";
import FaqSingle from "../../components/FaqSingle/FaqSingle";
import { transparentFaq } from "../../mocks/transparentPricing/transparentFaq";

const TransparentPricingPage = () => {
  return (
    <>
      {/* <Seo {...seoMocks[IPages.transparentPricing]} /> */}
      <PageLayout>
        <Hero />
        <Container>
          <SectionsStack>
            <EnterprisePackage />
            <FaqSingle {...transparentFaq} />
          </SectionsStack>
        </Container>
      </PageLayout>
    </>
  );
};

export default TransparentPricingPage;
