import React from "react";
import styled from "styled-components";

import ApiAccessBox from "./ApiAccessBox";
import Button, { ButtonThemes } from "../../../components/Button/Button";

const ApiAccessInformationBox = () => (
  <ApiAccessBox title="API Access">
    <Description>
      Unlock the power of Synthesys AI Voices with our comprehensive API integration. If you're a developer seeking to
      incorporate text-to-speech conversions into your application, our API is here to assist. Experience seamless
      text-to-speech conversion capabilities utilizing our extensive collection of voices and languages through a
      single, intuitive interface.
    </Description>
    <Description>
      Take a moment to explore our API documentation and when you're ready, effortlessly generate a secret key from the
      credentials panel below. Utilize your credentials to establish seamless communication with our API and unleash the
      full potential of Synthesys AI Voices.
    </Description>
    <ButtonWrapper>
      <Button
        text="API documentation"
        buttonTheme={ButtonThemes.Transparent}
        onClick={() => window?.open("https://documenter.getpostman.com/view/7750325/2s93eSZFcc", "_blank")?.focus()}
      />
    </ButtonWrapper>
  </ApiAccessBox>
);

const Description = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6f7074;
`;

const ButtonWrapper = styled.div`
  max-width: 135px;
  button > span {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.41px;
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

export default ApiAccessInformationBox;
