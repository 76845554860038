import React, { FC } from "react";
import styled, { css, keyframes } from "styled-components";

import { PriceSection } from "../../../types/pricing";
import Tabs from "./Tabs";
import {
  PricingBundleIcon,
  PricingHumansIcon,
  PricingImagesIcon,
  PricingVoicesIcon,
} from "../../../components/Icons/Icons";

interface PricingLeftProps {
  activeSection: PriceSection;
  setActiveSection: (section: PriceSection) => void;
  activeGroups: PriceSection[];
}

const tabList = [
  { value: PriceSection.voices, label: `AI Voices` },
  { value: PriceSection.humans, label: "AI Humans" },
  { value: PriceSection.image, label: "AI Image" },
  { value: PriceSection.studio, label: "Synthesys AI Studio" },
];

const PricingLeft: FC<PricingLeftProps> = ({ setActiveSection, activeSection, activeGroups }) => {
  const handleChangeSectionClick = (section: string) => {
    setActiveSection(section as PriceSection);
  };

  return (
    <>
      <DesktopWrapper>
        <Button
          svgColorType="fill"
          isActive={activeSection === "voices"}
          onClick={() => handleChangeSectionClick(PriceSection.voices)}
        >
          <ButtonContent>
            <PricingVoicesIcon />
            AI Voices
          </ButtonContent>
          {activeGroups.includes(PriceSection.voices) && <ActiveGroupChip>active</ActiveGroupChip>}
        </Button>
        <Button
          svgColorType="stroke"
          isActive={activeSection === "humans"}
          onClick={() => handleChangeSectionClick(PriceSection.humans)}
        >
          <ButtonContent>
            <PricingHumansIcon />
            AI Humans
          </ButtonContent>
          {activeGroups.includes(PriceSection.humans) && <ActiveGroupChip>active</ActiveGroupChip>}
        </Button>
        <Button
          svgColorType="fill"
          isActive={activeSection === "image"}
          onClick={() => handleChangeSectionClick(PriceSection.image)}
        >
          <ButtonContent>
            <PricingImagesIcon />
            AI Image
          </ButtonContent>
          {activeGroups.includes(PriceSection.image) && <ActiveGroupChip>active</ActiveGroupChip>}
        </Button>
        <Button
          svgColorType="fill"
          isActive={activeSection === "studio"}
          onClick={() => handleChangeSectionClick(PriceSection.studio)}
        >
          <BundleButtonContent>
            <PricingBundleIcon />
            Synthesys AI Studio Bundle
          </BundleButtonContent>
          {activeGroups.includes(PriceSection.studio) && <ActiveGroupChip>active</ActiveGroupChip>}
        </Button>
      </DesktopWrapper>
      <MobileWrapper>
        <Tabs tabs={tabList} activeTab={activeSection} onClickTab={handleChangeSectionClick} />
      </MobileWrapper>
    </>
  );
};

const MobileWrapper = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

const Button = styled.button<{ isActive: boolean; svgColorType: "fill" | "stroke" }>`
  width: 100%;
  padding: 26px 32px;
  color: #6f7074;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #f0f0f3;
  box-shadow: ${({ theme }) => theme.cardShadow};
  background: ${({ theme }) => theme.primaryBackground};

  border-radius: 16px;
  cursor: pointer;

  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.04em;
  text-align: left;
  font-family: Mulish;

  & path,
  & rect {
    ${({ svgColorType }) => svgColorType}: #6f7074;
  }

  ${({ isActive, svgColorType }) =>
    isActive &&
    css`
      font-weight: 600;
      background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      border: 2px solid #0063b4 !important;
      border-radius: 16px;

      & path,
      & rect {
        ${svgColorType}: #0063B4;
      }
    `}
`;

const ActiveGroupChip = styled.div`
  padding: 2px 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  border: 1px solid #0063b4 !important;
  border-radius: 8px;
`;

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 495px;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const colorTransition = keyframes`
  0% {
    fill: #0063b4;
  }
  50% {
    fill: #67afea;
  }
  100% {
    fill: #0063b4;
  }
`;

const BundleButtonContent = styled(ButtonContent)`
  & path,
  & rect {
    animation: ${colorTransition} 3s linear infinite;
  }
`;

export default PricingLeft;
