import styled from "styled-components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";

import Tabs from "./tabs/Tabs";
import Button from "../../../components/Button/Button";
import FormikField from "../../../components/FormInput/FormikField";
import { VideoTranslationContext } from "../../../App";
import { UploadVideoTabs } from "../../../types/videoTranslation";
import { CloudArrowUp, InfoIcon } from "../../../components/Icons/Icons";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { UploadUrlInputValues } from "../../../forms/video-translation/types";
import { uploadUrlSchema } from "../../../forms/video-translation/validation";
import { getUploadUrlFields, initialValuesUploadUrl } from "../../../forms/video-translation/uploadCard";
import {
  UploadTabsOption,
  languageRequirementsTooltip,
  requirementsRorLipSyncTooltip,
  videoRequirementsTooltip,
} from "../../../mocks/videoTranslation";
import Tooltip from "../../../components/Tooltip/Tooltip";
import {
  clearFaceDetect,
  clearVideoServiceUrl,
  getYoutubeUrlServer,
} from "../../../redux/actions/videoTranslationActions";
import { getVideoService } from "../../../redux/reducers/videoTranslationReducer";

const ACCEPTED_FORMATS = "video/mp4";

interface Props {
  handleVideoSelect: ({ target }: any) => void;
}

interface UploadURLProps {
  title: string;
  isLoading: boolean;
}

const UploadVideoCard = () => {
  const dispatch = useDispatch();
  const { uploadedVideo, handleVideoSelect, handleNextStep, setUploadedVideo } = useContext(VideoTranslationContext);

  const [activeTab, setActiveTab] = useState(UploadTabsOption[0].value);

  const videoService = useSelector(getVideoService);
  const outputUrl = videoService[activeTab]?.url;
  const isLoading = videoService[activeTab]?.isLoading;

  const handleActiveTab = (tab: UploadVideoTabs) => {
    if (isLoading) return;
    setActiveTab(tab);
  };

  const updatedHandleNextStep = (outputUrl?: string) => {
    if (outputUrl) {
      setUploadedVideo(outputUrl);
      dispatch(clearVideoServiceUrl(activeTab));
    }
    handleNextStep();
  };

  const tabsContent = {
    [UploadVideoTabs.Upload]: <UploadVideo handleVideoSelect={handleVideoSelect} />,
    [UploadVideoTabs.YouTube]: <UploadURL title="YouTube" isLoading={isLoading} />,
    [UploadVideoTabs.TikTok]: <UploadURL title="TikTok" isLoading={isLoading} />,
    [UploadVideoTabs.XTwitter]: <UploadURL title="X (Twitter)" isLoading={isLoading} />,
    [UploadVideoTabs.Vimeo]: <UploadURL title="Vimeo" isLoading={isLoading} />,
    [UploadVideoTabs.OtherURL]: <UploadURL title="Other URL" isLoading={isLoading} />,
  };

  useEffect(() => {
    if (uploadedVideo) {
      updatedHandleNextStep();
    }
  }, [uploadedVideo]);

  useEffect(() => {
    if (outputUrl) {
      updatedHandleNextStep(outputUrl);
    }
  }, [outputUrl]);

  useEffect(() => {
    dispatch(clearFaceDetect());
  }, []);

  return (
    <Wrapper>
      <Tabs data={UploadTabsOption} active={activeTab} handleActive={handleActiveTab} />
      <ContentWrapper>{tabsContent[activeTab]}</ContentWrapper>
      <BottomContent>
        <ItemWrapper>
          <span>Language Requirements</span>
          <InfoIcon />
          <Tooltip
            text={Object.keys(languageRequirementsTooltip).map((key, index) => (
              <span key={index}>
                <b>{key}:</b> {languageRequirementsTooltip[key]}
              </span>
            ))}
            position="top-left"
            reverseColor
            arrow
          />
        </ItemWrapper>
        <ItemWrapper>
          <span>Video Requirements</span>
          <InfoIcon />
          <Tooltip
            text={
              <ol>
                {videoRequirementsTooltip.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            }
            position="top-left"
            reverseColor
            arrow
          />
        </ItemWrapper>
        <ItemWrapper>
          <span>Requirements for better LipSync</span>
          <InfoIcon />
          <Tooltip
            text={
              <ol>
                {requirementsRorLipSyncTooltip.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            }
            position="top-left"
            reverseColor
            arrow
          />
        </ItemWrapper>
      </BottomContent>
    </Wrapper>
  );
};

const UploadVideo = ({ handleVideoSelect }: Props) => (
  <>
    <UploadSection>
      <UploadWrapper>
        <CloudArrowUp />
        <UploadText>
          Drag and drop your media or <span>upload file</span>
        </UploadText>
        <SecondaryText>File supported .mp4. Maximum file size 500 MB from 15s to 5m.</SecondaryText>
        <input id="videoUpload" name="videoUpload" type="file" accept={ACCEPTED_FORMATS} onChange={handleVideoSelect} />
      </UploadWrapper>
    </UploadSection>
  </>
);

const UploadURL = ({ title, isLoading }: UploadURLProps) => {
  const dispatch = useDispatch();

  const onSubmit = (values: UploadUrlInputValues) => {
    switch (title) {
      case "YouTube":
        dispatch(getYoutubeUrlServer(values.url));
        break;
      default:
        break;
    }
  };

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit: handleFormikSubmit,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: initialValuesUploadUrl,
    validationSchema: uploadUrlSchema,
    onSubmit,
  });

  return (
    <UploadURLWrapper onSubmit={handleFormikSubmit}>
      <UploadURLTitle>{title}</UploadURLTitle>
      <TextFieldWrapper>
        {getUploadUrlFields.map((field) => (
          <FormikField
            flexRow="31"
            handleBlur={handleBlur}
            key={field.id}
            errors={errors}
            touched={touched}
            field={field}
            handleChange={handleChange}
            values={values}
          />
        ))}

        <Button
          text={isLoading ? "Loading..." : "Upload"}
          type="submit"
          disabled={isLoading || Object.keys(errors).length > 0}
        />
      </TextFieldWrapper>
    </UploadURLWrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
  height: 342px;
  border-radius: 8px;
  border: ${({ theme }) => theme.videoTranslationBorder};
  background-color: ${({ theme }) => theme.videoTranslationBackground};
  position: relative;
`;

const BottomContent = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  bottom: 16px;
  left: 24px;

  & :nth-child(3):hover > {
    div {
      width: 160%;
    }
  }

  @media (max-width: 700px) {
    gap: 5px;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 4px;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #6f7074;
  }

  svg {
    width: 14px;
    height: 14px;
    path {
      stroke: #6f7074;
    }
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    border-radius: 4px;
    padding: 4px 8px 6px !important;
    width: 250%;

    & > span {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > ol {
        margin-inline-start: 12px;
        margin-block-start: 4px;
        margin-block-end: 4px;

        & > li {
          list-style-type: decimal !important;
          margin-bottom: 12px;
          font-size: 11px !important;
          line-height: 14px !important;
          text-align: left;
          text-transform: none;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & > span,
      li::marker {
        font-size: 11px !important;
        line-height: 14px !important;
        text-align: left;
        text-transform: none;
      }
    }
  }

  @media (max-width: 900px) {
    & > div {
      display: none;
    }
  }

  @media (max-width: 700px) {
    span {
      font-size: 10px;
    }
  }
`;

const ContentWrapper = styled.div`
  max-width: 92%;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 700px) {
    max-height: 217px;
  }
`;

const UploadSection = styled.div<{ drag?: boolean }>`
  position: relative;
  display: flex;
  max-height: 226px;
  height: 100%;
  padding: 40px 80px 40px 80px;
  margin: 24px 0px 0px 0px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23${({
    theme,
  }) =>
    theme.videoTranslationGray.replace(
      "#",
      "",
    )}' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  background-color: ${({ theme }) => theme.videoTransaltionUpload};
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 700px) {
    padding: 20px 30px 20px 30px;
  }
`;

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const UploadText = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0px 8px 0px;
  color: ${({ theme }) => theme.primaryText};
  text-align: center;

  span {
    background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SecondaryText = styled.div`
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #757679;
  text-align: center;
`;

const UploadURLWrapper = styled.form`
  max-height: 226px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
`;

const TextFieldWrapper = styled.div`
  display: flex;
  gap: 8px;
  div {
    width: 100%;

    input {
      width: 100%;
      padding: 12px 16px 12px 16px;
      border-radius: 8px;
      box-shadow: none;
      background: ${({ theme }) => theme.videoTranslationInput};
      border: none;
    }

    .form-field-error {
      bottom: -17px;
      left: 0;
      position: absolute;
    }
  }

  button {
    max-width: 122px;
    box-shadow: none;
    border-radius: 6px;
  }
`;

const UploadURLTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.primaryText};
`;

export default UploadVideoCard;
