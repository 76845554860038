import styled from "styled-components";
import { brands } from "../../../mocks/brands";

interface PricingTrustedBrandsProps {
  isOpacity?: boolean;
  isVertical?: boolean;
}
const PricingTrustedBrands = ({ isOpacity, isVertical }: PricingTrustedBrandsProps) => {
  return (
    <TrustedBrandWrapper isOpacity={isOpacity} isVertical={isVertical}>
      <span>Our&nbsp;trusted&nbsp;brands</span>
      <BrandRow isVertical={isVertical}>
        {brands.map(({ id, icon, path }) => (
          <a key={id} href={path} target="_blank" rel="noreferrer">
            {icon}
          </a>
        ))}
      </BrandRow>
    </TrustedBrandWrapper>
  );
};

const TrustedBrandWrapper = styled.div<PricingTrustedBrandsProps>`
  width: 100%;
  display: flex;
  align-items: ${({ isVertical }) => (isVertical ? "flex-start" : "center")};
  justify-content: space-between;
  gap: 12px;
  margin-top: ${({ isVertical }) => (isVertical ? "0" : "56px")};
  flex-direction: ${({ isVertical }) => (isVertical ? "column" : "row")};
  opacity: ${({ isOpacity }) => (isOpacity ? "0.5" : "1")};

  span {
    color: #ffffff;
    font-size: ${({ isVertical }) => (isVertical ? "16px" : "20px")};
    font-weight: 500;
    line-height: 1.2;
  }

  @media (max-width: 1299px) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: 599px) {
    gap: 12px;

    span {
      font-size: 16px;
    }
  }

  @media (max-width: 420px) {
    align-items: flex-start;
    margin-top: ${({ isVertical }) => (isVertical ? "0" : "32px")};

    span {
      font-size: 12px;
    }
  }
`;

const BrandRow = styled.div<PricingTrustedBrandsProps>`
  display: flex;
  width: ${({ isVertical }) => (isVertical ? "100%" : "auto")};
  align-items: center;
  justify-content: ${({ isVertical }) => (isVertical ? "space-between" : "center")};
  gap: 56px;

  & > a {
    display: flex;
  }

  @media (max-width: 1299px) {
    gap: 24px;
  }

  @media (max-width: 999px) {
    width: 100%;
    justify-content: space-between;
    gap: 24px;

    svg {
      height: 38px;
      width: auto;
    }
  }

  @media (max-width: 799px) {
    gap: 12px;

    svg {
      height: 32px;
    }
  }

  @media (max-width: 619px) {
    gap: 8px;

    svg {
      height: 22px;
    }
  }

  @media (max-width: 420px) {
    svg {
      height: 16px;
    }
  }
`;

export default PricingTrustedBrands;
