export const AnimatedTransitionIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.78384 7.62639H3.41703V6.25958C3.41703 5.8837 3.1095 5.57617 2.73362 5.57617C2.35775 5.57617 2.05022 5.8837 2.05022 6.25958V7.62639H0.683406C0.307533 7.62639 0 7.93392 0 8.3098C0 8.68567 0.307533 8.9932 0.683406 8.9932H2.05022V10.36C2.05022 10.7359 2.35775 11.0434 2.73362 11.0434C3.1095 11.0434 3.41703 10.7359 3.41703 10.36V8.9932H4.78384C5.15972 8.9932 5.46725 8.68567 5.46725 8.3098C5.46725 7.93392 5.15972 7.62639 4.78384 7.62639Z"
      fill="#191B1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.39285 3.29131L13.161 7.97418C13.3944 8.26374 13.3905 8.67739 13.1524 8.96305L9.25002 13.6459C9.09549 13.8309 8.87383 13.9269 8.64984 13.9269C8.47423 13.9269 8.29706 13.8676 8.15111 13.7458C7.81941 13.4703 7.77492 12.9778 8.05121 12.6469L11.5446 8.45496L8.17608 4.27003C7.90604 3.93443 7.95911 3.44273 8.2955 3.17268C8.6311 2.90185 9.12202 2.95571 9.39285 3.29131ZM5.49879 3.17235C5.83439 2.90231 6.32531 2.95538 6.59614 3.29098L10.3643 7.97385C10.5969 8.26341 10.5937 8.67706 10.3557 8.96272L6.45331 13.6456C6.29878 13.8306 6.07712 13.9266 5.85312 13.9266C5.67752 13.9266 5.50035 13.8672 5.3544 13.7455C5.02269 13.47 4.97821 12.9775 5.2545 12.6466L8.74714 8.45463L5.37937 4.2697C5.10933 3.9341 5.16318 3.4424 5.49879 3.17235Z"
      fill="#191B1F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8282 7.97418L12.06 3.29131C11.7892 2.95571 11.2983 2.90185 10.9627 3.17268C10.6263 3.44273 10.5732 3.93443 10.8432 4.27003L14.2118 8.45496L10.7184 12.6469C10.4421 12.9778 10.4866 13.4703 10.8183 13.7458C10.9642 13.8676 11.1414 13.9269 11.317 13.9269C11.541 13.9269 11.7626 13.8309 11.9172 13.6459L15.8196 8.96305C16.0576 8.67739 16.0615 8.26374 15.8282 7.97418Z"
      fill="#191B1F"
    />
  </svg>
);
