import { HumatarProps } from "../mocks/humans";
import { Filter } from "./filter";
import { ActorPositionTypes } from "./scene";

export interface Humatar {
  aiHumanActorId: number;
  allowCircle: boolean;
  userId: number;
  order: number;
  name: string;
  photo: string;
  brief: string;
  isFeMale: boolean;
  videoSampleLink: string;
  status: number;
  insertDateTime: Date;
  category: string;
  region: string;
  keyName: string;
  usedCount: number;
  isBookmarked: boolean;
  isLocked: boolean;
}

export interface IHumatarList {
  pageNumber: number;
  pageSize: number;
  firstPage: string;
  lastPage: string;
  totalPages: number;
  totalRecords: number;
  nextPage?: any;
  previousPage?: any;
  data: Humatar[];
}

export interface HumatarPreview {
  aiHumanActorId: number;
  aiHumanActorPreviewId: number;
  insertDateTime: string;
  language: string;
  preview: string;
}

export type HumatarPreviewPrefilled = {
  human: Humatar;
  setSelectedActor: (humatar: Humatar) => void;
  isPopup?: boolean;
};

export enum HumatarModules {
  "humatarList" = "humatarList",
  "humatarPreview" = "humatarPreview",
  "uploadHumatar" = "uploadHumatar",
}

export type RequestForHumatarServer = {
  keyword?: string;
  pageNumber?: number;
  filters?: Filter;
  isFeMale?: boolean | null;
  bookmarked?: boolean | null;
  popular?: boolean | null;
  history?: boolean | null;
};

export type HumatarPrefilled = {
  type: HumatarProps;
  setAddAvatar: (
    src: string,
    aiHumanActorId: number,
    allowCircle: boolean,
    canvasSize: { width: number; height: number },
    actorPositionType?: ActorPositionTypes,
  ) => void;
  requestForHumatarServer?: RequestForHumatarServer;
  previousFill?: HumatarPrefilled;
};
