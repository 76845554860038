export const OpenIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="5" width="18" height="18" rx="4" fill="url(#paint0_linear_1096_19225)" />
    <g opacity="0.4">
      <rect y="23" width="18" height="28" rx="4" transform="rotate(-90 0 23)" fill="url(#paint1_linear_1096_19225)" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.75 13.9998C20.75 14.1138 20.7115 14.2273 20.634 14.3198L18.1338 17.32C17.9573 17.532 17.6418 17.561 17.4298 17.384C17.2177 17.2075 17.1892 16.8925 17.3658 16.68L19.6039 13.9943L17.4463 11.3131C17.2732 11.0981 17.3073 10.7831 17.5223 10.6101C17.7373 10.437 18.0518 10.471 18.2253 10.6861L20.6395 13.6863C20.713 13.7778 20.75 13.8888 20.75 13.9998Z"
      fill="white"
    />
    <mask id="mask0_1096_19225" maskUnits="userSpaceOnUse" x="17" y="10" width="4" height="8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.75 13.9998C20.75 14.1138 20.7115 14.2273 20.634 14.3198L18.1338 17.32C17.9573 17.532 17.6418 17.561 17.4298 17.384C17.2177 17.2075 17.1892 16.8925 17.3658 16.68L19.6039 13.9943L17.4463 11.3131C17.2732 11.0981 17.3073 10.7831 17.5223 10.6101C17.7373 10.437 18.0518 10.471 18.2253 10.6861L20.6395 13.6863C20.713 13.7778 20.75 13.8888 20.75 13.9998Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1096_19225)"></g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 13.9998C7.25 14.1138 7.2885 14.2273 7.36601 14.3198L9.86619 17.32C10.0427 17.532 10.3582 17.561 10.5702 17.384C10.7823 17.2075 10.8108 16.8925 10.6342 16.68L8.39608 13.9943L10.5537 11.3131C10.7268 11.0981 10.6927 10.7831 10.4777 10.6101C10.2627 10.437 9.94819 10.471 9.77468 10.6861L7.36051 13.6863C7.287 13.7778 7.25 13.8888 7.25 13.9998Z"
      fill="white"
    />
    <mask id="mask1_1096_19225" maskUnits="userSpaceOnUse" x="7" y="10" width="4" height="8">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 13.9998C7.25 14.1138 7.2885 14.2273 7.36601 14.3198L9.86619 17.32C10.0427 17.532 10.3582 17.561 10.5702 17.384C10.7823 17.2075 10.8108 16.8925 10.6342 16.68L8.39608 13.9943L10.5537 11.3131C10.7268 11.0981 10.6927 10.7831 10.4777 10.6101C10.2627 10.437 9.94819 10.471 9.77468 10.6861L7.36051 13.6863C7.287 13.7778 7.25 13.8888 7.25 13.9998Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_1096_19225)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_1096_19225"
        x1="8.87692"
        y1="7.35385"
        x2="19.5385"
        y2="21.0615"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1096_19225"
        x1="3.87692"
        y1="26.6615"
        x2="20.6821"
        y2="40.5515"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
