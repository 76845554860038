import { HumatarProps } from "../../mocks/humans";
import { HumatarModules } from "../../types/humatar";

const HUMATARS = "HUMATARS_";

export const GET_HUMATARS = "GET_HUMATARS";
export const GET_HUMATARS_SERVER = "GET_HUMATARS_SERVER";
export const UPDATE_HUMATAR_LOADING = "UPDATE_HUMATAR_LOADING";
export const BOOKMARK_HUMATAR_SERVER = "BOOKMARK_HUMATAR_SERVER";

export const UPDATE_HAS_MORE_HUMATARS = "UPDATE_HAS_MORE_HUMATARS";
export const SET_PAGE_HUMATARS = "SET_PAGE_HUMATARS";
export const CLEAR_HUMATARS = "CLEAR_HUMATARS";
export const CLEAR_SEARCH = HUMATARS + "CLEAR_SEARCH";

export const SEARCH_HUMATARS_RESULT_LOADING = "SEARCH_HUMATARS_RESULT_LOADING";
export const FILTER_HUMATAR_LOADING = "FILTER_HUMATAR_LOADING";
export const GET_HUMATAR_PREVIEW_SERVER = "GET_HUMATAR_PREVIEW_SERVER";
export const DELETE_CUSTOM_HUMATAR_SERVER = "DELETE_CUSTOM_HUMATAR_SERVER";

export const UPLOAD_HUMATAR_SERVER = "UPLOAD_HUMATAR_SERVER";

interface UpdateHumatarLoadingProps {
  module: HumatarModules;
  isLoading: boolean;
}

interface GetHumatarsProps {
  keyword?: string;
  pageNumber?: number;
  categoryType?: string[];
  region?: string[];
  voiceAge?: string[];
  bookmarked?: boolean | null;
  history?: boolean | null;
  popular?: boolean | null;
  isFeMale?: boolean | null;
  pageSize?: number;
  category?: string[];
  VideoTranslate?: boolean;
}

interface UpdateHasMoreHumatarsProps {
  hasMore: boolean;
}
interface SetPageHumatarsProps {
  pageNumber: number;
}

interface ClearSearchProps {
  type?: HumatarProps;
}

interface DeleteCustomHumatarServerProps {
  id: number;
  previousFill?: any;
}

export const updateHasMoreHumatars = ({ hasMore }: UpdateHasMoreHumatarsProps) => ({
  type: UPDATE_HAS_MORE_HUMATARS,
  payload: {
    hasMore,
  },
});

export const setPageHumatars = ({ pageNumber }: SetPageHumatarsProps) => ({
  type: SET_PAGE_HUMATARS,
  payload: { pageNumber },
});

export const updateHumatarLoading = ({ module, isLoading }: UpdateHumatarLoadingProps) => ({
  type: UPDATE_HUMATAR_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const getHumatarsServer = ({
  keyword,
  pageNumber,
  categoryType,
  region,
  voiceAge,
  bookmarked,
  history,
  popular,
  isFeMale,
  pageSize,
  category,
  VideoTranslate,
}: GetHumatarsProps) => ({
  type: GET_HUMATARS_SERVER,
  payload: {
    pageNumber,
    request: {
      method: "POST",
      url: "/aIHumanActor/list",
      data: {
        pageNumber,
        keyword,
        categoryType: categoryType?.map((item) => (item === "Premium" ? "Standard" : "Premium")),
        region,
        voiceAge,
        bookmarked,
        history,
        popular,
        isFeMale,
        pageSize: pageSize || 34,
        category,
        VideoTranslate,
      },
    },
  },
});

export const clearHumatars = () => ({
  type: CLEAR_HUMATARS,
});

export const clearSearch = ({ type }: ClearSearchProps) => ({
  type: CLEAR_SEARCH,
  payload: type,
});

export const bookmarkHumatarServer = (id: number, action: boolean, isScreen?: boolean) => ({
  type: BOOKMARK_HUMATAR_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/AIHumanActor/BookmarkAIHumanActor?AIHumanActorId=${id}&add=${action}`,
    },
    id,
    action,
    isScreen,
  },
});

export const searchHumatarResultLoading = (loading: boolean) => ({
  type: SEARCH_HUMATARS_RESULT_LOADING,
  payload: loading,
});

export const filterHumatarLoading = (loading: boolean) => ({
  type: FILTER_HUMATAR_LOADING,
  payload: loading,
});

export const getHumatarPreviewServer = (id: number) => ({
  type: GET_HUMATAR_PREVIEW_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/aIHumanActor/getActorPreviews?AIHumanActorId=${id}`,
    },
  },
});

export const uploadHumatarServer = (formData: FormData, filename: string, noRefresh?: boolean) => ({
  type: UPLOAD_HUMATAR_SERVER,
  payload: {
    filename,
    request: {
      method: "POST",
      url: "/AIHumanActor/addAIHumanActor",
      data: formData,
    },
    noRefresh,
  },
});

export const deleteCustomHumatarServer = ({ id, previousFill }: DeleteCustomHumatarServerProps) => ({
  type: DELETE_CUSTOM_HUMATAR_SERVER,
  payload: {
    request: {
      method: "DELETE",
      url: `/AIHumanActor/delete/${id}`,
    },
    id,
    previousFill,
  },
});
