import styled from "styled-components";

export const RightArrowPlanIcon = () => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <StyledPath opacity="0.3" d="M1 1L5.5 6L0.999999 11" stroke="#191B1F" />
  </svg>
);

const StyledPath = styled.path`
  stroke: ${({ theme }) => theme.primaryText};
`;
