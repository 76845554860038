export const ShareIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66679 2.66797V5.33464C4.28346 6.01997 2.65346 9.85997 2.00013 13.3346C1.97546 13.472 5.58946 9.35997 8.66679 9.33464V12.0013L14.0001 7.33464L8.66679 2.66797Z"
      stroke="#191B1F"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
