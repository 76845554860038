import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import * as Sentry from "@sentry/react";
import { GET_TRANSITIONS_SERVER, updateTransitionLoading } from "../actions/transitionAction";
import { SentryErrors, sentryErrors } from "../../lib/sentry";

const transitionSaga = [
  takeEvery(`${GET_TRANSITIONS_SERVER}`, handleGetTransitionsServer),
  takeEvery(`${GET_TRANSITIONS_SERVER}_FAIL`, handleGetTransitionsServerFail),
  takeEvery(`${GET_TRANSITIONS_SERVER}_SUCCESS`, handleGetTransitionsServerSuccess),
];

function* handleGetTransitionsServer() {
  yield put(updateTransitionLoading({ isLoading: true }));
}

function* handleGetTransitionsServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_TRANSITIONS.toast);
  yield put(updateTransitionLoading({ isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_TRANSITIONS,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetTransitionsServerSuccess() {
  yield put(updateTransitionLoading({ isLoading: false }));
}

export default transitionSaga;
