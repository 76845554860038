import { ReactNode } from "react";
import styled from "styled-components";

import { AlignText } from "./SectionTitle";

interface SubtitleProps {
  subtitleAlignMd?: AlignText;
  subtitleAlignLg?: AlignText;
  subtitleTextColor?: string;
  subtitleFontSize?: string;
}

interface SubtitleArrProps {
  subtitleSpace?: string;
  subtitleItemsList?: boolean;
  subtitleMaxWidth?: string;
}

export interface SectionSubTitleProps extends SubtitleArrProps, SubtitleProps {
  subtitle?: ReactNode | ReactNode[];
  addSubtitleClass?: string;
}

const wrapperStyle = ({ subtitleSpace, subtitleItemsList, subtitleMaxWidth }: SubtitleArrProps) => ({
  width: "100%",
  maxWidth: subtitleMaxWidth ? subtitleMaxWidth : "100%",
  display: "flex",
  "flex-direction": "column",
  gap: subtitleSpace ? subtitleSpace : "8px",
  listStyle: subtitleItemsList ? "circle" : "none",
});

const textStyle = ({ subtitleTextColor, subtitleAlignMd, subtitleAlignLg, subtitleFontSize }: SubtitleProps) => ({
  width: "100%",
  fontWeight: "500",
  fontSize: subtitleFontSize || "16px",
  lineHeight: "1.5",
  a: {
    textDecoration: "underline",
    outline: "4px solid transparent",
    transition: "all 0.25s",

    ":hover": {
      backgroundColor: "#b0d4f5",
      outlineColor: "#b0d4f5",
    },
  },

  color: subtitleTextColor ? subtitleTextColor : "#6f7074",

  "@media (min-width: 768px)": {
    textAlign: subtitleAlignMd,
  },

  "@media (min-width: 1000px)": {
    lineHeight: "1.75",
    textAlign: subtitleAlignLg,
  },
});

const SectionSubTitle = ({
  subtitle,
  subtitleSpace,
  subtitleItemsList,
  subtitleMaxWidth,
  addSubtitleClass = "",
  ...props
}: SectionSubTitleProps) => {
  return subtitleItemsList ? (
    <SubTitleWrapperList
      className={`${addSubtitleClass} sectionSubTitle`}
      subtitleSpace={subtitleSpace}
      subtitleItemsList={subtitleItemsList}
      subtitleMaxWidth={subtitleMaxWidth}
    >
      {Array.isArray(subtitle) ? (
        subtitle.map((item, idx) => (
          <SubTitleListItem key={idx} {...props}>
            {item}
          </SubTitleListItem>
        ))
      ) : (
        <SubTitleListItem {...props}>{subtitle}</SubTitleListItem>
      )}
    </SubTitleWrapperList>
  ) : (
    <SubTitleWrapperBox
      className={`${addSubtitleClass} sectionSubTitle`}
      subtitleSpace={subtitleSpace}
      subtitleMaxWidth={subtitleMaxWidth}
    >
      {Array.isArray(subtitle) ? (
        subtitle.map((item, idx) => (
          <SubTitleParagraph key={idx} {...props}>
            {item}
          </SubTitleParagraph>
        ))
      ) : (
        <SubTitleParagraph {...props}>{subtitle}</SubTitleParagraph>
      )}
    </SubTitleWrapperBox>
  );
};

const SubTitleWrapperBox = styled("div")<SubtitleArrProps>((props) => ({
  ...wrapperStyle(props),
}));

const SubTitleWrapperList = styled("ul")<SubtitleArrProps>((props) => ({
  ...wrapperStyle(props),
}));

const SubTitleParagraph = styled("p")<SubtitleProps>((props) => ({
  ...textStyle(props),
}));

const SubTitleListItem = styled("li")<SubtitleProps>((props) => ({
  ...textStyle(props),
}));

export default SectionSubTitle;
