import { ActorTypeId } from "../types/sidebarBox";

export const sidebarBoxes = [
  {
    id: 1,
    title: "speed",
    defaultTitle: "speed",
    background: "#FF8C00",
    step: 6.66,
    defaultValue: "0.5",
    modifier: "x",
    values: [
      {
        id: 1,
        text: "0.5",
        label: "0.5",
      },
      {
        id: 2,
        text: "0.6",
      },
      {
        id: 3,
        text: "0.7",
      },
      {
        id: 4,
        text: "0.8",
      },
      {
        id: 5,
        text: "0.9",
      },
      {
        id: 6,
        text: "1",
        label: "1",
      },
      {
        id: 7,
        text: "1.1",
      },
      {
        id: 8,
        text: "1.2",
      },
      {
        id: 9,
        text: "1.3",
      },
      {
        id: 10,
        text: "1.4",
      },
      {
        id: 11,
        text: "1.5",
        label: "1.5",
      },
      {
        id: 12,
        text: "1.6",
      },
      {
        id: 13,
        text: "1.7",
      },
      {
        id: 14,
        text: "1.8",
      },
      {
        id: 15,
        text: "1.9",
      },
      {
        id: 16,
        text: "2",
        label: "2",
      },
    ],
    actorTypeId: ActorTypeId.TWO,
  },
  {
    id: 2,
    title: "pause",
    defaultTitle: "pause",
    background: "#E859FF",
    step: 2,
    defaultValue: "0",
    modifier: "s",
    values: [
      {
        id: 1,
        text: "0",
        label: "0",
      },
      {
        id: 2,
        text: "0.1",
      },
      {
        id: 3,
        text: "0.2",
      },
      {
        id: 4,
        text: "0.3",
      },
      {
        id: 5,
        text: "0.4",
      },
      {
        id: 6,
        text: "0.5",
      },
      {
        id: 7,
        text: "0.6",
      },
      {
        id: 8,
        text: "0.7",
      },
      {
        id: 9,
        text: "0.8",
      },
      {
        id: 10,
        text: "0.9",
      },
      {
        id: 11,
        text: "1",
        label: "1",
      },
      {
        id: 12,
        text: "1.1",
      },
      {
        id: 13,
        text: "1.2",
      },
      {
        id: 14,
        text: "1.3",
      },
      {
        id: 15,
        text: "1.4",
      },
      {
        id: 16,
        text: "1.5",
      },
      {
        id: 17,
        text: "1.6",
      },
      {
        id: 18,
        text: "1.7",
      },
      {
        id: 19,
        text: "1.8",
      },
      {
        id: 20,
        text: "1.9",
      },
      {
        id: 21,
        text: "2",
        label: "2",
      },
      {
        id: 22,
        text: "2.1",
      },
      {
        id: 23,
        text: "2.2",
      },
      {
        id: 24,
        text: "2.3",
      },
      {
        id: 25,
        text: "2.4",
      },
      {
        id: 26,
        text: "2.5",
      },
      {
        id: 27,
        text: "2.6",
      },
      {
        id: 28,
        text: "2.7",
      },
      {
        id: 29,
        text: "2.8",
      },
      {
        id: 30,
        text: "2.9",
      },
      {
        id: 31,
        text: "3",
        label: "3",
      },
      {
        id: 32,
        text: "3.1",
      },
      {
        id: 33,
        text: "3.2",
      },
      {
        id: 34,
        text: "3.3",
      },
      {
        id: 35,
        text: "3.4",
      },
      {
        id: 36,
        text: "3.5",
      },
      {
        id: 37,
        text: "3.6",
      },
      {
        id: 38,
        text: "3.7",
      },
      {
        id: 39,
        text: "3.8",
      },
      {
        id: 40,
        text: "3.9",
      },
      {
        id: 41,
        text: "4",
        label: "4",
      },
      {
        id: 42,
        text: "4.1",
      },
      {
        id: 43,
        text: "4.2",
      },
      {
        id: 44,
        text: "4.3",
      },
      {
        id: 45,
        text: "4.4",
      },
      {
        id: 46,
        text: "4.5",
      },
      {
        id: 47,
        text: "4.6",
      },
      {
        id: 48,
        text: "4.7",
      },
      {
        id: 49,
        text: "4.8",
      },
      {
        id: 50,
        text: "4.9",
      },
      {
        id: 51,
        text: "5",
        label: "5",
      },
    ],
    actorTypeId: ActorTypeId.TWO,
  },
  {
    id: 3,
    title: "speed",
    defaultTitle: "speed",
    background: "#FF8C00",
    step: 6.66,
    defaultValue: "0.5",
    modifier: "x",
    values: [
      {
        id: 1,
        text: "0.5",
        label: "0.5",
      },
      {
        id: 2,
        text: "0.6",
      },
      {
        id: 3,
        text: "0.7",
      },
      {
        id: 4,
        text: "0.8",
      },
      {
        id: 5,
        text: "0.9",
      },
      {
        id: 6,
        text: "1",
        label: "1",
      },
      {
        id: 7,
        text: "1.1",
      },
      {
        id: 8,
        text: "1.2",
      },
      {
        id: 9,
        text: "1.3",
      },
      {
        id: 10,
        text: "1.4",
      },
      {
        id: 11,
        text: "1.5",
        label: "1.5",
      },
      {
        id: 12,
        text: "1.6",
      },
      {
        id: 13,
        text: "1.7",
      },
      {
        id: 14,
        text: "1.8",
      },
      {
        id: 15,
        text: "1.9",
      },
      {
        id: 16,
        text: "2",
        label: "2",
      },
    ],
    actorTypeId: ActorTypeId.ONE,
  },
  {
    id: 4,
    title: "pause",
    defaultTitle: "pause",
    background: "#E859FF",
    step: 2,
    defaultValue: "0",
    modifier: "s",
    values: [
      {
        id: 1,
        text: "0",
        label: "0",
      },
      {
        id: 2,
        text: "0.1",
      },
      {
        id: 3,
        text: "0.2",
      },
      {
        id: 4,
        text: "0.3",
      },
      {
        id: 5,
        text: "0.4",
      },
      {
        id: 6,
        text: "0.5",
      },
      {
        id: 7,
        text: "0.6",
      },
      {
        id: 8,
        text: "0.7",
      },
      {
        id: 9,
        text: "0.8",
      },
      {
        id: 10,
        text: "0.9",
      },
      {
        id: 11,
        text: "1",
        label: "1",
      },
      {
        id: 12,
        text: "1.1",
      },
      {
        id: 13,
        text: "1.2",
      },
      {
        id: 14,
        text: "1.3",
      },
      {
        id: 15,
        text: "1.4",
      },
      {
        id: 16,
        text: "1.5",
      },
      {
        id: 17,
        text: "1.6",
      },
      {
        id: 18,
        text: "1.7",
      },
      {
        id: 19,
        text: "1.8",
      },
      {
        id: 20,
        text: "1.9",
      },
      {
        id: 21,
        text: "2",
        label: "2",
      },
      {
        id: 22,
        text: "2.1",
      },
      {
        id: 23,
        text: "2.2",
      },
      {
        id: 24,
        text: "2.3",
      },
      {
        id: 25,
        text: "2.4",
      },
      {
        id: 26,
        text: "2.5",
      },
      {
        id: 27,
        text: "2.6",
      },
      {
        id: 28,
        text: "2.7",
      },
      {
        id: 29,
        text: "2.8",
      },
      {
        id: 30,
        text: "2.9",
      },
      {
        id: 31,
        text: "3",
        label: "3",
      },
      {
        id: 32,
        text: "3.1",
      },
      {
        id: 33,
        text: "3.2",
      },
      {
        id: 34,
        text: "3.3",
      },
      {
        id: 35,
        text: "3.4",
      },
      {
        id: 36,
        text: "3.5",
      },
      {
        id: 37,
        text: "3.6",
      },
      {
        id: 38,
        text: "3.7",
      },
      {
        id: 39,
        text: "3.8",
      },
      {
        id: 40,
        text: "3.9",
      },
      {
        id: 41,
        text: "4",
        label: "4",
      },
      {
        id: 42,
        text: "4.1",
      },
      {
        id: 43,
        text: "4.2",
      },
      {
        id: 44,
        text: "4.3",
      },
      {
        id: 45,
        text: "4.4",
      },
      {
        id: 46,
        text: "4.5",
      },
      {
        id: 47,
        text: "4.6",
      },
      {
        id: 48,
        text: "4.7",
      },
      {
        id: 49,
        text: "4.8",
      },
      {
        id: 50,
        text: "4.9",
      },
      {
        id: 51,
        text: "5",
        label: "5",
      },
    ],
    actorTypeId: ActorTypeId.ONE,
  },
  {
    id: 5,
    title: "style",
    defaultTitle: "style",
    background: "#779930",
    step: 50,
    defaultValue: "None",
    values: [
      {
        id: 0,
        text: "",
        label: "None",
      },
      {
        id: 1,
        text: "General",
        label: "General",
      },
      {
        id: 2,
        text: "Assistant",
        label: "Assistant",
      },
      {
        id: 3,
        text: "Chat",
        label: "Chat",
      },
      {
        id: 4,
        text: "Customerservice",
        label: "Customerservice",
      },
      {
        id: 5,
        text: "Newscast",
        label: "Newscast",
      },
      {
        id: 6,
        text: "Angry",
        label: "Angry",
      },
      {
        id: 7,
        text: "Cheerful",
        label: "Cheerful",
      },
      {
        id: 8,
        text: "Sad",
        label: "Sad",
      },
      {
        id: 9,
        text: "Excited",
        label: "Excited",
      },
      {
        id: 10,
        text: "Friendly",
        label: "Friendly",
      },
      {
        id: 11,
        text: "Terrified",
        label: "Terrified",
      },
      {
        id: 12,
        text: "Shouting",
        label: "Shouting",
      },
      {
        id: 13,
        text: "Unfriendly",
        label: "Unfriendly",
      },
      {
        id: 14,
        text: "Whispering",
        label: "Whispering",
      },
      {
        id: 15,
        text: "Hopeful",
        label: "Hopeful",
      },
      {
        id: 16,
        text: "Narration-professional",
        label: "Narration-professional",
      },
      {
        id: 17,
        text: "Newscast-casual",
        label: "Newscast-casual",
      },
      {
        id: 18,
        text: "Newscast-formal",
        label: "Newscast-formal",
      },
      {
        id: 19,
        text: "Empathetic",
        label: "Empathetic",
      },
      {
        id: 20,
        text: "Calm",
        label: "Calm",
      },
      {
        id: 21,
        text: "Affectionate",
        label: "Affectionate",
      },
      {
        id: 22,
        text: "Disgruntled",
        label: "Disgruntled",
      },
      {
        id: 23,
        text: "Fearful",
        label: "Fearful",
      },
      {
        id: 24,
        text: "Gentle",
        label: "Gentle",
      },
      {
        id: 25,
        text: "Lyrical",
        label: "Lyrical",
      },
      {
        id: 26,
        text: "Serious",
        label: "Serious",
      },
      {
        id: 27,
        text: "Embarrassed",
        label: "Embarrassed",
      },
      {
        id: 28,
        text: "Depressed",
        label: "Depressed",
      },
      {
        id: 29,
        text: "Envious",
        label: "Envious",
      },
      {
        id: 30,
        text: "Narration-relaxed",
        label: "Narration-relaxed",
      },
      {
        id: 31,
        text: "Narration-upbeat",
        label: "Narration-upbeat",
      },
      {
        id: 32,
        text: "Sports-commentary",
        label: "Sports-commentary",
      },
      {
        id: 33,
        text: "Sports-commentary-excited",
        label: "Sports-commentary-excited",
      },
      {
        id: 34,
        text: "Documentary-narration",
        label: "Documentary-narration",
      },
    ],
    actorTypeId: ActorTypeId.ONE,
  },
  {
    id: 6,
    title: "pitch",
    defaultTitle: "pitch",
    background: "#31DFCA",
    step: 6.6,
    defaultValue: "1",
    values: [
      {
        id: 1,
        text: "0.5",
        label: "0.5",
      },
      {
        id: 2,
        text: "0.6",
      },
      {
        id: 3,
        text: "0.7",
      },
      {
        id: 4,
        text: "0.8",
      },
      {
        id: 5,
        text: "0.9",
      },
      {
        id: 6,
        text: "1",
        label: "1",
      },
      {
        id: 7,
        text: "1.1",
      },
      {
        id: 8,
        text: "1.2",
      },
      {
        id: 9,
        text: "1.3",
      },
      {
        id: 10,
        text: "1.4",
      },
      {
        id: 11,
        text: "1.5",
        label: "1.5",
      },
      {
        id: 12,
        text: "1.6",
      },
      {
        id: 13,
        text: "1.7",
      },
      {
        id: 14,
        text: "1.8",
      },
      {
        id: 15,
        text: "1.9",
      },
      {
        id: 16,
        text: "2",
        label: "2",
      },
    ],
    actorTypeId: ActorTypeId.ONE,
  },
];
