import { toast } from "react-toastify";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProfile } from "../redux/reducers/profileReducer";
import { generateAiImageQuery } from "../lib/generateAiImageQuery";
import { Popups, updatePopup } from "../redux/actions/popupsActions";
import { UploadImagePrefilled, UploadImageTypes } from "../types/image";
import {
  AiImagesContextTypes,
  RatioChange,
  RatioType,
  ResolutionChange,
  ResolutionType,
} from "../types/aiImagesContext";
import { addPromptToVideoServer, generateImagesServer } from "../redux/actions/imagesActions";
import {
  artStyles,
  illustrations,
  ratios,
  resolutions,
  artists,
  stylesArr,
  motions,
  ratiosText2Video,
  resolutionsText2Video,
  extendVideoOprions,
  imagesStoryRatios,
} from "../mocks/aiImages";
import {
  getUploadPersonalizeImage,
  getUploadPoseImage,
  getUploadStoryReferenceImage,
} from "../redux/reducers/imagesReducer";

export enum AIImagesSidebarTabs {
  "Text2Image" = "Image",
  "Text2Video" = "Video",
}

export const AiImagesSidebarTabs = [
  { label: AIImagesSidebarTabs.Text2Image },
  { label: AIImagesSidebarTabs.Text2Video },
];

const DEFAULT_OUTPUTS_AMOUNT = 4;

export const useAiImages = (): AiImagesContextTypes => {
  const dispatch = useDispatch();
  const { aiImageFreeUser: isFreeUser } = useSelector(getProfile);

  const storyReferenceImage = useSelector(getUploadStoryReferenceImage);
  const personalizeImage = useSelector(getUploadPersonalizeImage);
  const poseImage = useSelector(getUploadPoseImage);

  const [activeTab, setActiveTab] = useState<AIImagesSidebarTabs>(AiImagesSidebarTabs[0].label);
  const [text, setText] = useState<string>("");
  const [ratio, setRatio] = useState<RatioType>(ratios.data[0]);
  const [resolution, setResolution] = useState<ResolutionType>(resolutions.data[0]);
  const [ratioText2Video, setRatioText2Video] = useState<RatioType>(ratiosText2Video.data[0]);
  const [resolutionText2Video, setResolutionText2Video] = useState<ResolutionType>(resolutionsText2Video.data[0]);
  const [artist, setArtist] = useState<string>(artists.data[0].value);
  const [illustration, setIllustration] = useState<string>(illustrations.data[0].value);
  const [artStyle, setArtStyle] = useState<string>(artStyles.data[0].value);
  const [photorealistic, setPhotorealistic] = useState<boolean>(false);
  const [style, setStyle] = useState<string>(stylesArr.data[0].value);
  const [motion, setMotion] = useState<string>(motions.data[0].value);
  const [extendVideo, setExtendVideo] = useState<string>(extendVideoOprions.data[0].value);
  const [isPoseControlTogle, setPoseControlTogle] = useState(false);
  const [isPersonalizeTogle, setPersonalizeTogle] = useState(false);
  const [lipsyncFile, setLipsyncFile] = useState<File | null>(null);

  const handlePoseControlTogleClick = (status: boolean) => {
    setPoseControlTogle(status);
  };

  const handlePersonalizeTogleClick = (status: boolean) => {
    setPersonalizeTogle(status);
  };

  const updateLipsyncFile = (file: File | null) => {
    setLipsyncFile(file);
  };

  // const [subStyle, setSubStyle] = useState<string>(subStylesArr[style]?.[0]);

  const handleStyleChange = (newValue: string) => {
    const newStyle = stylesArr.data.find((style) => style.value === newValue)?.value;

    setStyle(newStyle || stylesArr.data[0].value);
    // setSubStyle("");
  };

  const [uploadedImage, setUploadedImage] = useState<string>("");
  const [inpaintImage, setInpaintImage] = useState<string>("");

  const [isCreateStoryMode, setIsCreateStoryMode] = useState<boolean>(false);
  const [imagesStoryTextValues, setImagesStoryTextValues] = useState<string[]>([]);
  const [videosStoryTextValues, setVideoStoryTextValues] = useState<string[]>([]);

  const [outputsAmount, setOutputsAmount] = useState<number>(DEFAULT_OUTPUTS_AMOUNT);

  const changeImagesStoryTextValues = (newValues: string[]) => {
    setImagesStoryTextValues(newValues);
  };

  const changeVideosStoryTextValues = (newValues: string[]) => {
    setVideoStoryTextValues(newValues);
  };

  const changeOutputsAmount = (newAmount: number) => {
    setOutputsAmount(newAmount);
  };

  const changeIsCreateStoryMode = (status: boolean) => {
    setIsCreateStoryMode(status);
    if (status === true && activeTab === AIImagesSidebarTabs.Text2Image) {
      setRatio(imagesStoryRatios.data[0]);
    } else {
      setRatio(ratios.data[0]);
    }
  };

  const handleResolutionChange = ({ newValue, resolutions, ratios, setResolution, setRatio }: ResolutionChange) => {
    const newResolution = resolutions.data.find((resolution) => resolution.value === newValue);
    const newRatio = ratios.data.find(
      (r) => r.resolution === newValue && r.value.replace(/\s*\(.*\)/, "") === ratio.value.replace(/\s*\(.*\)/, ""),
    );

    setResolution(newResolution || resolutions.data[0]);
    setRatio(newRatio || ratios.data[0]);
  };

  const handleRatioChange = ({ newValue, ratios, setRatio }: RatioChange) => {
    const newRation = ratios.data.find((ratio) => ratio.value === newValue);

    setRatio(newRation || ratios.data[0]);
  };

  const handleUploadedImage = (image: string) => {
    setUploadedImage(image);
  };

  const handleInPaintImage = (image: string) => {
    setInpaintImage(image);
    dispatch(
      updatePopup({
        popup: Popups.aIImageRemoveAreaPopup,
        status: true,
        prefilled: {
          isEdit: false,
        },
      }),
    );
  };

  const handleInPaintEditImage = (image: string) => {
    setInpaintImage(image);
    dispatch(
      updatePopup({
        popup: Popups.aIImageRemoveAreaPopup,
        status: true,
        prefilled: {
          isEdit: true,
        },
      }),
    );
  };

  const handleDeleteUploadedImage = () => setUploadedImage("");

  const handleOpenRemoveArea = () => {
    const prefilled: UploadImagePrefilled = {
      title: "InPainting",
      type: UploadImageTypes.InPainting,
      handleSelectImage: handleInPaintImage,
    };

    dispatch(
      updatePopup({
        popup: Popups.uploadAIImageAssetPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleOpenUpload = () => {
    const prefilled: UploadImagePrefilled = {
      title: activeTab === AIImagesSidebarTabs.Text2Image ? "Create Variations" : "Image to video",
      type: UploadImageTypes.CreateVariations,
      isTextToImage: activeTab === AIImagesSidebarTabs.Text2Image,
      handleSelectImage: handleUploadedImage,
    };

    dispatch(
      updatePopup({
        popup: Popups.uploadAIImageAssetPopup,
        status: true,
        prefilled,
      }),
    );
  };

  const handleVisualise = () => {
    if (
      !text &&
      (activeTab === AIImagesSidebarTabs.Text2Image || (activeTab === AIImagesSidebarTabs.Text2Video && !uploadedImage))
    ) {
      toast.error("Please enter text first to start generating images");
      return;
    }

    const textInput = generateAiImageQuery({ text, artist, illustration, artStyle });

    if (isPersonalizeTogle && !personalizeImage) {
      return toast.error("In Personalize mode you need to provide a face image");
    }

    if (activeTab === AIImagesSidebarTabs.Text2Image) {
      const filteredStory = imagesStoryTextValues.filter((item) => !!item?.trim());
      const storyText = filteredStory.join(" \n ");
      const storyAmount = filteredStory.length || 1;

      if (isCreateStoryMode && !filteredStory.length) {
        return toast.error("At least one story scene must be filled");
      }

      const resourceImage = isCreateStoryMode && storyReferenceImage ? storyReferenceImage : undefined;

      const samplesAmount = isFreeUser ? 2 : isCreateStoryMode ? storyAmount : outputsAmount;

      const createVariationFileUrl = isPersonalizeTogle && personalizeImage ? personalizeImage : uploadedImage;

      const face = isPoseControlTogle && poseImage ? poseImage : undefined;

      const model =
        isPersonalizeTogle || (isPoseControlTogle && poseImage)
          ? "personalize"
          : isCreateStoryMode
          ? "story"
          : undefined;

      dispatch(
        generateImagesServer({
          text: textInput,
          width: Number(ratio.width),
          height: Number(ratio.height),
          enhance: Number(photorealistic),
          upscale: resolution.upscale,
          samplesAmount,
          createVariationFileUrl,
          style_category: style || null,
          style_sub_category: null,
          // style_sub_category: subStyle || null,
          story: isCreateStoryMode ? storyText : undefined,
          resourceImage,
          face,
          model,
        }),
      );
    } else {
      const filteredStory = videosStoryTextValues.filter((item) => !!item?.trim());
      const storyText = filteredStory.join(" \n ");

      if (isCreateStoryMode && !filteredStory.length) {
        return toast.error("At least one story scene must be filled");
      }

      const resourceImage =
        isPersonalizeTogle && personalizeImage
          ? personalizeImage
          : isCreateStoryMode && storyReferenceImage
          ? storyReferenceImage
          : undefined;

      const extendSeconds = +extendVideo || undefined;

      dispatch(
        addPromptToVideoServer({
          title: "Project",
          prompt: uploadedImage ? "-" : text,
          generatedImage: uploadedImage,
          motion,
          size: ratioText2Video.size,
          story: isCreateStoryMode ? storyText : undefined,
          personalize: isPersonalizeTogle || undefined,
          resourceImage,
          extendSeconds,
          pose: isPoseControlTogle || undefined,
          poseImage: isPoseControlTogle && poseImage ? poseImage : undefined,
        }),
      );
    }
  };

  useEffect(() => {
    if (activeTab === AIImagesSidebarTabs.Text2Video && uploadedImage) {
      setText("");
    }
  }, [activeTab, uploadedImage]);

  useEffect(() => {
    if (!uploadedImage) return;
    handlePoseControlTogleClick(false);
    handlePersonalizeTogleClick(false);
    changeIsCreateStoryMode(false);
  }, [uploadedImage]);

  return {
    text,
    setText,
    ratio,
    setRatio,
    handleRatioChange,
    resolution,
    setResolution,
    handleResolutionChange,
    ratioText2Video,
    setRatioText2Video,
    resolutionText2Video,
    setResolutionText2Video,
    artist,
    setArtist,
    illustration,
    setIllustration,
    artStyle,
    setArtStyle,
    style,
    setStyle: handleStyleChange,
    motion,
    setMotion,
    activeTab,
    setActiveTab,
    photorealistic,
    setPhotorealistic,
    uploadedImage,
    setUploadedImage,
    handleUploadedImage,
    handleDeleteUploadedImage,
    inpaintImage,
    handleInPaintImage,
    handleOpenRemoveArea,
    handleOpenUpload,
    handleVisualise,
    handleInPaintEditImage,
    isCreateStoryMode,
    changeIsCreateStoryMode,
    extendVideo,
    setExtendVideo,
    imagesStoryTextValues,
    changeImagesStoryTextValues,
    outputsAmount,
    changeOutputsAmount,
    isPoseControlTogle,
    handlePoseControlTogleClick,
    isPersonalizeTogle,
    handlePersonalizeTogleClick,
    videosStoryTextValues,
    changeVideosStoryTextValues,
    lipsyncFile,
    updateLipsyncFile,
  };
};
