export const VoiceOnluHumatarIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59933 9.73609L10 12.0681V3.93276L6.59933 6.26476C6.488 6.34009 6.35667 6.38143 6.222 6.38143H3.33333V9.61943H6.222C6.35667 9.61943 6.488 9.66076 6.59933 9.73609ZM10.29 2.11676C10.494 1.97676 10.7587 1.96143 10.9773 2.07743C11.196 2.19276 11.3333 2.41943 11.3333 2.66676V13.3334C11.3333 13.5814 11.196 13.8081 10.9773 13.9234C10.88 13.9748 10.7733 14.0001 10.6667 14.0001C10.5347 14.0001 10.4027 13.9614 10.29 13.8841L6.01533 10.9528H2.66667C2.29867 10.9528 2 10.6548 2 10.2861V5.71476C2 5.34609 2.29867 5.04809 2.66667 5.04809H6.01533L10.29 2.11676ZM12.9155 5.48103C13.2015 5.25103 13.6222 5.29569 13.8529 5.58236C14.9435 6.93836 14.9435 9.06236 13.8529 10.4184C13.7209 10.5817 13.5275 10.667 13.3329 10.667C13.1862 10.667 13.0389 10.619 12.9155 10.5197C12.6289 10.289 12.5829 9.86969 12.8135 9.58236C13.5042 8.72503 13.5042 7.27569 12.8135 6.41836C12.5829 6.13103 12.6289 5.71169 12.9155 5.48103Z"
      fill="url(#paint0_linear_1_10642)"
    />
    <mask
      id="mask0_1_10642"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="13"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.59933 9.73609L10 12.0681V3.93276L6.59933 6.26476C6.488 6.34009 6.35667 6.38143 6.222 6.38143H3.33333V9.61943H6.222C6.35667 9.61943 6.488 9.66076 6.59933 9.73609ZM10.29 2.11676C10.494 1.97676 10.7587 1.96143 10.9773 2.07743C11.196 2.19276 11.3333 2.41943 11.3333 2.66676V13.3334C11.3333 13.5814 11.196 13.8081 10.9773 13.9234C10.88 13.9748 10.7733 14.0001 10.6667 14.0001C10.5347 14.0001 10.4027 13.9614 10.29 13.8841L6.01533 10.9528H2.66667C2.29867 10.9528 2 10.6548 2 10.2861V5.71476C2 5.34609 2.29867 5.04809 2.66667 5.04809H6.01533L10.29 2.11676ZM12.9155 5.48103C13.2015 5.25103 13.6222 5.29569 13.8529 5.58236C14.9435 6.93836 14.9435 9.06236 13.8529 10.4184C13.7209 10.5817 13.5275 10.667 13.3329 10.667C13.1862 10.667 13.0389 10.619 12.9155 10.5197C12.6289 10.289 12.5829 9.86969 12.8135 9.58236C13.5042 8.72503 13.5042 7.27569 12.8135 6.41836C12.5829 6.13103 12.6289 5.71169 12.9155 5.48103Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_10642)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_1_10642"
        x1="4.72911"
        y1="3.56924"
        x2="11.7327"
        y2="13.0772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
