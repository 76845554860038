import React, { useContext, useState } from "react";
import styled from "styled-components";
import ActionMenu from "../../../../../components/ActionMenu/ActionMenu";
import {
  BringLayerToFrontIcon,
  GetLayerBackIcon,
  LayersActiveIcon,
  LayersIcon,
} from "../../../../../components/Icons/Icons";
import { ChangeLayer } from "../../../../../hooks/useVideoEditor";
import { VideoEditorContext } from "../../../../../App";
import { useSelector } from "react-redux";
import { getTheme } from "../../../../../redux/reducers/themeReducer";
import { ThemeTypes } from "../../../../../types/theme";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

const getListItem = {
  [ChangeLayer.raise]: {
    text: "Bring to front",
    icon: <BringLayerToFrontIcon />,
  },
  [ChangeLayer.lower]: {
    text: "Send to back",
    icon: <GetLayerBackIcon />,
  },
};

const TextLayers = () => {
  const theme = useSelector(getTheme);
  const { changeObjectLayer } = useContext(VideoEditorContext);
  const [active, setActive] = useState<ChangeLayer>();

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const handleChangeActive = (type: ChangeLayer) => {
    changeObjectLayer(type);
    setActive(type);
  };

  return (
    <Wrapper>
      <ActionMenu
        open={isOpen}
        handleClose={handleClose}
        menuStyle={getMenuStyles(theme)}
        trigger={
          <ButtonWrapper isActive={isOpen}>
            <IconButton onClick={handleOpen}>
              <LayersActiveIcon />
            </IconButton>
            <Tooltip
              text="Layers"
              position="bottom"
              style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px", top: "150%" }}
              reverseColor
              arrow
            />
          </ButtonWrapper>
        }
      >
        {Object.keys(ChangeLayer).map((type) => (
          <MenuListItem key={type} isActive={type === active} onClick={() => handleChangeActive(type as ChangeLayer)}>
            {getListItem[type as ChangeLayer].icon}
            {getListItem[type as ChangeLayer].text}
          </MenuListItem>
        ))}
      </ActionMenu>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  position: relative;
`;

const ButtonWrapper = styled.div<{ isActive: boolean }>`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }
`;

const IconButton = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  border-radius: 4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const MenuListItem = styled.div<{ isActive: boolean }>`
  user-select: none;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  background: ${({ isActive, theme }) => (isActive ? theme.menuListItemActive : "transparent")};
  column-gap: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.primaryText};

  & svg {
    width: 16px;
    height: 16px;
  }
`;

const getMenuStyles = (theme: ThemeTypes) => ({
  transform: "translateY(10px)",
  left: "0",
  border: "none",
  padding: "12px 0",
  width: "166px",

  background: theme === "light" ? "#FFFFFF" : "#191B1F",
  boxShadow:
    theme === "light"
      ? "inset -2px -2px 4px rgba(0, 0, 0, 0.1)"
      : "4px 4px 8px rgba(0, 0, 0, 0.6), -4px -4px 8px rgba(73, 73, 73, 0.2)",
});

export default TextLayers;
