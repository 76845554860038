import React, { FC } from "react";

import { pricingSectionMock } from "../../../mocks/pricing";
import { PriceSection } from "../../../types/pricing";

interface PricingRightProps {
  activeSection: PriceSection;
  handleToggleClick: () => void;
  isToggle: boolean;
}

const PricingRight: FC<PricingRightProps> = ({ activeSection, handleToggleClick, isToggle }) => {
  return pricingSectionMock[activeSection].component({
    ...pricingSectionMock[activeSection],
    handleToggleClick,
    isToggle,
    activeSection,
  });
};

export default PricingRight;
