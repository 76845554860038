import React from "react";
import styled from "styled-components";

import Alert from "./Alert";
import Textfield from "../../../components/Textfield/Textfield";
import RadioButton from "../../../components/RadioButton/RadioButton";
import CircularProgress from "../../../components/Icons/CircularProgress";
import { Zone } from "../../../types/project";
import { SearchIcon } from "../../../components/Icons/Icons";
import { Status } from "../../../redux/actions/humansProjectActions";
import { useAiHumansTextTranslate } from "../../../hooks/useAiHumansTextTranslate";

interface Props {
  paragraph: Zone[];
  setMenuOpen: (id: number) => void;
}

const LanguageModal = ({ paragraph, setMenuOpen }: Props) => {
  const textInput = paragraph.map((p) => p.text);
  const {
    search,
    selectedLang,
    searchResults,
    languagesLoading,
    detectLoading,
    sourceLanguage,
    handleSearchChange,
    handleSelectLanguage,
  } = useAiHumansTextTranslate({ textInput, setMenuOpen });

  return (
    <Wrapper>
      <TextfieldWrapper>
        <Textfield value={search} placeholder="Search" startAdornment={<SearchIcon />} onChange={handleSearchChange} />
      </TextfieldWrapper>
      <ItemWrapper isLoading={detectLoading || languagesLoading}>
        {detectLoading || languagesLoading ? (
          <CircularProgress color="#009af7" />
        ) : sourceLanguage ? (
          searchResults.length ? (
            searchResults.map(({ language, name }, index: number) => (
              <Item key={language} onClick={() => handleSelectLanguage(language)}>
                {/* <img src={flag} alt={name} /> */}
                <span>{name}</span>
                <RadioButton
                  id={index}
                  //label={language}
                  value={selectedLang}
                  checked={language === selectedLang}
                  onChange={() => handleSelectLanguage(language)}
                />
              </Item>
            ))
          ) : (
            <span>Results not found</span>
          )
        ) : (
          <Alert status={Status.isError} closeIcon={false} text="Failed to recognise the language" />
        )}
      </ItemWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ItemWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  overflow: hidden auto;
  height: 100%;
  min-height: 216px;
  max-height: 216px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .alert-wrapper {
    position: absolute;
    padding: 12px 12px;
    border-radius: 4px;

    svg {
      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }

  & > span {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin: 15px auto;
  }

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }

  ${({ isLoading }) =>
    isLoading &&
    `
      align-items: center;
      justify-content: center;
    `}
`;

const Item = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  img {
    width: 16px;
    height: 12px;
    border-radius: 2px;
  }

  span {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.primaryText};
    margin-left: 8px;
  }

  div {
    margin-left: auto;
  }
`;

const TextfieldWrapper = styled.div`
  input {
    border-radius: 16px;
    padding: 12px 12px 12px 44px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

export default LanguageModal;
