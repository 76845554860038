export const UndoIcon = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.45412 7.50001C12.0171 7.50001 15.2311 9.13401 17.2641 11.759C16.3291 7.89001 12.7341 5.00001 8.45412 5.00001C7.90112 5.00001 7.45412 4.55301 7.45412 4.00001V2.07701L2.63012 6.25101L7.45412 10.463V8.50001C7.45412 7.94701 7.90112 7.50001 8.45412 7.50001ZM18.2211 17C17.7601 17 17.3581 16.685 17.2491 16.237C16.3591 12.588 13.2281 9.95201 9.45412 9.55301V10.674C9.45412 11.38 9.03512 12.009 8.36112 12.314C7.63012 12.647 6.77712 12.527 6.18412 12.01L1.11812 7.58601C0.725122 7.24201 0.500122 6.75501 0.500122 6.25001C0.500122 5.74501 0.725122 5.25801 1.11812 4.91401L6.18412 0.490013C6.77712 -0.0269868 7.63012 -0.146987 8.36112 0.186013C9.03512 0.491013 9.45412 1.12001 9.45412 1.82601V3.04401C15.0781 3.53701 19.5001 8.15001 19.5001 13.75C19.5001 14.56 19.3961 15.396 19.1921 16.236C19.0841 16.685 18.6831 17 18.2211 17Z"
      fill="url(#paint0_linear_50_2571)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_50_2571"
        x1="4.59243"
        y1="2.22268"
        x2="14.3344"
        y2="16.2213"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
