import React, { useState } from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";
import ActionMenu from "../../../components/ActionMenu/ActionMenu";
import { MenuIcon } from "../../../components/Icons/Icons";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { pages } from "../../../lib/routeUtils";
import { Link } from "react-router-dom";
import { UserPlan } from "../../../types/plans";
import { format, parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { cancelSubscriptionServer, getChangeSubscriptionsServer } from "../../../redux/actions/plansActions";
import { Popups, updatePopup } from "../../../redux/actions/popupsActions";
import { useSelector } from "react-redux";
import { getProfile } from "../../../redux/reducers/profileReducer";

const convertDate = (inputDate: string): string => {
  const dateObject = parseISO(inputDate);
  const formattedDate = format(dateObject, "dd MMMM yyyy");

  return formattedDate;
};

const splitStringByFirstDash = (inputString: string): [string, string] => {
  const parts = inputString.split("-");
  return [parts[0], parts.slice(1).join("-")];
};

interface Props {
  plan: UserPlan;
}

const PlanCard = ({ plan }: Props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();

  const theme = useTheme();
  const profile = useSelector(getProfile);

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleCancelSubscription = () => {
    // dispatch(cancelSubscriptionServer({ invoiceID: plan.invoiceID }));
    dispatch(
      updatePopup({
        popup: Popups.cancelSubscriptionPopup,
        status: true,
        prefilled: { nextInvoice: convertDate(plan.expiryDateTime), invoiceID: plan.invoiceID },
      }),
    );
  };

  const [tool, planName] = splitStringByFirstDash(plan.productName);

  const cardContent = [
    {
      label: "Tool",
      value: tool,
    },
    {
      label: "Plan",
      value: planName,
    },
    {
      label: "Period",
      value: plan.period,
    },
    {
      label: "Amount",
      value: `$${plan.price}`,
    },
    {
      label: "Next invoice date",
      value: convertDate(plan.expiryDateTime),
    },
    {
      label: "Status",
      value: plan.isCancelled ? "Cancelled" : plan.isActive ? "Active" : "",
    },
  ];

  return (
    <Card>
      <CardHeader>
        <Title>{plan.productName}</Title>
        <ActionsWrapper>
          {/* <ViewPlanText>View plan</ViewPlanText> */}
          {!plan.isCancelled && (
            <ActionMenu
              trigger={
                <IconButton
                  iconButtonTheme={IconButtonThemes.Transparent}
                  onClick={handleMenuOpen}
                  icon={<MenuIcon />}
                />
              }
              open={openMenu}
              handleClose={handleMenuClose}
              menuStyle={menuStyles(theme)}
            >
              {!profile.hidePricing && (
                <Link to={pages.pricingSubscriptions()}>
                  <MenuItem>Change/Add subscription</MenuItem>
                </Link>
              )}
              <MenuItem onClick={handleCancelSubscription}>Cancel subscription</MenuItem>
            </ActionMenu>
          )}
        </ActionsWrapper>
      </CardHeader>
      <CardContent>
        {cardContent.map((item, index) => (
          <>
            <Row key={item.label}>
              <ItemLabel>{item.label}</ItemLabel>
              <ItemValue>{item.value}</ItemValue>
            </Row>
            {index !== cardContent.length - 1 && <Divider />}
          </>
        ))}
      </CardContent>
    </Card>
  );
};

const Card = styled.div`
  border-radius: 16px;
  padding: 24px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 600;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
  position: relative;
`;

const ViewPlanText = styled.div`
  min-width: 75px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MenuItem = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;

const menuStyles = (theme: any) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  width: "auto",
  minWidth: "210px",
  padding: "12px",
  borderRadius: "12px",
  boxShadow: theme.cardShadow,
  background: theme.primaryBackground,
  border: "none",
});

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const ItemLabel = styled.div`
  color: ${({ theme }) => theme.buttonGreyText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const ItemValue = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #dfdfdf;
`;

export default PlanCard;
