export const EditIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98888 4.88155L4.24421 8.62622L4.06821 10.5969L6.05288 10.4162L9.78621 6.67888L7.98888 4.88155ZM11.9775 4.48555L10.1809 2.68955L8.88222 3.98822L10.6789 5.78555L11.9775 4.48555ZM3.39421 11.9969C3.37355 11.9989 3.35355 11.9995 3.33355 11.9995C3.15755 11.9995 2.98755 11.9302 2.86221 11.8042C2.72221 11.6642 2.65155 11.4696 2.66955 11.2729L2.92221 8.49222C2.95021 8.18755 3.08488 7.90022 3.30155 7.68355L9.29888 1.68555C9.76688 1.21622 10.6162 1.23955 11.1095 1.73222L12.9349 3.55755H12.9355C13.4462 4.06888 13.4662 4.88022 12.9809 5.36688L6.98288 11.3649C6.76621 11.5822 6.47955 11.7162 6.17421 11.7442L3.39421 11.9969ZM3.33341 13.333H12.6667C13.0334 13.333 13.3334 13.633 13.3334 13.9996C13.3334 14.3663 13.0334 14.6663 12.6667 14.6663H3.33341C2.96675 14.6663 2.66675 14.3663 2.66675 13.9996C2.66675 13.633 2.96675 13.333 3.33341 13.333Z"
      fill="url(#paint0_linear_1096_14237)"
    />
    <mask
      id="mask0_1096_14237"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="1"
      width="12"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98888 4.88155L4.24421 8.62622L4.06821 10.5969L6.05288 10.4162L9.78621 6.67888L7.98888 4.88155ZM11.9775 4.48555L10.1809 2.68955L8.88222 3.98822L10.6789 5.78555L11.9775 4.48555ZM3.39421 11.9969C3.37355 11.9989 3.35355 11.9995 3.33355 11.9995C3.15755 11.9995 2.98755 11.9302 2.86221 11.8042C2.72221 11.6642 2.65155 11.4696 2.66955 11.2729L2.92221 8.49222C2.95021 8.18755 3.08488 7.90022 3.30155 7.68355L9.29888 1.68555C9.76688 1.21622 10.6162 1.23955 11.1095 1.73222L12.9349 3.55755H12.9355C13.4462 4.06888 13.4662 4.88022 12.9809 5.36688L6.98288 11.3649C6.76621 11.5822 6.47955 11.7162 6.17421 11.7442L3.39421 11.9969ZM3.33341 13.333H12.6667C13.0334 13.333 13.3334 13.633 13.3334 13.9996C13.3334 14.3663 13.0334 14.6663 12.6667 14.6663H3.33341C2.96675 14.6663 2.66675 14.3663 2.66675 13.9996C2.66675 13.633 2.96675 13.333 3.33341 13.333Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1096_14237)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_1096_14237"
        x1="4.96418"
        y1="3.08932"
        x2="13.0999"
        y2="11.4667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);

export const EditIconProjectCardLarge = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.4165 1.16602H5.24984C2.33317 1.16602 1.1665 2.33268 1.1665 5.24935V8.74935C1.1665 11.666 2.33317 12.8327 5.24984 12.8327H8.74984C11.6665 12.8327 12.8332 11.666 12.8332 8.74935V7.58268"
      stroke="white"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.3566 1.76108L4.75993 6.35775C4.58493 6.53275 4.40993 6.87691 4.37493 7.12775L4.1241 8.88358C4.03076 9.51941 4.47993 9.96275 5.11576 9.87525L6.8716 9.62441C7.1166 9.58941 7.46076 9.41441 7.6416 9.23941L12.2383 4.64275C13.0316 3.84941 13.4049 2.92775 12.2383 1.76108C11.0716 0.594415 10.1499 0.967748 9.3566 1.76108Z"
      stroke="white"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.69727 2.41992C9.0881 3.81409 10.1789 4.90492 11.5789 5.30159"
      stroke="white"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
