import React, { useEffect, useState } from "react";
import withPrivateRoute from "../../hocs/withPrivateRoute";
import styled from "styled-components";
import SidebarLayout from "../../layouts/SidebarLayout";
import TrainingCard from "./components/TrainingCard";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export enum TrainingTab {
  voices = "AI Voices",
  humans = "AI Humans",
  images = "AI Images",
  translate = "Video Translate",
}

export enum VideoType {
  vimeo,
  youtube,
  pdf,
}

export interface IVideoType {
  id: number;
  type: VideoType;
  src: string;
  thumb?: string;
  title: string;
  subtitle?: string;
  subtitleSecond?: string;
}

const tabs = [
  {
    name: TrainingTab.voices,
    label: "AI Voices",
  },
  {
    name: TrainingTab.humans,
    label: "AI Humans",
  },
  {
    name: TrainingTab.images,
    label: "AI Images",
  },
  {
    name: TrainingTab.translate,
    label: "Video Translate",
  },
];

const TrainingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleTabChange = (newTab: TrainingTab) => {
    setActiveTab(newTab);
    const params = new URLSearchParams(location.search);
    params.set("training-filter", newTab);
    navigate(`?${params.toString()}`);
  };

  const handlePopupOpen = (src: string) => {
    dispatch(
      updatePopup({
        popup: Popups.vimeoPopup,
        status: true,
        prefilled: {
          src,
        },
      }),
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterValue = params.get("training-filter") || "";
    console.log(filterValue, "filterValue");
    setActiveTab(filterValue as TrainingTab);
    if (!filterValue) {
      const params = new URLSearchParams(location.search);
      params.set("training-filter", "AI Humans");
      navigate(`?${params.toString()}`);
    }
  }, [location.href]);

  return (
    <Wrapper>
      <SidebarLayout>
        <Content>
          <MobileOnly>
            <Sidebar mobile />
          </MobileOnly>
          <Title>Training</Title>
          <TabsWrapper>
            {tabs.map((tab) => (
              <Tab key={tab.name} isActive={activeTab === tab.name} onClick={() => handleTabChange(tab.name)}>
                {tab.label}
              </Tab>
            ))}
          </TabsWrapper>
          <CardsWrapper>
            {videos[activeTab]?.map((video: IVideoType) =>
              video.type === VideoType.pdf ? (
                <TrainingCard
                  key={video.id}
                  src={video.src}
                  thumb={video?.thumb}
                  title={video.title}
                  subtitle={video?.subtitle}
                  subtitleSecond={video?.subtitleSecond}
                  handleClick={() => handlePopupOpen(video.src)}
                  previewType="image"
                />
              ) : (
                <TrainingCard
                  key={video.id}
                  src={
                    video.type === VideoType.vimeo
                      ? `${video.src}&controls=0`
                      : `https://img.youtube.com/vi/${video.src}/0.jpg`
                  }
                  title={video.title}
                  subtitle={video?.subtitle}
                  subtitleSecond={video?.subtitleSecond}
                  handleClick={() =>
                    handlePopupOpen(
                      video.type === VideoType.youtube ? `https://www.youtube.com/embed/${video.src}` : video.src,
                    )
                  }
                  previewType={video.type === VideoType.vimeo ? "iframe" : "image"}
                />
              ),
            )}
          </CardsWrapper>
        </Content>
      </SidebarLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  width: 100%;
  height: 100vh;
  display: flex;
  font-family: "Mulish", sans-serif;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.primaryText};
`;

const TabsWrapper = styled.div`
  margin: 20px 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  gap: 2px;
  padding: 4px;
  box-shadow: ${({ theme }) => theme.inputShadow};
  border-radius: 60px;
  max-width: 100%;
  overflow: auto hidden;
  min-height: 48px;

  @media (max-width: 701px) {
    height: 90px;
  }

  ::-webkit-scrollbar {
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 0 20px;
  }
`;

const Tab = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  min-width: 140px;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? "600" : "500")};
  font-size: 14px;
  line-height: 24px;
  color: ${({ isActive, theme }) => (isActive ? theme.secondaryText : theme.primaryText)};
  border-radius: 52px;
  padding: 8px 24px;
  z-index: 1;
  background: ${({ isActive }) =>
    isActive ? "linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%)" : "transparent"};
  height: 100%;

  @media (max-width: 801px) {
    height: 34px;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 12px;
  padding-right: 30px;
  max-height: 100%;
  overflow: auto;

  & > div {
    width: calc(33% - 6px);
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 37px 0;
  }

  @media (max-width: 1201px) {
    max-width: 100%;
    & > div {
      width: calc(50% - 6px);
    }
    padding-right: 15px;
  }

  @media (max-width: 701px) {
    & > div {
      width: 100%;
    }
    padding-right: 10px;
  }
`;

const MobileOnly = styled.div`
  display: none;

  @media (max-width: 1001px) {
    display: flex;

    & > div {
      display: flex;
    }
  }
`;

export const videos = {
  [TrainingTab.voices]: [
    {
      id: 34,
      type: VideoType.youtube,
      src: "xO0_VlZ2gAg",
      title: "AI Voices - New Editor",
      subtitle: "Simple Mode: Create a simple voiceover with one voice and a simple text script.",
      subtitleSecond: "Advanced Mode: Add multiple voices to a single script to create interactive voiceovers.",
    },
    {
      id: 35,
      type: VideoType.youtube,
      src: "27JtfGRzJxE",
      title: "AI Voices - Speech to Speech",
      subtitle: "Change the voice of an existing audio with one of our best in class voice actors",
    },
    {
      id: 36,
      type: VideoType.youtube,
      src: "MTWnQjwzvfM",
      title: "AI Voices - Speech Translation",
      subtitle: "Translate an existing audio into 29 different languages",
    },
    {
      id: 2,
      type: VideoType.youtube,
      src: "Ehdfs-kYGrM",
      title: "Synthesys AI Voices - Choosing a Voice Actor",
      subtitle: "You can listen to the sample audio of the voice actors before you decide to use them for your project",
    },
    {
      id: 4,
      type: VideoType.youtube,
      src: "dDmM7n-CTnQ",
      title: "Synthesys AI Voices - Choose a language",
      subtitle: "Choose your desired language from our exhaustive list of 140+ supported languages.",
    },
    {
      id: 5,
      type: VideoType.youtube,
      src: "txnfe3QDQ4M",
      title: "Synthesys AI Voices - Pause control",
      subtitle:
        "Learn how to introduce extended pauses in between sentences to make your voiceover sound more natural and human-like.",
    },
    {
      id: 6,
      type: VideoType.youtube,
      src: "v3pkBV0qQU4",
      title: "Synthesys AI Voices - Speed Control",
      subtitle: "Learn how you can control the speed of the voiceover using the AI Voices Speed control feature",
    },
    {
      id: 7,
      type: VideoType.youtube,
      src: "YJcJD35bbVA",
      title: "Synthesys AI Voices - Pitch Control",
      subtitle:
        "Learn how to control the pitch of the voiceover without redoing the voiceover using the AI Voices Pitch Control feature.",
    },
    {
      id: 8,
      type: VideoType.youtube,
      src: "SXxY8futy9A",
      title: "Synthesys AI Voices - Access past projects in My Studio",
      subtitle:
        "You can find all your voiceovers in the My Studio section of your account to redownload them or make edits to them.",
    },
    {
      id: 9,
      type: VideoType.youtube,
      src: "uHaNaHuUU_c",
      title: "AI Voices GPT - How to find List of Voices and Create Voiceovers",
      subtitle: "See how to display the list of voices and create voiceovers directly using Synthesys AI Voices GPT",
    },
    {
      id: 10,
      type: VideoType.youtube,
      src: "cNi7TVtSp-0",
      title: "AI Voices GPT - Create Custom Voice",
      subtitle: "How to create Custom Voices directly using Synthesys AI Voices GPT",
    },
    {
      id: 11,
      type: VideoType.youtube,
      src: "mZT8lTnUEOg",
      title: "AI Voices GPT - Create Custom Voice",
      subtitle:
        "How to create exciting in voiceovers in multiple languages from Text Prompts in Synthesys AI Voices GPT",
    },
  ],
  [TrainingTab.humans]: [
    {
      id: 12,
      type: VideoType.vimeo,
      src: "https://player.vimeo.com/video/848700693?h=7f54cc5b0c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "Synthesys AI Humans - Complete Walkthrough",
      subtitle:
        "Learn how to use Synthesys AI Humans and the exciting features it offers to create engaging video content with speaking Human Avatars",
    },
    {
      id: 13,
      type: VideoType.youtube,
      src: "pXO8rLB2z7w",
      title: "Synthesys AI Humans - Choose Humatar",
      subtitle:
        "You can see how the humatars look like in motion by watching the sample videos available before you decide to use them for your video",
    },
    {
      id: 14,
      type: VideoType.youtube,
      src: "nWV3D07UYrA",
      title: "Synthesys AI Humans - Multiple Humatars",
      subtitle:
        "You can add more than one presenter to your video to create a more realistic and interactive video for your audience.",
    },
    {
      id: 15,
      type: VideoType.youtube,
      src: "UbCGvFvMFV0",
      title: "Synthesys AI Humans - Choose Voice Actors",
      subtitle: "You can choose from 370+ different voices across 140+ languages for your video ",
    },
    {
      id: 16,
      type: VideoType.youtube,
      src: "9ORVhvxl20E",
      title: "Synthesys AI Humans - Adding text",
      subtitle: "Learn how to add text options to your video in AI Humans",
    },
    {
      id: 17,
      type: VideoType.youtube,
      src: "5rcd7eO2oSw",
      title: "Synthesys AI Humans - Solid Color BG",
      subtitle:
        "You can add solid color backgrounds to your videos - Either choose from one of the preset color options or choose your own color from the color grid.",
    },
    {
      id: 18,
      type: VideoType.youtube,
      src: "9CO28jYkP4c",
      title: "Synthesys AI Humans - Image & Video Backgrounds",
      subtitle:
        "Learn how to add Images and videos as a background. You can also upload your own Image/video backgrounds.",
    },
    {
      id: 19,
      type: VideoType.youtube,
      src: "WIpAwUp-f_8",
      title: "Synthesys AI Humans - Transitions",
      subtitle:
        "You can add transitions to your slides to make your video more attractive and appealing to your customers.",
    },
    {
      id: 20,
      type: VideoType.youtube,
      src: "CqGXq1FmZaA",
      title: "Synthesys AI Humans - Download and Edit",
      subtitle: "Learn how you can download or edit your video after rendering.",
    },
    {
      id: 21,
      type: VideoType.youtube,
      src: "VFnOg2_7jMY",
      title: "Synthesys AI Humans - Translation",
      subtitle: "You can translate your text script to any of the 30+ languages AI Humans supports for translation.",
    },
    {
      id: 22,
      type: VideoType.youtube,
      src: "k6PMc2P_EdE",
      title: "Synthesys AI Humans - Upload Voice",
      subtitle:
        "If you have pre-recorded voice overs, you can upload them in AI Humans and our state-of-the-art AI Engine will make sure that the humatar is in perfect sync with the uploaded voiceover",
    },
    {
      id: 23,
      type: VideoType.youtube,
      src: "tYrmT7T0hkE",
      title: "Synthesys AI Humans - Background Soundtrack",
      subtitle: "Learn how to add a background soundtrack for your video. You can also upload your own soundtrack",
    },
    {
      id: 24,
      type: VideoType.vimeo,
      src: "https://player.vimeo.com/video/848700588?h=b8469a7f2f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "Synthesys AI Humans - Speed/pitch/pause",
      subtitle:
        "Learn more about the voice Speed/Pitch/Pause control features in AI Humans to make your video sound more realistic and human-like.",
    },
    {
      id: 25,
      type: VideoType.vimeo,
      src: "https://player.vimeo.com/video/848700635?h=ad0e668a03&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "Synthesys AI Humans - Import PDF",
      subtitle:
        "If you have a PDF presentation, you can upload it to AI Humans as a template and add Humatars to present the content. You can also convert PowerPoint presentation to PDF and then import it into AI Humans",
    },
    {
      id: 32,
      type: VideoType.pdf,
      thumb: "/images/training/pdf-image-32.png",
      src: "https://drive.google.com/file/d/1EPKie5KHEWZ7vXd6BwI-Ts-YsAOzTwDn/preview",
      title: "Custom Studio Avatars - Recording Instructions",
      subtitle:
        "Follow the instructions outlined in the training manual to record your video for Custom Studio Avatar creation for best results.",
    },
    {
      id: 33,
      type: VideoType.pdf,
      thumb: "/images/training/pdf-image-33.png",
      src: "https://drive.google.com/file/d/1DJmJEmDR07jx8qWCGobN14BlMetfwOKg/preview",
      title: "Instant Custom Avatars - Upload Instructions",
      subtitle:
        "Follow the instructions outlined in the training manual to upload your Instant Custom avatar and create exciting videos.",
    },
  ],
  [TrainingTab.images]: [
    {
      id: 26,
      type: VideoType.youtube,
      src: "nbz9ICHH4TE",
      title: "Synthesys AI Images - Create images using Text Prompts",
      subtitle: "Learn how to create copyright free images using simple text prompts in AI Images",
    },
    {
      id: 27,
      type: VideoType.youtube,
      src: "sDEKc0yiN6s",
      title: "Synthesys AI Images - Download Images",
      subtitle: "Download rendered images individually or as a set",
    },
    {
      id: 28,
      type: VideoType.youtube,
      src: "aHUoWiRQSak",
      title: "Synthesys AI Images - Save Images to Assets",
      subtitle: "Learn how to save images to assets to access it later in your account.",
    },
    {
      id: 29,
      type: VideoType.youtube,
      src: "SIbtKnLl3lc",
      title: "Synthesys AI Images - Variations",
      subtitle: "Create copyright free variations of existing images",
    },
    {
      id: 30,
      type: VideoType.youtube,
      src: "HIrKbNPmrmc",
      title: "Synthesys AI Images - InPainting",
      subtitle: "Make changes to an existing image with a text prompt and some Synthesys magic.",
    },
  ],
  [TrainingTab.translate]: [
    {
      id: 31,
      type: VideoType.vimeo,
      src: "https://player.vimeo.com/video/906048054?h=1d9b17b015&title=0&byline=0&portrait=0",
      title: "Synthesys Video Translate Demo",
      subtitle:
        "Translate any video into one of the 29 different languages, add avatars and lipsync to the translated video using the Synthesys Video Translate tool",
    },
  ],
};

export default withPrivateRoute(TrainingPage);
