import { Paragraphs, ProjectModules, Zone } from "../../types/project";

export const GET_PROJECT_SERVER = "GET_PROJECT_SERVER";
export const GET_PROJECT_LIST_SERVER = "GET_PROJECT_LIST_SERVER";
export const CREATE_PROJECT_SERVER = "CREATE_PROJECT_SERVER";
export const UPDATE_PROJECT_LOADING = "UPDATE_PROJECT_LOADING";
export const UPDATE_PROJECT_SERVER = "UPDATE_PROJECT_SERVER";
export const CLEAR_CURRENT_PROJECT = "CLEAR_CURRENT_PROJECT";
export const PLAY_AUDIO = "PLAY_AUDIO";
export const DELETE_PROJECT_SERVER = "DELETE_PROJECT_SERVER";
export const UPDATE_HAS_MORE_PROJECTS = "UPDATE_HAS_MORE_PROJECTS";
export const SET_PAGE_PROJECTS = "SET_PAGE_PROJECTS";
export const CLEAR_PROJECT_LIST = "CLEAR_PROJECT_LIST";
export const SET_CACHED_ZONES = "SET_CACHED_ZONES";
export const RENAME_PROJECT_SERVER = "RENAME_PROJECT_SERVER";

export const GET_PROJECT_LIST_REFRESH_SERVER = "GET_PROJECT_LIST_REFRESH_SERVER";

export const REDIRECT_TO_PROJECT = "REDIRECT_TO_PROJECT";

interface UpdateProjectLoadingProps {
  module: ProjectModules;
  isLoading: boolean;
}

export interface GetProjectListServerProps {
  keyword?: string;
  pageSize?: number;
  pageNumber?: number;
  projectTypeId?: ProjectType;
  status?: number | null;
  isStatusLoading?: boolean;
  isHistoryPage?: boolean;
}

interface UpdateHasMoreProjectsProps {
  hasMore: boolean;
}
interface SetPageProjectsProps {
  pageNumber: number;
}

export enum ProjectType {
  TTI = 1, //AI Voices
  HSS = 2, //AI Humans
  FS = 3, //Faceswap
  T2I = 4, //Synthesys Visual
  BG = 5, //Change Image/Video Background
}

interface CreateProjectServerProps {
  projectTypeId: ProjectType | undefined;
  projectId?: number;
  title: string | undefined;
  paragraphs?: Paragraphs[];
  isSimple?: boolean;
}

interface RedirectToProjectProps {
  projectName: string;
  projectId?: number;
}

export const updateHasMoreProjects = ({ hasMore }: UpdateHasMoreProjectsProps) => ({
  type: UPDATE_HAS_MORE_PROJECTS,
  payload: {
    hasMore,
  },
});

export const setPageProjects = ({ pageNumber }: SetPageProjectsProps) => ({
  type: SET_PAGE_PROJECTS,
  payload: { pageNumber },
});

export const updateProjectLoading = ({ module, isLoading }: UpdateProjectLoadingProps) => ({
  type: UPDATE_PROJECT_LOADING,
  payload: {
    module,
    isLoading,
  },
});

export const getProjectListServer = ({
  keyword,
  pageSize,
  pageNumber,
  projectTypeId,
  status,
  isStatusLoading,
  isHistoryPage,
}: GetProjectListServerProps) => ({
  type: GET_PROJECT_LIST_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/list",
      data: {
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 10,
        keyword,
        projectTypeId: projectTypeId || 1,
        status,
        sortWith: "updateDateTime",
        sortByDesc: true,
      },
      isStatusLoading,
      isHistoryPage,
    },
  },
});

export const getProjectListRefreshServer = () => ({
  type: GET_PROJECT_LIST_REFRESH_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/list",
      data: {
        pageNumber: 1,
        pageSize: 40,
        keyword: "",
        projectTypeId: 2,
        status: 0,
        sortWith: "updateDateTime",
        sortByDesc: true,
      },
    },
  },
});

export const setCachedZones = ({ zones }: { zones: Zone[] }) => ({
  type: SET_CACHED_ZONES,
  payload: {
    zones,
  },
});

export const clearCurrentProject = () => ({
  type: CLEAR_CURRENT_PROJECT,
});

export const clearProjectList = () => ({
  type: CLEAR_PROJECT_LIST,
});

export const getProjectServer = (projectId: number) => ({
  type: GET_PROJECT_SERVER,
  payload: {
    request: {
      method: "GET",
      url: `/project/get?Id=${projectId}`,
    },
  },
});

export const createProjectServer = ({ projectTypeId, title, paragraphs, isSimple }: CreateProjectServerProps) => ({
  type: CREATE_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/create",
      data: {
        projectTypeId,
        title,
        paragraphs,
        isSimple,
      },
    },
  },
});

export const updateProjectServer = (
  projectTypeId: ProjectType | undefined,
  projectId?: number,
  title?: string | undefined,
  paragraphs?: Paragraphs[],
  isSimple?: boolean,
  event?: "projectSavingEvent",
) => ({
  type: UPDATE_PROJECT_SERVER,
  payload: {
    event,
    request: {
      method: "POST",
      url: "/project/update",
      data: {
        projectId,
        projectTypeId,
        title,
        paragraphs,
        isSimple,
      },
    },
  },
});

interface DeleteProjectProps {
  projectId: string;
  projectTypeId: number;
  status?: number;
  redirectTo?: boolean;
  pageSize?: number;
}

export const deleteProjectserver = ({
  projectId,
  projectTypeId,
  status,
  redirectTo,
  pageSize,
}: DeleteProjectProps) => ({
  type: DELETE_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: `/project/delete?Id=${projectId}`,
    },
    projectTypeId,
    status,
    redirectTo,
    pageSize,
  },
});

interface RenameProjectServerProps {
  projectTypeId: ProjectType;
  projectId?: number;
  newTitle: string;
  oldTitle: string;
}

export const renameProjectServer = ({ projectId, projectTypeId, newTitle, oldTitle }: RenameProjectServerProps) => ({
  type: RENAME_PROJECT_SERVER,
  payload: {
    request: {
      method: "POST",
      url: "/project/rename",
      data: {
        projectId,
        projectTypeId,
        title: newTitle,
      },
    },
    oldTitle,
  },
});

export const playAudio = () => ({
  type: PLAY_AUDIO,
});

export const redirectToProject = ({ projectName, projectId }: RedirectToProjectProps) => ({
  type: REDIRECT_TO_PROJECT,
  payload: { projectName, projectId },
});
