export const GET_TRANSITIONS_SERVER = "GET_TRANSITIONS_SERVER";
export const UPDATE_TRANSITION_LOADING = "UPDATE_TRANSITION_LOADING";

interface UpdateTransitionLoadingProps {
  isLoading: boolean;
}

export const updateTransitionLoading = ({ isLoading }: UpdateTransitionLoadingProps) => ({
  type: UPDATE_TRANSITION_LOADING,
  payload: {
    isLoading,
  },
});

export const getTransitionsServer = () => ({
  type: GET_TRANSITIONS_SERVER,
  payload: {
    request: {
      method: "GET",
      url: "/TransitionEffect/list",
    },
  },
});
