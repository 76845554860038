import { toast } from "react-toastify";
import { put, select, takeEvery } from "redux-saga/effects";
import { HumatarModules } from "../../types/humatar";
import {
  BOOKMARK_HUMATAR_SERVER,
  CLEAR_SEARCH,
  DELETE_CUSTOM_HUMATAR_SERVER,
  GET_HUMATARS_SERVER,
  GET_HUMATAR_PREVIEW_SERVER,
  UPLOAD_HUMATAR_SERVER,
  clearHumatars,
  filterHumatarLoading,
  getHumatarsServer,
  searchHumatarResultLoading,
  updateHasMoreHumatars,
  updateHumatarLoading,
} from "../actions/humatarActions";
import { getHumatarList } from "../reducers/humatarReducer";
import { SentryErrors, sentryErrors } from "../../lib/sentry";

const humatarSagas = [
  takeEvery(`${GET_HUMATARS_SERVER}`, handleGetHumatarsServer),
  takeEvery(`${GET_HUMATARS_SERVER}_FAIL`, handleGetHumatarsServerFail),
  takeEvery(`${GET_HUMATARS_SERVER}_SUCCESS`, handleGetHumatarsServerSuccess),

  takeEvery(`${BOOKMARK_HUMATAR_SERVER}_FAIL`, handleBookmarkHumatarServerFail),
  takeEvery(`${BOOKMARK_HUMATAR_SERVER}_SUCCESS`, handleBookmarkHumatarServerSuccess),

  takeEvery(GET_HUMATAR_PREVIEW_SERVER, handleGetHumatarPreviewServer),
  takeEvery(`${GET_HUMATAR_PREVIEW_SERVER}_FAIL`, handleGetHumatarPreviewServerFail),
  takeEvery(`${GET_HUMATAR_PREVIEW_SERVER}_SUCCESS`, handleGetHumatarPreviewServerSuccess),

  takeEvery(UPLOAD_HUMATAR_SERVER, handleUploadHumatarServer),
  takeEvery(`${UPLOAD_HUMATAR_SERVER}_FAIL`, handleUploadHumatarServerFail),
  takeEvery(`${UPLOAD_HUMATAR_SERVER}_SUCCESS`, handleUploadHumatarServerSuccess),

  takeEvery(`${DELETE_CUSTOM_HUMATAR_SERVER}_SUCCESS`, handleDeleteCustomHumatarServerSuccess),
  takeEvery(`${DELETE_CUSTOM_HUMATAR_SERVER}_FAIL`, handleDeleteCustomHumatarServerFail),

  takeEvery(CLEAR_SEARCH, handleClearSearch),
];

function* handleGetHumatarsServer() {
  yield put(updateHumatarLoading({ module: HumatarModules.humatarList, isLoading: true }));
}

function* handleGetHumatarsServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_HUMATARS.toast);
  yield put(updateHumatarLoading({ module: HumatarModules.humatarList, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_HUMATARS,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetHumatarsServerSuccess(action: any) {
  const allHumatars = getHumatarList(yield select());
  const totalLength = action.payload.data.totalRecords;

  if (allHumatars.length >= totalLength) yield put(updateHasMoreHumatars({ hasMore: false }));

  yield put(updateHumatarLoading({ module: HumatarModules.humatarList, isLoading: false }));
  yield put(searchHumatarResultLoading(false));
  yield put(filterHumatarLoading(false));
}

function* handleClearSearch(action: any) {
  yield put(clearHumatars());
  yield put(
    getHumatarsServer({
      keyword: "",
      pageNumber: 1,
      categoryType: [],
      voiceAge: [],
      region: [],
      category: action.payload ? [`${action.payload}`] : ["Custom", "Seated", "Humatars"],
    }),
  );
}

function* handleBookmarkHumatarServerFail() {
  yield toast.error("Error while humatar bookmarked");
}

function* handleBookmarkHumatarServerSuccess(action: any) {
  const isBookmarked = action.meta.previousAction.payload.isScreen;

  if (isBookmarked) {
    yield put(clearHumatars());
    yield put(filterHumatarLoading(true));
    yield put(
      getHumatarsServer({ pageNumber: 1, bookmarked: isBookmarked, category: ["Custom", "Seated", "Humatars"] }),
    );
  }
}

function* handleGetHumatarPreviewServer() {
  yield put(updateHumatarLoading({ module: HumatarModules.humatarPreview, isLoading: true }));
}

function* handleGetHumatarPreviewServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_GETTING_HUMATAR_PREVIEW.toast);
  yield put(updateHumatarLoading({ module: HumatarModules.humatarPreview, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_GETTING_HUMATAR_PREVIEW,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleGetHumatarPreviewServerSuccess() {
  yield put(updateHumatarLoading({ module: HumatarModules.humatarPreview, isLoading: false }));
}

function* handleUploadHumatarServer() {
  yield put(updateHumatarLoading({ module: HumatarModules.uploadHumatar, isLoading: true }));
}

function* handleUploadHumatarServerFail(action: any) {
  yield toast.error(SentryErrors.SERVER_ERROR_WHILE_UPLOADING_HUMATAR.toast);
  yield put(updateHumatarLoading({ module: HumatarModules.uploadHumatar, isLoading: false }));

  yield sentryErrors({
    errorType: SentryErrors.SERVER_ERROR_WHILE_UPLOADING_HUMATAR,
    details: {
      responseFromApi: action?.error?.response?.data || null,
    },
  });
}

function* handleUploadHumatarServerSuccess(action: any) {
  const { noRefresh } = action.meta.previousAction.payload;
  yield put(updateHumatarLoading({ module: HumatarModules.uploadHumatar, isLoading: false }));
  yield toast.success("The humatar has been successfully added.");

  if (noRefresh) return;

  yield put(clearHumatars());
  yield put(
    getHumatarsServer({
      keyword: "",
      pageNumber: 1,
      category: ["Custom"],
    }),
  );
}

function* handleDeleteCustomHumatarServerSuccess(action: any) {
  const { previousFill } = action.meta.previousAction.payload;
  const { requestForHumatarServer } = previousFill;

  yield put(clearHumatars());
  yield put(getHumatarsServer(requestForHumatarServer));
  yield toast.success("The humatar has been deleted");
}

function* handleDeleteCustomHumatarServerFail() {
  yield toast.error("Error while deleting humatar");
}

export default humatarSagas;
