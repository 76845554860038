import styled from "styled-components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Button from "../Button/Button";
import Tooltip from "../Tooltip/Tooltip";
import CircularProgress from "../Icons/CircularProgress";
import useVoiceGeneration from "../../hooks/useVoiceGeneration";
import IconButton, { IconButtonThemes } from "../Button/IconButton";
import { EditorSelect } from "../../mocks/actors";
import { Paragraphs, Zone } from "../../types/project";
import { GenerateAudioParams } from "../../types/generateAudio";
import { getProfile } from "../../redux/reducers/profileReducer";
import { formatNumberToDuration } from "../../lib/formatDuration";
import { ImportLightIcon, NextIcon, PrevIcon } from "../Icons/Icons";
import { getProjectAudio } from "../../redux/reducers/projectReducer";
import { generateVoiceServer } from "../../redux/actions/actorActions";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { checkIfZoneCached, checkIfZonesMatch, getAllZones } from "../../lib/editorUtils";

interface Props {
  projectId?: number;
  paragraphs: Paragraphs[];
  selectedZone?: Zone;
  editorSelect: string;
  signUpPopupOpen: () => void;
}

const Player = ({ projectId, paragraphs, selectedZone, editorSelect, signUpPopupOpen }: Props) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const isGuest = profile.roleId === 3;
  const { cachedZonesAudio } = useSelector(getProjectAudio);
  const { Simple } = EditorSelect;

  const generateZoneByIndex = (startZone: number) => {
    generateAudio({
      startZone,
    });
  };

  const {
    audioPlayer,
    onPlaying,
    onLoadedMetadata,
    onLoadStart,
    handleBackClick,
    playing,
    isFetching,
    audioLoading,
    currentTime,
    duration,
    handlePlayClick,
    seekValue,
    handleProgressBarChange,
    handleForwardClick,
  } = useVoiceGeneration({
    paragraphs,
    selectedZone,
    generateZoneByIndex,
  });

  const generateAudio = ({ startZone }: GenerateAudioParams) => {
    // const paragraphsModified = removeEmptyParagrahs(removeEmptyZones(JSON.parse(JSON.stringify(paragraphs))));
    const allZones = getAllZones(paragraphs);
    const currentZone = startZone || startZone === 0 ? allZones[startZone] : selectedZone;

    if (currentZone) {
      const currentZoneIndex = allZones.findIndex((zone) => checkIfZonesMatch(zone, currentZone));
      const finalCurrentZone = allZones[currentZoneIndex];
      const isZoneCached = checkIfZoneCached(finalCurrentZone, cachedZonesAudio);
      if (!isZoneCached) {
        dispatch(generateVoiceServer({ data: [finalCurrentZone] }));
      } else {
        // TODO: sentry error - this exxception shouldn't be called
      }
    } else {
      // TODO: sentry error - this exxception shouldn't be called
    }
  };

  const handleOpenPopup = () => {
    if (!projectId) toast.error("Please save the project for download of the generated voice.");
    else {
      dispatch(
        updatePopup({
          popup: Popups.estimatedPopup,
          status: true,
        }),
      );
    }
  };

  return (
    <Wrapper>
      <audio ref={audioPlayer} onTimeUpdate={onPlaying} onLoadedMetadata={onLoadedMetadata} onLoadStart={onLoadStart} />
      <ManageButton>
        <IconButton
          disabled={editorSelect === Simple}
          iconButtonTheme={IconButtonThemes.Transparent}
          icon={<PrevIcon />}
          onClick={handleBackClick}
        />
        <PlayButton>
          <Button
            text={
              isFetching || audioLoading ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress color="#F0F0F3" />
                </div>
              ) : !playing || currentTime === duration ? (
                "Generate"
              ) : (
                "Pause"
              )
            }
            onClick={handlePlayClick}
          />
        </PlayButton>
        <IconButton
          disabled={editorSelect === Simple}
          iconButtonTheme={IconButtonThemes.Transparent}
          icon={<NextIcon />}
          onClick={handleForwardClick}
        />
      </ManageButton>
      <ProgressWrapper>
        <ProgressBar>
          <ProgressBarLine
            type="range"
            min="0"
            max="100"
            step="1"
            value={seekValue || 0}
            width={seekValue || 0}
            onChange={handleProgressBarChange}
          />
        </ProgressBar>
        <ProgressCount>
          {formatNumberToDuration(currentTime)} <span> / {formatNumberToDuration(duration)}</span>
        </ProgressCount>
      </ProgressWrapper>
      <WatermarkTrack>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={<ImportLightIcon />}
          onClick={!projectId ? handleOpenPopup : isGuest ? signUpPopupOpen : handleOpenPopup}
        />
        <Tooltip text="Download" />
      </WatermarkTrack>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 16px;
  padding: 13px;
  align-items: center;

  @media (max-width: 1000px) {
    margin: 24px 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ManageButton = styled.div`
  display: flex !important;
  align-items: center;
  gap: 4px;
  width: 156px;
  order: 1;
  margin-right: 16px;

  @media (max-width: 1000px) {
    order: 2;
    width: auto;
  }
`;

const PlayButton = styled.div`
  position: relative;
  width: 100px;

  & > button {
    height: 36px;
    font-size: 14px;
    background: #0063b4;
    box-shadow: none;

    .loader {
      display: flex;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const ProgressWrapper = styled.div`
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
  order: 2;

  @media (max-width: 1000px) {
    order: 1;
    width: 100%;
    margin-bottom: 12px;
  }
`;

const ProgressBar = styled.div`
  height: 12px;
  width: calc(100% - 100px);
  position: relative;
  background: #f0f0f3;
  box-shadow: -5px -5px 4px 0px #ffffff4d inset, 5px 5px 4px 0px #aeaec033 inset;
  border-radius: 60px;
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-left: 2px;
  padding-right: 2px;
`;

const ProgressBarLine = styled.input<{ width: number }>`
  background: ${({ theme, width }) => `linear-gradient(to right, #0063b4 ${width}%, transparent 0)`};
  border-radius: 52px;
  width: 100%;
  height: 8px;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0px;
    height: 0px;
    cursor: pointer;
  }
`;

const ProgressCount = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #191b1f;
  display: flex;
  margin-left: 19px;

  span {
    color: #191b1f66;
    display: flex;
    margin-left: 5px;
  }
`;

const WatermarkTrack = styled.div`
  display: flex;
  position: relative;
  order: 3;
  margin-left: 13px;
  max-width: 36px;
  width: 100%;

  :hover > div {
    top: -44px;
    right: -13px;
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 7px;
    min-width: max-content;
    box-shadow: none;
    background-color: #fff;
  }

  button {
    background: #0063b4;
    box-shadow: none;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 1000px) {
    order: 3;
    margin: 0;
    max-width: 36px;
    width: 100%;
  }
`;

export default Player;
