export const products = [
  {
    title: "AI Voices",
    description: "text to speech",
    image: "/images/home/ai-voices-popup.png",
    link: "/actors",
  },
  {
    title: "AI Humans",
    description: "text to video",
    image: "/images/home/ai-humans-popup.png",
    link: "/ai-humans",
  },
  {
    title: "AI Images",
    description: "Text to image",
    image: "/images/home/ai-images-popup.png",
    link: "/ai-images",
  },
];
