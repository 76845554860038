import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../../../components/Button/IconButton";
import { Avatar } from "../../../../../types/scene";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

interface Props {
  handleSceneObjectChange: (key: string, value: any, id: number) => void;
  currentObject: Avatar;
}

const AvatarBackground = ({ handleSceneObjectChange, currentObject }: Props) => {
  const [value, setValue] = useState("");
  const ref = useRef(null);

  const handleColoChange = (event: any) => {
    if (!currentObject) return;
    handleSceneObjectChange("background", event.target.value, currentObject.id);
    setValue(event.target.value);
  };

  const handleClick = () => {
    if (!ref.current) return;
    (ref.current as any).click();
  };

  useEffect(() => {
    if (!currentObject) return setValue("");
    setValue(currentObject.background as string);
  }, [currentObject]);

  return (
    <div>
      <ButtonWrapper>
        <IconButton
          iconButtonTheme={IconButtonThemes.Rounded}
          icon={<ColorInput ref={ref} type="color" onChange={handleColoChange} value={value} />}
          onClick={handleClick}
        />
        <Tooltip
          text={"Background"}
          position="bottom"
          style={{ boxShadow: "none", borderRadius: "4px", padding: "4px 8px 6px" }}
          reverseColor
          arrow
        />
      </ButtonWrapper>
    </div>
  );
};

const ButtonWrapper = styled.div`
  position: relative;

  & > div {
    width: max-content;
  }

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  button {
    position: relative;
    z-index: 1;
    box-shadow: ${({ theme }) => theme.secondaryButtonShadow};
    background: ${({ theme }) => theme.primaryBackground};
    border-radius: 12px;
    max-width: 48px;
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
    }
  }
`;

const ColorInput = styled.input`
  -webkit-appearance: none;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  &::-webkit-color-swatch {
    border: 1px solid ${({ theme }) => theme.primaryText};
    border-radius: 50%;
    padding: 0;
  }

  &::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
  }
`;

export default AvatarBackground;
