export const extractThumbnail = async (video: HTMLVideoElement): Promise<Blob | null> => {
  try {
    await video.play();

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");

    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      return new Promise<Blob | null>((resolve) => {
        canvas.toBlob((blob) => resolve(blob), "image/png");
      });
    } else {
      console.error("Canvas context not available.");
      return null;
    }
  } catch (error) {
    console.error("Error extracting thumbnail:", error);
    return null;
  }
};
