import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { SmallCheckIcon } from "../../../components/Icons/SmallCheckIcon";
import { setNewPlanServer } from "../../../redux/actions/sumoActions";
import { getPlanLinkLoading } from "../../../redux/reducers/sumoReducer";
import CircularProgress from "../../../components/Icons/CircularProgress";

interface Props {
  title: string;
  pros: string[];
  buttonText: string;
  isCurrent?: boolean;
}

const SumoLicenseCard: FC<Props> = ({ title, pros, isCurrent, buttonText }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getPlanLinkLoading);

  const handleClickPlan = () => {
    dispatch(setNewPlanServer());
  };

  const getButtonText = () => (isCurrent ? "Your current plan" : buttonText);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <ProsWrapper>
        {pros.map((elem) => (
          <Pros key={elem}>
            <IconWrapper>
              <SmallCheckIcon />
            </IconWrapper>
            <p dangerouslySetInnerHTML={{ __html: elem }} />
          </Pros>
        ))}
      </ProsWrapper>
      <Button isCurrent={isCurrent} disabled={isCurrent || isLoading} onClick={handleClickPlan}>
        {isLoading && !isCurrent ? <CircularProgress color="#009af7" /> : <p>{getButtonText()}</p>}
      </Button>
    </Wrapper>
  );
};

const IconWrapper = styled("div")`
  margin-top: 3px;
`;

const Pros = styled("div")`
  display: flex;
  gap: 8px;

  p {
    color: #3b3d47;

    font-size: 13px;
    font-weight: 500;
    line-height: 22px;

    b {
      color: ${({ theme }) => theme.primaryText};
      font-weight: 600;
    }
  }
`;

const Button = styled("button")<{ isCurrent?: boolean }>`
  margin-top: auto;
  width: 100%;
  padding: 0 14px;
  min-height: 48px;
  border-radius: 12px;
  box-shadow: 5px 5px 10px 0px rgba(174, 174, 192, 0.3), -5px -5px 10px 0px #fff, 2px 2px 4px 0px #fff inset,
    -2px -2px 4px 0px rgba(0, 0, 0, 0.1) inset;

  p {
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.41px;
    background: linear-gradient(142deg, #0063b4 0%, #009af7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:disabled {
    opacity: 1;
  }

  ${({ isCurrent }) =>
    isCurrent &&
    `
    box-shadow: -5px -5px 4px 0px rgba(255, 255, 255, 0.3) inset, 5px 5px 4px 0px rgba(174, 174, 192, 0.20) inset;
    p {
      color: #6F7074;
      background: unset;
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
    }
  `};
`;

const ProsWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Title = styled("h5")`
  color: ${({ theme }) => theme.primaryText};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  max-width: 270px;
  gap: 16px;
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
`;

export default SumoLicenseCard;
