import styled from "styled-components";

interface ISectionsStackProps {
  children: React.ReactNode;
  mobileTopPadding?: boolean;
  addTopSpace?: string;
}

const SectionsStack = ({ children, mobileTopPadding, addTopSpace }: ISectionsStackProps) => {
  return (
    <Stack mobileTopPadding={mobileTopPadding} addTopSpace={addTopSpace}>
      {children}
    </Stack>
  );
};

const Stack = styled.div<{ mobileTopPadding?: boolean; addTopSpace?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 140px;
  padding-top: 40px;
  padding-bottom: 140px;
  margin-top: ${({ addTopSpace }) => (addTopSpace ? addTopSpace : "0")};

  @media screen and (max-width: 999px) {
    gap: 100px;
    padding-top: ${({ mobileTopPadding }) => (mobileTopPadding ? "16px" : "0")};
    padding-bottom: 100px;
  }
`;

export default SectionsStack;
