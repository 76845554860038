export const MenuIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3.99988 12C3.99988 12.2652 4.10523 12.5196 4.29277 12.7071C4.48031 12.8946 4.73466 13 4.99988 13C5.26509 13 5.51945 12.8946 5.70698 12.7071C5.89452 12.5196 5.99988 12.2652 5.99988 12C5.99988 11.7348 5.89452 11.4804 5.70698 11.2929C5.51945 11.1054 5.26509 11 4.99988 11C4.73466 11 4.48031 11.1054 4.29277 11.2929C4.10523 11.4804 3.99988 11.7348 3.99988 12Z"
      fill="url(#paint0_linear_0_308)"
    />
    <path
      d="M10.9999 12C10.9999 12.2652 11.1052 12.5196 11.2928 12.7071C11.4803 12.8946 11.7347 13 11.9999 13C12.2651 13 12.5194 12.8946 12.707 12.7071C12.8945 12.5196 12.9999 12.2652 12.9999 12C12.9999 11.7348 12.8945 11.4804 12.707 11.2929C12.5194 11.1054 12.2651 11 11.9999 11C11.7347 11 11.4803 11.1054 11.2928 11.2929C11.1052 11.4804 10.9999 11.7348 10.9999 12Z"
      fill="url(#paint1_linear_0_308)"
    />
    <path
      d="M17.9999 12C17.9999 12.2652 18.1052 12.5196 18.2928 12.7071C18.4803 12.8946 18.7347 13 18.9999 13C19.2651 13 19.5194 12.8946 19.707 12.7071C19.8945 12.5196 19.9999 12.2652 19.9999 12C19.9999 11.7348 19.8945 11.4804 19.707 11.2929C19.5194 11.1054 19.2651 11 18.9999 11C18.7347 11 18.4803 11.1054 18.2928 11.2929C18.1052 11.4804 17.9999 11.7348 17.9999 12Z"
      fill="url(#paint2_linear_0_308)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_308"
        x1="7.44603"
        y1="11.2615"
        x2="7.68146"
        y2="13.6831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_0_308"
        x1="7.44603"
        y1="11.2615"
        x2="7.68146"
        y2="13.6831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_0_308"
        x1="7.44603"
        y1="11.2615"
        x2="7.68146"
        y2="13.6831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
