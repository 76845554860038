export const Microphone = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6001 29.24C9.88015 29.24 4.40015 23.7733 4.40015 17.04V14.5333C4.40015 14.0133 4.82681 13.6 5.33348 13.6C5.84015 13.6 6.26681 14.0267 6.26681 14.5333V17.04C6.26681 22.7333 10.8935 27.36 16.5868 27.36C22.2801 27.36 26.9068 22.7333 26.9068 17.04V14.5333C26.9068 14.0133 27.3335 13.6 27.8401 13.6C28.3468 13.6 28.7735 14.0267 28.7735 14.5333V17.04C28.8001 23.7733 23.3201 29.24 16.6001 29.24Z"
      fill="#00B8E0"
    />
    <path
      d="M16.6001 2.66667C12.1201 2.66667 8.4668 6.32 8.4668 10.8V17.0533C8.4668 21.5333 12.1201 25.1867 16.6001 25.1867C21.0801 25.1867 24.7335 21.5333 24.7335 17.0533V10.8C24.7335 6.32 21.0801 2.66667 16.6001 2.66667ZM19.5068 14.12C19.4135 14.48 19.0801 14.72 18.7201 14.72C18.6535 14.72 18.5735 14.7067 18.5068 14.6933C17.1468 14.32 15.7068 14.32 14.3468 14.6933C13.9068 14.8133 13.4668 14.56 13.3468 14.12C13.2268 13.6933 13.4801 13.24 13.9201 13.12C15.5601 12.6667 17.2935 12.6667 18.9335 13.12C19.3735 13.24 19.6268 13.68 19.5068 14.12ZM20.6401 10.4267C20.5201 10.76 20.2135 10.96 19.8801 10.96C19.7868 10.96 19.6935 10.9467 19.6001 10.9067C17.5601 10.16 15.3201 10.16 13.2801 10.9067C12.8535 11.0667 12.3868 10.8533 12.2268 10.4267C12.0801 10.0133 12.2935 9.54667 12.7201 9.38667C15.1201 8.52 17.7601 8.52 20.1468 9.38667C20.5735 9.54667 20.7868 10.0133 20.6401 10.4267Z"
      fill="#00B8E0"
    />
  </svg>
);
