import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { categoriesHumans } from "../../mocks/character";

import Button, { ButtonThemes } from "../Button/Button";
import CharacterCategory from "../CharacterCategory/CharacterCategory";
import CharacterSidebar from "../CharacterSidebar/CharacterSidebar";
import Chip from "../Chip/Chip";
import HumanCard from "../HumanCard/HumanCard";
import Modal from "../Modal/Modal";
import Textfield from "../Textfield/Textfield";

import {
  ClockIcon,
  CloseIconV2,
  DownloadIcon,
  LikeActiveIcon,
  LikeIcon,
  SearchFilterIcon,
  SearchIcon,
  StarIcon,
} from "../Icons/Icons";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  filterResultLoading,
  getCurrentPageHumatars,
  getHasMoreHumatars,
  getHumatarList,
  searchResultLoading,
} from "../../redux/reducers/humatarReducer";
import { Humatar, HumatarPrefilled, HumatarPreviewPrefilled } from "../../types/humatar";
import CardsLoader from "../CardsLoader/CardsLoader";
import { useDispatch } from "react-redux";
import {
  bookmarkHumatarServer,
  clearHumatars,
  clearSearch,
  filterHumatarLoading,
  getHumatarsServer,
  searchHumatarResultLoading,
} from "../../redux/actions/humatarActions";
import { Filter } from "../../types/filter";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import { VideoEditorContext } from "../../App";
import { Popups, updatePopup } from "../../redux/actions/popupsActions";
import { ActorPositionTypes } from "../../types/scene";
import { getPrefilled } from "../../redux/reducers/popupsReducer";
import { ConfirmationDelete, DeleteType } from "../../types/confirmationDelete";

export type SetSelectedActor = (humatar: Humatar) => void;

export interface FilterAIHumans {
  categoryType: string[];
  voiceAge: string[];
  isFeMale: string[];
  region: string[];
}

interface Props {
  canvasSize: { width: number; height: number };
  open: boolean;
  onClose: () => void;
}

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

const searchTimeoutDelay = 500;

const AIHumansPopup = ({ canvasSize, open, onClose }: Props) => {
  const { handleAddAvatar, actorPositionType } = useContext(VideoEditorContext);
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const humatarPrefilled: HumatarPrefilled = useSelector(getPrefilled);
  const [screen, setScreen] = useState({
    popular: false,
    history: false,
    bookmark: false,
  });
  const humans = useSelector(getHumatarList);
  const hasMore = useSelector(getHasMoreHumatars);
  const currentPage = useSelector(getCurrentPageHumatars);
  const searchLoading = useSelector(searchResultLoading);
  const filterLoading = useSelector(filterResultLoading);
  const [active, setActive] = useState<Humatar>();
  const [search, setSearch] = useState("");
  const [openMobileSelectList, setOpenMobileSelectList] = useState<boolean>(false);
  const [windowDimensions, setWindowDimensions] = useState<Record<string, number>>(getWindowDimensions());
  const setSelectedActor: SetSelectedActor = (humatar: Humatar) => {
    setActive(humatar);
  };

  const prefilled = humatarPrefilled && humatarPrefilled.type ? humatarPrefilled : humatarPrefilled?.previousFill;

  const [filters, setFilters] = useState<Filter>({
    categoryType: [],
    voiceAge: [],
    isFeMale: [],
    mood: [],
    content: [],
    region: [],
    country: [],
    language: [],
    category: [],
  });

  const allFilters = filters.categoryType
    .concat(filters.voiceAge, filters.isFeMale, filters.region, filters.category)
    .map((item) => {
      const filterObjArray = Object.entries(filters).map(([key, value]) => {
        return {
          key,
          value,
        };
      });

      const newKey = filterObjArray?.find(({ value }) => value.includes(item))?.key;

      return {
        key: newKey,
        item,
      };
    });

  const debounceHandleGetHumans = useRef(
    debounce(async (search?: string, filters?: Filter) => {
      dispatch(
        getHumatarsServer({
          keyword: search,
          pageNumber: 1,
          ...filters,
          isFeMale: filters?.isFeMale.length ? (filters.isFeMale[0] === "Male" ? false : true) : null,
        }),
      );
    }, searchTimeoutDelay),
  ).current;

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width > 735) {
      setOpenMobileSelectList(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    dispatch(searchHumatarResultLoading(true));
    dispatch(clearHumatars());
    debounceHandleGetHumans(search, filters);
  }, [search]);

  useEffect(() => {
    if (!isFirstRender.current) {
      dispatch(clearHumatars());
      dispatch(filterHumatarLoading(true));
      dispatch(
        getHumatarsServer({
          keyword: search,
          pageNumber: 1,
          ...filters,
          isFeMale: filters.isFeMale.length ? (filters.isFeMale[0] === "Male" ? false : true) : null,
          bookmarked: screen.bookmark,
          popular: screen.popular,
          history: screen.history,
        }),
      );
    }
    isFirstRender.current = false;
  }, [screen]);

  useEffect(() => {
    dispatch(filterHumatarLoading(true));
    dispatch(clearHumatars());
    debounceHandleGetHumans(search, filters);
  }, [filters]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

  const handleScreenChange = (e: any, key: "popular" | "history" | "bookmark", value: boolean) => {
    e.preventDefault();

    const newValue = {
      [key]: !value,
    };

    setScreen({ popular: false, history: false, bookmark: false, ...newValue });
  };

  const handleActive = (humatar: Humatar) => {
    const isCurrentHumatarActive = active?.aiHumanActorId === humatar.aiHumanActorId;
    if (isCurrentHumatarActive) {
      setActive(active);
    } else {
      setActive(humatar);
    }
  };

  const onSave = () => {
    const newActor = active;

    if (!newActor) {
      toast.error("Choose a humatar");
      return;
    }

    handleAddAvatar(
      newActor?.photo,
      newActor.aiHumanActorId,
      newActor.allowCircle,
      canvasSize,
      newActor.category === "Custom" ? true : false,
      ActorPositionTypes.FullBody,
    );
    handleClose();
  };

  const handleClose = () => {
    dispatch(clearSearch({ type: prefilled?.type }));
    setSearch("");
    onClose();
  };

  const handleFilterDelete = (
    key: "categoryType" | "voiceAge" | "isFeMale" | "mood" | "content" | "region" | "language" | "country",
    item: string,
  ) => {
    const newValue = {
      [key]: filters[key].filter((filter) => filter !== item) || [],
    };

    setFilters({ ...filters, ...newValue });
  };

  const fetchMore = (search: string) => {
    dispatch(
      getHumatarsServer({
        keyword: search,
        pageNumber: currentPage + 1,
        ...filters,
        isFeMale: filters.isFeMale.length ? (filters.isFeMale[0] === "Male" ? false : true) : null,
      }),
    );
  };

  const handleBookmarked = (e: any, id: number) => {
    e.stopPropagation();

    const isBookmarked = humans?.find((human) => human.aiHumanActorId === id)?.isBookmarked;

    if (isBookmarked) dispatch(bookmarkHumatarServer(id, false, screen.bookmark));
    else dispatch(bookmarkHumatarServer(id, true));
  };

  const handleCustomHumatarDelete = (e: any, human: Humatar) => {
    if (!human.aiHumanActorId) {
      throw Error(`The actor ID is missing when deleting`);
    }
    e.stopPropagation();
    e.preventDefault();

    const requestForHumatarServer = {
      keyword: search,
      pageNumber: 1,
      ...filters,
      isFeMale: filters.isFeMale.length ? (filters.isFeMale[0] === "Male" ? false : true) : null,
      bookmarked: screen.bookmark,
      popular: screen.popular,
      history: screen.history,
    };

    const previousFill: HumatarPrefilled = { ...(prefilled as HumatarPrefilled), requestForHumatarServer };

    const confirmationPrefilled: ConfirmationDelete = {
      id: human.aiHumanActorId,
      title: `Are you sure you want to delete ${human.name}?`,
      description: `The ${human.name} will be removed and the action cannot be undone`,
      typeId: 1,
      type: DeleteType.CustomHumatar,
      previousFill,
    };

    dispatch(
      updatePopup({
        popup: Popups.confirmationPopup,
        status: true,
        prefilled: confirmationPrefilled,
      }),
    );
  };

  const handlePopupOpen = (e: any, human: Humatar) => {
    e.stopPropagation();

    const prefilled: HumatarPreviewPrefilled = {
      human,
      setSelectedActor,
    };

    dispatch(
      updatePopup({
        popup: Popups.aIHumansPreviewPopup,
        status: true,
        prefilled,
      }),
    );
  };

  return (
    <Modal className="human" open={open} onClose={handleClose} closeIcon={false} maxWidth={1248} maxHeight={778}>
      <Content>
        <MobileOnlyTitleWrapper>
          <Title>AI Humans</Title>
        </MobileOnlyTitleWrapper>
        <CharacterSidebar categories={categoriesHumans} filters={filters} setFilters={setFilters} title="AI Humans" />
        <Right>
          <Heading>
            <ButtonWrapper>
              <Button
                buttonTheme={ButtonThemes.Transparent}
                text="Popular"
                icon={<StarIcon />}
                onClick={(e) => handleScreenChange(e, "popular", screen.popular)}
              />
              <Button
                buttonTheme={ButtonThemes.Transparent}
                text="History"
                icon={<ClockIcon />}
                onClick={(e) => handleScreenChange(e, "history", screen.history)}
              />
              <Button
                buttonTheme={ButtonThemes.Transparent}
                text="Bookmark"
                icon={!screen.bookmark ? <LikeIcon /> : <LikeActiveIcon />}
                onClick={(e) => handleScreenChange(e, "bookmark", screen.bookmark)}
              />
            </ButtonWrapper>
            <TextfieldWrapper>
              <Actions>
                <Textfield
                  value={search}
                  placeholder="Search actors"
                  startAdornment={<SearchIcon />}
                  onChange={handleSearchChange}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" || event.key === "Delete") {
                      event.stopPropagation();
                    }
                  }}
                />
              </Actions>
              <MobileOnlyFilterButton onClick={() => setOpenMobileSelectList(!openMobileSelectList)}>
                {openMobileSelectList ? <CloseIconV2 /> : <SearchFilterIcon />}
              </MobileOnlyFilterButton>
              <ImportMobileWrapper>
                <Import>
                  <DownloadIcon />
                </Import>
              </ImportMobileWrapper>
              {openMobileSelectList && (
                <CharactersWrapper>
                  <CharacterCategoryWrapper>
                    {categoriesHumans.map((category) => (
                      <CharacterCategory
                        key={category.id}
                        category={category}
                        filters={filters}
                        updateFilters={setFilters}
                      />
                    ))}
                  </CharacterCategoryWrapper>
                </CharactersWrapper>
              )}
            </TextfieldWrapper>
          </Heading>
          <ChipWrapper>
            {allFilters.map(({ key, item }: any, index: number) => (
              <Chip key={index} title={item} onRemove={() => handleFilterDelete(key, item)} />
            ))}
          </ChipWrapper>
          <HumansCardsWrapper id="scrollableDiv">
            <InfiniteScroll
              next={() => !searchLoading && !filterLoading && fetchMore(search)}
              hasMore={hasMore}
              loader={!screen.bookmark || !screen.history || !screen.popular ? <CardsLoader /> : null}
              dataLength={humans?.length}
              style={{ display: "contents" }}
              scrollableTarget="scrollableDiv"
            >
              {humans &&
                humans?.map((human) => {
                  const isCurrentHumatarActive = active?.aiHumanActorId === human.aiHumanActorId;
                  return (
                    <HumanCard
                      key={human.aiHumanActorId}
                      photo={human.photo}
                      name={human.name}
                      isDeleteButton={human.category === "Custom"}
                      isBookmarked={human.isBookmarked}
                      active={isCurrentHumatarActive}
                      isLocked={human.isLocked}
                      handleActive={() => handleActive(human)}
                      handleBookmarked={(e: any) => handleBookmarked(e, human.aiHumanActorId)}
                      handleClick={(e: any) => handlePopupOpen(e, human)}
                      handleCustomHumatarDelete={(e: any) => handleCustomHumatarDelete(e, human)}
                    />
                  );
                })}
            </InfiniteScroll>
          </HumansCardsWrapper>
          <ActionsWrapper>
            <Button text="Cancel" buttonTheme={ButtonThemes.Outline} onClick={handleClose} />
            <Button text="Add" onClick={onSave} />
          </ActionsWrapper>
        </Right>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;

  @media (max-width: 735px) {
    gap: 15px;
    flex-direction: column;
  }

  @media (max-height: 400px) {
    gap: 5px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden auto;
`;

const MobileOnlyFilterButton = styled.div`
  margin-left: 17px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 736px) {
    display: none;
  }
`;
const MobileOnlyTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background: ${({ theme }) => theme.button};
  }
  @media (min-width: 736px) {
    display: none;
  }
`;

const Title = styled.h2`
  font-family: "Mulish", sans-serif;
  color: ${({ theme }) => theme.primaryText};
  letter-spacing: -1px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 600px) and (max-height: 400px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 18px;

  button > span {
    font-size: 12px;
    line-height: 16px;

    @media (max-width: 600px) and (max-height: 400px) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  @media (max-height: 720px) {
    margin-bottom: 14px;

    button > span {
      font-size: 10px;
      line-height: 14px;
    }
  }

  @media (max-width: 735px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  @media (min-width: 736px) and (max-width: 949px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 735px) and (max-height: 660px) {
    margin-bottom: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  @media (max-width: 949px), (max-height: 800px) {
    margin-bottom: 5px;
  }
`;

const TextfieldWrapper = styled.div`
  width: 320px;

  @media (max-width: 735px) {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

const Actions = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
`;

const ImportDesktopWrapper = styled.div`
  @media (max-width: 735px) {
    display: none;
  }
`;

const ImportMobileWrapper = styled.div`
  margin-left: 16px;
  margin-bottom: 5px;

  @media (min-width: 736px) {
    display: none;
  }
`;

const Import = styled.div`
  background: linear-gradient(142.13deg, #0063b4 16.78%, #009af7 85.53%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  column-gap: 4px;
  align-items: center;

  & path {
    fill: #0063b4;
  }
`;

const ImportText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  gap: 12px;
  width: 100%;
  height: 32px;
  overflow: scroll;
  flex-flow: column wrap;
  flex-shrink: 0;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 735px) {
    display: none;
  }
`;

const HumansCardsWrapper = styled.div`
  position: relative;
  overflow: hidden auto;
  height: 100%;
  max-height: 100%;
  margin-top: 18px;
  display: grid;
  align-content: start;
  padding-bottom: 100px;

  .infinite-scroll-component__outerdiv {
    display: contents;
  }

  @media (min-width: 1310px) {
    grid-template-columns: repeat(4, 216px);
  }

  @media (min-width: 950px) and (max-width: 1309px) {
    grid-template-columns: repeat(3, 216px);
  }
  @media (min-width: 950px) and (max-width: 1309px) and (max-height: 860px), (max-height: 800px) {
    max-height: 58vh;
  }

  @media (max-height: 800px) {
    max-height: 58vh;
  }

  @media (max-height: 660px) {
    grid-template-columns: repeat(3, 216px);
  }

  @media (max-width: 949px) {
    grid-template-columns: repeat(2, 216px);
  }

  @media (max-width: 949px) and (max-height: 860px) {
    max-height: 58vh;
  }
  @media (max-width: 735px) {
    max-height: 65vh;
    margin-top: 0;
  }

  @media (max-width: 735px) and (max-height: 660px) {
    max-height: 58vh;
    margin-top: 0;
  }

  @media (max-width: 515px) {
    position: inherit;
    max-height: 71vh;
    margin-top: 0;
  }
  @media (max-width: 515px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 515px) and (max-height: 760px) {
    max-height: 62vh;
  }

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.characterActionsBackground};
  height: 100px;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 3;

  button {
    width: 161.5px;
  }

  @media (max-width: 515px) and (max-height: 760px) {
    height: 80px;
    width: 99%;
  }

  @media (min-width: 950px) and (max-height: 720px) {
    height: auto;

    button {
      width: 100px;
      max-height: 48px;

      & > span {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`;

const CharactersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 80px;
  border: solid 1px ${({ theme }) => theme.activeMenu};

  svg {
    path {
      fill: ${({ theme }) => theme.activeMenu};
    }
  }
`;

const CharacterCategoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 12px 16px 12px;
  border-radius: 16px;
  gap: 8px;
  max-height: 53vh;
  overflow-y: auto;

  @media (max-width: 1001px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.activeMenu};
  }

  ::-webkit-scrollbar-track {
    margin: 15px 0;
  }
`;

export default AIHumansPopup;
