export const NextIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 15.9082V8.01415L13.322 11.9982L8.5 15.9082ZM16 6.00015C15.448 6.00015 15 6.44715 15 7.00015V10.8182C14.953 10.7692 14.912 10.7182 14.858 10.6742L9.759 6.46015C9.15 5.95815 8.282 5.85615 7.55 6.20115C6.902 6.50715 6.5 7.11415 6.5 7.78715V16.2132C6.5 16.8862 6.902 17.4932 7.55 17.7992C7.839 17.9342 8.149 18.0012 8.456 18.0012C8.927 18.0012 9.391 17.8442 9.759 17.5392L14.859 13.3262C14.912 13.2822 14.953 13.2302 15 13.1812V17.0002C15 17.5532 15.448 18.0002 16 18.0002C16.552 18.0002 17 17.5532 17 17.0002V7.00015C17 6.44715 16.552 6.00015 16 6.00015Z"
      fill="url(#paint0_linear_493_11106)"
    />
    <mask id="mask0_493_11106" maskUnits="userSpaceOnUse" x="6" y="5" width="11" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 15.9082V8.01415L13.322 11.9982L8.5 15.9082ZM16 6.00015C15.448 6.00015 15 6.44715 15 7.00015V10.8182C14.953 10.7692 14.912 10.7182 14.858 10.6742L9.759 6.46015C9.15 5.95815 8.282 5.85615 7.55 6.20115C6.902 6.50715 6.5 7.11415 6.5 7.78715V16.2132C6.5 16.8862 6.902 17.4932 7.55 17.7992C7.839 17.9342 8.149 18.0012 8.456 18.0012C8.927 18.0012 9.391 17.8442 9.759 17.5392L14.859 13.3262C14.912 13.2822 14.953 13.2302 15 13.1812V17.0002C15 17.5532 15.448 18.0002 16 18.0002C16.552 18.0002 17 17.5532 17 17.0002V7.00015C17 6.44715 16.552 6.00015 16 6.00015Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_493_11106)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_493_11106"
        x1="8.76154"
        y1="7.56821"
        x2="16.046"
        y2="15.7615"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
