import axios from "axios";
import { useEffect, useState } from "react";

const useAIHumansFonts = () => {
  const [selectedFont, setSelectedFont] = useState("Mulish");
  const [fontList, setFontList] = useState([]);
  const [addedFonts, setAddedFonts] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [validFonts, setValidFonts] = useState<{ font: string; image: string }[]>([]);

  useEffect(() => {
    const fetchFonts = async () => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAZ109s1cW75BFQ2fIBwGQfu-TwP9wkagE",
        );
        const { items } = response.data;
        const fetchedFontList = items.map((item: any) => item.family);
        setFontList(fetchedFontList);
      } catch (error) {
        console.error("Error fetching Google Fonts:", error);
      }
    };

    fetchFonts();
  }, []);

  useEffect(() => {
    const fonts = fontList.map((font: string) => ({
      image: `https://app.synthesys.live/fonts/${font}-400.png`,
      font,
    }));

    setValidFonts(fonts);
  }, [fontList]);

  useEffect(() => {
    addFontToDocument(selectedFont);
  }, [selectedFont]);

  const addFontToDocument = async (font: string) => {
    if (addedFonts.includes(font)) return;
    setIsLoading(true);

    try {
      const response = await axios.get(`https://fonts.googleapis.com/css?family=${font}`);
      let css = response.data;
      css = css.replace(/}/g, "font-display: swap; }");

      const head = document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
    setAddedFonts((fonts) => [...fonts, font]);
  };

  const handleChangeSelected = (value: string) => {
    setSelectedFont(value);
  };

  return {
    fontList: validFonts,
    selectedFont,
    handleChangeSelected,
    addFontToDocument,
    isFontLoading: isLoading,
  };
};

export default useAIHumansFonts;
