import { ChangeSubscription, UserPlan } from "../../types/plans";
import { StoreType } from "../../types/store";
import {
  ADD_TO_LOADING_PLANS,
  CHANGE_SUBSCRIPTION_UPDATED,
  REMOVE_FROM_LOADING_PLANS,
  UPDATE_CHANGE_SUBSCRIPTIONS,
  UPDATE_CHANGE_SUBSCRIPTIONS_LOADING,
  UPDATE_PAYMENT_METHOD_PKS,
  UPDATE_PAYMENT_METHOD_PKS_LOADING,
  UPDATE_USER_PLANS,
  UPDATE_USER_PLANS_LOADING,
} from "../actions/plansActions";

export interface userPlansStateType {
  plans: UserPlan[];
  isLoading: boolean;
  changeSubscriptions: ChangeSubscription[];
  changeSubscriptionsLoading: boolean;
  subscriptionUpdated: boolean;
  loadingPlans: number[];
  paymentMethodPKS: string;
  paymentMethodPKSLoading: boolean;
}

const plansInitialState: userPlansStateType = {
  plans: [],
  isLoading: false,
  changeSubscriptions: [],
  changeSubscriptionsLoading: false,
  subscriptionUpdated: false,
  loadingPlans: [],
  paymentMethodPKS: "",
  paymentMethodPKSLoading: false,
};

const plansReducer = (state = plansInitialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER_PLANS: {
      return {
        ...state,
        plans: action.payload.data,
      };
    }
    case UPDATE_USER_PLANS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.status,
      };
    }
    case UPDATE_CHANGE_SUBSCRIPTIONS: {
      return {
        ...state,
        changeSubscriptions: action.payload.data,
      };
    }
    case UPDATE_CHANGE_SUBSCRIPTIONS_LOADING: {
      return {
        ...state,
        changeSubscriptionsLoading: action.payload.status,
      };
    }
    case CHANGE_SUBSCRIPTION_UPDATED: {
      return {
        ...state,
        subscriptionUpdated: action.payload.status,
      };
    }
    case ADD_TO_LOADING_PLANS: {
      return {
        ...state,
        loadingPlans: [...state.loadingPlans, action.payload.id],
      };
    }
    case REMOVE_FROM_LOADING_PLANS: {
      return {
        ...state,
        loadingPlans: state.loadingPlans.filter((id) => id !== action.payload.id),
      };
    }
    case UPDATE_PAYMENT_METHOD_PKS: {
      return {
        ...state,
        paymentMethodPKS: action.payload.data,
      };
    }
    case UPDATE_PAYMENT_METHOD_PKS_LOADING: {
      return {
        ...state,
        paymentMethodPKSLoading: action.payload.status,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const getUserPlans = (state: StoreType) => state.plans.plans;
export const getUserPlansLoading = (state: StoreType) => state.plans.isLoading;
export const getChangeSubscriptions = (state: StoreType) => state.plans.changeSubscriptions;
export const getChangeSubscriptionsLoading = (state: StoreType) => state.plans.changeSubscriptionsLoading;
export const getIsSubscriptionUpdated = (state: StoreType) => state.plans.subscriptionUpdated;
export const getLoadingPlans = (state: StoreType) => state.plans.loadingPlans;
export const getPaymentMethodPKS = (state: StoreType) => state.plans.paymentMethodPKS;
export const getPaymentMethodPKSLoading = (state: StoreType) => state.plans.paymentMethodPKSLoading;

export default plansReducer;
