export const PricingBundleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M10.8212 13.28H13.9112V20.48C13.9112 21.54 15.2312 22.04 15.9312 21.24L23.5012 12.64C24.1612 11.89 23.6312 10.72 22.6312 10.72H19.5412V3.51997C19.5412 2.45997 18.2212 1.95997 17.5212 2.75997L9.95122 11.36C9.30122 12.11 9.83122 13.28 10.8212 13.28Z"
      fill="url(#paint0_linear_0_340)"
    />
    <rect x="0.243774" y="11" width="8" height="2" rx="1" fill="#A9DEFF" />
    <rect x="0.243774" y="11" width="8" height="2" rx="1" fill="url(#paint1_linear_0_340)" />
    <rect x="0.243774" y="4" width="8" height="2" rx="1" fill="#A9DEFF" />
    <rect x="0.243774" y="4" width="8" height="2" rx="1" fill="url(#paint2_linear_0_340)" />
    <rect x="0.243774" y="18" width="8" height="2" rx="1" fill="#A9DEFF" />
    <rect x="0.243774" y="18" width="8" height="2" rx="1" fill="url(#paint3_linear_0_340)" />
    <defs>
      <linearGradient
        id="paint0_linear_0_340"
        x1="12.7083"
        y1="4.88545"
        x2="24.4631"
        y2="15.9668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_0_340"
        x1="1.24377"
        y1="12"
        x2="2.63828"
        y2="14.5342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#065EA6" />
        <stop offset="1" stopColor="#0C8EDD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_0_340"
        x1="1.24377"
        y1="5"
        x2="2.63828"
        y2="7.53424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#065EA6" />
        <stop offset="1" stopColor="#0C8EDD" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_0_340"
        x1="1.24377"
        y1="19"
        x2="2.63828"
        y2="21.5342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#065EA6" />
        <stop offset="1" stopColor="#0C8EDD" />
      </linearGradient>
    </defs>
  </svg>
);
