export const CrossDissolveIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="19.8729"
      height="21.1756"
      rx="4"
      transform="matrix(0.993068 0.117537 0.00820296 0.999966 9 2)"
      fill="url(#paint0_linear_2969_21978)"
    />
    <g opacity="0.7" filter="url(#filter0_f_2969_21978)">
      <rect
        width="16.2596"
        height="16.2596"
        rx="3.61325"
        transform="matrix(0.994522 -0.104528 0.0495471 0.998772 1 6.69922)"
        fill="url(#paint1_linear_2969_21978)"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2969_21978"
        x="0.2"
        y="4.2"
        width="18.5762"
        height="19.5395"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.4" result="effect1_foregroundBlur_2969_21978" />
      </filter>
      <linearGradient
        id="paint0_linear_2969_21978"
        x1="4.28031"
        y1="2.76911"
        x2="16.996"
        y2="18.1121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2969_21978"
        x1="3.50208"
        y1="2.12626"
        x2="13.1328"
        y2="14.5086"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#66B2E9" />
        <stop offset="1" stopColor="#90CEF5" />
      </linearGradient>
    </defs>
  </svg>
);
