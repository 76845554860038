export const ShareAssetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8.66667 2.66797V5.33464C4.28334 6.01997 2.65334 9.85997 2 13.3346C1.97534 13.472 5.58934 9.35997 8.66667 9.33464V12.0013L14 7.33464L8.66667 2.66797Z"
      stroke="#191B1F"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
