export const TrashIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66634 10.6668C6.66634 11.0335 6.36634 11.3335 5.99967 11.3335C5.63301 11.3335 5.33301 11.0335 5.33301 10.6668V8.00016C5.33301 7.6335 5.63301 7.3335 5.99967 7.3335C6.36634 7.3335 6.66634 7.6335 6.66634 8.00016V10.6668ZM10.6663 10.6668C10.6663 11.0335 10.3663 11.3335 9.99967 11.3335C9.63301 11.3335 9.33301 11.0335 9.33301 10.6668V8.00016C9.33301 7.6335 9.63301 7.3335 9.99967 7.3335C10.3663 7.3335 10.6663 7.6335 10.6663 8.00016V10.6668ZM12 12.6668C12 13.0342 11.7013 13.3335 11.3333 13.3335H4.66667C4.29867 13.3335 4 13.0342 4 12.6668V5.3335H12V12.6668ZM6.66602 2.88548C6.66602 2.78214 6.80868 2.66681 6.99935 2.66681H8.99935C9.19002 2.66681 9.33268 2.78214 9.33268 2.88548V4.00014H6.66602V2.88548ZM13.9997 4.00016H13.333H10.6663V2.8855C10.6663 2.0295 9.91901 1.3335 8.99967 1.3335H6.99967C6.08034 1.3335 5.33301 2.0295 5.33301 2.8855V4.00016H2.66634H1.99967C1.63301 4.00016 1.33301 4.30016 1.33301 4.66683C1.33301 5.0335 1.63301 5.3335 1.99967 5.3335H2.66634V12.6668C2.66634 13.7695 3.56367 14.6668 4.66634 14.6668H11.333C12.4357 14.6668 13.333 13.7695 13.333 12.6668V5.3335H13.9997C14.3663 5.3335 14.6663 5.0335 14.6663 4.66683C14.6663 4.30016 14.3663 4.00016 13.9997 4.00016Z"
      fill="url(#paint0_linear_2305_10104)"
    />
    <mask id="mask0_2305_10104" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66634 10.6668C6.66634 11.0335 6.36634 11.3335 5.99967 11.3335C5.63301 11.3335 5.33301 11.0335 5.33301 10.6668V8.00016C5.33301 7.6335 5.63301 7.3335 5.99967 7.3335C6.36634 7.3335 6.66634 7.6335 6.66634 8.00016V10.6668ZM10.6663 10.6668C10.6663 11.0335 10.3663 11.3335 9.99967 11.3335C9.63301 11.3335 9.33301 11.0335 9.33301 10.6668V8.00016C9.33301 7.6335 9.63301 7.3335 9.99967 7.3335C10.3663 7.3335 10.6663 7.6335 10.6663 8.00016V10.6668ZM12 12.6668C12 13.0342 11.7013 13.3335 11.3333 13.3335H4.66667C4.29867 13.3335 4 13.0342 4 12.6668V5.3335H12V12.6668ZM6.66602 2.88548C6.66602 2.78214 6.80868 2.66681 6.99935 2.66681H8.99935C9.19002 2.66681 9.33268 2.78214 9.33268 2.88548V4.00014H6.66602V2.88548ZM13.9997 4.00016H13.333H10.6663V2.8855C10.6663 2.0295 9.91901 1.3335 8.99967 1.3335H6.99967C6.08034 1.3335 5.33301 2.0295 5.33301 2.8855V4.00016H2.66634H1.99967C1.63301 4.00016 1.33301 4.30016 1.33301 4.66683C1.33301 5.0335 1.63301 5.3335 1.99967 5.3335H2.66634V12.6668C2.66634 13.7695 3.56367 14.6668 4.66634 14.6668H11.333C12.4357 14.6668 13.333 13.7695 13.333 12.6668V5.3335H13.9997C14.3663 5.3335 14.6663 5.0335 14.6663 4.66683C14.6663 4.30016 14.3663 4.00016 13.9997 4.00016Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2305_10104)"></g>
    <defs>
      <linearGradient
        id="paint0_linear_2305_10104"
        x1="4.2048"
        y1="3.07709"
        x2="12.1022"
        y2="13.2309"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0063B4" />
        <stop offset="1" stopColor="#009AF7" />
      </linearGradient>
    </defs>
  </svg>
);
