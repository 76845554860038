import React from "react";
import styled from "styled-components";
import IconButton, { IconButtonThemes } from "../../../components/Button/IconButton";
import { PlayIcon } from "../../../components/Icons/Icons";

interface Props {
  src: string;
  thumb?: string;
  title: string;
  subtitle?: string;
  subtitleSecond?: string;
  handleClick: () => void;
  previewType: "iframe" | "image";
}

const TrainingCard = ({ src, subtitle, subtitleSecond, title, handleClick, previewType, thumb }: Props) => {
  return (
    <Card>
      <Top>
        <VideoWrapper>
          {previewType === "iframe" ? <Video src={src} /> : <Image src={thumb ? thumb : src} />}
        </VideoWrapper>
        <PlayButton>
          <IconButton
            onClick={handleClick}
            className="rounded"
            iconButtonTheme={IconButtonThemes.Rounded}
            icon={<PlayIcon />}
          />
        </PlayButton>
      </Top>
      <Bottom>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {subtitleSecond && <Subtitle>{subtitleSecond}</Subtitle>}
      </Bottom>
    </Card>
  );
};

const Card = styled.div`
  background: ${({ theme }) => theme.primaryBackground};
  box-shadow: ${({ theme }) => theme.cardShadow};
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  position: relative;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 180px;
  padding: 0 5px;
  overflow: hidden;
  pointer-events: none;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const Video = styled.iframe`
  max-width: 100%;
  width: 100%;
  border: none;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & button {
    width: 48px;
    height: 48px;
    max-width: none;
    box-shadow: 5.012853622436523px 5.012853622436523px 10.025707244873047px 0px rgba(174, 174, 192, 0.2),
      2.005141496658325px 2.005141496658325px 4.01028299331665px 0px #fff inset,
      -2.005141496658325px -2.005141496658325px 4.01028299331665px 0px rgba(0, 0, 0, 0.1) inset;
  }
`;

const Bottom = styled.div`
  padding: 14px 24px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.primaryText};
  font-size: 16px;
  font-weight: 600;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.homeSectionTabs};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export default TrainingCard;
