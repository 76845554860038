export const TataLogoIcon = () => (
  <svg width="72" height="24" viewBox="0 0 72 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36.9412 8.99914H38.43V6.19549H39.4862V5.06231H35.885V6.19549H36.9412V8.99914Z" fill="#6F7074" />
    <path
      d="M40.5824 8.99937L41.3413 6.64509L42.099 8.99937H43.5554L42.1279 5.06254H40.5535L39.1271 8.99937H40.5824Z"
      fill="#6F7074"
    />
    <path d="M44.2557 8.99914H45.7445V6.19549H46.8007V5.06231H43.1995V6.19549H44.2557V8.99914Z" fill="#6F7074" />
    <path
      d="M47.899 8.99937L48.6579 6.64509L49.4156 8.99937H50.8721L49.4457 5.06254H47.8701L46.4437 8.99937H47.899Z"
      fill="#6F7074"
    />
    <path
      d="M62.1892 11.8139C62.3731 12.2596 62.6161 12.742 62.8578 13.1718L63.8319 14.9033H64.5375V10.9665H63.9071V12.3402C63.9071 12.9422 63.9198 13.449 63.9684 13.9716L63.9557 13.9753C63.7833 13.5479 63.567 13.09 63.3206 12.6565L62.3569 10.9665H61.5784V14.9033H62.2077V13.4954C62.2077 12.8494 62.1984 12.3292 62.1718 11.8176L62.1892 11.8139Z"
      fill="#6F7074"
    />
    <path
      d="M58.8049 13.791H59.9895L60.3273 14.9034H61.0584L59.8472 10.9666H58.9808L57.7834 14.9034H58.488L58.8049 13.791ZM59.204 12.2756C59.27 12.0412 59.3267 11.7689 59.381 11.5381H59.3926C59.4493 11.7664 59.5106 12.0351 59.5812 12.2781L59.875 13.2488H58.9137L59.204 12.2756Z"
      fill="#6F7074"
    />
    <path
      d="M47.7032 14.3695C47.3562 14.3695 47.0219 14.2523 46.8021 14.118L46.6505 14.7139C46.8541 14.8458 47.2636 14.9654 47.6581 14.9654C48.6206 14.9654 49.0729 14.4159 49.0729 13.7822C49.0729 13.2168 48.7617 12.8725 48.1139 12.6124C47.6165 12.4121 47.4001 12.2693 47.4001 11.9676C47.4001 11.7356 47.5841 11.4939 48.0167 11.4939C48.3673 11.4939 48.6275 11.6062 48.7617 11.6807L48.9248 11.1007C48.7305 10.9969 48.4343 10.9065 48.0306 10.9065C47.222 10.9065 46.7107 11.3925 46.7107 12.0372C46.7107 12.6014 47.104 12.9506 47.7206 13.1814C48.1914 13.3621 48.3811 13.538 48.3811 13.8347C48.3811 14.1546 48.137 14.3695 47.7032 14.3695Z"
      fill="#6F7074"
    />
    <path
      d="M37.9109 11.499C38.2151 11.499 38.4638 11.5698 38.6374 11.6528L38.7854 11.0801C38.6466 11.0008 38.3308 10.9055 37.8819 10.9055C36.7425 10.9055 35.8853 11.687 35.8853 12.9814C35.8853 14.1793 36.606 14.9657 37.7894 14.9657C38.2406 14.9657 38.5957 14.8717 38.7565 14.7862L38.6443 14.2196C38.465 14.3051 38.1769 14.3625 37.9005 14.3625C37.0803 14.3625 36.6025 13.813 36.6025 12.9448C36.6025 11.9996 37.1497 11.499 37.9109 11.499Z"
      fill="#6F7074"
    />
    <path d="M56.9502 14.9034V11.5711H58.0249V10.9666H55.2034V11.5711H56.2688V14.9034H56.9502Z" fill="#6F7074" />
    <path
      d="M70.5707 10.9666L70.1219 11.9752C69.9946 12.261 69.8916 12.5052 69.7991 12.7567H69.7875C69.6857 12.4905 69.5886 12.2659 69.4625 11.9765L69.0182 10.9666H68.2385L69.4185 13.2647V14.9034H70.0999V13.2452L71.3388 10.9678H70.5707V10.9666Z"
      fill="#6F7074"
    />
    <path
      d="M40.7585 10.9031C39.7058 10.9031 38.9828 11.7566 38.9828 12.9655C38.9828 14.1182 39.6491 14.9694 40.7042 14.9694H40.7099C41.7314 14.9694 42.4891 14.2147 42.4891 12.8959C42.4868 11.7701 41.8459 10.9031 40.7585 10.9031ZM40.7319 14.393H40.7261C40.0748 14.393 39.6942 13.7312 39.6966 12.9484C39.6954 12.1633 40.054 11.4782 40.7342 11.4782C41.4145 11.4782 41.7638 12.1804 41.7638 12.9228C41.7627 13.7458 41.3902 14.393 40.7319 14.393Z"
      fill="#6F7074"
    />
    <path
      d="M67.145 11.499C67.4492 11.499 67.698 11.5699 67.8715 11.6529L68.0196 11.0802C67.8807 11.0008 67.5649 10.9056 67.1161 10.9056C65.9766 10.9056 65.1194 11.6871 65.1194 12.9814C65.1194 14.1793 65.8401 14.9657 67.0235 14.9657C67.4747 14.9657 67.8298 14.8729 67.9906 14.7862L67.8784 14.2196C67.6991 14.3051 67.4111 14.3625 67.1346 14.3625C66.3144 14.3625 65.8366 13.813 65.8366 12.9448C65.8366 11.9997 66.3838 11.499 67.145 11.499Z"
      fill="#6F7074"
    />
    <path d="M54.0488 10.9666H53.3674V14.9034H55.5828V14.3002H54.0488V10.9666Z" fill="#6F7074" />
    <path
      d="M52.5927 13.2165V10.9684H51.9113V13.2641C51.9113 14.0358 51.6163 14.3912 51.1258 14.3912C50.6643 14.3912 50.3612 14.0334 50.3612 13.2641V10.9684H49.6798V13.2214C49.6798 14.4596 50.2409 14.9712 51.1004 14.9712C52.0016 14.97 52.5927 14.429 52.5927 13.2165Z"
      fill="#6F7074"
    />
    <path
      d="M46.0278 10.9666H45.3973V12.3403C45.3973 12.9423 45.41 13.4491 45.4586 13.9717L45.4459 13.9754C45.2735 13.548 45.0572 13.0901 44.8108 12.6566L43.8472 10.9666H43.0686V14.9034H43.6991V13.4955C43.6991 12.8495 43.6898 12.3294 43.6632 11.8189L43.6806 11.8153C43.8645 12.261 44.1074 12.7433 44.3492 13.1731L45.3233 14.9047H46.0289V10.9666H46.0278Z"
      fill="#6F7074"
    />
    <path d="M48.9453 16.8719H48.2639V20.8087H48.9453V16.8719Z" fill="#6F7074" />
    <path
      d="M43.5786 18.9624V18.9465C43.9268 18.8159 44.2507 18.474 44.2507 17.9709C44.2507 17.6363 44.1351 17.3652 43.9326 17.1869C43.6827 16.9671 43.2524 16.8719 42.7908 16.8719C42.3292 16.8719 42.0238 16.9061 41.7659 16.9561V20.8087H42.4368V19.203H42.7769C43.161 19.2127 43.3368 19.3776 43.4433 19.8868C43.5462 20.3728 43.6353 20.6951 43.692 20.8087H44.3861C44.3121 20.6561 44.2114 20.2079 44.0946 19.7195C44.002 19.3397 43.847 19.0699 43.5786 18.9624ZM42.8371 18.6791H42.4368V17.4446C42.5051 17.4263 42.6427 17.4092 42.8568 17.4092C43.294 17.4104 43.5775 17.6094 43.5775 18.0356C43.5775 18.4239 43.2952 18.6791 42.8371 18.6791Z"
      fill="#6F7074"
    />
    <path
      d="M46.5944 18.6715C46.4498 19.149 46.3145 19.6301 46.215 20.0941H46.2034C46.1063 19.6179 45.979 19.1575 45.8379 18.6605L45.3289 16.8704H44.5862L45.7928 20.8072H46.5736L47.8623 16.8704H47.137L46.5944 18.6715Z"
      fill="#6F7074"
    />
    <path
      d="M53.6582 19.0613H55.0637V18.4752H53.6582V17.4629H55.147V16.8719H52.9768V20.8075H55.2292V20.2165H53.6582V19.0613Z"
      fill="#6F7074"
    />
    <path
      d="M51.5571 17.4029C51.8614 17.4029 52.1101 17.4737 52.2836 17.5568L52.4317 16.9841C52.2929 16.9047 51.977 16.8095 51.5282 16.8095C50.3887 16.8095 49.5315 17.591 49.5315 18.8853C49.5315 20.0832 50.2522 20.8696 51.4356 20.8696C51.8868 20.8696 52.242 20.7768 52.4028 20.6901L52.2905 20.1235C52.1101 20.209 51.8232 20.2664 51.5467 20.2664C50.7265 20.2664 50.2487 19.7169 50.2487 18.8487C50.2487 17.9036 50.7959 17.4029 51.5571 17.4029Z"
      fill="#6F7074"
    />
    <path
      d="M37.3479 18.5163C36.8493 18.3161 36.6341 18.1732 36.6341 17.8716C36.6341 17.6396 36.818 17.3978 37.2495 17.3978C37.6 17.3978 37.8603 17.5101 37.9945 17.5846L38.1576 17.0046C37.9633 16.9008 37.6671 16.8104 37.2634 16.8104C36.4548 16.8104 35.9435 17.2964 35.9435 17.9412C35.9435 18.5053 36.3368 18.8546 36.9534 19.0854C37.4242 19.2661 37.6139 19.4419 37.6139 19.7386C37.6139 20.061 37.371 20.2759 36.936 20.2759C36.589 20.2759 36.2546 20.1587 36.0348 20.0244L35.8833 20.6203C36.0869 20.7522 36.4964 20.8718 36.8909 20.8718C37.8534 20.8718 38.3057 20.3223 38.3057 19.6886C38.3069 19.1208 37.9957 18.7764 37.3479 18.5163Z"
      fill="#6F7074"
    />
    <path
      d="M39.6036 19.0613H41.0092V18.4752H39.6036V17.4629H41.0936V16.8719H38.9222V20.8075H41.1758V20.2165H39.6036V19.0613Z"
      fill="#6F7074"
    />
    <path
      d="M57.1307 18.5163C56.6333 18.3161 56.4169 18.1732 56.4169 17.8716C56.4169 17.6396 56.6009 17.3978 57.0324 17.3978C57.3829 17.3978 57.6432 17.5101 57.7774 17.5846L57.9405 17.0046C57.7461 16.9008 57.45 16.8104 57.0462 16.8104C56.2376 16.8104 55.7263 17.2964 55.7263 17.9412C55.7263 18.5053 56.1196 18.8546 56.7362 19.0854C57.207 19.2661 57.3968 19.4419 57.3968 19.7386C57.3968 20.061 57.1538 20.2759 56.7189 20.2759C56.3718 20.2759 56.0375 20.1587 55.8177 20.0244L55.6661 20.6203C55.8697 20.7522 56.2793 20.8718 56.6737 20.8718C57.6362 20.8718 58.0885 20.3223 58.0885 19.6886C58.0897 19.1208 57.7785 18.7764 57.1307 18.5163Z"
      fill="#6F7074"
    />
    <path
      d="M0.761719 7.75459C0.761719 5.97179 1.85955 4.9021 3.46408 4.9021V1.87133C3.46408 0.979929 2.9007 0.385254 2.1129 0.385254C1.3251 0.385254 0.761719 0.979929 0.761719 1.87133V7.75459Z"
      fill="#6F7074"
    />
    <path
      d="M5.54753 20.9171C6.22312 20.9171 6.84203 20.7986 7.4054 20.5898C4.56191 21.0953 3.46408 19.0451 3.46408 16.7275V7.21998H6.75758C7.51762 7.21998 8.02431 6.74497 8.02431 6.06115C8.02431 5.37734 7.51762 4.90233 6.75758 4.90233H3.46408C1.85955 4.90233 0.761719 5.97201 0.761719 7.75482V15.9252C0.761719 19.1941 2.53514 20.9171 5.54753 20.9171Z"
      fill="#6F7074"
    />
    <path
      d="M7.40812 20.589C8.53372 20.1433 9.40713 19.3118 9.96935 18.0931L8.70262 15.6863C8.13924 17.4691 7.15478 18.4204 5.8036 18.4204C4.39573 18.4204 3.4668 17.5583 3.4668 15.5972V16.7267C3.4668 19.0443 4.56463 21.0946 7.40812 20.589Z"
      fill="#6F7074"
    />
    <path
      d="M18.9746 20.1141C12.5565 21.124 10.5297 17.4998 10.5297 12.8352C10.5297 9.44782 12.4998 7.13018 15.3433 7.13018C16.3844 7.13018 17.2578 7.36829 18.0456 7.75416C18.4112 7.93244 18.7212 7.99227 19.0023 7.99227C19.6779 7.99227 20.1279 7.51727 20.1279 6.80414C20.1279 6.35844 19.8746 5.91274 19.2834 5.61601C18.2134 5.05186 16.8344 4.72461 15.3421 4.72461C10.8941 4.72461 7.82617 8.05211 7.82617 12.895C7.82617 17.6487 10.7819 20.9469 15.5388 20.9469C16.8356 20.9457 17.9612 20.6783 18.9746 20.1141Z"
      fill="#6F7074"
    />
    <path
      d="M7.82617 12.894C7.82617 8.05113 10.8941 4.72363 15.3421 4.72363C16.8344 4.72363 18.2134 5.05089 19.2834 5.61504C19.8746 5.91176 20.1279 6.35746 20.1279 6.80317C20.1279 7.51629 19.6779 7.9913 19.0023 7.9913C18.7212 7.9913 18.4112 7.93146 18.0456 7.75318C17.0889 7.27817 16.16 7.1292 15.3433 7.1292C11.4285 7.13042 7.82617 9.7448 7.82617 12.894Z"
      fill="#6F7074"
    />
    <path
      d="M24.6312 5.67476C24.3779 5.94218 24.2367 6.26944 24.2367 6.65531C24.2367 7.63585 25.109 9.0914 27.1358 10.5775L29.4436 12.2711C31.1326 13.5191 31.8082 14.7072 31.8082 16.49C31.8082 17.7673 31.3293 18.8675 30.5415 19.6393C30.8226 19.3718 30.9915 19.0153 30.9915 18.5403C30.9915 17.4999 30.0059 15.8661 27.8946 14.3214L25.7834 12.7767C24.01 11.4689 23.3066 10.2514 23.3066 8.58709C23.3066 7.60654 23.6444 6.71514 24.2078 6.06185C24.3501 5.91166 24.4901 5.79321 24.6312 5.67476Z"
      fill="#6F7074"
    />
    <path
      d="M22.8008 19.0157C22.8008 19.4907 23.0264 19.8778 23.6175 20.1745C24.4342 20.5909 25.7565 20.9475 26.9388 20.9475C28.4311 20.9475 29.6689 20.4725 30.5423 19.6397C30.8234 19.3722 30.9923 19.0157 30.9923 18.5407C30.9923 17.5003 30.0067 15.8665 27.8955 14.3218C28.8522 15.0349 29.2466 15.6296 29.2466 16.4905C29.2466 17.7677 28.2899 18.6298 26.9098 18.6298C25.5864 18.6298 24.4608 17.9167 23.8419 17.9167C23.223 17.9167 22.8008 18.3624 22.8008 19.0157Z"
      fill="#6F7074"
    />
    <path
      d="M27.1365 10.5773C26.2353 9.89353 25.842 9.26954 25.842 8.52711C25.842 7.57588 26.5743 6.98242 27.6154 6.98242C28.9388 6.98242 29.5578 7.63571 30.3456 7.63571C30.9645 7.63571 31.3867 7.19001 31.3867 6.53672C31.3867 6.06171 31.1611 5.67463 30.57 5.3779C29.641 4.90289 28.6277 4.72461 27.5298 4.72461C26.3753 4.72461 25.3619 5.08117 24.6308 5.67585C24.3775 5.94327 24.2363 6.27052 24.2363 6.65639C24.2363 7.63571 25.1097 9.09126 27.1365 10.5773Z"
      fill="#6F7074"
    />
    <path
      d="M10.5293 12.8357C10.5293 17.5003 12.5561 21.1257 18.9742 20.1147C21.0287 18.9852 22.4932 16.698 23.5055 13.1923L24.0966 11.142C23.5332 10.3691 23.3088 9.5082 23.3088 8.58627C23.3088 7.60573 23.6466 6.71432 24.21 6.06104C23.3655 7.01227 22.6621 8.40799 22.0988 10.3691L21.4509 12.6269C20.2964 16.6089 18.4675 18.5102 15.624 18.5102C12.4994 18.5102 10.5293 16.223 10.5293 12.8357Z"
      fill="#6F7074"
    />
  </svg>
);
